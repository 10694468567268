import { UserConfigActionTypes, } from './types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';
export const getConfigById = createAsyncMapAction(UserConfigActionTypes.GET_CONFIG_BY_ID, p => p.userId);
export const getConfigExtended = createAsyncMapAction(UserConfigActionTypes.GET_CONFIG_EXTENDED, p => p.userId);
export const getConfigRaw = createAsyncAction(UserConfigActionTypes.GET_CONFIG_RAW);
export const listFlagsById = createAsyncMapAction(UserConfigActionTypes.LIST_FLAGS_BY_ID, p => p.userId);
export const setFlag = createAsyncMapAction(UserConfigActionTypes.SET_FLAG, p => p.requestId);
export const getFlagConfig = createAsyncMapAction(UserConfigActionTypes.GET_FLAG_CONFIG, p => p.tag);
export const setFlagConfig = createAsyncMapAction(UserConfigActionTypes.SET_FLAG_CONFIG, p => p.requestId);
export const deleteFlagConfig = createAsyncMapAction(UserConfigActionTypes.DELETE_FLAG, p => p.requestId);
