import { UploadActionTypes, } from './types';
import { createAsyncMapAction } from '~lib/shared/redux/actions';
export const listByIdentifiersSelector = (req) => [
    String(req.userId),
    req.relatedIdentifiers.join(':'),
    req.purpose,
].join(':');
export const fileById = createAsyncMapAction(UploadActionTypes.FILE_BY_ID, p => p.fileId);
export const listByIdentifiers = createAsyncMapAction(UploadActionTypes.LIST_BY_IDENTIFIERS, listByIdentifiersSelector);
export const uploadFile = createAsyncMapAction(UploadActionTypes.UPLOAD_FILE, p => p.requestId);
export const createFileFlag = createAsyncMapAction(UploadActionTypes.CREATE_FILE_FLAG, p => p.id);
export const listFilesByUserId = createAsyncMapAction(UploadActionTypes.LIST_FILES_BY_USER_ID, p => p.userId);
