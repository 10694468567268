import React, { Children, cloneElement, isValidElement } from 'react';
import { Panel } from '../atoms/panel';
import TabPanel from './TabPanel';
import { useTabsContext } from './Tabs';
const TabPanels = (props) => {
    const { children } = props;
    const { selectedIndex } = useTabsContext();
    const updatedChildren = Children.map(children, (child, index) => {
        const item = child;
        if (!isValidElement(item))
            return null;
        if (item.type === TabPanel) {
            const key = item.key || index;
            const active = index === selectedIndex;
            return active ? cloneElement(item, { key }) : null;
        }
        return child;
    });
    return React.createElement(Panel, null, updatedChildren);
};
export default TabPanels;
