import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { confirmCancellation } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherConfirmCancellation() {
    yield takeEvery(LtmActionTypes.CONFIRM_CANCELLATION, workerConfirmCancellation);
}
function* workerConfirmCancellation({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        const response = yield call(api.confirmCancellation, client, payload);
        yield put(confirmCancellation.success(ident, response));
    }
    catch (error) {
        yield put(confirmCancellation.failure(ident, error));
        yield put(flashMessages.addError('Unable to cancel policy', 'There was an unknown issue cancelling the policy', Cher.coerce(error)));
    }
}
