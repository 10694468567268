import { call, getContext, put } from 'redux-saga/effects';
import { listLatestIncidents as listLatestIncidentsApi } from '../../api';
import { listLatestIncidents } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
// eslint-disable-next-line
export default createTakeEverySagaSet(listLatestIncidents.request, function* workerListLatestIncidents({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId } = payload;
    try {
        const response = yield call(listLatestIncidentsApi, client, userId);
        yield put(listLatestIncidents.success(ident, response));
    }
    catch (error) {
        yield put(listLatestIncidents.failure(ident, error));
    }
});
