import { all } from 'redux-saga/effects';
import clearIncidents from './clear-incidents';
import createIncident from './create-incident';
import listLatestIncidents from './list-latest-incidents';
import removeIncident from './remove-incident';
import updateIncident from './update-incident';
export default function* incidentSaga() {
    yield all([
        clearIncidents,
        createIncident,
        listLatestIncidents,
        removeIncident,
        updateIncident,
    ]);
}
