import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherSetVehicleProvider() {
    yield takeEvery(VehicleActionTypes.SET_VEHICLE_PROVIDER, workerSetVehicleProvider);
}
function* workerSetVehicleProvider({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield api.setVehicleProvider(client, {
            vrm: payload.vrm,
            provider: payload.provider,
            actor: payload.actor,
        });
        yield put(actions.setVehicleProvider.success(ident));
    }
    catch (error) {
        yield put(actions.setVehicleProvider.failure(ident, error));
    }
}
