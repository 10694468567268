import React, { useContext } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import InputDecoratorContext from '../context/decorator';
const SearchableInput = ({ value, options, ...props }) => {
    const { error } = useContext(InputDecoratorContext);
    return (React.createElement(SearchableInputContainer, { "$error": Boolean(error) },
        React.createElement(ReactSelect
        // the key here is a workaround to the previously set selection not reloading at times.
        , { 
            // the key here is a workaround to the previously set selection not reloading at times.
            key: [options.length, value].join(), classNamePrefix: 'Select', placeholder: 'Search...', defaultValue: options.find(o => o.value === value), blurInputOnSelect: true, options: options, ...props })));
};
export const SearchableInputContainer = styled.div `
	.Select__control {
		background: ${p => p.theme.ui.surface};

		transition: all 100ms;

		border: 1px solid ${p => p.theme.ui.borderInteractiveDefault};
		border-radius: ${p => p.theme.borderRadius.regular};

		padding: 12px;
		font-size: 16px;
		line-height: 22px;

		&:hover {
			border: 1px solid ${p => p.theme.ui.borderInteractiveHover};
		}

		&:focus {
			outline: none; /* Outline isn't implemented or respected very well across browsers */
			border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
			box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
		}

		&:focus:not(:focus-visible) {
			outline: none;
		}

		&:focus-within {
			border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
			box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
		}

		&:disabled {
			cursor: not-allowed;
			background: ${p => p.theme.ui.surfaceFillMuted};

			border: 1px solid ${p => p.theme.ui.borderInteractiveDisabled};

			color: ${p => p.theme.ui.textMinor};
			-webkit-text-fill-color: ${p => p.theme.ui.textMinor};
			opacity: 1;
		}

		${p => (p.$error ? `
			background: ${p.theme.ui.surface};
			border: 1px solid ${p.theme.ui.borderInteractiveDanger};
			box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveDanger};

			&:hover {
				border: 1px solid ${p.theme.ui.borderInteractiveDanger};
			}

			&:focus-within {
				border: 1px solid ${p.theme.ui.borderInteractiveFocus};
				box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveFocus};
			}
		` : '')};

		.Select__value-container {
			height: 24px;

			&, & > * {
				padding: 0;
				margin: 0;
			}
		}

		.Select__placeholder {
			color: ${p => p.theme.ui.textMinorMuted};
		}

		.Select__single-value {
			color: ${p => p.theme.ui.textOnSurfaceBackground};
		}

		.Select__indicator-separator {
			display: none;
		}

		.Select__indicators {
			display: none;
		}
	}

	.Select__control--is-focused {
		border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
	}

	.Select__menu {
		border-radius: ${p => p.theme.borderRadius.large};
		box-shadow: ${p => p.theme.boxShadow.small};

		.Select__menu-list {
			display: flex;
			flex-direction: column;
			margin: ${p => p.theme.spacing.small} 0;
			padding: 0;

			.Select__option {
				flex: 1;
				color: ${p => p.theme.ui.textOnSurfaceBackground};
				border-radius: ${p => p.theme.borderRadius.extraSmall};
				margin: 0 ${p => p.theme.spacing.small};
				padding: ${p => p.theme.spacing.regular} ${p => p.theme.spacing.small};
				width: auto;
				cursor: pointer;
			}

			.Select__option--is-focused, .Select__option:hover {
				background-color: ${p => p.theme.ui.surfaceFillMuted};
			}

			.Select__option--is-selected {
				background-color: ${p => p.theme.ui.surfaceFill};
			}
		}
	}
`;
export default SearchableInput;
