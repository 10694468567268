import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import Spinner from '~lib/frontend/design-system/components/spinner';

const SpinnerContainer = styled.div`
	height: 100px;
	margin-top: 5px;
`;

const Section = styled.section`
	margin-top: 150px;
	margin-bottom: 80px;

	@media (min-width: 768px) {
		margin-bottom: 320px;
	}
`;

const Loading = () => (
	<React.Fragment>
		<Helmet defer>
			<title>{'Loading | Cuvva'}</title>
			<meta name={'robots'} content={'noindex, follow'} />
		</Helmet>
		<Section className={'margin'}>
			<div className={'container cf'}>
				<SpinnerContainer>
					<Spinner $size={'40px'} />
				</SpinnerContainer>
			</div>
		</Section>
	</React.Fragment>
);

export default Loading;
