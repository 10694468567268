import { call, getContext, put } from 'redux-saga/effects';
import { createQuote } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(createQuote.request, function* createQuoteSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.createQuote, client, requestPayload);
        yield put(createQuote.success(ident, response));
    }
    catch (error) {
        yield put(createQuote.failure(ident, error));
    }
});
