import React, { useState } from 'react';
import styled from 'styled-components';

import LightningBolt from '../atoms/LightningBolt';
import { media } from '~lib/frontend/design-system/breakpoints';
import { TypedObject } from '~lib/shared/helpers/typed';

type Bolts = 'upper-left' | 'upper-right' | 'lower-left' | 'lower-right';
type BoltTracking = Record<Bolts, boolean>;

const defaultBoltTracking: BoltTracking = {
	'upper-left': false,
	'upper-right': false,
	'lower-left': false,
	'lower-right': false,
};

interface LightningBoltsProps {
	setEasterMode: (enable: boolean) => void;
}

const LightningBolts: React.FCWithChildren<LightningBoltsProps> = ({ setEasterMode }) => {
	const [bolts, setBolts] = useState<BoltTracking>({ ...defaultBoltTracking });

	function setBolt(key: Bolts) {
		const newBolts = { ...bolts, [key]: true };

		setBolts(newBolts);

		if (TypedObject.values(newBolts).some(b => b === false) === false)
			setEasterMode(true);
	}

	return (
		<Wrapper>
			<Positioner
				top={0}
				left={110}
				rotation={18}
				onClick={() => setBolt('upper-left')}
			>
				<LightningBolt color={'primaryFill'} />
			</Positioner>
			<Positioner
				top={0}
				right={110}
				rotation={10}
				onClick={() => setBolt('upper-right')}
			>
				<LightningBolt color={'warningFill'} />
			</Positioner>
			<Positioner
				top={80}
				left={55}
				rotation={-33}
				onClick={() => setBolt('lower-left')}
			>
				<LightningBolt color={'warningFill'} />
			</Positioner>
			<Positioner
				top={80}
				right={55}
				rotation={-33}
				onClick={() => setBolt('lower-right')}
			>
				<LightningBolt color={'primaryFill'} />
			</Positioner>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	width: 100%;

	${media.lessThan('tablet')`
		display: none;
	`}
`;

interface PositionerProps {
	top?: number;
	left?: number;
	right?: number;
	rotation: number;
}

const Positioner = styled.div<PositionerProps>`
	position: absolute;
	top: ${p => p.top ?? 0}px;
	${p => p.left === void 0 ? '' : `left: ${p.left}px;`}
	${p => p.right === void 0 ? '' : `right: ${p.right}px;`}

	transform: rotate(${p => p.rotation}deg);
`;

export default LightningBolts;
