import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

/** @deprecated */
export const TitleRegular = styled.div`
	font-weight: 700;
	font-size: 2.75rem;
	margin-bottom: 0;
	line-height: 1.27em;

	${media.lessThan('tablet')`
		font-size: 1.625rem;
		line-height: 1.307em;
	`}
`;

/** @deprecated */
export const BodyBold = styled.div`
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 30px;
`;

/** @deprecated */
export const BodySmall = styled.div`
	font-size: 1rem;
	font-weight: normal;
	line-height: 24px;
`;

/** @deprecated */
export const BodySmallBold = styled(BodySmall)`
	font-weight: 500;
`;
