import { all } from 'redux-saga/effects';
import confirmCancellation from './confirm-cancellation';
import getConfig from './get-config';
import quoteAutocycle from './quote-autocycle';
import quoteCancellation from './quote-cancellation';
export default function* ltmCancellationSaga() {
    yield all([
        getConfig,
        quoteCancellation,
        quoteAutocycle,
        confirmCancellation,
    ]);
}
