import { combineReducers } from 'redux';

import careers from './get-careers';

export {
	careers,
};

export default combineReducers({
	careers,
});
