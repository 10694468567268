import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';

const QuoteExpired: React.FunctionComponent = () => {
	const errorImage = useContentAsset('website_core', 'quote/error.png');
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.quote.expired.${key}`, fallback);

	return (
		<React.Fragment>
			<VibrantScreen>
				<VibrantScreenCard>
					<CenteredCardPricing>
						<img src={errorImage} alt={'crossed sign'} />

						<Typography
							$bold
							$marginTop={'24px'}
							$marginBottom={'large'}
							$type={'Heading.Medium'}
							$color={'textOnSurfaceBackground'}
						>
							{get('title', 'Your quote expired')}
						</Typography>

						<Typography
							$marginTop={'24px'}
							$marginBottom={'extraLarge'}
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackgroundMuted'}
						>
							{get('body', 'Let’s get you another one ASAP — but keep in mind we can’t guarantee your price will be the same')}
						</Typography>

						<Button
							as={Anchor}
							// @ts-ignore
							to={'/vehicles'}
							$type={'primary'}
							$size={'large'}
							$stretch
						>
							{'Start a new quote'}
						</Button>
					</CenteredCardPricing>
				</VibrantScreenCard>
			</VibrantScreen>
			<Footer landingPage />
		</React.Fragment>
	);
};

const CenteredCardPricing = styled.div`
	text-align: center;
`;

export default QuoteExpired;
