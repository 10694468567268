import styled from 'styled-components';
export const CounterBadge = styled.div `
	display: inline-block;
	background: ${props => props.theme.legacy.tertiary};
	color: ${props => props.theme.legacy.blank};
	border-radius: .5rem;
	font-size: .5rem;
	line-height: .8rem;
	min-width: 1.2rem;
`;
