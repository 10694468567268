import styled from 'styled-components';

export const List = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ListItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};

	& + & {
		border-top: 1px solid ${p => p.theme.ui.borderSeparator};
	}

	& > * {
		flex: 0 0 auto;
	}
`;

export const ListItemLabel = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraSmall};
	flex: 1;
	margin: ${p => p.theme.spacing.large} 0;
`;
