export default function useAsyncStateStatus(state) {
    if (!state)
        return createStatus('pending');
    const fetching = state.fetching;
    const error = state.error !== void 0;
    const response = state.response !== void 0;
    // If nothing, show pending
    // If fetching but has no response, show fetching
    // If fetching but no response and no error, show fetching
    // If error, show error
    if (!fetching && !response && !error)
        return createStatus('pending');
    if (fetching && !response)
        return createStatus('fetching');
    if (fetching && error)
        return createStatus('fetching');
    if (error)
        return createStatus('error');
    const meta = {
        empty: false,
    };
    if (state.response === null)
        meta.empty = true;
    else if (Array.isArray(state.response))
        meta.empty = state.response.length === 0;
    else if (typeof state.response === 'object' && Object.keys(state.response).length === 0)
        meta.empty = true;
    return createStatus('response', meta);
}
function createStatus(status, meta) {
    return {
        status,
        is: (...statuses) => statuses.some(s => s === status),
        meta: status === 'response' ? meta : void 0,
    };
}
