import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import Footer from '~website/components/layout/footer';
import Header from '~website/components/layout/header';
import { trackScreenView } from '~website/features/analytics/store/actions';
import Builder from '~website/features/builder';
import Page404 from '~website/pages/NotFound';

const PageBuilderRenderer: React.FunctionComponent = React.memo(() => {
	const g = useContentKey('website_core');
	const location = useLocation();
	const dispatch = useDispatch();

	const builderSegment = useMemo(() => (g('builder') as unknown) as Record<string, Record<string, unknown>>, [g]);
	const page = useMemo(() => Object.keys(builderSegment).
		find(c => builderSegment[c].url === location.pathname), [builderSegment, location.pathname]);

	useEffect(() => {
		if (page === 'home') {
			const eventName = `none_screenView_${page}`;
			const eventPayload = { screenName: page, platform: 'website' };

			dispatch(trackScreenView({ eventName, eventPayload }));
		}
	}, [dispatch, page]);

	const get: GetterType = useCallback((path, fallback) => g(`builder[${page}].${path}`, fallback), [g, page]);

	if (!page) {
		return (
			<React.Fragment>
				<Header />
				<Page404 />
				<Footer />
			</React.Fragment>
		);
	}

	return <Builder get={get} />;
});

export default PageBuilderRenderer;
