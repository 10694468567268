import { useCallback, useState } from 'react';

import { Location } from '~lib/platform/ltm/types';

const useCurrentLocation = (options = {}) => {
	const [location, setLocation] = useState<Location>();
	const [error, setError] = useState<GeolocationPositionError>();
	const [fetching, setFetching] = useState(false);

	const doSearch = useCallback(() => {
		setFetching(true);

		const { geolocation } = navigator;

		geolocation.getCurrentPosition(({ coords }) => {
			setLocation({
				latitude: coords.latitude,
				longitude: coords.longitude,
			});
			setFetching(false);
		}, e => {
			setError(e);
			setFetching(false);
		}, options);
	}, [options]);

	return {
		doSearch,
		result: location,
		error,
		fetching,
	};
};

export default useCurrentLocation;
