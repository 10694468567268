import { call, getContext, put } from 'redux-saga/effects';
import { userById } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(userById.request, function* workerUserById({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.getUser, client, payload);
        yield put(userById.success(ident, response));
    }
    catch (error) {
        yield put(userById.failure(ident, error));
    }
});
