import React from 'react';

import InspirationCard from './atoms/InspirationCard';
import LackingInspirationGrid from './atoms/LackingInspirationGrid';
import LackingInspirationSection from './atoms/LackingInspirationSection';
import Typography from '~lib/frontend/design-system/components/Typography';
import { useDesignSystemAssets } from '~lib/frontend/hooks/use-design-system-asset';
import { LinkButton } from '~website/components/atoms/Button';

interface LackingInspirationItem {
	illustration: string;
	title: string;
	body: string;
}

interface LackingInspirationProps {
	title: string;
	items: LackingInspirationItem[];
	cta?: {
		url: string;
		text: string;
	};
}

const safeLength = (obj: unknown[] | string) => Array.from({ length: Array.isArray(obj) ? obj.length : 4 });

const LackingInspiration: React.FCWithChildren<LackingInspirationProps> = ({ title, items, cta }) => {
	const [, getAsset] = useDesignSystemAssets();

	return (
		<LackingInspirationSection>
			<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>{title}</Typography>
			<LackingInspirationGrid>
				{items.map((item, i) => (
					<InspirationCard key={i}>
						<img src={getAsset(`illustrations/${item.illustration}`)} />
						<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
							{item.title}
						</Typography>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{item.body}
						</Typography>
					</InspirationCard>
				))}
			</LackingInspirationGrid>
			{cta && (
				<LinkButton href={cta.url}>{cta.text}</LinkButton>
			)}
		</LackingInspirationSection>
	);
};

type GetterType = (key: string, fallback?: string) => string;

export const contentAdapter = (get: GetterType): LackingInspirationProps => {
	const itemsIterator = safeLength(get('items'));
	const items = itemsIterator.map((_, i) => ({
		illustration: get(`items[${i}].illustration`),
		title: get(`items[${i}].title`),
		body: get(`items[${i}].body`),
	}));
	const hasCTA = Boolean(get('cta'));
	const cta = {
		url: get('cta.url'),
		text: get('cta.text'),
	};

	return {
		title: get('title'),
		items,
		cta: hasCTA ? cta : void 0,
	};
};

export default LackingInspiration;
