import CuvvaClient from '@cuvva/cuvva-client';
import { getContext, put } from 'redux-saga/effects';

import * as actions from '../actions';
import * as api from '../api';
import { SetRenewalDateResponse } from '../types';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.setRenewalDate.request, function* setRenewalDateSaga(action) {
	const { ident } = action.meta;

	const client: CuvvaClient = yield getContext('api');

	try {
		const response: SetRenewalDateResponse = yield api.setRenewalDate(client, action.payload);

		yield put(actions.setRenewalDate.success(ident, response));
	} catch (error) {
		yield put(actions.setRenewalDate.failure(ident, error));
	}
});
