import { call, getContext, put } from 'redux-saga/effects';
import { createChangeRequest } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
// eslint-disable-next-line max-len
export default createTakeEverySagaSet(createChangeRequest.request, function* workerCreateChangeRequest({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.createChangeRequest, client, {
            createdBy: payload.createdBy,
            products: payload.products,
            profile: payload.profile,
            userId: payload.userId,
            vehicles: payload.vehicles,
            vehicleProfiles: payload.vehicleProfiles,
        });
        yield put(createChangeRequest.success(ident, response));
    }
    catch (error) {
        yield put(createChangeRequest.failure(ident, error));
    }
});
