import { useEffect, useState } from 'react';
export const useMediaQuery = (query) => {
    const [match, setMatch] = useState(false);
    useEffect(() => {
        if (!window || !window.matchMedia)
            return void 0;
        const updateMatch = () => setMatch(window.matchMedia(query).matches);
        updateMatch();
        const media = window.matchMedia(query);
        if (media.addEventListener)
            media.addEventListener('change', updateMatch);
        // eslint-disable-next-line deprecation/deprecation
        else if (media.addListener)
            // eslint-disable-next-line deprecation/deprecation
            media.addListener(updateMatch);
        return () => {
            if (media.removeEventListener)
                media.removeEventListener('change', updateMatch);
            // eslint-disable-next-line deprecation/deprecation
            else if (media.removeListener)
                // eslint-disable-next-line deprecation/deprecation
                media.removeListener(updateMatch);
        };
    }, [query]);
    return match;
};
export default useMediaQuery;
