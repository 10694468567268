import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { getCarClub, removeCarClubMember } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(removeCarClubMember.request, function* removeCarClubMemberSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.removeCarClubMember, client, payload);
        yield put(removeCarClubMember.success(ident, response));
        yield put(getCarClub.request({ carClubId: payload.carClubId }));
    }
    catch (error) {
        yield put(removeCarClubMember.failure(ident, error));
    }
});
