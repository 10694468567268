import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

interface GaugeProps{
	value: string;
	percentage: number;
}

const Gauge: React.FCWithChildren<GaugeProps> = ({ value, percentage }) => (
	<GuageContainer>
		<Inner></Inner>
		<ProgressText>
			<Typography
				$type={'Heading.Medium'}
				$color={'textOnSurfaceBackground'}
				$marginTop={'small'}
			>
				{value}
			</Typography>
		</ProgressText>
		<Circle>
			<ProgressContainer $percentage={percentage}>
				<Progress $percentage={percentage}></Progress>
			</ProgressContainer>
		</Circle>
	</GuageContainer>
);

const GuageContainer = styled.div`
	height: 80px;
	width: 80px;
	position: relative;
`;

const Inner = styled.div`
	position: absolute;
	z-index: 6;
	top: 50%;
	left: 50%;
	height: 64px;
	width: 64px;
	margin: -32px 0 0 -32px;
	background: ${p => p.theme.ui.surface};
	border-radius: 100%;
`;
const ProgressText = styled.div`
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	z-index:10;
`;
const Circle = styled.div``;
const ProgressContainer = styled.div<{ $percentage: number }>`
	position: absolute;
	height: 100%;
	width: 100%;
	background: ${p => p.$percentage > 30 ? p.theme.brand.yellow400 : p.theme.ui.successFill};
	border-radius: 100%;
	clip: rect(0px, 80px, 80px, 0px);
`;

const Progress = styled.div<{ $percentage: number }>`
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 100%;
	${p => `clip: rect(0px, 30px, ${p.$percentage}px, 0px);`}
	background: ${p => p.theme.ui.borderSeparator};
`;

export default Gauge;
