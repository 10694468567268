import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import TimeOwned from './TimeOwned';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { getAbiCodesOrDefault } from '~website/helpers/quick-quote';

const options = [
	{ value: true, display: 'Yes' },
	{ value: false, display: 'No' },
];

const notEmptyOwnerValidator = (value: string) => {
	if (!value)
		return new Cher('invalid');

	return void 0;
};

const VehicleOwner: React.FunctionComponent = () => {
	const abiCodes = useSelector(s => s.platform.codeMapping.abiCodes);
	const g = useContentKey('strings');

	const { value, validation, onChange: onSetOwnerType, setFieldError } = useVehicleProfileInternalChangeRequest(
		'owner',
		'type',
		notEmptyOwnerValidator,
	);

	const error = useOnPageError(validation, 'vehicle_ownership_input');

	const [policyholderOwnsVehicle, setPolicyholderOwnsVehicle] = useState<boolean>(void 0);
	const displaySecondQuestion = policyholderOwnsVehicle !== void 0 && !policyholderOwnsVehicle;
	const displayThirdQuestion = policyholderOwnsVehicle !== void 0 && policyholderOwnsVehicle;

	useEffect(() => {
		if (value === void 0)
			return;

		setPolicyholderOwnsVehicle(value === 'policyholder');
	}, [value]);

	const ownerCodes = getAbiCodesOrDefault(abiCodes).ownerCodes
		?.filter(({ code }) => code !== 'policyholder')
		?.map(
			({ code, sentenceForm }) => ({
				label: sentenceForm,
				value: code,
			}));

	const handlePolicyholderOwnsVehicle = (val: boolean) => {
		onSetOwnerType(val ? 'policyholder' : void 0);
		setPolicyholderOwnsVehicle(val);

		// SC: This is unfortunate, but this field isn't a normal field (chained radio button + dropdown)
		// for the same field, so we need a way to set an error when a "no" is selected
		// as the chained "get" inside the hook will inevitably fall back on the server known value.
		if (!val) {
			setFieldError(new Cher('invalid'));

			return;
		}
	};

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={'Do you (or will you) own the car?'}
				error={policyholderOwnsVehicle === void 0 || !displaySecondQuestion ? error : void 0}
			>
				<AlignDoubleColChildren>
					{options.map(o => (
						<RadioInput
							key={o.display}
							name={'vehicleOwner'}
							checked={o.value === policyholderOwnsVehicle}
							value={o.display}
							onChange={() => handlePolicyholderOwnsVehicle(o.value)}
							data-event-onchange-name={'vehicle_owner_selected'}
						/>
					))}
				</AlignDoubleColChildren>
			</QuoteInputDecorator>

			{displaySecondQuestion && (
				<QuoteInputDecorator
					label={g('motor.long_term.questions.vehicle.owner.question', 'Who owns (or will own) the car?')}
					error={error}
				>
					<TrackedSelectInput
						value={value}
						options={ownerCodes}
						placeholder={'Choose an option'}
						onChange={change => onSetOwnerType(change.value)}
						data-event-onfocus-name={'dropdown_selected'}
						data-event-onfocus-value={'vehicle_ownership'}
						data-event-onchange-name={'vehicle_ownership_input_selected'}
					/>
				</QuoteInputDecorator>
			)}

			{displayThirdQuestion && <TimeOwned />}
		</React.Fragment>
	);
};

export default VehicleOwner;
