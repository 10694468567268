import { combineReducers } from 'redux';

import analyticsTrackingDebug from './analytics-tracking-debug';
import sourceTracking from './source-tracking';

export {
	analyticsTrackingDebug,
	sourceTracking,
};

export default combineReducers({
	analyticsTrackingDebug,
	sourceTracking,
});
