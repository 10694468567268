import styled from 'styled-components';
import Text from './Text';
const Select = styled(Text).attrs(_ => ({ as: 'select' })) `
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iIzk5OTlBOCIgZD0iTTEyIDUuODNsMi40NiAyLjQ2YTEgMSAwIDEwMS40MS0xLjRMMTIuNyAzLjdhMSAxIDAgMDAtMS40MSAwTDguMTIgNi44OEExIDEgMCAxMDkuNTMgOC4zTDEyIDUuODN6bTAgMTIuMzRsLTIuNDYtMi40NmExIDEgMCAxMC0xLjQxIDEuNDFsMy4xNyAzLjE4YTEgMSAwIDAwMS40MSAwbDMuMTctMy4xN2ExIDEgMCAxMC0xLjQxLTEuNEwxMiAxOC4xNnoiLz4KPC9zdmc+") no-repeat ${p => p.disabled ? `, ${p.theme.ui.surfaceFillMuted};` : ''};
	background-position: calc(100% - 12px) 50%;
	background-size: 24px 24px;
	-moz-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
`;
export default Select;
