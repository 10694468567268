import styled, { css } from 'styled-components';
import BadgeSC from '../../badge/atoms/container';
import { getBadgeColors } from '../../badge/helpers';
import Typography from '../../Typography';
const radioStates = {
    unchecked: {
        default: '<circle cx="12" cy="12" r="10" fill="#fff"/><circle cx="12" cy="12" r="9.5" stroke="#C4C4CF"/>',
        disabled: '<circle cx="12" cy="12" r="10" fill="#F2F2F4"/><circle cx="12" cy="12" r="9.5" stroke="#D8D8DF"/>',
        error: '<circle cx="12" cy="12" r="9" fill="#fff" stroke="#E72E50" stroke-width="2"/>',
        focus: '<circle cx="12" cy="12" r="9" fill="#fff" stroke="#6544E2" stroke-width="2"/>',
        hover: '<circle cx="12" cy="12" r="10" fill="#fff"/><circle cx="12" cy="12" r="9.5" stroke="#050224"/>',
    },
    checked: {
        default: '<circle cx="12" cy="12" r="10" fill="#6544E2"/><circle cx="12" cy="12" r="4" fill="#fff"/>',
        disabled: '<circle cx="12" cy="12" r="10" fill="#C4C4CF"/><circle cx="12" cy="12" r="9.5" stroke="#D8D8DF"/><circle cx="12" cy="12" r="4" fill="#fff"/>',
        error: '<circle cx="12" cy="12" r="10" fill="#D7193C"/><circle cx="12" cy="12" r="4" fill="#fff"/>',
        focus: '<circle cx="12" cy="12" r="11" fill="#6544E2" stroke="#BBB7FB" stroke-width="2"/><circle cx="12" cy="12" r="4" fill="#fff"/>',
        hover: '<circle cx="12" cy="12" r="10" fill="#6544E2"/><circle cx="12" cy="12" r="9.5" stroke="#050224"/><circle cx="12" cy="12" r="4" fill="#fff"/>',
    },
};
const getSvg = (checked, state) => (`url('data:image/svg+xml,${encodeURIComponent(`<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">${radioStates[checked][state]}</svg>`)}')`);
export const RadioInputItemWrapInnerSC = styled.span `
	display: flex;
	padding-right: ${p => p.theme.spacing.small};

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	span {
		display: grid;
		place-items: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid;
		border-color: ${p => p.$checked ? `${p.theme.ui.borderInteractiveSelected}` : `${p.theme.ui.borderInteractiveDefault}`};
		background: ${p => p.$checked ? `${p.theme.ui.primaryAction}` : `${p.theme.ui.surface}`};
	}

	input + span::before {
		content: "";
		width: 0.5em;
		height: 0.5em;
		box-shadow: inset 0.5em 0.5em ${p => p.theme.ui.surface};
		border-radius: 50%;
		transition: all 100ms ease-in-out;
		opacity: 0;
	}

	input:checked + span::before {
		opacity: 1;
	}
`;
export const RadioInputItemWrapOuterSC = styled.label `
	*, *:before, *:after {
		box-sizing: border-box;
	}

	display: flex;
	flex: 1 0 0;
	flex-direction: row;
	align-items: center;
	padding: ${p => p.theme.spacing.regular};

	background: ${p => p.$checked ? p.theme.ui.primaryActionMuted : p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.regular};

	box-shadow: inset 0px 0px 0px 1px ${p => p.$checked ? `${p.theme.ui.borderInteractiveSelected}` : `${p.theme.ui.borderInteractiveDefault}`};

	transition: all 100ms ease-in-out;

	&:hover {
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveHover};
	}

	&:focus-within {
		box-shadow: inset 0px 0px 0px 2px ${p => p.theme.ui.primaryFill};
	}

	${p => p.$error ? `
		background: ${p.theme.ui.surface};
		box-shadow: inset 0px 0px 0px 2px ${p.theme.ui.borderInteractiveDanger};

		&:hover {
			box-shadow: inset 0px 0px 0px 2px ${p.theme.ui.borderInteractiveDanger};
		}

		&:focus-within {
			box-shadow: inset 0px 0px 0px 2px ${p.theme.ui.borderInteractiveFocus};
		}

		${RadioInputItemWrapInnerSC} {
			span {
				background: ${p.theme.ui.surface};
				border: ${`1px solid ${p.theme.ui.borderInteractiveDefault}`};
			}
		}
	` : ''};

	${p => p.$disabled && `
		cursor: not-allowed;
		background: ${p.theme.ui.surfaceFillMuted};
		box-shadow: 0px 0px 0px 1px ${p.theme.ui.borderInteractiveDisabled};

		&:hover {
			box-shadow: 0px 0px 0px 1px ${p.theme.ui.borderInteractiveDisabled};
		}

		${RadioInputItemWrapInnerSC} {
			span {
				background: ${p.$checked ? p.theme.ui.neutralFillMinorMuted : p.theme.ui.surface};
				border: ${p.$checked ? `2px solid ${p.theme.ui.borderInteractiveDisabled}` : `1px solid ${p.theme.ui.borderInteractiveDefault}`};
			}
		}
	`};
`;
export const RadioCardWrapper = styled.button `
	width: 100%;
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	border-radius: ${p => p.theme.borderRadius.regular};
	margin-bottom: ${p => p.theme.spacing.large};
	padding: ${p => `calc(1px + ${p.theme.spacing.large})`};
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	margin-bottom: ${p => p.theme.spacing.regular};
	background: none;
	outline: none;
	text-align: left;

	:hover, :focus, :focus-within {
		padding: ${p => p.theme.spacing.large};
		border: 2px solid ${p => p.theme.ui.borderInteractiveFocus};
	}

	input {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}

	${p => p.$checked && css `
		padding: ${p.theme.spacing.large};
		border: 2px solid ${p.theme.ui.borderInteractiveFocus};
		background-color: ${p.theme.ui.primaryFillMuted};
	`}

	${p => p.$disabled && css `
		opacity: .6;
		cursor: not-allowed;
	`};

	${p => p.$noBorder && css `
		border: 1px solid transparent;
		margin-bottom: 0;
		border-radius: 0;

		:hover, :focus, :focus-within {
			background-color: ${p.theme.ui.surfaceFillMuted};
			border: 2px solid transparent;

			${RadioCardBadges} {
				display: flex;
			}
		}
		${p.$checked && css `
			background-color: ${p.theme.ui.primaryFill};
			border: 2px solid transparent;
			${Typography} {
				color: ${p.theme.ui.textOnFill};
			}

			${RadioCardBadges} {
				display: flex;
			}

			:hover, :focus, :focus-within {
				border: 2px solid transparent;
				background-color: ${p.theme.ui.primaryFill};
			}
		`};
	`};
`;
export const RadioCardWithBadgeWrapper = styled.div `
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};
`;
export const RadioCardBadges = styled.div `
	display: ${p => p.$hideBadgesUntilHover ? 'none' : 'flex'};
	justify-content: flex-end;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};
	flex-wrap: wrap;
	max-width: 60%;
`;
export const RadioBadgeWrapper = styled.button `
	box-sizing: border-box;
	display: inline-flex;
	justify-content: flex-start;
	position: relative;
	cursor: pointer;
	margin-right: ${p => p.theme.spacing.regular};
	margin-bottom: ${p => p.theme.spacing.regular};
	background: none;
	border-radius: ${p => p.theme.borderRadius.extraLarge};
	padding: 0;
	border: none;
	outline: none;

	&:focus, &:hover {
		border: none;
		outline: none;

		 ${p => !p.$checked && css `
			${BadgeSC} {
				background-color: ${p => getBadgeColors('primary', true, p.theme.ui).background};
				color: ${p => getBadgeColors('primary', true, p.theme.ui).text};
			}
		`}
	}

	input {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
	${p => p.$disabled && css `
		opacity: 0.6;
		cursor: not-allowed;
	`};
`;
export const Radio = styled.input.attrs(_ => ({ type: 'radio' })) `
	position: relative;
	cursor: pointer;
	background: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
	width: 24px;
	height: 24px;
	display: inline-block;
	appearance: none;
	transition: background-image 200ms ease-in-out;

	background-image: ${getSvg('unchecked', 'default')};
	&:hover { background-image: ${getSvg('unchecked', 'hover')}; }
	&:focus { background-image: ${getSvg('unchecked', 'focus')}; }

	&[disabled] {
		background-image: ${getSvg('unchecked', 'disabled')};
		cursor: not-allowed;
	}

	${p => p.$error && css `background-image: ${getSvg('unchecked', 'error')};`};

	&:checked {
		background-image: ${getSvg('checked', 'default')};
		&:hover { background-image: ${getSvg('checked', 'hover')}; }
		&:focus { background-image: ${getSvg('checked', 'focus')}; }
		&[disabled] { background-image: ${getSvg('checked', 'disabled')}; }

		${p => p.$error && css `background-image: ${getSvg('checked', 'error')};`};
	}
`;
