import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { createQuote } from '../actions';
import { MotorCoverageActionTypes } from '../types';
export default function* watcherCreteQuote() {
    yield takeEvery(MotorCoverageActionTypes.CREATE_QUOTE, workerCreteQuote);
}
function* workerCreteQuote({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId, location, reason, vehicleId } = payload;
    try {
        const payload = { userId, location, reason, vehicleId };
        const response = yield call([client, client.rest], 'service-motor-coverage', 'post', '1/quotes', null, payload);
        yield put(createQuote.success(ident, response));
    }
    catch (error) {
        yield put(createQuote.failure(ident, error));
    }
}
