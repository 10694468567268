import { SocialDrivingActionTypes } from './types';
import { createAsyncMapAction } from '~lib/shared/redux/actions';
export const getSharedQuote = createAsyncMapAction(SocialDrivingActionTypes.GET_SHARED_QUOTE, p => p.key);
export const sharedQuoteLiveLocation = createAsyncMapAction(SocialDrivingActionTypes.SHARED_QUOTE_LIVE_LOCATION, p => p.key);
export const getVehicles = createAsyncMapAction(SocialDrivingActionTypes.GET_VEHICLES, p => p.requestId);
export const getLender = createAsyncMapAction(SocialDrivingActionTypes.GET_LENDER, p => p.lenderId);
export const getBorrower = createAsyncMapAction(SocialDrivingActionTypes.GET_BORROWERS, p => p.borrowerId);
export const removeVehicleLender = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_VEHICLE_LENDER, p => p.lenderId);
export const removeVehicleBorrowers = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_VEHICLE_BORROWERS, p => p.vehicleId);
export const getVehicleRates = createAsyncMapAction(SocialDrivingActionTypes.GET_VEHICLE_RATES, p => p.vehicleId);
export const listBorrowerPolicies = createAsyncMapAction(SocialDrivingActionTypes.LIST_BORROWER_POLICIES, p => `${p.requestId}`);
export const getLenderAccountStatus = createAsyncMapAction(SocialDrivingActionTypes.GET_LENDER_ACCOUNT_STATUS, p => p.lenderId);
export const getVehicleAvailability = createAsyncMapAction(SocialDrivingActionTypes.GET_VEHICLE_AVAILABILITY, p => p.vehicleId);
export const removeVehicleAvailability = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_VEHICLE_AVAILABILITY, p => p.vehicleId);
export const listUserContentRevisions = createAsyncMapAction(SocialDrivingActionTypes.LIST_USER_CONTENT_REVISIONS, p => p.vehicleId);
export const markUserContentInappropriate = createAsyncMapAction(SocialDrivingActionTypes.MARK_USER_CONTENT_INAPPROPRIATE, p => p.requestId);
export const listUserCarClubs = createAsyncMapAction(SocialDrivingActionTypes.LIST_USER_CAR_CLUBS, p => p.userId);
export const listVehicleCarClubs = createAsyncMapAction(SocialDrivingActionTypes.LIST_VEHICLE_CAR_CLUBS, p => p.vehicleId);
export const deleteCarClub = createAsyncMapAction(SocialDrivingActionTypes.DELETE_CAR_CLUB, p => p.carClubId);
export const removeCarClubMember = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_CAR_CLUB_MEMBER, p => p.userId);
export const removeCarClubVehicle = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_CAR_CLUB_VEHICLE, p => p.vehicleId);
export const getCarClub = createAsyncMapAction(SocialDrivingActionTypes.GET_CAR_CLUB, p => p.carClubId);
export const updateCarClubName = createAsyncMapAction(SocialDrivingActionTypes.UPDATE_CAR_CLUB_NAME, p => p.requestId);
export const setCarClubVisibility = createAsyncMapAction(SocialDrivingActionTypes.SET_CAR_CLUB_VISIBILITY, p => p.carClubId);
export const listVehiclePhotos = createAsyncMapAction(SocialDrivingActionTypes.LIST_VEHICLE_PHOTOS, p => p.requestId);
export const removeVehiclePhotos = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_VEHICLE_PHOTOS, p => p.vehicleId);
export const getVehicleLocation = createAsyncMapAction(SocialDrivingActionTypes.GET_VEHICLE_LOCATION, p => p.vehicleId);
export const removeVehicleLocation = createAsyncMapAction(SocialDrivingActionTypes.REMOVE_VEHICLE_LOCATION, p => p.vehicleId);
export const listTripRequests = createAsyncMapAction(SocialDrivingActionTypes.LIST_TRIP_REQUESTS, p => p.requestId);
