import { call, getContext, put } from 'redux-saga/effects';
import { listIntentsByUser } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(listIntentsByUser.request, function* listIntentsByUserSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.listIntentsByUser, client, payload);
        yield put(listIntentsByUser.success(ident, response));
    }
    catch (error) {
        yield put(listIntentsByUser.failure(ident, error));
    }
});
