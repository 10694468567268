import React from 'react';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useNotEmptyStorageValidator from '../../hooks/use-not-empty-storage-validate';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { getAbiCodesOrDefault } from '~website/helpers/quick-quote';

const StorageType: React.FunctionComponent = () => {
	const g = useContentKey('strings');

	const { value, validation, onChange } = useVehicleProfileInternalChangeRequest(
		'storage',
		'type',
		useNotEmptyStorageValidator,
	);
	const error = useOnPageError(validation, 'vehicle_location_input');
	const abiCodes = useSelector(s => s.platform.codeMapping.abiCodes);
	const locationCodes = getAbiCodesOrDefault(abiCodes).locationCodes
		?.map(({ code, sentenceForm }) => ({
			label: sentenceCase(sentenceForm),
			value: code,
		}));

	return (
		<QuoteInputDecorator
			label={g('motor.long_term.questions.vehicle.storage.question', 'Where is the car kept at night?')}
			error={error}
		>
			<TrackedSelectInput
				value={value}
				options={locationCodes}
				placeholder={'Choose an option'}
				onChange={change => onChange(change.value)}
				data-event-onfocus-name={'dropdown_selected'}
				data-event-onfocus-value={'vehicle_location'}
				data-event-onchange-name={'vehicle_location_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default StorageType;
