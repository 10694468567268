import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BroadcastChannel } from 'broadcast-channel';

import useUser from './use-user';
import { clearAuthUser, importAuthedUser } from '~website/store/auth/actions';

const useAuthCover = (): void => {
	const dispatch = useDispatch();

	const { namedUser, userLoading } = useUser();

	const postMessage = useCallback((message: string) => {
		const bc = new BroadcastChannel('cuvva-auth-check');

		bc.postMessage(message);
		bc.close();
	}, []);

	const handleMessage = useCallback(
		(e: string) => {
			const message = e;

			if (message === 'authenticated-user' && !namedUser)
				dispatch(importAuthedUser.request());

			if (message === 'unauthenticated-user' && namedUser)
				dispatch(clearAuthUser());
		},
		[dispatch, namedUser],
	);

	useEffect(() => {
		if (!userLoading) {
			if (namedUser)
				postMessage('authenticated-user');
			else
				postMessage('unauthenticated-user');
		}
	}, [postMessage, userLoading, namedUser]);

	useEffect(() => {
		const bc = new BroadcastChannel('cuvva-auth-check');

		bc.onmessage = handleMessage;

		return () => {
			bc.close();
		};
	}, [handleMessage]);

	return;
};

export default useAuthCover;
