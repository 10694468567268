import { combineReducers } from 'redux';
import { ChangeRequestActionTypes, initialChangeRequestState } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
export const createChangeRequest = createAsyncMapReducer(ChangeRequestActionTypes.CREATE_CHANGE_REQUEST, initialChangeRequestState.createChangeRequest);
export const confirmChangeRequest = createAsyncMapReducer(ChangeRequestActionTypes.CONFIRM_CHANGE_REQUEST, initialChangeRequestState.confirmChangeRequest);
export const discardChangeRequest = createAsyncMapReducer(ChangeRequestActionTypes.DISCARD_CHANGE_REQUEST, initialChangeRequestState.discardChangeRequest);
export const getChangeRequest = createAsyncMapReducer(ChangeRequestActionTypes.GET_CHANGE_REQUEST, initialChangeRequestState.getChangeRequest);
export const getChangeRequestInProgress = createAsyncMapReducer(ChangeRequestActionTypes.GET_CHANGE_REQUEST_IN_PROGRESS, initialChangeRequestState.getChangeRequestInProgress);
export default combineReducers({
    confirmChangeRequest,
    createChangeRequest,
    discardChangeRequest,
    getChangeRequest,
    getChangeRequestInProgress,
});
