import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listByIdentifiers } from '../actions';
import { UploadActionTypes } from '../types';
import { profileById } from '~lib/platform/profile/store/actions';
export default function* watcherListByIdentifiers() {
    yield takeEvery(UploadActionTypes.LIST_BY_IDENTIFIERS, workerListByIdentifiers);
}
function* workerListByIdentifiers({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId, relatedIdentifiers, purpose, urlTtl, includeHidden, preview, page } = payload;
    const usePreview = preview || preview === void 0;
    try {
        const response = yield call([client, client.rpc], 'service-upload', '2/2020-08-25/list_files_by_identifiers_paged', { userId, relatedIdentifiers, purpose, urlTtl, includeHidden, preview: usePreview, page });
        const distinct = Array.from(new Set(response.results.map(r => r.userId)));
        yield put(listByIdentifiers.success(ident, response));
        yield all(distinct.map(id => profileById.request({ userId: id })));
    }
    catch (error) {
        yield put(listByIdentifiers.failure(ident, error));
    }
}
