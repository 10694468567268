import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import { generate, Sex } from 'dln';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import { PageCtx } from '../../contexts/PageCtx';
import useDLRInternalChangeRequest from '../../hooks/use-dln-internal-change-request';
import useLockedDetails from '../../hooks/use-locked-details';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import DLNDialogHint from '../molecules/dialogs/DLNDialogHint';
import DlnAlreadyExists from '../molecules/DlnAlreadyExistsModal';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import PrefilledInput from '~lib/frontend/design-system/components/input/molecules/PrefilledInput';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { trackEvent, trackQuoteEvent } from '~website/features/analytics/store/actions';

interface UserProfile {
	personalName: string;
	familyName: string;
	birthDate: string;
	sex: string;
}

function computeUserFromStepData(userProfile: UserProfile) {
	const familyName = userProfile?.familyName;
	const personalName = userProfile?.personalName;
	const birthDate = userProfile?.birthDate;
	const sex = userProfile?.sex as Sex;

	if (!familyName || !personalName || birthDate.length < 10 || !sex)
		return null;

	return {
		familyName,
		personalName,
		birthDate,
		sex,
	};
}

function getComputedDLN(userProfile: UserProfile): string | null {
	const user = computeUserFromStepData(userProfile);

	if (!user)
		return null;

	const generated = generate(user);

	if (generated && generated.length > 0)
		return generated.slice(0, 12);

	return null;
}

const checkDlnRegexError = (error: Cher) => {
	if (!error)
		return false;

	if (error.code !== 'bad_request')
		return false;

	const schemaFailure = error.reasons?.find(r => r.code === 'schema_failure');

	if (!schemaFailure)
		return false;

	const dln = schemaFailure.meta?.field === 'dln';
	const pattern = (schemaFailure.meta?.message as string)?.includes('pattern');

	return Boolean(dln && pattern);
};

const helpfulErrorMessage = (error: string, regexFailing: boolean) => {
	if (error && regexFailing)
		return 'Double check the last 4 digits match your license';

	return error;
};

const knownDlnError = ['dln_already_exists_email_found', 'already_exists'];

const DLN: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const g = useContentKey('strings');

	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const pageFetching = page.fetching;
	const underlyingError = page?.fields?.dln?.error as Cher;
	const newFlag = useConfigFlag('login_account_website');
	const [alreadyAttachedModal, setAlreadyAttachedModal] = useState(false);
	const regexFailing = checkDlnRegexError(underlyingError);

	const { value, validation, onChange } = useDLRInternalChangeRequest('dln', notEmptyValidator);
	const [dln, setDLN] = useState<string>('');
	const { value: personalName } = useProfileInternalChangeRequest('personalName');
	const { value: familyName } = useProfileInternalChangeRequest('familyName');
	const { value: birthDate } = useProfileInternalChangeRequest('birthDate');
	const { value: sex } = useProfileInternalChangeRequest('sex');
	const error = useOnPageError(validation, 'driving_license_input');
	const locked = useLockedDetails();

	const userId = useSelector(s => s.internal.auth.user.response);
	const dlnVerified = useSelector(s => (
		s.platform.drivingLicenseRegistration.licenseByUserId[userId]?.response?.dln?.verified
	));

	const userProfile = useMemo(() => ({
		personalName,
		familyName,
		birthDate,
		sex: sex as Sex,
	}), [personalName, familyName, birthDate, sex]);

	const computedDLN = getComputedDLN(userProfile);

	useEffect(() => {
		const fullDLN = `${computedDLN}${dln}`;

		if (!value && !dln) return;

		if (dlnVerified && !dln)
			setDLN(value.substring(value.length - 4));

		if ((value !== fullDLN) && !dlnVerified)
			onChange(fullDLN);
	}, [computedDLN, dln, dlnVerified, onChange, userProfile, value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDLN(e.target.value?.substring(0, 4)?.toUpperCase());
		dispatch(trackQuoteEvent({
			action: 'driving_license_input_started',
			value: String(Boolean(e.target.value.length)),
		}));
	};

	useEffect(() => {
		if (!newFlag || !pageFetching)
			return;

		setAlreadyAttachedModal(value && underlyingError && knownDlnError.includes(underlyingError.code));
	}, [newFlag, underlyingError, pageFetching, value]);

	useEffect(() => {
		if (alreadyAttachedModal) {
			dispatch(
				trackEvent({
					eventName: 'error',
					eventPayload: {
						code: validation,
					},
				}),
			);
		}
	}, [dispatch, validation, alreadyAttachedModal]);

	return (
		<div>
			<QuoteInputDecorator
				error={alreadyAttachedModal ? void 0 : helpfulErrorMessage(error, regexFailing)}
				label={g('profile.licence.number_input.detail', 'Last 4 digits on your driving licence')}
			>
				<PrefilledInput
					type={'text'}
					value={dln}
					// SC: this is an interesting one:
					// viewport < ~375px means the browser will have a weird behaviour around min width, so `size` fixes it.
					// Note: This doesn't set any max char length though.
					size={4}
					disabled={locked}
					onChange={handleChange}
					prefilled={computedDLN}
					data-event-onfocus-name={'driving_license_input_selected'}
				/>
			</QuoteInputDecorator>

			{!error && (
				<Margin $marginTop={'small'}>
					<DLNDialogHint />
				</Margin>
			)}

			<DlnAlreadyExists
				open={alreadyAttachedModal}
				emailFound={underlyingError?.code === 'dln_already_exists_email_found'}
				onClose={() => setAlreadyAttachedModal(false)}
				error={validation}
			/>
		</div>
	);
};

export default DLN;
