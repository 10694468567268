import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getConfigById } from '../actions';
import * as api from '../api';
import { UserConfigActionTypes } from '../types';
export default function* watcherGetConfigById() {
    yield takeEvery(UserConfigActionTypes.GET_CONFIG_BY_ID, workerGetConfigById);
}
function* workerGetConfigById({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const config = yield call(api.getConfig, client, payload);
        yield put(getConfigById.success(ident, config));
    }
    catch (error) {
        yield put(getConfigById.failure(ident, error));
    }
}
