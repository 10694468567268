import URI from 'urijs';

export const isInternalURL = (href: string) => {
	try {
		const url = new URL(href, window.location.origin);

		return url.hostname === window.location.hostname;
	} catch {
		return false;
	}
};

export const getPath = (href: string) => {
	const uri = new URI(href);
	const parts = [uri.path()];

	if (uri.query())
		parts.push(uri.query());

	return parts.join('?');
};
