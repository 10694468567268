import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { profileById } from '../actions';
import { ProfileActionTypes } from '../types';
import { fileById } from '~lib/platform/upload/store/actions';
export default function* watcherProfileById() {
    yield takeEvery(ProfileActionTypes.PROFILE_BY_ID, workerProfileById);
}
function* workerProfileById({ payload, meta }) {
    const { ident } = meta;
    const { userId, revision, loadFiles } = payload;
    const client = yield getContext('api');
    try {
        const response = yield call(api.profileById, client, userId, revision);
        yield put(profileById.success(ident, response));
        if (!loadFiles)
            return;
        const { profilePhotoId, licensePhotoId } = response;
        if ((yield select(s => profilePhotoId && !s.platform.upload.fileById[profilePhotoId])))
            yield put(fileById.request({ fileId: profilePhotoId, urlTtl: 3600 }));
        if ((yield select(s => licensePhotoId && !s.platform.upload.fileById[licensePhotoId])))
            yield put(fileById.request({ fileId: licensePhotoId, urlTtl: 3600 }));
    }
    catch (error) {
        yield put(profileById.failure(ident, error));
    }
}
