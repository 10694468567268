import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialTermsState = {
    recordAgreement: {},
    latestDocuments: { ...initialAsyncState },
    userStatus: {},
};
export const TermsActionTypes = {
    RECORD_AGREEMENT: '@@platform/terms/RECORD_AGREEMENT',
    RECORD_AGREEMENT_SUCCESS: '@@platform/terms/RECORD_AGREEMENT_SUCCESS',
    RECORD_AGREEMENT_FAILURE: '@@platform/terms/RECORD_AGREEMENT_FAILURE',
    LATEST_DOCUMENTS: '@@platform/terms/LATEST_DOCUMENTS',
    LATEST_DOCUMENTS_SUCCESS: '@@platform/terms/LATEST_DOCUMENTS_SUCCESS',
    LATEST_DOCUMENTS_FAILURE: '@@platform/terms/LATEST_DOCUMENTS_FAILURE',
    USER_STATUS: '@@platform/terms/USER_STATUS',
    USER_STATUS_SUCCESS: '@@platform/terms/USER_STATUS_SUCCESS',
    USER_STATUS_FAILURE: '@@platform/terms/USER_STATUS_FAILURE',
};
