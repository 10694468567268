import React from 'react';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import { generateAssetAltDescription } from '~website/helpers/a11y';

const ImageComponent = styled.img`
	width: 100%;
	max-width: 500px;
`;

interface ImageBelowProps {
	asset: DesignSystemAsset;
}

const ImageBelow: React.FCWithChildren<ImageBelowProps> = ({ asset }) => {
	const image = useDesignSystemAsset(asset);

	if (!asset?.value || !image)
		return null;

	return (
		<Margin $marginTop={'48px'}>
			<ImageComponent
				src={image}
				alt={asset?.description || generateAssetAltDescription(asset)}
			/>
		</Margin>
	);
};

export default ImageBelow;
