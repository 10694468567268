import React, { useContext } from 'react';
import Typography from '../Typography';
import { CardHeaderTextWrapper, CardHeaderWrapper } from './atoms/CardHeaderWrapper';
import CardContext from './context';
import CardHeaderChevron from './molecules/CardHeaderChevron';
export const CardHeader = ({ children }) => {
    const { open, toggleCollapse, collapsible } = useContext(CardContext);
    return (React.createElement(CardHeaderWrapper, { "$collapsible": collapsible, onClick: toggleCollapse },
        collapsible && React.createElement(CardHeaderChevron, { open: open }),
        children));
};
export const CardHeaderText = ({ children }) => (React.createElement(CardHeaderTextWrapper, null,
    React.createElement(Typography, { "$bold": true, "$type": 'Body.Large', "$color": 'textOnSurfaceBackground' }, children)));
