import { call, getContext, put } from 'redux-saga/effects';
import { preparePhotoSubmission } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(preparePhotoSubmission.request, function* preparePhotoSubmissionSaga(action) {
    const client = yield getContext('api');
    const { payload } = action;
    const { ident } = action.meta;
    try {
        const response = yield call([client, client.rpc], 'service-kyc', '1/2020-04-01/prepare_photo_submission', payload);
        yield put(preparePhotoSubmission.success(ident, response));
    }
    catch (error) {
        yield put(preparePhotoSubmission.failure(ident, error));
    }
});
