import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialCodeMappingState = {
    geoCodes: { ...initialAsyncState },
    abiCodes: { ...initialAsyncState },
    errorCodes: { ...initialAsyncState },
};
export const CodeMappingActionTypes = {
    GEO_CODES: '@@platform/code-mapping/GEO_CODES',
    ABI_CODES: '@@platform/code-mapping/ABI_CODES',
    ERROR_CODES: '@@platform/code-mapping/ERROR_CODES',
};
