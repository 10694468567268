import React from 'react';
import styled from 'styled-components';

import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import { media } from '~lib/frontend/design-system/breakpoints';
import IconWrapped from '~lib/frontend/design-system/components/IconWrapped';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';
import ReferralBanner from '~website/features/refer-a-friend/molecules/ReferralBanner';

const ToolsWrapper = styled.div`
	& > * {
		margin: 52px auto;

		${media.greaterThan('tablet')`
			margin: 68px auto;
		`}
	}
`;

const Feature = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 36px;
	width: 305px;
	align-items: center;
`;

const FeaturesContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 630px;

	${media.greaterThan('tablet')`
		flex-direction: row;

		&>:not(:last-child) {
			margin-right: 32px;
		}
	`}
`;

interface ToolsContentProps {
	get: GetterType;
}

const Content: React.FCWithChildren<ToolsContentProps> = ({ get }) => {
	const getIterable = (key: string) => get(key) as unknown as unknown[];

	return (
		<ToolsWrapper>
			<FeaturesContainer>
				{getIterable('blocks')?.map((_, index) => (
					<Feature key={get(`blocks[${index}].title`)}>
						<IconWrapped
							icon={get(`blocks[${index}].new_icon`) as IconType}
							$size={'medium'}
							$type={'square'}
							$style={'primary'}
							$muted
						/>
						<Typography
							$type={'Body.Large'}
							$color={'textOnSurfaceBackground'}
							$marginTop={'large'}
							$marginBottom={'extraSmall'}
							$bold
							$align={'center'}
						>
							{get(`blocks[${index}].title`)}
						</Typography>
						<Typography
							$type={'Body.Medium'}
							$align={'center'}
						>
							{get(`blocks[${index}].body`)}
						</Typography>
					</Feature>
				))}
			</FeaturesContainer>

			<ReferralBanner />
		</ToolsWrapper>
	);
};

export default Content;
