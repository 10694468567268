import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialHiringOfferState = {
    createNewHiringOffer: {},
    updateHiringOffer: {},
    updateHiringOfferStatus: {},
    generateShareKey: {},
    deleteHiringOffer: {},
    retrieveHiringOffer: {},
    getHiringOffersList: { ...initialAsyncState },
};
export const HiringOfferTypes = {
    CREATE_NEW_HIRING_OFFER: '@@platform/hiring-offer/CREATE_NEW_HIRING_OFFER',
    UPDATE_HIRING_OFFER: '@@platform/hiring-offer/UPDATE_HIRING_OFFER',
    UPDATE_HIRING_OFFER_STATUS: '@@platform/hiring-offer/UPDATE_HIRING_OFFER_STATUS',
    GENERATE_SHARE_KEY: '@@platform/hiring-offer/GENERATE_SHARE_KEY',
    DELETE_HIRING_OFFER: '@@platform/hiring-offer/DELETE_HIRING_OFFER',
    RETRIEVE_HIRING_OFFER: '@@platform/hiring-offer/RETRIEVE_HIRING_OFFER',
    GET_HIRING_OFFERS_LIST: '@@platform/hiring-offer/GET_HIRING_OFFERS_LIST',
};
