import React from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { AbiCodesResponse } from '~lib/platform/code-mapping/types';
import { AsyncState } from '~lib/shared/redux/types/state';
import { getAbiCodesOrDefault } from '~website/helpers/quick-quote';
import { ApplicationState } from '~website/store';

const MaritalStatus: React.FunctionComponent = () => {
	const g = useContentKey('strings');
	const { value, validation, onChange } = useProfileInternalChangeRequest('maritalStatus', notEmptyValidator);
	const error = useOnPageError(validation, 'marital_status_input');
	const abiCodes = useSelector<ApplicationState, AsyncState<AbiCodesResponse>>(state =>
		state.platform.codeMapping.abiCodes);
	const maritalCodes = getAbiCodesOrDefault(abiCodes).maritalCodes?.map(code => ({
		label: code.name,
		value: code.code,
	}));

	return (
		<QuoteInputDecorator
			label={g('motor.long_term.questions.about_you.marital_status.question', 'Marital status')}
			error={error}
		>
			<TrackedSelectInput
				value={value}
				options={maritalCodes}
				placeholder={'Choose an option'}
				onChange={({ value }) => onChange(value)}
				data-event-onfocus-name={'dropdown_selected'}
				data-event-onfocus-value={'marital_status'}
				data-event-onchange-name={'marital_status_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default MaritalStatus;
