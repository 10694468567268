import { all, fork } from 'redux-saga/effects';
import decideResolution from './decide-resolution';
import getLatestKycOps from './get-latest-kyc-ops';
import latestKyc from './latest-kyc';
import preparePhotoSubmission from './prepare-photo-submission';
import revokePhotos from './revoke-photos';
import submitPhotos from './submit-photos';
import uploadKycDocs from './upload-kyc-docs';
export default function* motSaga() {
    yield all([
        decideResolution,
        fork(latestKyc),
        getLatestKycOps,
        preparePhotoSubmission,
        submitPhotos,
        fork(revokePhotos),
        uploadKycDocs,
    ]);
}
