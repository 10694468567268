import React, { useEffect, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import ksuid from '@cuvva/ksuid';
import styled from 'styled-components';
const EnhancedUncontrolledTooltip = props => {
    const { children, tooltip, onClick, ...otherProps } = props;
    const [rendered, setRendered] = useState(false);
    const id = useRef(void 0);
    if (!id.current)
        id.current = ksuid.generate('request').toString();
    useEffect(() => {
        setRendered(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        rendered && id.current && (React.createElement(UncontrolledTooltip, { ...otherProps, target: id.current }, tooltip)),
        React.createElement(WrappedContainer, { id: id.current, onClick: onClick }, children)));
};
export default EnhancedUncontrolledTooltip;
const WrappedContainer = styled.div `
	display: inline;
`;
