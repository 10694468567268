import styled from 'styled-components';
const InputWithIcon = styled.div `
	position: relative;

	& > input {
		padding-left: 40px;
	}

	& > :last-child {
		position: absolute;
		pointer-events: none;
		left: 12px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
`;
export default InputWithIcon;
