export const APP_PREFIX = 'cuvva.app.website';
export const supportFeelings = `${APP_PREFIX}.support.feelings`;
export const pageBuilderPosition = `${APP_PREFIX}.page-builder.position`;
export const cookiePolicyConsent = `${APP_PREFIX}.cookie-policy.consent`;

// new quote, will need to move these to a better place
export const quoteRecentVehiclesKey = `${APP_PREFIX}.quote.recent-vehicles`;
export const quoteRecentFlow = `${APP_PREFIX}.quote.recent-flow`;
export const quoteRecentNCB = `${APP_PREFIX}.quote.recent-ncb-years`;
export const quoteLocation = `${APP_PREFIX}.quote.location`;
export const quoteStartDate = `${APP_PREFIX}.quote.start-date`;
export const quoteUseClass = `${APP_PREFIX}.quote.use-class`;
