import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';

const LargeImage = styled.img`
	display: block;
	max-width: 343px;
	max-height: 215px;

	width: 100%;
	margin: 0 auto;
	margin-top: ${p => p.theme.spacing.large};
`;

const DLNDialogHint: React.FunctionComponent = () => {
	const example = useContentAsset('website_core', 'quote/licence_last_4_example.png');
	const [open, setOpen] = useState(false);
	const onClose = () => setOpen(false);

	return (
		<Fragment>
			<Button $type={'text'} type={'button'} onClick={() => setOpen(true)} $size={'medium'}>
				{'How to find your licence number'}
			</Button>

			<ComplexDialog.Dialog open={open} title={'Your licence number'} onClose={onClose}>
				<ComplexDialog.Content>
					<div>
						{'You can find this on the front of your licence.'}
						<br />
						{'It’s the 16 character long number with a 2 digit issue number '}
						{'after it.'}
					</div>

					<LargeImage src={example} />
				</ComplexDialog.Content>
				<ComplexDialog.Actions>
					<Button onClick={onClose}>{'Got it'}</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</Fragment>
	);
};

export default DLNDialogHint;
