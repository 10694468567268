import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useDLRInternalChangeRequest from '../../hooks/use-dln-internal-change-request';
import useLockedDetails from '../../hooks/use-locked-details';
import useOnPageError from '../../hooks/use-on-page-error';
import validPostcodeValidator from '../../validators/valid-postcode-validator';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';

const Postcode: React.FunctionComponent = () => {
	const { value, validation, onChange } = useDLRInternalChangeRequest('postcode', validPostcodeValidator);
	const error = useOnPageError(validation, 'license_postcode_input');
	const { onBlur, onFocus, error: blurError } = useOnBlurError(validation);
	const locked = useLockedDetails();

	return (
		<QuoteInputDecorator
			label={'Postcode on licence'}
			error={blurError ?? error}
		>
			<TextInput
				name={'address-postcode'}
				type={'text'}
				disabled={locked}
				value={value}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={e => onChange(e.target.value.toLocaleUpperCase())}
				data-event-onfocus-name={'license_postcode_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default Postcode;
