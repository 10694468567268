import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortIso8601 } from '~lib/frontend/helpers/sort';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { User } from '~lib/platform/auth/types';
import { licenseByUserId } from '~lib/platform/driving-license-registration/store/actions';
import { profileById } from '~lib/platform/profile/store/actions';

const useUser = () => {
	const dispatch = useDispatch();
	const userId = useSelector(s => s.internal.auth.user.response);
	const userById = useSelector(s => s.platform.auth.userById[`${userId}-false`]);
	const profileByIdState = useSelector(s => s.platform.profile.profileById[userId]);
	const licenseByUserIdState = useSelector(s => s.platform.drivingLicenseRegistration.licenseByUserId[userId]);
	const licenceState = useAsyncStateStatus(licenseByUserIdState).status;
	const profileState = useAsyncStateStatus(profileByIdState).status;
	const user = userById?.response;
	const profile = profileByIdState?.response;
	const userLoading = useAsyncStateStatus(userById).is('fetching', 'pending');
	const primaryEmailAddress = useMemo(() => getPrimaryEmailAddress(user), [user]);
	const namedUser = Boolean(primaryEmailAddress);

	useEffect(() => {
		if (!userId || profileState !== 'pending') return;
		dispatch(profileById.request({ userId }));
	}, [dispatch, profileState, userId]);

	useEffect(() => {
		if (!userId || licenceState !== 'pending') return;

		dispatch(licenseByUserId.request({ userId }));
	}, [dispatch, licenceState, userId]);

	return {
		userId,
		profile,
		primaryEmailAddress,
		namedUser,
		userLoading,
	};
};

function getPrimaryEmailAddress(user: User | undefined) {
	if (!user || !user.identifiers) return void 0;

	const identifiers = user.identifiers.sort(sortIso8601(e => e.attachedAt));

	return identifiers.find(i => i.type === 'email' && i.primary);
}

export default useUser;
