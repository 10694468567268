const spacing = {
    'extraSmall': '4px',
    'small': '8px',
    'regular': '12px',
    'large': '16px',
    'semiLarge': '24px',
    'extraLarge': '32px',
    '2xLarge': '40px',
    '3xLarge': '48px',
    '4xLarge': '64px',
    '5xLarge': '96px',
    '6xLarge': '128px',
};
export default spacing;
