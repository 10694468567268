import styled from 'styled-components';
const Overlay = styled.div `
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background: transparent;
	display: block;
	z-index: 1;
`;
export default Overlay;
