import { useEffect } from 'react';

export const useHideIntercomLauncher = () => {
	/* eslint-disable no-undef */
	useEffect(() => {
		try {
			// @ts-ignore
			Intercom('update', { hide_default_launcher: true });
		} catch { /* not much we can do */ }
	}, []);
	/* eslint-enable */
};
