export const initialAuthState = {
    attachIdentifier: {},
    authenticate: {},
    createClientCode: {},
    deauthenticate: {},
    detachIdentifier: {},
    replacePrimaryIdentifier: {},
    sendAuthenticationCode: {},
    sendIdentifierToken: {},
    setPrimaryIdentifier: {},
    userById: {},
    verifyIdentifier: {},
};
export const AuthActionTypes = {
    AUTHENTICATE: '@@platform/auth/AUTHENTICATE',
    USER_BY_ID: '@@platform/auth/USER_BY_ID',
    ATTACH_IDENTIFIER: '@@platform/auth/ATTACH_IDENTIFIER',
    DETACH_IDENTIFIER: '@@platform/auth/DETACH_IDENTIFIER',
    SET_PRIMARY_IDENTIFIER: '@@platform/auth/SET_PRIMARY_IDENTIFIER',
    SEND_IDENTIFIER_TOKEN: '@@platform/auth/SEND_IDENTIFIER_TOKEN',
    SEND_AUTHENTICATION_CODE: '@@platform/auth/SEND_AUTHENTICATION_CODE',
    CREATE_CLIENT_CODE: '@@platform/auth/CREATE_CLIENT_CODE',
    DEAUTHENTICATE: '@@platform/auth/DEAUTHENTICATE',
    REPLACE_PRIMARY_IDENTIFIER: '@@platform/auth/REPLACE_PRIMARY_IDENTIFIER',
    VERIFY_IDENTIFIER: '@@platform/auth/VERIFY_IDENTIFIER',
};
