import styled, { css } from 'styled-components';
const ModalCover = styled.aside `
	position: fixed;
	z-index: 519;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition-property: opacity;
	transition-timing-function: ease-out;
	transition-duration: 200ms;
	transition-delay: 0;
	background: rgba(0, 0, 0, 0.35);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ${p => p.$verticalAlign || 'center'};

	&:focus {
		outline: none;
	}

	${p => !p.$display && css `
		display: none;
	`};

	${p => p.$visible && css `
		opacity: 1;
	`};

	& > * {
		z-index: 520;

		opacity: 0;
		transform: translateY(30px);
		transition-property: opacity, transform;
		transition-timing-function: cubic-bezier(.2, .8, .4, 1);
		transition-duration: 200ms;
		transition-delay: 0;

		${p => p.$visible && css `
			opacity: 1;
			transform: translateY(0);
		`};

		display: flex;
		flex-direction: column;
		justify-content: center;

		&:focus {
			outline: none;
		}
	}
`;
export default ModalCover;
