export const banVehicle = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/ban_vehicle', req);
export const findVehicleId = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/find_vehicle_id', req);
export const flushCache = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/flush_cache', req);
export const getPublicVehicle = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/get_vehicle_public', req);
export const getVehicle = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/get_vehicle', req);
export const getVehicleConfig = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/get_vehicle_config', req);
export const listVehicleRevisions = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/list_revisions', req);
export const listExampleVehicles = (client) => client.rpc('service-vehicle', '2/2020-02-02/list_example_vehicles', null);
export const setVehicleProvider = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/set_vehicle_provider', req);
export const overrideVrm = (client, req) => client.rpc('service-vehicle', '2/2020-02-02/override_vrm', req);
