import styled from 'styled-components';
const CategoryWrap = styled.div `
	${p => p.theme.typography['Label.XSmall']()};
	color: ${p => p.theme.ui.textMinor};
	padding: ${p => p.theme.spacing.small};
	text-align: left;
	cursor: default;
	user-select: none;
`;
export default CategoryWrap;
