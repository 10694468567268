import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { trackScreenView } from '../analytics/store/actions';
import ReferralForm from './organism/ReferralForm';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { contentAdapter as headerContentAdapter } from '~website/components/Hero/HeaderHero';
import HeaderHeroWrap from '~website/components/Hero/HeaderHeroWrap';
import Footer from '~website/components/layout/footer';
import Header from '~website/components/layout/header';
import Meta from '~website/components/Meta';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { BuilderSections, SectionDefinition } from '~website/features/builder';

const ReferAFriendStatic: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages[refer-a-friend].${key}`, fallback);
	const headerHeroProps = headerContentAdapter((key, fallback) => get(`hero.${key}`, fallback));

	const sectionGetter: GetterType = (key, fallback) => get(`sections${key}`, fallback);

	const sections = get('sections') as SectionDefinition[];

	useEffect(() => {
		dispatch(trackScreenView({
			eventName: 'refer_a_friend',
			eventPayload: {},
		}));
	}, [dispatch]);

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<VisionaryContext.Provider value={headerHeroProps.theme === 'dark'}>
				<Header
					contentUnder
					{...headerHeroProps.header}
				/>
				<HeaderHeroWrap
					$themeKey={headerHeroProps.theme}
					$gradient={'center'}
					$backgroundImage={void 0}
					$hasSubHero={false}
				>
					<InnerContent>
						<HeadingsWrapper>
							<Typography
								$type={'Heading.Large'}
								$color={'textOnFill'}
								$align={'center'}
								as={'h1'}
							>
								{get('main_title')}
							</Typography>
							<Typography
								$type={'Body.XLarge'}
								$color={'textOnFillMuted'}
								$align={'center'}
							>
								{get('main_sub')}
							</Typography>
						</HeadingsWrapper>
						<ReferralForm />
					</InnerContent>
				</HeaderHeroWrap>
				<BuilderSections
					sections={sections}
					getter={sectionGetter}
				/>
			</VisionaryContext.Provider>
			<Footer />
		</React.Fragment>
	);
};

const HeadingsWrapper = styled.div`
	max-width: 575px;

	& + div {
		width: 343px;

		div:first-child {
			div:last-child {
				margin-top: ${p => p.theme.spacing.extraSmall};
				margin-bottom: ${p => p.theme.spacing.extraSmall};
				align-items: center;
			}
		}

		a {
			color: ${p => p.theme.ui.textDanger};
		}
	}
`;

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export default ReferAFriendStatic;
