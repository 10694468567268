import styled from 'styled-components';
export const Wrap = styled.button `
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${p => p.theme.spacing.small};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	cursor: ${p => p.disabled ? 'default' : 'pointer'};
	border: none;
	background: transparent;
	gap: ${p => p.theme.spacing.small};
	text-decoration: none;

	&:hover {
		background: ${p => p.disabled ? 'transparent' : p.theme.ui.surfaceFillMuted};
		text-decoration: none;
	}
`;
export const IconWrap = styled.div `
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${p => p.disabled ? p.theme.ui.surfaceFillMuted : p.theme.ui.primaryFillMuted};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
`;
export const EmojiWrap = styled.div `
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	font-size: 24px;
`;
export const ItemWithBodyWrap = styled.div `
	display: flex;
	flex-direction: row;
	padding: ${p => p.theme.spacing.small};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	cursor: pointer;
	border: none;
	background: transparent;
	gap: ${p => p.theme.spacing.small};
	text-decoration: none;

	&:hover {
		background: ${p => p.theme.ui.surfaceFillMuted};
		text-decoration: none;
	}
`;
