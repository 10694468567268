export default function createPrimitiveReducer(request, initialState) {
    return function asyncMapReducer(state = initialState, { type, payload }) {
        switch (type) {
            case request:
                return payload;
            default:
                return state;
        }
    };
}
