import styled from 'styled-components';

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	background: ${p => p.theme.ui.surfaceFill};
	border-radius: ${p => p.theme.borderRadius.large};

	padding: ${p => p.theme.spacing.large};

	@media screen and (min-width: 768px) {
		padding: ${p => p.theme.spacing.extraLarge};
	}
`;

export const CardHeading = styled.div`
	${p => p.theme.typography['Heading.Small']()};
	color: ${p => p.theme.ui.textOnSurfaceBackground};
	margin-bottom: ${p => p.theme.spacing.large};
`;

export const CardHeadingSide = styled(CardHeading)`
	margin-bottom: 0;
	text-align: center;
`;

export const CardSubHeading = styled.div`
	${p => p.theme.typography['Body.Medium']()};
	color: ${p => p.theme.ui.textOnSurfaceBackgroundMuted};
	margin-bottom: ${p => p.theme.spacing.large};
`;

export const CardInnerList = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;
