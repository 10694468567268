export const initialProfileState = {
    listProfiles: {},
    profileById: {},
    search: {},
    updateProfile: {},
};
export const ProfileActionTypes = {
    PROFILE_BY_ID: '@@platform/profile/PROFILE_BY_ID',
    PROFILE_BY_ID_SUCCESS: '@@platform/profile/PROFILE_BY_ID_SUCCESS',
    PROFILE_BY_ID_FAILURE: '@@platform/profile/PROFILE_BY_ID_FAILURE',
    UPDATE_PROFILE: '@@platform/profile/UPDATE_PROFILE',
    UPDATE_PROFILE_SUCCESS: '@@platform/profile/UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: '@@platform/profile/UPDATE_PROFILE_FAILURE',
    LIST_PROFILES: '@@platform/profile/LIST_PROFILES',
    LIST_PROFILES_SUCCESS: '@@platform/profile/LIST_PROFILES_SUCCESS',
    LIST_PROFILES_FAILURE: '@@platform/profile/LIST_PROFILES_FAILURE',
    SEARCH: '@@platform/profile/SEARCH',
    SEARCH_SUCCESS: '@@platform/profile/SEARCH_SUCCESS',
    SEARCH_FAILURE: '@@platform/profile/SEARCH_FAILURE',
};
