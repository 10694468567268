import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import OwnersHub from './OwnersHub';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { trackOwnersScreenView } from '~website/features/analytics/store/actions';
import CheckList from '~website/features/quote/components/molecules/CheckList';
import { OwnersData } from '~website/features/referral/types';
import useQuery from '~website/hooks/use-query';

interface UnlockFeaturesProps {
	onCreateAccount: () => void;
	ownersData: OwnersData;
}

const UnlockFeatures: React.FCWithChildren<UnlockFeaturesProps> = ({ onCreateAccount, ownersData }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();

	const checklistItems = ['Follow the driver’s trip', 'Know driver has arrived safely', 'See policy documents'];

	const goToSignin = () => {
		history.push(createAwareUrl('/signin'),
			{ redirectUri: createAwareUrl(location.pathname) });
	};

	useEffect(() => {
		dispatch(trackOwnersScreenView({
			screenName: vehicleProfileEvents.entryPointLocked.screenName,
			cuvvaSource: query.get('cuvva_source'),
		}));
	}, [dispatch, query]);

	return (
		<React.Fragment>
			<LockedSection>
				<Background>
					<OwnersHub ownersData={ownersData} asBackground />
				</Background>
				<Foreground>
					<Icon
						icon={'ic_lock'}
						$size={'12px'}
						$color={'textOnSurfaceBackground'}
						$marginLeft={'auto'}
						$marginRight={'auto'}
					/>
					<CheckList titleAlign={'center'}>
						{checklistItems.map(item => item)}
					</CheckList>
				</Foreground>
			</LockedSection>
			<ForegroundCreateAccount>
				<Button $type={'neutral'} $stretch onClick={onCreateAccount}>
					{'Create account'}
				</Button>
			</ForegroundCreateAccount>
			<SigninWrapper>
				<Typography
					$display={'inline'}
					$type={'Body.XSmall'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginRight={'small'}
				>
					{'Already have an account?'}
				</Typography>
				<Typography
					$display={'inline'}
					$type={'Label.XSmall'}
					$color={'primaryFill'}
					onClick={goToSignin}
				>
					{'Sign in'}
				</Typography>
			</SigninWrapper>
		</React.Fragment>
	);
};

const Background = styled.div`
	filter: blur(25px);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const Foreground = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	z-index: 2;
`;

const ForegroundCreateAccount = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	margin-bottom: ${p => p.theme.spacing.large};

	& > button {
		z-index: 1;
	}
`;

const LockedSection = styled.div`
	border-radius: ${p => p.theme.borderRadius.regular};
	position: relative;
	margin-top: ${p => p.theme.spacing.large};

	& > div:last-child {
		position: relative;
	}

	> svg {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 12px;
		height: 15px;

		path {
			fill: ${p => p.theme.ui.textOnSurfaceBackground};
		}
	}
`;

const SigninWrapper = styled.div`
	& > div:last-child {
		cursor: pointer;
	}
	z-index: 2;
`;

export default UnlockFeatures;
