import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import URI from 'urijs';

import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import HeaderHero from '~website/components/Hero/HeaderHero';

export const notSupportedMeLinks: string[] = [
	'/me/insights',
	'/me/kyc',
	'/me/discounts',
	'/me/discounts/add',
	'/me/feedback',
	'/me/mycars',
	'/me/appicon',
	'/me/connected-accounts',
	'/me/no-hard-feelings',
	'/me/friendscars',
	'/send-borrow-request',
];

const NotSupported: React.FunctionComponent = () => {
	const location = useLocation();

	// SC: seems like there's an issue on web views opened from other apps in mobile
	// therefore we use multiple points to be able to detect this.
	const { isAndroid, isApplePhone, isApplePad } = useMobileDetect();
	const smallScreen = useMediaQuery('(max-width: 1023px)');
	const isMobile = smallScreen || isAndroid || isApplePhone || isApplePad;

	const newPage = new URI(location.search).hasQuery('refresh');
	const showAppStoreButtons = newPage || !isMobile;
	const url = `https://www.cuvva.com${location.pathname}?refresh`;

	const onClick = () => window.open(url, '_blank');

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Sign into your account | Cuvva'}</title>
				<meta name={'robots'} content={'noindex, follow'} />
			</Helmet>
			<HeaderHero
				theme={'dark'}
				titleSegment={{
					centered: true,
					title: 'Download the Cuvva app',
					body: 'The page you’re looking for isn’t available on the web, so you’ll need to download the Cuvva app.',
					showAppStoreButtons,
					showButton: isMobile &&
						!newPage && {
							text: 'Open in the app',
							action: onClick,
						},
				}}
				mainImage={{
					type: 'background',
					value: 'hero_refer.png',
				}}
			/>
		</React.Fragment>
	);
};

export default NotSupported;
