import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useVoluntaryExcess from '../../hooks/use-voluntary-excess';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { CreateEstimateResponse } from '~lib/platform/ltm/types';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import { plainMoney } from '~website/helpers/monies';

const formatExcess = (value: number) => {
	if (typeof value !== 'number')
		return '';

	return plainMoney(value * 100);
};

const TotalExcess: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { value, onChange } = useVoluntaryExcess();

	const ltm = useSelector(s => s.internal.quote.estimate.ltm);
	const ltmEstimate = ltm?.response;
	const quoteStatus = useAsyncStateStatus(ltm).status;
	const [prevEstimate, setPrevEstimate] = useState(ltmEstimate);
	const estimateOrPrevious: CreateEstimateResponse = ltmEstimate ?? prevEstimate;

	const excess = estimateOrPrevious?.cover.excess;
	const voluntaryExcess = estimateOrPrevious?.underwriter.quoteRequirements.voluntaryExcess;
	const voluntaryExcessOptions = voluntaryExcess?.options;
	const voluntaryExcessDefault = voluntaryExcess?.default;
	const canUpdateExcess = Boolean(voluntaryExcessOptions?.length);

	// this is unfortunate but we don't get both base excess and selected one from the estimate endpoint
	// do we need to cache the value somehow
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const baseExcess = useMemo(() => (excess ?? 0) - ((value ?? 0) / 100), [
		quoteStatus,
	]);

	useEffect(() => {
		if (quoteStatus !== 'response')
			return;

		setPrevEstimate(ltmEstimate);
	}, [quoteStatus, ltmEstimate]);

	// sanity check: if they were to change from a estimate to another, make sure to pick the default
	useEffect(() => {
		if (!Array.isArray(voluntaryExcessOptions))
			return;

		const validValue = typeof value === 'number';
		const lower = value < Math.min(...voluntaryExcessOptions);
		const higher = value > Math.max(...voluntaryExcessOptions);

		if (!validValue || lower || higher)
			onChange(voluntaryExcessDefault);
	}, [voluntaryExcessOptions, value, voluntaryExcessDefault, onChange]);

	const excessOptions = (() => {
		if (!voluntaryExcessOptions?.length || !Number.isInteger(value))
			return [];

		return voluntaryExcessOptions.map(opt => ({
			label: plainMoney(opt + ((baseExcess ?? 0) * 100)),
			value: opt,
		}));
	})();

	// we're being optimist here and trying to display the standard default UI
	// even if we don't have a response back yet
	const setExcessUi = canUpdateExcess || quoteStatus !== 'response';

	return (
		<React.Fragment>
			<Margin $marginBottom={'extraLarge'}>
				{setExcessUi && (
					<React.Fragment>
						<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginBottom={'extraSmall'}>
							{'Set your total excess'}
						</Typography>

						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{
								'This is what you’ll have to pay if you make a claim — the higher it is, the less you’ll pay every month'
							}
						</Typography>
					</React.Fragment>
				)}

				{!setExcessUi && (
					<React.Fragment>
						<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginBottom={'extraSmall'}>
							{'Your excess'}
						</Typography>

						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{'Because of the value of your car, we’ve set the excess for you'}
						</Typography>
					</React.Fragment>
				)}
			</Margin>

			<QuoteInputDecorator
				onFocus={() => dispatch(trackQuoteEvent({
					action: 'dropdown_selected',
					value: 'total_excess',
				}))}
			>
				<SelectInput
					value={value}
					options={excessOptions}
					disabled={!canUpdateExcess}
					placeholder={canUpdateExcess ? 'Choose an option' : formatExcess(excess)}
					onChange={change => {
						if (!canUpdateExcess)
							return;

						onChange(change.value);

						dispatch(trackQuoteEvent({
							action: 'dropdown_option_selected',
							value: String(change.value),
						}));
					}}
				/>
			</QuoteInputDecorator>
		</React.Fragment>
	);
};

export default TotalExcess;
