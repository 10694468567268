import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import SectionSegment from './SectionSegment';

const TrustpilotReviewSegment = () => {
	const ref = useRef(null);

	useEffect(() => {
		if (window.Trustpilot) window.Trustpilot.loadFromElement(ref.current, true);
	}, []);

	return (
		<Section>
			<SectionSegment.Inner>
				<div
					ref={ref}
					data-locale={'en-GB'}
					data-template-id={'54ad5defc6454f065c28af8b'}
					data-businessunit-id={'5800a84a0000ff0005960599'}
					data-style-height={'240px'}
					data-style-width={'100%'}
					data-theme={'dark'}
					data-stars={'4,5'}
					data-review-languages={'en'}
				>
					<a href={'https://uk.trustpilot.com/review/cuvva.com'} target={'_blank'} rel={'noopener'}>
						{'Trustpilot'}
					</a>
				</div>
			</SectionSegment.Inner>
		</Section>
	);
};

const Section = styled.div`
	background: ${p => p.theme.ui.secondaryBackground};
`;

export default TrustpilotReviewSegment;
