import { call, getContext, put } from 'redux-saga/effects';
import { quoteAutocycle } from '../actions';
import * as api from '../api';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(quoteAutocycle.request, function* quoteAutocycleSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.quoteAutocycle, client, payload);
        yield put(quoteAutocycle.success(ident, response));
    }
    catch (error) {
        yield put(quoteAutocycle.failure(ident, error));
    }
});
