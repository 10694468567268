import React from 'react';

import { AdapterProps } from '../types';
import EmbeddedVideo, { contentAdapter } from '~website/components/atoms/EmbeddedVideo';
import TitleSegment from '~website/components/atoms/TitleSegment';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => {
	const props = contentAdapter(getter);
	const { title, body, id } = props;
	const video = <EmbeddedVideo {...props} />;

	if (!title)
		return <div id={id}>{video}</div>

	return (
		<TitleSegment
			title={title}
			body={body}
			id={id}
			centered
		>
			{video}
		</TitleSegment>
	);
};

export default {
	type: 'embedded_video',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			provider: { type: 'string' },
			source: { type: 'string' },
			autoplay: { type: 'boolean' },
			title: { type: 'string' },
			body: { type: 'string' },
			thumbnail: { type: 'string' },
		},
		default: {
			provider: 'youtube',
			source: '94lxIjf0Np8',
			autoplay: false,
			title: 'Cuvva video',
			body: '',
			thumbnail: 'home/youtube-thumbnail-hashtag-challenge.jpg',
		},
	},
};
