import styled from 'styled-components';

const LogoWrap = styled.div`
	svg {
		height: 32px;
		margin-bottom: ${p => p.theme.spacing.extraLarge};

		@media (min-width: 768px) {
			margin-bottom: 48px;
		}

		path {
			fill: ${p => p.theme.ui.blankFill};
		}
	}
`;

export default LogoWrap;
