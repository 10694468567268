import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialVehicleState = {
    banVehicle: {},
    findVehicleId: {},
    flushCache: {},
    getPublicVehicle: {},
    getVehicle: {},
    getVehicleConfig: {},
    listVehicleRevisions: {},
    listExampleVehicles: { ...initialAsyncState },
    setVehicleProvider: {},
    overrideVrm: {},
};
export const VehicleActionTypes = {
    BAN_VEHICLE: '@platform/vehicle/BAN_VEHICLE',
    BAN_VEHICLE_FAILURE: '@platform/vehicle/BAN_VEHICLE_FAILURE',
    BAN_VEHICLE_SUCCESS: '@platform/vehicle/BAN_VEHICLE_SUCCESS',
    FIND_VEHICLE_ID: '@platform/vehicle/FIND_VEHICLE_ID',
    FIND_VEHICLE_ID_FAILURE: '@platform/vehicle/FIND_VEHICLE_ID_FAILURE',
    FIND_VEHICLE_ID_SUCCESS: '@platform/vehicle/FIND_VEHICLE_ID_SUCCESS',
    FLUSH_CACHE: '@platform/vehicle/FLUSH_CACHE',
    FLUSH_CACHE_FAILURE: '@platform/vehicle/FLUSH_CACHE_FAILURE',
    FLUSH_CACHE_SUCCESS: '@platform/vehicle/FLUSH_CACHE_SUCCESS',
    GET_PUBLIC_VEHICLE: '@platform/vehicle/GET_PUBLIC_VEHICLE',
    GET_PUBLIC_VEHICLE_FAILURE: '@platform/vehicle/GET_PUBLIC_VEHICLE_FAILURE',
    GET_PUBLIC_VEHICLE_SUCCESS: '@platform/vehicle/GET_PUBLIC_VEHICLE_SUCCESS',
    GET_VEHICLE: '@platform/vehicle/GET_VEHICLE',
    GET_VEHICLE_FAILURE: '@platform/vehicle/GET_VEHICLE_FAILURE',
    GET_VEHICLE_SUCCESS: '@platform/vehicle/GET_VEHICLE_SUCCESS',
    GET_VEHICLE_CONFIG: '@platform/vehicle/GET_VEHICLE_CONFIG',
    GET_VEHICLE_CONFIG_FAILURE: '@platform/vehicle/GET_VEHICLE_CONFIG_FAILURE',
    GET_VEHICLE_CONFIG_SUCCESS: '@platform/vehicle/GET_VEHICLE_CONFIG_SUCCESS',
    LIST_VEHICLE_REVISIONS: '@platform/vehicle/LIST_VEHICLE_REVISIONS',
    LIST_EXAMPLE_VEHICLES: '@platform/vehicle/LIST_EXAMPLE_VEHICLES',
    LIST_EXAMPLE_VEHICLES_FAILURE: '@platform/vehicle/LIST_EXAMPLE_VEHICLES_FAILURE',
    LIST_EXAMPLE_VEHICLES_SUCCESS: '@platform/vehicle/LIST_EXAMPLE_VEHICLES_SUCCESS',
    SET_VEHICLE_PROVIDER: '@platform/vehicle/SET_VEHICLE_PROVIDER',
    SET_VEHICLE_PROVIDER_FAILURE: '@platform/vehicle/SET_VEHICLE_PROVIDER_FAILURE',
    SET_VEHICLE_PROVIDER_SUCCESS: '@platform/vehicle/SET_VEHICLE_PROVIDER_SUCCESS',
    OVERRIDE_VRM: '@platform/vehicle/OVERRIDE_VRM',
    OVERRIDE_VRM_FAILURE: '@platform/vehicle/OVERRIDE_VRM_FAILURE',
    OVERRIDE_VRM_SUCCESS: '@platform/vehicle/OVERRIDE_VRM_SUCCESS',
};
