import React, { useContext } from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';
import VisionaryContext from '~website/contexts/VisionaryContext';

interface StepProps {
	title: string;
	body: string;
}

interface HowItWorksProps {
	get: GetterType;
}

const HowItWorks: React.FCWithChildren<HowItWorksProps> = ({ get }) => {
	const isVisionary = useContext(VisionaryContext);
	const steps: StepProps[] = get('steps') || [];

	return (
		<React.Fragment>
			<Typography
				$type={'Heading.Large'}
				$color={isVisionary ? 'textOnFill' : 'textOnSurfaceBackground'}
			>
				{get('title')}
			</Typography>
			<Steps>
				{steps.map((step, i) => (
					<Step key={`${step.title}:${i}`}>
						<div>
							<Typography
								$type={'Heading.Small'}
								$marginBottom={'extraSmall'}
								$color={isVisionary ? 'textOnFill' : 'textOnSurfaceBackground'}
							>
								{get(`steps.${i}.title`)}
							</Typography>
							<Typography
								$type={'Body.Large'}
								$color={'textOnSurfaceBackgroundMuted'}
							>
								{get(`steps.${i}.body`)}
							</Typography>
						</div>
					</Step>
				))}
			</Steps>
		</React.Fragment>
	);
};

const Steps = styled.ol`
	margin-top: ${p => p.theme.spacing.large};
	counter-reset: steps;
	padding: 0;

	li {
		counter-increment: steps;
		position: relative;
		margin-left: ${p => p.theme.spacing['4xLarge']};

		&::before {
			content: counter(steps);

			margin-right: ${p => p.theme.spacing.large};
			border-radius: 50%;
			background: ${p => p.theme.brand.indigo800};
			color: ${p => p.theme.ui.textOnAction};
			text-align: center;

			--size: ${p => p.theme.spacing['3xLarge']};
			position: absolute;
			top: 0;
			left: calc(-1 * var(--size) - 16px);
			line-height: var(--size);
			width: var(--size);
			height: var(--size);
		}
	}
`;

const Step = styled.li`
	display: flex;
	margin-top: 24px;

	& > div {
		display: inline-block;
	}
`;

export default HowItWorks;
