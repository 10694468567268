import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { detachIdentifier, userById } from '../actions';
import * as api from '../api';
import { handleErrorMessage } from '~lib/platform/auth/store/errors';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(detachIdentifier.request, function* workerDetachIdentifier({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        const response = yield call(api.detachIdentifier, client, payload);
        yield put(detachIdentifier.success(ident, response));
        yield put(userById.request({ userId: payload.userId, includeRemovedIdentifiers: true }));
    }
    catch (error) {
        yield put(detachIdentifier.failure(ident, error));
        yield put(flashMessages.addError(...handleErrorMessage(Cher.coerce(error))));
    }
});
