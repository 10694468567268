import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { snakeCase } from 'change-case';
import styled from 'styled-components';

import { ChevronContainer, Container, Nav } from './atoms/container';
import { DesktopMenu, DesktopMenuItem } from './atoms/desktop';
import { AlwaysVisibleMenuItem, CTAItem, LogoItem, Menu, MenuItem } from './atoms/list';
import { MobileItem, MobileMenu, MobileStoresContainer } from './atoms/mobile';
import Burger from './molecules/Burger';
import { MobileAccordionItem } from './molecules/MobileAccordionItem';
import ThemeColor from './molecules/ThemeColor';
import { MenuItemProps } from './types';
import Anchor from '~lib/frontend/atoms/Anchor';
import CuvvaLogo from '~lib/frontend/atoms/CuvvaLogo';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import useScrollLock from '~lib/frontend/hooks/use-scroll-lock';
import { GetterType } from '~lib/frontend/types/content';
import debounce from '~lib/shared/helpers/debounce';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import VisionaryContext from '~website/contexts/VisionaryContext';
import UserSignedIn from '~website/features/quote/components/molecules/headers/UserSignedIn';
import { oneLink } from '~website/helpers/source-tracking';
import { NavContext } from '~website/hooks/use-sticky-nav';
import useUser from '~website/hooks/use-user';

export interface HeaderProps {
	landingPage?: boolean | string;
	hideHeaderHero?: boolean;
	contentUnder?: boolean;
	quoteProduct?: string;
	downloadUrl?: string;
}

const Header: React.FCWithChildren<HeaderProps> = ({ landingPage, contentUnder, hideHeaderHero, downloadUrl }) => {
	const visionaryContext = useContext(VisionaryContext);

	const [visionary, setVisionary] = useState(visionaryContext);
	const { setParentNavOpen } = useContext(NavContext);
	const { isMobile } = useMobileDetect();
	const history = useHistory();
	const containerRef = useRef<HTMLElement>();
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`global.header.${key}`, fallback);
	const newFlag = useConfigFlag('login_account_website');

	const [open, setOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false);
	const [active, setActive] = useState<string>(void 0);
	const toggleActive = (newActive: string) => setActive(newActive === active ? void 0 : newActive);
	const newDesign = useContext(BrandDesignContext);

	const menuItems = get<MenuItemProps[]>('items', []);
	const smallScreen = useMediaQuery('(max-width: 1050px)');

	const { namedUser } = useUser();

	useEffect(() => {
		if (!smallScreen && open) setOpen(false);
	}, [smallScreen, open]);

	useScrollLock(smallScreen && open);

	// handle updating of state "scrolled" (as we use that for solid nav background)
	useEffect(() => {
		if (typeof global === 'undefined') return void 0;
		if (hideHeaderHero) {
			setScrolled(true);

			return void 0;
		}

		let canDebounce = true;

		const update = () => {
			if (!canDebounce)
				return;

			setScrolled(window.scrollY > 50);
		};

		const debouncedUpdate = debounce(update, 5);

		global.addEventListener('scroll', debouncedUpdate);

		return () => {
			canDebounce = false;

			global.removeEventListener('scroll', debouncedUpdate);
		};
	}, [hideHeaderHero]);

	useEffect(() => {
		if (!newDesign) return;

		const newOpen = Boolean(open || active);
		const hasBackground = newOpen || scrolled;

		setVisionary(!hasBackground);
	}, [scrolled, newDesign, open, active]);

	// Close menu on click out
	useEffect(() => {
		if (!active || !containerRef.current)
			return void 0;

		const onClick: EventListener = e => {
			if (containerRef.current?.contains(e.target as Node))
				return;

			setActive(void 0);
			setOpen(false);
		};

		window.addEventListener('click', onClick, { passive: true });

		return () => window.removeEventListener('click', onClick);
	}, [active]);

	// Handle self-closing menu on navigation elsewhere
	useEffect(() => {
		const unlisten = history.listen(() => {
			setOpen(false);
			setActive(void 0);
		});

		return () => unlisten();
	}, [history]);

	useEffect(() => {
		if (!active) setParentNavOpen(false);
	}, [active, setParentNavOpen]);

	return (
		<Container $visionary={visionary} $contentUnder={contentUnder} ref={containerRef}>
			<ThemeColor />
			<Nav
				$scrolling={scrolled}
				$open={Boolean(open || active)}
				$visionary={visionary}
				$newDesign={newDesign}
			>
				<Menu>
					<LogoItem>
						<Anchor to={'/'}>
							<CuvvaLogo />
						</Anchor>
					</LogoItem>

					{!landingPage && (
						<React.Fragment>
							{menuItems.map(item => {
								if (item.subs) {
									return (
										<MenuItem
											key={item.title}
											onClick={() => setActive(active === item.title ? void 0 : item.title)}
										>
											<Typography
												$type={'Label.Medium'}
												$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
											>
												{item.title}
											</Typography>
											<ChevronContainer
												$open={active === item.title}
												$visionary={visionary}
											>
												<Icon icon={'ic_chevron_down_sm'} $size={'20px'} $color={'textOnSurfaceBackground'} />
											</ChevronContainer>

											<DesktopMenu
												$open={active === item.title}
												$visionary={visionary}
												className={`desktop-large-menu-${snakeCase(item.title)}`}
											>
												{item.subs.map(sub => (
													<DesktopMenuItem
														to={sub.url}
														key={sub.title}
														$visionary={visionary}
													>
														<Icon icon={sub.icon} $size={'24px'} $color={'primaryFill'} />
														<div>
															<Typography
																$bold
																$type={'Body.Medium'}
																$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
															>
																{sub.title}
															</Typography>
															<Typography
																$type={'Body.Small'}
																$color={visionary ? 'textOnFillMuted' : 'textOnSurfaceBackground'}
															>
																{sub.description}
															</Typography>
														</div>
													</DesktopMenuItem>
												))}
											</DesktopMenu>
										</MenuItem>
									);
								}

								return (
									<MenuItem
										key={item.title}
										onMouseOver={() => setActive(item.title)}
									>
										<Anchor to={item.url}>
											<div>
												<Typography
													$type={'Label.Medium'}
													$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
													$marginRight={item.badge ? 'extraSmall' : void 0}
												>
													{item.title}
												</Typography>
												{item.badge && (
													<Badge type={'danger'} size={'small'}>
														{item.badge}
													</Badge>
												)}
											</div>
										</Anchor>
									</MenuItem>
								);
							})}
							{newFlag && !namedUser && (
								<MenuItem onMouseOver={() => setActive('signin')}>
									<Anchor to={'/signin'}>
										<Typography
											$type={'Label.Medium'}
											$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
										>
											{'Sign in'}
										</Typography>
									</Anchor>
								</MenuItem>
							)}
							{newFlag && namedUser && (
								<AlwaysVisibleMenuItem onMouseOver={() => setActive('account')}>
									<UserSignedIn color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'} />
								</AlwaysVisibleMenuItem>
							)}
						</React.Fragment>
					)}

					{get('cta.title') && ((newFlag && !namedUser) || !newFlag) && (
						<CTAItem>
							<Button
								as={Anchor}
								// @ts-ignore
								to={downloadUrl ?? get('cta.url', '')}
								$type={'primary'}
								$size={'medium'}
								$display={'inline-flex'}
							>
								{get('cta.title')}
							</Button>
						</CTAItem>
					)}

					{!landingPage && (
						<MobileViewWrap>
							{newFlag && !namedUser && (
								<Button
									as={Anchor}
									// @ts-ignore
									to={downloadUrl ?? get('cta.url', '')}
									$type={'primary'}
									$size={'medium'}
									$display={'inline-flex'}
								>
									{get('cta.title')}
								</Button>
							)}
							<Burger
								open={open}
								setOpen={(open: boolean) => {
									setOpen(open);
									setParentNavOpen(open);
								}}
							/>
						</MobileViewWrap>
					)}
				</Menu>
			</Nav>
			<MobileMenu $open={open} $visionary={visionary} $fullHeight={newFlag}>
				{menuItems.map(item => {
					if (item.subs) {
						return (
							<MobileAccordionItem
								key={item.title}
								onClick={() => toggleActive(item.title)}
								active={active === item.title}
								label={item.title}
								visionary={visionary}
							>
								{item.subs.map(s => (
									<Anchor key={s.title} to={s.url}>
										<Typography
											$type={'Label.Medium'}
											$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
										>
											{s.title}
										</Typography>
									</Anchor>
								))}
							</MobileAccordionItem>
						);
					}

					return (
						<MobileItem key={item.title}>
							<Anchor to={item.url}>
								<div>
									<Typography
										$type={'Label.Medium'}
										$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
										$marginRight={item.badge ? 'extraSmall' : void 0}
									>
										{item.title}
									</Typography>
									{item.badge && (
										<Badge type={'danger'} size={'small'}>
											{item.badge}
										</Badge>
									)}
								</div>
							</Anchor>
						</MobileItem>
					);
				})}

				{newFlag && !namedUser && (
					<MobileItem>
						<Anchor to={'/signin'}>
							<Typography
								$type={'Label.Medium'}
								$color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}
							>
								{'Sign in'}
							</Typography>
						</Anchor>
					</MobileItem>
				)}

				{newFlag && namedUser && (
					<BottomAlignedCta>
						<CtaWrap>
							<Button
								as={Anchor}
								// @ts-ignore
								to={downloadUrl ?? get('cta.url', '')}
								$type={'primary'}
								$stretch
								$size={'small'}
								$display={'inline-flex'}
							>
								{get('cta.title')}
							</Button>
						</CtaWrap>
					</BottomAlignedCta>
				)}

				{!newFlag && (
					<MobileStoresContainer>
						<AppStoreButtonsSegment
							width={'150px'}
							url={isMobile ? oneLink : { appstore: 'download', playstore: 'download' }}
						/>
					</MobileStoresContainer>
				)}
			</MobileMenu>
		</Container>
	);
};

const BottomAlignedCta = styled.div`
	flex: 1;
	display: flex;
	align-content: center;
`;

const CtaWrap = styled.div`
	flex: 1;
	align-self: flex-end;
`;

const MobileViewWrap = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.small};
	align-items: center;

	@media (min-width: 1050px) {
		display: none;
	}
`;

export default Header;
