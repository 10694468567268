import styled, { keyframes } from 'styled-components';
import { parseMargin } from '../../atoms/utils/Margin';
const Rotate = keyframes `
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
`;
export const SpinnerWrap = styled.div `
	display: inline-flex;
	width: ${p => p.$size};
	height: ${p => p.$size};
	align-items: center;
	justify-content: center;

	${parseMargin};

	& > * {
		transform-origin: center;
		animation: ${Rotate} .6s linear infinite;
	}
`;
