import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { PageCtx } from '../../contexts/PageCtx';
import { pageActions, quoteActions } from '../../store/actions';
import Button from '~lib/frontend/design-system/components/button/Button';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';

interface ProceedButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	path: string;
	disabled?: boolean;
	submitEstimate?: boolean;
}

const ProceedButton: React.FCWithChildren<ProceedButtonProps> = ({
	path,
	children,
	disabled,
	type = 'submit',
	submitEstimate = false,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [proceed, setProceed] = useState(false);

	const pageId = useContext(PageCtx);
	const pages = useSelector(s => s.internal.quote.pages);
	const page = pages[pageId];

	const onSubmit = (e: React.FormEvent) => {
		setProceed(true);
		dispatch(pageActions.submitPage.request({ pageId, submitEstimate }));
		dispatch(quoteActions.acceptTerms());
		dispatch(trackQuoteEvent({ action: 'continue_cta_selected' }));

		e.stopPropagation();
		e.preventDefault();
	};

	useEffect(() => {
		if (!proceed || page.fetching || page.error)
			return;

		if (page.completed)
			history.push(createAwareUrl(path));
	}, [proceed, page, history, path]);

	return (
		<Button
			$stretch
			$size={'large'}
			type={type}
			$loading={page?.fetching}
			onClick={onSubmit}
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

export default ProceedButton;
