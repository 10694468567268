import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { updateVehicleProfile } from '../actions';
import { VehicleProfileActionTypes } from '../types';
export default function* watcherUpdateVehicleProfile() {
    yield takeEvery(VehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE, workerUpdateVehicleProfile);
}
function* workerUpdateVehicleProfile({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.updateVehicleProfile, client, requestPayload);
        yield put(updateVehicleProfile.success(ident, response));
    }
    catch (error) {
        yield put(updateVehicleProfile.failure(ident, error));
        yield put(flashMessages.addError('Unable to update vehicle profile', 'There was an unknown issue while updating this resource. The error is below:', Cher.coerce(error)));
    }
}
