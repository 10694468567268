import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { AsyncState } from '~lib/shared/redux/types/state';

interface Requirement {
	pricing: {
		totalPayable: number;
	};
	smartPricing: {
		nonSmartPricing?: {
			totalPayable: number;
		};
	};
}

const useAsyncPayable = (state: AsyncState<Requirement>) => {
	const status = useAsyncStateStatus(state).status;
	const stateTotalPayable = state?.response?.pricing?.totalPayable;
	const stateNonSmartPricing = state?.response?.smartPricing?.nonSmartPricing?.totalPayable;

	const hasResponse = status === 'response';
	const fetching = status === 'fetching' || state.fetching;
	const error = status === 'error';
	const totalPayable = fetching ? void 0 : stateTotalPayable;

	// this isn't great, but we currently don't have a way to know if it's enabled or not.
	// https://cuvva.slack.com/archives/C01UJJWAA6N/p1647949997134939
	const nonSmartPricing = !hasResponse || stateTotalPayable === stateNonSmartPricing ? void 0 : stateNonSmartPricing;
	const hasSmartPricing = nonSmartPricing !== void 0;

	return {
		fetching,
		error,
		totalPayable,
		hasSmartPricing,
		nonSmartPricing,
	};
};

export default useAsyncPayable;
