import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { userReferrer } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherUserReferrer() {
    yield takeEvery(PromoActionTypes.USER_REFERRER, workerUserReferrer);
}
function* workerUserReferrer({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.userReferrer, client, payload.userId);
        yield put(userReferrer.success(ident, response));
    }
    catch (error) {
        yield put(userReferrer.failure(ident, error));
    }
}
