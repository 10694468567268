import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { discount } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherFetchDiscount() {
    yield takeEvery(PromoActionTypes.FETCH_DISCOUNT, workerFetchDiscounts);
}
function* workerFetchDiscounts({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.getDiscount, client, payload.discountId);
        yield put(discount.success(ident, response));
    }
    catch (error) {
        yield put(discount.failure(ident, error));
    }
}
