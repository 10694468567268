import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialStaffState = {
    getStaffById: {},
    getStaffBySlug: {},
    listBadges: initialAsyncState,
    listStaff: {},
    listStaffPublic: initialAsyncState,
    upsertStaffMember: {},
    disableStaffMember: {},
};
export const StaffActionTypes = {
    LIST_BADGES: '@@platform/staff/LIST_BADGES',
    LIST_BADGES_FAILURE: '@@platform/staff/LIST_BADGES_FAILURE',
    LIST_BADGES_SUCCESS: '@@platform/staff/LIST_BADGES_SUCCESS',
    LIST_STAFF: '@@platform/staff/LIST_STAFF',
    LIST_STAFF_FAILURE: '@@platform/staff/LIST_STAFF_FAILURE',
    LIST_STAFF_SUCCESS: '@@platform/staff/LIST_STAFF_SUCCESS',
    LIST_STAFF_PUBLIC: '@@platform/staff/LIST_STAFF_PUBLIC',
    LIST_STAFF_PUBLIC_FAILURE: '@@platform/staff/LIST_STAFF_PUBLIC_FAILURE',
    LIST_STAFF_PUBLIC_SUCCESS: '@@platform/staff/LIST_STAFF_PUBLIC_SUCCESS',
    GET_STAFF_BY_ID: '@@platform/staff/GET_STAFF_BY_ID',
    GET_STAFF_BY_ID_FAILURE: '@@platform/staff/GET_STAFF_BY_ID_FAILURE',
    GET_STAFF_BY_ID_SUCCESS: '@@platform/staff/GET_STAFF_BY_ID_SUCCESS',
    GET_STAFF_BY_SLUG: '@@platform/staff/GET_STAFF_BY_SLUG',
    GET_STAFF_BY_SLUG_FAILURE: '@@platform/staff/GET_STAFF_BY_SLUG_FAILURE',
    GET_STAFF_BY_SLUG_SUCCESS: '@@platform/staff/GET_STAFF_BY_SLUG_SUCCESS',
    UPSERT_STAFF_MEMBER: '@@platform/staff/UPSERT_STAFF_MEMBER',
    DISABLE_STAFF_MEMBER: '@@platform/staff/DISABLE_STAFF_MEMBER',
};
