/* eslint-disable require-yield */
import Cookies, { CookieAttributes } from 'js-cookie';

import { cookieConsentAction } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { cookiePolicyConsent } from '~website/constants/storage';

export default createTakeEverySagaSet(cookieConsentAction.setCookieConsent, function* setCookieConsent(action) {
	const consent = action.payload;

	if (!consent && 'dataLayer' in window)
		window.dataLayer.push({ event: 'consentChoice' });

	if (!consent || consent === 'pending')
		return;

	const cookieOptions: CookieAttributes = {
		expires: 365,
		sameSite: 'none',
		secure: true,
	};

	Cookies.set(cookiePolicyConsent, action.payload, cookieOptions);

	// Update Google's Consent Mode
	const googleConsent = consent === 'non-relevant' ? 'granted' : 'denied';

	window.gtag('consent', 'update', {
		ad_storage: googleConsent,
		analytics_storage: googleConsent,
		ad_user_data: googleConsent,
		ad_personalization: googleConsent,
	});

	if ('dataLayer' in window) {
		// SMU - This is necessary because GTM has to wait before updating the relevant cookie state on their ends
		// weird hack but it allows the relevant tags load well
		window.setTimeout(() => {
			window.dataLayer.push({ event: 'consentChoice' });
			window.dataLayer.push({ event: 'consentUpdated' });
		}, 0);
	}
});
