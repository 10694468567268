import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialUserConfigState = {
    getConfigById: {},
    getConfigExtended: {},
    getConfigRaw: { ...initialAsyncState },
    getFlagConfig: {},
    listFlagsById: {},
    setFlag: {},
    setFlagConfig: {},
    deleteFlag: {},
};
export const UserConfigActionTypes = {
    GET_CONFIG_BY_ID: '@@platform/user-config/GET_CONFIG_BY_ID',
    GET_CONFIG_BY_ID_SUCCESS: '@@platform/user-config/GET_CONFIG_BY_ID_SUCCESS',
    GET_CONFIG_BY_ID_FAILURE: '@@platform/user-config/GET_CONFIG_BY_ID_FAILURE',
    GET_CONFIG_EXTENDED: '@@platform/user-config/GET_CONFIG_EXTENDED',
    GET_CONFIG_EXTENDED_SUCCESS: '@@platform/user-config/GET_CONFIG_EXTENDED_SUCCESS',
    GET_CONFIG_EXTENDED_FAILURE: '@@platform/user-config/GET_CONFIG_EXTENDED_FAILURE',
    GET_CONFIG_RAW: '@@platform/user-config/GET_CONFIG_RAW',
    GET_CONFIG_RAW_SUCCESS: '@@platform/user-config/GET_CONFIG_RAW_SUCCESS',
    GET_CONFIG_RAW_FAILURE: '@@platform/user-config/GET_CONFIG_RAW_FAILURE',
    GET_FLAG_CONFIG: '@@platform/user-config/GET_FLAG_CONFIG',
    GET_FLAG_CONFIG_SUCCESS: '@@platform/user-config/GET_FLAG_CONFIG_SUCCESS',
    GET_FLAG_CONFIG_FAILURE: '@@platform/user-config/GET_FLAG_CONFIG_FAILURE',
    LIST_FLAGS_BY_ID: '@@platform/user-config/LIST_FLAGS_BY_ID',
    LIST_FLAGS_BY_ID_SUCCESS: '@@platform/user-config/LIST_FLAGS_BY_ID_SUCCESS',
    LIST_FLAGS_BY_ID_FAILURE: '@@platform/user-config/LIST_FLAGS_BY_ID_FAILURE',
    SET_FLAG: '@@platform/user-config/SET_FLAG',
    SET_FLAG_SUCCESS: '@@platform/user-config/SET_FLAG_SUCCESS',
    SET_FLAG_FAILURE: '@@platform/user-config/SET_FLAG_FAILURE',
    SET_FLAG_CONFIG: '@@platform/user-config/SET_FLAG_CONFIG',
    SET_FLAG_CONFIG_SUCCESS: '@@platform/user-config/SET_FLAG_CONFIG_SUCCESS',
    SET_FLAG_CONFIG_FAILURE: '@@platform/user-config/SET_FLAG_CONFIG_FAILURE',
    DELETE_FLAG: '@@platform/user-config/DELETE_FLAG',
};
