export const PRIMARY = 'primary';
export const PRIMARYMUTED = 'primaryMuted';
export const TEXTONFILL = 'textOnFill';
export const DESTRUCTIVE = 'destructive';
export const DESTRUCTIVEMUTED = 'destructiveMuted';
export const OUTLINE = 'outline';
export const TEXT = 'text';
export const TEXTMUTED = 'textMuted';
export const NEUTRAL = 'neutral';
export const NEUTRALMUTED = 'neutralMuted';
export const NEUTRALMUTEDONFILL = 'neutralMutedOnFill';
export const ButtonTypes = [
    PRIMARY,
    PRIMARYMUTED,
    TEXTONFILL,
    DESTRUCTIVE,
    DESTRUCTIVEMUTED,
    OUTLINE,
    TEXT,
    TEXTMUTED,
    NEUTRAL,
    NEUTRALMUTED,
    NEUTRALMUTEDONFILL,
];
export const ButtonVariants = ['default', 'pill'];
