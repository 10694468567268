import styled from 'styled-components';
const OverflowScroll = styled.div `
	overflow-x: scroll;
	overflow-y: visible;

	/* Webkit only */
	::-webkit-scrollbar {
		display: none;
	}

	/* Firefox only */
	scrollbar-width: none;
`;
export default OverflowScroll;
