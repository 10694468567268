import { v4 } from 'uuid';
import { getItem, setItem } from '../helpers/local-storage';
import { COMMON_PREFIX } from '~lib/frontend/constants/storage';
export function getCuvvaId() {
    let cuvId = getItem(`${COMMON_PREFIX}.cuv_id`);
    if (cuvId)
        return cuvId;
    cuvId = v4();
    setItem(`${COMMON_PREFIX}.cuv_id`, cuvId);
    return cuvId;
}
