import { call, getContext, put } from 'redux-saga/effects';
import { getHiringOffersList } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getHiringOffersList.request, function* getHiringOffersListSaga() {
    const client = yield getContext('api');
    try {
        const response = yield call(api.getHiringOffersList, client);
        yield put(getHiringOffersList.success(response));
    }
    catch (error) {
        yield put(getHiringOffersList.failure(error));
    }
});
