import React, { FunctionComponent } from 'react';

import { KeyDocument } from '../atoms/KeyDocumentWrapper';
import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';

interface OfferKeyDocumentProps {
	title: string;
	fileName: string;
}

const OfferKeyDocument: FunctionComponent<OfferKeyDocumentProps> = ({ title, fileName }) => {
	const asset = useContentAsset('service_hiring_offer', fileName);

	return (
		<KeyDocument>
			<div>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $marginBottom={'small'}>
					{title}
				</Typography>
				<Typography $type={'Heading.XSmall'}>{'PDF'}</Typography>
			</div>

			<Anchor href={asset} download>
				<Icon $size={'32px'} icon={'ic_push_down'} $color={'primaryFill'} />
			</Anchor>
		</KeyDocument>
	);
};

export default OfferKeyDocument;
