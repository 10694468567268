import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherGetVehicleConfig() {
    yield takeEvery(VehicleActionTypes.GET_VEHICLE_CONFIG, workerGetVehicleConfig);
}
function* workerGetVehicleConfig({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const resp = yield api.getVehicleConfig(client, payload);
        yield put(actions.getVehicleConfig.success(ident, resp));
    }
    catch (error) {
        yield put(actions.getVehicleConfig.failure(ident, error));
    }
}
