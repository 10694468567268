import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { SmallCardWrapper } from '../atoms/Card';
import { CardTitleWrapper, Flex, IlloWrapper } from './MakeAClaim';
import RestartSubscriptionModal from './RestartSubscriptionModal';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

const RestartSubscription = () => {
	const [open, setOpen] = useState(false);
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);

	const illustration = useDesignSystemAsset({ type: 'illustration', value: 'il_ltm_calendar.svg' });
	const cancelSubscription = useConfigFlag('web_subscription_cancellation');

	return (
		<Fragment>
			<CancelASubWrapper onClick={onOpen}>
				<Flex>
					<div>
						<CardTitleWrapper>
							<Typography $display={'inline'} $type={'Heading.Small'} $bold $color={'textHighlight'}>
								{'Restart subscription'}
							</Typography>
							<Icon $size={'24px'} icon={'ic_chevron_right_small'} $color={'primaryFill'} />
						</CardTitleWrapper>
						<Typography
							$marginTop={'small'}
							$marginRight={'large'}
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackground'}
						>
							{'You’ve turned off autocycle, your subscription will end soon.'}
						</Typography>
					</div>
					<IlloWrapper>
						<picture>
							<img src={illustration} alt={'restart ltm calendar'} />
						</picture>
					</IlloWrapper>
				</Flex>
			</CancelASubWrapper>

			{cancelSubscription && (
				<RestartSubscriptionModal open={open} onClose={onClose} />
			)}
		</Fragment>
	);
};

export default RestartSubscription;

const CancelASubWrapper = styled(SmallCardWrapper)`
	cursor: pointer;
`;
