export const initialIncidentState = {
    clearIncidents: {},
    createIncident: {},
    listLatestIncidents: {},
    removeIncident: {},
    updateIncident: {},
};
export const IncidentActionTypes = {
    CREATE_INCIDENT: '@@platform/incident/CREATE_INCIDENT',
    LIST_LATEST_INCIDENTS: '@@platform/incident/LIST_LATEST_INCIDENTS',
    REMOVE_INCIDENT: '@@platform/incident/REMOVE_INCIDENT',
    UPDATE_INCIDENT: '@@platform/incident/UPDATE_INCIDENT',
    CLEAR_INCIDENTS: '@@platform/incident/CLEAR_INCIDENTS',
};
