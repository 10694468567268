import React from 'react';
import styled from 'styled-components';

import { SmallCardWrapper } from '../atoms/Card';
import { IlloWrapper } from './NoSubscription';
import { media } from '~lib/frontend/design-system/breakpoints';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import CheckList from '~website/components/CheckList';

const DownloadAppBanner = () => {
	const downloadPhone = useDesignSystemAsset({ type: 'illustration', value: 'il_download_phone.svg' });
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.profile.${key}`, fallback);

	return (
		<BannerWrapper>
			<IlloWrapper>
				<picture>
					<img src={downloadPhone} alt={'download app banner'} />
				</picture>
			</IlloWrapper>

			<Typography
				$type={'Heading.Small'}
				$align={'center'}
				$marginTop={'large'}
				$marginBottom={'semiLarge'}
				$color={'textOnSurfaceBackground'}
			>
				{get('download_app.title', 'Unlock more with the app')}
			</Typography>

			<CheckList
				items={get('download_app.items', [
					'Set MOT and tax reminders',
					'Your policy in your pocket',
					'Customer support 9am to 9pm, get a response in less than 2 minutes',
				])}
			/>

			<Margin $marginTop={'extraLarge'}>
				<AppStoreButtonsSegment />
			</Margin>
		</BannerWrapper>
	);
};

export default DownloadAppBanner;

const BannerWrapper = styled(SmallCardWrapper)`
	${media.lessThan('tablet')`
		display: none;
	`};
`;
