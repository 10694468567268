import styled, { css } from 'styled-components';
const TableWrapper = styled.table `
	width: 100%;
	border-collapse: collapse;
	${p => p.theme.typography['Body.Small']()};
	color: ${p => p.theme.ui.textOnSurfaceBackground};

	th,
	td {
		padding: 0.75rem 1rem;
		padding-right: 0;
		text-align: right;
		${p => (p.nowrap ? 'white-space: nowrap' : '')};
	}

	thead,
	td {
		border-top: 1px solid ${p => p.theme.ui.borderSeparator};
	}

	th {
		font-weight: bold;
		color: ${p => p.theme.ui.textOnSurfaceBackground};
	}

	td:first-child,
	th:first-child {
		padding-left: 0;
		text-align: left;
	}

	.card-body > & th {
		border-top: none;
	}

	${p => p.highlightOnHover && css `
		tbody tr:hover {
			background-color: ${p => p.theme.ui.surfaceFill} !important;
		}
	`};
`;
export default TableWrapper;
