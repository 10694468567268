import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useLockedDetails from '../../hooks/use-locked-details';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';

const FamilyName: React.FunctionComponent = () => {
	const { value, validation, onChange } = useProfileInternalChangeRequest('familyName', notEmptyValidator);
	const error = useOnPageError(validation, 'last_name_input');
	const locked = useLockedDetails();

	return (
		<QuoteInputDecorator label={'Last name'} error={error}>
			<TextInput
				disabled={locked}
				autoComplete={'family-name'}
				value={value ?? ''}
				onChange={e => onChange(e.target.value)}
				data-event-onfocus-name={'last_name_input_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default FamilyName;
