import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getSubscriptionUser } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherGetSubscriptionUser() {
    yield takeEvery(LtmActionTypes.GET_SUBSCRIPTION_USER, workerGetSubscriptionUser);
}
function* workerGetSubscriptionUser({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.getSubscriptionUser, client, payload);
        yield put(getSubscriptionUser.success(ident, response.userId));
    }
    catch (error) {
        yield put(getSubscriptionUser.failure(ident, error));
    }
}
