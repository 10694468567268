import { call, getContext, put } from 'redux-saga/effects';
import { getLatestKycOps } from '../actions';
import * as api from '../api';
import { fileById } from '~lib/platform/upload/store/actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getLatestKycOps.request, function* getLatestKYCOpsSaga(action) {
    const client = yield getContext('api');
    const { ident } = action.meta;
    try {
        const response = yield call(api.getLatestKycOps, client, action.payload);
        yield put(getLatestKycOps.success(ident, response));
        if (response.licensePhotoId)
            yield put(fileById.request({ fileId: response.licensePhotoId, urlTtl: 3600, browserCache: true }));
        if (response.profilePhotoId)
            yield put(fileById.request({ fileId: response.profilePhotoId, urlTtl: 3600, browserCache: true }));
    }
    catch (error) {
        yield put(getLatestKycOps.failure(ident, error));
    }
});
