const analyticsEvents = {
	entryPointLocked: {
		screenName: 'stm_referred_lender_owner_entry_point_locked',
		createAccountCtaEvent: 'stm_referred_lender_owner_entry_point_locked_create_account_action',
		signInLinkEvent: 'stm_referred_lender_owner_entry_point_locked_signin_link_action',
		variantConsentEvent: 'stm_referred_lender_owner_entry_point_locked_consent_action',
		variantScreenName: 'stm_referred_lender_owner_entry_point_locked_form',
	},
	entryPointCreateAccount: {
		screenName: 'stm_referred_lender_owner_entry_point_create_account',
		createAccountCtaEvent: 'stm_referred_lender_owner_entry_point_create_account_cta_action',
		signInLinkEvent: 'stm_referred_lender_owner_entry_point_create_account_signin_link_action',
		emailEnteredEvent: 'stm_referred_lender_owner_entry_point_create_account_email_entered_action',
		createAccountErrorEvent: 'stm_referred_lender_owner_entry_point_create_account_error_action',
		controlActionScreen: 'stm_referred_lender_owner_entry_point_create_account_control_action',
		joinFollowScreen: 'stm_referred_lender_owner_entry_point_create_account_join_follow_action',
		knowMoreScreen: 'stm_referred_lender_owner_entry_point_create_account_know_more_action',
	},
	entryPointLandingPage: {
		screenName: 'stm_referred_lender_owner_entry_point_landing_page',
		createAccountCtaEvent: 'stm_referred_lender_owner_entry_point_landing_page_cta_action',
		createAccountPage: 'stm_referred_lender_owner_entry_point_landing_page_create_account',
		createAccountPageCtaEvent: 'stm_referred_lender_owner_entry_point_landing_page_create_account_cta_action',
		signInLinkEvent: 'stm_referred_lender_owner_entry_point_landing_page_signin_link_action',
		emailEnteredEvent: 'stm_referred_lender_owner_entry_point_create_account_email_entered_action',
		createAccountErrorEvent: 'stm_referred_lender_owner_entry_point_create_account_error_action',
	},
	ownersHub: {
		screenName: 'stm_referred_lender_owner_owners_hub',
		vehicleDetailsCta: 'stm_referred_lender_owner_owners_hub_vehicle_details_action',
		policyDocsCta: 'stm_referred_lender_owner_owners_hub_policy_docs_action',
		drivingHighlightsCta: 'stm_referred_lender_owner_owners_hub_driving_highlights_action',
		lenderMilestonesCta: 'stm_referred_lender_owner_owners_hub_lender_milestones_action',
		tripHistoryCta: 'stm_referred_lender_owner_owners_hub_trip_history_action',
		compareWithFriendsCta: 'stm_referred_lender_owner_owners_hub_compare_with_friends_action',
	},
};

export default analyticsEvents;
