import React from 'react';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import useSubsUtils from '../hooks/use-subs-utils';
import { formatDate } from '../utils/formatDateTime';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Typography from '~lib/frontend/design-system/components/Typography';

const FailedPaymentCallout: React.FCWithChildren = () => {
	const subs = useSinglePolicy();
	const { nextBillingDate } = useSubsUtils(subs);
	const billingDate = nextBillingDate?.nextBillingDate;
	const formattedDate = formatDate(billingDate).date;

	const checkFailedPayment = subs.subInfo?.actionsRequired && 'paymentFailed' in subs.subInfo.actionsRequired;

	if (!checkFailedPayment) return null;

	return (
		<React.Fragment>
			<Margin $marginBottom={'4xLarge'}>
				<Banner.Wrapper type={'error'} icon={'ic_exclamation_circle'}>
					<Typography $type={'Body.Small'} $color={'textOnFill'}>
						{`Your last payment failed. Pay in the Cuvva app before ${formattedDate} to stay insured. We’ll keep trying to take payment before the end of your policy.`}
					</Typography>
				</Banner.Wrapper>
			</Margin>
		</React.Fragment>
	);
};

export default FailedPaymentCallout;
