import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import { TwoColumnsImageTag } from '~website/components/atoms/layout/TwoColumns';
import TwoColumnsFixedLayout from '~website/components/atoms/layout/TwoColumnsFixed';
import { TitleSegmentStepsProps } from '~website/components/atoms/TitleSegmentSteps';
import TrustSegment from '~website/components/atoms/TrustSegment';
import HeaderHeroWrap from '~website/components/Hero/HeaderHeroWrap';
import Footer from '~website/components/layout/footer';
import Header from '~website/components/layout/header';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { BuilderSections, SectionDefinition } from '~website/features/builder';
import { FlexContainer, InnerContent, PaddedInner, SectionWrapper } from '~website/features/dynamic-seo/atoms/Header';
import BrandSubHeader from '~website/features/dynamic-seo/organisms/BranSubHeader';
import { TitleContainer } from '~website/features/tools/Containers';
import { oneLink } from '~website/helpers/source-tracking';

interface CarInsuranceByBrandProps {
	contentKey?: string;
}

const CarInsuranceByBrand: React.FCWithChildren<CarInsuranceByBrandProps> = ({ contentKey }) => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.${contentKey}.${key}`, fallback);
	const carSharingIl = useDesignSystemAsset({ type: 'illustration', value: 'il_boy_girl_sharing_car_simple.svg' });
	const sectionGetter: GetterType = (key, fallback) => get(`sections${key}`, fallback);
	const sections: SectionDefinition[] = get('sections');

	const params = useParams<{ brand: string }>();
	const type = params.brand;

	const carType = type
		.split('-')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

	const stepsContent: TitleSegmentStepsProps = {
		title: 'How to get Temporary car insurance on a ',
		steps: [
			{
				title: 'Get started on the app',
				body: 'It just takes a few clicks',
			},
			{
				title: 'Find your car',
				body: 'Enter your registration plate',
			},
			{
				title: 'Check your cover and pay',
				body: 'Get cover from as little as 1 hour up to 28 days',
			},
			{
				title: 'Extend whenever you need',
				body: 'If you need more time than you thought',
			},
		],
	};

	const insuranceStepsTitle = `${get('insurance_steps.title')} ${carType} fast`;

	return (
		<React.Fragment>
			<Helmet defer={false}>
				<title>{`Insure a ${carType} with ${get('head.title')}`}</title>
				<meta name={'description'} content={get('head.description')} />
				<meta property={'og:locale'} content={'en_GB'} />
				<meta property={'og:type'} content={'website'} />
				<meta property={'og:title'} content={get('head.social_title')} />
				<meta property={'og:description'} content={get('head.social_description')} />
				<meta property={'og:url'} content={`${get('head.canonical_url')}/${type}`} />
				<meta property={'og:site_name'} content={'Cuvva'} />
				<meta name={'twitter:site'} content={'@Cuvva'} />
				<meta name={'twitter:creator'} content={'@cuvva'} />
				{Boolean(get('noindex')) && <meta name={'robots'} content={'noindex'} />}
				<link rel={'canonical'} href={`${get('head.canonical_url')}/${type}`} />
			</Helmet>
			<VisionaryContext.Provider value={true}>
				<Header contentUnder />
				<HeaderHeroWrap $themeKey={'dark'} $gradient={'center'} $backgroundImage={void 0} $hasSubHero={false}>
					<PaddedInner>
						<InnerContent>
							<TwoColumnsFixedLayout>
								<TitleContainer>
									<Typography as={'h1'} $type={'Heading.XLarge'} $color={'textOnFill'}>
										{`${get('main_title')} ${carType}`}
									</Typography>
									<Typography
										as={'h2'}
										$type={'Body.Large'}
										$marginTop={'extraLarge'}
										$color={'textOnFill'}
									>
										{get('main_sub')}
									</Typography>

									<FlexContainer>
										<Margin $marginTop={'large'}>
											<Button
												// @ts-ignore
												href={get('cta.url')}
												as={Anchor}
												$type={'neutral'}
												$size={'large'}
											>
												{get('cta.text')}
											</Button>
										</Margin>

										<Margin $marginTop={'large'}>
											<AppStoreButtonsSegment url={oneLink} />
										</Margin>
										<Margin $marginTop={'large'}>
											<TrustSegment trustProvider={'trustpilot'} />
										</Margin>
									</FlexContainer>
								</TitleContainer>
								<TwoColumnsImageTag
									src={carSharingIl}
									alt={'Illustration of boy and girl sharing car'}
								/>
							</TwoColumnsFixedLayout>
						</InnerContent>
					</PaddedInner>
				</HeaderHeroWrap>

				<SectionWrapper>
					<BrandSubHeader steps={stepsContent.steps} title={insuranceStepsTitle} />
				</SectionWrapper>

				<BuilderSections sections={sections} getter={sectionGetter} />
				<Footer />
			</VisionaryContext.Provider>
		</React.Fragment>
	);
};

export default CarInsuranceByBrand;
