import React from 'react';
import styled from 'styled-components';
import Icon from '~lib/frontend/design-system/components/Icon';
const RoundContainer = styled.div `
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${p => p.$size || '32'}px;
	height: ${p => p.$size || '32'}px;
	background: ${p => p.theme.ui[p.$secondary || 'primaryFillMuted']};
`;
const CircleIcon = ({ icon, size, primaryColor, secondaryColor }) => (React.createElement(RoundContainer, { "$primary": primaryColor, "$secondary": secondaryColor, "$size": size },
    React.createElement(Icon, { icon: icon, "$size": '62%', "$color": primaryColor || 'primaryFill' })));
export default CircleIcon;
