import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { errorCodes } from '~lib/platform/code-mapping/store/actions';
import { trackEvent, trackScreenView } from '~website/features/analytics/store/actions';

interface SignInProps {
	doSignIn: () => void;
	fetching: boolean;
	error: Cher;
	emailAddress: string;
	setEmailAddress: React.Dispatch<React.SetStateAction<string>>;
}

const DoSignIn: React.FCWithChildren<SignInProps> = props => {
	const { doSignIn, error, fetching, emailAddress, setEmailAddress } = props;

	const dispatch = useDispatch();

	const errorCodesStore = useSelector(s => s.platform.codeMapping.errorCodes);
	const errorCodesStatus = useAsyncStateStatus(errorCodesStore).status;
	const mappedError = errorCodesStore.response?.find(c => c.code === error?.code)?.message;

	useEffect(() => {
		if (errorCodesStatus === 'pending')
			dispatch(errorCodes.request(void 0));
	}, [dispatch, errorCodesStatus]);

	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.auth.signin.${key}`, fallback);

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') doSignIn();
	};

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: 'sign_in_email_viewed',
				eventPayload: {},
			}),
		);
	}, [dispatch]);

	return (
		<BodyContent>
			<div>
				<Typography
					$type={'Heading.Medium'}
					$color={'textOnSurfaceBackground'}
					$marginBottom={'regular'}
					$align={'center'}
				>
					{get('title', 'Welcome back 👋')}
				</Typography>

				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $align={'center'}>
					{get('body', 'Enter your email address and we’ll send you a link to sign in')}
				</Typography>
			</div>

			<InputDecorator
				label={'Email address'}
				error={mappedError}
				onFocus={() =>
					dispatch(
						trackEvent({
							eventName: 'email_input_selected',
						}),
					)
				}
			>
				<TextInput
					type={'email'}
					value={emailAddress}
					onChange={e => setEmailAddress(e.target.value)}
					onKeyDown={onKeyDown}
				/>
			</InputDecorator>

			<div>
				<Button
					disabled={!emailAddress}
					$loading={fetching}
					onClick={doSignIn}
					$type={'primary'}
					$size={'large'}
					$stretch
				>
					{get('submit', 'Sign in with email')}
				</Button>

				<NoAccount>
					<Typography
						$type={'Body.Medium'}
						$color={'textOnSurfaceBackground'}
						$display={'inline'}
						$align={'center'}
					>
						{'No account?'}
					</Typography>
					<Typography
						as={Anchor}
						to={'/vehicles'}
						$type={'Body.Medium'}
						$color={'textOnSurfaceBackground'}
						$display={'inline'}
						$align={'center'}
						onClick={() => dispatch(trackEvent({
							eventName: 'sign_in_email_actioned',
							eventPayload: {
								action: 'new_account',
							} }),
						)}
					>
						{'Get a quote'}
					</Typography>
				</NoAccount>
			</div>
		</BodyContent>
	);
};

const BodyContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const NoAccount = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	text-align: center;

	a,
	a:hover,
	a:visited {
		margin-left: ${p => p.theme.spacing.small};
		color: ${p => p.theme.ui.textHighlight};
		text-decoration: none;
	}
`;

export default DoSignIn;
