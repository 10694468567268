import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import useSubsUtils from '../hooks/use-subs-utils';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Icon from '~lib/frontend/design-system/components/Icon';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';

interface SinglePolicyHeroProps {
	loading: boolean;
}

const SinglePolicyHero: React.FCWithChildren<SinglePolicyHeroProps> = props => {
	const { loading: policyStatus } = props;

	const sub = useSinglePolicy();
	const vehicleId = sub.vehicleId;

	const dispatch = useDispatch();
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleStatus = useAsyncStateStatus(vehicle).status !== 'response';

	const loading = policyStatus || vehicleStatus;
	const checkUpcoming = Boolean(sub?.subInfo?.upcomingPolicy);

	useEffect(() => {
		if (vehicleId)
			dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [dispatch, vehicleId]);

	return (
		<div>
			<PolicyBadge loading={loading} />

			<Typography
				$color={'textOnFill'}
				$type={'Heading.Large'}
				$marginTop={'extraSmall'}
			>
				{'Manage Policy'}
			</Typography>

			<Margin $marginTop={'extraLarge'}>
				<Flex>
					<Icon $size={'20px'} icon={'ic_repeat'} $color={'blankFill'} />
					<Typography $marginLeft={'regular'} $type={'Body.Medium'} $bold $color={'textOnFill'}>
						{loading && <SkeletonLoader width={'100px'} height={'18px'} />}
						{!loading && 'Monthly subscription'}
					</Typography>
				</Flex>

				<Flex>
					<Icon $size={'20px'} icon={'ic_car'} $color={'blankFill'} />
					<Margin $marginLeft={'regular'}>
						<Typography
							$bold
							$display={'inline'}
							$type={'Body.Medium'}
							$color={'textOnFill'}
						>
							{loading && <SkeletonLoader width={'120px'} height={'18px'} />}
							{!loading && [vehicle?.response?.make, vehicle?.response?.model].join(' ')}
							{' · '}
						</Typography>
						<Typography
							$bold
							$display={'inline'}
							$type={'Body.Medium'}
							$color={'textOnFill'}
						>
							{loading && <SkeletonLoader width={'80px'} height={'18px'} />}
							{!loading && vehicle?.response?.prettyVrm}
						</Typography>
					</Margin>
				</Flex>

				<Flex>
					<Icon
						$size={'20px'}
						$color={'blankFill'}
						icon={checkUpcoming ? 'ic_card_payment' : 'ic_calendar_tomorrow'}
					/>
					<Margin $marginLeft={'regular'}>
						<PolicyTimeline loading={loading} />
					</Margin>
				</Flex>
			</Margin>
		</div>
	);
};

export default SinglePolicyHero;

interface PolicyItemsProps {
	loading: boolean;
}

const PolicyBadge: React.FCWithChildren<PolicyItemsProps> = props => {
	const { loading } = props;
	const sub = useSinglePolicy();
	const utils = useSubsUtils(sub);

	if (loading) return <SkeletonLoader width={'60px'} height={'18px'} />;

	const { badgeType } = utils;

	return <Badge size={'small'} type={badgeType.type}>{badgeType.text}</Badge>;
};

const PolicyTimeline: React.FCWithChildren<PolicyItemsProps> = props => {
	const { loading } = props;
	const sub = useSinglePolicy();
	const utils = useSubsUtils(sub);

	if (loading) return <SkeletonLoader width={'220px'} height={'18px'} />;

	const { timeline } = utils;

	return <Typography $bold $type={'Body.Medium'} $color={'textOnFill'}>{timeline}</Typography>;
};

const Flex = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	&:not(:last-of-type) {
		margin-bottom: ${p => p.theme.spacing.small};
	}
`;
