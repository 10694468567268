import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface ToolLinkProps {
	title: string;
	url: string;
	subtitle: string;
	illustrationName: string;
}

const ToolLink: React.FCWithChildren<ToolLinkProps> = props => {
	const { title, url, subtitle, illustrationName } = props;
	const illustrationAsset = useDesignSystemAsset({ type: 'illustration', value: illustrationName });

	return (
		<ToolLinkContainer to={url}>
			<ToolLinkContent>
				<Typography $type={'Body.Large'} $color={'textOnFill'} $bold>{title}</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnFill'}>{subtitle}</Typography>
			</ToolLinkContent>
			<ToolIllustration>
				<img src={illustrationAsset} />
			</ToolIllustration>
		</ToolLinkContainer>
	);
};

const ToolLinkContainer = styled(Anchor)`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 4px 0 4px ${p => p.theme.spacing.regular};
	background: ${p => p.theme.ui.surfaceDark};
	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	border-radius: ${p => p.theme.borderRadius.small};
	position: relative;
	width: 340px;
	height: 137px;
	${media.greaterThan('tablet')`
		width: 370px;
	`}
	margin-bottom: ${p => p.theme.spacing.extraLarge};
`;

const ToolLinkContent = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 175px;
`;

const ToolIllustration = styled.div`
	> img {
		max-width: 210px;
		position: absolute;
		bottom: -30px;
		width: 200px;
		left: 150px;
		${media.greaterThan('tablet')`
			left: 181px;
		`}
	}
`;

export default ToolLink;
