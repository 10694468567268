import React from 'react';

import { IlloWrapper } from '../../molecules/MakeAClaim';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface CancelASubscriptionModalProps {
	open: boolean;
	onClose: () => void;
}

const CancellationFeedback: React.FCWithChildren<CancelASubscriptionModalProps> = props => {
	const { open, onClose } = props;

	const illustration = useDesignSystemAsset({ type: 'illustration', value: 'il_pigeon_driving_waving.svg' });

	return (
		<Alert.Dialog
			open={open}
			onClose={onClose}
			title={'We\'ve cancelled your subscription'}
			illustration={
				<IlloWrapper>
					<picture>
						<img src={illustration} alt={'chat head'} />
					</picture>
				</IlloWrapper>
			}
		>
			<Alert.Content>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $marginBottom={'large'}>
					{'We’re sorry to see you go. If you need anything else, just let us know.'}
				</Typography>
			</Alert.Content>
			<Alert.Actions>
				<Button $type={'primary'} onClick={onClose}>
					{'Got it'}
				</Button>
			</Alert.Actions>
		</Alert.Dialog>
	);
};

export default CancellationFeedback;
