import React, { FunctionComponent } from 'react';

import { ReportingToWrapper } from '../atoms/ReportingToWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';

interface NextStepProps {
	title: string;
	description: string;
}

const NextStep: FunctionComponent<NextStepProps> = ({ title, description }) => (
	<ReportingToWrapper>
		<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginBottom={'small'}>
			{title}
		</Typography>
		<Typography $type={'Body.XLarge'}>{description}</Typography>
	</ReportingToWrapper>
);

export default NextStep;
