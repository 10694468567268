import { all, fork } from 'redux-saga/effects';
import cancelPolicy from './cancel-policy';
import estimate from './estimate';
import listEvents from './list-events';
import quote from './quote';
export default function* motorCoverageSaga() {
    yield all([
        fork(cancelPolicy),
        fork(estimate),
        fork(listEvents),
        fork(quote),
    ]);
}
