import React from 'react';
import styled from 'styled-components';

import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

const FCABlock: React.FunctionComponent = () => (
	<Wrap>
		<Icon icon={'ic_check_circle'} $color={'textOnFill'} $size={'24px'} />
		<Typography $type={'Label.Small'} $color={'textOnFill'}>
			{'FCA Authorised and regulated'}
		</Typography>
	</Wrap>
);

const Wrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: ${p => p.theme.spacing.small};
`;

export default FCABlock;
