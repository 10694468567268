import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import EditPrimaryIdentifier from '../organisms/EditPrimaryIdentifier';
import ListItem from './ListItem';
import { sortIso8601 } from '~lib/frontend/helpers/sort';
import { User, UserIdentifierType } from '~lib/platform/auth/types';
import useUser from '~website/hooks/use-user';

interface EditableIdentifierListItemProps {
	name: string;
	type: 'email' | 'mobile_phone';
}

const EditableIdentifierListItem: React.FCWithChildren<EditableIdentifierListItemProps> = ({ name, type }) => {
	const { userId } = useUser();
	const userById = useSelector(s => s.platform.auth.userById[`${userId}-false`]);

	const identifiers = getIdentifiers(userById?.response, type);

	const primary = identifiers.primary?.value;
	const others = identifiers.others.map(i => i.value);

	const body = (
		<Fragment>
			{primary}
			{others.length > 0 && (
				<Fragment>
					<br />
					{`and ${others.length} more`}
				</Fragment>
			)}
		</Fragment>
	);

	return (
		<ListItem label={name} body={body} locked={false} action={
			<EditPrimaryIdentifier type={type} />
		} />
	);
};

export default EditableIdentifierListItem;

function getIdentifiers(user: User | undefined, type: UserIdentifierType) {
	if (!user || !user.identifiers) {
		return {
			primary: void 0,
			others: [],
		};
	}

	const identifiers = user.identifiers.sort(sortIso8601(e => e.attachedAt));

	const primary = identifiers.find(i => i.type === type && i.primary);
	const others = identifiers.filter(i => i.type === type && i.value !== primary.value && !i.removedAt);

	return {
		primary,
		others,
	};
}
