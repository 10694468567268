import React, { forwardRef, useContext } from 'react';
import Text from '../atoms/Text';
import InputDecoratorContext from '../context/decorator';
const TextInput = forwardRef((props, ref) => {
    const { error } = useContext(InputDecoratorContext);
    const { type, ...rest } = props;
    return (React.createElement(Text, { ref: ref, "$error": Boolean(error), 
        // we need to add this cos mobile safari doesn't love you 💔
        // it has to do with borders how it renders them based on if the type is set or not.
        type: type || 'text', ...rest }));
});
export default TextInput;
