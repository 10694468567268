import Cher from '@cuvva/cher';

import validPostcodeValidator from './valid-postcode-validator';
import { Address } from '~lib/platform/profile/types';

const validAddressValidator = (value: Address | null | undefined) => {
	if (!value)
		return new Cher('invalid');

	const lines = value.lines.filter(l => Boolean(l)).map(l => l.trim())
		.filter(l => Boolean(l));

	if (![1, 2].includes(lines.length))
		return new Cher('invalid');

	const { postalCode, locality } = value;

	if (![postalCode, locality].map(s => s.trim()).every(Boolean))
		return new Cher('invalid');

	if (validPostcodeValidator(postalCode) !== void 0)
		return new Cher('invalid');

	return void 0;
};

export default validAddressValidator;
