import { all, fork } from 'redux-saga/effects';
import attemptCycleGeneration from './attempt-cycle-generation';
import config from './config';
import confirmCancellation from './confirm-cancellation';
import createEstimate from './create-estimate';
import createQuote from './create-quote';
import createSubscription from './create-subscription';
import getAdditionalDriver from './get-additional-driver';
import getNextBillingDate from './get-next-billing-date';
import getProfile from './get-profile';
import getSubscriptionByPolicy from './get-subscription-by-policy';
import getSubscriptionDetail from './get-subscription-detail';
import getSubscriptionUser from './get-subscription-user';
import listEvents from './list-events';
import listPoliciesBySubscription from './list-policies-by-subscription';
import listSubscriptions from './list-subscriptions';
import listTransactionsByPolicy from './list-transactions-by-policy';
import processCycle from './process-cycle';
import pushFailedPayment from './push-failed-payment';
import quoteAutocycle from './quote-autocycle';
import quoteCancellation from './quote-cancellation';
import regenerateQuote from './regenerate-quote';
import setDetails from './set-details';
import startPricing from './start-pricing';
import updatePricing from './update-pricing';
export default function* ltmSaga() {
    yield all([
        attemptCycleGeneration,
        createEstimate,
        createQuote,
        createSubscription,
        fork(config),
        fork(confirmCancellation),
        fork(getNextBillingDate),
        getProfile,
        getSubscriptionDetail,
        fork(getSubscriptionUser),
        fork(listEvents),
        fork(processCycle),
        fork(pushFailedPayment),
        fork(quoteAutocycle),
        fork(quoteCancellation),
        fork(regenerateQuote),
        fork(setDetails),
        fork(startPricing),
        fork(updatePricing),
        listPoliciesBySubscription,
        listSubscriptions,
        listTransactionsByPolicy,
        getSubscriptionByPolicy,
        getAdditionalDriver,
    ]);
}
