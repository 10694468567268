import React, { useEffect } from 'react';
import { useFrontload } from 'react-frontload';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import TwoColumnsLayout, { TwoColumnsImageTag } from './layout/TwoColumns';
import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { Anchor } from '~lib/frontend/atoms';
import Icon, { IconType } from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { getEnvironment } from '~lib/frontend/helpers/environment';
import { GetterType } from '~lib/frontend/types/content';
import { getFrontloadClient } from '~website/prerender/frontload-client';
import { listCuvvaPeeps } from '~website/store/about/actions';
import { listCuvvaPeepsFrontload } from '~website/store/about/sagas/list-cuvva-peeps';

interface Link {
	icon: IconType;
	link: string;
}

interface Section {
	heading: string;
	body: string;
}

interface Staff {
	id: string;
	name: string;
	role: string;
	links: Link[];
	sections: Section[];
}

interface TitleSegmentStaffHighlightProps extends TitleSegmentProps {
	staff: Staff[];
}

const TitleSegmentStaffHighlight: React.FCWithChildren<TitleSegmentStaffHighlightProps> = props => {
	const {
		staff,
		...titleSegmentProps
	} = props;

	return (
		<TitleSegment {...titleSegmentProps} centered>
			<Wrapper>
				{staff.map((item, i) => (
					<StaffSection
						key={`${item.name}:${i}`}
						staff={item}
						flip={i % 2 > 0}
					/>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentStaffHighlightProps => {
	const staff = get<Staff[]>('staff');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		staff,
		...titleSegment,
	};
};

const Wrapper = styled.div`
	position: relative;
	text-align: left;
	display: block;
	margin: 120px auto 0 auto;
	width: 100%;

	> * + * {
		margin-top: 240px;
	}
`;

const IconsWrap = styled.div`
	margin-top: 48px;
`;

const SectionsWrap = styled.div`
	margin-top: 48px;
	max-width: 500px;

	& > div + div {
		margin-top: 24px;
	}
`;

const ImageEffect = styled.div`
	width: 100%;
	height: 100%;
`;

const LargeEmoji = styled(TwoColumnsImageTag)`
	display: flex;
	font-size: 8em;
	justify-content: center;
	align-items: center;
`;

const StaffSection: React.FCWithChildren<{ staff: Staff; flip: boolean }> = props => {
	const { staff, flip } = props;
	const dispatch = useDispatch();

	useFrontload('service-staff', async () => {
		await listCuvvaPeepsFrontload(getFrontloadClient(), dispatch);
	});

	useEffect(() => {
		if (getEnvironment() === 'browser') dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	return (
		<TwoColumnsLayout flip={flip}>
			<div>
				<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>
					{staff.name}
				</Typography>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{staff.role}
				</Typography>
				{staff.links?.length > 0 && (
					<IconsWrap>
						{staff.links.map(link => (
							<Icon
								key={link.icon}
								icon={link.icon}
								as={Anchor}
								// @ts-ignore
								to={link.link}
								$size={'24px'}
								$color={'primaryFill'}
								$marginRight={'24px'}
							/>
						))}
					</IconsWrap>
				)}

				<SectionsWrap>
					{staff.sections.map((section, index) => (
						<div key={index}>
							<Typography $type={'Body.Large'} $color={'textOnSurfaceBackgroundMuted'}>
								{section.heading}
							</Typography>
							<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'}>
								{section.body}
							</Typography>
						</div>
					))}
				</SectionsWrap>
			</div>
			<StaffImage staffId={staff.id} />
		</TwoColumnsLayout>
	);
};

const StaffImage: React.FCWithChildren<{ staffId: string }> = ({ staffId }) => {
	const staffStore = useSelector(s => s.internal.about.listCuvvaPeeps);

	const staff = staffStore.response?.find(s => s.id === staffId);

	if (!staff)
		return <div />;

	const normalPhoto = staff.about?.images?.normal;
	const emoji = staff.emoji;

	return (
		<ImageEffect>
			{normalPhoto && (
				<TwoColumnsImageTag src={normalPhoto} />
			)}
			{!normalPhoto && (
				<LargeEmoji as={'div'}>{emoji}</LargeEmoji>
			)}
		</ImageEffect>
	);
};

export default TitleSegmentStaffHighlight;
