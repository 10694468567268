import styled from 'styled-components';

export const DownloadStepsList = styled.ul`
	display: inline-block;
	list-style: none outside none;
	padding: 0;
	margin: 0;
`;

export const DownloadStepsListItem = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${p => p.theme.spacing.large};

	&:first-child {
		margin-top: 0;
	}

	&>:nth-child(1) {
		margin-right: ${p => p.theme.spacing.large};
	}
`;

export const IconWrap = styled.div`
	width: 24px;
	height: 24px;
`;

export const CurrentCircle = styled.div`
	width: 20px;
	height: 20px;
	margin: 2px;

	border-radius: 50%;
	background: ${p => p.theme.ui.primaryFillMuted};
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&::after {
		content: " ";
		position: relative;
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background: ${p => p.theme.ui.primaryFill};
	}
`;

export const EmptyCircle = styled.div`
	width: 8px;
	height: 8px;
	margin: 8px;
	border-radius: 50%;
	background: ${p => p.theme.ui.neutralFillMinorMuted};
`;
