import { call, getContext, put } from 'redux-saga/effects';
import { getDocumentBase as getDocumentBaseApi } from '../../api';
import { getDocumentBase } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getDocumentBase.request, function* getDocBaseSaga() {
    const client = yield getContext('api');
    try {
        const response = yield call(getDocumentBaseApi, client);
        yield put(getDocumentBase.success(response));
    }
    catch (error) {
        yield put(getDocumentBase.failure(error));
    }
});
