import React, { useState } from 'react';

import useFriendlyError from '../../hooks/use-friendly-error';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import validAddressValidator from '../../validators/valid-address';
import NewAddressLookup from '../molecules/address-lookup/new/AddressLookup';
import Typography from '~lib/frontend/design-system/components/Typography';

interface ResidentialAddressProps {
	initialPostcode: string | undefined;
}

const ResidentialAddress: React.FCWithChildren<ResidentialAddressProps> = ({ initialPostcode }) => {
	const [postcode] = useState(() => initialPostcode);

	const { value, onChange } = useProfileInternalChangeRequest('residentialAddress');
	const address = value ?? void 0;
	const validation = validAddressValidator(address);
	const pageError = useOnPageError(validation, 'address_lookup');
	const error = useFriendlyError(pageError, 'profile', 'residentialAddress');

	return (
		<div>
			<Typography
				$type={'Heading.XSmall'}
				$color={'textOnSurfaceBackground'}
			>
				{'Your address'}
			</Typography>

			<NewAddressLookup
				postcode={postcode}
				address={address}
				onAddressChange={onChange}
				error={error}
			/>
		</div>
	);
};

export default ResidentialAddress;
