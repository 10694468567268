import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';

const workplaceImages = [
	{
		long: '/static/images/office-vibes/long-1.jpeg',
		halfFirst: '/static/images/office-vibes/small-2.jpeg',
		halfSecond: '/static/images/office-vibes/small-3.jpeg',
		full: '/static/images/office-vibes/short-1.jpeg',
	},
];

interface WorkplaceVibesProps {
	title: string;
	body: string;
}

const WorkplaceVibes: React.FCWithChildren<WorkplaceVibesProps> = ({ title, body }) => (
	<Wrapper>
		<TitleContainer>
			<Typography $color={'textOnSurfaceBackground'} $type={'Heading.Large'}>{title}</Typography>
			<Typography $color={'textOnSurfaceBackgroundMuted'} $type={'Body.XLarge'} $marginTop={'extraLarge'}>{body}</Typography>
		</TitleContainer>

		<VibeyGrid>
			<Image src={workplaceImages[0].long} />
			<Image src={workplaceImages[0].full} />
			<Image src={workplaceImages[0].halfFirst} />
			<Image src={workplaceImages[0].halfSecond} />
		</VibeyGrid>
	</Wrapper>
);

const Wrapper = styled.div``;

const TitleContainer = styled.div`
	margin: 0 auto;
	margin-bottom: 64px;
	max-width: 512px;
	text-align: center;
`;

const VibeyGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-gap: ${p => p.theme.spacing.extraLarge};

	max-width: 1150px;
`;

const Image = styled.img`
	border-radius: ${p => p.theme.borderRadius.large};
	overflow: hidden;
	object-fit: cover;
	width: 100%;

	&:nth-child(1) {
		grid-column: 1 / 3;
	}
	&:nth-child(2) {
		grid-row: 1 / 3;
		grid-column: 3 / 3;
	}
`;

export const contentAdapter = (get: GetterType): WorkplaceVibesProps => {
	const title = get('title');
	const body = get('body');

	return {
		title,
		body,
	};
};

export default WorkplaceVibes;
