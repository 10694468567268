import { DESTRUCTIVE, DESTRUCTIVEMUTED, NEUTRAL, NEUTRALMUTED, NEUTRALMUTEDONFILL, OUTLINE, PRIMARY, PRIMARYMUTED, TEXT, TEXTMUTED, TEXTONFILL, } from './types';
import { TypedObject } from '~lib/shared/helpers/typed';
export const getStyleForState = (state) => {
    if (Object.keys(state).length === 0)
        return '';
    const parseStyles = TypedObject.keys(state).map(key => `${key}: ${state[key]};`);
    return parseStyles.join('');
};
export const getButtonTextColors = (theme, buttonType) => {
    const { textOnSurfaceBackgroundMuted, textOnSurfaceBackground } = theme.ui;
    const { textOnAction, dangerAction, primaryAction } = theme.ui;
    switch (buttonType) {
        case 'outline':
        case 'text':
        case 'primaryMuted':
            return primaryAction;
        case 'textMuted':
            return textOnSurfaceBackgroundMuted;
        case 'neutral':
        case 'textOnFill':
        case 'neutralMuted':
            return textOnSurfaceBackground;
        case 'destructiveMuted':
            return dangerAction;
        case 'destructive':
        case 'primary':
        case 'neutralMutedOnFill':
        default:
            return textOnAction;
    }
};
export function states(theme) {
    return {
        [PRIMARY]: {
            disabled: { background: theme.ui.primaryAction },
            hover: { background: 'rgba(0, 0, 0, 0.04)' },
            focus: { background: 'rgba(0, 0, 0, 0.12)' },
            press: { background: 'rgba(0, 0, 0, 0.12)' },
        },
        [PRIMARYMUTED]: {
            disabled: { background: theme.ui.primaryActionMuted },
            hover: { background: theme.ui.primaryAction, opacity: 0.08 },
            focus: { background: theme.ui.primaryAction, opacity: 0.24 },
            press: { background: theme.ui.primaryAction, opacity: 0.32 },
        },
        [DESTRUCTIVE]: {
            disabled: { background: theme.ui.dangerAction },
            hover: { background: 'rgba(0, 0, 0, 0.04)' },
            focus: { background: 'rgba(0, 0, 0, 0.12)' },
            press: { background: 'rgba(0, 0, 0, 0.12)' },
        },
        [DESTRUCTIVEMUTED]: {
            disabled: { background: theme.ui.dangerActionMuted },
            hover: { background: theme.ui.dangerAction, opacity: 0.08 },
            focus: { background: theme.ui.dangerAction, opacity: 0.24 },
            press: { background: theme.ui.dangerAction, opacity: 0.32 },
        },
        [OUTLINE]: {
            disabled: { background: theme.ui.blankFill },
            hover: { background: theme.ui.primaryAction, opacity: 0.08 },
            focus: { background: theme.ui.primaryAction, opacity: 0.24 },
            press: { background: theme.ui.primaryAction, opacity: 0.32 },
        },
        [TEXT]: {
            disabled: { opacity: 0.4 },
            hover: {},
            focus: {},
            press: {},
        },
        [TEXTMUTED]: {
            disabled: { background: theme.ui.blankFill },
            hover: { background: theme.ui.textOnSurfaceBackground, opacity: 0.04 },
            focus: { background: theme.ui.textOnSurfaceBackground, opacity: 0.12 },
            press: { background: theme.ui.textOnSurfaceBackground, opacity: 0.12 },
        },
        [TEXTONFILL]: {
            disabled: { background: theme.ui.blankFill },
            hover: { background: theme.ui.blankFill, opacity: 0.08 },
            focus: { background: theme.ui.blankFill, opacity: 0.24 },
            press: { background: theme.ui.blankFill, opacity: 0.32 },
        },
        [NEUTRAL]: {
            disabled: { background: theme.ui.blankFill },
            hover: { background: theme.ui.textOnSurfaceBackground, opacity: 0.08 },
            focus: { background: theme.ui.textOnSurfaceBackground, opacity: 0.04 }, // confirm
            press: { background: theme.ui.textOnSurfaceBackground, opacity: 0.04 }, // confirm
        },
        [NEUTRALMUTED]: {
            disabled: { background: 'rgba(190, 190, 198, 0.2)' },
            hover: { background: 'rgba(190, 190, 198, 0.2)' },
            focus: { background: 'rgba(190, 190, 198, 0.2)' }, // confirm
            press: { background: 'rgba(190, 190, 198, 0.2)' }, // confirm
        },
        [NEUTRALMUTEDONFILL]: {
            disabled: { background: theme.ui.neutralActionMutedOnFill, opacity: 0.2 },
            hover: { background: theme.ui.blankFill, opacity: 0.08 },
            focus: { background: theme.ui.blankFill, opacity: 0.24 },
            press: { background: theme.ui.blankFill, opacity: 0.32 },
        },
    };
}
