import React from 'react';
import styled from 'styled-components';

import Anchor from '~lib/frontend/atoms/Link';
import Typography from '~lib/frontend/design-system/components/Typography';
import Calendar from '~website/components/atoms/annas/_placeholders/Calendar';
import { CuvvaPeep } from '~website/store/about/types';

const Wrapper = styled.div<{ hero?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	padding: ${p => p.theme.spacing.extraLarge};
	border-radius: ${p => p.theme.borderRadius.regular};
	background: ${props => props.theme.ui.surface};
`;

const Image = styled(Anchor)`
	display: flex;
	margin-bottom: 30px;
	align-items: flex-start;
	flex: 1;
	height: auto;
	cursor: pointer;

	img {
		width: 100%;
		border-radius: 10px;
		max-height: 400px;
	}
`;

const StyledLink = styled(Typography)`
	display: block;

	&, &:hover, &:visited {
		color: ${p => p.theme.ui.textOnSurfaceBackground};
	}

	&:hover {
		color: ${p => p.theme.ui.textOnSurfaceBackground};
		text-decoration: underline;
	}
`;

const Information = styled.div`
	flex: 0;
	flex-wrap: wrap;
	margin-bottom: 30px;

	.title {
		font-size: 1.2rem;
		margin-bottom: 10px;
		text-align: left;
		margin-bottom: 30px;
		cursor: pointer;
	}

	a {
		text-decoration: none;
	}
`;

const Tag = styled.div`
	display: inline-block;
	border-radius: 100px;
	background: ${props => props.theme.ui.textHighlight};
	padding: 0 10px;
	font-size: 0.8rem;
	line-height: 30px;
	color: ${props => props.theme.ui.blankFill};
	cursor: pointer;
`;

const CalendarWrap = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	@media (max-width: 350px) {
		${Typography} {
			display: block;
		}
	}
`;

const AuthorWrap = styled.div`
	flex: 0;
	display: flex;
	flex-direction: row;
	align-items: center;

	&>* {
		flex: 1;
	}
`;

const InformationExtra = styled.div`
	display: flex;
	flex: 0;
	flex-direction: row;
	justify-content: space-between;

	p {
		display: inline-block;
		color: ${props => props.theme.ui.textOnSurfaceBackgroundMuted};
		font-weight: bold;
		font-size: 0.8rem;
		margin-bottom: 0;
	}

	.mask-img {
		position: absolute;
		left: 50%;
		top: 34px;
		transform: translate(-50%, -50%);
		width: 70px;
	}

	.icon {
		position: relative;
		display: inline-block;
		height: 30px;
		width: 30px;
		margin-right: 8px;
		vertical-align: middle;
		overflow: hidden;

		svg {
			width: 100%;
			height: auto;
		}

		&.img {
			border-radius: 50%;
		}
	}
`;

interface NewsItemProps {
	altMain: string;
	author?: CuvvaPeep;
	halfWidth?: boolean;
	imageLoad?: 'lazy' | 'eager';
	imgMain: string;
	postDate: string;
	tagMain: string;
	textMain: string;
	url: string;
}

const getNicePostDate = (postDate: string) => {
	if (!postDate) return '';

	const date = new Date(postDate);

	if (isNaN(date.getTime())) return '';

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		year: 'numeric',
		month: 'short',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const NewsItem: React.FCWithChildren<NewsItemProps> = props => {
	const {
		altMain,
		author,
		imageLoad,
		imgMain,
		postDate,
		tagMain,
		textMain,
		url,
	} = props;

	return (
		<Wrapper>
			<Image to={url}>
				<img
					alt={altMain}
					src={imgMain}
					loading={imageLoad}
				/>
			</Image>
			<Information>
				<StyledLink
					$bold
					// @ts-ignore
					as={Anchor}
					to={url}
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginBottom={'regular'}
				>
					{textMain}
				</StyledLink>
				<Tag>{tagMain}</Tag>
			</Information>
			<InformationExtra>
				<CalendarWrap>
					<div className={'icon'}>
						<Calendar />
					</div>

					<Typography $type={'Body.Small'} $color={'textOnSurfaceBackground'} $display={'inline-block'}>
						{getNicePostDate(postDate)}
					</Typography>
				</CalendarWrap>
				<AuthorWrap>
					<div className={'icon img'}>
						{author?.about?.images?.normal && (
							<img
								alt={author ? author.about.name : ''}
								className={'mask-img'}
								src={author?.about?.images?.normal}
							/>
						)}
						{!author?.about?.images?.normal && author?.emoji}
					</div>
					<Typography
						$type={'Label.Medium'}
						$color={'textOnSurfaceBackgroundMuted'}
					>
						{author?.about?.name ?? ''}
					</Typography>
				</AuthorWrap>
			</InformationExtra>
		</Wrapper>
	);
};

NewsItem.defaultProps = {
	imageLoad: 'lazy',
};

export default NewsItem;
