import Cher from '@cuvva/cher';

const useNotEmptyStorageValidator = (value: string | void) => {
	if (!value)
		return new Cher('invalid');

	return void 0;
};

export default useNotEmptyStorageValidator;
