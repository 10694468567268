import styled, { css } from 'styled-components';

import Container from './Container';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

interface SectionSegmentProps {
	$visionary: boolean;
	$background: string | void;
	$backgroundMobile: string | void;
}

const Section = styled(Container)<SectionSegmentProps>`
	${p => p.$visionary && css`
		background: ${p.theme.ui.secondaryBackground};
	`};

	${p => p.$backgroundMobile && p.$visionary && css`
		background: url(${p.$backgroundMobile}), ${p.theme.ui.secondaryBackground};
	`};

	background-size: cover;
	background-repeat: no-repeat;

	@media (min-width: 768px) {
		${p => p.$background && p.$visionary && css`
			background: url(${p.$background}), ${p.theme.ui.secondaryBackground};
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top;
		`}
	}

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

const Inner = styled.div`
	padding-top: 80px;
	padding-bottom: 80px;
	margin: 0 auto;
	max-width: 1280px;

	@media (min-width: 768px) {
		padding-top: 96px;
		padding-bottom: 96px;
	}
`;

export default {
	Section,
	Inner,
};
