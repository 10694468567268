import Cher from '@cuvva/cher';
import { all, call, getContext, put } from 'redux-saga/effects';
import { declareLicense, licenseByUserId } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(declareLicense.request, function* workerDeclareLicense({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId, dln, issuingCountryCode } = payload;
    try {
        yield call(api.declareLicense, client, userId, dln, issuingCountryCode);
        yield all([
            put(declareLicense.success(ident)),
            put(licenseByUserId.request({ userId })),
            put(flashMessages.addSuccess('Updated license')),
        ]);
    }
    catch (error) {
        yield put(declareLicense.failure(ident, error));
        const cher = Cher.coerce(error);
        switch (cher.code) {
            case 'dln_already_attached':
                yield put(flashMessages.addDanger('User already has license', 'This user already has a license on their account. Try refreshing.'));
                break;
            case 'already_exists':
                yield put(flashMessages.addDanger('Already exists', 'That DLN is already attached to another account.'));
                break;
            case 'invalid_dln':
            case 'invalid_license':
                yield put(flashMessages.addDanger('License not valid', 'Double check that DLN (and for GB licenses, personal name, family name, sex and birth date) are correct.'));
                break;
            case 'license_not_found':
                yield put(flashMessages.addDanger('License not valid', 'Pretty much what it says on the tin. Check the DLN again.'));
                break;
            default:
                yield put(flashMessages.addError('Unknown error', 'There was an unknown error while trying to declare the license. The error has been outputted below:', cher));
                break;
        }
    }
});
