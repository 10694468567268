import React, { useContext } from 'react';

import { HeadingsWrapper, InnerContent, LogoWrap, Wrapper } from '../atoms/HiringOfferHeaderWrapper';
import HeaderShares from './HeaderShares';
import CuvvaLogo from '~lib/frontend/atoms/CuvvaLogo';
import Typography from '~lib/frontend/design-system/components/Typography';
import HeaderHeroWrap from '~website/components/Hero/HeaderHeroWrap';
import HiringOfferContext from '~website/features/hiring-offer/context';

const HiringOfferHeader = () => {
	const { candidateName, roleName } = useContext(HiringOfferContext);

	return (
		<Wrapper>
			<HeaderHeroWrap $themeKey={'dark'} $gradient={'center'} $backgroundImage={void 0} $hasSubHero={false}>
				<LogoWrap href={'/'}>
					<CuvvaLogo />
				</LogoWrap>
				<InnerContent>
					<HeadingsWrapper>
						<Typography
							$type={'Heading.Medium'}
							$color={'textOnFill'}
							$align={'center'}
							$marginBottom={'small'}
						>
							{`Hi ${candidateName} 👋`}
						</Typography>
						<Typography $type={'Heading.XLarge'} $color={'textOnFill'} $align={'center'} as={'h1'}>
							{'We’re pleased to offer '}
							{'you the role of '}
							{roleName}
						</Typography>
					</HeadingsWrapper>
					<HeaderShares />
				</InnerContent>
			</HeaderHeroWrap>
		</Wrapper>
	);
};

export default HiringOfferHeader;
