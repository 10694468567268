import Cher from '@cuvva/cher';
import CuvvaClient from '@cuvva/cuvva-client';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';

import { importRegisterUser } from '../actions';
import { GetUserActionTypes } from '../types';
import { userById } from '~lib/platform/auth/store/actions';
import { getUser } from '~lib/platform/auth/store/api';
import { User } from '~lib/platform/auth/types';
import { getConfigById } from '~lib/platform/user-config/store/actions';
import { AppsFlyerSDK } from '~website/helpers/appsflyer-sdk';
import { addEventVariable } from '~website/helpers/gtm';

export default function* watcherGetOrRegisterUser() {
	yield takeEvery(GetUserActionTypes.IMPORT_REGISTER_USER, worker);
}

function* worker() {
	const client: CuvvaClient = yield getContext('api');
	const appsFlyer: AppsFlyerSDK = yield getContext('appsFlyer');

	try {
		if (!client.auth)
			return;

		const user: User = yield call(getUser, client, {
			userId: client.auth.userId,
			includeRemovedIdentifiers: false,
		});

		yield all([
			put(userById.success(`${user.id}-false`, user)),
			put(importRegisterUser.success(user.id)),
			put(getConfigById.request({
				userId: user.id,
			})),
		]);

		addEventVariable('user_id', user.id);
		appsFlyer.identify(user.id);
	} catch (error) {
		const cher = Cher.coerce(error);

		yield put(importRegisterUser.failure(cher));

		if (['token_used', 'invalid_client_id', 'unauthorized', 'token_not_found'].includes(cher.code)) {
			client.auth = null;

			yield put(importRegisterUser.request());
		} else if (cher.code === 'token_revoked') {
			client.auth = null;

			// NOTE(afr): This is very hacky, but it guarantees no corrupt state can form
			// while also causing the happy path above to happen. This will have so rarely
			// that I don't think it is an issue.
			window.location.reload();
		}
	}
}
