import React from 'react';
import styled from 'styled-components';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import { FlexWrapper } from '../atoms/ReportingToWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

const FreeForChat: React.FunctionComponent = () => {
	const asset = useDesignSystemAsset({ type: 'photo', value: 'about_future_fit.png' });

	return (
		<FlexWrapper>
			<div>
				<SectionHeaderText>{'Are you free for a chat?'}</SectionHeaderText>
				<Typography $type={'Body.XLarge'}>
					{'We offer every candidate the chance to chat through that offer in more detail. '}
					{'If you would like to have one, then just let us know by responding to your offer email.'}
				</Typography>
			</div>
			<ImageDiv>
				<img src={asset} alt={'about future fit'} />
			</ImageDiv>
		</FlexWrapper>
	);
};

const ImageDiv = styled.div`
	img {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
`;

export default FreeForChat;
