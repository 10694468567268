import { combineReducers } from 'redux';
import { initialUploadState, UploadActionTypes } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
const fileById = createAsyncMapReducer(UploadActionTypes.FILE_BY_ID, initialUploadState.fileById);
const listByIdentifiers = createAsyncMapReducer(UploadActionTypes.LIST_BY_IDENTIFIERS, initialUploadState.listByIdentifiers);
const uploadFile = createAsyncMapReducer(UploadActionTypes.UPLOAD_FILE, initialUploadState.uploadFile);
const createFileFlag = createAsyncMapReducer(UploadActionTypes.CREATE_FILE_FLAG, initialUploadState.createFileFlag);
const listFilesByUserId = createAsyncMapReducer(UploadActionTypes.LIST_FILES_BY_USER_ID, initialUploadState.listFilesByUserId);
export default combineReducers({
    fileById,
    listByIdentifiers,
    uploadFile,
    createFileFlag,
    listFilesByUserId,
});
