import { call, getContext, put } from 'redux-saga/effects';
import { sendAuthenticationCode } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(sendAuthenticationCode.request, function* workerSendAuthenticationCode({ payload }) {
    const client = yield getContext('api');
    const { emailAddress, env, redirectUri, requestId, build } = payload;
    try {
        yield call([client, client.sendAuthenticationCode], emailAddress, env, redirectUri, build);
        yield put(sendAuthenticationCode.success(requestId));
    }
    catch (error) {
        yield put(sendAuthenticationCode.failure(requestId, error));
    }
});
