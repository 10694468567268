import Cher from '@cuvva/cher';

const positiveNumericValidator = (value: number | undefined) => {
	if (!Number.isFinite(value) || typeof value !== 'number' || value < 0)
		return new Cher('invalid');

	return void 0;
};

export default positiveNumericValidator;
