import React, { forwardRef, useCallback, useState } from 'react';
import { OTP } from '../atoms/OTP';
import SingleOTPInput from './SingleOTPInput';
const OTPInput = forwardRef((props, ref) => {
    const { $length: length, onChangeOTP, $autoFocus: autoFocus, disabled, ...rest } = props;
    const [values, setValues] = useState(Array(length).fill(''));
    const [activeInput, setActiveInput] = useState(0);
    const handleOTPChange = useCallback((otp) => {
        const otpValue = otp.join('');
        onChangeOTP(otpValue);
    }, [onChangeOTP]);
    const focusInput = useCallback((inputIndex) => {
        const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
        setActiveInput(selectedIndex);
    }, [length]);
    const focusPrevInput = useCallback(() => {
        focusInput(activeInput - 1);
    }, [activeInput, focusInput]);
    const focusNextInput = useCallback(() => {
        focusInput(activeInput + 1);
    }, [activeInput, focusInput]);
    const handleOnFocus = useCallback((index) => () => {
        focusInput(index);
    }, [focusInput]);
    const onBlur = useCallback(() => {
        setActiveInput(-1);
    }, []);
    const changeCodeAtFocus = useCallback((str) => {
        const updatedOTPValues = [...values];
        updatedOTPValues[activeInput] = str[0] || '';
        setValues(updatedOTPValues);
        handleOTPChange(updatedOTPValues);
    }, [activeInput, values, handleOTPChange]);
    const handleOnChange = useCallback((e) => {
        const val = e.currentTarget.value;
        if (!val) {
            e.preventDefault();
            return;
        }
        changeCodeAtFocus(val);
        focusNextInput();
    }, [changeCodeAtFocus, focusNextInput]);
    const handleOnKeyDown = useCallback((e) => {
        const pressedKey = e.key;
        switch (pressedKey) {
            case 'Backspace':
            case 'Delete': {
                e.preventDefault();
                if (values[activeInput])
                    changeCodeAtFocus('');
                else
                    focusPrevInput();
                break;
            }
            case 'ArrowLeft': {
                e.preventDefault();
                focusPrevInput();
                break;
            }
            case 'ArrowRight': {
                e.preventDefault();
                focusNextInput();
                break;
            }
            default: {
                if (pressedKey.match(/^[^a-zA-Z0-9]$/))
                    e.preventDefault();
                break;
            }
        }
    }, [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, values]);
    const handleOnPaste = useCallback((e) => {
        e.preventDefault();
        const pastedData = e.clipboardData
            .getData('text/plain')
            .trim()
            .slice(0, length - activeInput)
            .split('');
        if (pastedData) {
            let nextFocusIndex = 0;
            const updatedOTPValues = [...values];
            updatedOTPValues.forEach((val, index) => {
                if (index >= activeInput) {
                    const changedValue = pastedData.shift() || val;
                    if (changedValue) {
                        updatedOTPValues[index] = changedValue;
                        nextFocusIndex = index;
                    }
                }
            });
            setValues(updatedOTPValues);
            setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
        }
    }, [activeInput, length, values]);
    return (React.createElement(OTP, { ref: ref, ...rest }, Array(length)
        .fill('')
        .map((_, index) => (React.createElement(SingleOTPInput, { key: `SingleInput-${index}`, type: 'tel', autoComplete: 'one-time-code', autoFocus: autoFocus, focus: activeInput === index, onFocus: handleOnFocus(index), onBlur: onBlur, disabled: disabled, onChange: handleOnChange, onKeyDown: handleOnKeyDown, onPaste: handleOnPaste, value: values && values[index] })))));
});
export default OTPInput;
