import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { config } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherConfig() {
    yield takeEvery(LtmActionTypes.CONFIG, workerConfig);
}
function* workerConfig({ payload }) {
    const clientApi = yield getContext('api');
    const { userId, client } = payload;
    try {
        const response = yield call(api.config, clientApi, userId, client);
        yield put(config.success(response));
    }
    catch (error) {
        yield put(config.failure(error));
    }
}
