import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import VisionaryContext from '~website/contexts/VisionaryContext';

interface TrustpilotThemeAwareProps extends React.HTMLAttributes<HTMLDivElement> {}

const TrustpilotThemeAware: React.FCWithChildren<TrustpilotThemeAwareProps> = props => {
	const theme = useContext(ThemeContext);
	const visionary = useContext(VisionaryContext);
	const newDesign = useContext(BrandDesignContext);
	const dark = visionary || theme.themeKey === 'dark';

	const desktopAsset = useContentAsset('website_core', 'trustpilot/trustpilot-desktop.svg');
	const mobileAsset = useContentAsset('website_core', `trustpilot/${dark ? 'trustpilot-mobile' : 'focussed'}.svg`);

	const finalDesktopAsset = newDesign ? desktopAsset : mobileAsset;

	return (
		<Wrap {...props}>
			<Image src={finalDesktopAsset} alt={'trustpilot logo'} mobileSrc={mobileAsset} newDesign={newDesign} />
		</Wrap>
	);
};

const Wrap = styled.div`
	display: inline-block;
`;

const Image = styled.img<{ mobileSrc: string; newDesign: boolean }>`
	height: ${p => p.newDesign ? '150px' : '30px'};

	${p => media.lessThan('laptop')`
		height: ${p.newDesign ? '100px' : '30px'};
	`}

	${p => media.lessThan('tablet')`
		content: url(${p.mobileSrc});
		height: ${p.newDesign ? '30px' : '30px'};
	`}
`;

export default TrustpilotThemeAware;
