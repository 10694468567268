import { createAsyncActionTypes } from '../actions/types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createAsyncMapReducer(actionType, initialState) {
    const { request, success, failure, reset } = createAsyncActionTypes(actionType);
    return function asyncMapReducer(state = initialState, { type, payload, meta }) {
        switch (type) {
            case request:
                return {
                    ...state,
                    [meta.ident]: {
                        ...state[meta.ident],
                        fetching: true,
                    },
                };
            case success:
                return {
                    ...state,
                    [meta.ident]: {
                        fetching: false,
                        response: payload,
                    },
                };
            case failure:
                return {
                    ...state,
                    [meta.ident]: {
                        fetching: false,
                        error: payload,
                    },
                };
            case reset:
                return {
                    ...state,
                    [meta.ident]: {
                        fetching: false,
                        error: payload,
                    },
                };
            default:
                return state;
        }
    };
}
