import styled from 'styled-components';
export const CardWrapper = styled.div `
	border-radius: ${p => p.theme.borderRadius.large};
	margin-bottom: ${p => p.theme.spacing.large};
	border: ${p => (p.$error ? `1px solid ${p.theme.ui.dangerFill}` : 'none')};
	overflow: hidden;
	width: 100%;
	color: ${props => props.theme.ui.textOnSurfaceBackground};
	background-color: ${p => p.theme.ui[p.$error ? 'dangerFillMuted' : 'surface']};
	box-shadow: ${p => p.theme.boxShadow.extraSmall}
`;
