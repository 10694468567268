import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useLockedDetails from '../../hooks/use-locked-details';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import profileDOBValidator from '../../validators/profile-dob-validator';
import Date from '../fields/Date';

const BirthDate: React.FunctionComponent = () => {
	const { value, validation, onChange } = useProfileInternalChangeRequest('birthDate', profileDOBValidator);
	const error = useOnPageError(validation, 'dob_input');
	const locked = useLockedDetails();

	return (
		<QuoteInputDecorator
			label={'Date of birth'}
			error={error}
			help={'DD/MM/YYYY'}
		>
			<Date
				disabled={locked}
				autoComplete={'bday'}
				onChange={onChange}
				value={value}
				data-event-onfocus-name={'dob_input_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default BirthDate;
