import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { search } from '../actions';
import { ProfileActionTypes } from '../types';
export default function* watcherSearch() {
    yield takeEvery(ProfileActionTypes.SEARCH, workerSearch);
}
function* workerSearch({ payload, meta }) {
    const { ident } = meta;
    const { requestId, ...payloadBody } = payload;
    const client = yield getContext('api');
    try {
        const response = yield call(api.search, client, payloadBody);
        yield put(search.success(ident, response));
    }
    catch (error) {
        yield put(search.failure(ident, error));
    }
}
