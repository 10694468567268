/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../actions';
import { initialAsyncState } from '../types/state';
export default function createAsyncSlice(name, initialState = initialAsyncState) {
    const actions = createAsyncAction(name);
    const slice = createSlice({
        name,
        initialState,
        reducers: {},
        extraReducers: builder => builder
            .addCase(actions.request, state => ({
            ...state,
            fetching: true,
        }))
            .addCase(actions.success, (_state, action) => ({
            fetching: false,
            response: action.payload,
        }))
            .addCase(actions.failure, (_state, action) => ({
            fetching: false,
            error: action.payload,
        }))
            .addCase(actions.reset, () => ({
            fetching: false,
        })),
    });
    return {
        ...slice,
        actions,
    };
}
