import { combineReducers } from '@reduxjs/toolkit';

import consent from './reducers/consent';

const reducers = combineReducers({
	consent: consent.reducer,
});

export const initialCookieConsentState: ReturnType<typeof reducers> = {
	consent: consent.getInitialState(),
};

export default reducers;
