import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import AddressEdit from './AddressEdit';
import SearchAddress from './SearchAddress';
import SearchResult from './SearchResult';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import type { Address } from '~lib/platform/profile/types';
import { postcodeRegex } from '~lib/shared/helpers/regex';
import { AsyncState, initialAsyncState } from '~lib/shared/redux/types/state';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import usePostcodeLocationSearch from '~website/features/quote/hooks/use-postcode-location-search';
import type { LookupByPostcodeResponse } from '~website/features/quote/store/types';

interface AddressLookupProps {
	postcode: string | undefined;
	onAddressChange: (address: Address | undefined) => void;
	address: Address | undefined;
	error: string | undefined;
}

const AddressLookup: React.FCWithChildren<AddressLookupProps> = ({
	postcode,
	onAddressChange,
	address,
	error,
}) => {
	const dispatch = useDispatch();
	const { result, onChange } = usePostcodeLocationSearch();
	const [enterManually, setEnterManually] = useState(false);

	const [dataset, setDataset] = useState<AsyncState<LookupByPostcodeResponse>>(initialAsyncState);
	const datasetStatus = useAsyncStateStatus(dataset).status;

	const safePostcode = postcode?.replace(/\s/g, '');

	// When the results arrive, set it locally. Order of defined hooks here is important.
	useEffect(() => setDataset(result), [result]);

	// If you get passed in a postcode, run a search.
	useEffect(() => {
		if (!safePostcode)
			return;

		if (!postcodeRegex.exec(safePostcode)) {
			setDataset({
				error: new Cher('invalid'),
				fetching: false,
			});

			return;
		}

		onChange(safePostcode);
	}, [safePostcode, onChange]);

	// Auto select the first address if there's only one in the result set.
	useEffect(() => {
		if (!dataset?.response)
			return;

		const addresses = dataset.response.addresses;

		if (addresses?.length === 1)
			onAddressChange(addresses[0]);

		// If a postcode is valid-looking but it isn't, this will be triggered.
		if (addresses?.length === 0) {
			setDataset({
				error: new Cher('invalid'),
				fetching: false,
			});
		}
	}, [onAddressChange, dataset]);

	// If there's already an address saved or we just picked one.
	useEffect(() => {
		if (!address)
			return;

		setEnterManually(true);
	}, [address]);

	const onEnterManually = () => {
		setEnterManually(true);
		dispatch(trackQuoteEvent({ action: 'manual_address_selected' }));
	};

	const searchNewPostcode = () => {
		setDataset(initialAsyncState);
		onAddressChange(void 0);
		setEnterManually(false);
	};

	return (
		<React.Fragment>
			{((!enterManually && datasetStatus === 'response') || enterManually) && !safePostcode && (
				<Margin $marginTop={'small'}>
					<Button
						$type={'text'}
						$size={'medium'}
						onClick={searchNewPostcode}
					>
						{'Search new postcode'}
					</Button>
				</Margin>
			)}

			<Spacer>
				{!enterManually && !safePostcode && datasetStatus !== 'response' && (
					<SearchAddress
						onDatasetUpdate={setDataset}
						onEnterManually={onEnterManually}
						error={error}
					/>
				)}

				{!enterManually && datasetStatus === 'fetching' && (
					<SpinnerWrap>
						<div>
							<Spinner $size={'32px'} />
						</div>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{'Loading'}
						</Typography>
					</SpinnerWrap>
				)}

				{!enterManually && datasetStatus === 'response' && (
					<SearchResult
						dataset={dataset.response!.addresses}
						onAddressSelected={onAddressChange}
						onEnterManually={onEnterManually}
						error={error}
					/>
				)}

				{!enterManually && datasetStatus === 'error' && (
					<Banner.Wrapper icon={'ic_alert'} type={'error'} muted>
						{'We couldn\'t find that postcode'}
					</Banner.Wrapper>
				)}

				{enterManually && (
					<AddressEdit
						error={error}
						address={address}
						onChange={onAddressChange}
					/>
				)}
			</Spacer>
		</React.Fragment>
	);
};

const SpinnerWrap = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.large};
	align-items: center;

	& > * {
		flex: 1;
	}

	& > :first-child {
		flex: 0 0 32px;
		width: 32px;
		height: 32px;
	}
`;

const Spacer = styled.div`
	margin-top: ${p => p.theme.spacing.extraLarge};
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

export default AddressLookup;
