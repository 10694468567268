import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherBanVehicle() {
    yield takeEvery(VehicleActionTypes.BAN_VEHICLE, workerBanVehicle);
}
function* workerBanVehicle({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield api.banVehicle(client, {
            vehicleId: payload.vehicleId,
            banReason: payload.banReason,
        });
        yield put(actions.banVehicle.success(ident));
    }
    catch (error) {
        yield put(actions.banVehicle.failure(ident, error));
    }
}
