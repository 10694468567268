import { combineReducers } from 'redux';
import { initialVehicleState, VehicleActionTypes } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
export const banVehicle = createAsyncMapReducer(VehicleActionTypes.BAN_VEHICLE, initialVehicleState.banVehicle);
export const findVehicleId = createAsyncMapReducer(VehicleActionTypes.FIND_VEHICLE_ID, initialVehicleState.findVehicleId);
export const flushCache = createAsyncMapReducer(VehicleActionTypes.FLUSH_CACHE, initialVehicleState.flushCache);
export const getPublicVehicle = createAsyncMapReducer(VehicleActionTypes.GET_PUBLIC_VEHICLE, initialVehicleState.getPublicVehicle);
export const getVehicle = createAsyncMapReducer(VehicleActionTypes.GET_VEHICLE, initialVehicleState.getVehicle);
export const getVehicleConfig = createAsyncMapReducer(VehicleActionTypes.GET_VEHICLE_CONFIG, initialVehicleState.getVehicleConfig);
export const listVehicleRevisions = createAsyncMapReducer(VehicleActionTypes.LIST_VEHICLE_REVISIONS, initialVehicleState.listVehicleRevisions);
export const listExampleVehicles = createAsyncReducer(VehicleActionTypes.LIST_EXAMPLE_VEHICLES, initialVehicleState.listExampleVehicles);
export const setVehicleProvider = createAsyncMapReducer(VehicleActionTypes.SET_VEHICLE_PROVIDER, initialVehicleState.setVehicleProvider);
export const overrideVrm = createAsyncMapReducer(VehicleActionTypes.OVERRIDE_VRM, initialVehicleState.overrideVrm);
export default combineReducers({
    banVehicle,
    findVehicleId,
    flushCache,
    getPublicVehicle,
    getVehicle,
    getVehicleConfig,
    listVehicleRevisions,
    listExampleVehicles,
    setVehicleProvider,
    overrideVrm,
});
