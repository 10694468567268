import React from 'react';

import HowItGoesGrid from '../atoms/HowItGoesGrid';
import HowItGoesSection from '../atoms/HowItGoesSection';
import Typography from '~lib/frontend/design-system/components/Typography';

interface HowItGoesProps {
	get: (key: string, fallback?: string) => string;
}

const HowItGoes: React.FCWithChildren<HowItGoesProps> = ({ get }) => (
	<HowItGoesSection>
		<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>{get('title')}</Typography>
		<HowItGoesGrid>
			<div>
				<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
					{get('left')}
				</Typography>
				<Typography $type={'Heading.XLarge'} $color={'textHighlight'}>
					{'£10'}
				</Typography>
			</div>
			<div>
				<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
					{get('right')}
				</Typography>
				<Typography $type={'Heading.XLarge'} $color={'textHighlight'}>
					{'£10'}
				</Typography>
			</div>
		</HowItGoesGrid>

		<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'}>
			{get('explanation')}
		</Typography>

		{get('pro_tip', 'none') !== 'none' && (
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{get('pro_tip')}
			</Typography>
		)}
	</HowItGoesSection>

);

export default HowItGoes;
