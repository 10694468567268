import React, { FCWithChildren } from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';

const DownloadAppBase: FCWithChildren = ({ children }) => {
	const downloadLogo = useContentAsset('website_core', 'quote/handoff_download.png');

	return (
		<DownloadAppContainer>
			<DownloadLogo src={downloadLogo} />

			<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
				{'Download the Cuvva app to finish checking out'}
			</Typography>

			{children}
		</DownloadAppContainer>
	);
};

const DownloadLogo = styled.img`
	width: 130px;
	height: 130px;
	margin-bottom: ${p => p.theme.spacing.extraLarge};
`;

const DownloadAppContainer = styled.div`
	text-align: center;
`;

export default DownloadAppBase;
