import React from 'react';

import Footer, { FooterProps } from './footer';
import Header, { HeaderProps } from './header';

interface LayoutProps {
	options?: LayoutOptions;
	component?: React.FCWithChildren;
}

type LayoutOptions = {
	header?: Partial<HeaderProps>;
	footer?: Partial<FooterProps>;
};

const Layout: React.FCWithChildren<LayoutProps> = ({ options, children, component: Component }) => (
	<React.Fragment>
		<Header {...options?.header} />
		{Component && (
			<Component />
		)}
		{children}
		<Footer {...options?.footer} />
	</React.Fragment>
);

export default Layout;
