import React from 'react';
import clone from 'lodash/clone';

import QuoteInputDecorator from '../../../../components/atoms/QuoteInputDecorator';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import { Address } from '~lib/platform/profile/types';
import validPostcodeValidator from '~website/features/quote/validators/valid-postcode-validator';

interface AddressEditProps {
	address: Address | undefined;
	onChange: (address: Address) => void;
	error: string | undefined;
}

const AddressEdit: React.FCWithChildren<AddressEditProps> = ({ onChange, address, error }) => {
	const addressLines = address?.lines;
	const addressLine1 = addressLines?.[0];
	const addressLine2 = addressLines?.[1];
	const addressPostcode = address?.postalCode;
	const addressCity = address?.locality;

	const validPostcode = validPostcodeValidator(addressPostcode) === void 0;
	const { error: postcodeError, onFocus, onBlur } = useOnBlurError(validPostcode);

	const baseAddress: Address = (() => {
		if (address)
			return clone(address);

		return {
			lines: ['', ''],
			countryCode: 'GB',
			postalCode: '',
			locality: '',
		};
	})();

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={'Address line 1'}
				error={error && !addressLine1 && 'Enter a building or street'}
			>
				<TextInput
					type={'text'}
					name={'address-line-1'}
					value={addressLine1}
					onChange={e => onChange({
						...baseAddress,
						lines: [e.target.value, baseAddress.lines[1]],
					})}
				/>
			</QuoteInputDecorator>

			<QuoteInputDecorator
				label={'Address line 2'}
			>
				<TextInput
					type={'text'}
					name={'address-line-2'}
					value={addressLine2}
					onChange={e => onChange({
						...baseAddress,
						lines: [baseAddress.lines[0], e.target.value],
					})}
				/>
			</QuoteInputDecorator>

			<AlignDoubleColChildren>
				<QuoteInputDecorator
					label={'City'}
					error={error && !addressCity && 'Enter a city'}
				>
					<TextInput
						type={'text'}
						name={'address-city'}
						value={addressCity}
						size={8}
						onChange={e => onChange({
							...baseAddress,
							locality: e.target.value,
						})}
					/>
				</QuoteInputDecorator>
				<QuoteInputDecorator
					label={'Postcode'}
					error={(postcodeError || error) && !validPostcode && 'Enter a valid postcode'}
				>
					<TextInput
						type={'text'}
						name={'address-postcode'}
						value={addressPostcode}
						size={8}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={e => onChange({
							...baseAddress,
							postalCode: e.target.value,
						})}
					/>
				</QuoteInputDecorator>
			</AlignDoubleColChildren>
		</React.Fragment>
	);
};

export default AddressEdit;
