import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listDiscountsNeu } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherListDiscountsNeu() {
    yield takeEvery(PromoActionTypes.LIST_DISCOUNTS_NEU, workerListDiscountsNeu);
}
function* workerListDiscountsNeu({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.listDiscountsNeu, client, payload.userId, payload.type, payload.includeExpired, payload.includeUsed, payload.includeNotOffered);
        yield put(listDiscountsNeu.success(ident, response));
    }
    catch (error) {
        yield put(listDiscountsNeu.failure(ident, error));
    }
}
