import styled, { css } from 'styled-components';
export const Badge = styled.div `
	${p => p.theme.typography['Label.Medium']()};
	display: inline-block;
	padding: ${p => p.theme.spacing.extraSmall} ${p => p.theme.spacing.large};
	border-radius: ${p => p.theme.borderRadius.extraLarge};

	${p => p.$style === 'success' && css `
		background: ${p => p.theme.ui.successFillMuted};
		color: ${p => p.theme.ui.textSuccess};
	`};

	${p => p.$style === 'default' && css `
		background: ${p => p.theme.ui.primaryFillMuted};
		color: ${p => p.theme.ui.textHighlight};
	`};
`;
export const CompactBadge = styled.div `
	${p => p.theme.typography['Label.XSmall']()};
	padding: 2px 6px;
	border-radius: 10px;
	width: fit-content;

	${p => generateCompactBadgeStateCss(p.state)}
`;
function generateCompactBadgeStateCss(state) {
    switch (state) {
        case 'primary': {
            return css `
				color: ${p => p.theme.ui.textSuccess};
				background: ${p => p.theme.ui.primaryActionMuted};
			`;
        }
        case 'warning': {
            return css `
				color: ${p => p.theme.ui.textWarning};
				background: ${p => p.theme.ui.warningFillMuted};
			`;
        }
        case 'danger': {
            return css `
				color: ${p => p.theme.ui.textDanger};
				background: ${p => p.theme.ui.dangerActionMuted};
			`;
        }
        case 'neutral':
        default: {
            return css `
				color: ${p => p.theme.ui.textMinor};
				background: ${p => p.theme.ui.neutralActionMuted};
			`;
        }
    }
}
