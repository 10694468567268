import Cher from '@cuvva/cher';
import { all, call, getContext, put } from 'redux-saga/effects';
import { licenseByUserId, removeTestPass } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(removeTestPass.request, function* workerRemoveTestPass({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId } = payload;
    try {
        yield call(api.removeTestPass, client, userId);
        yield all([
            put(removeTestPass.success(ident)),
            put(licenseByUserId.request({ userId })),
            put(flashMessages.addSuccess('This user has no longer passed their test 🔫')),
        ]);
    }
    catch (error) {
        yield put(removeTestPass.failure(ident, error));
        const cher = Cher.coerce(error);
        switch (cher.code) {
            case 'test_pass_not_found':
                yield put(flashMessages.addDanger('Test pass not found', 'You can\'t remove what isn\'t there.'));
                break;
            case 'no_license_registered':
                yield put(flashMessages.addDanger('No license registered', 'This user has no license registered to their account.'));
                break;
            default:
                yield put(flashMessages.addError('Unable to remove test pass', 'There was an unknown error while removing that this user has no longer passed.', cher));
                break;
        }
    }
});
