import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

export const KeyDocumentWrapper = styled.div`
	padding: ${p => p.theme.spacing.regular};
	border-radius: ${p => p.theme.borderRadius.extraLarge};
	box-shadow: ${p => p.theme.boxShadow.small};
	display: block;

	${p => media.greaterThan('laptop')`
		padding: ${p.theme.spacing.extraLarge};
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: ${p.theme.spacing.extraLarge};
	`}
`;

export const KeyDocument = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
	padding: ${p => p.theme.spacing.regular} 0;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};

	:last-child {
		border-bottom: none;
	}

	${p => media.greaterThan('laptop')`
		display: grid;
		grid-template-columns: 80% auto;
		gap: ${p.theme.spacing.regular};
		align-items: center;
		justify-content: flex-start;
		border-right: 1px solid ${p.theme.ui.borderSeparator};
		border-bottom: 0;

		:last-child {
			border-right: none;
		};
	`}
`;
