import React, { useContext } from 'react';
import styled from 'styled-components';
import IconButton from '../../button/IconButton';
import RegPlate from '../atoms/RegPlate';
import InputDecoratorContext from '../context/decorator';
import Icon from '~lib/frontend/design-system/components/Icon';
const RegPlateInput = props => {
    const { error } = useContext(InputDecoratorContext);
    const { value, onChange } = props;
    return (React.createElement(RegPlateContainer, { "$error": Boolean(error) },
        React.createElement(RegPlate, { "$error": Boolean(error), value: value, onChange: e => onChange(e.target.value), placeholder: props.placeholder, onFocus: props.onFocus, onBlur: props.onBlur, onKeyUp: props.onKeyUp, ref: props.inputRef, background: props.background, "aria-label": props.ariaLabel }),
        React.createElement(IconContainer, null,
            React.createElement(Icon, { icon: 'ic_search', "$size": '24px', "$color": value ? 'textOnSurfaceBackground' : 'textMinor' })),
        props?.displaySearchButton && value && (React.createElement(IconButton, { "$icon": 'ic_search', "$type": 'primary', "$loading": props.loading, onClick: props.onSearch }))));
};
const IconContainer = styled.div `
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	inset: 0;
	padding-left: 10px;
`;
const RegPlateContainer = styled.div `
	display: flex;
	position: relative;

	button {
		position: absolute;
		top: 4px;
		bottom: 4px;
		right: 4px;
	}

	input:focus + ${IconContainer} > div {
		background-color: ${p => p.theme.ui.textOnSurfaceBackground};
	}
`;
export default RegPlateInput;
