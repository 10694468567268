import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import classnames from 'classnames';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useSegment from '~lib/frontend/hooks/use-segment';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import Dribbble from '~website/assets/svgs/Dribbble';
import Facebook from '~website/assets/svgs/Facebook';
import Instagram from '~website/assets/svgs/Instragram';
import Twitter from '~website/assets/svgs/Twitter';
import BoxText from '~website/components/atoms/BoxText';
import Page404 from '~website/pages/NotFound';
import { ApplicationState } from '~website/store';
import { listCuvvaPeeps } from '~website/store/about/actions';

const Section = styled.section`
	margin-bottom: 80px;

	@media (min-width: 768px) {
		margin-bottom: 200px;
	}
`;

const MainHeader = styled.div`
	position: relative;
	margin: 0 auto;
	padding: 130px 0;
	max-width: 750px;
	text-align: center;
`;

const WrapperContent = styled.div`
	max-width: 750px;
	margin: 0 auto 115px;
	padding: 0 25px;

	.img {
		width: 100%;
		text-align: center;
		height: auto;
		margin: 0 auto 100px;
	}
`;

const InfoBeforeRead = styled.div`
	text-align: left;
	margin-bottom: 50px;
`;

const InfoPost = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	.items {
		flex: 0 0 50%;
	}

	.follow {
		color: ${props => props.theme.ui.textOnSurfaceBackground};
		text-align: left;
		font-weight: bold;
	}

	.social-media {
		text-align: left;
		flex: 0 0 25%;

		svg {
			transition: ease-in 0.2s;

			&:hover {
				transform: translateY(-2px);
			}
		}

		svg,
		g {
			fill: ${props => props.theme.ui.neutralFill};
		}

		a {
			align-items: center;
			margin-right: 20px;
		}
	}

	.by-who {
		justify-content: right;
		display: flex;
		align-items: center;
		flex: 0 0 calc(50% - 10px);

		div {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}

		.info {
			text-align: left;
			margin-bottom: 0;
			&:first-child {
				font-weight: bold;
			}

			@media (max-width: 768px) {
				&:last-child {
					font-size: 0.8rem;
				}
			}
		}

		.icon {
			position: relative;
			display: inline-block;
			height: 42px;
			width: 42px;
			margin-right: 8px;
			vertical-align: middle;
			overflow: hidden;
			border-radius: 50%;
		}

		.mask-img {
			position: absolute;
			left: 50%;
			top: 48px;
			transform: translate(-50%, -50%);
			width: 100px;
		}
	}
`;

const getMinutesReadCountSentence = (wordCount: number) => {
	const minutes = Math.max(1, Math.round(wordCount / 265));
	const minuteWord = minutes > 1 ? 'minutes' : 'minute';

	return `${minutes} ${minuteWord} read`;
};

const getFriendlyPostDate = (postDate: string) => {
	if (!postDate) return '';

	const parsedDate = new Date(postDate);

	if (isNaN(parsedDate.getTime())) return '';

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(parsedDate);
};

const NewsPost: React.FunctionComponent = () => {
	const staff = useSelectorOrDefault((s: ApplicationState) => s.platform.staff.listStaffPublic.response, []);
	const dispatch = useDispatch();
	const location = useLocation();
	const newsSegment = useSegment('website_news');

	useEffect(() => {
		dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	if (!newsSegment)
		return <Page404 />;

	// eslint-disable-next-line
	const postContent: Record<string, any> = Object.values(newsSegment.content)
		.find((p: Record<string, unknown>) => p.url === location.pathname);

	if (!postContent)
		return <Page404 />;

	const wordCount = postContent.body_content.split(' ').length;
	const author = (() => {
		const member = staff.find(s => s.id === postContent.user_id);

		if (!member) {
			return {
				name: 'Team member',
				role: '',
				image: null,
			};
		}

		return {
			name: member.about.name,
			role: member.about.role,
			image: member.about.images ? member.about.images.normal || '' : '',
		};
	})();

	return (
		<React.Fragment>
			<Helmet defer={false}>
				<title>{postContent.head.title}</title>
				<meta name={'description'} content={postContent.description} />
				<meta property={'og:locale'} content={'en_GB'} />
				<meta property={'og:type'} content={'website'} />
				<meta property={'og:title'} content={postContent.head.facebook_title} />
				<meta property={'og:description'} content={postContent.head.description} />
				<meta property={'og:url'} content={postContent.head.facebook_url} />
				<meta property={'og:site_name'} content={'Cuvva'} />
				<meta property={'og:image'} content={newsSegment.assets[postContent.image_asset].content} />
				<meta property={'og:image:secure_url'} content={postContent.head.facebook_url} />
				<meta property={'og:image:width'} content={'1200'} />
				<meta property={'og:image:height'} content={'630'} />
				<meta property={'og:image:alt'} content={'shared-thumbnail'} />
				<meta name={'twitter:card'} content={'summary_large_image'} />
				<meta name={'twitter:description'} content={postContent.head.twitter_description} />
				<meta name={'twitter:title'} content={postContent.head.twitter_title} />
				<meta name={'twitter:site'} content={'@Cuvva'} />
				<meta name={'twitter:image'} content={newsSegment.assets[postContent.image_asset].content} />
				<meta name={'twitter:creator'} content={'@cuvva'} />
				<meta name={'robots'} content={postContent.head.robots} />
				<link rel={'canonical'} href={postContent.head.canonical_url} />
			</Helmet>
			<Section>
				<div className={'container cf'}>
					<MainHeader>
						<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} as={'h1'}>
							{postContent.title}
						</Typography>
					</MainHeader>
					<WrapperContent className={'cf'}>
						<img
							className={'img'}
							src={newsSegment.assets[postContent.image_asset].content}
							alt={postContent.description}
						/>
						<InfoBeforeRead>
							<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
								{`By ${author.name}, ${getFriendlyPostDate(postContent.post_date)}`}
							</Typography>
							<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'} $bold>
								{getMinutesReadCountSentence(wordCount)}
							</Typography>
						</InfoBeforeRead>
						<BoxText className={classnames({ 'old-blog': postContent.old_blog })}>
							<div dangerouslySetInnerHTML={{ __html: postContent.body_content }} />
						</BoxText>
						<InfoPost>
							<div className={'items'}>
								<div className={'follow cf'}>
									<p>{'Follow us on...'}</p>
								</div>
								<div className={'social-media'}>
									<Anchor className={'iconSocial facebook'} to={'https://www.facebook.com/getcuvva/'} target={'_blank'}>
										<Facebook />
									</Anchor>
									<Anchor className={'iconSocial twitter'} to={'https://twitter.com/cuvva'} target={'_blank'}>
										<Twitter />
									</Anchor>
									<Anchor className={'iconSocial instagram'} to={'https://www.instagram.com/getcuvva/'} target={'_blank'}>
										<Instagram />
									</Anchor>
									<Anchor className={'iconSocial instagram'} to={'https://dribbble.com/Cuvva'} target={'_blank'}>
										<Dribbble />
									</Anchor>
								</div>
							</div>
							<div className={'items by-who'}>

								<div className={'icon'}>
									{author.image ? (
										<img className={'mask-img'} src={author.image} alt={author.name} />
									) : (
										<Icon
											icon={'ic_cuvva'}
											$display={'block'}
											$color={'blackFill'}
											$size={'42px'}
										/>
									)}
								</div>
								<div>
									<p className={'info'}>
										{author.name}
									</p>
									<p className={'info'}>
										{author.role}
									</p>
								</div>
							</div>
						</InfoPost>
					</WrapperContent>
				</div>
			</Section>
		</React.Fragment>
	);
};

export default NewsPost;
