import { combineReducers } from 'redux';
import { initialVehicleProfileState, VehicleProfileActionTypes } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
export const listVehicleProfilesReducer = createAsyncMapReducer(VehicleProfileActionTypes.LIST_VEHICLE_PROFILES, initialVehicleProfileState.listVehicleProfiles);
export const vehicleProfileReducer = createAsyncMapReducer(VehicleProfileActionTypes.GET_VEHICLE_PROFILE, initialVehicleProfileState.userVehicleProfile);
export const userVehicleProfileReducer = createAsyncMapReducer(VehicleProfileActionTypes.USER_VEHICLE_PROFILE, initialVehicleProfileState.userVehicleProfile);
export const updateVehicleProfileReducer = createAsyncMapReducer(VehicleProfileActionTypes.UPDATE_VEHICLE_PROFILE, initialVehicleProfileState.updateVehicleProfile);
export default combineReducers({
    listVehicleProfiles: listVehicleProfilesReducer,
    updateVehicleProfile: updateVehicleProfileReducer,
    userVehicleProfile: userVehicleProfileReducer,
    vehicleProfile: vehicleProfileReducer,
});
