import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listEvents } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherListEvents() {
    yield takeEvery(LtmActionTypes.LIST_EVENTS, workerListEvents);
}
function* workerListEvents({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { userId } = payload;
    try {
        const events = [];
        let response;
        let afterEventIdentifier = payload.afterEventIdentifier || null;
        do {
            response = yield call(api.listEvents, client, userId, afterEventIdentifier);
            const lastEvent = response.slice(-1)[0];
            const uniqueEventsId = events.map(e => e.eventIdentifier);
            afterEventIdentifier = lastEvent ? lastEvent.eventIdentifier : null;
            events.push(...response.filter(e => !uniqueEventsId.includes(e.eventIdentifier)));
        } while (response.length > 0 && afterEventIdentifier);
        yield put(listEvents.success(ident, events));
    }
    catch (error) {
        yield put(listEvents.failure(ident, error));
    }
}
