import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Cher from '@cuvva/cher';

import parseStateParams from '../helpers/parseStateParams';
import { sharedQuotePreferences } from '../store/actions';
import { OwnersData } from '../types';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { AsyncState } from '~lib/shared/redux/types/state';
import { trackOwnersScreenView } from '~website/features/analytics/store/actions';
import useQuery from '~website/hooks/use-query';
import { ApplicationState } from '~website/store';

interface SharedQuoteHook {
	ownersData: OwnersData;
	loading: boolean;
	vehicle: AsyncState<PublicVehicle, Cher>;
}

function useSharedQuote(pageEvent?: string): SharedQuoteHook {
	const dispatch = useDispatch();
	const [ownersData, setOwnersData] = useState<OwnersData>();
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const params: Record<string, string> = useParams();
	const sharedQuote = useSelector(
		(state: ApplicationState) => state.platform.socialDriving.getSharedQuote[params?.id],
	);
	const vehicle = useSelector(
		(s: ApplicationState) => s.platform.vehicle.getPublicVehicle[`${sharedQuote?.response?.vehicleId}:false`],
	);

	const query = useQuery();

	useEffect(() => {
		if (query.get('state')) {
			const stateParams = atob(query.get('state'));

			setOwnersData(parseStateParams(stateParams, history, params?.id));
			setLoading(false);

			return;
		}

		dispatch(sharedQuotePreferences.request({ key: params?.id }));

		if (pageEvent) {
			dispatch(trackOwnersScreenView({
				screenName: pageEvent,
				cuvvaSource: query.get('cuvva_source'),
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (sharedQuote?.response) {
			setOwnersData(sharedQuote?.response);
			dispatch(getPublicVehicle.request({ id: sharedQuote?.response?.vehicleId, bypassChecks: false }));
			setLoading(false);
		}

		// SC: this might be the worst user experience ever, redirecting without a single explanation.
		if (sharedQuote?.error?.code)
			history.push(createAwareUrl('/'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, sharedQuote]);

	return {
		ownersData,
		loading,
		vehicle,
	};
}

export default useSharedQuote;
