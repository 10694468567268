import styled, { css } from 'styled-components';
const BannerWrapper = styled.div `
	border-radius: ${p => p.theme.borderRadius.small};
	color: ${p => p.theme.ui.textHighlight};
	padding: ${p => p.theme.spacing.large};
	margin-bottom: ${p => p.theme.spacing.regular};
	position: relative;
	display: none;
	visibility: hidden;
	opacity: 0;
	background: ${p => p.theme.ui[p.$color]};
	transition: all 1s;
	box-shadow: ${p => p.$shadow && p.theme.boxShadow.small};

	h4,
	p {
		margin: 0;
		padding: 0;
	}

	${p => p.$show && css `
		display: flex;
		align-items: flex-start;
		visibility: visible;
		opacity: 1;
	`}
`;
export default BannerWrapper;
