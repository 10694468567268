import styled, { css } from 'styled-components';

export const QuoteCard = styled.div<{ $noTopRadius?: boolean; $showBorder?: boolean }>`
	background: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};

	${p => p.$noTopRadius && css`
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	`}

	${p => p.$showBorder && css`
		border: 1px solid ${p.theme.ui.borderInteractiveDefault};
		border-top-right-radius: 0;
	`}

	padding: 24px ${p => p.theme.spacing.large};

	@media (min-width: 768px) {
		padding: ${p => p.theme.spacing.extraLarge};
	}

	@media (min-width: 1024px) {
		padding: 24px;
	}
`;

export const CardRoundedHeading = styled.div`
	background: ${p => p.theme.ui.surfaceDark};
	padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.large};
	border-radius: 0;
	border-top-left-radius: ${p => p.theme.borderRadius.large};
	border-top-right-radius: ${p => p.theme.borderRadius.large};
`;
