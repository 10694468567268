import React, { useEffect, useState } from 'react';
import domp from 'dompurify';
import showdown from 'showdown';
import styled from 'styled-components';
const converter = new showdown.Converter({});
converter.setFlavor('github');
converter.addExtension(() => [
    {
        type: 'output',
        filter: text => {
            const tableRegex = /<table([^>]*)>/g;
            return text.replace(tableRegex, '<div class="table-container"><table$1>');
        },
    },
], 'wrapTables');
const preprocessMarkdown = (text) => text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');
const Markdown = ({ children }) => {
    const preprocessed = preprocessMarkdown(children);
    const [content, setContent] = useState(preprocessed);
    useEffect(() => {
        if (typeof children !== 'string') {
            setContent('Invalid markdown body');
            return;
        }
        const converted = converter.makeHtml(preprocessed);
        const sanitized = domp.sanitize(converted);
        setContent(sanitized);
    }, [children, preprocessed]);
    return React.createElement(Wrapper, { dangerouslySetInnerHTML: { __html: content } });
};
const Wrapper = styled.div `
	a {
		color: ${props => props.theme.ui.textHighlight} !important;
	}

	p {
		margin: 0;
	}

	ol,
	ul {
		list-style: decimal;
		margin: 0;

		li {
			margin: 0;
			padding: 0;
		}
	}

	.table-container {
		overflow-x: auto;
	}

	table {
		min-width: 100%;
	}
	th,
	td {
		border: none;
		padding: 8px;
	}
	th {
		background-color: ${props => props.theme.ui.primaryFillMuted};
		color: ${props => props.theme.ui.textHighlight};
	}
	td {
		border-bottom: 1px solid ${props => props.theme.ui.borderSeparator};
	}
`;
export default Markdown;
