import React, { useState } from 'react';

import Locked from '../entry-point-locked';
import vehicleProfileEvents from '../events';
import ExperimentLandingPage from '../ExperimentLandingPage';
import MVExperiments from '~website/features/experiments/MVExperiments';
import PageSpinner from '~website/features/referral/atoms/PageSpinner';
import useSharedQuote from '~website/features/referral/hooks/use-shared-quote';
import useEmailAttached from '~website/hooks/use-email-attached';

const checklistItems = ['Follow their trip ', 'Know that they got there safe', 'Get their policy documents'];

const controlChecklistItems = [
	'Follow the driver’s trip',
	'Know that they got there safe',
	'Easily grab their policy documents',
];

const CreateAccountLandingPage: React.FunctionComponent = () => {
	const [complete, setComplete] = useState(false);
	const { ownersData, loading: loadingSharedQuote } = useSharedQuote(
		vehicleProfileEvents.entryPointCreateAccount.screenName,
	);

	const isEmailAttached = useEmailAttached();

	const VersionControl = () => (
		<ExperimentLandingPage
			ownersData={ownersData}
			updatePage={() => setComplete(true)}
			emailEnteredEvent={vehicleProfileEvents.entryPointCreateAccount.emailEnteredEvent}
			onCreateAccountEvent={vehicleProfileEvents.entryPointCreateAccount.createAccountCtaEvent}
			onClickSigninEvent={vehicleProfileEvents.entryPointCreateAccount.signInLinkEvent}
			contentTitle={'Create an account'}
			contentChecklist={controlChecklistItems}
			submitBtnLabel={'Continue with email'}
			screenTrackName={vehicleProfileEvents.entryPointCreateAccount.controlActionScreen}
		/>
	);

	const VersionJoin = () => (
		<ExperimentLandingPage
			ownersData={ownersData}
			updatePage={() => setComplete(true)}
			emailEnteredEvent={vehicleProfileEvents.entryPointCreateAccount.emailEnteredEvent}
			onCreateAccountEvent={vehicleProfileEvents.entryPointCreateAccount.createAccountCtaEvent}
			onClickSigninEvent={vehicleProfileEvents.entryPointCreateAccount.signInLinkEvent}
			contentTitle={`Join ${ownersData.profile.personalName} on the road`}
			contentChecklist={checklistItems}
			submitBtnLabel={'Let’s go 👉 '}
			screenTrackName={vehicleProfileEvents.entryPointCreateAccount.joinFollowScreen}
		/>
	);

	const VersionNeedToKnow = () => (
		<ExperimentLandingPage
			ownersData={ownersData}
			updatePage={() => setComplete(true)}
			emailEnteredEvent={vehicleProfileEvents.entryPointCreateAccount.emailEnteredEvent}
			onCreateAccountEvent={vehicleProfileEvents.entryPointCreateAccount.createAccountCtaEvent}
			onClickSigninEvent={vehicleProfileEvents.entryPointCreateAccount.signInLinkEvent}
			contentTitle={`${ownersData.profile.personalName} is borrowing your car`}
			contentChecklist={checklistItems}
			submitBtnLabel={'Let’s go 👉 '}
			screenTrackName={vehicleProfileEvents.entryPointCreateAccount.knowMoreScreen}
		/>
	);

	const experiments = [
		{
			size: 3334, // 34%
			value: VersionControl,
		},
		{
			size: 3333, // 33%
			value: VersionJoin,
		},
		{
			size: 3333, // 33%
			value: VersionNeedToKnow,
		},
	];

	if (loadingSharedQuote)
		return <PageSpinner />;

	if (isEmailAttached || complete)
		return <Locked showHub />;

	return <MVExperiments testId={'ab_000000CGwO58jQCIrBoqK0MSN2jzc'} experiments={experiments} />;
};

export default CreateAccountLandingPage;
