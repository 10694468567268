import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { LinkButton } from './Button';
import TwoColumnsLayout from './layout/TwoColumns';
import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';

interface PricingItem {
	value: number;
	unit: string;
	price: string;
}
interface Button {
	text: string;
	url: string;
}

interface PricingSectionProps extends TitleSegmentProps {
	id?: string;
	pricingTitle: string;
	flip?: boolean;
	items: PricingItem[];
	button?: Button;
}

const PricingSection: React.FCWithChildren<PricingSectionProps> = props => {
	const {
		id,
		flip,
		pricingTitle,
		items,
		button,
		...titleSegment
	} = props;

	const refDurations = useRef<HTMLDivElement>(void 0);
	const [selected, setSelected] = useState(0);

	const arrowClick = () => {
		if (!refDurations.current)
			return;

		refDurations.current.scrollLeft += 100;
	};

	return (
		<TwoColumnsLayout flip={flip} id={id}>
			<TitleSegment
				{...titleSegment}
				isHero={false}
			/>

			<Wrapper>
				<Typography $type={'Body.Large'} $bold $color={'textOnSurfaceBackground'}>
					{pricingTitle}
				</Typography>

				<ScrollableDurationsWrap>
					<ScrollableDurations ref={refDurations}>
						{items.map((item, index) => (
							<DurationItem
								key={`${index}:${item.unit}:${item.value}`}
								selected={selected === index}
								onClick={() => setSelected(index)}
							>
								<Typography $type={'Body.Large'} $bold $color={'textHighlight'}>
									{item.value}
								</Typography>
								<Typography $type={'Label.Medium'} $bold $color={'textHighlight'}>
									{item.unit}
								</Typography>
								<Typography $type={'Label.Small'} $bold $color={'textHighlight'}>
									{item.price}
								</Typography>
							</DurationItem>
						))}
					</ScrollableDurations>
					<ArrowContainer onClick={arrowClick}>
						<Icon icon={'ic_chevron_down_sm'} $size={'16px'} $color={'blankFill'} />
					</ArrowContainer>
				</ScrollableDurationsWrap>

				{button && (
					<Margin $marginTop={'extraLarge'}>
						<LinkButton href={button.url}>{button.text}</LinkButton>
					</Margin>
				)}
			</Wrapper>
		</TwoColumnsLayout>
	);
};

export const contentAdapter = (get: GetterType): PricingSectionProps => {
	const pricingTitle = get<string>('pricing_title');
	const flip = get<boolean>('flip') || false;
	const id = get<string>('id');
	const hasButton = Boolean(get<string>('button.text'));
	const button = hasButton ? get<Button>('button') : void 0;
	const items = get<PricingItem[]>('items');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		id,
		flip,
		pricingTitle,
		items,
		button,
		...titleSegment,
	};
};

const Wrapper = styled.div`
	position: relative;
	max-width: 400px;

	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;

	& > ${Typography} {
		color: ${p => p.theme.ui.textOnSurfaceBackground};
	}
`;

const ScrollableDurationsWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ArrowContainer = styled.div`
	cursor: pointer;
	user-select: none;
	flex: 0 0 30px;
	display: flex;
	width: 30px;
	height: 30px;
	margin-left: ${p => p.theme.spacing.regular};
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background: ${p => p.theme.ui.primaryAction};

	& > * {
		transform: rotate(-90deg);
	}

	@media (max-width: 767px) {
		display: none;
	}
`;

const ScrollableDurations = styled.div`
	flex: 1;
	max-width: 355px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: ${p => p.theme.spacing.large} 0;
	margin: ${p => p.theme.spacing.regular} auto 0 auto;
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;

	@media (max-width: 370px) {
		max-width: 320px;
	}

	::before {
		content: "_";
		opacity: 0;
		flex: 0 0 10%;
	}
	::after {
		content: "_"; /* can't be a single space for some chrome bug */
		opacity: 0;
		flex: 0 0 20px;
	}
`;

const DurationItem = styled.div<{ selected?: boolean }>`
	display: flex;
	user-select: none;
	cursor: pointer;
	flex-direction: column;
	align-items: center;
	height: 100px;
	flex: 0 0 100px;
	padding: ${p => p.theme.spacing.regular};
	box-sizing: border-box;
	justify-content: space-evenly;
	border-radius: ${p => p.theme.borderRadius.regular};
	background: ${p => p.selected ? p.theme.ui.surfaceDark : p.theme.ui.surface};
	box-shadow: 0px 4px 25px 0px rgba(206, 206, 234, 0.4);


	${Typography} {
		color: ${p => p.selected ? p.theme.ui.blankFill : p.theme.ui.textHighlight};
	}

	& + & {
		margin-left: ${p => p.theme.spacing.regular};
	}
`;

export default PricingSection;
