import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import useProfileInternalChangeRequest from '../hooks/use-profile-internal-change-request';
import { runLtmEstimate } from '../store';
import Form from './atoms/Form';
import EmploymentStatus from './binded-fields/EmploymentStatus';
import HomeOwner from './binded-fields/HomeOwner';
import JobTitle from './binded-fields/JobTitle';
import MaritalStatus from './binded-fields/MaritalStatus';
import OwnOtherVehicles from './binded-fields/OwnOtherVehicles';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import Typography from '~lib/frontend/design-system/components/Typography';

const Personal: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const { value: employmentStatus } = useProfileInternalChangeRequest('employmentCode');
	const requiresJobTitle = ['e', 's'].includes(employmentStatus?.toLowerCase());

	const formTracking = useFormEventTracking();

	useEffect(() => {
		dispatch(runLtmEstimate.actions.request());
	}, [dispatch]);

	return (
		<Form {...formTracking}>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'Personal details'}
				</Typography>

				<PageError page={page} />
			</div>

			<MaritalStatus />
			<EmploymentStatus />
			{requiresJobTitle && <JobTitle />}
			<HomeOwner />
			<OwnOtherVehicles />

			<ProceedButton path={'/quote/customise'}>
				{'Continue'}
			</ProceedButton>
		</Form>
	);
};

export default Personal;
