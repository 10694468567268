import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ksuid from '@cuvva/ksuid';

import { VerificationContextProvider } from '../contexts/VerificationContext';
import ComprehensiveCoverCard from './molecules/ComprehensiveCoverCard';
import VerifyPrimaryIdentifier from './molecules/verifications/identifier';
import VerificationEnd from './molecules/verifications/VerificationEnd';
import VerificationStart from './molecules/verifications/VerificationStart';
import VerifyAddress from './molecules/verifications/VerifyAddress';
import VerifyLicence from './molecules/verifications/VerifyLicence';
import VerifyNoClaims from './molecules/verifications/VerifyNoClaims';
import VerifySelfie from './molecules/verifications/VerifySelfie';
import VerifyVehicle from './molecules/verifications/VerifyVehicle';
import VerificationsSplitUI from './molecules/VerificationsSplitUI';

const generateKey = () => ksuid.generate('request').toString();

const Verifications = () => {
	const match = useRouteMatch();

	return (
		<VerificationsSplitUI splashContent={<ComprehensiveCoverCard />}>
			<VerificationContextProvider>
				<Switch>
					<Route sensitive exact path={`${match.path}`}>
						<VerificationStart />
					</Route>

					<Route sensitive exact path={`${match.path}/email`}>
						<VerifyPrimaryIdentifier type={'email'} key={generateKey()} />
					</Route>

					<Route sensitive exact path={`${match.path}/mobile_phone`}>
						<VerifyPrimaryIdentifier type={'mobile_phone'} key={generateKey()} />
					</Route>

					<Route sensitive exact path={`${match.path}/kyc_photo`}>
						<VerifySelfie />
					</Route>

					<Route sensitive exact path={`${match.path}/license_photo`}>
						<VerifyLicence />
					</Route>

					<Route sensitive exact path={`${match.path}/residential_photo`}>
						<VerifyAddress />
					</Route>

					<Route sensitive exact path={`${match.path}/vehicle_photo`}>
						<VerifyVehicle />
					</Route>

					<Route sensitive exact path={`${match.path}/ncb_photo`}>
						<VerifyNoClaims />
					</Route>

					<Route sensitive exact path={`${match.path}/end`}>
						<VerificationEnd />
					</Route>
				</Switch>
			</VerificationContextProvider>
		</VerificationsSplitUI>
	);
};

export default Verifications;
