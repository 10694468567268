import React from 'react';
import { Helmet } from 'react-helmet-async';

import { getEnvironment } from '~lib/frontend/helpers/environment';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import HeaderHero from '~website/components/Hero/HeaderHero';
import Footer from '~website/components/layout/footer';

const NotFound: React.FunctionComponent = () => {
	const environment = getEnvironment();
	const url = environment === 'server' ? '/' : createAwareUrl('/');

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Page not found | Cuvva'}</title>
				<meta name={'robots'} content={'noindex, follow'} />
			</Helmet>
			<HeaderHero
				titleSegment={{
					centered: true,
					title: 'Not found!',
					body: 'Looks like the page you were trying to find has driven off.',
					showButton: {
						text: 'Country roads, take me home...',
						url,
					},
				}}
				mainImage={void 0}
			/>
			<Footer />
		</React.Fragment>
	);
};

export default NotFound;
