import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';
import Button from '~lib/frontend/design-system/components/button/Button';
import IconWrapped from '~lib/frontend/design-system/components/IconWrapped';
import Typography from '~lib/frontend/design-system/components/Typography';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';
import { useMobileOneLink } from '~website/hooks/use-mobile-one-link';

interface ResultContentProps {
	get: (key: string, fallback?: string) => string;
}

const DownloadInfo: React.FCWithChildren<ResultContentProps> = ({ get }) => {
	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const location = useLocation();
	const mobileOneLink = useMobileOneLink();
	const { isMobile } = useMobileDetect();
	const newOneLink = isMobile ? mobileOneLink : oneLink.general;

	const urlWithParams = reapplyParamsIfExternalUrl(newOneLink, sourceTracking, location.pathname);

	const downloadApp = () => {
		window.open(urlWithParams, '_blank');
	};

	return (
		<ResultContent>
			<IconWrapped
				icon={'ic_device_iphone'}
				$size={'medium'}
				$type={'square'}
				$style={'primary'}
				$muted
			/>
			<Typography
				$type={'Body.Large'}
				$align={'center'}
				$marginBottom={'extraSmall'}
				$marginTop={'large'}
				$color={'textOnSurfaceBackground'}
			>
				{get('result_title')}
			</Typography>
			<Typography
				$type={'Body.Medium'}
				$align={'center'}
				$marginBottom={'large'}
			>
				{get('result_sub_title')}
			</Typography>
			<Button onClick={downloadApp} $type={'primary'}>{'Download Cuvva'}</Button>
		</ResultContent>
	);
};

const ResultContent = styled.div`
	width: 340px;
	margin: 24px auto 62px;
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.greaterThan('tablet')`
		margin: 24px auto 80px;
	`}

	button {
		margin: 0 auto;
	}
`;

export default DownloadInfo;
