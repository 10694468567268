import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentCardsColumn, { contentAdapter } from '~website/components/atoms/TitleSegmentCardsColumn';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentCardsColumn {...contentAdapter(getter)} />
);

export default {
	type: 'title_segment_cards_column',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			subtitle: { type: 'string' },
			body: { type: 'text' },
			show_button: { type: 'cta' },
			cards: {
				type: 'object[]',
				fields: {
					illustration_name: { type: 'string' },
					title: { type: 'string' },
					body: { type: 'text' },
					card_url: { type: 'string' },
					show_checklist: { type: 'checklistItem[]' },
					show_button: { type: 'cta' },
				},
			},
		},
		default: {
			title: 'Anybody that needs a test',
			body: 'gets a test.',
			show_button: {
				text: 'Do get a test',
				url: '#',
			},
			cards: [{
				illustration_name: 'il_cuvva_phone.svg',
				title: 'test',
				body: 'The first feature',
				card_url: '',
				show_checklist: [{ label: 'One', checked: true }, { label: 'Two', checked: true }, { label: 'Three', checked: true }],
				show_button: {
					text: 'Get a quote',
					url: '#',
				},
			}, {
				illustration_name: 'il_cuvva_phone.svg',
				title: 'test',
				body: 'The second feature',
				card_url: '',
				show_checklist: [{ label: 'One', checked: true }, { label: 'Two', checked: true }, { label: 'Three', checked: true }],
				show_button: {
					text: 'Get a quote',
					url: '#',
				},
			}, {
				illustration_name: 'il_cuvva_phone.svg',
				title: 'Item three',
				body: 'The third feature',
				card_url: '',
				show_checklist: [{ label: 'One', checked: true }, { label: 'Two', checked: true }, { label: 'Three', checked: true }],
				show_button: {
					text: 'Get a quote',
					url: '#',
				},
			}],
		},
	},
};
