import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { listUserCarClubs } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(listUserCarClubs.request, function* listUserCarClubsSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.listUserCarClubs, client, payload);
        yield put(listUserCarClubs.success(ident, response));
    }
    catch (error) {
        yield put(listUserCarClubs.failure(ident, error));
    }
});
