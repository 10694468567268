import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Radio } from '../atoms/Radio';
import Typography from '~lib/frontend/design-system/components/Typography';
const RadioButton = forwardRef((props, ref) => {
    const { title, body, ...otherProps } = props;
    return (React.createElement(RadioWrap, null,
        React.createElement(Radio, { ...otherProps, ref: ref }),
        React.createElement(CopyContainer, null,
            React.createElement(Typography, { "$type": 'Body.Medium', "$color": 'textOnSurfaceBackground' }, title),
            body && (React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackgroundMuted' }, body)))));
});
const RadioWrap = styled.label `
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.small};
	align-items: flex-start;
	justify-content: flex-start;

	${Radio} {
		flex: 0 0 auto;
	}
`;
const CopyContainer = styled.div `
	display: flex;
	flex-direction: column;
	flex: 1;
`;
export default RadioButton;
