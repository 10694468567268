import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

export const TestimonialsList = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-start;
	width: 100%;
	gap: ${p => p.theme.spacing.large};

	${media.greaterThan('laptop')`	
		grid-template-columns: 1fr 1fr;
		align-items: center;
	`}
`;

export const TestimonialWrapper = styled.div`
	padding: ${p => p.theme.spacing.regular};
	border-radius: ${p => p.theme.borderRadius.regular};
	border: 1px solid ${p => p.theme.ui.borderSeparator};
`;
