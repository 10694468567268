import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';
import styled from 'styled-components';

import RadioButton from '~lib/frontend/design-system/components/input/molecules/RadioButton';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { setUserStatus, userStatus } from '~lib/platform/marketing-consent/store/actions';
import { ConsentType } from '~lib/platform/marketing-consent/store/types';
import useUser from '~website/hooks/use-user';

const generateRequestId = () => ksuid.generate('request').toString();

const MarketingPreferences: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.profile.contact_preferences.${key}`, fallback);
	const { userId } = useUser();
	const [localEmailSettings, setLocalEmailSettings] = useState<ConsentType | undefined>(void 0);
	const [requestId, setRequestId] = useState(generateRequestId);
	const status = useSelector(s => s.platform.marketingConsent.userStatus[userId]);

	// NB: currentEmailSettings could be `null` if this was never set.
	const currentEmailSettings = status?.response?.emailSetting;

	const setStatus = useSelector(s => s.platform.marketingConsent.setUserStatus[requestId]);
	const setStatusStatus = useAsyncStateStatus(setStatus).status;

	useEffect(() => {
		if (!userId)
			return;

		dispatch(userStatus.actions.request({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (!localEmailSettings || !userId)
			return;

		const requestId = generateRequestId();

		setRequestId(requestId);
		dispatch(setUserStatus.actions.request({ requestId, userId, emailSetting: localEmailSettings }));
	}, [dispatch, localEmailSettings, userId]);

	useEffect(() => {
		if (setStatusStatus === 'error')
			setLocalEmailSettings(currentEmailSettings);
	}, [dispatch, setStatusStatus, currentEmailSettings]);

	useEffect(() => {
		if (currentEmailSettings)
			setLocalEmailSettings(currentEmailSettings);

		if (currentEmailSettings === null)
			setLocalEmailSettings('relevant');
	}, [dispatch, currentEmailSettings]);

	return (
		<RadioContainer>
			<RadioButton
				name={'marketing_preferences'}
				value={'anything'}
				title={get('types.anything.title', 'Send all the good stuff')}
				body={get('types.anything.body')}
				checked={localEmailSettings === 'anything'}
				onChange={() => setLocalEmailSettings('anything')}
			/>
			<RadioButton
				name={'marketing_preferences'}
				value={'relevant'}
				title={get('types.relevant.title', 'Relevant updates and reminders')}
				body={get('types.relevant.body')}
				checked={localEmailSettings === 'relevant'}
				onChange={() => setLocalEmailSettings('relevant')}
			/>
			<RadioButton
				name={'marketing_preferences'}
				value={'essential'}
				title={get('types.essential.title', 'Unsubscribe')}
				body={get('types.essential.body')}
				checked={localEmailSettings === 'essential'}
				onChange={() => setLocalEmailSettings('essential')}
			/>
		</RadioContainer>
	);
};

const RadioContainer = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.large};
	flex-direction: column;

	& > * {
		flex: 1;
	}
`;

export default MarketingPreferences;
