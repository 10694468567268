import React from 'react';
import styled from 'styled-components';

import Spinner from '~lib/frontend/design-system/components/spinner';

const PageSpinner: React.FunctionComponent = () => (
	<SpinnerContainer>
		<div>
			<Spinner $size={'30px'} />
		</div>
	</SpinnerContainer>
);

const SpinnerContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;

export default PageSpinner;
