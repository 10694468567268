import React from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import SearchableInput from '~lib/frontend/design-system/components/input/molecules/SearchableInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { getAbiCodesOrDefault } from '~website/helpers/quick-quote';

const JobTitle: React.FunctionComponent = () => {
	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) => g(`motor.long_term.questions.about_you.occupation.${key}`, fallback);

	const { value, validation, onChange } = useProfileInternalChangeRequest('occupationCode', notEmptyValidator);
	const error = useOnPageError(validation, 'job_title_input');
	const abiCodes = useSelector(state => state.platform.codeMapping.abiCodes);
	const occupationCodes = getAbiCodesOrDefault(abiCodes).occupationCodes?.map(value => ({
		label: value.name,
		value: value.code,
	})).sort((a, b) => a.label > b.label ? 1 : -1);

	return (
		<QuoteInputDecorator
			label={get('question', 'What’s your job title?')}
			help={get('detail', 'If you can’t find your exact role, just pick one that’s close to it')}
			error={error}
		>
			<SearchableInput
				options={occupationCodes}
				onChange={({ value }) => onChange(value)}
				value={value}
				data-event-onchange-name={'job_title_selected'}
				data-event-onchange-value={value}
			/>
		</QuoteInputDecorator>
	);
};

export default JobTitle;
