import createAsyncMapSlice from '~lib/shared/redux/slices/async-map-slice';
const action = (...args) => ['@@platform/marketing-consent'].concat(...args).join('/');
export const userStatus = createAsyncMapSlice(action `userStatus`, p => p.userId);
export const setUserStatus = createAsyncMapSlice(action `setUserStatus`, p => p.requestId);
export const getAuthenticationToken = createAsyncMapSlice(action `getAuthenticationToken`, p => p.identifier);
export const getDeviceConfig = createAsyncMapSlice(action `getDeviceConfig`, p => p.integration);
export const marketingConsentActions = {
    userStatus: userStatus.actions,
    setUserStatus: setUserStatus.actions,
    getAuthenticationToken: getAuthenticationToken.actions,
    getDeviceConfig: getDeviceConfig.actions,
};
