import React, { useEffect, useState } from 'react';
import { useFrontload } from 'react-frontload';
import { useDispatch } from 'react-redux';
import { sentenceCase } from 'change-case';
import styled from 'styled-components';

import LightningBolts from '../molecules/LightningBolts';
import PersonCard from '../molecules/PersonCard';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { getEnvironment } from '~lib/frontend/helpers/environment';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import { GetterType } from '~lib/frontend/types/content';
import { TypedObject } from '~lib/shared/helpers/typed';
import { TabBar, TabItem } from '~website/components/molecules/Tabs';
import { getFrontloadClient } from '~website/prerender/frontload-client';
import { ApplicationState } from '~website/store';
import { listCuvvaPeeps } from '~website/store/about/actions';
import { listCuvvaPeepsFrontload } from '~website/store/about/sagas/list-cuvva-peeps';
import { CuvvaPeep, CuvvaPeeps } from '~website/store/about/types';

interface MeetTheTeamProps {
	title: string;
	body: string;
	seeMoreContent: string;
	buttonContent: string;
}

const MeetTheTeam: React.FCWithChildren<MeetTheTeamProps> = props => {
	const dispatch = useDispatch();
	const [tab, setTab] = useState('All teams');
	const [easterMode, setEasterMode] = useState(false);
	const { title, body, buttonContent } = props;
	const staffStore = useSelectorOrDefault((s: ApplicationState) => s.internal.about.listCuvvaPeeps, {
		fetching: true,
	});

	useFrontload('service-staff', async () => {
		await listCuvvaPeepsFrontload(getFrontloadClient(), dispatch);
	});

	useEffect(() => {
		if (getEnvironment() === 'browser') dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	if (staffStore.error || !staffStore.response) return null;

	const tabs = getTabContent(staffStore.response);

	return (
		<Wrapper>
			<LightningBolts setEasterMode={setEasterMode} />

			<TitleContainer>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $marginBottom={'extraLarge'}>
					{title}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $marginTop={'large'}>
					{body}
				</Typography>
			</TitleContainer>

			<TabBar $items={TypedObject.keys(tabs).length}>
				{TypedObject.keys(tabs).map(k => (
					<TabItem active={tab === k} key={k} onClick={() => setTab(k)}>
						<Typography $type={'Label.Medium'} $color={tab === k ? 'textOnFill' : 'textHighlight'}>
							{k}
						</Typography>
						<Typography $type={'Label.Medium'} $color={tab === k ? 'textOnFill' : 'textHighlight'}>
							{tabs[k]}
						</Typography>
					</TabItem>
				))}
			</TabBar>

			<PeopleWrapper>
				{staffStore.response
					.filter(p => filterPeeps(p, tab))
					.map(peep => (
						<PersonCard easterMode={easterMode} peep={peep} key={peep.id} />
					))}
			</PeopleWrapper>

			<Button
				as={Anchor}
				// @ts-ignore
				href={'/careers'}
				$type={'primary'}
				$size={'large'}
				$display={'inline-flex'}
			>
				{buttonContent}
			</Button>
		</Wrapper>
	);
};

function getTabContent(staff: CuvvaPeeps) {
	const mapping: Record<string, number> = { 'All teams': staff.length };

	for (const person of staff) {
		const team = person.teams?.[0];

		if (!team) continue;

		const friendlyTeam = sentenceCase(team);

		if (mapping[friendlyTeam]) mapping[friendlyTeam] += 1;
		else mapping[friendlyTeam] = 1;
	}

	return mapping;
}

function filterPeeps(peep: CuvvaPeep, tab: string) {
	if (tab === 'All teams') return true;

	return peep.teams[0] === tab.toLowerCase();
}

const TitleContainer = styled.div`
	max-width: 550px;
	margin: 0 auto 32px auto;
`;

const Wrapper = styled.div`
	position: relative;
	text-align: center;
`;

const PeopleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	margin: 30px 0;
`;

export const contentAdapter = (get: GetterType): MeetTheTeamProps => {
	const title = get('title');
	const body = get('body');
	const seeMoreContent = '';
	const buttonContent = get<string>('button_content');

	return {
		title,
		body,
		seeMoreContent,
		buttonContent,
	};
};

export default MeetTheTeam;
