export const arrayWithDefault = (args, defaultValue) => 
// @ts-ignore (very sad)
(args.map((a) => a || defaultValue));
export const withDefault = (value, defaultValue) => (value || defaultValue);
export const getProperty = (o, propertyName) => o[propertyName];
function typedTypedObject(inputObject) {
    return Object.keys(inputObject);
}
function typedObjectValues(inputObject) {
    return Object.values(inputObject);
}
function typedObjectEntries(inputObject) {
    return Object.entries(inputObject);
}
export const TypedObject = {
    keys: typedTypedObject,
    values: typedObjectValues,
    entries: typedObjectEntries,
};
