import React from 'react';

import Counter3ConfettiGrid from './atoms/Counter3ConfettiGrid';
import Counter3ConfettiGridCounters from './atoms/Counter3ConfettiGridCounters';
import Counter3ConfettiSection from './atoms/Counter3ConfettiSection';
import Counter3ConfettiWrap from './atoms/Counter3ConfettiWrap';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import Number3Confetti from '~website/assets/svgs/referral/Number3Confetti';
import ConfettiOnOver from '~website/components/ConfettiOnOver';

interface Counter3ConfettiProps {
	get: (key: string, fallback?: string) => string;
}

const Counter3Confetti: React.FCWithChildren<Counter3ConfettiProps> = ({ get }) => (
	<Counter3ConfettiSection>
		<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>
			{get('title')}
		</Typography>

		<Counter3ConfettiGrid>
			<Counter3ConfettiGridCounters>
				<img src={'/static/images/counter-3-confetti/desktop-counter.svg'} />
				<img src={'/static/images/counter-3-confetti/mobile-counter.svg'} />
			</Counter3ConfettiGridCounters>
			<div>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
					{get('items[0][0]')}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{get('items[0][1]')}
				</Typography>
			</div>
			<div>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
					{get('items[1][0]')}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{get('items[1][1]')}
				</Typography>
			</div>
			<div>
				<ConfettiOnOver>
					<Counter3ConfettiWrap>
						<Number3Confetti />
					</Counter3ConfettiWrap>
				</ConfettiOnOver>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
					{get('items[2][0]')}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{get('items[2][1]')}
				</Typography>
			</div>
		</Counter3ConfettiGrid>

		{Boolean(get('cta')) && (
			<Button
				as={Anchor}
				// @ts-ignore
				to={get('cta.url')}
				$display={'inline-block'}
			>
				{get('cta.text')}
			</Button>
		)}
	</Counter3ConfettiSection>
);

export default Counter3Confetti;
