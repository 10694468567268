import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import UserDropdown from './UserDropdown';
import Icon from '~lib/frontend/design-system/components/Icon';
import { UIColors } from '~lib/frontend/design-system/types';
import useUser from '~website/hooks/use-user';

interface UserSignedInProps {
	color: keyof UIColors;
}

const UserSignedIn: React.FCWithChildren<UserSignedInProps> = ({ color }) => {
	const history = useHistory();
	const { profile } = useUser();
	const containerRef = useRef<HTMLDivElement>();
	const itemButton = useRef<HTMLButtonElement>();
	const [open, setOpen] = useState(false);

	// Close menu on click out
	useEffect(() => {
		if (!containerRef.current)
			return void 0;

		const onClick: EventListener = e => {
			if (containerRef.current?.contains(e.target as Node))
				return;

			setOpen(false);
		};

		window.addEventListener('click', onClick, { passive: true });

		return () => window.removeEventListener('click', onClick);
	}, []);

	// Handle self-closing menu on navigation elsewhere
	useEffect(() => {
		const unlisten = history.listen(() => {
			setOpen(false);
		});

		return () => unlisten();
	}, [history]);

	return (
		<Wrapper ref={containerRef}>
			<ItemButton
				$open={open}
				$color={color}
				ref={itemButton}
				onClick={() => setOpen(!open)}
			>
				<Icon icon={'ic_account_circle'} $color={color} $size={'24px'} />
				<Name>
					{profile?.preferredName ?? profile?.personalName}
				</Name>
				<Icon icon={'ic_chevron_down_sm'} $color={color} $size={'20px'} />
			</ItemButton>

			<UserDropdown callerRef={itemButton} open={open} setOpen={setOpen} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	min-width: 0;
`;

const Name = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ItemButton = styled.button<{ $open: boolean; $color: keyof UIColors }>`
	min-width: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${p => p.theme.spacing.extraSmall};

	${p => p.theme.typography['Label.Medium']()};
	color: ${p => p.theme.ui[p.$color]};

	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;

	& > :last-child {
		transform: ${p => p.$open ? 'rotate(-180deg)' : 'rotate(0deg)'};
		transition: all 0.2s cubic-bezier(0, 1, 0, 1);
	}
`;

export default UserSignedIn;
