import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import Container from '../atoms/Container';
import EmbeddedVideo, { EmbeddedVideoProps, Wrapper as EmbeddedVideoWrapper } from '../atoms/EmbeddedVideo';
import { TwoColumnsImageTag } from '../atoms/layout/TwoColumns';
import TitleSegment, { TitleSegmentProps } from '../atoms/TitleSegment';
import NewHeaderHeroWrap from './NewHeaderHeroWrap';
import SubHeaderHero, { SubHeaderHeroProps } from './SubHeaderHero';
import { media } from '~lib/frontend/design-system/breakpoints';
import {
	EditableTypographyContext,
	useCreateEditableTypographyContextValue,
} from '~lib/frontend/design-system/context/editable-typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import { Theme } from '~lib/frontend/design-system/types';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset } from '~lib/frontend/types/content';

interface NewHeaderHeroProps {
	theme?: Theme;
	titleSegment: TitleSegmentProps;
	mainImage: DesignSystemAsset;
	embeddedVideo?: EmbeddedVideoProps;
	mobileImage?: DesignSystemAsset;
	subHero?: SubHeaderHeroProps;
}

const NewHeaderHero: FC<NewHeaderHeroProps> = ({
	theme,
	titleSegment,
	mainImage,
	embeddedVideo,
	mobileImage,
	...props
}) => {
	const asBackground = titleSegment.centered;

	const main = useDesignSystemAsset(mainImage);
	const mobile = useDesignSystemAsset(mobileImage);

	const video = embeddedVideo?.thumbnail && <EmbeddedVideo {...embeddedVideo} />;

	const titleSegmentEditableTypographyContext = useCreateEditableTypographyContextValue('title_segment');

	return (
		<Fragment>
			<NewHeaderHeroWrap
				$themeKey={theme}
				$desktopBackgroundImage={main}
				$mobileBackgroundImage={mobile}
				$hasSubHero={Boolean(props.subHero)}
			>
				<InnerContent>
					{!video && asBackground && (
						<PaddedInner>
							<EditableTypographyContext.Provider value={titleSegmentEditableTypographyContext}>
								<TitleSegment {...titleSegment} isHero />
							</EditableTypographyContext.Provider>
						</PaddedInner>
					)}
					{!asBackground && (
						<PaddedInner>
							<TitleSegment {...titleSegment} isHero />
						</PaddedInner>
					)}
				</InnerContent>
			</NewHeaderHeroWrap>

			{props.subHero && <SubHeaderHero {...props.subHero} theme={theme} />}
		</Fragment>
	);
};

const PaddedInner = styled.div`
	padding: 0 ${p => p.theme.spacing['3xLarge']};
	position: relative;
	width: 100%;
	max-width: 1280px;

	& > * {
		margin: 0 auto;
	}

	${media.lessThan('tablet')`
		padding: 0 0;
	`}
`;

const InnerContent = styled(Container)`
	padding-left: 0;
	padding-right: 0;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${p => safeAreaPadded(p.theme.spacing.large, '24px')};

	/* as the content of the hero always goes under the header, we need to roughly account for the navigation height */
	@media screen and (min-width: 1024px) {
		padding-top: 60px;
	}

	${media.lessThan('tablet')`
		padding: 180px 20px;
	`}

	${EmbeddedVideoWrapper} {
		${media.greaterThan('tablet')`
			display: none;
		`}
	}

	${PaddedInner} ${TwoColumnsImageTag}, ${PaddedInner} ${EmbeddedVideoWrapper} {
		display: none;

		${media.greaterThan('tablet')`
			display: block;
		`}
	}
`;

export default NewHeaderHero;
