const maskDate = (val: string) => {
	const v = val.replace(/[^\d/]/g, '').slice(0, 10);

	let [day, month, year] = v.split('/');
	// if there a slash, the part on the right is a string
	// otherwise it's undefined

	if (day?.length === 2 && !month) month = '';
	if (day?.length === 1 && month?.length === 0) day = `0${day}`;

	if (month?.length === 2 && !year) year = '';
	if (month?.length === 1 && year?.length === 0) month = `0${month}`;

	if (year?.length === 0 && month?.length === 0) year = void 0;

	return [day, month, year].filter(f => f !== void 0).join('/');
};

export { maskDate };
