import { all } from 'redux-saga/effects';
import createNewHiringOffer from './create-new-hiring-offer';
import deleteHiringOffer from './delete-offer';
import generateShareKey from './generate-share-key';
import getHiringOffersList from './get-hiring-offer-list';
import retrieveHiringOffer from './retrieve-offer';
import updateHiringOffer from './update-hiring-offer';
import updateHiringOfferStatus from './update-hiring-offer-status';
export default function* hiringOfferSaga() {
    yield all([
        createNewHiringOffer,
        updateHiringOffer,
        updateHiringOfferStatus,
        generateShareKey,
        getHiringOffersList,
        deleteHiringOffer,
        retrieveHiringOffer,
    ]);
}
