import React from 'react';
import styled from 'styled-components';

import Link from '~lib/frontend/atoms/Link';
import Icon from '~lib/frontend/design-system/components/Icon';
import { BodyBold } from '~website/components/atoms/Typography';

const CardContainer = styled.div`
	margin-bottom: 30px;
	box-shadow: ${p => p.theme.boxShadow.small};
	background: ${p => p.theme.ui.surface};
	border: 1px solid ${p => p.theme.ui.background};
	border-radius: ${p => p.theme.borderRadius.large};
	text-align: left;
`;

const TextContainer = styled(BodyBold)`
	margin: 0 1.25rem;
	display: flex;
	flex-direction: row;

	transition: 0.2s ease;

	& + & {
		border-top: 1px solid ${props => props.theme.ui.background};
	}
`;

const Text = styled(Link)`
	display: block;
	flex: 1;
	color: ${props => props.theme.ui.textHighlight};
	text-decoration: none;
	padding: 1.3rem 0;
`;

const ChevronContainer = styled.div`
	flex: 0 15px;
	padding: 1rem 0;
	padding-left: 10px;

	& > * {
		transform: rotate(-90deg);
	}
`;

interface PageListProps {
	content: {
		url: string;
		text: string;
	}[];
}

const PageList: React.FCWithChildren<PageListProps> = ({ content }) => (
	<CardContainer>
		{content.map((item, key) => (
			<TextContainer key={key} as={'div'}>
				<Text to={item.url}>{item.text}</Text>
				<ChevronContainer>
					<Icon icon={'ic_chevron_down'} $size={'15px'} $color={'textHighlight'} />
				</ChevronContainer>
			</TextContainer>
		))}
	</CardContainer>
);

export default PageList;
