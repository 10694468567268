import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherListExampleVehicles() {
    yield takeEvery(VehicleActionTypes.LIST_EXAMPLE_VEHICLES, workerListExampleVehicles);
}
function* workerListExampleVehicles() {
    const client = yield getContext('api');
    try {
        const resp = yield api.listExampleVehicles(client);
        yield put(actions.listExampleVehicles.success(resp));
    }
    catch (error) {
        yield put(actions.listExampleVehicles.failure(error));
    }
}
