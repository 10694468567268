import styled, { css } from 'styled-components';
export const IconContainer = styled.div `
	width: 24px;
	height: 24px;
	cursor: pointer;

	${p => p.$noDisplay && css `
		visibility: hidden;
	`};
`;
export const AbsoluteModalClose = styled(IconContainer) `
	position: absolute;
	top: 24px;
	right: 24px;

	@media (max-width: 767px) {
		top: 16px;
		right: 16px;
	}
`;
