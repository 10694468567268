import React, { useContext } from 'react';
import { AlignDateChildren } from '../atoms/Aligners';
import Text from '../atoms/Text';
import InputDecoratorContext from '../context/decorator';
const DateInput = props => {
    const { error } = useContext(InputDecoratorContext);
    const { disabled, onChange, values } = props;
    const proxyOnChange = (index, newValue) => {
        const vals = [values?.[0], values?.[1], values?.[2]];
        vals.splice(index, 1, newValue);
        onChange(...vals);
    };
    return (React.createElement(AlignDateChildren, null,
        React.createElement(Text, { "$error": Boolean(error), disabled: disabled, value: values?.[0], onChange: e => proxyOnChange(0, e.target.value), autoComplete: 'bday-day', maxLength: 2, pattern: '[0-9]*' }),
        React.createElement(Text, { "$error": Boolean(error), disabled: disabled, value: values?.[1], onChange: e => proxyOnChange(1, e.target.value), autoComplete: 'bday-month', maxLength: 2, pattern: '[0-9]*' }),
        React.createElement(Text, { "$error": Boolean(error), disabled: disabled, value: values?.[2], onChange: e => proxyOnChange(2, e.target.value), autoComplete: 'bday-year', maxLength: 4, pattern: '[0-9]*' })));
};
export default DateInput;
