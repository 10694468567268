import React from 'react';
import styled from 'styled-components';

import PolicyInfo from '../atoms/PolicyInfo';
import PolicyUserCar from '../atoms/PolicyUserCar';
import PolicyUserInfo from '../atoms/PolicyUserInfo';

const PolicyDetails = () => (
	<PolicyDetailsWrapper>
		<PolicyInfo />
		<PolicyUserInfo />
		<PolicyUserCar />
	</PolicyDetailsWrapper>
);

export default PolicyDetails;

const PolicyDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;
