import styled, { css } from 'styled-components';

import { BodySmallBold, TitleRegular } from '~website/components/atoms/Typography';

export type BuilderPosition = 'side' | 'bottom';

export const WholePageContainer = styled.div<{ $position: BuilderPosition }>`
	display: grid;

	${p => p.$position === 'side' && css`
		grid-template-columns: 1fr min(450px, 30vw);
		grid-template-rows: 1fr;
	`}
	${p => p.$position === 'bottom' && css`
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 400px;

		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		height: 100vh;
	`}
`;

export const BuilderContainer = styled.div<{ $position: BuilderPosition }>`
	background: ${p => p.theme.ui.background};

	${p => p.$position === 'side' && css`
		position: fixed;
		top: 0; right: 0; bottom: 0;
		width: min(450px, 30vw);
		overflow-y: scroll;

		border-left: 2px dashed ${p => p.theme.ui.borderInteractiveDanger};
	`}

	${p => p.$position === 'bottom' && css`
		grid-row: 2;
		grid-column: 1;

		border-top: 2px dashed ${p => p.theme.ui.borderInteractiveDanger};
		overflow-y: auto;
	`}
`;

export const RendererContainer = styled.div<{ $position: BuilderPosition }>`
	${p => p.$position === 'side' && css`
		grid-row: 1;
		grid-column: 1;
	`}
	${p => p.$position === 'bottom' && css`
		grid-row: 1;
		grid-column: 1;
		overflow-y: auto;
	`}
`;

export const PleaseAddNewSection = styled(TitleRegular)`
	text-align: center;
	margin: 200px auto;
	margin-bottom: 600px;
`;

export const EditableSectionWrap = styled.div`
	border-bottom: 1px solid #6f646421;
	margin: 10px 0 24px;
	padding-bottom: 24px;
`;

export const SectionName = styled(BodySmallBold)`
	color: black;
`;

export const AddNewArrayItem = styled.div`
	margin-bottom: 24px;
`;

export const BuilderBlocks = styled.div`
	overflow: auto;
	padding: 10px;

	& > * {
		border-bottom: 1px solid #6f646421;
		padding-top: 24px;
		padding-bottom: 12px;

		&:last-of-type {
			border-bottom: none;
		}
	}
`;

export const FieldWrap = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: ${p => p.theme.spacing.small};

	& > * {
		flex: 1;
	}

	input[type='checkbox'] {
		flex: 0;
	}
`;
export const RemoveListItem = styled(BodySmallBold)`
	flex: 0;
	cursor: pointer;
	color: red;
`;

export const RemoveListItemInline = styled(BodySmallBold)`
	display: flex;
	justify-content: flex-end;
`;

export const PricingItemsWrapper = styled.div`
	padding-bottom: 24px;
`;

export const ChecklistItemWrapper = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.regular};
`;

export const MoreButtonsAdditionWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: ${p => p.theme.spacing.regular};
	margin: ${p => p.theme.spacing.regular} 0;
`;
