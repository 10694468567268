import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';

type ConsentType = 'anything' | 'relevant' | 'essential';

interface HookReturn<T = ConsentType> {
	value: T | undefined;
	onChange: (value: T) => void;
}

const field = 'marketingConsent';

const useMarketingConsent = (): HookReturn => {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);
	const userId = useSelector(s => s.internal.auth.user.response)!;

	const marketing = useSelector(s => s.platform.marketingConsent.userStatus?.[userId]);
	const userConsent = marketing?.response?.emailSetting ?? 'essential';

	const cr = internalChangeRequest[internalChangeRequestId];

	const onChange = useCallback((consent: ConsentType) => {
		dispatch(stepActions.setMarketingConsent({
			userId,
			internalChangeRequestId,
			marketingConsent: consent,
		}));
	}, [dispatch, internalChangeRequestId, userId]);

	const value = cr?.marketingConsent?.[userId] ?? userConsent;

	useEffect(() => {
		dispatch(pageActions.registerField({
			field,
			pageId,
		}));
	}, [dispatch, pageId]);

	return {
		onChange,
		value,
	};
};

export default useMarketingConsent;
