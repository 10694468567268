import styled from 'styled-components';

const SplashSide = styled.div`
	background: ${p => p.theme.ui.secondaryBackground};
	flex: 0 0 432px;
	display: none;
	position: relative;

	@media (min-width: 1024px) {
		display: block;
	}

	@media (min-width: 1440px) {
		flex: 0.43;
	}
`;

export default SplashSide;
