import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import notEmptyValidator from '../validators/not-empty';
import useFriendlyError from './use-friendly-error';
import { User } from '~lib/platform/auth/types';

interface HookReturn<T = string> {
	value: T | undefined;
	validation: ReturnType<typeof useFriendlyError>;
	onChange: (value: T) => void;
}

const field = 'emailAddress';

function useUserEmailAddress(): HookReturn<string> {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);
	const userId = useSelector(s => s.internal.auth.user.response)!;
	const userById = useSelector(s => s.platform.auth.userById[`${userId}-false`]);

	const cr = internalChangeRequest[internalChangeRequestId];

	const onChange = useCallback((emailAddress: string) => {
		dispatch(stepActions.setEmailAddress({
			emailAddress,
			userId,
			internalChangeRequestId,
		}));
	}, [dispatch, userId, internalChangeRequestId]);

	const authEmailAddress = getEmail(userById.response);

	const value = cr?.auth?.[userId] ?? authEmailAddress;

	// idea on how to register the component
	useEffect(() => {
		dispatch(pageActions.registerField({
			field,
			pageId,
		}));
	}, [dispatch, pageId]);

	useEffect(() => {
		const error = notEmptyValidator(value); // todo: this needs a purpose built validator for emails

		dispatch(stepActions.setFieldError({
			field,
			pageId,
			error,
		}));
	}, [value, pageId, dispatch]);

	return {
		onChange,
		value,
		validation: useFriendlyError(page?.fields?.[field]?.error, 'profile', field),
	};
}

function getEmail(user: User | undefined) {
	if (!user || !user.identifiers)
		return void 0;

	// If you attach more than one identifier and go back to the previous page, shouldn't we display to you the
	// last email address you've added regardless what's primary and what's not? ...

	return user.identifiers
		.filter(i => i.type === 'email')
		.sort((a, b) => -a.attachedAt.localeCompare(b.attachedAt))[0]?.value;
}

export default useUserEmailAddress;
