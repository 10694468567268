import styled, { css } from 'styled-components';
import Typography from '../../Typography';
const CheckBoxInputWrapper = styled.span `
	display: flex;
	padding-right: ${p => p.theme.spacing.small};

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	span {
		display: grid;
		place-items: center;
		width: 20px;
		height: 20px;
		border-radius: 6px;
		border: ${p => p.$checked ? `2px solid ${p.theme.ui.surfaceHighlight}` : `1px solid ${p.theme.ui.borderSeparator}`};
	}

	input + span::before {
		content: "";
		width: 0.5em;
		height: 0.5em;
		border-radius: 6px;
		transition: 150ms opacity ease-in-out;
		opacity: 0;
	}

	input:checked + span::before {
		opacity: 1;
		content: "✓";
		width: 1em;
		height: 1em;
		border-radius: unset;
		box-shadow: inset 1em 1em ${p => p.theme.ui.surfaceHighlight};
		color: ${p => p.theme.ui.blankFill};
		padding-left: 0.1em;
		line-height: 0.9em;
	}
`;
export const CheckBoxCardWrapper = styled.label `
	width: 100%;
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	border-radius: ${p => p.theme.borderRadius.regular};
	margin-bottom: ${p => p.theme.spacing.regular};
	padding: ${p => `calc(1px + ${p.theme.spacing.regular})`};
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	cursor: pointer;
	margin-right: ${p => p.theme.spacing.regular};
	margin-bottom: ${p => p.theme.spacing.regular};

	:hover, :focus, :focus-within {
		padding: ${p => p.theme.spacing.regular};
		border: 2px solid ${p => p.theme.ui.borderInteractiveFocus};
	}

	input {
		position: absolute;
		width: 100%;
		opacity: 0;
		left: 0;
	}

	${p => p.$checked && css `
		padding: ${p.theme.spacing.regular};
		border: 2px solid ${p.theme.ui.borderInteractiveSelected};
		background-color: ${p.theme.ui.primaryActionMuted};
	`}

	${p => p.$disabled && css `
		opacity: .6;
		cursor: not-allowed;
	`};


	${p => p.$noBorder && css `
		border: 1px solid transparent;
		margin-bottom: 0;
		border-radius: 0;

		:hover, :focus, :focus-within {
			background-color: ${p.theme.ui.surfaceFillMuted};
			border: 2px solid transparent;

			${CheckBoxCardBadges} {
				display: flex;
			}
		}
		
		${p.$checked && css `
			background-color: ${p.theme.ui.primaryFill};
			border: 2px solid transparent;
			${Typography} {
				color: ${p.theme.ui.textOnFill};
			}
			${CheckBoxCardBadges} {
				display: flex;
			}

			:hover, :focus, :focus-within {
				border: 2px solid transparent;
				background-color: ${p.theme.ui.primaryFill};
			}
		`};
	`};
`;
export const CheckBoxWrapper = styled.label `
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	cursor: pointer;
	margin-right: ${p => p.theme.spacing.regular};
	margin-bottom: ${p => p.theme.spacing.regular};

	input {
		position: absolute;
		width: 100%;
		opacity: 0;
	}

	${p => p.$disabled && css `
		opacity: .6;
		cursor: not-allowed;
	`};

	${p => p.$labelFirst && css `
		flex-direction: row-reverse;
		justify-content: flex-end;
	`}

	${p => p.$stretch && css `
		justify-content: space-between;
	`}
`;
export const CheckBoxCardWithBadgeWrapper = styled.div `
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};
`;
export const CheckBoxCardBadges = styled.div `
	display: ${p => (p.$hideBadgesUntilHover ? 'none' : 'flex')};
	justify-content: flex-end;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};
	flex-wrap: wrap;
	max-width: 60%;
`;
export default CheckBoxInputWrapper;
