export function getItem(key, tier = 'localStorage') {
    if (!hasStorageTier(tier))
        return null;
    const value = window[tier].getItem(key);
    if (value === void 0 || value === null)
        return null;
    return JSON.parse(value);
}
export function removeItem(key, tier = 'localStorage') {
    if (hasStorageTier(tier))
        window[tier].removeItem(key);
}
export function setItem(key, value, tier = 'localStorage') {
    if (!hasStorageTier(tier))
        return;
    window[tier].setItem(key, JSON.stringify(value));
}
let storageResult = void 0;
function hasStorageTier(tier) {
    if (storageResult !== void 0)
        return storageResult;
    if (typeof window === 'undefined')
        return (storageResult = false);
    if (typeof window[tier] !== 'undefined') {
        try {
            window[tier].setItem('feature_test', 'yes');
            if (window[tier].getItem('feature_test') === 'yes') {
                window[tier].removeItem('feature_test');
                return (storageResult = true);
            }
        }
        catch (error) { /**/ }
    }
    return (storageResult = false);
}
export class LocalStorage {
    _prefix;
    constructor(prefix) {
        this._prefix = prefix;
    }
    generateKey(key) {
        return `${this._prefix}.${key}`;
    }
    get(key) {
        return getItem(this.generateKey(key));
    }
    set(key, value) {
        setItem(this.generateKey(key), value);
    }
    remove(key) {
        removeItem(this.generateKey(key));
    }
}
export default {
    getItem,
    removeItem,
    setItem,
    LocalStorage,
};
