// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce(fn, ms) {
    let timer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (...args) => {
        if (timer !== null)
            clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn(...args);
        }, ms);
    };
}
