import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { parseMargin } from './atoms/utils/Margin';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
const IconWrap = styled.div `
	display: ${p => p.$display ?? 'inline-block'};
	width: var(--size, ${p => p.$size});
	height: var(--size, ${p => p.$size});
	background-color: var(--fill, ${p => p.theme.ui[p.$color]});

	/* SC: This is needed as sometimes, in a group of children, we set flex and don't specify how this should behave */
	flex: 0 0 auto;

	${parseMargin};

	mask: url(${p => p.$src}) no-repeat center / contain;
`;
const Icon = forwardRef((props, ref) => {
    const { icon, ...otherProps } = props;
    const src = useDesignSystemAsset({ type: 'icon', value: `${icon}.svg` });
    return (React.createElement(IconWrap, { ...otherProps, "$src": src, ref: ref }));
});
Icon.displayName = 'Icon';
export default Icon;
