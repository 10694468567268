import { call, getContext, put } from 'redux-saga/effects';
import * as actions from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(actions.generateReferralCode.request, function* generateReferralCode(action) {
    const { userId } = action.payload;
    const { ident } = action.meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.generateReferralCode, client, userId);
        yield put(actions.generateReferralCode.success(ident, response));
    }
    catch (error) {
        yield put(actions.generateReferralCode.failure(ident, error));
    }
});
