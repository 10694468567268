import URI from 'urijs';

import { isInternalURL } from './url';
import { TypedObject } from '~lib/shared/helpers/typed';

export const oneLink = {
	playstore: 'https://cuvvaplayapp.onelink.me/bs8e/wdk1o6db',
	appstore: 'https://cuvvaapp.onelink.me/6DCd/jve0ulrz',
	general: 'https://go.onelink.me/PmWW',
};

const inputTrackingParams = [
	'utm_source', 'utm_campaign', 'utm_term', 'utm_content',
	'c', 'af_c', 'cuv_campaign', 'pid', 'af_pid', 'af_ad', 'af_adset',
	'gclid', 'msclkid', 'wbraid', 'keyword',
];

const getCurrentParams = (): Record<string, string> => {
	if (typeof window === 'undefined')
		return {};

	const uri = new URI(window.location.href);

	return uri.query(true) as Record<string, string>;
};

export const getCurrentTrackingParams = (): Record<string, string> => {
	const params = getCurrentParams();

	const filteredParams: Record<string, string> = TypedObject.keys(params).reduce((prev, current) => {
		if (!inputTrackingParams.includes(current))
			return prev;

		return {
			...prev,
			[current]: params[current],
		};
	}, {});

	// exception goes for referrer
	if (!filteredParams.referrer && document && document.referrer && document.referrer !== '')
		filteredParams.referrer = document.referrer.toLowerCase();

	return filteredParams;
};

export function reapplyParamsIfExternalUrl(url: string, sourceTracking: Record<string, string>, currentPath: string) {
	if (isInternalURL(url))
		return url;

	// Skip attribution for facebook - we're making the assumption here that all external URL will be appsflyer tho
	// https://cuvva.slack.com/archives/C0PGNMTT3/p1599062945046500?thread_ts=1599059474.044500&cid=C0PGNMTT3
	if (sourceTracking?.referrer?.includes('facebook'))
		return url;

	const uri = new URI(url);

	// this is opinionated. might need to update it if necessary
	if (Object.keys(uri.query(true)).length)
		return url;

	return reapplyParams(url, sourceTracking, currentPath);
}

export function reapplyParams(url: string, sourceTracking: Record<string, string>, currentPath: string) {
	const finalLink = new URI(url);

	// handle campaign value
	if (sourceTracking?.c)
		finalLink.addQuery('c', sourceTracking.c);
	else if (sourceTracking?.utm_campaign)
		finalLink.addQuery('c', sourceTracking.utm_campaign);
	else if (sourceTracking?.af_c)
		finalLink.addQuery('c', sourceTracking.af_c);
	else if (sourceTracking?.cuv_campaign)
		finalLink.addQuery('c', sourceTracking.cuv_campaign);

	if (sourceTracking?.gclid)
		finalLink.addQuery('af_sub1', sourceTracking.gclid);
	else if (sourceTracking?.msclkid)
		finalLink.addQuery('af_sub1', sourceTracking.msclkid);
	else if (sourceTracking?.wbraid)
		finalLink.addQuery('af_sub1', sourceTracking.wbraid);

	if (sourceTracking?.gclid)
		finalLink.addQuery('pid', 'google_lp');
	else if (sourceTracking?.msclkid)
		finalLink.addQuery('pid', 'bing_lp');
	else if (sourceTracking?.wbraid)
		finalLink.addQuery('pid', 'youtube_lp');
	else if (sourceTracking?.utm_source === 'email')
		finalLink.addQuery('pid', 'email');
	else if (sourceTracking?.utm_source === 'snapchat')
		finalLink.addQuery('pid', 'snapchat_int');
	else if (sourceTracking?.utm_source === 'tiktok')
		finalLink.addQuery('pid', 'bytedanceglobal_int');
	else if (sourceTracking?.utm_source === 'social_facebook')
		finalLink.addQuery('pid', 'organic_facebook');
	else if (sourceTracking?.utm_source === 'social_twitter')
		finalLink.addQuery('pid', 'organic_twitter');
	else if (sourceTracking?.utm_source === 'social_snapchat')
		finalLink.addQuery('pid', 'organic_snapchat');
	else if (sourceTracking?.utm_source === 'social_tiktok')
		finalLink.addQuery('pid', 'organic_tiktok');
	else if (sourceTracking?.utm_source === 'social_linkedin')
		finalLink.addQuery('pid', 'organic_linkedin');
	else if (sourceTracking?.utm_source === 'youtube')
		finalLink.addQuery('pid', 'organic_youtube');
	else if (sourceTracking?.utm_source === 'partnerships')
		finalLink.addQuery('pid', 'partnerships');
	else
		finalLink.addQuery('pid', 'organic_web');

	// SC: I'm not super happy with this.
	const isOrganic = finalLink.query(true).pid === 'organic_web';

	if (isOrganic) {
		finalLink.removeQuery('c');

		if (currentPath.includes('/how-insurance-works/'))
			finalLink.addQuery('c', 'Guides');
		else if (currentPath.includes('/support/'))
			finalLink.addQuery('c', 'Support');
		else if (currentPath.includes('/car-insurance/') || currentPath.endsWith('/car-insurance'))
			finalLink.addQuery('c', 'Car insurance');
		else if (currentPath === '/')
			finalLink.addQuery('c', 'Homepage');
		else if (currentPath.includes('/insurance-groups'))
			finalLink.addQuery('c', 'Insurance groups');
		else if (currentPath.includes('/free-car-check'))
			finalLink.addQuery('c', 'Free car check');
		else if (currentPath.startsWith('/quote/'))
			finalLink.addQuery('c', 'Quote flow');
		else
			finalLink.addQuery('c', 'Other');
	}

	if (isOrganic)
		finalLink.addQuery('af_adset', currentPath);
	else if (sourceTracking?.utm_term)
		finalLink.addQuery('af_adset', sourceTracking.utm_term);

	if (sourceTracking?.utm_content)
		finalLink.addQuery('af_ad', sourceTracking.utm_content);

	// -> Old rules -> (should this be removed?)
	if (sourceTracking?.keyword)
		finalLink.addQuery('af_keywords', sourceTracking.keyword);

	return finalLink.toString();
}
