import { useEffect, useState } from 'react';
const useFlowDialog = (initialStep = 0, flowLength) => {
    const [index, setIndex] = useState(initialStep);
    const [flowPath, setFlowPath] = useState([initialStep]);
    const finalStep = index === flowLength - 1;
    useEffect(() => {
        if (flowPath.includes(index))
            return;
        setFlowPath(prev => [...prev, index]);
    }, [flowPath, index]);
    const goToNextStep = () => {
        const canGoToNextStep = index < flowLength - 1;
        if (!canGoToNextStep)
            return;
        setIndex(index + 1);
    };
    const goToUniqueStep = (value) => {
        const valueIsValid = value >= 0 && value <= flowLength - 1;
        if (!valueIsValid)
            return;
        setIndex(value);
    };
    const goToPreviousStep = () => {
        if (index <= 0)
            return;
        const newFlowPath = flowPath.slice(0, flowPath.length - 1);
        setIndex(newFlowPath[newFlowPath.length - 1]);
        setFlowPath(newFlowPath);
    };
    const endFlow = () => {
        setIndex(initialStep);
        setFlowPath([initialStep]);
    };
    return { index, finalStep, goToPreviousStep, goToNextStep, goToUniqueStep, endFlow };
};
export default useFlowDialog;
