import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCancellationFlow } from '../../context/CancellationFlowContext';
import { CancellationFlowIndex } from '../../utils/cancellationFlow';
import CancellationModalLayout from './CancellationModalLayout';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import { StatedReason } from '~lib/platform/ltm-cancellation/types';
import useUser from '~website/hooks/use-user';

const CancellationReason = () => {
	const { userId } = useUser();
	const cancellationReasonsStore = useSelector(s => s.platform.ltmCancellation.getConfig[userId]);

	const { cancellationReason, setCancellationReason } = useCancellationFlow();

	const [nextStep, setNextStep] = useState(void 0);

	const updateReason = (reason: StatedReason) => {
		if (reason.hasNotes) setCancellationReason({ ...reason, description: '' });
		else setCancellationReason(reason);
	};

	useEffect(() => {
		if (cancellationReason?.hasNotes) setNextStep(CancellationFlowIndex.customReason);
		else setNextStep(CancellationFlowIndex.confirmCancellation);
	}, [cancellationReason, setCancellationReason]);

	return (
		<CancellationModalLayout
			canProceed={Boolean(cancellationReason)}
			nextStep={nextStep}
			modalHeader={'Why are you cancelling? (Be honest, we can take it)'}
		>
			{cancellationReasonsStore?.response?.cancellationReasons?.map(reason => (
				<Margin $marginBottom={'small'}>
					<RadioInput
						key={reason.type}
						name={'cancellation-reason'}
						checked={reason.type === cancellationReason?.type}
						value={reason.description}
						onChange={() => updateReason(reason)}
					/>
				</Margin>
			))}
		</CancellationModalLayout>
	);
};

export default CancellationReason;
