import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { trackEvent } from '../../analytics/store/actions';
import { generateReferralLink } from '../../referral/store/actions';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import ErrorBanner from '~website/features/referral/atoms/ErrorBanner';
import useCreateAccount from '~website/hooks/use-create-account';
import useEmailAttached from '~website/hooks/use-email-attached';

const ReferralForm: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const isEmailAttached = useEmailAttached();
	const [inputValue, setInputValue] = useState<string>();
	const [copied, setCopied] = useState(false);
	const link = useSelector(s => s.internal.referral.generateReferralLink);

	function onSuccess() {
		dispatch(generateReferralLink.request());
	}

	const createAccountResponse = useCreateAccount({ email: inputValue, onSuccess });
	const { loading, onClick: createAccount, error } = createAccountResponse;

	const getShareableLink = () => {
		if (link?.response?.referralLink) {
			navigator.clipboard.writeText(link.response.referralLink);
			setCopied(true);
			dispatch(trackEvent({ eventName: 'refer_a_friend_link_copied' }));

			return;
		}

		if (!isEmailAttached) {
			createAccount();

			return;
		}

		dispatch(generateReferralLink.request());
		dispatch(trackEvent({ eventName: 'refer_a_friend_cta_action' }));
	};

	const getButtonText = () => {
		if (link?.response?.referralLink && !copied) return 'Copy link';
		if (link?.response?.referralLink && copied) return 'Copied';

		return 'Get my shareable link';
	};

	const goToSignIn = () => {
		history.push(createAwareUrl('/signin'),
			{ redirectUri: createAwareUrl(location.pathname) });
	};

	useEffect(() => {
		if (link?.response?.referralLink) {
			setInputValue(link.response.referralLink);
			dispatch(trackEvent({ eventName: 'refer_a_friend_link_generated' }));
		}
	}, [dispatch, link]);

	if (isEmailAttached === void 0) return null;

	return (
		<Margin $marginTop={'extraLarge'}>
			{(!isEmailAttached || link?.response?.referralLink) && (
				<InputDecorator error={Boolean(error.error)}>
					<TextInput
						value={inputValue}
						onChange={e => setInputValue(e.target.value)}
						type={'email'}
						placeholder={'Enter your email'}
						onFocus={() => (
							dispatch(trackEvent({ eventName: 'refer_a_friend_input_action' }))
						)}
						onKeyDown={e => {
							if (e.key === 'Enter')
								getShareableLink();
						}}
					/>
					{Boolean(error.error) && (
						<ErrorBanner onButtonClick={goToSignIn} />
					)}
					<Margin $marginBottom={'small'} />
				</InputDecorator>
			)}
			<Button
				$type={'primary'}
				$size={'large'}
				$stretch
				onClick={getShareableLink}
				$loading={loading || link?.fetching}
			>
				{getButtonText()}
			</Button>
			{!isEmailAttached && (
				<SignInHero>
					<Typography
						$display={'inline'}
						$type={'Body.XSmall'}
						$color={'textOnFillMuted'}
						$marginRight={'small'}
					>
						{'Already have an account?'}
					</Typography>
					<Typography
						$display={'inline'}
						$type={'Label.XSmall'}
						$color={'textOnAction'}
						onClick={goToSignIn}
					>
						{'Sign in'}
					</Typography>
				</SignInHero>
			)}
		</Margin>
	);
};

const SignInHero = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	text-align: center;

	& > div:nth-child(2) {
		cursor: pointer;
	}
`;

export default ReferralForm;
