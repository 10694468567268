import { sentenceCase } from 'change-case';

import { DesignSystemAsset } from '~lib/frontend/types/content';

export const generateAssetAltDescription = (asset: DesignSystemAsset) => {
	if (!asset)
		return 'unknown image';

	let description = asset.value;

	if (description.indexOf('.') > -1) {
		description = description.split('.')
			.slice(0, -1)
			.join('.');
	}

	description = sentenceCase(description).toLocaleLowerCase();

	return `${asset.type} ${description}`;
};
