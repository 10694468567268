import { useSelector } from 'react-redux';

import { ApplicationState } from '~website/store';

function useEmailAttached(): boolean | void {
	const userId: string = useSelector((state: ApplicationState) => state.internal.auth.user.response);
	const userById = useSelector((state: ApplicationState) => state.platform.auth.userById[`${userId}-false`]);
	const isEmailAttached = userById?.response?.identifiers.some(user => user.attachedAt);

	return isEmailAttached;
}

export default useEmailAttached;
