import React from 'react';
import { Redirect, Route } from 'react-router';

import useUser from '../../hooks/use-user';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import SpinnerCover from '~website/features/quote/components/quote-page/molecules/SpinnerCover';

interface AuthenticatedRouteProps {
	path: string;
	exact?: boolean;
	component: React.FCWithChildren;
}

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
	const { component: Component, path, exact = true } = props;

	const { namedUser, userLoading } = useUser();

	if (userLoading)
		return <SpinnerCover />;

	if (namedUser) {
		return (
			<Route
				sensitive
				path={path}
				exact={exact}
			>
				<Component />
			</Route>
		);
	}

	return <Redirect to={createAwareUrl('/signin')} />;
};

export default AuthenticatedRoute;
