import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import MixpanelClient from '~lib/frontend/api/mixpanel-client';
import { UserConfigActionTypes } from '~lib/platform/user-config/store/types';
import { UserConfigPayload } from '~lib/platform/user-config/types';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

export default function* watcherRegisterMixpanel() {
	yield takeEvery(UserConfigActionTypes.GET_CONFIG_BY_ID_SUCCESS, workerRegisterMixpanel);
}

function* workerRegisterMixpanel({ payload }: PayloadAction<string, UserConfigPayload>) {
	const mixpanel: MixpanelClient = yield getContext('mixpanelClient');

	const userState: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

	mixpanel.setUserId(userState?.response);

	yield call([mixpanel, mixpanel.setUserConfig], payload);
}
