import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ksuid from '@cuvva/ksuid';

import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { sendAuthenticationCode } from '~lib/platform/auth/store/actions';
import { trackEvent } from '~website/features/analytics/store/actions';
import useQuery from '~website/hooks/use-query';

interface EmailAlreadyExistsModalProps {
	open: boolean;
	emailAddress: string;
	onClose: () => void;
}

const generateRequestId = () => ksuid.generate('request').toString();

const EmailAlreadyExistsModal: React.FCWithChildren<EmailAlreadyExistsModalProps> = props => {
	const { open, emailAddress, onClose } = props;

	const dispatch = useDispatch();
	const history = useHistory();
	const [requestId, setRequestId] = useState(generateRequestId);
	const redirectUri = createAwareUrl('/vehicles');

	const query = useQuery();
	const build = query.get('build');

	const authCode = useSelector(s => s.platform.auth.sendAuthenticationCode[requestId]);
	const error = authCode?.error;
	const fetching = authCode?.fetching;
	const done = authCode?.response === null;

	const doSignIn = () => {
		const requestId = generateRequestId();

		setRequestId(requestId);

		dispatch(sendAuthenticationCode.request({
			requestId,
			emailAddress,
			env: window.cuvva.client.env,
			build,
			redirectUri,
		}));
	};

	useEffect(() => {
		if (!open) return;
		dispatch(
			trackEvent({
				eventName: 'error',
				eventPayload: {
					code: 'email_already_exists',
				},
			}),
		);
	}, [dispatch, open]);

	useEffect(() => {
		if (done !== true)
			return;

		history.push(createAwareUrl('/quote/check-your-inbox'), { emailAddress });
	}, [emailAddress, done, history]);

	return (
		<Alert.Dialog open={open} title={'It looks like you already have an account'} onClose={onClose} centered>
			<Alert.Content>
				{`We already have an account linked to ${emailAddress}`}
				<br />
				{'You’ll need to log in to continue your quote, we’ll send you a magic link.'}

				{error && (
					<Banner.Wrapper icon={'ic_alert'} type={'error'} muted>
						{'There was a problem sending the magic link, please try again'}
					</Banner.Wrapper>
				)}
			</Alert.Content>
			<Alert.Actions>
				<Button
					$type={'primary'}
					onClick={doSignIn}
					$loading={fetching}
				>
					{'Log in with email'}
				</Button>
				<Button $type={'neutralMuted'} onClick={onClose}>{'Close'}</Button>
			</Alert.Actions>
		</Alert.Dialog>
	);
};

export default EmailAlreadyExistsModal;
