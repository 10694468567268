import React, { useEffect } from 'react';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import { Location } from '~lib/platform/ltm/types';
import useCurrentLocation from '~website/features/quote/hooks/use-current-location';
import useReversiblePostcodeLookup from '~website/features/quote/hooks/use-reversible-postcode-lookup';

export interface LocationUpdate extends Location {
	postcode: string;
}

interface LocateMeProps {
	onLocationChange: (update: LocationUpdate) => void;
}

const LocateMe: React.FCWithChildren<LocateMeProps> = ({ onLocationChange }) => {
	const {
		onLookupCoords,

		reverseGeocodingResult,
		reverseGeocodingResultStatus,
	} = useReversiblePostcodeLookup();
	const { doSearch, result, error, fetching } = useCurrentLocation();
	const browserSupportsIt = Boolean(window?.navigator?.geolocation);

	useEffect(() => {
		if (!result)
			return;

		onLookupCoords(result);
	}, [onLookupCoords, result]);

	useEffect(() => {
		if (reverseGeocodingResultStatus === 'error') {
			onLocationChange(void 0);

			return;
		}

		if (reverseGeocodingResultStatus !== 'response')
			return;

		onLocationChange({
			...result,
			postcode: reverseGeocodingResult.response.postcode,
		});
	}, [result, onLocationChange, reverseGeocodingResultStatus, reverseGeocodingResult]);

	if (!browserSupportsIt)
		return null;

	return (
		<Margin $marginTop={'small'}>
			<Button
				$type={'text'}
				$leadingIcon={'ic_location'}
				$size={'medium'}
				disabled={Boolean(fetching || error || reverseGeocodingResultStatus === 'error')}
				$loading={fetching}
				$stretch={false}
				onClick={e => {
					e.currentTarget.blur();
					doSearch();
				}}
			>
				{'Use current location'}
			</Button>

			{reverseGeocodingResultStatus === 'error' && (
				<Margin $marginTop={'regular'}>
					<Banner.Wrapper type={'neutral'} icon={'ic_exclamation_circle'} muted>
						{'We cannot find a postcode. Are you located in the UK?'}
					</Banner.Wrapper>
				</Margin>
			)}

			{error && (
				<Margin $marginTop={'regular'}>
					<Banner.Wrapper type={'error'} icon={'ic_exclamation_circle'} muted>
						{'We cannot locate you, please try manually with a postcode.'}
					</Banner.Wrapper>
				</Margin>
			)}
		</Margin>
	);
};

export default LocateMe;
