import { createContext, useContext } from 'react';

import { FlowDialogResponse } from '~lib/frontend/design-system/components/dialog/hooks/use-flow-dialog';
import { StatedReason } from '~lib/platform/ltm-cancellation/types';

interface CancellationComponentProps {
	flow: FlowDialogResponse;
	closeModal: () => void;
	cancellationReason: StatedReason;
	setCancellationReason: (reason: StatedReason) => void;
}

export const CancellationComponentFlowContext = createContext<CancellationComponentProps | null>(null);

export const useCancellationFlow = () => {
	const context = useContext(CancellationComponentFlowContext);

	if (!context)
		throw new Error('useCancellationFlow must be used within CancellationComponentFlowContext.Provider');

	return context;
};
