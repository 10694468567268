import { call, getContext, put, select } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import IterableSDK, { CustomEvent } from '~lib/frontend/api/iterable-sdk';
import { UserConfigPayload } from '~lib/platform/user-config/types';
import { getLogger } from '~lib/shared/logger';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

function* sendToIterable(event: CustomEvent) {
	const userConfig: AsyncState<UserConfigPayload> = yield select(
		(state: ApplicationState) => state.platform.userConfig.getConfigById[event.userId],
	);

	const flags = userConfig?.response?.flags;

	if (!flags?.includes('web_iterable_client')) return;

	const client: IterableSDK = yield getContext('iterableClient');

	try {
		yield call([client, client.trackEvent], event);
	} catch (error) {
		getLogger().warning('iterable event failed', { error });
	}

	yield put(
		analyticsTrackingDebug({
			destination: 'iterable',
			payload: {
				event: 'screen_viewed',
				payload: event,
			},
		}),
	);
}

export default sendToIterable;
