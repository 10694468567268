import CuvvaClient from '@cuvva/cuvva-client';

import { SetRenewalDateRequest, SetRenewalDateResponse } from './types';

export const setRenewalDate = (client: CuvvaClient, req: SetRenewalDateRequest) =>
	client.rpc<SetRenewalDateResponse>(
		'service-ltm-renewal-reminder',
		'1/latest/set_renewal_date',
		req,
	);
