import React from 'react';
import styled from 'styled-components';

import useContentAsset from '~lib/frontend/hooks/use-content-asset';

const SmartPricingCarousel = () => {
	const image1 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2a.svg');
	const image2 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2b.svg');
	const image3 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2c.svg');
	const image4 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2d.svg');
	const image5 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2e.svg');
	const image6 = useContentAsset('website_core', 'quote/smart-pricing-carousel/smart-pricing-step-2f.svg');

	const images = [image1, image2, image3, image4, image5, image6];

	return (
		<SmartPricingCarouselContainer>
			<ScrollContainer>
				{images.map((image, index) => (
					<div key={image}>
						<picture>
							<img src={image} alt={`smart-pricing-carousel-${index + 1}`} />
						</picture>
					</div>
				))}
			</ScrollContainer>
		</SmartPricingCarouselContainer>
	);
};

const SmartPricingCarouselContainer = styled.div`
	margin-top: ${p => p.theme.spacing.regular};
`;

const ScrollContainer = styled.div`
	--size: 343px;
	--gap: ${p => p.theme.spacing.large};

	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: var(--size);
	gap: calc(var(--gap) / 2);
	padding-block: var(--gap);
	padding-inline: var(--gap);

	height: 100%;
	padding: 24px ${p => p.theme.spacing.large} 0;

	@media (min-width: 768px) {
		padding: 24px 24px 0;
	}

	overflow-x: auto;
	overscroll-behavior-x: contain;
	scroll-snap-type: x mandatory;
	scroll-padding-inline: var(--gap);
	scroll-snap-points-x: repeat(var(--size * --gap));

	::-webkit-scrollbar {
		display: none;
	}

	div {
		scroll-snap-align: start;

		display: grid;
		margin: 0;

		position: relative;
		cursor: pointer;
		user-select: none;

		&:first-child::before {
			content: '';
			position: absolute;
			width: var(--gap);
			height: 100%;
			left: calc(var(--gap) * -1);
			inline-size: var(--gap);
			block-size: 100%;
			inset-block-end: 0;
			inset-start: calc(var(--gap) * -1);
		}

		&:last-child::after {
			content: '';
			position: absolute;
			width: var(--gap);
			height: 100%;
			right: calc(var(--gap) * -1);
			inline-size: var(--gap);
			block-size: 100%;
			inset-block-start: 0;
			inset-end: calc(var(--gap) * -1);
		}
	}

	picture {
		width: 100%;
		height: 257px;

		display: inline-block;
		inline-size: var(--size);
		border-radius: ${e => e.theme.borderRadius.regular};
		overflow: hidden;
	}

	img {
		display: block;
		height: auto;
		inline-size: 100%;
		block-size: 100%;
		object-fit: cover;
	}
`;

export default SmartPricingCarousel;
