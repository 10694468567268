import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import getCustomCodes from '../features/referral/helpers/getCustomCodes';
import { attachIdentifier } from '~lib/platform/auth/store/actions';
import { errorCodes } from '~lib/platform/code-mapping/store/actions';
import { marketingConsentActions } from '~lib/platform/marketing-consent/store/actions';
import { redeemCode } from '~lib/platform/promo/store/actions';
import { trackEvent, trackOwnersEvent } from '~website/features/analytics/store/actions';
import { renderFriendlyError } from '~website/helpers/errors';

interface Profile {
	personalName: string;
	preferredName: string;
}

interface SharedDetails {
	emailAddress: string;
	referralCode: string;
}

interface CreateAccountProps {
	email: string;
	onSuccess?: () => void;
	onClickButton?: () => void;
	profile?: Profile;
	sharedDetails?: SharedDetails;
	referralCode?: string;
	enableDiscount?: boolean;
	marketingConsent?: boolean;
}

interface CreateAccountHook {
	onClick: () => void;
	loading: boolean;
	error: {
		error: Cher | undefined;
		errorMessage: React.ReactNode;
	};
	duplicateAccount: boolean;
}

function useCreateAccount(props: CreateAccountProps): CreateAccountHook {
	const { email, onSuccess, profile, sharedDetails, enableDiscount, marketingConsent } = props;
	const dispatch = useDispatch();
	const userId: string = useSelector(s => s.internal.auth.user.response);
	const identifier = useSelector(s => s.platform.auth.attachIdentifier[`${userId}-${email}`]);
	const redeemCodeIden = [userId, sharedDetails?.referralCode].join();
	const redeemCodeState = useSelector(s => s.platform.promo.redeemCode[redeemCodeIden]);
	const codeMappingErrors = useSelector(s => s.platform.codeMapping.errorCodes.response) || [];
	const errorCodesStore = getCustomCodes(profile?.preferredName, sharedDetails?.referralCode)
		.concat(codeMappingErrors);

	const error = identifier?.error || redeemCodeState?.error;
	const errorMessage = error ? renderFriendlyError(error, errorCodesStore) : void 0;
	const emailAlreadyExists = identifier?.error?.message === 'email_already_exists';

	useEffect(() => {
		if (identifier?.response === null && !redeemCodeState?.error && !redeemCodeState?.fetching) {
			const successEvent = 'web_account_created';

			dispatch(trackEvent({
				eventName: successEvent,
				intercomEvent: successEvent,
			}));

			if (onSuccess) onSuccess();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifier, redeemCodeState]);

	useEffect(() => {
		dispatch(errorCodes.request(void 0));
	}, [dispatch]);

	useEffect(() => {
		if (error) {
			dispatch(trackOwnersEvent({
				eventName: 'stm_referred_lender_owner_error_creating_account',
			}));
		}
	}, [dispatch, error]);

	const onClick = () => {
		dispatch(
			attachIdentifier.request({
				userId,
				type: 'email',
				value: email,
				token: null,
			}),
		);

		if (enableDiscount) dispatch(redeemCode.request({ userId, code: sharedDetails?.referralCode }));
		if (marketingConsent) dispatch(marketingConsentActions.userStatus.request({ userId }));
	};

	return {
		onClick,
		loading: identifier?.fetching,
		error: {
			error,
			errorMessage,
		},
		duplicateAccount: emailAlreadyExists,
	};
}

export default useCreateAccount;
