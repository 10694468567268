import { createAsyncActionTypes } from '../actions/types';
export default function createAsyncReducer(actionType, initialState) {
    const { request, success, failure, reset } = createAsyncActionTypes(actionType);
    return function asyncReducer(state = initialState, { type, payload }) {
        switch (type) {
            case request:
                return {
                    ...state,
                    fetching: true,
                };
            case success:
                return {
                    fetching: false,
                    response: payload,
                };
            case failure:
                return {
                    fetching: false,
                    error: payload,
                };
            case reset:
                return {
                    fetching: false,
                    error: void 0,
                    response: void 0,
                };
            default:
                return state;
        }
    };
}
