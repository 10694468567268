import Cher from '@cuvva/cher';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, take } from 'redux-saga/effects';

import { clientCode } from '..';
import { InternalQuoteState } from '../types';
import { createClientCode } from '~lib/platform/auth/store/actions';
import { CreateClientCodeResponse, HandoffContext } from '~lib/platform/auth/store/types';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import { getCuvvaId } from '~lib/shared/storage';
import { ApplicationState } from '~website/store';
import { GetUserActionTypes } from '~website/store/auth/types';

export default createTakeEverySagaSet(clientCode.actions.request, function* clientCodeSaga() {
	const clientId = window?.cuvva.client.clientId;
	const userState: AsyncState<string> = yield select(
		(state: ApplicationState) => state.internal.auth.user,
	);
	const context: HandoffContext = yield getHandoffContext();

	let userId;

	if (!userState || userState.fetching) {
		const userSaga: PayloadAction<string, string> =	yield take(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS);

		userId = userSaga.payload;
	} else {
		userId = userState.response;
	}

	try {
		// Can't do much else in here:
		if (!userId)
			throw new Cher('unknown');

		const clientCodeRes: CreateClientCodeResponse = yield requestAndTake(
			createClientCode.request({
				clientId,
				userId,
				scope: 'self:official_app',
				device: {
					adId: null,
					cuvId: getCuvvaId(),
					platform: 'web',
				},
				handoff: context,
			}),
		);

		yield put(clientCode.actions.success(clientCodeRes));
	} catch (error) {
		yield put(clientCode.actions.failure(Cher.coerce(error)));
	}
});

function* getHandoffContext() {
	const qState: InternalQuoteState = yield select((state: ApplicationState) => state.internal.quote);

	switch (true) {
		case Boolean(qState.state.product === 'stm' && qState.estimate?.stm?.response?.id):
			return { code: 'stm_estimate_recovery', identifier: qState.estimate.stm.response.id };

		case Boolean(qState.state.product === 'ltm' && qState.estimate?.ltm?.response?.id):
			return { code: 'ltm_pricing_recovery', identifier: qState.estimate.ltm.response.id };

		default:
			return null;
	}
}
