import React from 'react';
import styled from 'styled-components';

import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import { ShareQuoteLiveLocationResponse } from '~lib/platform/social-driving/types';

interface LocationAvailableProps {
	name: string;
	lastUpdate: string;
	location: ShareQuoteLiveLocationResponse['currentLocation'];
}

const LiveLocation: React.FCWithChildren<LocationAvailableProps> = props => {
	const { name, lastUpdate, location } = props;

	const asset = useContentAsset('website_core', 'quote/small-map.png');

	return (
		<LocationWrapper>
			<LocationMapWrapper>
				<img src={asset} alt={'Location map'} />

				<LocationContentWrapper>
					<OwnerNameWrapper>
						<Badge type={'primary'} size={'small'}>
							{name}
						</Badge>
					</OwnerNameWrapper>

					<LocationPulse />

					<LocationCard>
						<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
							{location.friendlyName}
						</Typography>

						<Typography
							$type={'Body.Small'}
							$color={'textOnSurfaceBackgroundMuted'}
							$marginTop={'extraSmall'}
						>
							{'London, N1'}
						</Typography>

						<Typography $type={'Body.Small'} $color={'textMinor'} $marginTop={'regular'} $bold>
							{`Updated ${lastUpdate}`}
						</Typography>
					</LocationCard>
				</LocationContentWrapper>
			</LocationMapWrapper>
		</LocationWrapper>
	);
};

export default LiveLocation;

export const LocationDuration = styled.div`
	background-color: ${p => p.theme.ui.surfaceFill};
	border-radius: ${p => p.theme.borderRadius.regular};
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${p => p.theme.spacing.large};
	margin-top: ${p => p.theme.spacing.large};
`;

const LocationWrapper = styled(LocationDuration)`
	padding: 0;
`;

const LocationMapWrapper = styled.div`
	position: relative;
	border-radius: ${p => p.theme.borderRadius.regular};

	img {
		max-width: 100%;
		height: 100%;
		border-radius: ${p => p.theme.borderRadius.regular};
	}
`;

const LocationContentWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const OwnerNameWrapper = styled.div`
	position: absolute;
	left: 20px;
	top: 45px;
`;

const LocationPulse = styled.div`
	position: absolute;
	top: 65px;
	left: 20px;

	background: ${p => p.theme.ui.primaryFill};
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(101, 68, 226, 1);
	transform: scale(1);
	animation: pulse 2s infinite;

	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(101, 68, 226, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const LocationCard = styled.div`
	position: absolute;
	background-color: ${p => p.theme.ui.surfaceFill};
	border-radius: ${p => p.theme.borderRadius.regular};
	text-align: left;
	right: 12px;
	top: 12px;

	margin-bottom: 0;
	padding: ${p => p.theme.spacing.regular};

	&&&& {
		text-align: left;
	}
`;
