import styled from 'styled-components';

const Counter3ConfettiSection = styled.div`
	margin: 0 auto;
	max-width: 700px;
	text-align: center;

	> * {
		text-align: center;
	}

	> *  + * {
		margin-top: 50px;
	}
`;

export default Counter3ConfettiSection;
