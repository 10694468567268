import styled from 'styled-components';
export const CardHeaderGroupActionWrapper = styled.div `
	flex: 1;
	padding-right: 0;
	display: flex;
	justify-content: flex-end;
	flex-wrap: nowrap;
	align-items: center;
	width: 100%;
	gap: ${p => p.theme.spacing.regular};
`;
