import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { taxStatus } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(taxStatus.request, function* workerVehicleTaxStatus({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.getTaxStatus, client, payload);
        yield put(taxStatus.success(ident, response));
    }
    catch (error) {
        yield put(taxStatus.failure(ident, error));
    }
});
