import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/browser';
import { ButtonWrapper, WrappedButton } from './SimpleErrorBoundary';
import Button from '~lib/frontend/design-system/components/button/Button';
import Card, { CardBody } from '~lib/frontend/design-system/components/card/Card';
import Typography from '~lib/frontend/design-system/components/Typography';
// eslint-disable-next-line no-process-env
const isProduction = process.env.NODE_ENV === 'production';
const DetailedErrorBoundary = props => {
    const [eventId, setEventId] = useState(void 0);
    const { reset, handleReset, message, children } = props;
    return (React.createElement(ErrorBoundary, { resetKeys: [reset], onReset: reset ? handleReset : () => null, fallbackRender: ({ error, resetErrorBoundary }) => (React.createElement(ErrorFallback, { error: error, message: message, eventId: eventId, resetErrorBoundary: resetErrorBoundary })), onError: (error, info) => {
            if (!isProduction)
                return;
            datadogLogs?.logger?.error(message ?? 'DetailedErrorBoundary: error', { error, info });
            Sentry?.withScope(scope => {
                scope.setExtras(info);
                const eventId = Sentry.captureException(error);
                setEventId(eventId);
            });
        } }, children));
};
const ErrorFallback = props => {
    const { error, resetErrorBoundary, message, eventId } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { title: message || 'Unknown error' }),
        React.createElement(Card, { error: true },
            React.createElement(CardBody, null,
                React.createElement(Typography, { "$type": 'Heading.Medium', "$color": 'textOnSurfaceBackground' }, 'Whoops! Something went wrong 🙈'),
                React.createElement(Typography, { "$type": 'Heading.Small' },
                    React.createElement("pre", null, message || error.message)),
                React.createElement(Typography, { "$type": 'Body.Large' },
                    'The error has been reported to us. You can use the error event id ',
                    React.createElement("code", null, eventId),
                    ' to ask how it\'s going in the ',
                    React.createElement("code", null, '#cuvva-dash-feedback'),
                    " ",
                    'channel in Slack.'),
                React.createElement("div", null,
                    React.createElement("details", null,
                        React.createElement(Typography, { "$type": 'Body.Small' },
                            React.createElement("summary", null, 'Click for error details '),
                            error?.stack && error?.stack.toString()))),
                React.createElement(ButtonWrapper, null,
                    React.createElement(WrappedButton, null,
                        React.createElement(Button, { "$display": 'inline-flex', onClick: () => {
                                if (eventId)
                                    navigator.clipboard.writeText(eventId);
                            } }, 'Copy event id')),
                    React.createElement(Button, { "$type": 'neutralMuted', "$display": 'inline-flex', onClick: resetErrorBoundary }, 'Try again'))))));
};
export default DetailedErrorBoundary;
