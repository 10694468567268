import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import { QuoteLocation } from '../store/types';
import { HookReturn } from '../types';
import validCoordinatesValidator from '../validators/valid-coordinates-validator';
import useFriendlyError from './use-friendly-error';

const field = 'quoteLocation';

function useQuoteLocation(): HookReturn<QuoteLocation | null> {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const value = useSelector(s => s.internal.quote.state[field]);
	const page = useSelector(s => s.internal.quote.pages[pageId]);

	const onChange = useCallback((location: QuoteLocation | null) => {
		dispatch(stepActions.setQuoteLocation(location));
	}, [dispatch]);

	const setFieldError = useCallback((error: Cher) => dispatch(stepActions.setFieldError({
		field,
		pageId,
		error,
	})), [dispatch, pageId]);

	useEffect(() => {
		dispatch(pageActions.registerField({
			field,
			pageId,
		}));
	}, [dispatch, pageId]);

	useEffect(() => {
		const error = validCoordinatesValidator(value);

		dispatch(stepActions.setFieldError({
			field,
			pageId,
			error,
		}));
	}, [value, pageId, dispatch]);

	return {
		onChange,
		value,
		setFieldError,
		validation: useFriendlyError(page?.fields?.[field]?.error, 'stm', field),
	};
}

export default useQuoteLocation;
