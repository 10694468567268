import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { clientCode } from '../../store';
import { PanelArea } from '../atoms/PanelArea';
import DownloadAppBase from '../molecules/DownloadAppBase';
import DownloadSteps from '../molecules/DownloadSteps';
import { Selectable } from '~lib/frontend/atoms';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import TrustSegment from '~website/components/atoms/TrustSegment';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';

const DownloadApp: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const handoff = useSelector(s => s.internal.quote.handoff);
	const codeState = useAsyncStateStatus(handoff).status;
	const [copied, setCopied] = useState(false);
	const [cachedCode, setCachedCode] = useState<string>(void 0);
	const code = handoff?.response?.clientCode;

	useEffect(() => {
		dispatch(clientCode.actions.request());
	}, [dispatch]);

	useEffect(() => {
		if (codeState === 'response')
			setCachedCode(code);
		if (codeState === 'error')
			setCachedCode(void 0);
	}, [code, codeState]);

	useEffect(() => {
		if (!copied)
			return void 0;

		const timeout = setTimeout(() => {
			setCopied(false);
		}, 2000);

		return () => clearTimeout(timeout);
	}, [copied]);

	const onClickCopy = () => {
		navigator.clipboard.writeText(cachedCode);
		setCopied(true);

		dispatch(trackQuoteEvent({ action: 'copy_code_cta_selected' }));
	};

	const onNewCode = () => dispatch(clientCode.actions.request());

	return (
		<Fragment>
			<VibrantScreen>
				<VibrantScreenCard>
					<DownloadAppBase>
						<PanelArea>
							<Typography
								$type={'Body.Medium'}
								$color={'textOnSurfaceBackground'}
								$bold
							>
								{'Enter this code in the app to retrieve your quote'}
							</Typography>

							<Margin $marginTop={'large'} $marginBottom={'large'}>
								<Selectable>
									<CodeWrap>
										{cachedCode && cachedCode.split('').map((c, i) => (
											<Typography
												key={`${c}:${i}`}
												$type={'Heading.XSmall'}
												$color={'primaryAction'}
											>
												{c}
											</Typography>
										))}
									</CodeWrap>
								</Selectable>
							</Margin>

							<ActionContainer>
								<Button
									$size={'small'}
									$type={'primary'}
									$leadingIcon={copied ? 'ic_check' : 'ic_copy'}
									onClick={onClickCopy}
								>
									{copied ? 'Copied' : 'Copy code'}
								</Button>
								<Button
									$size={'small'}
									$type={'primary'}
									$leadingIcon={'ic_retry'}
									onClick={onNewCode}
									disabled={codeState !== 'response'}
								>
									{'New code'}
								</Button>
							</ActionContainer>
						</PanelArea>

						<Margin $marginBottom={'extraLarge'}>
							<DownloadSteps current={1}>
								{'Get a quote'}
								{'Download the app'}
								{'Enter code'}
								{'Buy policy'}
							</DownloadSteps>
						</Margin>

						<div onClick={() => dispatch(trackQuoteEvent({ action: 'download_cuvva_app_selected' }))}>
							<AppStoreButtonsSegment />
						</div>
					</DownloadAppBase>
				</VibrantScreenCard>

				<Margin
					$marginTop={'large'}
					$marginBottom={'large'}
					onClick={() => dispatch(trackQuoteEvent({ action: 'trustpilot_cta_selected' }))}
				>
					<DesignSystemProvider themeKey={'dark'} typographyStyle={'expressive'}>
						<TrustSegment trustProvider={'trustpilot'} />
					</DesignSystemProvider>

					<LegalDisclaimerBlock>
						<Icon
							icon={'ic_check_circle'}
							$size={'24px'}
							$color={'textOnFill'}
							$marginRight={'small'}
						/>
						<Typography $type={'Label.Small'} $color={'textOnFill'}>
							{'FCA Authorised and regulated'}
						</Typography>
					</LegalDisclaimerBlock>
				</Margin>
			</VibrantScreen>
			<Footer landingPage />
		</Fragment>
	);
};

const CodeWrap = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	background: ${p => p.theme.ui.primaryActionMuted};
	color: ${p => p.theme.ui.primaryFill};
	justify-content: center;
	align-items: center;
	padding: 12px 18px;

	&>* {
		margin-left: 10px;
	}

	&>:first-child {
		margin-left: 0;
	}

	&>:nth-child(5) {
		margin-left: 20px;
	}
`;

const ActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.regular};
`;

const LegalDisclaimerBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export default DownloadApp;
