import CuvvaClient from '@cuvva/cuvva-client';
import { Dispatch } from 'redux';
import { getContext, put, takeEvery } from 'redux-saga/effects';

import { getCareers } from '../actions';
import { listCareers } from '../api';
import { Careers, CareersActionTypes } from '../types';

const operationsDeps = ['customer operations', 'leadership', 'finance', 'operations', 'people and talent'];
const marketingDeps = ['marketing'];

export default function* getCareersSagaWatcher() {
	yield takeEvery(CareersActionTypes.GET_CAREERS, getCareersSaga);
}

async function fetchAndTransformJobs(client: CuvvaClient) {
	const response: Careers = await listCareers(client);
	const jobs = response.map(job => {
		const departmentName = job.department.trim().toLowerCase();

		if (operationsDeps.includes(departmentName)) {
			return {
				...job,
				department: 'Operations',
			};
		}

		if (marketingDeps.includes(departmentName)) {
			return {
				...job,
				department: 'Marketing',
			};
		}

		return {
			...job,
			department: job.department,
		};
	});

	return jobs;
}

function* getCareersSaga() {
	const client: CuvvaClient = yield getContext('api');

	try {
		const resp: Careers = yield fetchAndTransformJobs(client);

		yield put(getCareers.success(resp));
	} catch (error) {
		yield put(getCareers.failure(error));
	}
}

export async function listCareersFrontload(client: CuvvaClient, dispatch: Dispatch) {
	const resp = await fetchAndTransformJobs(client);

	dispatch(getCareers.success(resp));
}
