import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { getButtonType, TitleButtonType } from './TitleSegment';
import { Anchor } from '~lib/frontend/atoms';
import Markdown from '~lib/frontend/atoms/Markdown';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset, GetterType } from '~lib/frontend/types/content';
import SectionSegment from '~website/components/atoms/SectionSegment';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';

interface InlineBannerProps {
	id?: string;
	asset: DesignSystemAsset;
	title: string;
	body: string;
	showButton?: TitleButtonType | false;
}

const InlineBanner: React.FCWithChildren<InlineBannerProps> = props => {
	const { id, asset, title, body, showButton } = props;

	const isVisionary = useContext(VisionaryContext);
	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const dsAsset = useDesignSystemAsset(asset);
	const location = useLocation();

	if (showButton && showButton?.url)
		showButton.url = reapplyParamsIfExternalUrl(showButton.url, sourceTracking, location.pathname);

	return (
		<DesignSystemProvider themeKey={'light'} typographyStyle={'expressive'}>
			<Section $visionary={isVisionary} $background={void 0} $backgroundMobile={void 0} id={id}>
				<SectionSegment.Inner>
					<Wrapper>
						<DesktopImage src={dsAsset} />
						<div>
							<Typography $type={'Heading.Medium'} $bold={false} $color={'textBlack'}>
								{title}
							</Typography>
							<Typography $type={'Body.Medium'} $color={'textBlack'}>
								{body && <Markdown>{body}</Markdown>}
							</Typography>

							{showButton && (
								<React.Fragment>
									<Margin $marginTop={'large'} />
									{showButton?.text && (
										<Button
											// @ts-ignore
											href={showButton.url}
											as={showButton.url ? Anchor : void 0}
											$type={getButtonType(showButton.type, isVisionary)}
											$leadingIcon={showButton.leading_icon}
											$trailingIcon={showButton.trailing_icon}
											$variant={showButton.variant}
											onClick={showButton.url ? void 0 : showButton.action}
											$size={'medium'}
											$stretch={false}
										>
											{showButton.text}
										</Button>
									)}
								</React.Fragment>
							)}
						</div>
					</Wrapper>
				</SectionSegment.Inner>
			</Section>
		</DesignSystemProvider>
	);
};

export const contentAdapter = (get: GetterType): InlineBannerProps => {
	const asset = get<DesignSystemAsset>('asset');
	const title = get<string>('title');
	const body = get<string>('body');
	const button = get<string | false>('show_button.text', false);
	const showButton = button ? get<TitleButtonType>('show_button') : void 0;
	const id = get<string>('id');

	return {
		id,
		asset,
		title,
		body,
		showButton,
	};
};

const DesktopImage = styled.img`
	max-height: 100px;
`;

const Section = styled(SectionSegment.Section)`
	background: ${p => p.theme.ui.primaryFillMuted};

	> ${SectionSegment.Inner} {
		padding-top: 20px;
		padding-bottom: 20px;

		@media (min-width: 768px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	flex-direction: column;
	text-align: center;

	@media (min-width: 768px) {
		justify-content: center;
		gap: 30px;
		flex-direction: row;
		text-align: left;
	}
`;

export default InlineBanner;
