import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import Download from '../../Download';
import CreateAccount from './CreateAccount';
import CreateAccountLandingPage from './entry-point-create-account';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import Meta from '~website/components/Meta';

const VehicleProfileExperiments: React.FunctionComponent = () => {
	const { path } = useRouteMatch();
	const g = useContentKey('website_core');
	const get = (key: string, fallback?: string) => g(`pages.referral.${key}`, fallback);

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<Switch>
				<Route
					sensitive
					path={path}
					exact
					component={CreateAccountLandingPage}
				/>
				<Route sensitive path={`${path}/download`} component={Download} />
				<Route sensitive path={`${path}/create-account`} component={CreateAccount} />
			</Switch>
		</React.Fragment>
	);
};

export default VehicleProfileExperiments;
