import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialDrivingLicenseRegistrationState = {
    declareLicense: {},
    declareTestPass: {},
    licenseByUserId: {},
    listMockedLicenses: { ...initialAsyncState },
    removeLicense: {},
    removeTestPass: {},
    search: {},
    updateLicense: {},
    updateLicenseHint: {},
    lookupLicense: {},
};
export const DrivingLicenseRegistrationActionTypes = {
    LICENSE_BY_USER_ID: '@@platform/driving-license-registration/LICENSE_BY_USER_ID',
    UPDATE_LICENSE_HINT: '@@platform/driving-license-registration/UPDATE_LICENSE_HINT',
    DECLARE_LICENSE: '@@platform/driving-license-registration/DECLARE_LICENSE',
    DECLARE_TEST_PASS: '@@platform/driving-license-registration/DECLARE_TEST_PASS',
    REMOVE_LICENSE: '@@platform/driving-license-registration/REMOVE_LICENSE',
    REMOVE_TEST_PASS: '@@platform/driving-license-registration/REMOVE_TEST_PASS',
    UPDATE_LICENSE: '@@platform/driving-license-registration/UPDATE_LICENSE',
    LIST_MOCKED_LICENSES: '@@platform/driving-license-registration/LIST_MOCKED_LICENSES',
    SEARCH: '@@platform/driving-license-registration/SEARCH',
    LOOKUP_LICENSE: '@@platform/driving-license-registration/LOOKUP_LICENSE',
};
