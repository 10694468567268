import styled from 'styled-components';

import { Theme } from '~lib/frontend/design-system/types';

interface NewHeaderHeroWrapProps {
	$themeKey: Theme;
	$desktopBackgroundImage: string;
	$mobileBackgroundImage: string;
	$hasSubHero: boolean;
}

/* eslint-disable @typescript-eslint/indent */
const NewHeaderHeroWrap = styled.div<NewHeaderHeroWrapProps>`
	display: flex;
	align-items: center;
	background: #0f2362; // Fallback for IE

	min-height: 795px;

	@media screen and (min-width: 1024px) {
		padding: 60px 0;
	}

	@media (max-width: 767px) {
		min-height: ${p => (p.$hasSubHero ? 'unset' : '600px')};
	}

	background-image: url(${p => p.$desktopBackgroundImage});
	background-size: cover;
	background-position: right center;
	background-repeat: no-repeat;

	@media (max-width: 767px) {
		background-image: url(${p => p.$mobileBackgroundImage});
		background-position: bottom center;
		min-height: 1550px;
		max-height: 1600px;
		align-items: flex-start;
	}
`;

export default NewHeaderHeroWrap;
