import { call, getContext, put } from 'redux-saga/effects';
import * as actions from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(actions.listPromos.request, function* listPromos(action) {
    const { ident } = action.meta;
    const client = yield getContext('api');
    const { requestId, ...payload } = action.payload;
    try {
        const response = yield call(api.listPromos, client, payload);
        yield put(actions.listPromos.success(ident, response));
    }
    catch (error) {
        yield put(actions.listPromos.failure(ident, error));
    }
});
