import { call, getContext, put } from 'redux-saga/effects';
import { userById } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(userById.request, function* userByIdSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { userId } = payload;
    const { ident } = meta;
    try {
        const stripeUser = yield call(api.getUser, client, userId);
        yield put(userById.success(ident, stripeUser));
    }
    catch (error) {
        yield put(userById.failure(ident, error));
    }
});
