import React, { useContext } from 'react';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import { KeyDocumentWrapper } from '../atoms/KeyDocumentWrapper';
import { SectionWrapper } from '../atoms/ReportingToWrapper';
import HiringOfferContext from '../context';
import OfferKeyDocument from '../molecules/OfferKeyDocument';

const KeyDocuments: React.FunctionComponent = () => {
	const { employmentTerms } = useContext(HiringOfferContext);
	const termsOfEmployment = employmentTerms === 'general' ? 'key-terms-of-employment-non-cops' : 'key-terms-of-employment-cops';

	return (
		<SectionWrapper>
			<SectionHeaderText>{'Key Documents'}</SectionHeaderText>
			<KeyDocumentWrapper>
				<OfferKeyDocument title={'Terms of Employment'} fileName={`${termsOfEmployment}.pdf`} />
				<OfferKeyDocument title={'Options explainer'} fileName={'options-explainer-2021-ft-hires.pdf'} />
			</KeyDocumentWrapper>
		</SectionWrapper>
	);
};

export default KeyDocuments;
