import * as Sentry from '@sentry/browser';
import { takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import { GetUserActionTypes } from '../types';

export default function* watcherRegisterSentry() {
	yield takeEvery(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS, workerRegisterSentry);
}

// eslint-disable-next-line require-yield
function* workerRegisterSentry({ payload: userId }: PayloadAction<string, string>) {
	if (userId)
		Sentry.setUser({ userId });
}
