import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialContentState = {
    documentBase: { ...initialAsyncState },
    pages: { ...initialAsyncState },
    segments: {},
};
export const ContentActionTypes = {
    GET_DOCUMENT_BASE: '@@platform/content/GET_DOCUMENT_BASE',
    GET_PAGES: '@@platform/content/GET_PAGES',
    GET_SEGMENT: '@@platform/content/GET_SEGMENT',
};
