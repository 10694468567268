import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import useLockedDetails from '../hooks/use-locked-details';
import Form from './atoms/Form';
import AddressRadio from './binded-fields/AddressRadio';
import DLN from './binded-fields/DLN';
import NCB from './binded-fields/NCB';
import Postcode from './binded-fields/Postcode';
import LockedOutCallout from './molecules/LockedOutCallout';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import { Anchor } from '~lib/frontend/atoms';
import Typography from '~lib/frontend/design-system/components/Typography';

const License: React.FunctionComponent = () => {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const quoteState = useSelector(s => s.internal.quote);
	const product = quoteState.state.product;

	const locked = useLockedDetails();
	const formTracking = useFormEventTracking();

	return (
		<Form {...formTracking}>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'Your driving licence and address'}
				</Typography>
				<Typography $type={'Body.Medium'} $marginTop={'small'}>
					{'Make sure your details match your DVLA driving licence.'}
				</Typography>

				{locked && <LockedOutCallout />}
				<PageError page={page} />
			</div>

			<DLN />
			{product === 'ltm' && <NCB />}
			<Postcode />

			<AddressRadio />

			<div>
				<ProceedButton path={'/quote/your-incidents'}>
					{'Continue'}
				</ProceedButton>

				<SmallCopy $type={'Body.Small'} $color={'textMinor'} $marginTop={'regular'}>
					{'By continuing you agree with Cuvva carrying out searches using your data, and sharing it with relevant parties including the DVLA. '}
					<Anchor to={'https://www.cuvva.com/support/cuvvas-terms-conditions'}>{'Get more info.'}</Anchor>
				</SmallCopy>
			</div>
		</Form>
	);
};

const SmallCopy = styled(Typography)`
	a, a:hover, a:visited {
		color: ${p => p.theme.ui.textHighlight};
		text-decoration: none;
	}
`;

export default License;
