import CuvvaClient, { Auth } from '@cuvva/cuvva-client';

interface RegisterUserRequestPayload {
	clientId: string;
	grants: unknown[];
}

export async function registerUser(client: CuvvaClient): Promise<Auth> {
	const body: RegisterUserRequestPayload = {
		clientId: window?.cuvva?.client?.clientId || 'client_000000BPG6Lks9tQoAiJYrBRSXPX6',
		grants: [],
	};

	const response = await client.rpcNoAuth<Auth>('service-auth', '2/2018-12-11/register_user', body);

	// eslint-disable-next-line no-param-reassign
	client.auth = response;

	return response;
}
