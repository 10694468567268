import React from 'react';

import SelectInput, { SelectInputProps } from '~lib/frontend/design-system/components/input/molecules/SelectInput';

interface TrackedChangeEvent<HTMLSelectElement> extends React.ChangeEvent<HTMLSelectElement> {
	trackedValue?: unknown;
}

const TrackedSelectInput = <T extends unknown = string>(props: React.PropsWithChildren<SelectInputProps<T>>) => {
	// adding actual value as a payload for event tracking
	const onChangeCapture = (e: TrackedChangeEvent<HTMLSelectElement>) => {
		const selectedValue = props.options[parseInt(e.target.value, 10)].value;

		// eslint-disable-next-line no-param-reassign
		e.trackedValue = selectedValue;
	};

	return <SelectInput {...props} onChangeCapture={onChangeCapture} />;
};

export default TrackedSelectInput;
