import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useAsyncPayable from '../../hooks/use-async-payable';
import { stepActions } from '../../store/actions';
import HorizontalRule from '../atoms/HorizontalRule';
import { IconContainer, SmartPricingCardContainer, SmartPricingCardHeading, SmartPricingContentWrapper, SmartPricingProcessList, ToggleSmartPricingContainer } from '../atoms/SmartPricingCard';
import SmartPricingDialog from './dialogs/SmartPricingDialog';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon from '~lib/frontend/design-system/components/Icon';
import SwitchInput from '~lib/frontend/design-system/components/input/molecules/SwitchInput';
import Typography from '~lib/frontend/design-system/components/Typography';

const SmartPricing = () => {
	const dispatch = useDispatch();

	const ltmEstimate = useSelector(s => s.internal.quote.estimate.ltm);
	const { fetching } = useAsyncPayable(ltmEstimate);

	const [open, setOpen] = useState(false);
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);

	const checkFirstTime = useRef<number>(void 0);
	const switchRef = useRef<HTMLInputElement>(null);

	const smartPricing = useSelector(s => s.internal.quote.state.smartPricing);
	const setSmartPricing = (smartPricing: boolean) => dispatch(stepActions.setSmartPricing(smartPricing));

	const checkModalHasNotOpened = () => {
		if (typeof checkFirstTime?.current === 'undefined') {
			onOpen();
			checkFirstTime.current = 1;

			return;
		}
	};

	const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		checkModalHasNotOpened();
		setSmartPricing(!e.target.checked);
	};

	const handleCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();

		if (e.target === switchRef.current) return;

		checkModalHasNotOpened();
		setSmartPricing(!smartPricing);
	};

	return (
		<SmartPricingCardContainer active={smartPricing} disabled={fetching}>
			<SmartPricingCardWrapper onClick={handleCard}>
				<SmartPricingCardHeading>
					<Typography
						$type={'Label.Small'}
						$color={'textOnFill'}
						$marginTop={'small'}
						$marginBottom={'small'}
						$marginLeft={'large'}
						$marginRight={'large'}
					>
						{'Get 10% off each month, just for opting in'}
					</Typography>
				</SmartPricingCardHeading>

				<SmartPricingContentWrapper>
					<ToggleSmartPricingContainer>
						<Typography $marginRight={'large'} $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
							{'Turn on Smart Pricing'}
						</Typography>

						<SwitchInput ref={switchRef} checked={smartPricing} onChange={handleSwitch} />
					</ToggleSmartPricingContainer>

					<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
						{
							'You could save up to 33% each month. No need to install a device in your car. The better you drive, the less you pay 💸'
						}
					</Typography>

					<Margin $marginTop={'large'} $marginBottom={'large'}>
						<HorizontalRule />
					</Margin>

					<SmartPricingProcessList>
						<li>
							<IconContainer>
								<Icon icon={'ic_device_iphone'} $size={'20px'} $color={'neutralFill'} />
							</IconContainer>
							<Typography $type={'Body.Small'} $bold $color={'textOnSurfaceBackground'}>
								{'Download our free Cuvva app'}
							</Typography>
						</li>
						<li>
							<IconContainer>
								<Icon icon={'ic_car'} $size={'20px'} $color={'neutralFill'} />
							</IconContainer>
							<Typography $type={'Body.Small'} $bold $color={'textOnSurfaceBackground'}>
								{'Allow us to track motion and location data on your smartphone'}
							</Typography>
						</li>
						<li>
							<IconContainer>
								<Icon icon={'ic_tax'} $size={'20px'} $color={'neutralFill'} />
							</IconContainer>
							<Typography $type={'Body.Small'} $bold $color={'textOnSurfaceBackground'}>
								{'Pay a fair price based on how you drive'}
							</Typography>
						</li>
					</SmartPricingProcessList>
				</SmartPricingContentWrapper>
			</SmartPricingCardWrapper>

			<Margin $marginTop={'large'}>
				<SmartPricingDialog open={open} onOpen={onOpen} onClose={onClose} />
			</Margin>
		</SmartPricingCardContainer>
	);
};

const SmartPricingCardWrapper = styled.div`
	cursor: pointer;
`;

export default SmartPricing;
