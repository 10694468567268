import styled from 'styled-components';

export const LegalLinks = styled.div`
	display: grid;
	justify-content: space-between;
	grid-column-gap: 24px;
	grid-row-gap: ${p => p.theme.spacing.large};
	width: 100%;
	white-space: nowrap;
	grid-template-columns: 1fr 1fr;

	& > * {
		flex: 0;
	}

	@media (min-width: 767px) {
		display: flex;
		width: fit-content;
	}
`;

export const LegalSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;

	& > :first-child {
		margin-bottom: ${p => p.theme.spacing.large};
	}

	& > :nth-child(2) {
		margin-bottom: 90px;
	}

	@media (min-width: 768px) {
		flex-direction: row;

		& > :nth-child(2) {
			white-space: nowrap;
		}
	}
`;
