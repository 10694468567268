import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { updatePricing } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherUpdatePricing() {
    yield takeEvery(LtmActionTypes.UPDATE_PRICING, workerUpdatePricing);
}
function* workerUpdatePricing({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.updatePricing, client, requestPayload);
        yield put(updatePricing.success(ident, response));
    }
    catch (error) {
        yield put(updatePricing.failure(ident, error));
    }
}
