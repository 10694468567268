import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

const DotsContainer = styled.div`
	margin: 10px auto;
	flex-direction: column;
	flex: 1;
	display: inline-flex;
	max-width: 700px;
	justify-content: flex-end;
`;

const TypographyWrap = styled.div`
	flex-direction: column;
	display: flex;
	gap: 24px;

	> * {
		flex: 1;
	}

	@media (min-width: 768px) {
		flex-direction: row;
	}
`;

const Dots = styled.ul`
	z-index: 1;
	display: none;

	li::before {
		content: '';
		position: absolute;
		top: 14px;
		left: -192px;
		width: 192px;
		height: 2px;
		background: transparent;
		border-bottom: 1px dashed ${props => props.theme.ui.borderSeparator};
		z-index: -1;
	}

	li:first-child::before {
		display: none;
	}

	@media (min-width: 768px) {
		display: block;
		margin-bottom: 24px;
	}
`;

const SingleDot = styled.li`
	width: 32px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	border-radius: 50%;

	margin: 0 96px;
	display: inline-block;
	position: relative;
	background: ${p => p.theme.ui.surfaceDark};
	color: ${p => p.theme.ui.textOnFill};
`;

const MobileOnly = styled.div`
	@media (min-width: 768px) {
		display: none;
	}
`;

interface DotsContainerProps {
	items: string[];
}

const BulletDots: React.FCWithChildren<DotsContainerProps> = ({ items }) => (
	<DotsContainer>
		<Dots>
			{items.map((item, i) => (
				<SingleDot key={`${i}:${item}`}>{i + 1}</SingleDot>
			))}
		</Dots>
		<TypographyWrap>
			{items.map((item, i) => (
				<div key={`${i}:${item}`}>
					<MobileOnly>
						<SingleDot>{i + 1}</SingleDot>
					</MobileOnly>
					<Typography
						key={item}
						$type={'Heading.XSmall'}
						$color={'textOnFill'}
						$align={'center'}
						$display={'inline-block'}
						$marginTop={'small'}
					>
						{item}
					</Typography>
				</div>
			))}
		</TypographyWrap>
	</DotsContainer>
);

export default BulletDots;
