import React from 'react';

import {
	CookiePolicyButtonArea,
	CookiePolicyContainer,
	CookiePolicyHeader,
	CookiePolicyWrapper,
} from './CookiePolicyCard';
import useCookieNotice from './hooks/use-cookie-notice';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

const CookiePolicy: React.FunctionComponent = () => {
	const { consent, onChange } = useCookieNotice();

	if (consent !== null)
		return null;

	return (
		<CookiePolicyWrapper $display={consent === null}>
			<CookiePolicyContainer>
				<div>
					<CookiePolicyHeader>
						<Icon icon={'ic_cookie'} $size={'32px'} $color={'textHighlight'} $marginRight={'regular'} />
						<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
							{'We’d love to use optional cookies'}
						</Typography>
					</CookiePolicyHeader>

					<Margin $marginTop={'small'}>
						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{'Optional cookies are used to personalise your experience and show you more relevant ads. If you want to learn more about how we use cookies, you can read our '}
							<Anchor target={'_blank'} href={'/support/cuvva-cookie-policy'}>{'cookie policy.'}</Anchor>
						</Typography>
					</Margin>
				</div>

				<CookiePolicyButtonArea>
					<Button
						type={'button'}
						$type={'neutralMuted'}
						onClick={() => onChange('relevant')}
					>
						{'Decline'}
					</Button>

					<Button
						data-testid={'accept-cookies'}
						type={'button'}
						$type={'primary'}
						onClick={() => onChange('non-relevant')}
					>
						{'Accept'}
					</Button>
				</CookiePolicyButtonArea>
			</CookiePolicyContainer>
		</CookiePolicyWrapper>
	);
};

export default CookiePolicy;
