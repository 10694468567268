import { createReducer } from '@reduxjs/toolkit';

import { AnalyticsTrackingDebugEvent } from '../../types';
import * as actions from '../actions';

const createAnalyticsTrackingDebugReducer = createReducer<AnalyticsTrackingDebugEvent[]>([], builder => {
	builder
		.addCase(actions.analyticsTrackingDebug, (state, action) => [
			...state,
			{
				createdAt: (new Date()).toISOString(),
				...action.payload,
			}]);
});

export default createAnalyticsTrackingDebugReducer;
