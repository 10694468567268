import styled from 'styled-components';

const InspirationCard = styled.div`
	padding: 24px;
	text-align: left;
	background: ${p => p.theme.ui.surface};
	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	transition: 0.1s linear;

	> img {
		width: 100%;
		max-width: 80px;
	}

	&:hover {
		transform: translateY(-2px);
	}
`;

export default InspirationCard;
