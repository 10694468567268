import CancellationReason from '../organisms/cancellations/CancellationReason';
import ConfirmCancellation from '../organisms/cancellations/ConfirmCancellation';
import CustomCancellationReason from '../organisms/cancellations/CustomCancellationReason';

export enum CancellationFlowIndex {
	cancellationReason,
	customReason,
	confirmCancellation,
}

export const CancellationFlowComponents = [
	CancellationReason,
	CustomCancellationReason,
	ConfirmCancellation,
];
