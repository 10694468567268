import { LtmActionTypes, } from './types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';
export const listEvents = createAsyncMapAction(LtmActionTypes.LIST_EVENTS, p => p.userId);
export const config = createAsyncAction(LtmActionTypes.CONFIG);
export const setDetails = createAsyncMapAction(LtmActionTypes.SET_DETAILS, p => p.requestId);
export const getSubscriptionUser = createAsyncMapAction(LtmActionTypes.GET_SUBSCRIPTION_USER, p => p.subscriptionId);
export const startPricing = createAsyncMapAction(LtmActionTypes.START_PRICING, p => p.requestId);
export const updatePricing = createAsyncMapAction(LtmActionTypes.UPDATE_PRICING, p => p.requestId);
export const createEstimate = createAsyncMapAction(LtmActionTypes.CREATE_ESTIMATE, p => p.requestId);
export const createQuote = createAsyncMapAction(LtmActionTypes.CREATE_QUOTE, p => p.requestId);
export const getProfile = createAsyncMapAction(LtmActionTypes.GET_PROFILE, p => p.userId);
export const quoteCancellation = createAsyncMapAction(LtmActionTypes.QUOTE_CANCELLATION, p => p.requestId);
export const confirmCancellation = createAsyncMapAction(LtmActionTypes.CONFIRM_CANCELLATION, p => p.cancellationRequestId);
export const getSubscriptionDetail = createAsyncMapAction(LtmActionTypes.GET_SUBSCRIPTION_DETAIL, p => p.subscriptionId);
export const quoteAutocycle = createAsyncMapAction(LtmActionTypes.QUOTE_AUTOCYCLE, p => p.requestId);
export const getNextBillingDate = createAsyncMapAction(LtmActionTypes.GET_NEXT_BILLING_DATE, p => p.subscriptionId);
export const regenerateQuote = createAsyncMapAction(LtmActionTypes.REGENERATE_QUOTE, p => p.requestId);
export const processCycle = createAsyncMapAction(LtmActionTypes.PROCESS_CYCLE, p => p.subscriptionId);
export const pushFailedPayment = createAsyncMapAction(LtmActionTypes.PUSH_FAILED_PAYMENT, p => p.subscriptionId);
export const listPoliciesBySubscription = createAsyncMapAction(LtmActionTypes.LIST_POLICIES_BY_SUBSCRIPTION, p => p.subscriptionId);
export const listSubscriptions = createAsyncMapAction(LtmActionTypes.LIST_SUBSCRIPTIONS, p => p.userId);
export const listTransactionsByPolicy = createAsyncMapAction(LtmActionTypes.LIST_TRANSACTIONS_BY_POLICY, p => p.policyId);
export const getSubscriptionByPolicy = createAsyncMapAction(LtmActionTypes.GET_SUBSCRIPTION_BY_POLICY, p => p.policyId);
export const attemptCycleGeneration = createAsyncMapAction(LtmActionTypes.ATTEMPT_CYCLE_REGENERATION, p => p.requestId);
export const createSubscription = createAsyncMapAction(LtmActionTypes.CREATE_SUBSCRIPTION, p => p.requestId);
export const getAdditionalDriver = createAsyncMapAction(LtmActionTypes.GET_ADDITIONAL_DRIVER, p => p.additionalDriverId);
