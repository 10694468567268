import styled from 'styled-components';

export const Menu = styled.ul`
	margin: 0 auto; /* safari bug m8 */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	max-width: 1280px;
	padding-top: ${p => p.theme.spacing.regular};
	padding-bottom: ${p => p.theme.spacing.regular};
	gap: ${p => p.theme.spacing.extraLarge};

	@media (min-width: 768px) {
		padding-top: 20px;
		padding-bottom: 20px;
		max-width: 1280px;
	}
`;

export const LogoItem = styled.li`
	flex: 1;
	z-index: 6;
	text-align: left;

	&, a, svg {
		display: block;
	}

	svg {
		height: 24px;

		@media (min-width: 768px) {
			height: 28px;
		}
	}
`;

export const MenuItem = styled.li`
	flex: 0;
	z-index: 6;
	position: relative;
	display: none;
	font-weight: bold;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: opacity 0.2s ease-in-out;
	white-space: nowrap;

	> a {
		text-decoration: none;
		color: inherit;

		& > div {
			display: flex;
		}
	}

	@media (min-width: 1050px) {
		display: inline-flex;
	}
`;

export const AlwaysVisibleMenuItem = styled(MenuItem)`
	display: inline-flex;

	flex: 0 1 auto;
	min-width: 0;
`;

export const CTAItem = styled.li`
	flex: 0;
	z-index: 4;
	margin-left: ${p => p.theme.spacing.regular};
	display: none;

	@media (min-width: 1050px) {
		display: block;
	}
`;
