import styled from 'styled-components';

const BoxText = styled.div`
	text-align: left;
	${p => p.theme.typography['Body.Large']()};
	color: ${props => props.theme.ui.textOnSurfaceBackground};
	margin-bottom: 100px;

	h1, h2, h3, h4, h5, h6 {
		${p => p.theme.typography['Heading.Medium']()};
		margin: 40px 0 25px 0;
	}

	table {
		border: 1px solid ${p => p.theme.ui.borderSeparator};
		border-collapse: collapse;
		max-width: 100vw;
		overflow-x: auto;
		display: block;

		th, td {
			border: 1px solid ${p => p.theme.ui.borderSeparator};
		}

		td { padding: ${p => p.theme.spacing.small}; }
		th { padding: ${p => p.theme.spacing.large}; }
	}

	a {
		&, &:visited, &:hover, &:active {
			text-decoration: underline;
			color: ${props => props.theme.ui.textOnSurfaceBackground};
		}
	}

	img {
		display: block;
		width: 100%;
		max-width: 600px;
		height: auto;
		margin: 20px auto;
	}

	ul, ol {
		display: block;
		list-style-type: disc;
		margin-left: ${p => p.theme.spacing.regular};

		li {
			margin-bottom: ${p => p.theme.spacing.small};
		}
	}
`;

export default BoxText;
