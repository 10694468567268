import React, { useContext } from 'react';
import styled from 'styled-components';

import DownloadAppSubtext from '../../atoms/DownloadAppSubtext';
import HorizontalRule from '../../atoms/HorizontalRule';
import { CardRoundedHeading, QuoteCard } from '../atoms/QuoteCard';
import { LtmQuoteContext } from '../context';
import { Anchor } from '~lib/frontend/atoms';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Button from '~lib/frontend/design-system/components/button/Button';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { CreateQuoteResponse, Pricing } from '~lib/platform/ltm/types';
import CheckList from '~website/components/CheckList';
import { containsDiscount } from '~website/features/discounts/utils/handleDiscounts';
import { monzoMoney, plainMoney } from '~website/helpers/monies';

interface QuoteCardWithHeadingProps {
	pricing: Pricing | undefined;
	nonSmartPricing: Pricing | undefined;
	discounts?: CreateQuoteResponse['eligibleDiscounts'];
}

const QuoteCardWithHeading: React.FCWithChildren<QuoteCardWithHeadingProps> = ({
	pricing, nonSmartPricing, discounts,
}) => {
	const { asyncStatus } = useContext(LtmQuoteContext);
	const isLoading = ['pending', 'fetching'].includes(asyncStatus);

	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.quote.${key}`, fallback);

	const nextRoute = useNextRoute();

	const hasDiscounts = containsDiscount(discounts, pricing);
	const {
		oldTotalPayable,
		totalPayable,
		nonSmartPricingTotalPayable,
		discount,
	} = getUiPricing(pricing, nonSmartPricing);

	const priceLoading = typeof totalPayable !== 'number';
	const title = typeof nonSmartPricingTotalPayable === 'undefined' ? 'To pay today' : 'Your smart price';

	return (
		<div>
			<CardRoundedHeading>
				<Typography $type={'Label.Small'} $color={'textOnFill'} $align={'center'}>
					{title}
				</Typography>
			</CardRoundedHeading>
			<QuoteCard $noTopRadius>
				<QuotePricingContainer>
					<div>
						{hasDiscounts && (
							<SmartPricingBadgeWrap>
								<Badge size={'small'} type={'success'}>
									{`One time discount: ${plainMoney(discount)}`}
								</Badge>
								<Typography $type={'Label.Large'} $color={'textSuccess'} $marginLeft={'regular'}>
									{plainMoney(oldTotalPayable)}
								</Typography>
							</SmartPricingBadgeWrap>
						)}
						<Typography $type={'Heading.XLarge'} $color={'textOnSurfaceBackground'} $align={'center'}>
							{priceLoading && <SkeletonLoader width={'144px'} height={'63px'} />}
							{!priceLoading && monzoMoney(totalPayable)}
						</Typography>
						<Typography $type={'Body.Small'} $color={priceLoading ? 'textOnSurfaceBackgroundMuted' : 'textOnSurfaceBackground'} $align={'center'}>
							{priceLoading && 'refreshing your price...'}
							{!priceLoading && 'per month'}
						</Typography>
					</div>
					<HorizontalRule />
					<CheckList items={get<string[]>('card_checklist', [])} />

					<div>
						<Button
							as={Anchor}
							// @ts-ignore
							to={nextRoute}
							$type={'primary'}
							$size={'large'}
							$loading={isLoading}
							disabled={isLoading}
							$stretch
						>
							{'Get insured'}
						</Button>

						{typeof nonSmartPricingTotalPayable !== 'undefined' && <DownloadAppSubtext />}
					</div>
				</QuotePricingContainer>
			</QuoteCard>
		</div>
	);
};

const useNextRoute = () => {
	const purchaseFlow = useConfigFlag('web_subscription_purchase_flow') ?? false;

	const { quote, asyncStatus } = useContext(LtmQuoteContext);
	const isSmartPricingOn = quote?.response?.summary?.policy?.smartPricing?.enabled ?? false;

	if (isSmartPricingOn)
		return '/quote/download';

	if (!purchaseFlow || asyncStatus !== 'response')
		return '/quote/download';

	return '/quote/declarations';
};

const getUiPricing = (pricing: Pricing, nonSmartPricing: Pricing) => {
	const totalPayable = pricing?.totalPayable;
	const oldTotalPayable = pricing?.discount?.original.totalPayable ?? pricing?.totalPayable;
	const nonSmartPricingTotalPayable = nonSmartPricing?.totalPayable;
	const discount = oldTotalPayable - totalPayable;

	return {
		totalPayable,
		oldTotalPayable,
		nonSmartPricingTotalPayable,
		discount,
	};
};

export const QuotePricingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const SmartPricingBadgeWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: ${p => p.theme.spacing.large};

	& > * {
		flex: 0 0 auto;
	}

	& > :last-child {
		text-decoration: line-through;
	}
`;

export default QuoteCardWithHeading;
