import styled from 'styled-components';

export const MeHeader = styled.div`
	min-height: 288px;
	background: ${p => p.theme.ui.violetGradientLeading}, ${p => p.theme.ui.secondaryBackground};

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: ${p => p.theme.spacing.small};

	padding: 0 ${p => p.theme.spacing.semiLarge};
	@media (min-width: 628px) {
		padding: 0 ${p => p.theme.spacing.extraLarge};
	}
`;

export const MeHero = styled.div`
	display: flex;
	justify-content: flex-start;

	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: ${p => p.theme.spacing['4xLarge']};
`;

export const MeSinglePolicyHero = styled(MeHero)`
	width: 100%;
	max-width: 1120px;
	margin-top: ${p => p.theme.spacing['6xLarge']};
`;
