import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollResetter: React.FunctionComponent = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return <React.Fragment></React.Fragment>;
};

export default ScrollResetter;
