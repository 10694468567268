import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../utils/formatDateTime';
import { policyBadgeType, policyTimeline } from '../utils/policyUtils';
import { FlattenPolicySubs } from './use-subscriptions';
import { getNextBillingDate } from '~lib/platform/ltm/store/actions';
import useUser from '~website/hooks/use-user';

const useSubsUtils = (subscription: FlattenPolicySubs) => {
	const dispatch = useDispatch();
	const { userId } = useUser();

	const subs = useMemo(() => subscription ? subscription : void 0, [subscription]);
	const subscriptionId = subs?.subInfo?.subscriptionId;

	const nextBillingDateStore = useSelector(s => s.platform.ltm.getNextBillingDate?.[subscriptionId]);
	const nextBillingDate = nextBillingDateStore?.response;

	const billingDate = nextBillingDate?.nextBillingDate ? formatDate(nextBillingDate?.nextBillingDate) : void 0;

	const badgeType = useMemo(() => policyBadgeType(subs), [subs]);
	const timeline = useMemo(() => policyTimeline(subs, billingDate?.date),
		[subs, billingDate],
	);

	useEffect(() => {
		if (userId && subscriptionId)
			dispatch(getNextBillingDate.request({ userId, subscriptionId }));
	}, [dispatch, subscriptionId, userId]);

	return {
		badgeType,
		timeline,
		nextBillingDate,
	};
};

export default useSubsUtils;
