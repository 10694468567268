import React from 'react';
import styled, { useTheme } from 'styled-components';

import { UIColors } from '~lib/frontend/design-system/types';

interface LighningBoltProps {
	color: keyof UIColors;
}

const LightningBolt: React.FCWithChildren<LighningBoltProps> = ({ color }) => {
	const theme = useTheme();

	return (
		<Wrapper>
			<svg
				xmlns={'http://www.w3.org/2000/svg'}
				viewBox={'0 0 34 50'}
				fill={'none'}
			>
				<path
					d={'M27.4 6.6l-.5 18.3-19.7 1L6.7 43'}
					stroke={theme.ui[color]}
					strokeWidth={'12'}
					strokeLinecap={'round'}
				/>
			</svg>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 35px;
`;

export default LightningBolt;
