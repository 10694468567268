import { createContext, useContext } from 'react';
export const EditableTypographyContext = createContext(null);
export function useCreateEditableTypographyContextValue(prefix) {
    const currentContext = useContext(EditableTypographyContext);
    if (currentContext === null)
        return null;
    const fullPrefix = prefix;
    const contextContent = {
        updateValue: (key, value) => {
            currentContext?.updateValue(`${fullPrefix}.${key}`, value);
        },
    };
    return contextContent;
}
