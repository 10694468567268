import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialLtmState = {
    attemptCycleGeneration: {},
    config: { ...initialAsyncState },
    confirmCancellation: {},
    createEstimate: {},
    createQuote: {},
    createSubscription: {},
    getNextBillingDate: {},
    getProfile: {},
    getSubscriptionDetail: {},
    getSubscriptionByPolicy: {},
    listEvents: {},
    listPoliciesBySubscription: {},
    listSubscriptions: {},
    listTransactionsByPolicy: {},
    processCycle: {},
    pushFailedPayment: {},
    quoteAutocycle: {},
    quoteCancellation: {},
    regenerateQuote: {},
    setDetails: {},
    startPricing: {},
    subscriptionUsers: {},
    updatePricing: {},
    getAdditionalDriver: {},
};
export const LtmActionTypes = {
    LIST_EVENTS: '@@platform/ltm/LIST_EVENTS',
    LIST_EVENTS_SUCCESS: '@@platform/ltm/LIST_EVENTS_SUCCESS',
    LIST_EVENTS_FAILURE: '@@platform/ltm/LIST_EVENTS_FAILURE',
    CONFIG: '@@platform/ltm/CONFIG',
    CONFIG_SUCCESS: '@@platform/ltm/CONFIG_SUCCESS',
    CONFIG_FAILURE: '@@platform/ltm/CONFIG_FAILURE',
    SET_DETAILS: '@@platform/ltm/SET_DETAILS',
    SET_DETAILS_SUCCESS: '@@platform/ltm/SET_DETAILS_SUCCESS',
    SET_DETAILS_FAILURE: '@@platform/ltm/SET_DETAILS_FAILURE',
    GET_SUBSCRIPTION_USER: '@@platform/ltm/GET_SUBSCRIPTION_USER',
    GET_SUBSCRIPTION_USER_SUCCESS: '@@platform/ltm/GET_SUBSCRIPTION_USER_SUCCESS',
    GET_SUBSCRIPTION_USER_FAILURE: '@@platform/ltm/GET_SUBSCRIPTION_USER_FAILURE',
    CREATE_ESTIMATE: '@@platform/ltm/CREATE_ESTIMATE',
    START_PRICING: '@@platform/ltm/START_PRICING',
    START_PRICING_SUCCESS: '@@platform/ltm/START_PRICING_SUCCESS',
    START_PRICING_FAILURE: '@@platform/ltm/START_PRICING_FAILURE',
    UPDATE_PRICING: '@@platform/ltm/UPDATE_PRICING',
    UPDATE_PRICING_SUCCESS: '@@platform/ltm/UPDATE_PRICING_SUCCESS',
    UPDATE_PRICING_FAILURE: '@@platform/ltm/UPDATE_PRICING_FAILURE',
    GET_PROFILE: '@@platform/ltm/GET_PROFILE',
    GET_PROFILE_SUCCESS: '@@platform/ltm/GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: '@@platform/ltm/GET_PROFILE_FAILURE',
    QUOTE_CANCELLATION: '@@platform/ltm/QUOTE_CANCELLATION',
    QUOTE_CANCELLATION_SUCCESS: '@@platform/ltm/QUOTE_CANCELLATION_SUCCESS',
    QUOTE_CANCELLATION_FAILURE: '@@platform/ltm/QUOTE_CANCELLATION_FAILURE',
    CONFIRM_CANCELLATION: '@@platform/ltm/CONFIRM_CANCELLATION',
    CONFIRM_CANCELLATION_SUCCESS: '@@platform/ltm/CONFIRM_CANCELLATION_SUCCESS',
    CONFIRM_CANCELLATION_FAILURE: '@@platform/ltm/CONFIRM_CANCELLATION_FAILURE',
    QUOTE_AUTOCYCLE: '@@platform/ltm/QUOTE_AUTOCYCLE',
    QUOTE_AUTOCYCLE_SUCCESS: '@@platform/ltm/QUOTE_AUTOCYCLE_SUCCESS',
    QUOTE_AUTOCYCLE_FAILURE: '@@platform/ltm/QUOTE_AUTOCYCLE_FAILURE',
    GET_NEXT_BILLING_DATE: '@@platform/ltm/GET_NEXT_BILLING_DATE',
    GET_NEXT_BILLING_DATE_SUCCESS: '@@platform/ltm/GET_NEXT_BILLING_DATE_SUCCESS',
    GET_NEXT_BILLING_DATE_FAILURE: '@@platform/ltm/GET_NEXT_BILLING_DATE_FAILURE',
    REGENERATE_QUOTE: '@@platform/ltm/REGENERATE_QUOTE',
    PROCESS_CYCLE: '@@platform/ltm/PROCESS_CYCLE',
    PUSH_FAILED_PAYMENT: '@@platform/ltm/PUSH_FAILED_PAYMENT',
    CREATE_QUOTE: '@@platform/ltm/CREATE_QUOTE',
    LIST_POLICIES_BY_SUBSCRIPTION: '@@platform/ltm/LIST_POLICIES_BY_SUBSCRIPTION',
    LIST_SUBSCRIPTIONS: '@@platform/ltm/LIST_SUBSCRIPTIONS',
    GET_SUBSCRIPTION_DETAIL: '@@platform/ltm/GET_SUBSCRIPTION_DETAILS',
    LIST_TRANSACTIONS_BY_POLICY: '@@platform/ltm/LIST_TRANSACTIONS_BY_POLICY',
    GET_SUBSCRIPTION_BY_POLICY: '@@platform/ltm/GET_SUBSCRIPTION_BY_POLICY',
    ATTEMPT_CYCLE_REGENERATION: '@@platform/ltm/ATTEMPT_CYCLE_REGENERATION',
    CREATE_SUBSCRIPTION: '@@platform/ltm/CREATE_SUBSCRIPTION',
    GET_ADDITIONAL_DRIVER: '@@platform/ltm/GET_ADDITIONAL_DRIVER',
};
