import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

export const ReportingToWrapper = styled.div`
	padding: ${p => p.theme.spacing.regular};
	border-radius: ${p => p.theme.borderRadius.extraLarge};
	box-shadow: ${p => p.theme.boxShadow.small};

	${p => media.greaterThan('laptop')`
		padding: ${p.theme.spacing.extraLarge};
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	`}
`;

export const SectionWrapper = styled.div`
	margin: 5rem 0;

	${media.greaterThan('laptop')`
		margin: 10rem 0;
	`}
`;

export const FlexWrapper = styled(SectionWrapper)`
	align-items: center;
	display: flex;
	flex-flow: column-reverse;
	gap: 4rem;

	> * {
		flex: 1;
		width: 100%;
	}

	${media.greaterThan('laptop')`
		flex-flow: row;
	`}
`;

export const Report = styled.div`
	padding: ${p => p.theme.spacing.extraLarge} 0;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	display: flex;
	flex-flow: column;
	gap: ${p => p.theme.spacing.small};

	:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}
	:first-child {
		padding-top: 0;
	}
`;
