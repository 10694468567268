import React from 'react';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import SignalInterestCTAs from './SignalInterestCTAs';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface LenderMilestonesProps {
	open: boolean;
	closeModal: () => void;
}

const LenderMilestones: React.FCWithChildren<LenderMilestonesProps> = ({ open, closeModal }) => {
	const ilPhoneCar = useDesignSystemAsset({
		type: 'illustration', value: 'il_phone_car02.svg' });

	const ilStopWatch = useDesignSystemAsset({
		type: 'illustration', value: 'il_stopwatch.svg' });

	const ilPension = useDesignSystemAsset({
		type: 'illustration', value: 'il_pension.svg' });

	const ilRosette = useDesignSystemAsset({
		type: 'illustration', value: 'il_rosette.svg' });

	const milestones = [ilPhoneCar, ilStopWatch, ilPension, ilRosette];
	const tableItems = [
		{ title: 'First drive', value: 'First recorded trip' },
		{ title: 'Marathon trip', value: '26.2 miles in 1 trip' },
		{ title: 'Centurion', value: 'Driven 100 miles' },
		{ title: 'Proclaimer', value: 'Driven 500 miles' },
	];

	return (
		<ComplexDialog.Dialog
			open={open}
			title={'Lender milestones'}
			onClose={closeModal}
		>
			<ComplexDialog.Content>
				<Typography
					$type={'Body.XLarge'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginTop={'regular'}
					$marginBottom={'extraLarge'}
					$align={'center'}
				>
					{'Discover more about your driving and earn badges when you complete different milestones with Cuvva'}
				</Typography>
				<MilestonesIllustrations>
					{milestones.map(m => (
						<Milestone key={m}>
							<img src={m} />
						</Milestone>
					))}
				</MilestonesIllustrations>
				<MilestonesTable>
					{tableItems.map(item => (
						<MilestonesRow key={item.title}>
							<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
								{item.title}
							</Typography>
							<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
								{item.value}
							</Typography>
						</MilestonesRow>
					))}
				</MilestonesTable>
			</ComplexDialog.Content>
			<ComplexDialog.Actions>
				<SignalInterestCTAs
					optinEvent={`${vehicleProfileEvents.ownersHub.lenderMilestonesCta}_yes`}
					optoutEvent={`${vehicleProfileEvents.ownersHub.lenderMilestonesCta}_no`}
					closeModal={closeModal}
				/>
			</ComplexDialog.Actions>
		</ComplexDialog.Dialog>
	);
};

const MilestonesIllustrations = styled.div`
	display: flex;
	justify-content: center;

	& > :not(:last-child) {
		margin-right: ${p => p.theme.spacing.small};
	}
`;
const Milestone = styled.div`
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.regular};
	display: flex;
	flex-grow: 1;
	justify-content: center;

	img {
		width: 57px;
		height: 57px;
	}
`;
const MilestonesTable = styled.div`
	margin-top: ${p => p.theme.spacing.regular};
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.large};

	& > :not(:first-child) {
		padding: ${p => p.theme.spacing.large} 0;
	}

	& > :first-child {
		padding-bottom: ${p => p.theme.spacing.large};
	}

	& > :not(:last-child) {
		border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	}
`;
const MilestonesRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export default LenderMilestones;
