import React from 'react';

const Dribbble: React.FunctionComponent = () => (
	<svg width={'20px'} height={'20px'} viewBox={'0 0 20 20'}>
		<circle
			cx={'10'}
			cy={'12'}
			r={'9'}
			fill={'#EA4C89'}
			fillOpacity={'0'}
		></circle>
		<g id={'Assets'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g
				id={'Views-/-Footer-/-Tablet'}
				transform={'translate(-669.000000, -206.000000)'}
				fill={'#EA4C89'}
				fillRule={'nonzero'}
			>
				<g id={'Footer-padding'}>
					<g id={'Group-8'} transform={'translate(52.000000, 177.000000)'}>
						<g id={'Follow-us'} transform={'translate(514.000000, 0.000000)'}>
							<g id={'Group-11'} transform={'translate(0.000000, 29.000000)'}>
								<g id={'Group-5'}>
									<g
										id={'Icons-/-Social-/-Dribbble'}
										transform={'translate(103.000000, 0.000000)'}
									>
										<path
											d={'M10,20 C4.4875,20 0,15.5125 0,10 C0,4.4875 4.4875,0 10,0 C15.5125,0 20,4.4875 20,10 C20,15.5125 15.5125,20 10,20 Z M18.4333333,11.3683333 C18.1416667,11.2766667 15.7916667,10.5741667 13.1133333,11.0033333 C14.23,14.0733333 14.6858333,16.5733333 14.7733333,17.0933333 C16.69,15.7975 18.0533333,13.7433333 18.4358333,11.3683333 L18.4333333,11.3683333 Z M13.3375,17.875 C13.21,17.125 12.7125,14.515 11.5125,11.4 L11.4575,11.4166667 C6.6325,13.0958333 4.9075,16.4375 4.7575,16.75 C6.19916667,17.8816667 8.02416667,18.555 9.99916667,18.555 C11.1825,18.555 12.3075,18.3133333 13.3325,17.8766667 L13.3375,17.875 Z M3.65416667,15.725 C3.8475,15.3916667 6.19166667,11.5125 10.5975,10.0875 C10.71,10.05 10.8225,10.0175 10.935,9.9875 C10.7183333,9.5 10.485,9.015 10.2416667,8.5375 C5.975,9.8125 1.83833333,9.75833333 1.46333333,9.75 L1.46,10.01 C1.46,12.2041667 2.29166667,14.2075 3.655,15.7225 L3.65416667,15.725 Z M1.6375,8.2625 C2.02083333,8.26916667 5.54,8.28416667 9.535,7.2225 C8.12,4.7075 6.59333333,2.59083333 6.36833333,2.2825 C3.97833333,3.4075 2.19333333,5.6075 1.63833333,8.2575 L1.6375,8.2625 Z M8,1.71 C8.235,2.02666667 9.7875,4.13833333 11.185,6.71 C14.2225,5.5725 15.51,3.84333333 15.6625,3.625 C14.1541667,2.28333333 12.1708333,1.47 10,1.47 C9.3125,1.47 8.64166667,1.55333333 8,1.7075 L8,1.71 Z M16.6125,4.6125 C16.4308333,4.85416667 15,6.69 11.8425,7.97916667 C12.0425,8.3875 12.2341667,8.8 12.4091667,9.2175 C12.4758333,9.3675 12.5341667,9.5175 12.5925,9.65916667 C15.4341667,9.30083333 18.2591667,9.87583333 18.5425,9.93416667 C18.5258333,7.9175 17.8091667,6.0675 16.6175,4.6175 L16.6125,4.6125 Z'}
											id={'Dribbble'}
										></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Dribbble;
