export const initialVehicleProfileState = {
    listVehicleProfiles: {},
    updateVehicleProfile: {},
    userVehicleProfile: {},
    vehicleProfile: {},
};
export const VehicleProfileActionTypes = {
    GET_VEHICLE_PROFILE: '@@platform/vehicle-profile/GET_VEHICLE_PROFILE',
    GET_VEHICLE_PROFILE_FAILURE: '@@platform/vehicle-profile/GET_VEHICLE_PROFILE_FAILURE',
    GET_VEHICLE_PROFILE_SUCCESS: '@@platform/vehicle-profile/GET_VEHICLE_PROFILE_SUCCESS',
    LIST_VEHICLE_PROFILES: '@@platform/vehicle-profile/LIST_VEHICLE_PROFILES',
    LIST_VEHICLE_PROFILES_FAILURE: '@@platform/vehicle-profile/LIST_VEHICLE_PROFILES_FAILURE',
    LIST_VEHICLE_PROFILES_SUCCESS: '@@platform/vehicle-profile/LIST_VEHICLE_PROFILES_SUCCESS',
    USER_VEHICLE_PROFILE: '@@platform/vehicle-profile/USER_VEHICLE_PROFILE',
    USER_VEHICLE_PROFILE_FAILURE: '@@platform/vehicle-profile/USER_VEHICLE_PROFILE_FAILURE',
    USER_VEHICLE_PROFILE_SUCCESS: '@@platform/vehicle-profile/USER_VEHICLE_PROFILE_SUCCESS',
    UPDATE_VEHICLE_PROFILE: '@@platform/vehicle-profile/UPDATE_VEHICLE_PROFILE',
    UPDATE_VEHICLE_PROFILE_FAILURE: '@@platform/vehicle-profile/UPDATE_VEHICLE_PROFILE_FAILURE',
    UPDATE_VEHICLE_PROFILE_SUCCESS: '@@platform/vehicle-profile/UPDATE_VEHICLE_PROFILE_SUCCESS',
};
