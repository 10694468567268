import { all } from 'redux-saga/effects';
import getAuthenticationToken from './get-authentication-token';
import getDeviceConfig from './get-device-config';
import setUserStatus from './set-user-status';
import userStatus from './user-status';
export default function* marketingConsentSaga() {
    yield all([
        userStatus,
        setUserStatus,
        getAuthenticationToken,
        getDeviceConfig,
    ]);
}
