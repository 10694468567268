import { call, getContext, put } from 'redux-saga/effects';
import { sendDocuments } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(sendDocuments.request, function* workerSendPolicyDocuments({ meta, payload: requestPayload }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const payload = {
        policyId: requestPayload.policyId,
        toAddress: requestPayload.toAddress,
        useCase: requestPayload.useCase,
    };
    try {
        yield call(api.sendPolicyDocuments, client, payload);
        yield put(sendDocuments.success(ident));
    }
    catch (error) {
        yield put(sendDocuments.failure(ident, error));
    }
});
