import { call, getContext, put } from 'redux-saga/effects';
import { licenseByUserId } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(licenseByUserId.request, function* workerLicenseByUserId({ payload, meta }) {
    const { ident } = meta;
    const { userId, revision } = payload;
    const client = yield getContext('api');
    try {
        const response = yield call(api.licenseByUserId, client, userId, revision);
        yield put(licenseByUserId.success(ident, response));
    }
    catch (error) {
        yield put(licenseByUserId.failure(ident, error));
    }
});
