import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import DownloadApp from '../quote/components/organisms/DownloadApp';
import ReferralCard from './atoms/ReferralCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import { trackScreenView } from '~website/features/analytics/store/actions';

const Download: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();

	useEffect(() => {
		dispatch(trackScreenView({
			eventName: 'stm_referred_lender_owner_download',
			eventPayload: {},
		}));
	}, [dispatch, path]);

	return (
		<Margin $marginTop={'extraLarge'} $marginBottom={'extraLarge'}>
			<ReferralCard>
				<DownloadApp />
			</ReferralCard>
		</Margin>

	);
};

export default Download;
