import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as Dropdown from '~lib/frontend/design-system/components/dropdown';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useSubscriptions from '~website/features/policy/hooks/use-subscriptions';
import useUser from '~website/hooks/use-user';
import { clearAuthUser } from '~website/store/auth/actions';

interface UserDropdownProps {
	callerRef: React.MutableRefObject<HTMLElement>;
	open: boolean;
	setOpen: (open: boolean) => void;
}

const UserDropdown: React.FCWithChildren<UserDropdownProps> = ({ callerRef, open, setOpen }) => {
	const dispatch = useDispatch();
	const { primaryEmailAddress } = useUser();
	const { checkForFailedPayment } = useSubscriptions();

	if (!callerRef.current)
		return null;

	return (
		<Wrap>
			<Dropdown.Menu reference={callerRef} shown={open} onClose={() => setOpen(false)} placement={'bottom-end'}>
				<React.Fragment>
					<Dropdown.ItemWithBody
						type={'link'}
						to={'/me'}
						icon={'ic_account_circle'}
					>
						<LoggedInUser>
							<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
								{'Account'}
							</Typography>
							<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
								{primaryEmailAddress?.value}
							</Typography>
						</LoggedInUser>
					</Dropdown.ItemWithBody>

					<Dropdown.ItemWithBody
						type={'link'}
						icon={'ic_policy'}
						to={'/me/policies'}
					>
						<PolicyLinkWrapper>
							<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
								{'Policies'}
							</Typography>
							{checkForFailedPayment && <Icon icon={'ic_exclamation_circle'} $size={'24px'} $color={'dangerFill'} />}
						</PolicyLinkWrapper>
					</Dropdown.ItemWithBody>
				</React.Fragment>

				<Dropdown.ItemWithBody
					type={'button'}
					icon={'ic_box_arrow_right'}
					onClick={() => void dispatch(clearAuthUser())}
				>
					<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
						{'Log out'}
					</Typography>
				</Dropdown.ItemWithBody>
			</Dropdown.Menu>
		</Wrap>
	);
};

export default UserDropdown;

// SC: this is tragic. We are playing with z-index and there's currently no way to edit the dropdown
// built in one, other than targeting an inner element. And because we shouldn't be including atoms
// from other components as inner workings of a component should be treated as opaque, this is it.
const Wrap = styled.div`
	aside {
		z-index: 11;
	}
`;

export const PolicyLinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 1 0 auto;
`;

export const LoggedInUser = styled.div`
	display: inline-flex;
	flex-direction: column;
	max-width: 250px;
	cursor: inherit;

	& > * {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
