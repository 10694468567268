import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { GetterType } from '~lib/frontend/types/content';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import BulletDots from '~website/components/atoms/BulletDots';
import Footer from '~website/components/layout/footer';
import Header from '~website/components/layout/header';
import Meta from '~website/components/Meta';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';
import { useHideIntercomLauncher } from '~website/hooks/intercom';
import { useMobileOneLink } from '~website/hooks/use-mobile-one-link';

const DownloadQuote: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages[download-quote].${key}`, fallback);
	const mobileOneLink = useMobileOneLink();
	const redirectUrl = get('redirect_url', mobileOneLink);
	const queCodeAssetName = get('qr_code', 'quote/branch-qr-code.png');
	const quoteQRCode = useContentAsset('website_core', queCodeAssetName);

	const { isAndroid, isApplePhone, isApplePad } = useMobileDetect();
	const isMobile = isAndroid || isApplePhone || isApplePad;

	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const location = useLocation();
	const fullUrl = reapplyParamsIfExternalUrl(redirectUrl, sourceTracking, location.pathname);

	useEffect(() => {
		if (!isMobile) return;

		window.location.assign(fullUrl);
	}, [fullUrl, isMobile]);

	useHideIntercomLauncher();

	return (
		<VisionaryContext.Provider value={true}>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<Header contentUnder downloadUrl={'https://cuvva.onelink.me/PmWW/dl'} />
			<PageWrap>
				<QRCodeWrap>
					<QRCodeImage src={quoteQRCode} alt={'QR code download the Cuvva app'} />
					<Typography $type={'Heading.Large'} $color={'textOnFill'} $marginTop={'40px'} as={'h1'}>
						{get('heading', 'Download Cuvva')}
					</Typography>
					<Typography $type={'Heading.XSmall'} $color={'textOnFill'} $marginTop={'large'}>
						{get('subheading', 'Scan the QR code with your phone camera to install Cuvva.')}
					</Typography>

					<Margin $marginTop={'60px'} $marginBottom={'60px'}>
						<BulletDots items={get('bullets', ['Download Cuvva', 'Create an account', 'Get covered 🎉'])} />
					</Margin>

					<AppStoreWrap>
						<AppStoreButtonsSegment url={oneLink} />
					</AppStoreWrap>
				</QRCodeWrap>
			</PageWrap>
			<Footer />
		</VisionaryContext.Provider>
	);
};

const PageWrap = styled.div`
	background: ${p => p.theme.ui.secondaryBackground};
	min-height: 600px;
	padding-top: 160px;

	@media (min-width: 768px) {
		padding-top: 200px;
	}

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

const QRCodeWrap = styled.div`
	margin: 0 auto;
	text-align: center;
`;

const QRCodeImage = styled.img`
	margin: 0 auto;
	display: block;
	padding: ${p => p.theme.spacing.large};
	width: 128px;
	height: 128px;
	background: white;
	border-radius: ${p => p.theme.borderRadius.regular};
`;

const AppStoreWrap = styled.div`
	padding-bottom: 120px;
	display: flex;
	justify-content: center;

	@media (min-width: 768px) {
		padding-bottom: 160px;
	}
`;

export default DownloadQuote;
