import React from 'react';

import { discountInfo } from '../../utils/handleDiscounts';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { DiscountV3 } from '~lib/platform/promo/types';

interface DiscountItemProps {
	discount: DiscountV3;
}

const Discount = (props: DiscountItemProps) => {
	const { discount } = props;

	const discountInformation = discountInfo(discount);
	const title = discountInformation.title();
	const type = discountInformation.type();
	const state = discountInformation.state();
	const expiryDate = discountInformation.expiryDate();
	const requirements = discountInformation.requirements();
	const formattedRequirements = requirements.map(d => d).join(', ');

	const iconType = type === 'percent' ? 'ic_percentfill' : 'ic_tax_fill';
	const iconColor = state === 'active' ? 'successFill' : 'neutralFillMinorMuted';

	return (
		<li>
			<Icon icon={iconType} $color={iconColor} $size={'24px'} $marginBottom={'small'} />

			<div>
				<Typography $type={'Label.Medium'} $color={'textOnSurfaceBackground'}>
					{`${title} ${formattedRequirements}`}
				</Typography>

				<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
					{expiryDate}
				</Typography>
			</div>
		</li>
	);
};

export default Discount;
