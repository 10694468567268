import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';

export interface ConfirmScreenProps {
	heading: string;
	body: string;
	previewBuffer: string | undefined;
	tryAgainText?: string;
	continueText?: string;
	analyticsPage: string;
}

export interface ConfirmControlsProps extends ConfirmScreenProps {
	onTryAgain: () => void;
	onContinue: () => void;
}

const ConfirmScreen = (props: ConfirmControlsProps) => {
	const {
		heading,
		body,
		previewBuffer,
		onContinue,
		continueText = 'Continue',
		onTryAgain,
		tryAgainText = 'Try again',
		analyticsPage,
	} = props;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(trackPayOnWebScreenView({ screenName: analyticsPage }));
	}, [dispatch, analyticsPage]);

	return (
		<React.Fragment>
			<Typography
				$type={'Heading.Medium'}
				$color={'textOnSurfaceBackground'}
				$align={'center'}
				$marginTop={'large'}
				$marginBottom={'large'}
			>
				{heading}
			</Typography>

			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
				{body}
			</Typography>

			<Margin $marginTop={'extraLarge'} $marginBottom={'extraLarge'}>
				<PreviewContainer>
					<picture>
						<img src={previewBuffer} alt={'document-preview'} />
					</picture>
				</PreviewContainer>
			</Margin>

			<AlignDoubleColChildren>
				<Button $stretch $size={'large'} $type={'neutralMuted'} onClick={onTryAgain}>
					{tryAgainText}
				</Button>
				<Button $stretch $size={'large'} $type={'primary'} onClick={onContinue}>
					{continueText}
				</Button>
			</AlignDoubleColChildren>
		</React.Fragment>
	);
};

const PreviewContainer = styled.div`
	padding: 0 5%;

	img {
		border-radius: 16px;
		width: 100%;
	}
`;

export default ConfirmScreen;
