import React from 'react';

import { CurrentCircle, DownloadStepsList, DownloadStepsListItem, EmptyCircle, IconWrap } from '../atoms/DownloadStepsSC';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface DownloadStepsProps {
	current: number;
}

const DownloadSteps: React.FCWithChildren<DownloadStepsProps> = ({ children, current }) => (
	<DownloadStepsList>
		{React.Children.toArray(children).map((t, i) => (
			<DownloadStepsListItem key={`${t}:${i}`}>
				<IconWrap>
					{i < current && <Icon icon={'ic_check_circle'} $size={'24px'} $color={'primaryFill'} />}
					{i === current && <CurrentCircle />}
					{i > current && <EmptyCircle />}
				</IconWrap>

				<Typography
					$type={'Body.Large'}
					$bold={i === current}
					$color={i === current ? 'textHighlight' : 'textOnSurfaceBackgroundMuted'}
				>
					{t}
				</Typography>
			</DownloadStepsListItem>
		))}
	</DownloadStepsList>
);

export default DownloadSteps;
