import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { createPromos, listPromos } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherCreatePromos() {
    yield takeEvery(PromoActionTypes.CREATE_PROMOS, workerCreatePromos);
}
// eslint-disable-next-line max-len
function* workerCreatePromos({ payload, meta }) {
    const { ident } = meta;
    const { requestId, listRequestId, pageNumber, ...requestBody } = payload;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        yield call(api.createPromos, client, requestBody);
        yield put(createPromos.success(ident));
        yield put(flashMessages.addSuccess(`The promo code has successfully been created 📣${pageNumber > 0 ? ". You'll find it on page 1." : ''}`));
        yield put(listPromos.request({ requestId: listRequestId, pageSize: 20, pageNumber }));
    }
    catch (error) {
        const cher = Cher.coerce(error);
        if (cher.code !== 'promo_already_exists') {
            yield put(flashMessages.addError('Unable to create promo', 'There was an unknown issue when creating the promo code. The error is below:', cher));
        }
        yield put(createPromos.failure(ident, error));
    }
}
