import React, { useContext } from 'react';

import { Report, ReportingToWrapper } from '../atoms/ReportingToWrapper';
import HiringOfferContext from '../context';
import Typography from '~lib/frontend/design-system/components/Typography';

const CuvvaSharesOptions: React.FunctionComponent = () => {
	const { shareOptionsCount, shareOptionsEstimatedValue } = useContext(HiringOfferContext);

	if (![shareOptionsCount, shareOptionsEstimatedValue].some(Boolean)) return null;

	const sharesValue = shareOptionsEstimatedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return (
		<ReportingToWrapper>
			{shareOptionsCount && (
				<Report>
					<Typography $type={'Heading.XSmall'}>{'Number of shares'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{shareOptionsCount}
					</Typography>
				</Report>
			)}
			{shareOptionsEstimatedValue && (
				<Report>
					<Typography $type={'Heading.XSmall'}>{'Value of Shares Options'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{`£${sharesValue}`}
					</Typography>
					<Typography $type={'Body.Medium'}>{'*As of previous round of funding in 2019'}</Typography>
				</Report>
			)}
		</ReportingToWrapper>
	);
};

export default CuvvaSharesOptions;
