import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';

import { stepActions } from '../actions';
import type { InternalChangeRequestState } from '../types';

export default createSlice({
	name: '@@internal/quote/internal-change-request',
	initialState: {} as Record<string, Partial<InternalChangeRequestState>>,
	reducers: {},
	extraReducers: builder => builder
		.addCase(stepActions.setProfileValue, (state, action) => {
			const { userId, internalChangeRequestId, key, value } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				profile: {
					...state?.[internalChangeRequestId]?.profile,
					[userId]: {
						...state?.[internalChangeRequestId]?.profile?.[userId],
						[key]: value,
					},
				},
			};
		})
		.addCase(stepActions.setEmailAddress, (state, action) => {
			const { userId, internalChangeRequestId, emailAddress } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				auth: {
					...state?.[internalChangeRequestId]?.auth,
					[userId]: emailAddress,
				},
			};
		})
		.addCase(stepActions.setMarketingConsent, (state, action) => {
			const { userId, internalChangeRequestId, marketingConsent } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				marketingConsent: {
					[userId]: marketingConsent,
				},
			};
		})
		.addCase(stepActions.setDLRValue, (state, action) => {
			const { userId, internalChangeRequestId, key, value } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				drivingLicenseRegistration: {
					...state?.[internalChangeRequestId]?.drivingLicenseRegistration,
					[userId]: {
						...state?.[internalChangeRequestId]?.drivingLicenseRegistration?.[userId],
						[key]: value,
					},
				},
			};
		})
		.addCase(stepActions.setBeenInvolved, (state, action) => {
			const { internalChangeRequestId, beenInvolved } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				incidents: {
					...state?.[internalChangeRequestId]?.incidents,
					beenInvolved,
				},
			};
		})
		.addCase(stepActions.addIncident, (state, action) => {
			const { internalChangeRequestId } = action.payload;

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				incidents: {
					...state?.[internalChangeRequestId]?.incidents,
					incidents: [
						...(state?.[internalChangeRequestId]?.incidents?.incidents || []),
						{
							category: void 0,
							cost: 0,
							date: void 0,
							atFault: void 0,
							bodilyInjury: void 0,
							theftOf: void 0,
						},
					],
				},
			};
		})
		.addCase(stepActions.deleteIncident, (state, action) => {
			const { internalChangeRequestId, index } = action.payload;

			const incidents = [...state?.[internalChangeRequestId]?.incidents?.incidents || []];

			incidents.splice(index, 1);

			state[internalChangeRequestId].incidents.incidents = incidents;
		})
		.addCase(stepActions.editIncident, (state, action) => {
			const { internalChangeRequestId, index, key, value } = action.payload;
			const incidentsCopy = [...state[internalChangeRequestId]?.incidents?.incidents || []];
			const updateIncident = { ...incidentsCopy[index] };

			// @ts-ignore y u do this to me
			updateIncident[key] = value;

			incidentsCopy.splice(index, 1, updateIncident);

			state[internalChangeRequestId] = {
				...state?.[internalChangeRequestId],
				incidents: {
					...state?.[internalChangeRequestId]?.incidents,
					incidents: incidentsCopy,
				},
			};
		})
		.addCase(stepActions.setVehicleProfileValue, (state, action) => {
			const { vehicleId, internalChangeRequestId, key, value } = action.payload;

			set(state, `[${internalChangeRequestId}].vehicleProfile[${vehicleId}].${key}`, value);
		})
	,
});
