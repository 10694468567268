import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';

import LiveLocation from './sharedQuoteLocation/LiveLocation';
import { LoadingLocationData, NoLocationAvailable } from './sharedQuoteLocation/LiveLocationLoading';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import { sharedQuoteLiveLocation } from '~lib/platform/social-driving/store/actions';
import { OwnersData } from '~website/features/referral/types';
import { ApplicationState } from '~website/store';

interface SharedQuoteLiveLocationProps {
	ownersData: OwnersData;
}

const SharedQuoteLiveLocation: React.FCWithChildren<SharedQuoteLiveLocationProps> = props => {
	const { ownersData } = props;

	const dispatch = useDispatch();
	const params: Record<string, string> = useParams();

	const showSharedQuoteLiveLocation = useConfigFlag('shared_quote_live_location');

	const sharedQLiveLocation = useSelector(
		(state: ApplicationState) => state.platform.socialDriving.getSharedQuoteLiveLocation[params?.id],
	);

	const sqLiveLocationStatus = useAsyncStateStatus(sharedQLiveLocation);

	useEffect(() => {
		if (params?.id)
			dispatch(sharedQuoteLiveLocation.request({ key: params.id }));
	}, [dispatch, params?.id]);

	if (sqLiveLocationStatus.is('pending', 'fetching'))
		return <LoadingLocationData />;

	if (sqLiveLocationStatus.is('error') || !showSharedQuoteLiveLocation)
		return <NoLocationAvailable />;

	const lastUpdate = moment(sharedQLiveLocation?.response?.lastUpdatedAt).fromNow();

	return (
		<LiveLocation
			lastUpdate={lastUpdate}
			name={ownersData?.profile?.personalName}
			location={sharedQLiveLocation?.response.currentLocation}
		/>
	);
};

export default SharedQuoteLiveLocation;
