import React from 'react';

import { Background, BackgroundInner } from './atoms/Background';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import HeaderBar from '~website/features/quote/components/molecules/headers/HeaderBar';

interface VibrantScreenProps {
	title?: string | undefined;
}

const VibrantScreen: React.FCWithChildren<VibrantScreenProps> = ({ title, children }) => (
	<DesignSystemProvider themeKey={'light'} typographyStyle={'expressive'}>
		<Background>
			<HeaderBar transparent />
			<BackgroundInner>
				{title && (
					<Margin $marginTop={'extraLarge'}>
						<Typography $type={'Heading.Large'} $color={'textOnFill'} $align={'center'}>
							{title}
						</Typography>
					</Margin>
				)}

				<Margin
					$marginRight={'large'}
					$marginLeft={'large'}
				>
					{children}
				</Margin>
			</BackgroundInner>
		</Background>
	</DesignSystemProvider>
);

export default VibrantScreen;
