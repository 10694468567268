import React, { Fragment, FunctionComponent, useEffect, useMemo } from 'react';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import Loading from './Loading';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useWindowSize from '~lib/frontend/hooks/use-window-size';
import { retrieveHiringOffer } from '~lib/platform/hiring-offer/store/actions';
import { fromWebsafeBase64 } from '~lib/shared/helpers/base64';
import SectionSegment from '~website/components/atoms/SectionSegment';
import { Overlay } from '~website/features/hiring-offer/atoms/HiringOfferHeaderWrapper';
import { FlexWrapper } from '~website/features/hiring-offer/atoms/ReportingToWrapper';
import CuvvaOptions from '~website/features/hiring-offer/components/CuvvaOptions';
import CuvvaSharesOptions from '~website/features/hiring-offer/components/CuvvaSharesOptions';
import FreeForChat from '~website/features/hiring-offer/components/FreeforChat';
import HiringOfferFooter from '~website/features/hiring-offer/components/HiringOfferFooter';
import HiringOfferHeader from '~website/features/hiring-offer/components/HiringOfferHeader';
import KeyDocuments from '~website/features/hiring-offer/components/KeyDocuments';
import KeyTerms from '~website/features/hiring-offer/components/KeyTerms';
import NextSteps from '~website/features/hiring-offer/components/NextSteps';
import ReportingTo from '~website/features/hiring-offer/components/ReportingTo';
import Testimonials from '~website/features/hiring-offer/components/Testimonials';
import TooManyRequests from '~website/features/hiring-offer/components/TooManyRequests';
import HiringOfferContext from '~website/features/hiring-offer/context';
import { OfferData } from '~website/features/hiring-offer/types';
import Page404 from '~website/pages/NotFound';

interface OfferParams {
	id: string;
}

const HiringOffer: FunctionComponent = () => {
	const { id } = useParams<OfferParams>();
	const location = useLocation();
	const windowSize = useWindowSize({ debounceTimeout: 1000 });

	const offerDetailsStore = useSelector(s => s.platform.hiringOffer.retrieveHiringOffer[id]);
	const status = useAsyncStateStatus(offerDetailsStore);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(retrieveHiringOffer.request({ key: id }));
	}, [dispatch, id]);

	const offerDetails: OfferData = useMemo(() => {
		if (!location.hash) return null;
		const hash = location.hash.replace('#', '');

		try {
			const decodedData = fromWebsafeBase64(hash);
			const salaryInfo = JSON.parse(decodedData);

			if (!salaryInfo) return null;
			if (offerDetailsStore?.response && salaryInfo) {
				return {
					...offerDetailsStore.response,
					...salaryInfo,
				};
			}
		} catch (e) {
			return null;
		}

		return null;
	}, [location.hash, offerDetailsStore?.response]);

	if (status.is('fetching', 'pending')) return <Loading />;
	if (status.is('error') && offerDetailsStore.error.code === 'too_many_requests') return <TooManyRequests />;

	if (status.is('error') || !offerDetails) return <Page404 />;
	const pageTitle = `Job offer for ${offerDetails.candidateName}`;

	return (
		<HiringOfferContext.Provider value={offerDetails}>
			<Fragment>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<Overlay>
					<Confetti
						width={windowSize.width}
						height={windowSize.height}
						recycle={false}
						tweenDuration={30000}
						numberOfPieces={2000}
					/>
				</Overlay>
				<HiringOfferHeader />
				<SectionSegment.Section $visionary={false} $background={'none'} $backgroundMobile={'none'}>
					<SectionSegment.Inner>
						<Testimonials />
						<FlexWrapper>
							<ReportingTo />
							<KeyTerms />
						</FlexWrapper>
						<KeyDocuments />
						<FlexWrapper>
							<CuvvaSharesOptions />
							<CuvvaOptions />
						</FlexWrapper>
						<FreeForChat />
						<NextSteps />
						<HiringOfferFooter />
					</SectionSegment.Inner>
				</SectionSegment.Section>
			</Fragment>
		</HiringOfferContext.Provider>
	);
};

export default HiringOffer;
