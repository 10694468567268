import React, { useContext, useLayoutEffect, useRef } from 'react';
import { SingleOTP } from '../atoms/OTP';
import InputDecoratorContext from '../context/decorator';
import usePrevious from '../hooks/use-previous';
const SingleOTPInput = (props) => {
    const { focus, autoFocus, ...rest } = props;
    const { error } = useContext(InputDecoratorContext);
    const inputRef = useRef(null);
    const prevFocus = usePrevious(Boolean(focus));
    useLayoutEffect(() => {
        if (inputRef?.current) {
            if (focus && autoFocus)
                inputRef.current.focus();
            if (focus && autoFocus && focus !== prevFocus) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }
    }, [autoFocus, focus, prevFocus]);
    return (React.createElement(SingleOTP, { ref: inputRef, "$error": Boolean(error), ...rest }));
};
export default SingleOTPInput;
