export interface CustomEvent {
	eventType: 'EVENT';
	eventName: string; // this is mandatory for custom events

	// everything down here is optional
	eventValue?: string | Record<string, unknown>; // this can be JSON - but must be a string.
	eventCategory?: string;
	eventLabel?: string;
}

export class AppsFlyerSDK {
	identify(userId: string) {
		try {
			// @ts-ignore
			AF('pba', 'setCustomerUserId', userId); // eslint-disable-line
		} catch {
			// not much we can do
		}
	}

	trackEvent(event: Omit<CustomEvent, 'eventType'>) {
		try {
			// @ts-ignore
			AF('pba', 'event', { eventType: 'EVENT', ... event }); // eslint-disable-line
		} catch {
			// not much we can do
		}
	}
}
