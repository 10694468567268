const system = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';
const grotesk = `"neue-haas-grotesk-text", ${system}`;
const riforma = `Riforma, ${system}`;
const numberPlate = `"UKNumberPlate", ${system}`;
const fonts = {
    heading: riforma,
    expressive: grotesk,
    productive: system,
    UKNumberPlate: numberPlate,
    default: grotesk,
    system,
};
export default fonts;
