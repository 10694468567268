import Cher from '@cuvva/cher';
import { createAction } from '@reduxjs/toolkit';

import type { PreloadedVehicle, QuoteLocation, SettableVehicleProfileValue, SubmitPagePayload } from './types';
import type { IncidentBase } from '~lib/platform/incident/types';
import type { UseClass } from '~lib/platform/ltm/types';
import type { Profile } from '~lib/platform/profile/types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';

const action = (...args: TemplateStringsArray[]) => ['@@internal/quote'].concat(...args).join('/');

export const quoteActions = {
	fetchUser: createAsyncAction<void>(action`fetchUser`),
	fetchPreferences: createAsyncAction<void>(action`fetchPreferences`),
	setRecentFlow: createAction<string>(action`setRecentFlow`),
	setRecentVehicles: createAction<PreloadedVehicle[]>(action`setRecentVehicles`),
	setVehicle: createAction<{ vehicleId: string }>(action`setVehicle`),
	setProduct: createAction<string>(action`setProduct`),
	setFlow: createAction<string>(action`setFlow`),
	acceptTerms: createAction<void>(action`acceptTerms`),
};

export const stepActions = {
	setProfileValue: createAction<SetValueProps<Profile>>(action`setProfileValue`),
	setMarketingConsent: createAction<SetMarketingConsent>(action`setMarketingConsent`),
	setVehicleProfileValue: createAction<SetVehicleProfileValue>(action`setVehicleProfileValue`),
	setDLRValue: createAction<SetDLRValue>(action`setDlrValue`),
	setEmailAddress: createAction<SetEmailAddress>(action`setEmailAddress`),
	setFieldError: createAction<SetError>(action`setFieldError`),

	setBeenInvolved: createAction<SetIncidentBeenInvolved>(action`setIncidentBeenInvolved`),
	addIncident: createAction<AddIncident>(action`addIncident`),
	editIncident: createAction<EditIncident>(action`editIncident`),
	deleteIncident: createAction<DeleteIncident>(action`deleteIncident`),

	setNCBYears: createAction<number | null>(action`setNcbYears`),
	setQuoteLocation: createAction<QuoteLocation | null>(action`setQuoteLocation`),
	setStartDate: createAction<string>(action`setStartDate`),
	setUseClass: createAction<UseClass>(action`setUseClass`),
	setVoluntaryExcess: createAction<number>(action`setVoluntaryExcess`),
	setSmartPricing: createAction<boolean>(action`setSmartPricing`),
};

export const pageActions = {
	newChangeRequest: createAction<NewChangeRequest>(action`newChangeRequest`),
	submitPage: createAsyncMapAction<SubmitPagePayload, string>(action`submitPage`, p => p.pageId),
	registerField: createAction<RegisterField>(action`registerField`),
	deregisterField: createAction<RegisterField>(action`deregisterField`),
};

interface SetValueProps<T>{
	key: keyof T;
	userId?: string;
	internalChangeRequestId: string;
	value: T[keyof T];
}

interface SetMarketingConsent {
	userId: string;
	internalChangeRequestId: string;
	marketingConsent: 'anything' | 'relevant' | 'essential';
}

interface SetEmailAddress {
	userId: string;
	internalChangeRequestId: string;
	emailAddress: string;
}

interface SetDLRValue {
	key: 'dln' | 'postcode';
	userId: string;
	internalChangeRequestId: string;
	value: string;
}

// 👇 Vehicle profile 👇
interface SetVehicleProfileValue {
	key: SettableVehicleProfileValue;
	userId: string;
	vehicleId: string;
	internalChangeRequestId: string;
	value: unknown;
}

// 👇 Incidents 👇
interface SetIncidentBeenInvolved {
	beenInvolved: boolean;
	internalChangeRequestId: string;
}

interface AddIncident {
	internalChangeRequestId: string;
}

interface EditIncident {
	index: number;
	key: keyof IncidentBase;
	value: IncidentBase[keyof IncidentBase];
	internalChangeRequestId: string;
}

interface DeleteIncident {
	index: number;
	internalChangeRequestId: string;
}

// 👇 Page & fields specific payload 👇
interface SetError {
	pageId: string;
	field: string;
	error: Cher | void;
}

interface RegisterField {
	pageId: string;
	field: string;
}

interface NewChangeRequest {
	pageId: string;
	internalChangeRequestId: string;
	pagePath: string;
}
