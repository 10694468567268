import { put } from 'redux-saga/effects';

import { trackScreenView } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.trackAccountsScreenView,
	function* accountsScreenViewSaga(action) {
		const { screenName } = action.payload;

		const eventPayload = {
			product: 'ltm',
			platform: 'website',
			screen_name: screenName,
		};

		yield put(trackScreenView({
			eventName: screenName,
			eventPayload,
		}));
	});
