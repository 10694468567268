import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getLatestKyc } from '../actions';
import { KycActionTypes } from '../types';
import { fileById } from '~lib/platform/upload/store/actions';
export default function* watcherLatestKyc() {
    yield takeEvery(KycActionTypes.GET_LATEST_KYC, workerLatestKyc);
}
function* workerLatestKyc({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call([client, client.rpc], 'service-kyc', '1/2020-04-01/get_latest_kyc', payload);
        yield put(getLatestKyc.success(ident, response));
        if (response.licensePhotoId)
            yield put(fileById.request({ fileId: response.licensePhotoId, urlTtl: 3600, browserCache: true }));
        if (response.profilePhotoId)
            yield put(fileById.request({ fileId: response.profilePhotoId, urlTtl: 3600, browserCache: true }));
        if (response.backLicensePhotoId)
            yield put(fileById.request({ fileId: response.backLicensePhotoId, urlTtl: 3600, browserCache: true }));
    }
    catch (error) {
        yield put(getLatestKyc.failure(ident, error));
    }
}
