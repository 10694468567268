import { useCallback, useEffect, useState } from 'react';
import debounce from '~lib/shared/helpers/debounce';
function useWindowSize({ debounceTimeout = 400 } = {}) {
    const isClient = typeof window === 'object';
    const getSize = useCallback(() => ({
        width: isClient ? window.innerWidth : void 0,
        height: isClient ? window.innerHeight : void 0,
    }), [isClient]);
    const [windowSize, setWindowSize] = useState(getSize());
    useEffect(() => {
        if (!isClient)
            // eslint-disable-next-line no-empty-function
            return () => { };
        const handleResize = () => setWindowSize(getSize());
        const debounceHandleResize = debounce(handleResize, debounceTimeout);
        window.addEventListener('resize', debounceHandleResize);
        // window.addEventListener('orientationchange', debounceHandleResize);
        return () => window.removeEventListener('resize', debounceHandleResize);
    }, [debounceTimeout, getSize, isClient]);
    return windowSize;
}
export default useWindowSize;
