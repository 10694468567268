import styled, { css } from 'styled-components';
export const Table = styled.table `
	width: 100%;

	${props => props.layoutFixed && css `
		table-layout: fixed;
	`};

	${props => props.responsive && `
		overflow: auto;
	`};

	thead {
		background-color: ${props => props.theme.ui.surfaceFill};
	}

	th {
		white-space: nowrap;
		color: ${props => props.theme.ui.textOnSurfaceBackgroundMuted};

		/* this needs to be standardised once t8y is out */
		/* body.small.bold */
		font-size: 14px;
		font-weight: bold;
	}

	th, td {
		${p => p.condensed ? 'padding: 0.3rem' : 'padding: 0.7rem 1rem'};
		border-top: 1px solid ${props => props.theme.ui.borderSeparator};
		${p => p.nowrap ? 'white-space: nowrap' : ''};
	}

	td:first-child, td:last-child {
		${p => p.condensed && 'padding: 0.3rem 1rem'};
	}

	th:first-child, th:last-child {
		${p => p.condensed && 'padding: 0.5rem 1rem'};
	}

	.card-body > & th {
		border-top: none;
	}

	${p => p.striped && css `
		tbody tr:nth-child(even) {
			background-color: ${props => props.theme.ui.surfaceFill};
		}
	`};

	${p => p.highlightOnHover && css `
		tbody tr:hover {
			// highlightOnHover takes precedence over everything
			// https://cuvva.slack.com/archives/CG9NJ4UJE/p1624462103380200?thread_ts=1624456063.376600&cid=CG9NJ4UJE
			background-color: ${props => props.theme.ui.surfaceFill} !important;
		}
	`};
`;
export default Table;
