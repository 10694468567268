import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { listVehicleProfiles } from '../actions';
import { VehicleProfileActionTypes } from '../types';
export default function* watcherListVehicleProfiles() {
    yield takeEvery(VehicleProfileActionTypes.LIST_VEHICLE_PROFILES, workerListVehicleProfiles);
}
function* workerListVehicleProfiles({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.listVehicleProfiles, client, payload);
        yield put(listVehicleProfiles.success(ident, response));
    }
    catch (error) {
        yield put(listVehicleProfiles.failure(ident, error));
    }
}
