import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { createIncidents as createIncidentsApi } from '../../api';
import { createIncident, listLatestIncidents } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(createIncident.request, function* workerCreateIncident({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { userId, incidents } = payload;
    try {
        yield call(createIncidentsApi, client, userId, incidents);
        yield put(createIncident.success(ident));
        yield put(listLatestIncidents.request({ userId }));
        yield put(flashMessages.addSuccess('Incident successfully created 🚕'));
    }
    catch (error) {
        yield put(createIncident.failure(ident, error));
        yield put(flashMessages.addError('Unable to create the new incident', 'There was an unknown issue creating the new incident', Cher.coerce(error)));
    }
});
