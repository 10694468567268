import styled from 'styled-components';

const HowItGoesGrid = styled.div`
	max-width: 280px;
	margin: 32px auto 20px auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: 16px;
	justify-items: stretch;

	> * {
		text-align: center;

	}

	> div > :nth-child(2) {
		margin-top: 10px;
	}
`;

export default HowItGoesGrid;
