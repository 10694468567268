export const initialPaymentState = {
    attachPaymentMethod: {},
    completeWebIntent: {},
    createWebIntent: {},
    listIntentsByUser: {},
    listPaymentMethods: {},
    userById: {},
    getMandates: {},
    updateMandate: {},
    createPayment: {},
};
export const PaymentActionTypes = {
    ATTACH_PAYMENT_METHOD: '@@platform/payment/ATTACH_PAYMENT_METHOD',
    COMPLETE_WEB_INTENT: '@@platform/payment/COMPLETE_WEB_INTENT',
    CREATE_WEB_INTENT: '@@platform/payment/CREATE_WEB_INTENT',
    INTENTS_BY_USER: '@@platform/payment/INTENTS_BY_USER',
    LIST_PAYMENT_METHODS: '@@platform/payment/LIST_PAYMENT_METHODS',
    USER_BY_ID: '@@platform/payment/USER_BY_ID',
    GET_MANDATES: '@@platform/payment/GET_MANDATES',
    UPDATE_MANDATE: '@@platform/payment/UPDATE_MANDATE',
    CREATE_PAYMENT: '@@platform/payment/CREATE_PAYMENT',
};
