import Cher from '@cuvva/cher';
import { all, call, getContext, put } from 'redux-saga/effects';
import { licenseByUserId, updateLicense } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(updateLicense.request, function* workerUpdateLicense({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId } = payload;
    try {
        yield call(api.updateLicense, client, userId, { dln: payload.dln, postcode: payload.postcode, issuingCountryCode: payload.issuingCountryCode });
        yield all([
            put(updateLicense.success(ident)),
            put(licenseByUserId.request({ userId })),
            put(flashMessages.addSuccess('Updated license')),
        ]);
    }
    catch (error) {
        yield put(updateLicense.failure(ident, error));
        const cher = Cher.coerce(error);
        switch (cher.code) {
            case 'no_license_registered':
                yield put(flashMessages.addDanger('No license registered', 'This user has no license registered to their account.'));
                break;
            case 'already_exists':
                yield put(flashMessages.addDanger('Already exists', 'That DLN is already attached to another account.'));
                break;
            case 'postcode_mismatch':
                yield put(flashMessages.addDanger('Postcode doesn\'t match', 'That postcode doesn\'t match what the DVLA has on record.'));
                break;
            case 'license_not_found':
                yield put(flashMessages.addDanger('License not valid', 'Pretty much what it says on the tin. Check the DLN again.'));
                break;
            case 'invalid_dln':
            case 'invalid_license':
                yield put(flashMessages.addDanger('License not valid', 'Double check that DLN and personal name, family name, sex and birth date are correct.'));
                break;
            case 'invalid_postcode':
                yield put(flashMessages.addDanger('Postcode not valid', 'The postcode doesn\'t seem to be valid.'));
                break;
            case 'invalid_input':
                yield put(flashMessages.addDanger('Invalid input', 'You shouldn\'t see this... Neither the postcode nor license number was sent to the server.'));
                break;
            default:
                yield put(flashMessages.addError('Unknown error', 'There was an unknown error while trying to update this user license. The error has been outputted below:', cher));
                break;
        }
    }
});
