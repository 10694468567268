import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { updateProfile } from '../actions';
import { ProfileActionTypes } from '../types';
export default function* watcherUpdateProfile() {
    yield takeEvery(ProfileActionTypes.UPDATE_PROFILE, workerUpdateProfile);
}
function* workerUpdateProfile({ payload, meta }) {
    const { ident } = meta;
    const { userId, requestId, ...requestPayload } = payload;
    const client = yield getContext('api');
    try {
        const response = yield call(api.updateProfile, client, userId, requestPayload);
        yield put(updateProfile.success(ident, response));
    }
    catch (error) {
        yield put(updateProfile.failure(ident, error));
    }
}
