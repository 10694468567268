import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { listProfiles } from '../actions';
import { ProfileActionTypes } from '../types';
export default function* watcherListProfiles() {
    yield takeEvery(ProfileActionTypes.LIST_PROFILES, workerListProfiles);
}
function* workerListProfiles({ payload, meta }) {
    const { ident } = meta;
    const { filters } = payload;
    const client = yield getContext('api');
    try {
        const response = yield call(api.listProfiles, client, filters);
        yield put(listProfiles.success(ident, response));
    }
    catch (error) {
        yield put(listProfiles.failure(ident, error));
    }
}
