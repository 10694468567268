import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import BillingInfoCard from '../atoms/BillingInfoCard';
import { BigCardWrapper } from '../atoms/Card';
import { useSinglePolicy } from '../context/SinglePolicyContext';
import { pastEndDate } from '../utils/formatDateTime';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { listTransactionsByPolicy } from '~lib/platform/ltm/store/actions';
import { formatValue } from '~website/features/quote/components/quote-page/formatters';
import useUser from '~website/hooks/use-user';

const PolicyBilling = () => {
	const dispatch = useDispatch();
	const { userId } = useUser();
	const { policyId, endDate } = useSinglePolicy();

	const ended = pastEndDate(endDate) ?? true;

	const transactionsStore = useSelector(s => s.platform.ltm.listTransactionsByPolicy?.[policyId]);
	const loading = useAsyncStateStatus(transactionsStore).status !== 'response';
	const transactions = transactionsStore?.response;

	useEffect(() => {
		if (userId && policyId) {
			dispatch(listTransactionsByPolicy.request({
				userId,
				policyId,
			}));
		}
	}, [dispatch, userId, policyId]);

	const grandTotal = useMemo(() => {
		if (!transactions) return 'N/A';

		const total = transactions.reduce((acc, curr) => {
			const totalPayable = (curr?.pricing.totalPayable ?? 0) as number;

			// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
			return acc + totalPayable;
		}, 0);

		return formatValue(total);
	}, [transactions]);

	return (
		<BillingWrapper>
			<Typography
				$marginBottom={'large'}
				$type={'Heading.Medium'}
				$color={'textOnSurfaceBackground'}
			>
				{'Billing'}
			</Typography>

			<BillingItemWrapper>

				<BillingInfoCard loading={loading} transactions={transactions} />

				<BillingTotalWrapper>
					<Typography
						$type={'Heading.XSmall'}
						$color={'textOnSurfaceBackground'}
					>
						{'Grand total'}
					</Typography>
					<Typography
						$bold
						$type={'Body.Medium'}
						$color={'textOnSurfaceBackground'}
					>
						{grandTotal}
					</Typography>
				</BillingTotalWrapper>
			</BillingItemWrapper>
		</BillingWrapper>
	);
};

export default PolicyBilling;

const BillingWrapper = styled.div`
	a, a:hover, a:visited {
		text-decoration: none;
		color: ${p => p.theme.ui.textHighlight};
	}
`;

const BillingTotalWrapper = styled(BigCardWrapper)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.theme.spacing.large};

	@media (min-width: 628px) {
		padding: ${p => p.theme.spacing.extraLarge};
	}
`;

const BillingItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;
