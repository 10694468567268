import CuvvaClient from '@cuvva/cuvva-client';
import { call, getContext, select, take, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import { GetUserActionTypes } from '../types';
import IterableSDK from '~lib/frontend/api/iterable-sdk';
import { getAuthenticationToken, getDeviceConfig } from '~lib/platform/marketing-consent/store/api';
import {
	GetAuthenticationTokenPayload,
	GetAuthenticationTokenResponse,
	GetDeviceConfigPayload,
	GetDeviceConfigResponse,
} from '~lib/platform/marketing-consent/store/types';
import { UserConfigActionTypes } from '~lib/platform/user-config/store/types';
import { getLogger } from '~lib/shared/logger';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

export default function* watcherRegisterIterable() {
	yield takeEvery(UserConfigActionTypes.GET_CONFIG_BY_ID_SUCCESS, workerRegisterIterable);
}

function* workerRegisterIterable() {
	const client: IterableSDK = yield getContext('iterableClient');
	const cuvvaClient: CuvvaClient = yield getContext('api');

	const userState: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

	let userId;

	if (!userState || userState.fetching) {
		const userSaga: PayloadAction<string, string> = yield take(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS);

		userId = userSaga.payload;
	} else {
		userId = userState.response;
	}

	const authTokenPayload: GetAuthenticationTokenPayload = {
		identifier: userId,
		audience: 'iterable.com',
		integration: 'IT',
		expiry: 1200,
	};

	const deviceConfigPayload: GetDeviceConfigPayload = {
		integration: 'IT',
	};

	try {
		const tokenResponse: GetAuthenticationTokenResponse = yield call(
			getAuthenticationToken,
			cuvvaClient,
			authTokenPayload,
		);
		const deviceConfigReaponse: GetDeviceConfigResponse = yield call(
			getDeviceConfig,
			cuvvaClient,
			deviceConfigPayload,
		);

		const apiKey: string = deviceConfigReaponse.key;
		const token: string = tokenResponse.token;

		yield call([client, client.initializeIterable], userId, apiKey, token);
	} catch (error) {
		getLogger().warning('iterable setup failed', { error });
	}
}
