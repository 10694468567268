import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { lookupByPostcode } from '../store';

const usePostcodeLocationSearch = () => {
	const dispatch = useDispatch();
	const lookupByPostcodeStore = useSelector(s => s.internal.quote.lookupByPostcode);
	const [postcode, setPostcode] = useState('');
	const result = lookupByPostcodeStore[postcode];
	const postcodeFormatted = result?.response?.postcode;

	const onChange = useCallback((postcode: string) => {
		if (!postcode || !postcode.trim())
			return;

		setPostcode(postcode);
		dispatch(lookupByPostcode.actions.request({ postcode }));
	}, [dispatch]);

	return {
		onChange,
		result,
		postcodeFormatted,
	};
};

export default usePostcodeLocationSearch;
