import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import { formatYears, formatYearsMonths } from '~lib/frontend/helpers/format-years-months';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { abiCodes } from '~lib/platform/code-mapping/store/actions';
import { AbiCodesResponse } from '~lib/platform/code-mapping/types';
import { getAnnualMileageOptions } from '~lib/platform/ltm/helpers/annual-mileage';
import { Location, ProfileAddress } from '~lib/platform/ltm/types';
import { plainMoney } from '~website/helpers/monies';

export const formatIsoDate = (date: string, time = false) => {
	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) return 'Invalid date';

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		weekday: 'short',
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	};

	if (time) {
		options.hour = '2-digit';
		options.minute = '2-digit';
		options.hour12 = false;
	}

	return new Intl.DateTimeFormat('en-GB', options).format(parsedDate);
};

export const formatAddress = (address: ProfileAddress) => {
	const flattened = flatten([address.lines, address.locality, address.postalCode]);

	return flattened.filter(Boolean).join(', ');
};

export const formatCoords = (location: Location) => {
	if (!location)
		return 'N/A';

	return [location.latitude, location.longitude].map(v => v.toFixed(5)).join(', ');
};

export const formatBool = (val: boolean) => {
	if (typeof val === 'undefined')
		return '-';

	return val ? 'Yes' : 'No';
};

export const useFormatMileage = () => {
	const ltmConfig = useSelector(s => s.platform.ltm.config?.response);
	const options = getAnnualMileageOptions(ltmConfig?.vehicle?.annualMileage);

	return (mileage: number) => options.find(s => s.value === mileage.toString())?.label ?? `Up to ${mileage}`;
};

export const formatValue = (value: number | undefined) => {
	if (typeof value !== 'number')
		return 'N/A';

	return plainMoney(value);
};

export const formatNcb = (value: number | undefined) => {
	if (typeof value !== 'number')
		return '0 years';

	return formatYearsMonths(value, '0 years');
};

export const formatPolicyNcb = (value: number | undefined) => {
	if (typeof value !== 'number')
		return '0 years';

	return formatYears(value);
};

export const useAbiCodeFormatter = () => {
	const dispatch = useDispatch();
	const store = useSelector(s => s.platform.codeMapping.abiCodes);
	const status = useAsyncStateStatus(store).status;
	const formatter = useMemo(() => formatAbiCode.bind(null, store.response), [store]);

	useEffect(() => {
		if (status === 'pending')
			dispatch(abiCodes.request());
	}, [dispatch, status]);

	return formatter;
};

function formatAbiCode<
	A extends AbiCodesResponse,
	K extends keyof AbiCodesResponse,
>(
	abiCodes: A,
	key: K,
	code: string,
	field: 'name' | 'sentenceForm' = 'name',
): string {
	// @ts-ignore
	return abiCodes?.[key].find(s => s.code === code)[field];
}
