import React, { useContext, useRef } from 'react';
import Typography from '../Typography';
import { CardBodyContainer, CardBodyWrapper } from './atoms/CardBodyWrapper';
import CardContext from './context';
import useResizeObserver from '~lib/frontend/hooks/use-resize-observer';
const CardBody = ({ children, noPadding, border, separator }) => {
    const cardBody = useRef(null);
    const { open, collapsible, loading } = useContext(CardContext);
    useResizeObserver(cardBody);
    const height = cardBody?.current?.offsetHeight ?? 0;
    return (React.createElement(CardBodyContainer, { "$noPadding": noPadding, "$open": open, "$height": height, "$collapsible": collapsible, "$border": border, "$separator": separator },
        React.createElement(CardBodyWrapper, { ref: cardBody, "$noPadding": noPadding },
            loading && (React.createElement(Typography, { "$type": 'Body.Medium', "$color": 'textOnSurfaceBackground' }, 'Loading...')),
            !loading && children)));
};
export default CardBody;
