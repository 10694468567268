import { coloursMap } from './colors';
export const getBadgeColors = (type, muted, uiColors) => {
    const { backgroundMuted, textMuted, backgroundSolid, textSolid } = coloursMap[type];
    return {
        text: uiColors[muted ? textMuted : textSolid],
        background: uiColors[muted ? backgroundMuted : backgroundSolid],
    };
};
export const getPadding = (size, hasIcon) => {
    if (size === 'small' && !hasIcon)
        return '0 6px';
    if (size === 'small' && hasIcon)
        return '0 6px 0 5px';
    return '0 12px';
};
