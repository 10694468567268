import React from 'react';
import styled from 'styled-components';
const CuvvaLogo = ({ minimal, fill = 'blankFill' }) => (React.createElement(StyledSvg, { xmlns: 'http://www.w3.org/2000/svg', fill: 'none', viewBox: minimal ? '0 0 27 27' : '0 0 128 26', "$fill": fill, width: minimal ? 27 : 128 },
    React.createElement("path", { fill: '#fff', fillRule: 'evenodd', d: 'M0 13C0 5.82 5.844 0 13.053 0a13.08 13.08 0 019.23 3.808A12.973 12.973 0 0126.106 13c0 7.18-5.844 13-13.053 13C5.844 26 0 20.18 0 13zm4.367-.521a.497.497 0 00.504.52h16.365a.497.497 0 00.504-.52c-.27-4.544-4.056-8.146-8.687-8.146-4.63 0-8.416 3.602-8.686 8.146zM126.336 5.239h-3.152c-.326 0-.508.216-.544.54l-.181 1.802c-1.232-1.622-3.007-2.703-5.398-2.703-5 0-8.623 4.073-8.623 9.227 0 5.046 3.623 9.046 8.623 9.046 2.427 0 4.202-1.045 5.362-2.523l.217 1.73c.036.325.218.541.544.541h3.152c.326 0 .543-.216.543-.54V5.779c0-.324-.217-.54-.543-.54zm-8.732 13.587c-2.645 0-4.565-2.018-4.565-4.757 0-2.847 1.92-4.866 4.565-4.866 2.681 0 4.529 2.199 4.529 4.866 0 2.703-1.848 4.757-4.529 4.757z', clipRule: 'evenodd' }),
    !minimal && (React.createElement("path", { fill: '#fff', d: 'M67.75 5.239h-3.696c-.326 0-.543.216-.543.54v9.768c0 1.261-1.269 3.424-3.84 3.424-1.704 0-3.407-1.01-3.407-3.929V5.779c0-.324-.217-.54-.543-.54h-3.695c-.327 0-.544.216-.544.54v10.128c0 4.721 2.754 7.46 6.739 7.46 2.246 0 4.058-1.26 5.29-2.99l.145 1.982c.036.324.217.54.543.54h3.55c.327 0 .544-.216.544-.54V5.779c0-.324-.217-.54-.544-.54zM83.622 5.671L79.31 17.35 74.963 5.67c-.109-.288-.362-.432-.652-.432h-3.732c-.399 0-.58.216-.435.612l6.666 16.616c.11.288.363.432.653.432h3.695c.29 0 .544-.144.652-.432L88.44 5.85c.145-.396-.036-.612-.434-.612h-3.732c-.29 0-.543.144-.652.432zM99.421 17.349l4.312-11.678c.108-.288.362-.432.652-.432h3.731c.399 0 .58.216.435.612l-6.63 16.616c-.109.288-.362.432-.652.432h-3.696c-.29 0-.543-.144-.652-.432L90.255 5.85c-.145-.396.036-.612.435-.612h3.731c.29 0 .544.144.652.432l4.348 11.678zM47.16 8.49a.73.73 0 01-.525.262.792.792 0 01-.539-.204l-.002-.002-.001-.001c-1.153-1.01-2.571-1.634-4.324-1.634-3.314 0-5.935 2.617-5.935 6.162 0 3.517 2.62 6.134 5.935 6.134 1.753 0 3.171-.623 4.324-1.633l.001-.002.002-.001a.791.791 0 01.54-.204.73.73 0 01.525.262l1.89 2.022c.134.136.242.313.242.528 0 .218-.11.397-.248.533a10.187 10.187 0 01-7.247 2.984c-5.93 0-10.678-4.693-10.678-10.623 0-5.957 4.747-10.65 10.678-10.65 2.88-.001 5.407 1.152 7.247 2.983.137.136.248.315.248.533 0 .216-.108.393-.243.528L47.16 8.49z' }))));
const StyledSvg = styled.svg `
	path {
		fill: var(--fill, ${p => p.theme.ui[p.$fill]});
	}
`;
export default CuvvaLogo;
