import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import { SectionWrapper } from '../atoms/ReportingToWrapper';
import { TestimonialsList } from '../atoms/TestimonialWrapper';
import Testimonial from '../molecules/Testimonial';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import HiringOfferContext from '~website/features/hiring-offer/context';
import { listCuvvaPeeps } from '~website/store/about/actions';

const Testimonials = () => {
	const { testimonials } = useContext(HiringOfferContext);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	const staffStore = useSelector(s => s.platform.staff.listStaffPublic);
	const status = useAsyncStateStatus(staffStore);
	const staffStoreFailedToLoad = status.is('fetching', 'pending', 'error');

	if (!testimonials || staffStoreFailedToLoad) return null;

	return (
		<SectionWrapper>
			<SectionHeaderText>{'Here’s why we think you’d be great at Cuvva'}</SectionHeaderText>
			<TestimonialsList>
				{testimonials.map((testimonial, index) => {
					const staffName = staffStore.response?.find(staff => staff.id === testimonial.staffId)?.about.name;
					const name = staffName ? staffName : 'Someone';
					const staffRole = staffStore.response?.find(staff => staff.id === testimonial.staffId)?.about.role;
					const role = staffRole ? staffRole : 'Cuvva Team Member';
					const testifier = `${name}, ${role}`;

					return <Testimonial key={index} message={testimonial.testimonial} testifier={testifier} />;
				})}
			</TestimonialsList>
		</SectionWrapper>
	);
};

export default Testimonials;
