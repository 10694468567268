import React, { useContext } from 'react';
import moment from 'moment';

import { HeaderSharesWrapper, OfferStartAndSharesWrapper, RuleSeparator } from '../atoms/HiringOfferHeaderWrapper';
import HiringOfferContext from '../context';
import Typography from '~lib/frontend/design-system/components/Typography';

const HeaderShares = () => {
	const { salaryValue, shareOptionsCount, proposedStartDate, responseDeadline } = useContext(HiringOfferContext);
	const salary = salaryValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return (
		<HeaderSharesWrapper>
			<OfferStartAndSharesWrapper>
				<div>
					<Typography $type={'Heading.XSmall'}>{'Annual salary'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{`£${salary}`}
					</Typography>
				</div>
				<RuleSeparator />
				<div>
					<Typography $type={'Heading.XSmall'}>{'Share options'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{shareOptionsCount}
					</Typography>
				</div>
				<RuleSeparator />

				<div>
					<Typography $type={'Heading.XSmall'}>{'Suggested start date'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{proposedStartDate ? moment(proposedStartDate).format('ddd MMM Do, YYYY') : 'TBC'}
					</Typography>
				</div>
			</OfferStartAndSharesWrapper>
			<Typography $type={'Body.XLarge'}>
				{`Please let us know of your decision by ${moment(responseDeadline).format('ddd MMM Do, YYYY')}`}
			</Typography>
		</HeaderSharesWrapper>
	);
};

export default HeaderShares;
