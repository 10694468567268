import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { attachIdentifier } from '~lib/platform/auth/store/actions';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.submitRenewalReminder.request,
	function* submitRenewalReminderSaga(action) {
		const { userId, email, reminder } = action.payload;

		try {
			yield requestAndTake(
				attachIdentifier.request({
					userId,
					type: 'email',
					value: email,
					token: null,
				}),
			);

			yield put(actions.setRenewalDate.request(reminder));
		} catch (error) { /* */ }
	});
