import Cher from '@cuvva/cher';

import dateValidator from './valid-date';

const pastDateWithin5YearsValidator = (value: string) => {
	const { valid, day, month, year } = dateValidator(value);

	if (!valid)
		return new Cher('invalid');

	const futureDate = isFutureDate(day, month, year);

	if (futureDate)
		return new Cher('invalid');

	const now = new Date();
	const fiveYearsAgo = new Date();

	fiveYearsAgo.setFullYear(now.getFullYear() - 5);
	const date = new Date(year, month - 1, day); // month is 0-based

	if (date < fiveYearsAgo)
		return new Cher('too_old');

	return void 0;
};

function isFutureDate(day: number, month: number, year: number) {
	const date = new Date(year, month - 1, day); // month is 0-based
	const now = new Date();

	return date > now;
}

export default pastDateWithin5YearsValidator;
