import { call, getContext, put } from 'redux-saga/effects';
import { getMandates } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getMandates.request, function* getMandatesSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.getMandates, client, payload);
        yield put(getMandates.success(ident, response));
    }
    catch (error) {
        yield put(getMandates.failure(ident, error));
    }
});
