import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { trackEvent } from '../../analytics/store/actions';
import DotList from './DotList';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { GetterType } from '~lib/frontend/types/content';
import { reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';
import { useMobileOneLink } from '~website/hooks/use-mobile-one-link';

const NextSteps: React.FC = () => {
	const { isMobile, isLoading } = useMobileDetect();
	const g = useContentKey('website_core');
	const dispatch = useDispatch();
	const get: GetterType = (key, fallback) => g(`pages[download].${key}`, fallback);
	const queCodeAssetName = get('qr_code', 'quote/branch-qr-code.png');
	const quoteQRCode = useContentAsset('website_core', queCodeAssetName);
	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const oneLink = useMobileOneLink();
	const oneLinkWithParams = typeof location === 'undefined' ? oneLink : reapplyParamsIfExternalUrl(oneLink, sourceTracking, location.pathname);

	const steps = {
		mobile: ['Download the Cuvva app', 'Create an account', 'Tap the link in your friend\'s message'],
		desktop: ['Scan the QR code', 'Create an account', 'Tap the link in your friend\'s message on your smartphone'],
	};

	return (
		<Card>
			<Typography
				$type={'Heading.Medium'}
				$color={'textOnSurfaceBackground'}
				$bold
				$marginBottom={'10px'}
				$align={'center'}
			>
				{'Next steps'}
			</Typography>

			{isLoading && <Loader />}

			{!isLoading && (
				<>
					{/* if on desktop - show qr code */}
					{!isMobile && <QRCodeImage src={quoteQRCode} alt={'QR code download the Cuvva app'} />}

					<Margin $marginTop={'semiLarge'} $marginBottom={'semiLarge'}>
						<DotList items={isMobile ? steps.mobile : steps.desktop} />
					</Margin>

					{/* if om mobile - show button */}
					{isMobile && <div onClick={() => {
						dispatch(trackEvent({
							eventName: 'vehicleSharing_download_actioned',
							eventPayload: {},
						}));
					}}>
						<Button as={Anchor}
							// @ts-ignore
							href={oneLinkWithParams}
						>
							{'Download Cuvva'}
						</Button>
					</div>
					}
				</>
			)}

		</Card>
	);
};

const Loader: React.FC = () => (
	<>
		<Margin $marginTop={'regular'}></Margin>
		<SkeletonLoader />
		<Margin $marginBottom={'regular'}></Margin>
		<SkeletonLoader />
		<Margin $marginBottom={'regular'}></Margin>
		<SkeletonLoader />
	</>
);

const Card = styled.div`
	padding: ${p => p.theme.spacing.semiLarge} ${p => p.theme.spacing.semiLarge};
	text-align: left;
	background: ${p => p.theme.ui.surface};
	box-shadow: 0px 4px 16px 0px #0000001F;
	border-radius: ${p => p.theme.borderRadius.large};
`;

const QRCodeImage = styled.img`
	margin: 0 auto;
	display: block;
	padding: ${p => p.theme.spacing.large};
	width: 136px;
	height: 136px;
	background: ${p => p.theme.ui.blankFill};
	border-radius: ${p => p.theme.borderRadius.regular};
`;

export default NextSteps;
