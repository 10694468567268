import { PaymentActionTypes, } from './types';
import { createAsyncMapAction } from '~lib/shared/redux/actions';
export const attachPaymentMethod = createAsyncMapAction(PaymentActionTypes.ATTACH_PAYMENT_METHOD, p => [p.userId, p.stpPaymentMethodId].join(':'));
export const completeWebIntent = createAsyncMapAction(PaymentActionTypes.COMPLETE_WEB_INTENT, p => [p.userId, p.payableId].join(':'));
export const createWebIntent = createAsyncMapAction(PaymentActionTypes.CREATE_WEB_INTENT, p => [p.userId, p.payableId].join(':'));
export const listIntentsByUser = createAsyncMapAction(PaymentActionTypes.INTENTS_BY_USER, p => p.userId);
export const listPaymentMethods = createAsyncMapAction(PaymentActionTypes.LIST_PAYMENT_METHODS, p => p.userId);
export const userById = createAsyncMapAction(PaymentActionTypes.USER_BY_ID, p => p.userId);
export const getMandates = createAsyncMapAction(PaymentActionTypes.GET_MANDATES, p => p.userId);
export const updateMandate = createAsyncMapAction(PaymentActionTypes.UPDATE_MANDATE, p => p.requestId);
export const createPayment = createAsyncMapAction(PaymentActionTypes.CREATE_PAYMENT, p => p.requestId);
