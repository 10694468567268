import { initialAsyncState } from '~lib/shared/redux/types/state';
export const initialKycState = {
    decideResolution: {},
    latestKyc: {},
    latestKycOps: {},
    preparePhotoSubmission: {},
    submitPhotos: { ...initialAsyncState },
    revokePhotos: { ...initialAsyncState },
    uploadKycDocs: {},
};
export const KycActionTypes = {
    DECIDE_RESOLUTION: '@@platform/kyc/DECIDE_RESOLUTION',
    GET_LATEST_KYC: '@@platform/kyc/GET_LATEST_KYC',
    GET_LATEST_KYC_FAILURE: '@@platform/kyc/GET_LATEST_KYC_FAILURE',
    GET_LATEST_KYC_SUCCESS: '@@platform/kyc/GET_LATEST_KYC_SUCCESS',
    GET_LATEST_KYC_OPS: '@@platform/kyc/GET_LATEST_KYC_OPS',
    PREPARE_PHOTO_SUBMISSION: '@@platform/kyc/PREPARE_PHOTO_SUBMISSION',
    PREPARE_PHOTO_SUBMISSION_SUCCESS: '@@platform/kyc/PREPARE_PHOTO_SUBMISSION_SUCCESS',
    PREPARE_PHOTO_SUBMISSION_FAILURE: '@@platform/kyc/PREPARE_PHOTO_SUBMISSION_FAILURE',
    SUBMIT_PHOTOS: '@@platform/kyc/SUBMIT_PHOTOS',
    SUBMIT_PHOTOS_SUCCESS: '@@platform/kyc/SUBMIT_PHOTOS_SUCCESS',
    SUBMIT_PHOTOS_FAILURE: '@@platform/kyc/SUBMIT_PHOTOS_FAILURE',
    REVOKE_PHOTOS: '@@platform/kyc/REVOKE_PHOTOS',
    REVOKE_PHOTOS_FAILURE: '@@platform/kyc/REVOKE_PHOTOS_FAILURE',
    REVOKE_PHOTOS_SUCCESS: '@@platform/kyc/REVOKE_PHOTOS_SUCCESS',
    UPLOAD_KYC_DOCS: '@@platform/kyc/UPLOAD_KYC_DOCS',
    UPLOAD_KYC_DOCS_FAILURE: '@@platform/kyc/UPLOAD_KYC_DOCS_FAILURE',
    UPLOAD_KYC_DOCS_SUCCESS: '@@platform/kyc/UPLOAD_KYC_DOCS_SUCCESS',
};
