import styled from 'styled-components';

const TrustpilotContainer = styled.div`
	margin: 0 auto;
	padding: ${p => p.theme.spacing.extraLarge};
	z-index: 2;
	max-width: 368px;
	color: ${p => p.theme.ui.textOnFill};

	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: ${p => p.theme.spacing.extraLarge};

	& > a {
		padding: 0;
	}
`;

export default TrustpilotContainer;
