import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listSurveysByUserId } from '../actions';
import * as api from '../api';
import { SurveyActionTypes } from '../types';
export default function* watcherListSurveysByUserId() {
    yield takeEvery(SurveyActionTypes.LIST_SURVEYS_BY_USER_ID, workerListSurveysByUserId);
}
function* workerListSurveysByUserId({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.listSurveys, client, payload.userId);
        yield put(listSurveysByUserId.success(ident, response));
    }
    catch (error) {
        yield put(listSurveysByUserId.failure(ident, error));
    }
}
