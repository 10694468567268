import styled from 'styled-components';
import Typography from '~lib/frontend/design-system/components/Typography';
const InputPrefilled = styled.div `
	position: relative;

	${Typography} {
		position: absolute;
		pointer-events: none;
		display: flex;
		align-items: center;
		top: 1px;
		left: 1px;
		bottom: 1px;
		padding-left: ${p => p.theme.spacing.regular};
		padding-right: ${p => p.theme.spacing.small};
	}

	input {
		padding-left: ${p => p.$pad}px;
	}
`;
export default InputPrefilled;
