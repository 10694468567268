import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormWrapper } from '../atoms/alignment';
import Button from '~lib/frontend/design-system/components/button/Button';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import { trackEvent } from '~website/features/analytics/store/actions';
import { generateReferralLink } from '~website/features/referral/store/actions';

const ReferAFriend = () => {
	const dispatch = useDispatch();
	const link = useSelector(s => s.internal.referral.generateReferralLink);
	const showLink = Boolean(link?.response?.referralLink);

	const getShareableLink = () => {
		dispatch(generateReferralLink.request());

		dispatch(trackEvent({
			eventName: 'friends_refer_a_friend_actioned',
			eventPayload: {
				product: 'none',
				platform: 'website',
			},
		}));
	};

	return (
		<React.Fragment>
			{showLink ? (
				<ReferAFriendLink />
			) : (
				<Button
					$stretch
					$size={'large'}
					type={'button'}
					onClick={getShareableLink}
				>
					{'Get my shareable link'}
				</Button>
			)}
		</React.Fragment>
	);
};

export default ReferAFriend;

const ReferAFriendLink = () => {
	const dispatch = useDispatch();
	const [copied, setCopied] = useState(false);
	const link = useSelector(s => s.internal.referral.generateReferralLink);
	const [value] = useState(() => link?.response?.referralLink ?? void 0);

	const copyLink = () => {
		if (link?.response?.referralLink) {
			navigator.clipboard.writeText(link.response.referralLink);

			dispatch(trackEvent({
				eventName: 'refer_friend_share_link_actioned',
				eventPayload: {
					product: 'none',
					platform: 'website',
				},
			}));

			setCopied(true);

			setTimeout(() => {
				setCopied(false);
			}, 3000);

			return;
		}
	};

	const getButtonText = () => {
		if (link?.response?.referralLink && copied)
			return 'Copied';

		return 'Copy link';
	};

	return (
		<FormWrapper>
			<TextInput readOnly value={value ?? ''} />

			<Button
				$stretch
				$size={'large'}
				type={'button'}
				onClick={copyLink}
			>
				{getButtonText()}
			</Button>
		</FormWrapper>
	);
};
