import { call, getContext, put } from 'redux-saga/effects';
import { deleteHiringOffer, getHiringOffersList } from '../actions';
import * as api from '../api';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(deleteHiringOffer.request, function* deleteOfferSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        yield call(api.deleteHiringOffer, client, payload);
        yield put(deleteHiringOffer.success(ident));
        yield put(getHiringOffersList.request());
    }
    catch (error) {
        yield put(deleteHiringOffer.failure(ident, error));
    }
});
