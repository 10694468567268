import Cher from '@cuvva/cher';
export const bucketCount = 65536;
const maxBps = 10000;
/*
    This code is a 1-2-1 replica of the @cuvva/ab and @cuvva/split-test packages, however they are modified to work
    inside the browser, as the browser has it's own Crypto libraries and they also require the usage of async/await.
*/
export async function check(testId, subjectId, maxBucketIndex) {
    if (maxBucketIndex < 0 || maxBucketIndex > bucketCount)
        throw new Error('maxBucketIndex must be a uint16');
    const encoder = new TextEncoder();
    const buf = encoder.encode(`${testId}${subjectId}`);
    const arrayBuffer = await window.crypto.subtle.digest('SHA-1', buf);
    const hash = new Uint8Array(arrayBuffer);
    // eslint-disable-next-line no-bitwise
    const bucket = (hash[6] << 8) + hash[7];
    return bucket <= maxBucketIndex;
}
/**
 * SplitTest also referred as "A/B test" encapsulates logic that randomly split users into
 * different sized groups to help making informed decisions based on the allocation
 *
 * The "ab" library is used to determine the userId group for a specific testId
 *
 * Attributes of a test:
 * - testId: identifier used to randomly allocate users into a group
 * - groups: buckets consisting of a size of the group (in basis points) and a value
 */
export default class SplitTest {
    testId;
    groups;
    constructor(testId, groups) {
        this.testId = testId;
        this.groups = groups;
        this.validate();
    }
    async evaluate(userId) {
        let maxBucketIndex = 0;
        for (const g of this.groups) {
            maxBucketIndex += Math.floor(((bucketCount - 1) * g.size) / maxBps);
            // eslint-disable-next-line no-await-in-loop
            if (await check(this.testId, userId, maxBucketIndex))
                return g.value;
        }
        throw new Cher('impossible_case', { testId: this.testId });
    }
    validate() {
        if (!this.testId)
            throw new Cher('missing_test_id');
        if (!this.groups || !this.groups.length)
            throw new Cher('missing_groups', { testId: this.testId });
        if (this.groups.length <= 1)
            throw new Cher('invalid_groups_length', { testId: this.testId });
        if (this.groups.some(g => !g.value))
            throw new Cher('invalid_group_value', { testId: this.testId });
        const sizeTotal = this.groups.reduce((a, b) => a + b.size, 0);
        if (sizeTotal !== maxBps)
            throw new Cher('invalid_total_size', { testId: this.testId });
    }
}
