import React from 'react';

import useLockedDetails from '../../hooks/use-locked-details';
import EmailAddress from './EmailAddress';
import MarketingConsent from './MarketingConsent';

const EmailAddressWrapper = () => {
	// SMU - we need a way to display the marketing content once,
	// if locked is true, then we have passed through this stage and hide
	const locked = useLockedDetails();

	return (
		<div>
			<EmailAddress />
			{!locked && <MarketingConsent />}
		</div>
	);
};

export default EmailAddressWrapper;
