export const assetTypes = [
    'image',
    'photo',
    'illustration',
    'icon',
    'background',
    'video',
    'mask',
    'share',
    'manufacturer',
    'payment',
];
