import React from 'react';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon, { IconType } from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

const checkmark = 'ic_check' as unknown as IconType;

const VerifyListItems = ({ items, pre = 'This should be:' }: { items: string[]; pre?: string }) => (
	<React.Fragment>
		<Margin $marginTop={'extraLarge'} $marginBottom={'large'}>
			<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
				{pre}
			</Typography>
		</Margin>

		<VerificationListWrapper>
			{items.map((item, idx) => (
				<li key={idx}>
					<Icon
						icon={checkmark}
						$size={'20px'}
						$color={'primaryFill'}
						$marginRight={'small'}
						$marginTop={'extraSmall'}
					/>

					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{item}
					</Typography>
				</li>
			))}
		</VerificationListWrapper>
	</React.Fragment>
);

const VerificationListWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	li {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}
`;

export default VerifyListItems;
