import Cher from '@cuvva/cher';
import { all, call, getContext, put } from 'redux-saga/effects';
import { licenseByUserId, removeLicense } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(removeLicense.request, function* workerRemoveLicense({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId } = payload;
    try {
        yield call(api.removeLicense, client, userId);
        yield all([
            put(removeLicense.success(ident)),
            put(licenseByUserId.request({ userId })),
            put(flashMessages.addSuccess('Removed license')),
        ]);
    }
    catch (error) {
        yield put(removeLicense.failure(ident, error));
        const cher = Cher.coerce(error);
        switch (cher.code) {
            case 'policies_purchased':
                yield put(flashMessages.addDanger('Policies purchased', 'You can\'t remove the license from an account that has purchased.'));
                break;
            case 'no_license_registered':
                yield put(flashMessages.addDanger('No license registered', 'This user has no license registered to their account.'));
                break;
            default:
                yield put(flashMessages.addError('Unknown error', 'There was an unknown error while trying to remove this license. The error has been outputted below:', cher));
                break;
        }
    }
});
