import { getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(actions.getSharedQuote.request, function* getSharedQuoteSaga(action) {
    const { key } = action.payload;
    const { ident } = action.meta;
    const client = yield getContext('api');
    try {
        const response = yield api.getSharedQuote(client, {
            key,
        });
        yield put(actions.getSharedQuote.success(ident, response));
    }
    catch (error) {
        yield put(actions.getSharedQuote.failure(ident, error));
    }
});
