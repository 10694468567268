import { call, getContext, put } from 'redux-saga/effects';
import { geoCodes } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(geoCodes.request, function* workerGeoCodes() {
    const client = yield getContext('api');
    try {
        const geoCodesResponse = yield call(api.getGeoCodes, client);
        yield put(geoCodes.success(geoCodesResponse));
    }
    catch (error) {
        yield put(geoCodes.failure(error));
    }
});
