import React from 'react';
import styled from 'styled-components';

import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { Anchor } from '~lib/frontend/atoms';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import { GetterType } from '~lib/frontend/types/content';

export interface LinkItem {
	title: string;
	url?: string;
}

export interface TitleSegmentLinkStripsProps extends TitleSegmentProps {
	links: LinkItem[];
}

const TitleSegmentLinkStrips: React.FCWithChildren<TitleSegmentLinkStripsProps> = props => {
	const { centered, links, ...titleSegmentProps } = props;

	return (
		<TitleSegment {...titleSegmentProps} centered={centered}>
			<Wrapper $centered={centered}>
				{links.map((s, i) => s.url ? (
					<Anchor href={s.url} key={`${i}-${s.title}`}>
						<Badge muted type={'primary'} size={'medium'}>
							{s.title}
						</Badge>
					</Anchor>
				) : (
					<Badge key={`${i}-${s.title}`} muted type={'primary'} size={'medium'}>
						{s.title}
					</Badge>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentLinkStripsProps => {
	const links = get<LinkItem[]>('links') || [];
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		// @ts-ignore
		links,
		...titleSegment,
	};
};

const Wrapper = styled.div<{ $centered: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 800px;
	gap: ${p => p.theme.spacing.extraLarge};
`;

export default TitleSegmentLinkStrips;
