import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { removeIncident as removeIncidentApi } from '../../api';
import { listLatestIncidents, removeIncident } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(removeIncident.request, function* workerRemoveIncident({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { userId, incidentId } = payload;
    try {
        yield call(removeIncidentApi, client, userId, incidentId);
        yield put(removeIncident.success(ident));
        yield put(listLatestIncidents.request({ userId }));
        yield put(flashMessages.addSuccess('Incident successfully removed 🚨'));
    }
    catch (error) {
        yield put(removeIncident.failure(ident, error));
        yield put(flashMessages.addError('Unable to remove incident', 'There was an unknown issue removing the incident', Cher.coerce(error)));
    }
});
