import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

export const IllustrationContainer = styled.div`
	img {
		width: 130px;
		height: 130px;
		margin: auto;
	}
	margin-top: 40px;
	${media.greaterThan('tablet')`
		margin-top: 73px;
	`}
`;

export const Container = styled.div`
	max-width: 1080px;
	padding: 0 ${p => p.theme.spacing.large};
	margin: 0 auto;

	display: flex;
	justify-content: center;
`;

export const TitleContainer = styled.div`
	max-width: 540px;
`;

export const ContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Guides = styled.div`
	width: 340px;
	margin-bottom: 62px;
	${media.greaterThan('tablet')`
		width: 370px;
		margin-bottom: 80px;
	`}
`;
