import React from 'react';
import styled from 'styled-components';

import { formatIsoDate } from '../formatters';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';

interface PolicyPurchaseOverviewProps {
	vrm: string;
	startDate: string;
}

const PolicyPurchaseOverview: React.FCWithChildren<PolicyPurchaseOverviewProps> = ({ vrm, startDate }) => (
	<Wrap>
		{[
			['Reg plate', vrm],
			['Starting', startDate && formatIsoDate(startDate)],
			['Ending', 'Monthly rolling cover'],
		].map(([key, value]) => (
			<React.Fragment key={key}>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{key}
				</Typography>

				{value && (
					<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
						{value}
					</Typography>
				)}

				{!value && (
					<LoaderWrap>
						<SkeletonLoader width={'50%'} />
					</LoaderWrap>
				)}
			</React.Fragment>
		))}
	</Wrap>
);

const Wrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-auto-flow: row;
	gap: ${p => p.theme.spacing.semiLarge};

	& > :nth-child(even) {
		text-align: right;
		justify-content: end;
	}

	@media screen and (min-width: 1024px) {
		gap: ${p => p.theme.spacing.small};

		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-auto-flow: column;

		& > :nth-child(even) {
			text-align: left;
			justify-content: start;
		}
	}
`;

const LoaderWrap = styled.div`
	display: flex;
	align-items: flex-end;
`;

export default PolicyPurchaseOverview;
