import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { runLtmQuote } from '.';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { initialAsyncState } from '~lib/shared/redux/types/state';

const generateRequestId = () => ksuid.generate('request').toString();

export const useLatestLtmQuote = () => {
	const dispatch = useDispatch();
	const [key, setKey] = useState('');

	const stateSelected = useSelector(s => s.internal.quote.quote.ltm[key]);
	const state: typeof stateSelected = useMemo(() => stateSelected ?? initialAsyncState, [stateSelected]);

	const quoteSelected = useSelector(s => s.platform.ltm.createQuote[state.response?.quoteRequestId]);
	const quote: typeof quoteSelected = useMemo(() => quoteSelected ?? initialAsyncState, [quoteSelected]);

	const asyncStatus = useAsyncStateStatus(quoteSelected).status;

	const requestQuote = useCallback(() => {
		const requestId = generateRequestId();

		setKey(requestId);
		dispatch(runLtmQuote.actions.request({ requestId }));
	}, [dispatch]);

	return {
		state,
		quote,
		asyncStatus,
		requestQuote,
	};
};
