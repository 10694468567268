import { call, getContext, put } from 'redux-saga/effects';
import { confirmChangeRequest } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(confirmChangeRequest.request, function* workerConfirmChangeRequest({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.confirmChangeRequest, client, {
            changeRequestId: payload.changeRequestId,
            confirmedBy: payload.confirmedBy,
            products: payload.products,
        });
        yield put(confirmChangeRequest.success(ident, response));
    }
    catch (error) {
        yield put(confirmChangeRequest.failure(ident, error));
    }
});
