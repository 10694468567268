import React from 'react';

import { TestimonialWrapper } from '../atoms/TestimonialWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';

interface TestimonialProps {
	message: string;
	testifier: string;
}

const Testimonial: React.FCWithChildren<TestimonialProps> = ({ message, testifier }) => (
	<TestimonialWrapper>
		<Typography $type={'Body.XLarge'} $color={'textOnSurfaceBackground'}>
			{`"${message}"`}
		</Typography>
		<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
			{testifier}
		</Typography>
	</TestimonialWrapper>
);

export default Testimonial;
