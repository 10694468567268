import { call, getContext, put } from 'redux-saga/effects';
import { createPayment } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(createPayment.request, function* createPaymentSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...restPayload } = payload;
    try {
        const response = yield call(api.createPayment, client, restPayload);
        yield put(createPayment.success(ident, response));
    }
    catch (error) {
        yield put(createPayment.failure(ident, error));
    }
});
