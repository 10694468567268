import React from 'react';

import { Endorsement as EndorsementType } from '../types';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';

interface EndorsementProps {
	endorsement: EndorsementType;
}

const Endorsement: React.FCWithChildren<EndorsementProps> = ({ endorsement }) => {
	const image = useContentAsset('website_core', endorsement.image);

	return (
		<img key={endorsement.image} src={image} />
	);
};

export default Endorsement;
