import React from 'react';

import { AdapterProps } from '../types';
import TrustpilotReviewSegment from '~website/components/atoms/TrustpilotReviewSegment';

const adapter: React.FCWithChildren<AdapterProps> = () => (
	<TrustpilotReviewSegment />
);

export default {
	type: 'trustpilot_review_segment',
	adapter,
	builder: {
		fields: {
		},
		default: {
		},
	},
};
