import React, { useCallback, useEffect, useRef } from 'react';
import TabComponent from '../atoms/tab';
import { useTabNavigationContext } from './TabList';
import { useTabsContext } from './Tabs';
const Tab = props => {
    const { children, active, disabled, ...extraProps } = props;
    const defaultRef = useRef();
    const { background } = useTabsContext();
    const { setLeft, setWidth } = useTabNavigationContext();
    const setRef = (ref) => {
        defaultRef.current = ref;
        if (active)
            updateContext();
    };
    const updateContext = useCallback(() => {
        if (!defaultRef.current)
            return;
        const rects = defaultRef.current.getBoundingClientRect();
        const parent = defaultRef.current.parentElement.getBoundingClientRect();
        setLeft(Math.round(rects.left - parent.left));
        setWidth(Math.round(rects.width));
    }, [setLeft, setWidth]);
    useEffect(() => {
        if (active)
            updateContext();
    }, [active, updateContext]);
    return (React.createElement(TabComponent, { ...extraProps, "$background": background, ref: setRef, disabled: disabled, "$active": active }, children));
};
export default Tab;
