import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { errorCodes } from '~lib/platform/code-mapping/store/actions';
import { trackPaymentErrorEvent } from '~website/features/analytics/store/actions';
import { PaymentErrorEventPayload } from '~website/features/analytics/types';

const useErrorMapper = () => {
	const dispatch = useDispatch();
	const errorCodesStore = useSelector(s => s.platform.codeMapping.errorCodes);
	const errorCodesStatus = useAsyncStateStatus(errorCodesStore).status;

	useEffect(() => {
		if (errorCodesStatus === 'pending')
			dispatch(errorCodes.request(void 0));
	}, [dispatch, errorCodesStatus]);

	const getMappedError = useCallback((error: Cher) => {
		const errorCode = error?.code;

		return errorCodesStore?.response?.find(c => c.code === errorCode)?.message ?? errorCode;
	}, [errorCodesStore]);

	const trackError = useCallback((action: string, error?: string, message?: string, declineCode?: string): void => {
		const payload: PaymentErrorEventPayload = {
			action,
			code: error,
			message,
			declineCode,
		};

		dispatch(trackPaymentErrorEvent(payload));
	}, [dispatch]);

	return { getMappedError, trackError };
};

export default useErrorMapper;
