import React, { Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import IncidentCategory from './IncidentCategory';
import IncidentDate from './IncidentDate';
import IncidentFault from './IncidentFault';
import IncidentInjuries from './IncidentInjuries';
import IncidentTheftOf from './IncidentTheftOf';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { PageCtx } from '~website/features/quote/contexts/PageCtx';
import { useIncidentItemFieldError, useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import { stepActions } from '~website/features/quote/store/actions';

interface IncidentCardProps {
	index: number;
	onSave: () => void;
	onCancel: () => void;
}

const IncidentEditCard: React.FCWithChildren<IncidentCardProps> = ({ index, onSave, onCancel }) => {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const internalChangeRequestId = useSelector(s => s.internal.quote.pages[pageId].internalChangeRequestId);

	const { value: category, validation: categoryErr } = useIncidentItemInternalChangeRequest(index, 'category');
	const dateErr = useIncidentItemFieldError(index, 'date');
	const atFaultErr = useIncidentItemFieldError(index, 'atFault');
	const bodilyInjuryErr = useIncidentItemFieldError(index, 'bodilyInjury');
	const costErr = useIncidentItemFieldError(index, 'cost');
	const theftOfErr = useIncidentItemFieldError(index, 'theftOf');

	const canSave = (() => {
		// can save if all the validation errors are falsy
		const canSaveChecks = [categoryErr, dateErr, costErr];

		if (category === 'accident')
			canSaveChecks.push(atFaultErr, bodilyInjuryErr);
		if (category === 'theft')
			canSaveChecks.push(theftOfErr);

		return canSaveChecks.every(v => !v);
	})();

	const removeAndClose = () => {
		onCancel();
		dispatch(stepActions.deleteIncident({ index, internalChangeRequestId }));
	};

	return (
		<BigCard>
			<Typography
				$type={'Heading.Small'}
				$color={'textOnSurfaceBackground'}
			>
				{'Incident details'}
			</Typography>

			<IncidentCategory index={index} />
			<IncidentDate index={index} />

			{category === 'accident' && (
				<Fragment>
					<IncidentFault index={index} />
					<IncidentInjuries index={index} />
				</Fragment>
			)}

			{category === 'theft' && <IncidentTheftOf index={index} />}

			<AlignActionsRight>
				<Button $type={'neutralMuted'} $size={'medium'} onClick={removeAndClose}>
					{'Delete'}
				</Button>

				<Button
					$type={'primary'}
					$size={'medium'}
					disabled={!canSave}
					onClick={canSave ? () => onSave() : void 0}
				>
					{'Save'}
				</Button>
			</AlignActionsRight>
		</BigCard>
	);
};

const BigCard = styled.div`
	padding: 32px 24px;
	background: ${p => p.theme.ui.surface};
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	border-radius: ${p => p.theme.borderRadius.large};

	display: grid;
	grid-template-columns: 1fr;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const AlignActionsRight = styled.div`
	display: block;
	text-align: right;

	&>* {
		display: inline;
		margin-left: ${p => p.theme.spacing.regular};
	}
`;

export default IncidentEditCard;
