import { delay, put, select } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import * as actions from '../actions';
import handleGTM from './handle-gtm';
import sendToIterable from './handle-iterable';
import sendToMixpanel from './handle-mixpanel';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.trackScreenView,
	function* screenView({ payload }) {
		const { eventName, eventPayload, intercomScreenViewName } = payload;
		const user: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

		const intercomScreenViewEvent = intercomScreenViewName ?? 'screen_viewed';

		if (!window?.Intercom) return;

		window.Intercom('trackEvent', intercomScreenViewEvent, eventPayload);

		yield put(
			analyticsTrackingDebug({
				destination: 'intercom',
				payload: {
					event: 'screen_viewed',
					payload: eventPayload,
				},
			}),
		);

		// delay to attach mixpanel to window
		if (typeof window === 'undefined' || typeof window.mixpanel === 'undefined') yield delay(5000);

		const iterableEventPayload = {
			eventName,
			userId: user?.response,
		};

		yield sendToMixpanel(eventName, eventPayload);
		yield sendToIterable(iterableEventPayload);

		const eventProduct = eventPayload?.product ?? 'none';

		const eventValue = {
			properties: { screen: eventPayload?.screen_name },
			product: eventProduct,
			action: 'screenView',
			screen: eventPayload?.screen_name,
		};

		yield handleGTM({
			eventName: 'screenView',
			eventValue,
			...(eventPayload?.product && { eventCategory: `Get an estimate ${eventPayload?.product}` }),
		});
	});
