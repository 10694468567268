import React from 'react';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import { BigCardWrapper } from './Card';
import InfoCard from './InfoCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import { formatBool, formatPolicyNcb, formatValue } from '~website/features/quote/components/quote-page/formatters';

const PolicyInfo = () => {
	const { reference, subInfo } = useSinglePolicy();

	return (
		<BigCardWrapper>
			<Typography $display={'inline'} $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
				{'Your cover'}
			</Typography>

			<Margin $marginTop={'small'}>
				<InfoCard
					items={[
						['Policy number', reference],
						['Type of cover', 'Fully comprehensive'],
						['Smart Pricing', formatBool(subInfo.details.smartPricing)],
						['Total excess', formatValue(subInfo.details.excess)],
						['Business use', formatBool(subInfo.details.useClass === 'class1')],
						['No-claims bonus', formatPolicyNcb(subInfo.details.ncbYears)],
					]}
				/>
			</Margin>
		</BigCardWrapper>
	);
};

export default PolicyInfo;
