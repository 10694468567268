import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSegment } from '~lib/platform/content/store/actions';
import { emptySegmentState } from '~lib/platform/content/types';
/**
 * Transparently handles returning a segment value, and fetching if it hasn't been already
 * returning a fallback with an empty segment.
 *
 * This shouldn't be used on its own, as we can't track what is being read from it or not.
 * Use some of the other sibling helper hooks.
 */
export default function useSegment(segmentName) {
    const segments = useSelector((state) => state.platform.content.segments);
    const dispatch = useDispatch();
    const segment = segments[segmentName];
    // Handles the self fetching of the segment on first request
    useEffect(() => {
        if (segment?.fetching || segment?.error || segment?.response)
            return;
        dispatch(getSegment.request({
            schemaVersion: '1.0.0',
            segment: segmentName,
        }));
    }, [dispatch, segment, segmentName]);
    return segment?.response ?? emptySegmentState;
}
