import React from 'react';

import { MeCardWrapper } from '../atoms/Card';
import { PolicyWrapper } from '../atoms/PreviousPolicy';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';

const SubsLoading = () => (
	<MeCardWrapper>
		<Margin $marginTop={'large'}>
			<SkeletonLoader width={'15%'} />

			<PolicyWrapper>
				{Array.from({ length: 6 }).map((_key, index) => (
					<React.Fragment key={index}>
						<SkeletonLoader height={'14px'} width={'20%'} />
						<SkeletonLoader height={'14px'} width={'10%'} />
					</React.Fragment>
				))}
			</PolicyWrapper>
		</Margin>
	</MeCardWrapper>
);

export default SubsLoading;
