import React, { useContext } from 'react';

import * as adapters from './adapters';
import IdContext from './id-context';
import { AdapterProps, SectionMeta } from './types';
import { Theme } from '~lib/frontend/design-system/types';
import { useDesignSystemAssets } from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import { TypedObject } from '~lib/shared/helpers/typed';
import { FadeVector, FadeWrap } from '~website/components/atoms/FadeVector';
import SectionSegment from '~website/components/atoms/SectionSegment';
import HeaderHero, { contentAdapter as headerContentAdapter } from '~website/components/Hero/HeaderHero';
import Footer from '~website/components/layout/footer';
import Meta from '~website/components/Meta';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import VisionaryContext from '~website/contexts/VisionaryContext';

export interface SectionDefinition {
	type: string;
	meta: SectionMeta;
	content: unknown;
}

type AdapterMap = Record<string, React.FCWithChildren<AdapterProps>>;

const adaptersMap: AdapterMap = TypedObject.keys(adapters).reduce((accumulator, currentValue) => {
	const adapter = adapters[currentValue];

	return {
		[adapter.type]: adapter.adapter,
		...accumulator,
	};
}, {});

const buildSection = (section: SectionDefinition, getter: GetterType) => {
	const Component = adaptersMap[section.type];

	if (!Component)
		return null;

	return (
		<Component
			content={section.content}
			getter={getter}
		/>
	);
};

interface BuilderSectionsProps {
	sections: SectionDefinition[];
	getter: GetterType;
}

export const BuilderSections: React.FCWithChildren<BuilderSectionsProps> = props => {
	const { sections, getter } = props;
	const [, getAsset] = useDesignSystemAssets();
	const visionary = useContext(VisionaryContext);

	return (
		<React.Fragment>
			{sections.map((s, i) => {
				const blockGetter: GetterType = (key, fallback) => getter(`[${i}].content.${key}`, fallback);
				const section = buildSection(s, blockGetter);
				const hasFade = Boolean(blockGetter<boolean>('background_fade', false));
				let background = blockGetter<string>('background', void 0);
				let backgroundMobile = blockGetter<string>('background_mobile', void 0);
				const key = `${i}:${s.type}`;

				if (background)
					background = getAsset(`backgrounds/${background}`);
				if (backgroundMobile)
					backgroundMobile = getAsset(`backgrounds/${backgroundMobile}`);

				if (!section)
					return null;

				const id = [s.type, i].join('_');

				let WrappedSection = (
					<IdContext.Provider value={id} key={key}>
						<SectionSegment.Section
							key={key}
							id={id}
							$visionary={visionary}
							$background={background}
							$backgroundMobile={backgroundMobile}
						>
							<SectionSegment.Inner>
								{section}
							</SectionSegment.Inner>
						</SectionSegment.Section>
					</IdContext.Provider>
				);

				if (s.meta?.custom_container)
					WrappedSection = section;

				if (hasFade) {
					return (
						<FadeWrap key={key}>
							<FadeVector />
							{WrappedSection}
						</FadeWrap>
					);
				}

				return WrappedSection;
			}).filter(Boolean)}
		</React.Fragment>
	);
};

interface InputPayload {
	get: GetterType;
}

const Builder: React.FCWithChildren<InputPayload> = ({ get }) => {
	const landingPage = Boolean(get('page.landing'));
	const showHeaderHero = Boolean(get('page.show_header_hero', true));
	const visionary = Boolean(get('page.visionary', false));
	const endorsements = Boolean(get('page.endorsements', false));
	const newDesign = Boolean(get('page.new_design', false));

	const heroGetter: GetterType = (key, fallback) => get(`hero.${key}`, fallback);
	const headerHeroProps = headerContentAdapter(heroGetter);

	const sectionGetter: GetterType = (key, fallback) => get(`sections.${key}`, fallback);
	const sections = get('sections') as SectionDefinition[];

	const theme = heroGetter<Theme>('theme') || 'light';

	return (
		<React.Fragment>
			<BrandDesignContext.Provider value={newDesign}>
				<Meta get={(key: string, fallback: string) => get(`head.${key}`, fallback)} />
				<HeaderHero
					{...headerHeroProps}
					titleSegment={headerHeroProps.titleSegment}
					header={{ landingPage }}
					hideHeaderHero={!showHeaderHero}
					theme={theme}
				/>

				<VisionaryContext.Provider value={visionary}>
					<BuilderSections sections={sections} getter={sectionGetter} />
				</VisionaryContext.Provider>

				<Footer landingPage={landingPage} endorsements={endorsements} />
			</BrandDesignContext.Provider>
		</React.Fragment>
	);
};

export default Builder;
