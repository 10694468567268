import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useNCB from '../../hooks/use-ncb';
import useOnPageError from '../../hooks/use-on-page-error';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import { AlignInlineChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import { range } from '~lib/frontend/helpers/numbers';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';

const radioOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

const NCB: React.FunctionComponent = () => {
	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) => g(`motor.long_term.questions.about_you.no_claims.${key}`, fallback);

	const [showNCB, setShowNCB] = useState<boolean>(void 0);
	const { onChange, value, validation } = useNCB();
	const error = useOnPageError(validation, 'no_claims_bonus_input');
	const ltmConfig = useSelector(s => s.platform.ltm.config);

	const dropdownValues = useMemo(() => {
		const ncb = ltmConfig?.response?.ncb;

		if (!ncb)
			return [];

		const min = ncb.min === 0 ? ncb.step : ncb.min;

		return range(min, ncb.max, ncb.step)
			.map((l, i, array) => {
				const years = l / 12;
				const plural = years > 1 ? 's' : '';

				return {
					label: i === array.length - 1 ? `More than ${years} year${plural}` : `${years} year${plural}`,
					value: years,
				};
			});
	}, [ltmConfig?.response?.ncb]);

	const isNumeric = Number.isInteger(value);
	const isPositiveNumeric = isNumeric && value > 0;

	useEffect(() => {
		if (isPositiveNumeric) {
			setShowNCB(true);

			return;
		}

		if (isNumeric) {
			setShowNCB(false);

			return;
		}
	}, [isNumeric, isPositiveNumeric]);

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={'Do you have a no claims bonus?'}
				error={showNCB === void 0 ? error : void 0}
			>
				<AlignInlineChildren>
					{radioOptions.map(o => (
						<RadioInput
							key={o.label}
							name={'ncbSelect'}
							checked={o.value === showNCB}
							value={o.label}
							data-event-onchange-name={'no_claims_bonus_input_selected'}
							onChange={() => {
								setShowNCB(o.value);

								if (o.value === false)
									onChange(0);
								else
									onChange(null);
							}}
						/>
					))}
				</AlignInlineChildren>
			</QuoteInputDecorator>

			{Boolean(showNCB || value) && (
				<QuoteInputDecorator
					label={get('question', 'Years of no claims bonus')}
					error={error}
				>
					<TrackedSelectInput
						value={value}
						options={dropdownValues}
						placeholder={'Choose an option'}
						onChange={e => onChange(e.value)}
						data-event-onfocus-name={'dropdown_selected'}
						data-event-onfocus-value={'no_claims_bonus_years'}
						data-event-onchange-name={'no_claims_bonus_years_input_selected'}
					/>
				</QuoteInputDecorator>
			)}
		</React.Fragment>
	);
};

export default NCB;
