import React from 'react';

import { MeCardWrapper } from '../atoms/Card';
import CurrentPolicy from '../atoms/CurrentPolicy';
import PreviousPolicy from '../atoms/PreviousPolicy';
import type { FlattenPolicySubs } from '../hooks/use-subscriptions';
import { pastEndDate } from '../utils/formatDateTime';

interface SubscriptionListProps {
	subscriptions: FlattenPolicySubs[];
}

const SubscriptionList: React.FCWithChildren<SubscriptionListProps> = ({ subscriptions }) => {
	const currentSubs = subscriptions.filter(subscription => (subscription.endDate && !pastEndDate(subscription.endDate)));
	const previousSubs = subscriptions.filter(subscription => (subscription.endDate && pastEndDate(subscription.endDate)));

	return (
		<MeCardWrapper>
			{currentSubs.length > 0 && <CurrentPolicy subs={currentSubs} />}
			{previousSubs.length > 0 && <PreviousPolicy subs={previousSubs} />}
		</MeCardWrapper>
	);
};

export default SubscriptionList;
