import { all, fork } from 'redux-saga/effects';
import latestDocuments from './latest-documents';
import recordAgreement from './record-agreement';
import userStatus from './user-status';
export default function* termsSaga() {
    yield all([
        fork(latestDocuments),
        fork(recordAgreement),
        fork(userStatus),
    ]);
}
