import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { attachIdentifier } from '../actions';
import * as api from '../api';
import { handleErrorMessage } from '~lib/platform/auth/store/errors';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(attachIdentifier.request, function* workerAttachIdentifier({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const clientId = window?.cuvva?.client?.clientId || 'client_000000BPG6Lks9tQoAiJYrBRSXPX6';
    try {
        const response = yield call(api.attachIdentifier, client, {
            ...payload,
            clientId,
        });
        yield put(attachIdentifier.success(ident, response));
    }
    catch (error) {
        yield put(attachIdentifier.failure(ident, error));
        yield put(flashMessages.addError(...handleErrorMessage(Cher.coerce(error))));
    }
});
