import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import useIncidentsInternalChangeRequest from '../hooks/use-incident-change-request';
import Form from './atoms/Form';
import Incidents from './binded-fields/Incidents';
import IncidentConfirmationModal from './molecules/dialogs/IncidentConfirmationModal';
import LockedOutCallout from './molecules/LockedOutCallout';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';

const YourIncidents: React.FunctionComponent = () => {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const quoteState = useSelector(s => s.internal.quote);
	const product = quoteState.state.product;

	const [openConfirmationModal, setOpenModal] = useState(false);
	const onOpen = () => setOpenModal(true);
	const onClose = () => setOpenModal(false);

	const { beenDeclared, validation, beenInvolved } = useIncidentsInternalChangeRequest();

	const formTracking = useFormEventTracking();

	const [proceed, setProceed] = useState(false);

	const handleOpenConfirmation = () => {
		setProceed(true);
	};

	useEffect(() => {
		if (!proceed)
			return;

		onOpen();

		setProceed(false);
	}, [proceed]);

	// SMU: We want to disable the continue button if the user is declaring some incidents.
	const [disable, setDisable] = useState(false);

	return (
		<Form {...formTracking}>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'Incidents'}
				</Typography>
				<Typography $type={'Body.Medium'} $marginTop={'small'}>
					{
						'You need to declare any incidents from the last 5 years now, even if you weren’t at fault or a claim was never made, as you won’t be able to add or change them later.'
					}
				</Typography>

				{beenDeclared && <LockedOutCallout />}
				<PageError page={page} />
			</div>

			<Incidents setDisable={setDisable} />

			{isUndefined(beenInvolved) && !beenDeclared && validation && (
				product === 'ltm' ? (
					<ProceedButton path={'/quote/vehicle-info'} disabled={disable}>{'Continue'}</ProceedButton>
				) : (
					<ProceedButton path={'/quote/your-estimate'} submitEstimate={true}>{'Continue'}</ProceedButton>
				))
			}

			{(!isUndefined(beenInvolved) && !beenDeclared) && (
				<Button
					$stretch
					type={'submit'}
					$size={'large'}
					onClick={handleOpenConfirmation}
					disabled={disable}
				>
					{'Continue'}
				</Button>
			)}

			{beenDeclared && (
				product === 'ltm' ? (
					<ProceedButton
						path={'/quote/vehicle-info'}
						disabled={disable}
					>
						{'Continue'}
					</ProceedButton>
				) : (
					<ProceedButton path={'/quote/your-estimate'} submitEstimate={true}
					>{'See quote'}</ProceedButton>
				)
			)}

			<IncidentConfirmationModal openModal={openConfirmationModal} onClose={onClose} />
		</Form>
	);
};

export default YourIncidents;

function isUndefined(value: unknown) {
	return typeof value === 'undefined';
}
