import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import useResizeObserver from '~lib/frontend/hooks/use-resize-observer';
const AccordionContainer = styled.div `
	position: relative;
`;
const AccordionInner = styled.div `
	position: relative;
	width: 100%;
`;
const AccordionItemWrapper = styled.div `
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.large};

	&:not(:first-child) {
		margin-top: ${p => p.theme.spacing.small};
	}
`;
const AccordionTitle = styled.div `
	margin: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${p => p.theme.ui.textOnSurfaceBackground};
	padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large};
`;
export const AccordionTitleText = styled.div `
	flex: 1;
`;
const AccordionBody = styled.div `
	display: block;
	position: relative;
	padding: 0;
	margin: 0;
	height: 0;
	overflow: hidden;
	transition: height 0.3s;

	${({ active, bodyHeight }) => active && css `
			height: ${bodyHeight}px;
		`}
`;
const AccordionContent = styled.div `
	margin: 0;
	padding: 0 1rem 1rem;
	padding: 0 ${p => p.theme.spacing.large} ${p => p.theme.spacing.large};
	height: auto;
`;
const AccordionItem = props => {
    const { index, title, content, } = props;
    const ref = useRef(null);
    const [active, setActive] = useState(false);
    useResizeObserver(ref);
    return (React.createElement(AccordionItemWrapper, { key: `${title}:${index}` },
        React.createElement(AccordionTitle, { active: active, onClick: () => setActive(active => !active) },
            React.createElement(AccordionTitleText, null, title),
            React.createElement(Icon, { icon: active ? 'ic_chevron_up_circle_fill' : 'ic_chevron_down_circle', "$size": '20px', "$color": 'primaryFill' })),
        React.createElement(AccordionBody, { active: active, bodyHeight: ref.current?.clientHeight ?? 0 },
            React.createElement(AccordionContent, { ref: ref }, content))));
};
const AccordionItems = ({ accordionContent, }) => (React.createElement(React.Fragment, null, accordionContent.map(({ title, content }, i) => (React.createElement(AccordionItem, { index: i, title: title, content: content, key: `${title}:${i}` })))));
const Accordion = ({ items }) => (React.createElement(AccordionContainer, null,
    React.createElement(AccordionInner, null,
        React.createElement(AccordionItems, { accordionContent: items }))));
export default Accordion;
