import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { updateMandate } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(updateMandate.request, function* updateMandateSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    const flashMessages = yield getContext('flashMessages');
    try {
        const response = yield call(api.updateMandate, client, requestPayload);
        yield put(flashMessages.addSuccess('Payment method has been updated'));
        yield put(updateMandate.success(ident, response));
    }
    catch (error) {
        yield put(flashMessages.addError('Unable to update payment method', 'There was an unknown issue. The error is below:', Cher.coerce(error)));
        yield put(updateMandate.failure(ident, error));
    }
});
