import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { userStatus } from '../actions';
import * as api from '../api';
import { TermsActionTypes } from '../types';
export default function* watcherUserStatus() {
    yield takeEvery(TermsActionTypes.USER_STATUS, workerUserStatus);
}
function* workerUserStatus({ payload, meta }) {
    const client = yield getContext('api');
    const { userId } = payload;
    const { ident } = meta;
    try {
        const response = yield call(api.userStatus, client, userId);
        yield put(userStatus.success(ident, response));
    }
    catch (error) {
        yield put(userStatus.failure(ident, error));
    }
}
