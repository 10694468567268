/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import styled, { useTheme } from 'styled-components';

import * as adapters from '../adapters';
import { SectionDefinition } from '../index';
import { BuilderHeroDetail, BuilderMeta, BuilderPageDetail, BuilderPageStructure, BuilderSection } from '../types';
import { getField } from './field';
import HeroBuilder from './hero-builder';
import MetaBuilder from './meta-builder';
import PageBuilder from './page-builder';
import { BuilderBlocks, EditableSectionWrap } from './styled';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import Button from '~lib/frontend/design-system/components/button/Button';
import Card, { CardBody, CardHeaderGroup, CardHeaderText } from '~lib/frontend/design-system/components/card/Card';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { getProperty, TypedObject } from '~lib/shared/helpers/typed';
export interface BuilderProps {
	state: BuilderPageStructure;
	setState: (state: BuilderPageStructure) => void;
	togglePosition: () => void;
}

const Builder: React.FCWithChildren<BuilderProps> = ({ state, setState, togglePosition }) => {
	const dispatch = useDispatch();
	const theme = useTheme();

	return (
		<DesignSystemProvider themeKey={theme.themeKey} typographyStyle={'productive'}>
			<BuilderBlocks>
				<div>
					<Typography
						$type={'Heading.Medium'}
						$color={'textOnSurfaceBackground'}
						$marginBottom={'extraLarge'}
					>
						{'Actions'}
					</Typography>
					<Button
						$size={'small'}
						$type={'neutral'}
						$display={'inline'}
						onClick={() => dispatch(push(createAwareUrl('/content/page-builder')))}
					>
						{'Go home'}
					</Button>{' '}
					<Button $size={'small'} $type={'neutral'} $display={'inline'} onClick={() => togglePosition()}>
						{'Switch view'}
					</Button>{' '}
					<Button
						$size={'small'}
						$type={'neutral'}
						$display={'inline'}
						onClick={() => navigator.clipboard.writeText(JSON.stringify(state, null, '\t'))}
					>
						{'Copy JSON'}
					</Button>
				</div>

				<InputDecorator label={'URL'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={state.url}
						onChange={e => setState({ ...state, url: e.target.value })}
					/>
				</InputDecorator>

				<div>
					<Card collapsible collapsed>
						<CardHeaderGroup>
							<CardHeaderText>{'Meta'}</CardHeaderText>
						</CardHeaderGroup>
						<CardBody>
							<MetaBuilder
								state={state.head}
								setState={(head: BuilderMeta) => setState({ ...state, head })}
							/>
						</CardBody>
					</Card>
				</div>

				<div>
					<Card collapsible collapsed>
						<CardHeaderGroup>
							<CardHeaderText>{'Page properties'}</CardHeaderText>
						</CardHeaderGroup>
						<CardBody>
							<PageBuilder
								state={state.page}
								setState={(page: BuilderPageDetail) => setState({ ...state, page })}
							/>
						</CardBody>
					</Card>
				</div>

				<div>
					<Card collapsible collapsed>
						<CardHeaderGroup>
							<CardHeaderText>{'Hero'}</CardHeaderText>
						</CardHeaderGroup>
						<CardBody>
							<HeroBuilder
								state={state.hero}
								setState={(hero: BuilderHeroDetail) => setState({ ...state, hero })}
							/>
						</CardBody>
					</Card>
				</div>

				<div>
					<Typography
						$type={'Heading.Small'}
						$marginBottom={'regular'}
						$color={'textOnSurfaceBackground'}
					>
						{'Sections'}
					</Typography>
					<SectionsBuilder
						state={state.sections}
						setState={(sections: SectionDefinition[]) => setState({ ...state, sections })}
					/>
				</div>
			</BuilderBlocks>
		</DesignSystemProvider>
	);
};

interface SectionsBuilderProps {
	state: BuilderSection[];
	setState: React.Dispatch<any>;
}

const SectionsBuilder: React.FCWithChildren<SectionsBuilderProps> = ({ state, setState }) => {
	const availableDefaults: Record<string, Record<string, string>> = TypedObject.keys(adapters).reduce((a, v) => {
		const adapter = adapters[v];
		const defaultPayload = adapters[v]?.builder?.default;

		if (!defaultPayload)
			return a;

		return {
			...a,
			[adapter.type]: defaultPayload,
		};
	}, {});
	const availableAdaptersType = TypedObject.keys(availableDefaults);

	const replaceSection = (index: number, s: BuilderSection) => {
		const newStates = [...state];

		newStates.splice(index, 1, s);
		setState(newStates);
	};

	const removeSection = (index: number) => {
		const newStates = [...state];

		newStates.splice(index, 1);
		setState(newStates);
	};

	const moveSection = (index: number, direction: number) => {
		const newStates = [...state];
		const replaceWith = newStates.splice(index + direction, 1);

		newStates.splice(index, 0, ...replaceWith);

		setState(newStates);
	};

	const addSection = (type: string) => {
		const adapterDefault = cloneDeep(getProperty(availableDefaults, type));

		if (!adapterDefault) return;

		setState([...state, {
			type,
			meta: { custom_container: type === 'inline_banner' || type === 'trustpilot_review_segment' },
			content: {
				...adapterDefault,
			},
		}]);
	};

	return (
		<SectionWrapper>
			{state.map((s, i) => (
				<EditableSectionWrap key={`section-${i}`}>
					<Card collapsible collapsed>
						<CardHeaderGroup>
							<CardHeaderText>{s.type}</CardHeaderText>
						</CardHeaderGroup>
						<CardBody>
							<EditSectionBuilder
								key={`${JSON.stringify(s.type)}:${i}`}
								state={s}
								setState={s => replaceSection(i, s)}
							/>

							<SectionControlWrapper>
								<Button
									$size={'small'}
									$type={'destructive'}
									$leadingIcon={'ic_bin'}
									onClick={() => removeSection(i)}
								>
									{'Remove'}
								</Button>
								<Button
									$type={'neutral'}
									$size={'small'}
									$leadingIcon={'ic_chevron_up_sm'}
									onClick={() => moveSection(i, -1)}
								>
									{'Move up'}
								</Button>
								<Button
									$type={'neutral'}
									$size={'small'}
									$trailingIcon={'ic_chevron_down_sm'}
									onClick={() => moveSection(i, 1)}
								>
									{'Move down'}
								</Button>
							</SectionControlWrapper>
						</CardBody>
					</Card>
				</EditableSectionWrap>
			))}

			<SelectInput
				$size={'small'}
				value={'select'}
				placeholder={'Choose an option'}
				onChange={({ value }) => addSection(value)}
				options={availableAdaptersType.map(a => ({ value: a, label: a }))}
			/>
		</SectionWrapper>
	);
};

interface EditSectionBuilderProps {
	state: BuilderSection;
	setState: React.Dispatch<BuilderSection>;
}

const EditSectionBuilder: React.FCWithChildren<EditSectionBuilderProps> = ({ state, setState }) => {
	const adapterName = TypedObject.keys(adapters).find(a => adapters[a].type === state.type);
	const adapter = adapters[adapterName];
	const adapterBuilder = adapter?.builder;

	if (!adapterBuilder)
		return <span>{'No adapter found for this section'}</span>;

	const fields: Record<string, { type: string }> = adapterBuilder.fields;
	const inputFields = [];
	const content = state.content as Record<string, string | any>;

	const updateField = (key: string, value: string) => {
		setState({
			...state,
			content: {
				...(state.content as any),
				[key]: value,
			},
		});
	};

	for (const fieldKey of TypedObject.keys(fields)) {
		const field = fields[fieldKey];

		const component = getField(
			field,
			fieldKey,
			content[fieldKey as keyof typeof content] as string,
			value => updateField(fieldKey, value),
		);

		inputFields.push(component);
	}

	return (
		<div>
			{inputFields}
		</div>
	);
};

export default Builder;

const SectionWrapper = styled.div`
	select {
		width: 100%;
	}

	margin-bottom: 24px;
`;

const SectionControlWrapper = styled.div`
	display: flex;
	justify-content: space-around;
`;
