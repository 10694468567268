import styled from 'styled-components';

import { LogoItem } from './list';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

interface ContainerProps {
	$visionary?: boolean;
	$contentUnder?: boolean;
}

export const Container = styled.header<ContainerProps>`
	position: sticky;
	top: 0;
	z-index: 20;
	border: 0;

	${LogoItem} svg path {
		fill: ${p => (p.$visionary ? p.theme.ui.blankFill : p.theme.ui.neutralFill)};
	}

	/* Why this is important: */
	/* This makes sure the background goes under the header a bit */
	& + * {
		margin-top: ${p => p.$contentUnder ? '-80px' : '0'};
	}
`;

type NavProps = {
	$scrolling: boolean;
	$visionary?: boolean;
	$open: boolean;
	$newDesign?: boolean;
};

export const Nav = styled.nav<NavProps>`
	perspective: 800px;
	margin: auto;
	width: 100%;
	z-index: 100;
	transition: background-color 0.2s ease-in-out;
	position: sticky;
	top: 0;

	background: ${p => {
		const hasBackground = p.$open || p.$scrolling;

		if (hasBackground && p.$visionary) return `${p.theme.ui.secondaryBackground}${p.$open ? '' : 'EE'}`;
		if (hasBackground) return `${p.theme.ui.blankBackground}${p.$open ? '' : 'EE'}`;

		return 'transparent';
	}};

	${p => (p.$scrolling || p.$open ? 'backdrop-filter: blur(5px)' : '')};

	/* Handle padding + iPhone top notch in landscape mode */
	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export const ChevronContainer = styled.div<{ $open: boolean; $visionary: boolean }>`
	margin-left: ${p => p.theme.spacing.extraSmall};
	width: 20px;
	height: 20px;

	& > * {
		transform: ${p => (p.$open ? 'rotate(-180deg)' : 'rotate(0deg)')};
		transition: all 0.2s cubic-bezier(0, 1, 0, 1);
		--fill: ${p => p.$visionary ? p.theme.ui.textOnFill : p.theme.ui.textOnSurfaceBackground};
	}
`;
