import { combineReducers } from '@reduxjs/toolkit';
import { getAuthenticationToken, getDeviceConfig, setUserStatus, userStatus } from './actions';
const reducers = combineReducers({
    userStatus: userStatus.reducer,
    setUserStatus: setUserStatus.reducer,
    getAuthenticationToken: getAuthenticationToken.reducer,
    getDeviceConfig: getDeviceConfig.reducer,
});
export const initialMarketingConsentState = {
    userStatus: userStatus.getInitialState(),
    setUserStatus: setUserStatus.getInitialState(),
    getAuthenticationToken: getAuthenticationToken.getInitialState(),
    getDeviceConfig: getDeviceConfig.getInitialState(),
};
export default reducers;
