export function toWebsafeBase64(input) {
    return btoa(input)
        .replace(/[+]/g, '-')
        .replace(/[/]/g, '_')
        .replace(/[=]/g, '');
}
export function fromWebsafeBase64(input) {
    let nonWebsafe = input
        .replace(/[-]/g, '+')
        .replace(/[_]/g, '/');
    nonWebsafe += '=='.substring(0, (3 * input.length) % 4);
    return atob(nonWebsafe);
}
