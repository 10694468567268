import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { parseMargin } from './atoms/utils/Margin';
import Icon from './Icon';
const IconWrap = styled.div `
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: ${p => getOuterSize(p.$size)};
	height: ${p => getOuterSize(p.$size)};
	background: ${p => p.theme.ui[getOuterColor(p.$style, Boolean(p.$muted))]};
	border-radius: ${p => getBorderRadius(p.$type, p.theme.borderRadius.small)};

	${parseMargin};
`;
const IconWrapped = forwardRef(({ icon, children: _, ...otherProps }, ref) => (React.createElement(IconWrap, { ...otherProps, ref: ref },
    React.createElement(Icon, { icon: icon, "$size": getInnerSize(otherProps.$size), "$color": getInnerColor(otherProps.$style, Boolean(otherProps.$muted)) }))));
IconWrapped.displayName = 'IconWrapped';
function getOuterSize(size) {
    if (size === 'small')
        return '32px';
    return '40px';
}
function getInnerSize(size) {
    if (size === 'small')
        return '18px';
    return '24px';
}
function getInnerColor(style, muted) {
    if (style === 'onFill' && !muted)
        return 'blackFill';
    else if (!muted)
        return 'blankFill';
    // all the muted here 👇
    switch (style) {
        case 'primary':
        case 'success':
        case 'warning':
        case 'danger':
        case 'onFill':
            return getFillColor(style);
        case 'neutral':
        default:
            return 'blackFill';
    }
}
function getOuterColor(style, muted) {
    if (muted) {
        // eslint-disable-next-line default-case
        switch (style) {
            case 'primary':
                return 'primaryFillMuted';
            case 'success':
                return 'successFillMuted';
            case 'warning':
                return 'warningFillMuted';
            case 'danger':
                return 'dangerFillMuted';
            case 'onFill':
                return 'blankFillMuted';
            case 'neutral':
            default:
                return 'surfaceFillMuted';
        }
    }
    switch (style) {
        case 'primary':
        case 'success':
        case 'warning':
        case 'danger':
        case 'onFill':
            return getFillColor(style);
        case 'neutral':
        default:
            return 'neutralFill';
    }
}
function getFillColor(color) {
    switch (color) {
        case 'success':
            return 'successFill';
        case 'warning':
            return 'warningFill';
        case 'danger':
            return 'dangerFill';
        case 'onFill':
            return 'blankFill';
        case 'primary':
        default:
            return 'primaryFill';
    }
}
function getBorderRadius(type, defaultValue) {
    if (type === 'circle')
        return '50%';
    return defaultValue;
}
export default IconWrapped;
