import { combineReducers } from 'redux';
import { CodeMappingActionTypes, initialCodeMappingState } from './types';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
export const abiCodes = createAsyncReducer(CodeMappingActionTypes.ABI_CODES, initialCodeMappingState.abiCodes);
export const errorCodes = createAsyncReducer(CodeMappingActionTypes.ERROR_CODES, initialCodeMappingState.errorCodes);
export const geoCodes = createAsyncReducer(CodeMappingActionTypes.GEO_CODES, initialCodeMappingState.geoCodes);
export default combineReducers({
    abiCodes,
    errorCodes,
    geoCodes,
});
