import Cher from '@cuvva/cher';
import { call, put } from 'redux-saga/effects';
import { preparePhotoSubmission, submitPhotos, uploadKycDocs } from '~lib/platform/kyc/store/actions';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(uploadKycDocs.request, function* uploadKycDocsSaga(action) {
    const { userId, profilePhoto, licensePhoto } = action.payload;
    const { ident } = action.meta;
    const result = new Map();
    result.set('userId', userId);
    try {
        if (profilePhoto) {
            const { contentLength, contentType } = profilePhoto;
            const { profilePhoto: respProfilePhoto } = yield requestAndTake(preparePhotoSubmission.request({
                userId,
                profilePhoto: {
                    contentLength,
                    contentType,
                },
            }));
            if (respProfilePhoto) {
                yield call(upload, profilePhoto, respProfilePhoto.uploadUrl);
                yield put(submitPhotos.request({
                    userId,
                    profilePhotoId: respProfilePhoto.fileId,
                }));
                result.set('profilePhotoId', respProfilePhoto.fileId);
            }
        }
        if (licensePhoto) {
            const { contentLength, contentType } = licensePhoto;
            const { licensePhoto: respLicensePhoto } = yield requestAndTake(preparePhotoSubmission.request({
                userId,
                licensePhoto: {
                    contentLength,
                    contentType,
                },
            }));
            if (respLicensePhoto) {
                yield call(upload, licensePhoto, respLicensePhoto.uploadUrl);
                yield put(submitPhotos.request({
                    userId,
                    licensePhotoId: respLicensePhoto.fileId,
                }));
                result.set('licensePhotoId', respLicensePhoto.fileId);
            }
        }
        const response = Object.fromEntries(result);
        yield put(uploadKycDocs.success(ident, response));
    }
    catch (error) {
        yield put(uploadKycDocs.failure(ident, Cher.coerce(error)));
    }
});
async function upload(file, url) {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': file.contentType,
        },
        body: file.body,
        // S3 PutObject with signedUrl returns a 200 and an empty response on success.
        // An empty respons is invalid input for json parse, so set it to text instead.
    }).then(resp => resp.text());
}
