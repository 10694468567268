import styled from 'styled-components';

import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

export const InnerContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
`;

export const SectionWrapper = styled.div`
	padding-top: 80px;
	padding-bottom: 80px;
	margin: 0 auto;
	max-width: 1280px;

	@media (min-width: 768px) {
		padding-top: 96px;
		padding-bottom: 96px;
	}
`;

export const PaddedInner = styled.div`
	padding-left: 20px;
	padding-right: 20px;
	margin: 0 auto;


	& > * {
		margin: 0 auto;
	}

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export const FlexContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
	margin-top: 48px;

	& > * {
		flex: 1;
	}
`;
