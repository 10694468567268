import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFound from './NotFound';
import Footer from '~website/components/layout/footer';
import ProfileWrapper from '~website/features/me-hub/containers/ProfileWrapper';
import Policy from '~website/features/policy/Policy';
import SinglePolicy from '~website/features/policy/SinglePolicy';

const MeHub: React.FunctionComponent = () => {
	const match = useRouteMatch();

	return (
		<React.Fragment>
			<Switch>
				<Route sensitive exact path={[match.path, `${match.path}/account`]}>
					<ProfileWrapper view={'profile'} />
				</Route>

				<Route sensitive exact path={`${match.path}/discounts`}>
					<ProfileWrapper view={'discounts'} />
				</Route>

				<Route sensitive exact path={`${match.path}/policies`}>
					<Policy />
				</Route>

				<Route sensitive exact path={`${match.path}/policy/:policyId`}>
					<SinglePolicy />
				</Route>

				<Route>
					<NotFound />
				</Route>
			</Switch>
			<Footer />
		</React.Fragment>
	);
};

export default MeHub;
