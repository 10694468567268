import { all, fork } from 'redux-saga/effects';
import banVehicle from './ban-vehicle';
import findVehicleId from './find-vehicle-id';
import flushCache from './flush-cache';
import getPublicVehicle from './get-public-vehicle';
import getVehicle from './get-vehicle';
import getVehicleConfig from './get-vehicle-config';
import listExampleVehicles from './list-example-vehicles';
import listVehicleRevisions from './list-vehicle-revisions';
import overrideVrm from './override-vrm';
import setVehicleProvider from './set-vehicle-provider';
export default function* vehicleSaga() {
    yield all([
        fork(banVehicle),
        fork(findVehicleId),
        fork(flushCache),
        fork(getPublicVehicle),
        fork(getVehicle),
        fork(getVehicleConfig),
        fork(listVehicleRevisions),
        fork(listExampleVehicles),
        fork(setVehicleProvider),
        fork(overrideVrm),
    ]);
}
