import styled from 'styled-components';

const NotScrollable = styled.div<{ $imageUrl: string }>`
	position: sticky;
	top: 0;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background: url('${p => p.$imageUrl}'), linear-gradient(${p => p.theme.ui.surfaceDark}, ${p => p.theme.ui.surfaceDark});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`;

export default NotScrollable;
