import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Anchor from '~lib/frontend/atoms/Anchor';
import Link from '~lib/frontend/atoms/Link';
import { media } from '~lib/frontend/design-system/breakpoints';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import { NavContext } from '~website/hooks/use-sticky-nav';

interface GuideMenuProps {
	topics: string[];
	setTopic?: (topic: string) => void;
	customMaxWidth?: number;
}

const NAVBAR_HEIGHT = 76;

const GuideMenu: React.FCWithChildren<GuideMenuProps> = ({ topics, setTopic, customMaxWidth }) => {
	const { removeNav, parentNavOpen } = React.useContext(NavContext);
	const [isTopicsOpen, setIsTopicsOpen] = useState(false);
	const mobileView = useMediaQuery('(max-width: 767px)');
	const ref = useRef<HTMLDivElement>(null);

	const toggleTopicsOpen = (isOpen: boolean) => {
		setIsTopicsOpen(!isOpen);
	};

	const onClickTopicItem = (topic: string) => {
		setTopic(topic);
		setIsTopicsOpen(false);
	};

	useEffect(() => {
		if (parentNavOpen)
			setIsTopicsOpen(false);
	}, [parentNavOpen]);

	return (
		<div style={{ position: 'relative', height: 'auto' }}>
			<Menu removeNav={removeNav} customMaxWidth={customMaxWidth} ref={ref}>
				<MenuTitle to={'/how-insurance-works'}>
					<Typography $type={'Label.Large'} $color={'textOnSurfaceBackground'}>
						{'How insurance works'}
					</Typography>
				</MenuTitle>
				<MenuRight>
					<MenuToolsLink to={'/how-insurance-works#tools'}>
						<Typography $type={'Label.Medium'} $color={'textHighlight'}>
							{'Tools'}
						</Typography>
					</MenuToolsLink>
					<MenuTopics onClick={() => toggleTopicsOpen(isTopicsOpen)}>
						<Typography $type={'Label.Medium'} $color={'textHighlight'}>
							{'Topics'}
						</Typography>
						<ChevronContainer isOpen={isTopicsOpen}>
							<Icon icon={'ic_chevron_down'} $size={'20px'} $color={'primaryAction'} />
						</ChevronContainer>
					</MenuTopics>

				</MenuRight>
				{isTopicsOpen && (
					<TopicMenuContainer
						mobileView={mobileView}
						removeNav={removeNav}
						customMaxWidth={customMaxWidth}
					>
						<TopicMenu
							mobileView={mobileView}
							removeNav={removeNav}
							isOpen={isTopicsOpen}
						>
							{topics.map(topic => (
								<TopicMenuItem
									key={topic}
									onClick={() => onClickTopicItem(topic)}
								>
									<Link to={'/how-insurance-works'}>{topic.split('_').join(' ')}</Link>
								</TopicMenuItem>
							))}
						</TopicMenu>
					</TopicMenuContainer>
				)}
			</Menu>
		</div>
	);
};

const Menu = styled.div<{ removeNav: boolean; customMaxWidth: number }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${p => p.theme.ui.surface};
	border-radius: 16px;
	margin: 0 ${props => props.theme.spacing.regular} ${props => props.theme.spacing.small};

	padding: ${props => props.theme.spacing.regular};
	position: fixed;
	top: ${NAVBAR_HEIGHT + 5}px;
	z-index: 2;
	width: -webkit-fill-available;
	max-width: ${p => p.customMaxWidth ? `${p.customMaxWidth}px` : '950px'};

	@media (max-width: 350px) {
		padding: ${props => props.theme.spacing.small};
	}

	${media.greaterThan('tablet')({
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: '12px 24px 12px 12px',
	})};
	height: auto;
	filter: drop-shadow(0px 3px 4px rgba(10, 10, 92, 0.05));

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

const MenuTitle = styled(Anchor)`
	text-decoration: none;

	&, &:visited {
		color: inherit;
	}
`;

const MenuRight = styled.div`
	display: flex;
	justify-content: space-between;
	margin-right: ${props => props.theme.spacing.regular};
	min-width: 100px;
	${media.greaterThan('tablet')({
		marginRight: '12px',
		minWidth: '120px',
	})};
`;

const MenuToolsLink = styled(Anchor)`
	cursor: pointer;
	text-decoration: none;
`;

const MenuTopics = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const ChevronContainer = styled.div<{ isOpen: boolean }>`
	width: ${props => props.theme.spacing.regular};
	height: 20px;
	position: absolute;
	right: 0;
	padding: 0;
	margin-right: ${props => props.theme.spacing.small};
	margin-top: ${props => props.theme.spacing.extraSmall};

	${media.greaterThan('tablet')({
		right: '12px',
	})};

	& > * {
		transform: ${p => p.isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'};
		transition: all 0.2s cubic-bezier(0, 1, 0, 1);
	}
`;

const getCustomStyles = (
	p: TopicMenuProps | TopicMenuContainerProps,
	f: () => string | number,
	mobileFn?: () => string | number,
) => {
	if (p.mobileView) return mobileFn ? mobileFn() : '';

	return f();
};

type TopicMenuContainerProps = {
	removeNav: boolean;
	mobileView: boolean;
	customMaxWidth?: number;
	menuPosition?: number;
}

const TopicMenuContainer = styled.div<TopicMenuContainerProps>`
	max-width: ${p => p.customMaxWidth ? `${p.customMaxWidth + 40}px` : '1000px'};
	position: absolute;
	top: 10px;
	right: 0;
	z-index: ${p => getCustomStyles(p, () => p.menuPosition <= 0 ? 2 : '')};
	margin-top: ${p => getCustomStyles(p, () => p.menuPosition <= 0 ? '40px' : '0')};

	${media.greaterThan('tablet')({
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 'fit-content',
		display: 'flex',
		justifyContent: 'flex-end',
	})};
`;

type TopicMenuProps = {
	removeNav: boolean;
	mobileView: boolean;
	customMaxWidth?: number;
	menuPosition?: number;
	isOpen: boolean;
}

const TopicMenu = styled.div<TopicMenuProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	top: 77px;
	padding: 1rem;
	box-shadow: ${p => p.theme.boxShadow.medium};
	background: ${p => p.theme.ui.surface};

	@media (max-width: 349px) {
		left: 0;
		right: 0;
	}

	z-index: 5;
	border-radius: 24px;
	margin-top: -20px;
	right: 0;
	position: ${p => p.mobileView ? 'fixed' : 'absolute'};
	z-index: 1000;

	${media.greaterThan('tablet')({
		width: '250px',
	})};
`;

const TopicMenuItem = styled.div`
	padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.large};

	> a {
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: ${p => p.theme.ui.textHighlight};
		text-decoration: none;
		font-weight: 600;
		text-transform: capitalize;

		@media (max-width: 350px) {
			font-size: 14px;
		}
	}
`;

export default GuideMenu;
