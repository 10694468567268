import { combineReducers } from 'redux';
import { HiringOfferTypes, initialHiringOfferState } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
const createNewHiringOffer = createAsyncMapReducer(HiringOfferTypes.CREATE_NEW_HIRING_OFFER, initialHiringOfferState.createNewHiringOffer);
const getHiringOffersList = createAsyncReducer(HiringOfferTypes.GET_HIRING_OFFERS_LIST, initialHiringOfferState.getHiringOffersList);
const updateHiringOffer = createAsyncMapReducer(HiringOfferTypes.UPDATE_HIRING_OFFER, initialHiringOfferState.updateHiringOffer);
const updateHiringOfferStatus = createAsyncMapReducer(HiringOfferTypes.UPDATE_HIRING_OFFER_STATUS, initialHiringOfferState.updateHiringOfferStatus);
const generateShareKey = createAsyncMapReducer(HiringOfferTypes.GENERATE_SHARE_KEY, initialHiringOfferState.generateShareKey);
const deleteHiringOffer = createAsyncMapReducer(HiringOfferTypes.DELETE_HIRING_OFFER, initialHiringOfferState.deleteHiringOffer);
const retrieveHiringOffer = createAsyncMapReducer(HiringOfferTypes.RETRIEVE_HIRING_OFFER, initialHiringOfferState.retrieveHiringOffer);
export default combineReducers({
    createNewHiringOffer,
    getHiringOffersList,
    updateHiringOffer,
    updateHiringOfferStatus,
    generateShareKey,
    deleteHiringOffer,
    retrieveHiringOffer,
});
