export const initialPromoState = {
    createDiscount: {},
    createPromos: {},
    discount: {},
    doNotOffer: {},
    listDiscounts: {},
    listDiscountsNeu: {},
    listDiscountsPublic: {},
    listPromos: {},
    redeemCode: {},
    generateReferralCode: {},
    updatePromo: {},
    userReferees: {},
    userReferrer: {},
};
export const PromoActionTypes = {
    CREATE_DISCOUNT: '@@platform/promo/CREATE_DISCOUNT',
    CREATE_DISCOUNT_SUCCESS: '@@platform/promo/CREATE_DISCOUNT_SUCCESS',
    CREATE_DISCOUNT_FAILURE: '@@platform/promo/CREATE_DISCOUNT_FAILURE',
    LIST_DISCOUNTS: '@@platform/promo/LIST_DISCOUNTS',
    LIST_DISCOUNTS_SUCCESS: '@@platform/promo/LIST_DISCOUNTS_SUCCESS',
    LIST_DISCOUNTS_FAILURE: '@@platform/promo/LIST_DISCOUNTS_FAILURE',
    FETCH_DISCOUNT: '@@platform/promo/FETCH_DISCOUNT',
    FETCH_DISCOUNT_SUCCESS: '@@platform/promo/FETCH_DISCOUNT_SUCCESS',
    FETCH_DISCOUNT_FAILURE: '@@platform/promo/FETCH_DISCOUNT_FAILURE',
    USER_REFERRER: '@@platform/promo/USER_REFERRER',
    USER_REFERRER_SUCCESS: '@@platform/promo/USER_REFERRER_SUCCESS',
    USER_REFERRER_FAILURE: '@@platform/promo/USER_REFERRER_FAILURE',
    USER_REFEREES: '@@platform/promo/USER_REFEREES',
    USER_REFEREES_SUCCESS: '@@platform/promo/USER_REFEREES_SUCCESS',
    USER_REFEREES_FAILURE: '@@platform/promo/USER_REFEREES_FAILURE',
    LIST_DISCOUNTS_NEU: '@@platform/promo/LIST_DISCOUNTS_NEU',
    LIST_DISCOUNTS_NEU_SUCCESS: '@@platform/promo/LIST_DISCOUNTS_NEU_SUCCESS',
    LIST_DISCOUNTS_NEU_FAILURE: '@@platform/promo/LIST_DISCOUNTS_NEU_FAILURE',
    REDEEM_CODE: '@@platform/promo/REDEEM_CODE',
    REDEEM_CODE_SUCCESS: '@@platform/promo/REDEEM_CODE_SUCCESS',
    REDEEM_CODE_FAILURE: '@@platform/promo/REDEEM_CODE_FAILURE',
    GENERATE_REFERRAL_CODE: '@@platform/promo/GENERATE_REFERRAL_CODE',
    GENERATE_REFERRAL_CODE_SUCCESS: '@@platform/promo/GENERATE_REFERRAL_CODE_SUCCESS',
    GENERATE_REFERRAL_CODE_FAILURE: '@@platform/promo/GENERATE_REFERRAL_CODE_FAILURE',
    DO_NOT_OFFER: '@@platform/promo/DO_NOT_OFFER',
    DO_NOT_OFFER_SUCCESS: '@@platform/promo/DO_NOT_OFFER_SUCCESS',
    DO_NOT_OFFER_FAILURE: '@@platform/promo/DO_NOT_OFFER_FAILURE',
    LIST_DISCOUNTS_PUBLIC: '@@platform/promo/LIST_DISCOUNTS_PUBLIC',
    CREATE_PROMOS: '@@platform/promo/CREATE_PROMOS',
    CREATE_PROMOS_SUCCESS: '@@platform/promo/CREATE_PROMOS_SUCCESS',
    CREATE_PROMOS_FAILURE: '@@platform/promo/CREATE_PROMOS_FAILURE',
    LIST_PROMOS: '@@platform/promo/LIST_PROMOS',
    LIST_PROMOS_SUCCESS: '@@platform/promo/LIST_PROMOS_SUCCESS',
    LIST_PROMOS_FAILURE: '@@platform/promo/LIST_PROMOS_FAILURE',
    UPDATE_PROMO: '@@platform/promo/UPDATE_PROMO',
    UPDATE_PROMO_SUCCESS: '@@platform/promo/UPDATE_PROMO_SUCCESS',
    UPDATE_PROMO_FAILURE: '@@platform/promo/UPDATE_PROMO_FAILURE',
};
