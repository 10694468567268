import React, { useContext } from 'react';

import { EmbeddedVideoProps } from '../atoms/EmbeddedVideo';
import { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from '../atoms/TitleSegment';
import Header, { HeaderProps } from '../layout/header';
import NewHeaderHero from './NewHeaderHero';
import OldHeaderHero from './OldHeaderHero';
import { contentAdapter as subHeroContentAdapter, SubHeaderHeroProps } from './SubHeaderHero';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import { Theme } from '~lib/frontend/design-system/types';
import { DesignSystemAsset, GetterType } from '~lib/frontend/types/content';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import VisionaryContext from '~website/contexts/VisionaryContext';

type GradientOption = 'leading' | 'center' | 'trailing' | 'none';

interface HeaderHeroProps {
	mainImage: DesignSystemAsset;
	mobileImage?: DesignSystemAsset;
	embeddedVideo?: EmbeddedVideoProps;

	header?: HeaderProps;
	titleSegment: TitleSegmentProps;
	subHero?: SubHeaderHeroProps;
	hideHeaderHero?: boolean;
	gradient?: GradientOption;
	theme?: Theme;
}

const HeaderHero: React.FCWithChildren<HeaderHeroProps> = props => {
	const { mainImage, mobileImage, embeddedVideo, header, theme, titleSegment, gradient, hideHeaderHero = false } = props;
	const alternateBackground = theme === 'dark';
	const newDesign = useContext(BrandDesignContext);

	return (
		<VisionaryContext.Provider value={alternateBackground}>
			<Header contentUnder hideHeaderHero={hideHeaderHero} {...header} />
			{hideHeaderHero && <Margin $marginBottom={'5xLarge'} />}
			{!hideHeaderHero && (newDesign ? (
				<NewHeaderHero
					theme={theme}
					titleSegment={titleSegment}
					mainImage={mainImage}
					embeddedVideo={embeddedVideo}
					mobileImage={mobileImage}
				/>
			) : (
				<OldHeaderHero
					theme={theme}
					titleSegment={titleSegment}
					mainImage={mainImage}
					embeddedVideo={embeddedVideo}
					mobileImage={mobileImage}
					gradient={gradient}
				/>
			))}
		</VisionaryContext.Provider>
	);
};

HeaderHero.defaultProps = {
	theme: 'light',
};

export const contentAdapter = (get: GetterType): HeaderHeroProps => {
	const mainImage = get<DesignSystemAsset>('main_image');
	const mobileImage = get<DesignSystemAsset>('mobile_image');
	const embeddedVideo = get<EmbeddedVideoProps>('embedded_video');
	const centered = get<string>('alignment') === 'center';
	const gradient = get<GradientOption>('gradient', 'none');
	const titleSegment = titleSegmentContentAdapter((key, fallback) => get(`title_segment.${key}`, fallback));
	const hasSubHero = Boolean(get<unknown>('sub_hero', false));
	const theme = get<Theme>('theme') || 'light';
	const subHero = hasSubHero ? subHeroContentAdapter((key, fallback) => get(`sub_hero.${key}`, fallback)) : void 0;

	return {
		titleSegment: {
			...titleSegment,
			centered,
		},
		mainImage,
		mobileImage,
		embeddedVideo,
		subHero,
		theme,
		gradient,
	};
};

export default HeaderHero;
