import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getPolicyVerificationList } from '../components/molecules/verifications/verification-flow';
import { LtmQuoteContext } from '../components/quote-page/context';
import { flows, QuotePage } from '../flows';

const useQuoteStepIndicator = () => {
	const history = useHistory();
	const pathname = history.location.pathname;
	const lastPath = pathname.split('/').pop();

	// ====== Quotes ======
	const qState = useSelector(s => s.internal.quote);
	const flow = qState.state.flow || qState.state.recentFlow;
	const quotes: QuotePage[] = flows?.[flow]?.pages ?? [];
	const quoteSteps = quotes?.length ?? 0;
	const quoteStepIndex = quotes?.findIndex(p => p?.path === pathname);
	const activeQuoteStepIndex = useMemo(() => {
		if (pathname.includes('verification') || pathname.includes('payment'))
			return quoteSteps;

		return quoteStepIndex;
	}, [pathname, quoteStepIndex, quoteSteps]);

	// ====== Verification ======
	const { quote } = useContext(LtmQuoteContext);
	const requirements = quote?.response?.requirements?.policy;
	const verificationItems = useMemo(() => {
		const items = getPolicyVerificationList(requirements).map(item => item.path);

		items.push('end');

		return items ?? [];
	}, [requirements]);

	const verificationSteps = verificationItems.length;
	const verificationStepIndex = verificationItems?.findIndex(p => p === lastPath);

	const activeVerificationStepIndex = useMemo(() => {
		if (pathname.includes('payment'))
			return verificationSteps;

		if (pathname.includes('verification'))
			return verificationStepIndex === -1 ? 0 : verificationStepIndex + 1;

		return verificationStepIndex;
	}, [pathname, verificationSteps, verificationStepIndex]);

	// ====== Payment ======
	const checkPaymentPath = lastPath.includes('payment');
	const paymentSteps = 2;
	const activePaymentStepIndex = checkPaymentPath ? 1 : 0;

	return {
		product: flow === 'monthly' ? 'ltm' : 'stm',
		quoteSteps,
		activeQuoteStepIndex,
		verificationSteps,
		activeVerificationStepIndex,
		paymentSteps,
		activePaymentStepIndex,
	};
};

export default useQuoteStepIndicator;
