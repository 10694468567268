import { useContext } from 'react';
import get from 'lodash/get';
import { ContentKey } from '../contexts/content';
import useSegment from './use-segment';
/**
 * This allows you to access *any* segment content value, by key, with a simple getter returned.
 * This is the preferred method as we can eventually track keys access and work on optimisation
 */
const useContentKey = (segment) => {
    const { showContentKey } = useContext(ContentKey);
    const segmentContent = useSegment(segment);
    const getter = (key, fallback) => {
        const defaultValue = fallback === void 0 ? '' : fallback;
        const value = get(segmentContent, `content.${key}`, defaultValue);
        if (!showContentKey)
            return value;
        if (typeof value === 'string')
            return key;
        if (Array.isArray(value))
            return []; // opinionated but won't make it break - could return an array of same length?
        // Ultimately, if it's an object or boolean, there isn't much we can do
        return value;
    };
    return getter;
};
export default useContentKey;
