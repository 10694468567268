import React from 'react';
import styled from 'styled-components';

import { SmartPricingDialogContentWrapper } from '../atoms/SmartPricingCard';
import SmartPricingCarousel from './SmartPricingCarousel';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { GetterType } from '~lib/frontend/types/content';

const SmartPricingSteps = () => {
	const stepOneImg = useContentAsset('website_core', 'quote/smart-pricing-step-1.png');
	const stepThreeImg = useContentAsset('website_core', 'quote/smart-pricing-step-3.png');

	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) =>
		g(`insights.smart_pricing_education.${key}`, fallback);

	const { isMobile } = useMobileDetect();
	const marginSizing = isMobile ? 'extraLarge' : '48px';
	const marginYSizing = isMobile ? 'large' : '24px';

	return (
		<SmartPricingDialogContentWrapper>
			<Margin $marginTop={marginSizing} $marginLeft={marginYSizing} $marginRight={marginYSizing}>
				<Typography $color={'textHighlight'} $type={'Heading.XSmall'}>
					{get('step_1.step_indicator.default', 'Step 1')}
				</Typography>

				<Typography $marginTop={'regular'} $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
					{get('step_1.title', 'Start driving')}
				</Typography>

				<Typography $type={'Body.Medium'} $marginTop={'regular'} $color={'textOnSurfaceBackgroundMuted'}>
					{get(
						'step_1.body',
						'It’s totally free to get set up. All you need to do is take your phone with you when you drive. There’s no need to get a black box installed. Easy peasy 👍',
					)}
				</Typography>

				<ImageContainer>
					<picture>
						<img src={stepOneImg} alt={`smart-pricing-image-${1}`} />
					</picture>
				</ImageContainer>
			</Margin>

			<Margin $marginTop={marginSizing}>
				<Margin $marginLeft={marginYSizing} $marginRight={marginYSizing}>
					<Typography $color={'textHighlight'} $type={'Heading.XSmall'}>
						{get('step_2.step_indicator.default', 'Step 2')}
					</Typography>

					<Typography $marginTop={'regular'} $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
						{get('step_2.title', 'You’ll get a driving score')}
					</Typography>

					<Typography $type={'Body.Medium'} $marginTop={'regular'} $color={'textOnSurfaceBackgroundMuted'}>
						{get(
							'step_2.body',
							'Once you’ve driven 125 miles, we’ll turn your data into a driving score. You’ll also get insights on your driving style, so you can see how you’re doing 👀',
						)}
					</Typography>
				</Margin>

				<SmartPricingCarousel />
			</Margin>

			<Margin $marginTop={marginSizing} $marginLeft={marginYSizing} $marginRight={marginYSizing}>
				<Typography $color={'textHighlight'} $type={'Heading.XSmall'}>
					{get('step_3.step_indicator.default', 'Step 3')}
				</Typography>

				<Typography $marginTop={'regular'} $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
					{get('step_3.title', 'Good drivers pay less')}
				</Typography>

				<Typography $type={'Body.Medium'} $marginTop={'regular'} $color={'textOnSurfaceBackgroundMuted'}>
					{get(
						'step_3.body',
						'We reward good drivers with sweet, sweet savings – you could save up to a third on the cost of your insurance every month 🎉',
					)}
				</Typography>

				<ImageContainer>
					<picture>
						<img src={stepThreeImg} alt={`smart-pricing-image-${3}`} />
					</picture>
				</ImageContainer>
			</Margin>
		</SmartPricingDialogContentWrapper>
	);
};

const ImageContainer = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	max-height: 211px;
	border-radius: ${e => e.theme.borderRadius.large};
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
`;

export default SmartPricingSteps;
