import { all, put, select } from 'redux-saga/effects';

import { quoteActions } from '../actions';
import { PreloadedVehicle } from '../types';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { userVehicleProfile } from '~lib/platform/vehicle-profile/store/actions';
import { setItem } from '~lib/shared/helpers/local-storage';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { quoteRecentVehiclesKey } from '~website/constants/storage';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(quoteActions.setVehicle, function* setVehicleSaga(action) {
	const { vehicleId } = action.payload;

	yield all([
		handleRecentVehicles(vehicleId),
		handleVehicleProfile(vehicleId),
	]);
});

function* handleRecentVehicles(vehicleId: string) {
	const recentVehiclesStore: PreloadedVehicle[] = yield select(
		(s: ApplicationState) => s.internal.quote.state.recentVehicles,
	);

	let newStructure = [...(recentVehiclesStore || [])];

	try {
		let vehicle: PublicVehicle;

		vehicle = yield select((s: ApplicationState) => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]?.response);

		if (!vehicle) {
			vehicle = yield requestAndTake(
				getPublicVehicle.request({ id: vehicleId, bypassChecks: false }),
			);
		}

		const vehStructure: PreloadedVehicle = {
			vrm: vehicle.prettyVrm,
			vehicleId: vehicle.id,
			model: vehicle.model,
			make: vehicle.make,
		};

		newStructure.unshift(vehStructure);

		newStructure = newStructure.filter(
			(value, index, self) => self.findIndex(t => t.vehicleId === value.vehicleId) === index,
		);

		// cap to 5
		newStructure = newStructure.slice(0, 5);

		setItem(quoteRecentVehiclesKey, newStructure.map(v => v.vehicleId));

		yield put(quoteActions.setRecentVehicles(newStructure));
	} catch (e) {
		// best effort
	}
}

function* handleVehicleProfile(vehicleId: string) {
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);

	try {
		yield requestAndTake(
			userVehicleProfile.request({ userId, vehicleId, revisionId: null }),
		);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
	}
}
