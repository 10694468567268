import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';

interface TrustSegmentProps {
	trustProvider: 'trustpilot';
	showVehicleSearch?: boolean;
	showVehicleSearchFlag?: boolean;
}

const AppStoreBadgesSegment: React.FCWithChildren<TrustSegmentProps> = ({
	showVehicleSearch,
	showVehicleSearchFlag,
}) => {
	const asset = useContentAsset('website_core', 'download/appstore.svg');

	return (
		<AppStoreWrapper $showVehicleSearch={showVehicleSearch} $showVehicleSearchFlag={showVehicleSearchFlag}>
			<Anchor href={'/download'} target={'blank'}>
				<img src={asset} alt={'app store badge'} />
			</Anchor>
		</AppStoreWrapper>
	);
};

const AppStoreWrapper = styled.div<{ $showVehicleSearch?: boolean; $showVehicleSearchFlag?: boolean }>`
	border: none;
	display: flex;
	align-items: center;
	justify-content: ${p => (p.$showVehicleSearch && p.$showVehicleSearchFlag ? 'left' : 'center')};

	gap: ${p => p.theme.spacing.regular};
	padding: ${p => p.theme.spacing.regular} 0;
	border-radius: ${p => p.theme.borderRadius.regular};
	color: ${p => p.theme.ui.textOnFill};
	cursor: pointer;

	${media.lessThan('tablet')({
		justifyContent: 'center',
	})};

	&,
	& * {
		text-decoration: none;
	}
`;

export default AppStoreBadgesSegment;
