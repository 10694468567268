import { call, getContext, put } from 'redux-saga/effects';
import { getPages as getPagesApi } from '../../api';
import { getPages } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getPages.request, function* getPagesSaga() {
    const client = yield getContext('api');
    try {
        const pages = yield call(getPagesApi, client);
        yield put(getPages.success(pages));
    }
    catch (error) {
        yield put(getPages.failure(error));
    }
});
