import { call, getContext, put } from 'redux-saga/effects';
import { updateLicenseHint } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(updateLicenseHint.request, function* workerUpdateLicenseHint({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield call(api.updateLicenseHint, client, payload);
        yield put(updateLicenseHint.success(ident));
    }
    catch (error) {
        // TODO handle code === dln_already_attached
        yield put(updateLicenseHint.failure(ident, error));
    }
});
