import styled from 'styled-components';

import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

export const EndorsementLogos = styled.div`
	background: ${p => p.theme.ui.secondaryBackground};
	padding: 80px ${p => p.theme.spacing.large};

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export const EndorsementLogosInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;

	img {
		height: 40px;
	}

	img+img {
		margin-top: 48px;
	}

	@media (min-width: 768px) {
		flex-direction: row;

		img+img {
			margin-top: 0;
		}
	}
`;
