import CuvvaClient from '@cuvva/cuvva-client';

import { Doggo } from '~lib/platform/doggos/types';
import { StaffMemberPublic } from '~lib/platform/staff/types';

export async function getStaffAndDoggos(client: CuvvaClient) {
	const [doggos, staff] = await Promise.all([
		client.rpcNoAuth('service-doggos', '1/2019-06-24/list_office_doggos', null),
		client.rpcNoAuth('service-staff', '1/2019-05-22/list_staff_public', null),
	]);

	return {
		doggos: doggos as Doggo[],
		staff: staff as StaffMemberPublic[],
	};
}
