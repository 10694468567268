import React, { useContext } from 'react';
import { RadioInputItemWrapInnerSC, RadioInputItemWrapOuterSC } from '../atoms/Radio';
import InputDecoratorContext from '../context/decorator';
import Typography from '~lib/frontend/design-system/components/Typography';
const RadioInput = ({ name, value, disabled, checked, onChange, body, ...props }) => {
    const { error } = useContext(InputDecoratorContext);
    return (React.createElement(RadioInputItemWrapOuterSC, { "$checked": checked, "$error": Boolean(error), "$disabled": Boolean(disabled), role: 'radio', "aria-checked": checked },
        React.createElement(RadioInputItemWrapInnerSC, { "$checked": checked },
            React.createElement("input", { type: 'radio', name: name, disabled: disabled, value: value, checked: checked, onChange: disabled ? void 0 : () => onChange(), ...props }),
            React.createElement("span", null)),
        value && !body && (React.createElement(Typography, { "$type": 'Body.Medium', "$color": disabled ? 'textMinor' : 'textOnSurfaceBackground' }, value)),
        body ?? null));
};
export default RadioInput;
