import React from 'react';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';

const KeyTerms: React.FunctionComponent = () => (
	<div>
		<SectionHeaderText>{'Key Terms'}</SectionHeaderText>
		<Typography $type={'Body.XLarge'} $marginTop={'large'}>
			{'More details about the key terms are in your terms of employment.'}
		</Typography>
	</div>
);

export default KeyTerms;
