import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { authenticate } from '../actions';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(authenticate.request, function* workerAuthenticate({ payload }) {
    const client = yield getContext('api');
    const { requestId, code, state } = payload;
    try {
        const redirectUri = yield call([client, client.useAuthenticationCode], code, state);
        const safeUri = getSafeRedirectUri(redirectUri);
        const envUri = createAwareUrl(safeUri);
        yield put(authenticate.success(requestId, { redirectUri: envUri }));
    }
    catch (error) {
        const cher = Cher.coerce(error);
        if (['token_used', 'invalid_client_id', 'unauthorized', 'token_not_found'].includes(cher.code))
            yield put(authenticate.failure(requestId, cher));
        yield put(authenticate.failure(requestId, error));
    }
});
function getSafeRedirectUri(uri) {
    if (!uri)
        return '/';
    return `/${uri.replace(/^(?:(?:https?)?[:/\\.]+)/, '')}`;
}
