export const getBannerColor = (muted) => {
    if (muted) {
        return {
            error: 'dangerFillMuted',
            success: 'successFillMuted',
            primary: 'primaryFillMuted',
            neutral: 'surface',
        };
    }
    return {
        error: 'dangerFill',
        success: 'successFill',
        primary: 'primaryFill',
        neutral: 'surface',
    };
};
export const getIconColor = (type, muted) => {
    const buttonColor = {
        error: 'textDanger',
        success: 'textSuccess',
        primary: 'textHighlight',
        neutral: 'textHighlight',
    };
    if (!muted && type !== 'neutral')
        return 'textOnFill';
    return buttonColor[type];
};
export const getTitleColor = (type, muted) => {
    if (muted || type === 'neutral')
        return 'textOnSurfaceBackground';
    return 'textOnFill';
};
export const getBodyColor = (type, muted) => {
    if (muted || type === 'neutral')
        return 'textOnSurfaceBackgroundMuted';
    return 'textOnFill';
};
