import React from 'react';
import styled from 'styled-components';

import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface IlloWrapperProps {
	filename: string;
	altDescription: string;
}

const IllustrationWrapper = (props: IlloWrapperProps) => {
	const { filename, altDescription } = props;

	const illustration = useDesignSystemAsset({ type: 'illustration', value: filename });

	return (
		<IlloWrapper>
			<picture>
				<img src={illustration} alt={altDescription} />
			</picture>
		</IlloWrapper>
	);
};

export default IllustrationWrapper;

export const IlloWrapper = styled.div`
	max-width: 100%;
	max-height: 100%;
	text-align: center;

	img {
		width: 120px;
		height: 120px;
		object-fit: contain;
	}
`;
