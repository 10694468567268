import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { processCycle } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherProcessCycle() {
    yield takeEvery(LtmActionTypes.PROCESS_CYCLE, workerProcessCycle);
}
function* workerProcessCycle({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        yield call(api.processCycle, client, payload);
        yield put(processCycle.success(ident));
    }
    catch (error) {
        yield put(processCycle.failure(ident, error));
    }
}
