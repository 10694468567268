// Legacy REST endpoints
export const createDiscount = (client, body) => client.rest('service-promo', 'post', '1/discounts', null, body);
export const listDiscounts = (client, userId) => client.rest('service-promo', 'get', '1/discounts', { userId }, null);
// New RPC style endpoints
export const getDiscount = (client, discountId) => client.rpc('service-promo', '2/2021-02-05/get_discount', { discountId });
export const userReferrer = (client, userId) => client.rpc('service-promo', '2/2021-02-05/get_referrer', { userId });
export const userReferees = (client, userId) => client.rpc('service-promo', '2/2021-02-05/list_referees', { userId });
export const listDiscountsNeu = (client, userId, type, includeExpired, includeUsed, includeNotOffered) => client.rpc('service-promo', '2/2021-02-05/list_discounts', {
    userId,
    type,
    includeExpired,
    includeUsed,
    includeNotOffered,
});
export const redeemCode = (client, userId, code) => client.rpc('service-promo', '2/2021-02-05/redeem_code', { userId, code });
export const generateReferralCode = (client, userId) => client.rpc('service-promo', '2/2021-02-05/generate_referral_code', { userId });
export const doNotOffer = (client, discountId) => client.rpc('service-promo', '2/2021-02-05/do_not_offer', { discountId });
export const listDiscountPublic = (client, payload) => client.rpc('service-promo', '2/2021-02-05/list_discounts_public', payload);
export const listPromos = (client, payload) => client.rpc('service-promo', '2/2021-02-05/list_promos', payload);
export const createPromos = (client, payload) => client.rpc('service-promo', '2/2021-02-05/create_promos', payload);
export const updatePromo = (client, payload) => client.rpc('service-promo', '2/2021-02-05/update_promo', payload);
