import { AsyncState, initialAsyncState } from '~lib/shared/redux/types/state';

export type Career = {
	id: number;
	employmentType: string;
	department: string;
	location: string;
	title: string;
	url: string;
};

export type Careers = Career[];

export interface CareersState {
	careers: AsyncState<Careers>;
}

export const initialCareersState: CareersState = {
	careers: initialAsyncState,
};

export const CareersActionTypes = {
	GET_CAREERS: '@@internal/careers/GET_CAREERS',
	GET_CAREERS_FAILURE: '@@internal/careers/GET_CAREERS_FAILURE',
	GET_CAREERS_SUCCESS: '@@internal/careers/GET_CAREERS_SUCCESS',
};
