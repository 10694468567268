import { combineReducers } from 'redux';
import { initialKycState, KycActionTypes } from '../types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
export const decideResolution = createAsyncMapReducer(KycActionTypes.DECIDE_RESOLUTION, initialKycState.decideResolution);
export const latestKyc = createAsyncMapReducer(KycActionTypes.GET_LATEST_KYC, initialKycState.latestKyc);
export const latestKycOps = createAsyncMapReducer(KycActionTypes.GET_LATEST_KYC_OPS, initialKycState.latestKycOps);
export const preparePhotoSubmission = createAsyncMapReducer(KycActionTypes.PREPARE_PHOTO_SUBMISSION, initialKycState.preparePhotoSubmission);
export const submitPhotos = createAsyncReducer(KycActionTypes.SUBMIT_PHOTOS, initialKycState.submitPhotos);
export const revokePhotos = createAsyncReducer(KycActionTypes.REVOKE_PHOTOS, initialKycState.revokePhotos);
export const uploadKycDocs = createAsyncMapReducer(KycActionTypes.UPLOAD_KYC_DOCS, initialKycState.uploadKycDocs);
export default combineReducers({
    decideResolution,
    latestKyc,
    latestKycOps,
    preparePhotoSubmission,
    submitPhotos,
    revokePhotos,
    uploadKycDocs,
});
