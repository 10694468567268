import styled from 'styled-components';
export const IllustrationWrap = styled.div `
	max-width: 128px;
	max-height: 128px;
	margin: 0 auto;

	> img {
		width: 100%;
		height: 100%;
	}
`;
