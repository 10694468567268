export function openBlankWindow() {
    return window.open('about:blank', '', '_blank');
}
export function openBlankTab() {
    return window.open('about:blank', '_blank', 'top=0,left=0');
}
export function writeHtmlToWindow(win, title, body, cssUrl, cssContent) {
    const html = `<!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<meta http-equiv="X-UA-Compatible" content="ie=edge">
	${cssUrl ? `<link rel="stylesheet" href="${cssUrl}">` : ''}
	${cssContent ? `<style>${cssContent}</style>` : ''}
	<title>${title}</title>
</head>
<body>
	${body}
</body>
</html>
`;
    win.document.body.insertAdjacentHTML('afterbegin', html);
    win.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    win.focus();
    return win;
}
