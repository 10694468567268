import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import CheckYourInboxContent from '~website/features/sign-in/molecules/CheckYourInboxContent';

const CheckYourInbox: React.FunctionComponent = () => {
	const history = useHistory<{ emailAddress: string | undefined }>();
	const emailAddress = history.location?.state?.emailAddress;

	return (
		<Wrap>
			<CheckYourInboxContent emailAddress={emailAddress} />
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: ${p => p.theme.spacing.large} 0;
`;

export default CheckYourInbox;
