import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import InputPrefilledSC from '../atoms/InputPrefilled';
import TextInput from './TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
const PrefilledInput = forwardRef((props, ref) => {
    const { disabled, prefilled, ...otherProps } = props;
    const prefilledRef = useRef(null);
    const theme = useTheme();
    const [pad, setPad] = useState(0);
    const recompute = () => {
        if (!prefilledRef?.current)
            return;
        const rect = prefilledRef.current?.getBoundingClientRect();
        if (rect && rect.width)
            setPad(Math.ceil(rect.width));
    };
    useEffect(() => {
        recompute();
    }, [prefilled, theme.typography]);
    return (React.createElement(InputPrefilledSC, { "$pad": pad },
        React.createElement(TextInput, { ref: ref, disabled: disabled, ...otherProps }),
        React.createElement(Typography, { ref: prefilledRef, "$type": 'Body.Medium', "$color": 'textMinor' }, prefilled)));
});
export default PrefilledInput;
