import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { ConfirmScreenProps } from './ConfirmScreen';
import SingleFileUpload from './SingleFileUpload';
import { UploadScreenProps } from './UploadScreen';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { uploadKycDocs } from '~lib/platform/kyc/store/actions';
import { useVerificationContext } from '~website/features/quote/contexts/VerificationContext';

const generateRequestId = () => ksuid.generate('request').toString();

const VerifyLicence = () => {
	const { onUploadComplete } = useVerificationContext();

	const [requestId, setRequestId] = useState<string>(void 0);
	const uploadedFile = useSelector(s => s.platform.kyc.uploadKycDocs[requestId]);
	const isUploading = useAsyncStateStatus(uploadedFile).is('fetching');
	const uploadError = useAsyncStateStatus(uploadedFile).is('error') && uploadedFile?.error;

	const fileId = uploadedFile?.response?.licensePhotoId;

	const [preview, setPreview] = useState<string>(void 0);

	const uploadScreen: UploadScreenProps = {
		illuFilename: 'il_id_gb_01.svg',
		illuAlt: 'il_id_gb_01',
		heading: 'Let’s add your licence',
		body: 'Take a photo of the front of your UK driving licence',
		action: (file, userId, buffer) => {
			const requestId = generateRequestId();

			setRequestId(requestId);
			setPreview(buffer);

			return uploadKycDocs.request({
				requestId,
				userId,
				licensePhoto: {
					contentLength: file.size,
					contentType: file.type,
					body: file,
				},
			});
		},
		analyticsPage: 'verification_takePhotoID',
	};

	const confirmScreen: ConfirmScreenProps = {
		heading: 'Please check your licence',
		body: 'Make sure all the corners are visible and it’s clear – no blur, glare or reflections.',
		previewBuffer: preview,
		analyticsPage: 'verification_photoIDReview',
	};

	return (
		<SingleFileUpload
			uploadScreen={uploadScreen}
			confirmScreen={confirmScreen}
			fileId={fileId}
			isUploading={isUploading}
			onUploadComplete={() => onUploadComplete(fileId)}
			uploadError={uploadError}
			onRetry={() => setRequestId(void 0)}
		/>
	);
};

export default VerifyLicence;
