import React from 'react';
import styled from 'styled-components';

import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CheckListProps {
	items: string[];
}

const CheckList: React.FCWithChildren<CheckListProps> = ({ items }) => (
	<ChecksContainer>
		{items.map(item => (
			<CheckItem key={item}>
				<Icon icon={'ic_check'} $color={'primaryFill'} $size={'24px'} />
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{item}
				</Typography>
			</CheckItem>
		))}
	</ChecksContainer>
);

const ChecksContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;

const CheckItem = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.small};

	& > * {
		flex: 1;
	}

	&>:first-child {
		flex: 0 0 auto;
	}
`;

export default CheckList;
