import React from 'react';

import { DiscountProps } from '../../hooks/use-discounts';
import { DiscountListWrap } from '../atoms/alignment';
import Discount from '../atoms/Discount';

const InactiveDiscounts = ({ discounts }: DiscountProps) => (
	<DiscountListWrap>
		{discounts.map(discount => <Discount discount={discount} />)}
	</DiscountListWrap>
);

export default InactiveDiscounts;
