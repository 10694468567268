import styled from 'styled-components';

const CardOnVibrantBackground = styled.div<{ $maxWidth?: number }>`
	background: ${p => p.theme.ui.secondarySurface};
	border-radius: ${p => p.theme.borderRadius.large};
	max-width: ${p => p.$maxWidth ?? 564}px;
	padding: ${p => p.theme.spacing.extraLarge} ${p => p.theme.spacing.large};
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: ${p => p.theme.spacing.extraLarge};
	}
`;

export default CardOnVibrantBackground;
