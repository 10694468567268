import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useCancellationFlow } from '../../context/CancellationFlowContext';
import { useSinglePolicy } from '../../context/SinglePolicyContext';
import CancellationModalLayout from './CancellationModalLayout';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { confirmCancellation, quoteAutocycle } from '~lib/platform/ltm-cancellation/store/actions';

interface PolicyDetailsProps {
	title: string;
	description: string;
	icon: ReactNode;
}
const ConfirmCancellation = () => {
	const policy = useSinglePolicy();
	const dispatch = useDispatch();
	const { cancellationReason, closeModal } = useCancellationFlow();

	const endDate = policy.endDate;
	const { date, time } = formattedEndDate(endDate);

	const vehicleId = policy.vehicleId;

	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const policyVehicle = `${[vehicle?.response?.make, vehicle?.response?.model].join(' ')} (${
		vehicle?.response?.prettyVrm
	})`;

	const { subscriptionId, userId } = policy.subInfo;

	useEffect(() => {
		dispatch(quoteAutocycle.request({
			subscriptionId,
			userId,
			createdBy: userId,
			reason: 'customer_invoked',
			autocycle: false,
			overruleChecks: null,
		}));
	}, [dispatch, subscriptionId, userId]);
	const quoteAutocycleStore = useSelector(s => s.platform.ltmCancellation.quoteAutocycle[subscriptionId]);
	const cancellationRequestId = quoteAutocycleStore?.response?.autocycleRequestId;

	const confirmCancellationStore = useSelector(
		s => s.platform.ltmCancellation.confirmCancellation[cancellationRequestId],
	);

	const quoteAutocycleStatus = useAsyncStateStatus(quoteAutocycleStore);
	const confirmCancellationStatus = useAsyncStateStatus(confirmCancellationStore);

	const confirm = () => {
		const { type, description } = cancellationReason;

		dispatch(confirmCancellation.request({
			cancellationRequestId,
			userId,
			statedReason: {
				type,
				description: description || null,
			},
		}));
	};

	const loading = confirmCancellationStatus.is('fetching');
	const quoteError = quoteAutocycleStatus.is('error');
	const confirmationError = confirmCancellationStatus.is('error');

	useEffect(() => {
		if (confirmCancellationStatus.is('response')) closeModal();
	}, [closeModal, confirmCancellationStatus]);

	return (
		<CancellationModalLayout canProceed={Boolean(!quoteError)} confirm={confirm} loading={loading}>
			{quoteError && <Banner.Wrapper type={'error'} muted icon={'ic_alert'}>
				{'An error occured. Please try again.'}
			</Banner.Wrapper>}
			{confirmationError && <Banner.Wrapper type={'error'} muted icon={'ic_alert'}>
				{'We encountered an issue while trying to cancel this policy. Please try again'}
			</Banner.Wrapper>}
			<Margin $marginBottom={'semiLarge'}>
				<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{'Confirm your cancellation'}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{'Here’s everything you need to know about cancelling your subscription today.'}
				</Typography>
			</Margin>
			<PolicyDetails
				title={'Your vehicle'}
				description={policyVehicle}
				icon={
					<VehicleManufacturer
						make={vehicle?.response?.make}
						size={'20px'}
						color={'textOnSurfaceBackground'}
					/>
				}
			/>
			<PolicyDetails
				title={'Cover end'}
				description={`${time} on ${date}`}
				icon={<Icon $size={'20px'} icon={'ic_timer'} $color={'textOnSurfaceBackground'} />}
			/>
			<PolicyDetails
				title={'No cancellation fee'}
				description={'But we can’t get you a refund this time'}
				icon={<Icon $size={'20px'} icon={'ic_tax_fill'} $color={'textOnSurfaceBackground'} />}
			/>
		</CancellationModalLayout>
	);
};

const PolicyDetails: FunctionComponent<PolicyDetailsProps> = ({ title, description, icon }) => (
	<PolicyDetailsWrapper>
		{icon}
		<div>
			<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
				{title}
			</Typography>
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
				{description}
			</Typography>
		</div>
	</PolicyDetailsWrapper>
);

const PolicyDetailsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${p => p.theme.spacing.large};
	margin-bottom: ${p => p.theme.spacing.semiLarge};
`;

const formattedEndDate = (endDate: string) => {
	const optionsDate: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};

	const optionsTime: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	};

	const date = new Intl.DateTimeFormat('en-GB', optionsDate).format(new Date(endDate));
	const time = new Intl.DateTimeFormat('en-GB', optionsTime).format(new Date(endDate));

	return { date, time };
};

export default ConfirmCancellation;
