import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listDiscounts } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherListDiscounts() {
    yield takeEvery(PromoActionTypes.LIST_DISCOUNTS, workerListDiscounts);
}
function* workerListDiscounts({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.listDiscounts, client, payload.userId);
        yield put(listDiscounts.success(ident, response));
    }
    catch (error) {
        yield put(listDiscounts.failure(ident, error));
    }
}
