import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import JumpTo from '../components/atoms/JumpTo';
import { PageHead } from './Guides';
import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import BoxText from '~website/components/atoms/BoxText';
import { SmallerContainer } from '~website/components/atoms/Container';
import GuideMenu from '~website/components/layout/GuideMenu';
import Meta from '~website/components/Meta';
import { GuidesTopicPage } from '~website/hooks/guides-segment';
import { useGuideContent } from '~website/hooks/use-segment';
import Page404 from '~website/pages/NotFound';

const Topic: React.FunctionComponent = () => {
	const guideSegment = useGuideContent();
	const get = useContentKey('website_guides');
	const isMobile = useMediaQuery('(max-width: 767px)');
	const history = useHistory();

	const setTopic = (topic: string) => {
		history.replace('/how-insurance-works', { topic });
	};

	if (!guideSegment) return <Page404 />;

	const { global, pages } = guideSegment.content;
	const assets = guideSegment.assets;

	const pageSlug = Object.keys(pages).find(c => pages[c].url === history.location.pathname);
	const page: GuidesTopicPage = pages[pageSlug];

	if (!page) return <Page404 />;

	const topics = Object.keys(global.guides);

	const topicContents = page.sections
		.map(section => {
			const sectionKey = Object.keys(page.articles).find(a => section.selector === a);

			if (!sectionKey) return null;

			return {
				selector: section?.selector,
				name: section?.name,
				withImage: section?.content_image,
				imageCaption: section.image_caption,
				heading: section.heading,
				callout: section.callout,
				showButton: section.show_button,
				content: page.articles[sectionKey],
			};
		})
		.filter(Boolean);

	const jumpItems = topicContents.map(({ selector, name }) => ({ selector, name }));

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`pages[${pageSlug}].head.${key}`, fallback)} />
			<GuideMenu customMaxWidth={800} topics={topics} setTopic={topic => setTopic(topic)} />
			<HeaderSection>
				<div className={'container cf'}>
					<HeaderContent>
						<TopicTitle
							$type={'Heading.XLarge'}
							$marginBottom={'24px'}
							$align={'left'}
							$color={'textOnSurfaceBackground'}
							as={'h1'}
						>
							{get(`pages[${pageSlug}].title`)}
						</TopicTitle>

						<TopicJumpTo>
							<JumpTo
								title={'Jump to'}
								items={jumpItems}
								alignTitle={isMobile ? 'left' : 'center'}
								alignBadges={'left'}
							/>
						</TopicJumpTo>
					</HeaderContent>
				</div>
			</HeaderSection>
			<BodySection>
				<BodyContent>
					{topicContents.map(content => (
						<TopicContent
							key={`${content.name}:${content.selector}`}
							id={content.selector}
							withImage={Boolean(content?.withImage)}
						>
							<div>
								{Boolean(content?.heading) && (
									<TopicTitle
										$type={'Heading.Medium'}
										$marginBottom={'24px'}
										$align={'left'}
										$color={'textOnSurfaceBackground'}
										as={'h1'}
									>
										{content.heading}
									</TopicTitle>
								)}
								<TopicText
									withImage={Boolean(content?.withImage)}
									dangerouslySetInnerHTML={{ __html: content.content }}
								/>

								{Boolean(content?.callout) && (
									<Banner.Wrapper type={'primary'} icon={'ic_info_alert'} muted>
										<Banner.Title>
											{content.callout.header}
										</Banner.Title>

										{content.callout.content}

										{content.callout.url && (
											<React.Fragment>
												{' '}
												<Banner.Link href={content.callout.url}>
													{content.callout.url_text ?? 'Learn more'}
												</Banner.Link>
											</React.Fragment>
										)}
									</Banner.Wrapper>
								)}
								{content?.showButton && (
									<ButtonWrapper>
										<Button
											as={Anchor}
											$type={'primaryMuted'}
											// @ts-ignore
											to={content.showButton.url}
										>
											{content.showButton.text}
										</Button>
									</ButtonWrapper>
								)}
							</div>

							{content?.withImage && (
								<div>
									<TopicContentImage>
										<img src={assets[content.withImage].content} />
										<Typography
											$type={'Body.Large'}
											$align={'center'}
											$color={'textMinor'}
											$marginTop={'small'}
										>
											{content.imageCaption}
										</Typography>
									</TopicContentImage>
								</div>
							)}
						</TopicContent>
					))}
					<Typography
						$type={'Body.Large'}
						$align={'center'}
						$color={'textMinor'}
						$marginBottom={'60px'}
					>
						{`Updated on ${moment(page.head?.schema?.dateModified).format('Do MMMM YYYY')}`}
					</Typography>
				</BodyContent>
			</BodySection>
		</React.Fragment>
	);
};

const HeaderSection = styled(SmallerContainer)`
	background: ${p => p.theme.ui.background};
	margin-top: -10px;
	min-height: 500px;
	max-width: 950px;
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing.large};
	padding-top: 140px;

	${media.greaterThan('tablet')({
		maxWidth: 'none',
		minHeight: '450px',
	})};
`;

const TopicJumpTo = styled.div`
	max-width: 800px;
	margin-bottom: 150px;

	${media.greaterThan('tablet')({
		textAlign: 'center',
	})};
`;

const TopicText = styled(BoxText)<{ withImage?: boolean }>`
	margin-right: ${props => props.theme.spacing.small};
	margin-bottom: ${p => (p.withImage ? 20 : 60)}px;

	h2, h3, h4 {
		margin-top: 0;
		margin-bottom: ${props => props.theme.spacing.large};
	}

	p + h2, h3, h4 {
		margin-top: 60px;
	}

	ul {
		margin-top: ${props => props.theme.spacing.large};
		margin-bottom: ${props => props.theme.spacing.large};
	}

	li {
		line-height: 1.7;
	}
`;

const TopicContent = styled.div<{ withImage?: boolean }>`
	margin-bottom: ${p => (p.withImage ? '60px' : '0')};
	display: flex;
	flex-direction: column;
	align-items: center;

	> div:first-of-type {
		${media.greaterThan('tablet')({
		marginRight: '24px',
	})};
	}

	${media.greaterThan('tablet')({
		display: 'flex',
		flexDirection: 'row',
	})};
`;

const TopicContentImage = styled.div`
	width: 338px;
	height: 271px;
	border-radius: ${p => p.theme.spacing.small};

	& > img {
		max-width: 338px;
	}
`;

const TopicTitle = styled(Typography)`
	max-width: 800px;

	${media.greaterThan('tablet')({
		textAlign: 'center',
	})};
`;

const BodyContent = styled.div`
	max-width: 800px;
	z-index: 1;
`;

const BodySection = styled(SmallerContainer)`
	background: ${p => p.theme.ui.blankBackground};
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	padding: ${props => props.theme.spacing.extraLarge} ${props => props.theme.spacing.large};
	padding-bottom: 0;
	margin-top: -100px;

	${media.greaterThan('tablet')({
		maxWidth: 'none',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	})};
`;

const HeaderContent = styled(PageHead)`
	margin-top: ${p => p.theme.spacing.extraLarge};
`;

const ButtonWrapper = styled.div`
	margin-bottom: ${props => props.theme.spacing.regular};
	width: 240px;

	${media.lessThan('tablet')({
		width: '100%',
	})};

`;

export default Topic;
