import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { userVehicleProfile } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(userVehicleProfile.request, function* userVehicleProfileSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { userId, vehicleId, revisionId } = payload;
    try {
        const response = yield call(api.getUsersVehicleProfile, client, userId, vehicleId, revisionId);
        yield put(userVehicleProfile.success(ident, response));
    }
    catch (error) {
        yield put(userVehicleProfile.failure(ident, error));
    }
});
