import styled from 'styled-components';

const StoresWrap = styled.div`
	& > * {
		justify-content: flex-start;
	}

	@media (min-width: 768px) {
		& > * {
			justify-content: flex-end;
		}
	}
`;

export default StoresWrap;
