import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { latestDocuments } from '../actions';
import * as api from '../api';
import { TermsActionTypes } from '../types';
export default function* watcherLatestDocuments() {
    yield takeEvery(TermsActionTypes.LATEST_DOCUMENTS, workerLatestDocuments);
}
function* workerLatestDocuments() {
    const client = yield getContext('api');
    try {
        const response = yield call(api.latestDocuments, client);
        yield put(latestDocuments.success(response));
    }
    catch (error) {
        yield put(latestDocuments.failure(error));
    }
}
