import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CancellationComponentFlowContext } from '../../context/CancellationFlowContext';
import { useSinglePolicy } from '../../context/SinglePolicyContext';
import useSingleSubscription from '../../hooks/use-single-subscription';
import { CancellationFlowComponents, CancellationFlowIndex } from '../../utils/cancellationFlow';
import CancellationFeedback from './CancellationFeedback';
import useFlowDialog from '~lib/frontend/design-system/components/dialog/hooks/use-flow-dialog';
import * as FlowDialog from '~lib/frontend/design-system/components/dialog/molecules/FlowDialog';
import { StatedReason } from '~lib/platform/ltm-cancellation/types';

interface CancelASubscriptionModalProps {
	open: boolean;
	onClose: () => void;
}

const CancellationFlowModal: React.FCWithChildren<CancelASubscriptionModalProps> = props => {
	const { open, onClose } = props;
	const [openFlowDialog, setOpenFlowDialog] = useState(open);
	const [openCancellationFeedback, setOpenCancellationFeedback] = useState(false);
	const policy = useSinglePolicy();

	const quoteAutocycleStore = useSelector(
		s => s.platform.ltmCancellation.quoteAutocycle[policy.subInfo.subscriptionId],
	);
	const cancellationRequestId = quoteAutocycleStore?.response?.autocycleRequestId;

	const confirmCancellationStore = useSelector(
		s => s.platform.ltmCancellation.confirmCancellation[cancellationRequestId],
	);

	const length = Object.values(CancellationFlowIndex).length / 2;
	const flow = useFlowDialog(0, length);
	const { index, goToPreviousStep, endFlow } = flow;
	const [cancellationReason, setCancellationReason] = useState<StatedReason>(void 0);
	const { refetch } = useSingleSubscription(policy.policyId);

	const closeModal = () => {
		if (confirmCancellationStore?.response) {
			setOpenFlowDialog(false);
			setOpenCancellationFeedback(true);
		} else {
			onClose();
		}

		endFlow();
	};

	const completeCancellation = () => {
		setOpenCancellationFeedback(false);
		onClose();
		refetch();
	};

	const Component = CancellationFlowComponents[index];

	const value = { flow, closeModal, cancellationReason, setCancellationReason };

	return (
		<CancellationComponentFlowContext.Provider value={value}>
			{openFlowDialog && (
				<FlowDialog.Dialog
					open={openFlowDialog}
					title={'Cancel subscription'}
					onClose={closeModal}
					totalSteps={length}
					currentStep={index}
					goToPreviousStep={goToPreviousStep}
					preventOutsideClick
				>
					<Component />
				</FlowDialog.Dialog>
			)}
			{openCancellationFeedback && (
				<CancellationFeedback open={openCancellationFeedback} onClose={completeCancellation} />
			)}
		</CancellationComponentFlowContext.Provider>
	);
};

export default CancellationFlowModal;
