import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { submitSurvey } from '../actions';
import * as api from '../api';
import { SurveyActionTypes } from '../types';
export default function* watcherSubmitSurvey() {
    yield takeEvery(SurveyActionTypes.SUBMIT_SURVEY, workerSubmitSurvey);
}
function* workerSubmitSurvey({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requestId, ...requestPayload } = payload;
    try {
        yield call(api.submitSurvey, client, requestPayload);
        yield put(submitSurvey.success(ident));
    }
    catch (error) {
        yield put(submitSurvey.failure(ident, error));
    }
}
