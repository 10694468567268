import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { quoteCancellation } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherQuoteCancellation() {
    yield takeEvery(LtmActionTypes.QUOTE_CANCELLATION, workerQuoteCancellation);
}
/* eslint-disable @typescript-eslint/no-explicit-any, max-len, @typescript-eslint/no-unused-vars */
function* workerQuoteCancellation({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.quoteCancellation, client, requestPayload);
        yield put(quoteCancellation.success(ident, response));
    }
    catch (error) {
        yield put(quoteCancellation.failure(ident, error));
        const cher = Cher.coerce(error);
        yield put(flashMessages.addError(`Unable to quote cancellation: ${cher.code}`, 'There was an unknown issue cancelling the policy', cher));
    }
}
