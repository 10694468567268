import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';

export const Overlay = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	pointer-events: none;
`;

export const LogoWrap = styled(Anchor)`
	position: absolute;
	top: 2rem;
	width: 100%;
	display: flex;
	justify-content: center;

	svg {
		height: 32px;
		margin-bottom: ${p => p.theme.spacing.extraLarge};

		path {
			fill: ${p => p.theme.ui.blankFill};
		}

		${media.greaterThan('laptop')`
			margin-bottom: 48px;
		`}
	}
`;

export const Wrapper = styled.div`
	margin-bottom: 15rem;
	height: 100%;

	${media.greaterThan('laptop')`
		margin-bottom: 4rem;
	`}
`;

export const InnerContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
`;

export const HeadingsWrapper = styled.div`
	max-width: 100%;
	height: 100%;
	padding: ${p => p.theme.spacing.regular};

	${media.greaterThan('laptop')`
		max-width: 50%;
	`}
`;

export const SectionHeaderText = styled.div`
	${p => p.theme.typography['Heading.Large']()};
	color: ${p => p.theme.ui.textOnSurfaceBackground};
	margin-bottom: ${p => p.theme.spacing.extraLarge};
	width: 100%;

	${media.greaterThan('laptop')`
		width: 80%;
	`}
`;

export const HeaderSharesWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: column;
	align-items: center;
	position: absolute;
	top: 95%;
	padding: ${p => p.theme.spacing.regular};
`;

export const OfferStartAndSharesWrapper = styled.div`
	padding: ${p => p.theme.spacing.extraLarge};
	box-shadow: ${p => p.theme.boxShadow.small};
	background-color: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};
	display: block;
	width: 85%;
	margin-bottom: ${p => p.theme.spacing.large};

	${media.greaterThan('laptop')`
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 65%;
	`}
`;

export const RuleSeparator = styled.div`
	height: 2px;
	width: 100%;
	margin: ${p => p.theme.spacing.regular} 0;
	background-color: ${p => p.theme.ui.borderSeparator};

	${p => media.greaterThan('laptop')`
		height: 3rem;
		width: 2px;
		margin: 0 ${p.theme.spacing.regular};
	`}
`;
