import styled from 'styled-components';

import Container from '~website/components/atoms/Container';

const Section = styled(Container)`
	margin-top: 160px;

	@media (max-width: 767px) {
		& + & {
			margin-top: 75px;
		}
	}
`;

export default Section;
