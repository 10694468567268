import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import HeaderHero from '~website/components/Hero/HeaderHero';
import { clearAuthUser } from '~website/store/auth/actions';

const Goodbye: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Sign out | Cuvva'}</title>
				<meta name={'robots'} content={'noindex, follow'} />
			</Helmet>
			<HeaderHero
				titleSegment={{
					centered: true,
					title: 'Goodbye 👋',
					body: 'Ends your session by tapping below',
					showButton: {
						text: 'Sign me out',
						action: () => void dispatch(clearAuthUser()),
					},
				}}
				mainImage={{
					type: 'background',
					value: 'hero_stm.png',
				}}
			/>
		</React.Fragment>
	);
};

export default Goodbye;
