import { CreateQuoteResponse, Pricing } from '~lib/platform/ltm/types';
import { DiscountRequirement, DiscountV3 } from '~lib/platform/promo/types';
import { formatDate, pastEndDate, toDaysMinutesSeconds } from '~website/features/policy/utils/formatDateTime';
import { plainMoney } from '~website/helpers/monies';

export const containsDiscount = (discounts: CreateQuoteResponse['eligibleDiscounts'], pricing: Pricing) => {
	const activeDiscounts = discounts?.filter(d => !d.expired || !d.used) ?? [];
	const checkPricingForDiscount = pricing?.discount?.type || pricing?.discount?.value;

	if (checkPricingForDiscount)
		return true;

	return activeDiscounts.length > 0;
};

export function discountInfo(discount: DiscountV3) {
	const pricingType = discount.pricing.type;
	const isRedeemed = discount.used;
	const isExpired = pastEndDate(discount.expiresAt);

	const referrer = discount?.referringUser ?? null;

	const titleString = () => {
		const preset = referrer ? `${referrer.name} sent you ` : '';
		const cost = pricingType === 'flat' ? plainMoney(discount.pricing.value) : `${discount.pricing.value}%`;

		if (discount.pricing.type === 'cost_price')
			return 'Cost price';

		return `${preset}${cost} off`;
	};

	return {
		title: titleString,
		type: () => pricingType === 'flat' ? 'fixed' : 'percent',
		state: () => {
			if (discount.used || isExpired)
				return 'inactive';

			return 'active';
		},
		expiryDate: () => {
			if (isRedeemed)
				return `Redeemed at ${formatDate(discount.usedAt).date}`;

			if (isExpired)
				return `Expired at ${formatDate(discount.expiresAt).date}`;

			return `Expires at ${formatDate(discount.expiresAt).date}`;
		},
		requirements: () => parseRequirements(discount.requirements),
	};
}

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
	['one', 'st'],
	['two', 'nd'],
	['few', 'rd'],
	['other', 'th'],
]);

const formatOrdinals = (n: number) => {
	const rule = pr.select(n);
	const suffix = suffixes.get(rule);

	return `${n}${suffix}`;
};

const parseRequirements = (requirements: DiscountRequirement[]) => {
	if (!requirements?.length)
		return [];

	const elements = [];

	for (const req of requirements) {
		switch (req.type) {
			case 'product': {
				const extra = [];

				// type driving is stm, motor_long_term is ltm
				// if max_length is in seconds, then its STM and changes to hours

				if (req.params.type === 'motor_long_term') {
					if (req.params.cycle)
						extra.push(`subscription in the ${formatOrdinals(Number(req.params.cycle))} month`);

					elements.push([...extra].join(' '));
				}

				if (req.params.type === 'driving') {
					// NB: Not sure how to support max_length for STM yet
					if (req.params.maxLength)
						extra.push(`up to ${toDaysMinutesSeconds(req.params.maxLength as number)}`);

					elements.push(['on your short term policy', ...extra].join(' '));
				}

				break;
			}
			case 'no_previous_policies':
			case 'no_previous_policies_3m_expiry':
			case 'no_previous_policies_dynamic_expiry':
				elements.push('your first STM policy');
				break;
			default:
				elements.push(JSON.stringify(req));
		}
	}

	return elements;
};
