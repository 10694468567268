import styled, { css } from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

export const SmartPricingCardHeading = styled.div`
	background: ${p => p.theme.ui.primaryFill};
	border: 2px solid ${p => p.theme.ui.borderInteractiveFocus};

	border-top-left-radius: ${e => e.theme.borderRadius.large};
	border-top-right-radius: ${e => e.theme.borderRadius.large};
	position: relative;
	z-index: 2;
`;

export const SmartPricingContentWrapper = styled.div`
	position: relative;

	padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large};

	${Typography} {
		width: 90%;
	}

	background: ${p => p.theme.ui.surfaceFill};

	border-bottom-left-radius: ${e => e.theme.borderRadius.large};
	border-bottom-right-radius: ${e => e.theme.borderRadius.large};

	box-shadow: inset 0 0 0 1px ${p => p.theme.ui.borderSeparator};
	margin-top: -2px;
`;

export const SmartPricingCardContainer = styled.div<{ active: boolean; disabled: boolean }>`
	${SmartPricingContentWrapper} {
		z-index: 1;
		${p => p.active && css`
			box-shadow: inset 0 0 0 2px ${p => p.theme.ui.borderInteractiveFocus};
		`}
	}

	pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
`;

export const ToggleSmartPricingContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: ${p => p.theme.spacing.small};
`;

export const SmartPricingProcessList = styled.ul`
	list-style: none;
	display: grid;
	grid-template-columns: 1fr;
	gap: ${p => p.theme.spacing.large};

	li {
		display: flex;
		align-items: center;
	}
`;

export const IconContainer = styled.div`
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${e => e.theme.borderRadius.small};
	padding: ${p => p.theme.spacing.small};
	margin-right: ${p => p.theme.spacing.regular};
`;

export const SmartPricingDialogContentWrapper = styled.div`
	text-align: center;
`;
