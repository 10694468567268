import jsonClient, { Client } from '@cuvva/json-client';
import camelize from 'camelize';

export interface ExpandedAddressResponse {
	postcode: string;
	latitude: number;
	longitude: number;
	addresses: FullAddress[];
}

export interface FullAddress {
	formattedAddress: string[]; // should be 5 strings
	thoroughfare: string;
	buildingName: string;
	subBuildingName: string;
	subBuildingNumber: string;
	buildingNumber: string;
	line1: string;
	line2: string;
	line3: string;
	line4: string;
	locality: string;
	townOrCity: string;
	county: string;
	district: string;
	country: string;
}

export class GetAddressIO {
	private apiKey: string = void 0;
	private baseUrl = 'https://api.getAddress.io/find';
	private client: Client;

	constructor(apiKey: string) {
		this.apiKey = apiKey;
		this.client = jsonClient(this.baseUrl);
	}

	async listAddressesByPostcode(postcode: string): Promise<ExpandedAddressResponse> {
		const resp = await this.client('GET', postcode, {
			'api-key': this.apiKey,
			'expand': 'true',
		}, null);

		return camelize(resp) as ExpandedAddressResponse;
	}
}
