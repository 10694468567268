import Cher from '@cuvva/cher';

const validCoordinatesValidator = (value: { longitude: number; latitude: number}) => {
	if (!value || typeof value?.longitude !== 'number' || typeof value?.latitude !== 'number')
		return new Cher('invalid');

	if (value.latitude !== 0 && value.longitude !== 0)
		return void 0;

	return new Cher('invalid');
};

export default validCoordinatesValidator;
