import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import Form from './atoms/Form';
import AnnualMileage from './binded-fields/AnnualMileage';
import BusinessUse from './binded-fields/BusinessUse';
import EstimatedValue from './binded-fields/EstimatedValue';
import RegisteredKeeper from './binded-fields/RegisteredKeeper';
import StorageType from './binded-fields/StorageType';
import VehicleOwner from './binded-fields/VehicleOwner';
import StorageLocationWithLookup from './molecules/address-lookup/StorageLocationWithLookup';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import Typography from '~lib/frontend/design-system/components/Typography';

const VehicleInfo: React.FunctionComponent = () => {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const vehicleId = useSelector(s => s.internal.quote.state.vehicleId);
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const needsEstimate = vehicle?.response?.valueKnown === false;

	const formTracking = useFormEventTracking();

	return (
		<Form {...formTracking}>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'About the car'}
				</Typography>

				<PageError page={page} />
			</div>

			<VehicleOwner />
			<RegisteredKeeper />
			{needsEstimate && (
				<EstimatedValue />
			)}
			<AnnualMileage />
			<BusinessUse />
			<StorageType />
			<StorageLocationWithLookup />

			<ProceedButton path={'/quote/personal'}>
				{'Continue'}
			</ProceedButton>
		</Form>
	);
};

export default VehicleInfo;
