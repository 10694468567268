import React from 'react';
import { useDispatch } from 'react-redux';

import { trackQuoteEvent } from '~website/features/analytics/store/actions';

export type FormTrackingEventProps = ReturnType<typeof useFormEventTracking>;

interface TrackedFormEvent<HTMLFormElement> extends React.FormEvent<HTMLFormElement> {
	trackedValue?: unknown;
}

const useFormEventTracking = () => {
	const dispatch = useDispatch();

	const handleOnFocusEventTracking = (e: React.FocusEvent<HTMLFormElement, Element>) => {
		const dataSet = e.target.dataset;
		const eventName = dataSet?.eventOnfocusName;
		const eventValue = dataSet.eventOnfocusValue;

		if (eventName && eventValue) {
			dispatch(trackQuoteEvent({
				action: eventName,
				value: eventValue,
			}));
		} else if (eventName) {
			dispatch(trackQuoteEvent({
				action: eventName,
			}));
		}

		return;
	};

	const handleOnChangeEventTracking = (e: TrackedFormEvent<HTMLFormElement>) => {
		const event = e.target as EventTarget & HTMLFormElement;
		const inputType = event.type;
		const dataSet = event.dataset;
		const eventName = dataSet?.eventOnchangeName;

		// firstly trying to get value from data-attributes, then value from TrackedSelectInput, then default event value
		const eventValue = dataSet?.eventOnchangeValue || e.trackedValue || event.value as string;

		const checkedEvent = inputType === 'checkbox' ? event.checked : eventValue;

		if (eventName && checkedEvent) {
			dispatch(trackQuoteEvent({
				action: eventName,
				value: checkedEvent,
			}));
		}

		return;
	};

	return {
		onFocusCapture: handleOnFocusEventTracking,
		onChange: handleOnChangeEventTracking,
	};
};

export default useFormEventTracking;
