import React from 'react';

import { AdapterProps } from '../types';
import MeetTheTeam, { contentAdapter } from '~website/features/about-cuvva/components/organisms/MeetTheTeam';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<MeetTheTeam {...contentAdapter(getter)} />
);

export default {
	type: 'about_meet_the_team',
	adapter,
	builder: {
		fields: {
			title: { type: 'string' },
			body: { type: 'string' },
			button_content: { type: 'string' },
		},
		default: {
			title: 'Meet the team',
			body: 'We’re a team of dreamers, designers and customer champions turning the way the industry is run upside down.',
			button_content: 'Want to work with us?',
		},
	},
};
