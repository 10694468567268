import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Markdown from '~lib/frontend/atoms/Markdown';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

export interface ShowChecklist {
	label: string;
	checked?: boolean;
}

interface ChecklistProps {
	checklist: ShowChecklist[];
}

const CardChecklist: FunctionComponent<ChecklistProps> = ({ checklist }) => {
	if (!checklist || !checklist.length) return null;

	return (
		<div>
			{checklist.map((item, i) => {
				if (!item) return null;
				const { label = '', checked = true } = item;

				return (
					<ChecklistItem key={i}>
						<Icon icon={checked ? 'ic_check' : 'ic_cross'} $size={'25px'} $color={'primaryFill'} />
						<Typography
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackgroundMuted'}
							$marginLeft={'regular'}
						>
							<Markdown>{label}</Markdown>
						</Typography>
					</ChecklistItem>
				);
			})}
		</div>
	);
};

const ChecklistItem = styled.div`
	display: flex;
	min-height: fit-content;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	margin-top: ${p => p.theme.spacing.large};

	&:first-child {
		margin-top: 0;
	}

	> * {
		flex: 1;

		&:first-child {
			flex: 0 0 25px;
		}
	}
`;

export default CardChecklist;
