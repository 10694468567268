import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { listDiscountsPublic } from '~lib/platform/promo/store/actions';
import { DiscountV3 } from '~lib/platform/promo/types';
import { pastEndDate } from '~website/features/policy/utils/formatDateTime';
import useUser from '~website/hooks/use-user';

const generateRequestId = () => ksuid.generate('request').toString();

export interface ActiveDiscountsProps {
	loading: boolean;
	discounts: DiscountV3[];
}

export interface DiscountProps {
	discounts: ActiveDiscountsProps['discounts'];
}

const useDiscounts = () => {
	const dispatch = useDispatch();
	const [requestId] = useState(generateRequestId);
	const discountStore = useSelector(s => s.platform.promo.listDiscountsPublic[requestId]);
	const { userId } = useUser();

	const loading = useAsyncStateStatus(discountStore).is('pending', 'fetching');
	const error = useAsyncStateStatus(discountStore).is('error');

	const discounts = discountStore?.response;
	const activeDiscounts = useMemo(() => discounts?.filter(d => !d.used && !pastEndDate(d.expiresAt)) ?? [], [discounts]);
	const previousDiscounts = useMemo(() => discounts?.filter(d => d.used || pastEndDate(d.expiresAt)) ?? [], [discounts]);

	useEffect(() => {
		dispatch(listDiscountsPublic.request({
			requestId,
			userId,
			includeUsed: true,
			includeExpired: true,
		}));
	}, [dispatch, requestId, userId]);

	return {
		loading,
		activeDiscounts: error ? [] : activeDiscounts,
		previousDiscounts: error ? [] : previousDiscounts,
	};
};

export default useDiscounts;
