import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BroadcastChannel } from 'broadcast-channel';
import Cookies from 'js-cookie';

import { cookiePolicyConsent } from '~website/constants/storage';
import { cookieConsentAction } from '~website/features/cookie-policy/store/actions';
import { CookieConsentType } from '~website/features/cookie-policy/store/types';

export type CookieNotice = ReturnType<typeof useCookieNotice>;

const useCookieNotice = () => {
	const dispatch = useDispatch();
	const consent = useSelector(s => s.internal.cookieConsent.consent);

	useEffect(() => {
		if (consent !== 'pending')
			return;

		const value = Cookies.get(cookiePolicyConsent) as CookieConsentType ?? null;

		dispatch(cookieConsentAction.setCookieConsent(value));
	}, [consent, dispatch]);

	const onChange = useCallback((consent: CookieConsentType) => {
		dispatch(cookieConsentAction.setCookieConsent(consent));
	}, [dispatch]);

	useEffect(() => {
		const bc = new BroadcastChannel('cuvva-cookie-consent');

		if (consent && consent !== 'pending')
			bc.postMessage(JSON.stringify({ consent }));

		bc.onmessage = (e: string) => {
			try {
				const { consent } = JSON.parse(e);

				onChange(consent);
			} catch {
				// do nothing
			}
		};

		return () => void bc.close();
	}, [consent, onChange]);

	return {
		consent,
		onChange,
	};
};

export default useCookieNotice;
