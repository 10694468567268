import { call, getContext, put } from 'redux-saga/effects';
import { getAndParseLiteSegment } from '../../utils';
import { getSegment } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getSegment.request, function* getSegmentSaga(action) {
    const client = yield getContext('api');
    const { ident } = action.meta;
    try {
        const seggy = yield call(getAndParseLiteSegment, client, action.payload);
        yield put(getSegment.success(ident, seggy));
    }
    catch (error) {
        yield put(getSegment.failure(ident, error));
    }
});
