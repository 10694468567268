import React, { useState } from 'react';
import { CardWrapper } from './atoms/CardWrapper';
import CardBody from './CardBody';
import { CardHeader, CardHeaderText } from './CardHeader';
import CardHeaderAction from './CardHeaderAction';
import { CardHeaderGroup } from './CardHeaderGroup';
import { CardHeaderGroupAction } from './CardHeaderGroupAction';
import CardContext from './context';
const Card = props => {
    const { children, collapsible, error, loading, collapsed, ...rest } = props;
    const [open, setOpen] = useState(() => !collapsed);
    const toggleCollapse = () => {
        if (collapsible)
            setOpen(!open);
    };
    const value = { collapsible, error, loading, open, toggleCollapse, ...rest };
    return (React.createElement(CardContext.Provider, { value: value },
        React.createElement(CardWrapper, { "$error": error, "$loading": loading }, children)));
};
export { CardHeaderGroup, CardHeaderGroupAction, CardHeader, CardHeaderText, CardBody, CardHeaderAction };
export default Card;
