import styled, { css } from 'styled-components';
import { ComplexDialogTitleBar } from './ComplexDialogStyle';
export const FlowDialogTitleBar = styled(ComplexDialogTitleBar) `
	border-bottom: 4px solid ${p => p.theme.ui.borderSeparator};
	position: relative;

	${p => p.$preventBackNavigation && css `
		& > :first-child {
			flex: 0 0 0px;
		}
	`}

	:after {
		position: absolute;
		content: '';
		bottom: -4px;
		left: 0;
		width: ${p => `${p.$stage}%`};
		transition: width 0.2s ease-in-out;
		height: 4px;
		background-color: ${p => p.theme.ui.borderInteractiveFocus};
	}
`;
export const BackIconWrapper = styled.div `
	width: 24px;
	height: 24px;
	cursor: pointer;
	margin-right: ${p => p.theme.spacing.large};
	transition: all 0.2s ease-in-out;

	${p => p.$noDisplay && css `
		visibility: hidden;
		margin-right: 0;
		width: 0px;
	`};
`;
