import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import { createGlobalStyle } from 'styled-components';
import ModalCover from '../atoms/ModalCover';
import useAnimateModal from '../hooks/use-animate-modal';
import useScrollLock from '~lib/frontend/hooks/use-scroll-lock';
export const Modal = props => {
    const { title, verticalAlign, dismissible, onClose, open, preventOutsideClick = false, children } = props;
    const { bgRendered, dialogRendered } = useAnimateModal(open, 300);
    const modalRef = useRef(null);
    const focusTrapOptions = {
        fallbackFocus: () => modalRef?.current,
        initialFocus: () => modalRef?.current,
        allowOutsideClick: false,
        preventScroll: true,
        escapeDeactivates: false,
    };
    useScrollLock(bgRendered);
    const onKeyDown = (e) => {
        if (!dismissible)
            return;
        if (['Escape', 'Esc'].includes(e.key))
            onClose?.();
    };
    const onClickOutside = (e) => {
        const target = e.target;
        const clickWithin = modalRef?.current?.contains(target);
        const isOnOverlay = modalRef?.current === target;
        if (!dismissible || (clickWithin && !isOnOverlay) || preventOutsideClick) {
            e.stopPropagation();
            return;
        }
        onClose?.();
    };
    useEffect(() => {
        if (open)
            document.documentElement.classList.add('is-locked');
        else
            document.documentElement.classList.remove('is-locked');
        return () => {
            document.documentElement.classList.remove('is-locked');
        };
    }, [open]);
    useEffect(() => {
        function syncHeight() {
            document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
        }
        window.addEventListener('resize', syncHeight);
        return () => window.removeEventListener('resize', syncHeight);
    }, []);
    const GlobalStyle = createGlobalStyle `
		html.is-locked,
		html.is-locked body {
			/* want to fix the height to the window height */
			height: calc(var(--window-inner-height) - 1px);

			/* want to block all overflowing content */
			overflow: hidden;

			/* want to exclude padding from the height */
			box-sizing: border-box;
		}
	`;
    if (!open && !bgRendered)
        return null;
    return ReactDOM.createPortal(React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(FocusTrap, { key: String(title), active: dialogRendered, focusTrapOptions: focusTrapOptions },
            React.createElement(ModalCover, { key: String(title), "$verticalAlign": verticalAlign, role: 'dialog', "aria-modal": 'true', onClick: onClickOutside, onKeyDown: onKeyDown, "$display": bgRendered, "$visible": dialogRendered, tabIndex: -1, ref: modalRef, "aria-label": String(title) }, children))), document.body);
};
export default Modal;
