import { all, fork } from 'redux-saga/effects';

import clearAuthUser from './clear-auth-user';
import importAuthedUser from './import-authed-user';
import importUser from './import-user';
import registerIntercom from './register-intercom';
import registerIterable from './register-iterable';
import registerMixPanel from './register-mixpanel';
import registerSentry from './register-sentry';
import registerUser from './register-user';

export default function* authSaga() {
	yield all([
		clearAuthUser,
		fork(importUser),
		fork(registerIntercom),
		fork(registerMixPanel),
		fork(registerSentry),
		fork(registerUser),
		fork(registerIterable),
		importAuthedUser,
	]);
}
