import styled, { keyframes } from 'styled-components';
import { getButtonTextColors } from '../helpers';
const Rotate = keyframes `
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;
const SpinnerWrapper = styled.div `
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		transform-origin: center;
		animation: ${Rotate} .6s linear infinite;
		--fill: ${p => getButtonTextColors(p.theme, p.buttonType)};
	}
`;
export default SpinnerWrapper;
