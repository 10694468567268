import React from 'react';
import styled from 'styled-components';

import type { FlattenPolicySubs } from '../hooks/use-subscriptions';
import PolicyCoreCard from './PolicyCoreCard';
import Card from '~lib/frontend/design-system/components/card/Card';
import CardBody from '~lib/frontend/design-system/components/card/CardBody';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CurrentPolicyProps {
	subs: FlattenPolicySubs[];
}

const CurrentPolicy: React.FCWithChildren<CurrentPolicyProps> = ({ subs }) => (
	<React.Fragment>
		<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
			{'Current'}
		</Typography>

		<Policies>
			{subs.map(sub => <SingleCurrentPolicy key={sub.policyId} sub={sub} />)}
		</Policies>
	</React.Fragment>
);

export default CurrentPolicy;

interface SingleCurrentPolicyProps {
	sub: FlattenPolicySubs;
}

const SingleCurrentPolicy: React.FCWithChildren<SingleCurrentPolicyProps> = ({ sub }) => (
	<Card>
		<CardBody>
			<PolicyCoreCard sub={sub} />
		</CardBody>
	</Card>
);

const Policies = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${p => p.theme.spacing.large};
`;
