import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { updateIncident as updateIncidentApi } from '../../api';
import { listLatestIncidents, updateIncident } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(updateIncident.request, function* workerUpdateIncident({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { userId, incidentId, changes } = payload;
    try {
        yield call(updateIncidentApi, client, userId, incidentId, changes);
        yield put(updateIncident.success(ident));
        yield put(listLatestIncidents.request({ userId }));
        yield put(flashMessages.addSuccess('Incident successfully updated 🚀'));
    }
    catch (error) {
        yield put(updateIncident.failure(ident, error));
        yield put(flashMessages.addError('Unable to update incident', 'There was an unknown issue updating the incident', Cher.coerce(error)));
    }
});
