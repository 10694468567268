import React, { forwardRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';
import URI from 'urijs';
import { getEnvironment } from '../helpers/environment';
import { createAwareUrl } from '../helpers/uri';
const Anchor = forwardRef((props, ref) => {
    const { children, href, to, replace, skipEnvSet, ...otherProps } = props;
    // We can use `as string` here as we have prop type safety
    let ensuredPath = href || to || '';
    const sameOrigin = (function checkSameOrigin() {
        if (getEnvironment() === 'server')
            return void 0;
        return new URL(document.baseURI).origin === new URL(ensuredPath, document.baseURI).origin;
    }());
    const uri = ensuredPath ? URI(ensuredPath) : void 0;
    const target = sameOrigin === true ? void 0 : '_blank';
    const rel = sameOrigin === true ? void 0 : 'noopener noreferrer';
    if (sameOrigin === true) {
        const isFragment = ensuredPath?.startsWith('#');
        if (uri && !uri.hasQuery('env') && !skipEnvSet && !isFragment)
            ensuredPath = createAwareUrl(ensuredPath);
        return (React.createElement(HashLinkWrapper, { replace: replace, ...otherProps, to: ensuredPath, ref: ref }, children));
    }
    return (React.createElement(AnchorWrapper, { rel: rel, ref: ref, target: target, ...otherProps, href: ensuredPath }, children));
});
export default Anchor;
const HashLinkWrapper = styled(HashLink) `
	${({ disabled }) => disabled && css `
		cursor: not-allowed;
		opacity: 0.4;
		pointer-events: none;
	`}
`;
const AnchorWrapper = styled.a `
	${({ disabled }) => disabled && css `
		cursor: not-allowed;
		opacity: 0.4;
		pointer-events: none;
	`}
`;
