import React from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { getAnnualMileageOptions } from '~lib/platform/ltm/helpers/annual-mileage';

const hintContent = 'Look back at last year’s mileage, or figure out roughly how many miles you to drive in an average week and multiply it by 52';

const AnnualMileage: React.FunctionComponent = () => {
	const g = useContentKey('strings');

	const { value, validation, onChange } = useVehicleProfileInternalChangeRequest('annualMileage', void 0, notEmptyValidator);
	const error = useOnPageError(validation, 'annual_mileage_input');
	const ltmConfig = useSelector(s => s.platform.ltm.config.response);
	const options = getAnnualMileageOptions(ltmConfig?.vehicle?.annualMileage);

	return (
		<QuoteInputDecorator
			label={g('motor.long_term.questions.vehicle.annual_mileage.question', 'What\'s the annual mileage?')}
			error={error}
			help={g('motor.long_term.questions.vehicle.annual_mileage.detail', hintContent)}
		>
			<TrackedSelectInput
				value={String(value)}
				options={options}
				placeholder={'Choose an option'}
				data-event-onfocus-name={'dropdown_selected'}
				data-event-onfocus-value={'annual_mileage'}
				data-event-onchange-name={'annual_mileage_selected'}
				onChange={({ value }) => onChange(parseInt(value, 10))}
			/>
		</QuoteInputDecorator>
	);
};

export default AnnualMileage;
