import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { redeemCode } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherRedeemCode() {
    yield takeEvery(PromoActionTypes.REDEEM_CODE, workerRedeemCode);
}
function* workerRedeemCode({ payload, meta }) {
    const { ident } = meta;
    const { code, userId } = payload;
    const client = yield getContext('api');
    try {
        yield call(api.redeemCode, client, userId, code);
        yield put(redeemCode.success(ident));
    }
    catch (error) {
        yield put(redeemCode.failure(ident, error));
    }
}
