import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { userReferees } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherUserReferees() {
    yield takeEvery(PromoActionTypes.USER_REFEREES, workerUserReferees);
}
function* workerUserReferees({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call(api.userReferees, client, payload.userId);
        yield put(userReferees.success(ident, response));
    }
    catch (error) {
        yield put(userReferees.failure(ident, error));
    }
}
