import styled, { css } from 'styled-components';

export const SplashContent = styled.div<{ $background: string }>`
	margin-top: -57px;
	background: url(${p => p.$background});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 364px;

	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.small};
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-bottom: 50px;

	@media (min-width: 1024px) {
		padding-bottom: 0;
		min-height: 408px;
		margin-top: -73px;
	}
`;

export const QuoteLayoutWrap = styled.div`
	padding: 0 ${p => p.theme.spacing.large};
	background: ${p => p.theme.ui.background};
	padding-bottom: 48px;

	@media (max-width: 1024px) {
		padding-bottom: 0;
		margin-bottom: -52px;
	}
`;

export const QuoteLayoutLowerSplash = styled.div`
	padding: 48px 0 80px 0;
	background: ${p => p.theme.ui.primaryFill};
`;

export const QuoteLayoutLowerSplashInner = styled.div`
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing.extraLarge};
	max-width: calc(564px + ${p => p.theme.spacing.extraLarge} + ${p => p.theme.spacing.extraLarge});
`;

export const QuoteLayout = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: 1fr;
	grid-row-gap: ${p => p.theme.spacing.large};
	margin: 0 auto;
	top: -100px;
	max-width: 688px;

	@media (min-width: 1024px) {
		max-width: 1120px;
		grid-row-gap: 0;
		top: 0;
		margin-top: 0;
		padding-top: 48px;

		grid-template-columns: 1fr 400px;
		grid-column-gap: ${p => p.theme.spacing.extraLarge};
	}
`;

export const PriceWrap = styled.div<{ $docsHeigh: number }>`
	grid-column: 1;
	grid-row: 1;

	@media (min-width: 1024px) {
		position: sticky;
		top: ${p => p.theme.spacing.large};
		margin-bottom: ${p => p.$docsHeigh + 32}px;

		grid-column: 2;
		grid-row: 1;
	}
`;

export const PolicyDocsWrap = styled.div<{ $stickyOffset: number; $docsHeigh: number }>`
	grid-column: 1;
	grid-row: 3;

	@media (min-width: 1024px) {
		position: sticky;
		top: ${p => p.$stickyOffset + 32}px;
		margin-top: ${p => (p.$docsHeigh + 16) * -1}px;
		height: ${p => p.$docsHeigh + 16}px;
		overflow: visible;

		grid-column: 2;
		grid-row: 2;
	}
`;

export const DetailsCard = styled.div`
	grid-column: 1;
	grid-row: 2;
	display: grid;
	grid-template-columns: 1fr;
	gap: ${p => p.theme.spacing.large};

	@media (min-width: 1024px) {
		grid-column: 1;
		grid-row: 1 / span 3;
	}
`;

export const TrustpilotAligner = styled.div<{ $mobile: boolean }>`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	${p => p.$mobile && css`
		@media screen and (min-width: 1024px) {
			display: none;
		}
	`}
	${p => !p.$mobile && css`
		display: none;
		padding-bottom: 0;

		@media screen and (min-width: 1024px) {
			display: flex;
		}
	`}
`;

export const TrustpilotWrap = styled.div`
	margin: 64px auto ${p => p.theme.spacing.extraLarge} auto;
	text-align: center;
`;
