import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getNextBillingDate } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherGetNextBillingDate() {
    yield takeEvery(LtmActionTypes.GET_NEXT_BILLING_DATE, workerGetNextBillingDate);
}
function* workerGetNextBillingDate({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.getNextBillingDate, client, payload);
        yield put(getNextBillingDate.success(ident, response));
    }
    catch (error) {
        yield put(getNextBillingDate.failure(ident, error));
    }
}
