import { combineReducers } from 'redux';
import { IncidentActionTypes, initialIncidentState } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
const clearIncidents = createAsyncMapReducer(IncidentActionTypes.CLEAR_INCIDENTS, initialIncidentState.clearIncidents);
const createIncident = createAsyncMapReducer(IncidentActionTypes.CREATE_INCIDENT, initialIncidentState.createIncident);
const listLatestIncidents = createAsyncMapReducer(IncidentActionTypes.LIST_LATEST_INCIDENTS, initialIncidentState.listLatestIncidents);
const removeIncident = createAsyncMapReducer(IncidentActionTypes.REMOVE_INCIDENT, initialIncidentState.removeIncident);
const updateIncident = createAsyncMapReducer(IncidentActionTypes.UPDATE_INCIDENT, initialIncidentState.updateIncident);
export default combineReducers({
    clearIncidents,
    createIncident,
    listLatestIncidents,
    removeIncident,
    updateIncident,
});
