import { call, getContext, put } from 'redux-saga/effects';
import { listPaymentMethods } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(listPaymentMethods.request, function* listPaymentMethodsSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { userId } = payload;
    const { ident } = meta;
    try {
        const response = yield call(api.listPaymentMethods, client, userId);
        yield put(listPaymentMethods.success(ident, response));
    }
    catch (error) {
        yield put(listPaymentMethods.failure(ident, error));
    }
});
