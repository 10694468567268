import React from 'react';

import { BuilderMeta } from '../types';
import { FieldWrap } from './styled';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextAreaInput from '~lib/frontend/design-system/components/input/molecules/TextAreaInput';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
interface MetaBuilderProps {
	state: BuilderMeta;
	setState: (state: BuilderMeta) => void;
}

const MetaBuilder: React.FCWithChildren<MetaBuilderProps> = ({ state, setState }) => (
	<div>
		<InputDecorator label={'Title'}>
			<TextInput
				$size={'small'}
				value={state.title}
				onChange={e => setState({ ...state, title: e.target.value })}
				data-testid={'title'}
			/>
		</InputDecorator>

		<InputDecorator label={'Description'}>
			<TextAreaInput
				$size={'small'}
				value={state.description}
				data-testid={'description'}
				onChange={e => setState({ ...state, description: e.target.value })}
			/>
		</InputDecorator>

		<InputDecorator label={'Social title'}>
			<TextInput
				$size={'small'}
				value={state.social_title}
				onChange={e => setState({ ...state, social_title: e.target.value })}
				data-testid={'social_title'}
			/>
		</InputDecorator>

		<InputDecorator label={'Social description'}>
			<TextAreaInput
				$size={'small'}
				value={state.social_description}
				onChange={e => setState({ ...state, social_description: e.target.value })}
				data-testid={'social_description'}
			/>
		</InputDecorator>

		<FieldWrap>
			<Typography $type={'Label.Medium'}>{'Do not index?'}</Typography>
			<input
				type={'checkbox'}
				checked={state.noindex}
				onChange={e => setState({ ...state, noindex: e.target.checked })}
				data-testid={'noindex'}
			/>
		</FieldWrap>
	</div>
);

export default MetaBuilder;
