import { all } from 'redux-saga/effects';

import accountsEvent from './accounts-event';
import accountsScreenView from './accounts-screen-view';
import ownersEvent from './owners-event';
import ownersScreenView from './owners-screen-view';
import payOnWebScreenView from './pay-on-web-screen-view';
import paymentErrorEvent from './payment-error-event';
import quoteEvent from './quote-event';
import quoteScreenView from './quote-screen-view';
import referralEmailTrigger from './referral-email';
import screenView from './screen-view';
import trackEvent from './track-event';

export default function* analyticsSaga() {
	yield all([
		accountsEvent,
		accountsScreenView,
		screenView,
		trackEvent,
		payOnWebScreenView,
		quoteScreenView,
		quoteEvent,
		ownersEvent,
		ownersScreenView,
		referralEmailTrigger,
		paymentErrorEvent,
	]);
}
