import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

export const MeCardContainer = styled.div`
	background: ${p => p.theme.ui.background};

	padding: ${p => p.theme.spacing.semiLarge};
	${p => media.greaterThan('mobile')`
		padding: ${p.theme.spacing.extraLarge};
	`};
	${p => media.greaterThan('tablet')`
		padding: ${p.theme.spacing['3xLarge']}  ${p.theme.spacing.extraLarge};
	`};
`;

export const MeCardWrapper = styled.div`
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	border: none;
	overflow: hidden;
	background-color: transparent;
	border-radius: ${p => p.theme.borderRadius.large};
`;

export const SinglePolicyWrapper = styled(MeCardWrapper)`
	max-width: 1120px;
`;

export const MeCardBody = styled.div`
	padding: ${p => p.theme.spacing.semiLarge};
	${p => media.greaterThan('mobile')`
		padding: ${p.theme.spacing.extraLarge};
	`};
	${p => media.greaterThan('tablet')`
		padding: ${p.theme.spacing['3xLarge']}  ${p.theme.spacing.extraLarge};
	`};

	background-color: ${p => p.theme.ui.surface};
`;

export const SinglePolicyCardWrapper = styled(MeCardBody)`
	border-radius: ${p => p.theme.borderRadius.large};
`;

export const SmallCardWrapper = styled.div`
	background-color: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.large};

	@media (min-width: 628px) {
		padding: ${p => p.theme.spacing.semiLarge};
	}
`;

export const BigCardWrapper = styled(SmallCardWrapper)`
	padding: ${p => p.theme.spacing.semiLarge} ${p => p.theme.spacing.large};

	@media (min-width: 628px) {
		padding: ${p => p.theme.spacing?.['2xLarge']} ${p => p.theme.spacing.extraLarge};
	}
`;
