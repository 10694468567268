import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherFindVehicleId() {
    yield takeEvery(VehicleActionTypes.FIND_VEHICLE_ID, workerFindVehicleId);
}
function* workerFindVehicleId({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const resp = yield api.findVehicleId(client, payload);
        yield put(actions.findVehicleId.success(ident, resp));
    }
    catch (error) {
        yield put(actions.findVehicleId.failure(ident, error));
    }
}
