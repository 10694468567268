import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { recordAgreement } from '../actions';
import * as api from '../api';
import { TermsActionTypes } from '../types';
export default function* watcherRecordAgreement() {
    yield takeEvery(TermsActionTypes.RECORD_AGREEMENT, workerRecordAgreement);
}
function* workerRecordAgreement({ payload, meta }) {
    const { ident } = meta;
    const { userId, document, version } = payload;
    const client = yield getContext('api');
    try {
        yield call(api.recordAgreement, client, userId, document, version);
        yield put(recordAgreement.success(ident));
    }
    catch (error) {
        yield put(recordAgreement.failure(ident, error));
    }
}
