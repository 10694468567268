import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { oneLink } from '~website/helpers/source-tracking';

export const useMobileOneLink = () => {
	const { isAndroid, isApplePhone, isApplePad } = useMobileDetect();
	let platform: 'playstore' | 'appstore' | 'general' = 'general';

	if (isAndroid) platform = 'playstore';
	else if (isApplePhone || isApplePad) platform = 'appstore';

	return oneLink[platform];
};
