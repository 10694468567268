import Cher from '@cuvva/cher';

const notEmptyValidator = (value: string | number | undefined) => {
	if (!value)
		return new Cher('invalid');

	return void 0;
};

export default notEmptyValidator;
