import React from 'react';

import AboutYou from './components/AboutYou';
import Customise from './components/Customise';
import Licence from './components/License';
import Personal from './components/Personal';
import TripStart from './components/TripStart';
import VehicleInfo from './components/VehicleInfo';
import YourIncidents from './components/YourIncidents';

type Product = 'stm' | 'ltm'

export interface QuotePage {
	name: string;
	path: string;
	analyticScreenName: string;
	component: React.FCWithChildren;
}

interface QuoteFlowDescription {
	product: Product;
	pages: QuotePage[];
}

const ltm: QuoteFlowDescription = {
	product: 'ltm',
	pages: [{
		name: 'About you',
		path: '/quote/your-details',
		analyticScreenName: 'about_you',
		component: AboutYou,
	}, {
		name: 'Licence and address',
		path: '/quote/your-licence',
		analyticScreenName: 'licence_and_address',
		component: Licence,
	}, {
		name: 'Incidents',
		path: '/quote/your-incidents',
		analyticScreenName: 'incidents',
		component: YourIncidents,
	}, {
		name: 'Vehicle',
		path: '/quote/vehicle-info',
		analyticScreenName: 'vehicle_info',
		component: VehicleInfo,
	}, {
		name: 'Personal',
		path: '/quote/personal',
		analyticScreenName: 'personal',
		component: Personal,
	}, {
		name: 'Customise',
		path: '/quote/customise',
		analyticScreenName: 'customise',
		component: Customise,
	}],
};

const stm: QuoteFlowDescription = {
	product: 'stm',
	pages: [{
		name: 'Trip start',
		path: '/quote/trip-start',
		analyticScreenName: 'trip_start',
		component: TripStart,
	}, {
		name: 'About you',
		path: '/quote/your-details',
		analyticScreenName: 'about_you',
		component: AboutYou,
	}, {
		name: 'Licence and address',
		path: '/quote/your-licence',
		analyticScreenName: 'licence_and_address',
		component: Licence,
	}, {
		name: 'Incidents',
		path: '/quote/your-incidents',
		analyticScreenName: 'incidents',
		component: YourIncidents,
	}],
};

export const flows: Record<string, QuoteFlowDescription> = {
	hourly: stm,
	monthly: ltm,
};
