import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import { HookReturn } from '../types';
import useFriendlyError from './use-friendly-error';

const field = 'ncb';

function useNCB(): HookReturn<number | null> {
	const dispatch = useDispatch();
	const value = useSelector(s => s.internal.quote.state[field]);
	const ltmConfig = useSelector(s => s.platform.ltm.config.response)!;
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const validation = page?.fields?.ncb?.error;

	const onChange = (years: number | null) => {
		const max = ltmConfig.ncb.max / 12;
		const min = ltmConfig.ncb.min / 12;

		if (years === null) {
			dispatch(stepActions.setNCBYears(years));

			return;
		}

		if (!Number.isInteger(years))
			return;

		if (years < min || years > max)
			return;

		dispatch(stepActions.setNCBYears(years));
	};

	useEffect(() => {
		dispatch(pageActions.registerField({
			field: 'ncb',
			pageId,
		}));

		return () => {
			dispatch(pageActions.deregisterField({
				field: 'ncb',
				pageId,
			}));
		};
	}, [dispatch, pageId]);

	useEffect(() => {
		const isValid = value !== null && Number.isInteger(value) && value > -1;
		const error = isValid ? void 0 : new Cher('invalid');

		dispatch(stepActions.setFieldError({
			field: 'ncb',
			pageId,
			error,
		}));
	}, [value, pageId, dispatch]);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'quote', 'ncb'),
	};
}

export default useNCB;
