import { AnalyticsTrackingDebugEvent } from '../types';

export interface AnalyticsState {
	analyticsTrackingDebug: AnalyticsTrackingDebugEvent[];
	sourceTracking: Record<string, string>;
}

export const initialAnalyticsState: AnalyticsState = {
	analyticsTrackingDebug: [],
	sourceTracking: {},
};

export const AnalyticsEventTypes = {
	TRACK_EVENT: '@@internal/analytics/v2/TRACK_EVENT',
	SOURCE_TRACKING: '@@internal/analytics/v2/SOURCE_TRACKING',
	SCREEN_VIEW: '@@internal/analytics/v2/SCREEN_VIEW',
	TRACK_QUOTE_SCREEN_VIEW: '@@internal/analytics/v2/TRACK_QUOTE_SCREEN_VIEW',
	TRACK_QUICK_QUOTE_SCREEN_VIEW: '@@internal/analytics/v2/TRACK_QUICK_QUOTE_SCREEN_VIEW',
	TRACK_QUICK_QUOTE_EVENT: '@@internal/analytics/v2/TRACK_QUICK_QUOTE_EVENT',
	TRACK_QUOTE_EVENT: '@@internal/analytics/v2/TRACK_QUOTE_EVENT',
	TRACK_OWNERS_SCREEN_VIEW: '@@internal/analytics/v2/TRACK_OWNERS_SCREEN_VIEW',
	TRACK_OWNERS_EVENT: '@@internal/analytics/v2/TRACK_OWNERS_EVENT',
	ANALYTICS_TRACKING_DEBUG: '@@internal/analytics/v2/ANALYTICS_TRACKING_DEBUG',
	REFERRAL_EMAIL_TRIGGER_EVENT: '@@internal/analytics/v2/REFERRAL_EMAIL_TRIGGER_EVENT',
	TRACK_ACCOUNTS_SCREEN_VIEW: '@@internal/analytics/v2/TRACK_ACCOUNTS_SCREEN_VIEW',
	TRACK_ACCOUNTS_EVENT: '@@internal/analytics/v2/TRACK_ACCOUNTS_EVENT',
	TRACK_PAY_ON_WEB_SCREEN_VIEW: '@@internal/analytics/v2/TRACK_PAY_ON_WEB_SCREEN_VIEW',
};
