import styled from 'styled-components';
import Typography from '../../Typography';
export const BannerContent = styled(Typography) `
	flex: 1;
	word-break: break-word;
`;
export const BannerButtonWrapper = styled(Typography) `
	border: none;
	background: none;
	padding: 0;
`;
export const CloseBanner = styled.div `
	position: absolute;
	top: ${p => p.theme.spacing.large};
	right: ${p => p.theme.spacing.large};
	cursor: pointer;
	margin-left: ${p => p.theme.spacing.small};
`;
