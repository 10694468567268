import { call, getContext, put } from 'redux-saga/effects';
import { replacePrimaryIdentifier } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(replacePrimaryIdentifier.request, function* workerReplacePrimaryIdentifier({ payload, meta }) {
    const { ident } = meta;
    const { requestId, ...requestBody } = payload;
    const client = yield getContext('api');
    const clientId = window?.cuvva?.client?.clientId || 'client_000000BPG6Lks9tQoAiJYrBRSXPX6';
    try {
        const response = yield call(api.replacePrimaryIdentifier, client, {
            ...requestBody,
            clientId,
        });
        yield put(replacePrimaryIdentifier.success(ident, response));
    }
    catch (error) {
        yield put(replacePrimaryIdentifier.failure(ident, error));
    }
});
