import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { titleCase } from 'title-case';

import { List } from '../atoms/list';
import ListItem from './ListItem';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { listPaymentMethods } from '~lib/platform/payment/store/actions';

interface PaymentMethodsListProps {
	userId: string;
}

const PaymentMethodsList: React.FCWithChildren<PaymentMethodsListProps> = ({ userId }) => {
	const dispatch = useDispatch();
	const pmtMethods = useSelector(s => s.platform.payment.listPaymentMethods[userId]);
	const status = useAsyncStateStatus(pmtMethods).status;

	useEffect(() => {
		if (userId)
			dispatch(listPaymentMethods.request({ userId }));
	}, [dispatch, userId]);

	if (['pending', 'fetching'].includes(status)) {
		return (
			<Spinner $size={'24px'} $color={'primaryFill'} />
		);
	}

	if (status === 'response' && pmtMethods.response.length === 0) {
		return (
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{'There are no cards on record'}
			</Typography>
		);
	}

	return (
		<List>
			{status === 'error' && (
				<Banner.Wrapper muted type={'error'} icon={'ic_alert'}>
					{'We\'re having some issues loading the payment methods: '}
					{pmtMethods.error.code}
				</Banner.Wrapper>
			)}

			{status === 'response' && pmtMethods.response.map(pmtMethod => (
				<ListItem
					key={pmtMethod.stpPaymentMethodId}
					label={`${titleCase(pmtMethod.card.brand)} •••• ${pmtMethod.card.last4 ?? ''}`}
					body={`Expires ${String(pmtMethod.card.expMonth).padStart(2, '0')}/${pmtMethod.card.expYear}`}
					action={null}
					locked={false}
				/>
			))}
		</List>
	);
};

export default PaymentMethodsList;
