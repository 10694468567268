import React from 'react';
import styled from 'styled-components';

import HorizontalRule from '../../atoms/HorizontalRule';
import { MarginProps, parseMargin } from '~lib/frontend/design-system/components/atoms/utils/Margin';
import Typography from '~lib/frontend/design-system/components/Typography';

const FancyLineBreak: React.FCWithChildren<MarginProps> = ({ children, ...rest }) => (
	<Wrap {...rest}>
		<HorizontalRule $color={'blankFillMuted'} />
		<Typography $type={'Label.Large'} $color={'textOnFillMuted'}>
			{children}
		</Typography>
		<HorizontalRule $color={'blankFillMuted'} />
	</Wrap>
);

const Wrap = styled.div<MarginProps>`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.regular};
	align-items: center;
	${parseMargin}

	& > * {
		flex: 1;
	}

	& > :nth-child(2) {
		flex: 0;
	}
`;

export default FancyLineBreak;
