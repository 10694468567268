import { call, getContext, put } from 'redux-saga/effects';
import { createWebIntent } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(createWebIntent.request, function* createWebIntentSaga({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const webIntent = yield call(api.createWebIntent, client, payload);
        yield put(createWebIntent.success(ident, webIntent));
    }
    catch (error) {
        yield put(createWebIntent.failure(ident, error));
    }
});
