import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherFlushCache() {
    yield takeEvery(VehicleActionTypes.FLUSH_CACHE, workerFlushCache);
}
function* workerFlushCache({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield api.flushCache(client, {
            clearStorage: payload.clearStorage,
            clearVrmMap: payload.clearVrmMap,
            vehicleIds: payload.vehicleIds,
            vrms: payload.vrms,
        });
        yield put(actions.flushCache.success(ident));
    }
    catch (error) {
        yield put(actions.flushCache.failure(ident, error));
    }
}
