/* eslint-disable @typescript-eslint/indent */
import { datadogLogs } from '@datadog/browser-logs';
import getHostEnvironment from '../helpers/getHostEnvironment';
export const configureDatadog = (_app) => {
    const env = getHostEnvironment();
    const isLocal = env === 'local';
    datadogLogs.init({
        clientToken: 'pub3aa97dbae1508f9e7e7731c121f895d1',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: !isLocal,
        sampleRate: 100,
        service: 'website',
        env,
        beforeSend: log => {
            if (log.http?.status_code === 0)
                return false;
            return true;
        },
    });
    datadogLogs.setGlobalContextProperty('env', env);
    datadogLogs.logger.setLevel('error');
    // datadogLogs.logger.setHandler('console');
};
