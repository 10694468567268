import React from 'react';
import { ThemeProvider } from 'styled-components';
import borderRadius from './border-radius';
import boxShadow from './box-shadow';
import brand from './brand';
import breakpoints from './breakpoints';
import fonts from './fonts';
import spacing from './spacing';
import { createUiColors } from './themes';
import { createTypographyStyles } from './typography-styles';
export function createDesignSystem(themeKey, typographyStyle, legacy) {
    return {
        themeKey,
        borderRadius,
        boxShadow,
        brand,
        breakpoints,
        fonts,
        spacing,
        typography: createTypographyStyles(typographyStyle),
        ui: createUiColors(brand, themeKey),
        legacy,
    };
}
const DesignSystemProvider = props => {
    const { children, themeKey, typographyStyle, legacy } = props;
    return (React.createElement(ThemeProvider, { theme: createDesignSystem(themeKey || 'light', typographyStyle || 'productive', legacy) }, children));
};
export { DesignSystemProvider };
