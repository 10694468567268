import styled, { css } from 'styled-components';
import { media } from '~lib/frontend/design-system/breakpoints';
export const CardHeaderGroupWrapper = styled.div `
	width: 100%;
	display: grid;
	grid-template-columns: minmax(100px, 1fr) minmax(100px, 20%);
	gap: normal;
	align-items: center;
	justify-content: space-between;

	${media.greaterThan('mobile') `
		grid-template-columns: 1fr minmax(100px, 50%);
	`};
`;
export const CardHeaderGroupContainer = styled.div `
	padding: ${p => p.theme.spacing.large};

	${p => p.$collapsible && css `
		display: flex;
		align-items: center;
		cursor: pointer;
	`}
`;
export const CardDropdownMenu = styled.div `
	margin: ${p => p.theme.spacing.extraSmall} 0;
	display: block;
	width: 100%;

	> * {
		width: 100%;
	}
`;
export const CardActionsDropdownWrapper = styled.div `
	display: flex;
	align-items: center;
	gap: ${p => p.theme.spacing.regular};

	${p => p.$loading && css `
		opacity: 0.5;
	`}
`;
