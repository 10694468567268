import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { getLatestKyc, revokePhotos } from '../actions';
import { KycActionTypes } from '../types';
export default function* watcherRevokePhotos() {
    yield takeEvery(KycActionTypes.REVOKE_PHOTOS, workerRevokePhotos);
}
function* workerRevokePhotos({ payload }) {
    const client = yield getContext('api');
    try {
        yield call([client, client.rpc], 'service-kyc', '1/2020-04-01/revoke_photos', payload);
        yield put(revokePhotos.success(void 0));
        yield put(getLatestKyc.request({ userId: payload.userId }));
    }
    catch (error) {
        yield put(revokePhotos.failure(error));
    }
}
