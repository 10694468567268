import { combineReducers } from 'redux';
import { initialLtmCancellationState, LtmCancellationActionTypes } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
export const getConfig = createAsyncMapReducer(LtmCancellationActionTypes.GET_CONFIG, initialLtmCancellationState.getConfig);
export const quoteCancellation = createAsyncMapReducer(LtmCancellationActionTypes.QUOTE_CANCELLATION, initialLtmCancellationState.quoteCancellation);
export const quoteAutocycle = createAsyncMapReducer(LtmCancellationActionTypes.QUOTE_AUTOCYCLE, initialLtmCancellationState.quoteAutocycle);
export const confirmCancellation = createAsyncMapReducer(LtmCancellationActionTypes.CONFIRM_CANCELLATION, initialLtmCancellationState.confirmCancellation);
export default combineReducers({
    getConfig,
    quoteCancellation,
    quoteAutocycle,
    confirmCancellation,
});
