import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Cher from '@cuvva/cher';
const SCREEN_VIEW = 'screen_view';
const SCREEN_ACTION = 'screen_action';
const TrackingStateContext = React.createContext(null);
const TrackingDispatchContext = React.createContext(null);
function trackingReducer(state, action) {
    switch (action.type) {
        case SCREEN_VIEW: {
            return {
                ...state,
                screenView: {
                    payload: action.payload,
                },
                screenAction: null,
            };
        }
        case SCREEN_ACTION: {
            return {
                ...state,
                screenAction: {
                    payload: {
                        view: state.screenView?.payload.view,
                        screen: state.screenView?.payload.screen,
                        ...action.payload,
                    },
                },
            };
        }
        default:
            return state;
    }
}
export const TrackingProvider = ({ sendEvent, children }) => {
    const [state, dispatch] = React.useReducer(trackingReducer, {
        screenView: null, screenAction: null,
    });
    const dispatchEvent = useDispatch();
    const location = useLocation();
    const pathname = location.pathname;
    const trackUserPage = (paths) => {
        if (paths.length > 3) {
            dispatch({ type: SCREEN_VIEW, payload: { view: paths[3], screen: 'user_profile' } });
            return void 0;
        }
        dispatch({ type: SCREEN_VIEW, payload: { view: 'identity', screen: 'user_profile' } });
        return window.setTimeout(() => {
            dispatch({
                type: SCREEN_ACTION,
                payload: {
                    component: 'navigation_bar',
                    element: 'tab',
                    action: 'click',
                },
            });
        }, 1000);
    };
    useEffect(() => {
        const paths = pathname.split('/');
        let timer = void 0;
        if (paths[1] === 'users')
            timer = trackUserPage(paths);
        return () => {
            if (timer !== void 0)
                window.clearTimeout(timer);
        };
    }, [pathname]);
    useEffect(() => {
        if (state.screenView && !state.screenAction)
            dispatchEvent(sendEvent(SCREEN_VIEW, state.screenView.payload));
        if (state.screenAction)
            dispatchEvent(sendEvent(SCREEN_ACTION, state.screenAction.payload));
    }, [dispatchEvent, sendEvent, state]);
    const dispatchTrackingAction = (params) => {
        dispatch({ type: SCREEN_ACTION, payload: { ...params } });
    };
    return (React.createElement(TrackingStateContext.Provider, { value: state },
        React.createElement(TrackingDispatchContext.Provider, { value: dispatchTrackingAction }, children)));
};
export const useScreenAction = () => {
    const context = React.useContext(TrackingDispatchContext);
    if (!context)
        throw new Cher('useScreenAction must be used within a TrackingProvider');
    return context;
};
