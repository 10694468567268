import styled from 'styled-components';

export const CheckListMain = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CheckListContainer = styled.ul<{ $orientation?: 'inline' | 'block' }>`
	list-style: none outside none;
	padding: 0;
	margin: 0;
	${p => p.$orientation === 'inline' && (
		'display: flex;'
	)}
`;

export const CheckListItem = styled.div<{ $orientation?: 'inline' | 'block' }>`
	display: flex;
	flex-direction: row;
	margin-top: ${p => p.theme.spacing.large};

	${p => p.$orientation === 'inline' && `
		margin-top: 0;
		margin-right: 8px;
	`}

	&>:first-child {
		flex: 0 0 auto;
	}

	& > * {
		flex: 1;
	}
`;
