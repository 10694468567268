import styled from 'styled-components';

const Counter3ConfettiGridCounters = styled.div`
	@media (max-width: 767px) {
		grid-column-start: 1;
		grid-column-end: 1;
		grid-row-start: 1;
		grid-row-end: 4;

		> :first-child {
			display: none;
		}
	}

	@media (min-width: 768px) {
		grid-column-start: 1;
		grid-column-end: 4;

		> :nth-child(2) {
			display: none;
		}
	}
`;

export default Counter3ConfettiGridCounters;
