import { History } from 'history';

import { OwnersData } from '../types';
import { createAwareUrl } from '~lib/frontend/helpers/uri';

function parseStateParams(stateParams: string, history: History, referralCode: string): OwnersData {
	let state;

	try {
		const parsedData = JSON.parse(stateParams);

		state = {
			vehicle: {
				make: parsedData?.make,
				model: parsedData?.model,
				vrm: parsedData?.vrm,
			},
			profile: {
				personalName: parsedData?.name,
				preferredName: parsedData?.name,
			},
			share: {
				emailAddress: parsedData?.email,
				referralCode,
			},
		};
	} catch (e) {
		history.replace(createAwareUrl('/'));
	}

	return state;
}

export default parseStateParams;
