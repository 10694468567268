import React from 'react';

const Twitter: React.FunctionComponent = () => (
	<svg width={'20px'} height={'20px'} id={'twitter'} viewBox={'0 0 20 14'}>
		<circle
			cx={'10'}
			cy={'10'}
			r={'9'}
			fill={'#1DA2f2'}
			fillOpacity={'0'}
		></circle>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g
				id={'Views-/-Footer-/-Tablet'}
				transform={'translate(-601.000000, -209.000000)'}
				fill={'#1DA1F2'}
			>
				<g id={'Footer-padding'}>
					<g id={'Group-8'} transform={'translate(52.000000, 177.000000)'}>
						<g id={'Follow-us'} transform={'translate(514.000000, 0.000000)'}>
							<g id={'Group-11'} transform={'translate(0.000000, 29.000000)'}>
								<g id={'Group-5'}>
									<g
										id={'Icons-/-Social-/-Twitter'}
										transform={'translate(35.000000, 0.000000)'}
									>
										<path
											d={'M17.9647515,4.66719639 C17.3010291,4.94637822 16.5923087,5.13656636 15.8460897,5.22340698 C16.6065581,4.78489773 17.1915337,4.09376121 17.4682722,3.26913416 C16.7550519,3.66745271 15.9645848,3.95739991 15.1231199,4.1188804 C14.4511479,3.43061465 13.4934378,3 12.4299821,3 C10.392317,3 8.74013583,4.58107346 8.74013583,6.52888707 C8.74013583,6.80878659 8.77388442,7.07792075 8.83538186,7.33557185 C5.76800967,7.19705747 3.04937294,5.78751218 1.22994875,3.65668734 C0.909712095,4.17486031 0.730469564,4.77700313 0.730469564,5.43297278 C0.730469564,6.66022453 1.38294238,7.73891424 2.37140119,8.37263546 C1.76617641,8.3539755 1.19695013,8.19464808 0.700470814,7.93053776 L0.700470814,7.97431691 C0.700470814,9.68601015 1.97016791,11.1134977 3.65984751,11.4386118 C3.35011041,11.5182755 3.02312404,11.561337 2.687888,11.561337 C2.45239782,11.561337 2.22665722,11.5398062 2.00091663,11.4996155 C2.47414691,12.9012662 3.83459023,13.9232583 5.45377276,13.951966 C4.19382526,14.8986005 2.59714179,15.4627057 0.877463439,15.4627057 C0.584975626,15.4627057 0.293237782,15.4461988 0,15.4146204 C1.6416816,16.4150818 3.57585101,17 5.66751385,17 C12.4577309,17 16.1663264,11.6201876 16.1663264,6.96237248 C16.1663264,6.81237504 16.1663264,6.66094223 16.1550769,6.5102271 C16.8757968,6.01573794 17.5050206,5.390629 18,4.68155021 L17.9647515,4.66719639 Z'}
											id={'Twitter'}
										></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Twitter;
