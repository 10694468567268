import React, { useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { SectionWrapper } from '../atoms/ReportingToWrapper';
import HiringOfferContext from '../context';
import NextStep from '../molecules/NextStep';

const NextSteps: React.FunctionComponent = () => {
	const { responseDeadline } = useContext(HiringOfferContext);

	return (
		<SectionWrapper>
			<Wrapper>
				<NextStep
					title={'What happens next if I accept?'}
					description={
						'Your next steps will be with Poppy, our Workplace Experience Associate who will be in touch to start your pre-boarding and to support you right up until your first day at Cuvva.'
					}
				/>
				<NextStep
					title={'How should I respond?'}
					description={
						'We can’t wait to work with you. When you’re ready to let us know of your decision, just reply to the offer email. '
					}
				/>
				<NextStep
					title={'What do I need to respond by?'}
					description={`Please let us know of your decision by ${moment(responseDeadline).format(
						'dddd, MMMM Do YYYY',
					)}.`}
				/>
			</Wrapper>
		</SectionWrapper>
	);
};

const Wrapper = styled.div`
	> * {
		margin-bottom: ${p => p.theme.spacing.extraLarge};
	}
`;

export default NextSteps;
