import React, { FCWithChildren } from 'react';

import Header from '~website/components/layout/header';
import VisionaryContext from '~website/contexts/VisionaryContext';

const PolicyContainer: FCWithChildren = ({ children }) => (
	<VisionaryContext.Provider value={true}>
		<Header contentUnder />
		{children}
	</VisionaryContext.Provider>
);

export default PolicyContainer;
