import {
	GenerateReferralResponse,
	ReferralActionTypes,
	SetRenewalDateRequest,
	SetRenewalDateResponse,
	SubmitRenewalReminderRequest,
} from './types';
import { GetSharedQuoteRequest, GetSharedQuoteResponse } from '~lib/platform/social-driving/types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';

export const setRenewalDate = createAsyncMapAction<SetRenewalDateRequest, SetRenewalDateResponse>(
	ReferralActionTypes.SET_RENEWAL_DATE,
	p => p.vehicleId,
);

export const submitRenewalReminder = createAsyncMapAction<SubmitRenewalReminderRequest>(
	ReferralActionTypes.SUBMIT_RENEWAL_REMINDER,
	p => p.userId,
);

export const sharedQuotePreferences = createAsyncMapAction<GetSharedQuoteRequest, GetSharedQuoteResponse>(
	ReferralActionTypes.SHARED_QUOTE_PREFERENCES,
	p => p.key,
);

export const generateReferralLink = createAsyncAction<void, GenerateReferralResponse>(
	ReferralActionTypes.GENERATE_REFERRAL_LINK,
);
