import React from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import { FaultStatus } from '~lib/platform/incident/types';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import notEmptyValidator from '~website/features/quote/validators/not-empty';

const optionsTheftOf = [{
	value: 'vehicle',
	display: 'Vehicle',
}, {
	value: 'contents',
	display: 'Only contents',
}];

const IncidentTheftOf: React.FCWithChildren<{ index: number }> = ({ index }) => {
	const { onChange, validation, value } = useIncidentItemInternalChangeRequest(index, 'theftOf', notEmptyValidator);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	return (
		<QuoteInputDecorator
			label={'What was stolen?'}
			onFocus={onFocus}
			onBlur={onBlur}
			error={error || pageError}
		>
			<AlignDoubleColChildren>
				{optionsTheftOf.map(o => (
					<RadioInput
						key={o.display}
						name={`incident-theft-of-${index}`}
						checked={value === o.value}
						value={o.display}
						onChange={() => onChange(o.value as FaultStatus)}
					/>
				))}
			</AlignDoubleColChildren>
		</QuoteInputDecorator>
	);
};

export default IncidentTheftOf;
