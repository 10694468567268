export const initialMotorCoverageState = {
    estimate: {},
    cancelPolicy: {},
    listEvents: {},
    quote: {},
};
export const MotorCoverageActionTypes = {
    ESTIMATE: '@@platform/motor-coverage/ESTIMATE',
    ESTIMATE_SUCCESS: '@@platform/motor-coverage/ESTIMATE_SUCCESS',
    ESTIMATE_FAILURE: '@@platform/motor-coverage/ESTIMATE_FAILURE',
    CANCEL_POLICY: '@@platform/motor-coverage/CANCEL_POLICY',
    CANCEL_POLICY_SUCCESS: '@@platform/motor-coverage/CANCEL_POLICY_SUCCESS',
    CANCEL_POLICY_FAILURE: '@@platform/motor-coverage/CANCEL_POLICY_FAILURE',
    LIST_EVENTS: '@@platform/motor-coverage/LIST_EVENTS',
    LIST_EVENTS_SUCCESS: '@@platform/motor-coverage/LIST_EVENTS_SUCCESS',
    LIST_EVENTS_FAILURE: '@@platform/motor-coverage/LIST_EVENTS_FAILURE',
    CREATE_QUOTE: '@@platform/motor-coverage/CREATE_QUOTE',
    CREATE_QUOTE_SUCCESS: '@@platform/motor-coverage/CREATE_QUOTE_SUCCESS',
    CREATE_QUOTE_FAILURE: '@@platform/motor-coverage/CREATE_QUOTE_FAILURE',
};
