import { useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import useAsyncStateStatus from './use-async-state-status';
const overridesUrlKey = 'flags';
const initialValue = {
    on: [],
    off: [],
};
const overrides = (() => {
    if (!('location' in global))
        return initialValue;
    try {
        const params = new URLSearchParams(location.search);
        const hasOverrides = params.has(overridesUrlKey);
        if (!hasOverrides)
            return initialValue;
        const overrides = params.get(overridesUrlKey);
        if (!overrides)
            throw new Cher('unable_to_decode');
        const on = [];
        const off = [];
        // SC: `.` doesn't get URL encoded and looks better than `,` which gets URL encoded
        const pieces = overrides.split('.');
        for (const flag of pieces) {
            if (!flag)
                continue;
            const name = flag.replace(/^[+-]/, '');
            if (flag.startsWith('-'))
                off.push(name);
            else
                on.push(name);
        }
        return {
            on,
            off,
        };
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Malformed override, unable to decode it', e);
        return initialValue;
    }
})();
export default function useConfigFlag(flag, allowOverrides = true) {
    const user = useSelector((s) => s.internal.auth);
    const userId = user?.user?.response ?? user?.whoami?.userId ?? '';
    const config = useSelector((s) => s.platform.userConfig.getConfigById[userId]);
    const status = useAsyncStateStatus(config);
    if (status.is('fetching', 'pending'))
        return void 0;
    if (!userId || !config?.response)
        return false;
    const flags = handleFlagsOverrides(config.response.flags, allowOverrides ? overrides : void 0);
    return flags.includes(flag);
}
function handleFlagsOverrides(flags, overrides = initialValue) {
    const cloned = cloneDeep(overrides);
    // SC: this makes sure we can override with partial keys
    // (e.g. having `on` but missing `off`) and still have it working
    merge(cloned, initialValue);
    const copy = flags.filter(f => !cloned.off.includes(f));
    for (const flag of cloned.on) {
        if (!copy.includes(flag))
            copy.push(flag);
    }
    return copy;
}
