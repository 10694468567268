import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

const ReferralCard = styled.div`
	background: ${p => p.theme.ui.secondarySurface};
	border-radius: ${p => p.theme.borderRadius.large};
	max-width: 440px;
	margin: 0 auto;
	box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);

	@media (max-width: 767px) {
		padding: ${p => p.theme.spacing.extraLarge} 24px;
	}

	padding: 24px ${p => p.theme.spacing.extraLarge};

	${media.lessThan('tablet')`
		padding: 24px 16px;
	`}
`;

export default ReferralCard;
