import { call, getContext, put } from 'redux-saga/effects';
import { verifyIdentifier } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(verifyIdentifier.request, function* workerVerifyIdentifierIdentifier({ payload, meta }) {
    const { ident } = meta;
    const { requestId, ...requestBody } = payload;
    const client = yield getContext('api');
    const clientId = window?.cuvva?.client?.clientId || 'client_000000BPG6Lks9tQoAiJYrBRSXPX6';
    try {
        const response = yield call(api.verifyIdentifier, client, {
            ...requestBody,
            clientId,
        });
        yield put(verifyIdentifier.success(ident, response));
    }
    catch (error) {
        yield put(verifyIdentifier.failure(ident, error));
    }
});
