import { all, fork } from 'redux-saga/effects';
import listProfiles from './list-profiles';
import profileById from './profile-by-id';
import search from './search';
import updateProfile from './update-profile';
export default function* profileSaga() {
    yield all([
        fork(listProfiles),
        fork(profileById),
        fork(search),
        fork(updateProfile),
    ]);
}
