import React from 'react';
import styled from 'styled-components';
import CheckBoxInputWrapper from '../atoms/CheckBox';
import Typography from '~lib/frontend/design-system/components/Typography';
const CheckBoxInput = props => {
    const { name, label, disabled, checked, onChange } = props;
    return (React.createElement(CheckBoxInputOuterContainer, { onClick: () => onChange() },
        React.createElement(CheckBoxInputWrapper, { "$checked": checked },
            React.createElement("input", { type: 'checkbox', name: name, disabled: disabled, checked: checked }),
            React.createElement("span", null)),
        React.createElement(Typography, { "$type": 'Body.Medium', "$marginLeft": 'extraSmall', "$color": 'textOnSurfaceBackground' }, label)));
};
const CheckBoxInputOuterContainer = styled.div `
	display: flex;
	align-items: center;
	cursor: pointer;
`;
export default CheckBoxInput;
