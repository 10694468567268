import styled from 'styled-components';
import { getButtonTextColors } from '../helpers';
const IconWrapper = styled.div `
	width: 18px;
	height: 18px;
	margin-right: ${p => p.$leadingIcon && !p.$stretch ? '4px' : '0'};
	margin-left: ${p => p.$trailingIcon && !p.$stretch ? '4px' : '0'};

	--fill: ${p => getButtonTextColors(p.theme, p.$type)};
`;
export default IconWrapper;
