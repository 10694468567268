import React from 'react';

import { AdapterProps } from '../types';
import PricingSection, { contentAdapter } from '~website/components/atoms/PrincingSection';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<PricingSection {...contentAdapter(getter)} />
);

export default {
	type: 'pricing_section',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			pricingTitle: { type: 'string' },
			flip: { type: 'boolean' },
			items: {
				type: 'object[]',
				fields: {
					value: { type: 'number' },
					unit: { type: 'string' },
					price: { type: 'string' },
				},
			},
			button: {
				type: 'object',
				fields: {
					text: { type: 'string' },
					url: { type: 'string' },
				},
			},

			centered: { type: 'boolean' },
			title: { type: 'string' },
			body: { type: 'string' },
			show_button: { type: 'cta' },
			show_app_store_buttons: { type: 'boolean' },
			show_trustpilot: { type: 'boolean' },
		},
		default: {
			pricingTitle: 'Compare prices',
			flip: false,
			items: [{
				value: 1,
				unit: 'hour',
				price: '13.20',
			}, {
				value: 2,
				unit: 'hours',
				price: '13.20',
			}, {
				value: 3,
				unit: 'hours',
				price: '13.20',
			}],
			button: {
				text: 'Get a quote',
				url: '#',
			},

			centered: false,
			title: 'Get insured for just one hour.',
			body: '(Disclaimer about prices)',
			show_button: {
				text: 'Get a quote',
				url: '/get-an-estimate',
			},
			show_app_store_buttons: false,
			show_trustpilot: false,
		},
	},
};
