import { call, getContext, put } from 'redux-saga/effects';
import { createFileFlag } from '../actions';
import * as api from '../api';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(createFileFlag.request, function* createFileFlagSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        yield call(api.createFileFlag, client, payload);
        yield put(createFileFlag.success(ident));
    }
    catch (error) {
        yield put(createFileFlag.failure(ident, error));
    }
});
