import React, { Children, cloneElement, createContext, isValidElement, useContext } from 'react';
import { ActionsSpacer, LinedUpButtonArea, StackedButtonArea } from '../atoms/ActionsAlignment';
import { DialogContainer, DialogContentContainer } from '../atoms/DialogStyle';
import { AbsoluteModalClose } from '../atoms/Icon';
import { defaultButtonTypes } from '../types';
import Modal from './Modal';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
export { default as Illustration } from './Illustration';
const Alignment = createContext({ isCentered: false });
export const Dialog = props => {
    const { illustration, title, centered, children, open, onClose } = props;
    const isMobile = useMediaQuery('(max-width: 767px)');
    const isActuallyCentered = centered || isMobile;
    return (React.createElement(Modal, { dismissible: true, title: title, open: open, onClose: onClose, verticalAlign: isMobile ? 'flex-end' : 'center' },
        React.createElement(DialogContainer, { "$centered": isActuallyCentered, "$hasClose": true },
            React.createElement(AbsoluteModalClose, { tabIndex: -1, onClick: onClose },
                React.createElement(Icon, { icon: 'ic_cross_outline', "$size": '24px', "$color": 'neutralFillMinor' })),
            illustration,
            React.createElement(Typography, { "$type": 'Heading.Small', "$color": 'textOnSurfaceBackground', "$marginTop": 'small', "$marginBottom": 'large', "$marginLeft": isActuallyCentered ? '24px' : '0', "$marginRight": '24px' }, title),
            React.createElement(Alignment.Provider, { value: { isCentered: isActuallyCentered } }, children))));
};
export const Content = ({ children }) => (React.createElement(DialogContentContainer, null, children));
export const Actions = ({ children }) => {
    const alignment = useContext(Alignment);
    const ActionsContainer = alignment.isCentered ? StackedButtonArea : LinedUpButtonArea;
    const newActions = Children.map(children, (action, index) => {
        if (!isValidElement(action))
            return null;
        const type = action.props.type || defaultButtonTypes[index];
        return cloneElement(action, {
            fullWidth: alignment.isCentered,
            size: 'large',
            type,
        });
    })?.reverse();
    return (React.createElement(ActionsSpacer, null,
        React.createElement(ActionsContainer, null, newActions)));
};
