import React, { Fragment, useState } from 'react';
import Button from '../button/Button';
import * as Alert from './molecules/Alert';
import * as ComplexDialog from './molecules/ComplexDialog';
import * as SimpleDialog from './molecules/SimpleDialog';
import { DesignSystemProvider } from '~lib/frontend/design-system';
const Showcase = () => {
    const [title, setTitle] = useState('By continuing you agree');
    const [body, setBody] = useState('By continuing you agree with Cuvva carrying out searches using your data, and sharing it with relevant parties including the DVLA.');
    const [hasIllustration, setHasIllustration] = useState(false);
    const [isCentered, setIsCentered] = useState(false);
    const [primaryAction, setPrimaryAction] = useState('Done');
    const [secondaryAction, setSecondaryAction] = useState('');
    const [tertiaryAction, setTertiaryAction] = useState('');
    const [t8yStyle, setT8yStyle] = useState('expressive');
    return (React.createElement("div", { style: { maxWidth: '500px', margin: '50px auto' } },
        React.createElement("span", { style: { marginRight: '10px' } }, 'Typography style:'),
        React.createElement("select", { value: t8yStyle, onChange: e => setT8yStyle(e.target.value) },
            React.createElement("option", { value: 'expressive' }, 'expressive'),
            React.createElement("option", { value: 'productive' }, 'productive')),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Title:'),
        React.createElement("input", { value: title, onChange: e => setTitle(e.target.value) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Has example illustration (if supported):'),
        React.createElement("input", { type: 'checkbox', checked: hasIllustration, onChange: e => setHasIllustration(e.target.checked) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Centered (if supported):'),
        React.createElement("input", { type: 'checkbox', checked: isCentered, onChange: e => setIsCentered(e.target.checked) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Body:'),
        React.createElement("textarea", { value: body, onChange: e => setBody(e.target.value) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Primary action:'),
        React.createElement("input", { value: primaryAction, onChange: e => setPrimaryAction(e.target.value) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Secondary action:'),
        React.createElement("input", { value: secondaryAction, onChange: e => setSecondaryAction(e.target.value) }),
        React.createElement("br", null),
        React.createElement("span", { style: { marginRight: '10px' } }, 'Tertiary action:'),
        React.createElement("input", { value: tertiaryAction, onChange: e => setTertiaryAction(e.target.value) }),
        React.createElement("br", null),
        React.createElement(DesignSystemProvider, { themeKey: 'light', typographyStyle: t8yStyle },
            React.createElement("div", { style: { margin: '10px 0' } },
                React.createElement(TestDialogAlert, { title: title, body: body, hasIllustration: hasIllustration, isCentered: isCentered, actions: [primaryAction, secondaryAction] })),
            React.createElement("div", { style: { margin: '10px 0' } },
                React.createElement(TestDialogSimpleDialog, { title: title, body: body, hasIllustration: hasIllustration, isCentered: isCentered, actions: [primaryAction, secondaryAction, tertiaryAction] })),
            React.createElement("div", { style: { margin: '10px 0' } },
                React.createElement(TestDialogComplexDialog, { title: title, body: body, hasIllustration: hasIllustration, isCentered: isCentered, actions: [primaryAction, secondaryAction, tertiaryAction] })))));
};
const TestDialogAlert = props => {
    const { title, body, hasIllustration, isCentered, actions } = props;
    const [open, setOpen] = useState(false);
    return (React.createElement(Fragment, null,
        React.createElement(Button, { onClick: () => setOpen(true) }, 'Alert'),
        React.createElement(Alert.Dialog, { open: open, title: title, centered: isCentered, illustration: hasIllustration ? React.createElement(ExampleIllustration, null) : void 0 },
            React.createElement(Alert.Content, null, body),
            React.createElement(Alert.Actions, null, actions.filter(Boolean).map(action => (React.createElement(Button, { key: action, onClick: () => setOpen(false) }, action)))))));
};
const TestDialogSimpleDialog = props => {
    const { title, body, hasIllustration, isCentered, actions } = props;
    const [open, setOpen] = useState(false);
    return (React.createElement(Fragment, null,
        React.createElement(Button, { onClick: () => setOpen(true) }, 'Simple Dialog'),
        React.createElement(SimpleDialog.Dialog, { open: open, title: title, illustration: hasIllustration ? React.createElement(ExampleIllustration, null) : void 0, centered: isCentered, onClose: () => setOpen(false) },
            React.createElement(SimpleDialog.Content, null, body),
            React.createElement(SimpleDialog.Actions, null, actions.filter(Boolean).map(action => (React.createElement(Button, { key: action, onClick: () => setOpen(false) }, action)))))));
};
const TestDialogComplexDialog = props => {
    const { title, body, hasIllustration, actions } = props;
    const [open, setOpen] = useState(false);
    return (React.createElement(Fragment, null,
        React.createElement(Button, { onClick: () => setOpen(true) }, 'Complex Dialog'),
        React.createElement(ComplexDialog.Dialog, { open: open, title: title, illustration: hasIllustration ? React.createElement(ExampleIllustration, null) : void 0, onClose: () => setOpen(false) },
            React.createElement(ComplexDialog.Content, null, body),
            React.createElement(ComplexDialog.Actions, null, actions.filter(Boolean).map(action => (React.createElement(Button, { key: action, onClick: () => setOpen(false) }, action)))))));
};
const ExampleIllustration = () => (React.createElement(Alert.Illustration, null, 'Illustration here'));
export default Showcase;
