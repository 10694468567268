import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import { Badge } from '~lib/frontend/design-system/components/atoms/Badge';
import Typography from '~lib/frontend/design-system/components/Typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset, { useDesignSystemAssets } from '~lib/frontend/hooks/use-design-system-asset';
import { SmallerContainer } from '~website/components/atoms/Container';
import PageList from '~website/components/atoms/PageList';
import GuideMenu from '~website/components/layout/GuideMenu';
import Meta from '~website/components/Meta';
import { useGuideContent } from '~website/hooks/use-segment';
import Page404 from '~website/pages/NotFound';

const Guides: React.FunctionComponent = () => {
	const guideSegment = useGuideContent();
	const get = useContentKey('website_guides');
	const location = useLocation();
	const locationState = location.state as Record<string, string>;
	const selectedTopic = locationState?.topic;
	const { global } = guideSegment.content;

	useEffect(() => {
		if (location.hash === '#tools') {
			const element = document.getElementById('tools');
			const top = element.getBoundingClientRect().top - 95;

			window.scrollBy({ top, behavior: 'smooth' });
		}
	}, [location.hash]);

	const notebookAsset = useDesignSystemAsset({ type: 'illustration', value: 'il_notebook.svg' });
	const [, getAsset] = useDesignSystemAssets();

	const topics = Object.keys(global?.guides || {});
	const [guideSelected, setGuideSelected] = useState(selectedTopic ?? topics?.[0]);

	if (!guideSegment) return <Page404 />;

	const itemGuides = global.guides[guideSelected];

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`landing.head.${key}`, fallback)} />
			<GuideMenu topics={topics} setTopic={setGuideSelected} />

			<Section>
				<PageHead>
					<HeaderIllustration src={notebookAsset} />
					<Badge $style={'success'}>{get('global.badge')}</Badge>
					<Typography
						$type={'Heading.XLarge'}
						$marginTop={'large'}
						$marginBottom={'regular'}
						$align={'center'}
						$color={'textOnSurfaceBackground'}
						as={'h1'}
					>
						{get('global.header')}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{get('global.description')}
					</Typography>
				</PageHead>

				<Filters>
					{topics.map(topic => (
						<FilterButton
							key={topic}
							onClick={() => setGuideSelected(topic)}
							active={guideSelected === topic}
						>
							<Typography
								$bold
								$type={'Body.Medium'}
								$color={guideSelected === topic ? 'textOnAction' : 'primaryAction'}
							>
								{topic.split('_').join(' ')}
							</Typography>
						</FilterButton>
					))}
				</Filters>

				<div>
					<PageList
						content={itemGuides.map(a => ({
							text: a.title,
							url: `how-insurance-works/${a.url}`,
						}))}
					/>
				</div>

				<Tools id={'tools'}>
					<ToolsHeader>
						<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{get('global.tools.header')}
						</Typography>
						<Badge $style={'success'}>{get('global.tools.badge')}</Badge>
					</ToolsHeader>

					{global.tools.items.map(item => (
						<ToolLink to={item.url} key={item.title}>
							<CarCheckerTool>
								<ToolBody>
									<div>
										<Typography $bold $type={'Body.Medium'} $color={'textOnAction'}>
											{item.title}
										</Typography>
										<Typography $type={'Body.Small'} $color={'textOnAction'}>
											{item.description}
										</Typography>
									</div>
									<ToolIllustration>
										<img src={getAsset(`illustrations/${item.image}`)} alt={'Tool illustration'} />
									</ToolIllustration>
								</ToolBody>
							</CarCheckerTool>
						</ToolLink>
					))}
				</Tools>
			</Section>
		</React.Fragment>
	);
};

const Section = styled(SmallerContainer)`
	min-height: 600px;
	max-width: 950px;
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing.large};
	padding-top: 160px;

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

const HeaderIllustration = styled.img`
	width: 150px;
	height: 150px;
`;

export const PageHead = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	> svg {
		width: 172px;
		margin-bottom: ${props => props.theme.spacing.extraLarge};
	}
`;

const Filters = styled.div`
	margin: ${props => props.theme.spacing.extraLarge} 0;
	display: flex;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;

	& > * {
		flex: 0 0 auto;
		margin: ${props => props.theme.spacing.extraSmall};
	}
`;

const FilterButton = styled.div<{ active: boolean }>`
	border: 1px solid ${p => p.theme.ui.primaryAction};
	background: ${p => (p.active ? p.theme.ui.primaryAction : 'none')};
	border-radius: ${props => props.theme.spacing.small};
	text-transform: capitalize;
	cursor: pointer;
	padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.small};
`;

const Tools = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	margin: 64px 0 ${props => props.theme.spacing.large};

	${media.greaterThan('tablet')({
		margin: '64px 0 0',
	})};
`;

const ToolsHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> p {
		margin-bottom: 0;
		font-size: 16px;
	}
`;

const ToolBody = styled.div`
	display: flex;

	> * {
		flex: 1;
	}
`;

const ToolIllustration = styled.div`
	> img {
		width: 100%;
		max-width: 375px;
	}
`;

const CarCheckerTool = styled.div`
	background: ${props => props.theme.ui.secondaryBackground};
	border-radius: ${props => props.theme.spacing.small};
	box-shadow: ${p => p.theme.boxShadow.small};
	padding: ${props => props.theme.spacing.regular};
	margin: ${props => props.theme.spacing.large} 0;
	max-width: 400px;
	cursor: pointer;
`;

const ToolLink = styled(Anchor)`
	text-decoration: none;
`;

export default Guides;
