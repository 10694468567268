import { EmbeddedVideoProps } from './EmbeddedVideo';
import { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { GetterType } from '~lib/frontend/types/content';

interface TitleSegmentEmbeddedVideo {
	embeddedVideo: EmbeddedVideoProps;
	titleSegment: TitleSegmentProps;
}

export const contentAdapter = (get: GetterType): TitleSegmentEmbeddedVideo => {
	const embeddedVideo = get<EmbeddedVideoProps>('embedded_video');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		embeddedVideo,
		titleSegment,
	};
};
