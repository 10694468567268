import Cher from '@cuvva/cher';
import { all, call, getContext, put } from 'redux-saga/effects';
import { declareTestPass, licenseByUserId } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(declareTestPass.request, function* workerDeclareTestPass({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId } = payload;
    try {
        yield call(api.declareTestPass, client, userId);
        yield all([
            put(declareTestPass.success(ident)),
            put(licenseByUserId.request({ userId })),
            put(flashMessages.addSuccess('This user has now passed their test!')),
        ]);
    }
    catch (error) {
        yield put(declareTestPass.failure(ident, error));
        const cher = Cher.coerce(error);
        switch (cher.code) {
            case 'already_passed':
                yield put(flashMessages.addDanger('Already passed', 'This user has already passed their test.'));
                break;
            case 'no_license_registered':
                yield put(flashMessages.addDanger('No license registered', 'This user has no license registered to their account.'));
                break;
            case 'test_pass_already_attached':
                yield put(flashMessages.addDanger('Test pass already declared', 'A test pass has already been declared on this account.'));
                break;
            default:
                yield put(flashMessages.addError('Unable to declare test pass', 'There was an unknown error declaring that this user has passed.', cher));
                break;
        }
    }
});
