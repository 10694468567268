import React from 'react';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import Gauge from './Guage';
import SignalInterestCTAs from './SignalInterestCTAs';
import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CompareWithFriendsProps {
	open: boolean;
	closeModal: () => void;
}

interface InsightProps {
	icon: IconType;
	text: string;
}

const Insight: React.FCWithChildren<InsightProps> = ({ icon, text }) => (
	<InsightContainer>
		<Icon
			icon={icon}
			$size={'12px'}
			$display={'block'}
			$color={'primaryFill'}
			$marginRight={'small'}
		/>
		<Typography
			$type={'Body.Small'}
			$align={'center'}
			$color={'textOnSurfaceBackground'}
			$marginBottom={'small'}
		>
			{text}
		</Typography>
	</InsightContainer>
);

const CompareWithFriends: React.FCWithChildren<CompareWithFriendsProps> = ({ open, closeModal }) => {
	const yourInsights = [
		{ icon: 'ic_accelerating', text: 'Accelerating' },
		{ icon: 'ic_gauge', text: 'Speed' },
		{ icon: 'ic_corners', text: 'Cornering' },
	];

	const otherInsights = [
		{ icon: 'ic_accelerating', text: 'Accelerating' },
		{ icon: 'ic_focusing', text: 'Focusing' },
	];

	return (
		<ComplexDialog.Dialog
			open={open}
			title={'Compare with friends'}
			onClose={closeModal}
		>
			<ComplexDialog.Content>
				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginTop={'regular'}
					$marginBottom={'extraLarge'}
					$align={'center'}
				>
					{'Get a trip score for every car journey you make and compare with friends or family that borrow you car through Cuvva.'}
				</Typography>
				<Container>
					<FriendInfo>
						<Typography
							$type={'Heading.Medium'}
							$color={'textOnSurfaceBackground'}
							$marginTop={'small'}
							$marginBottom={'extraLarge'}
						>
							{'You'}
						</Typography>
						<Gauge value={'88'} percentage={28} />
						<Typography
							$type={'Body.Medium'}
							$bold
							$align={'center'}
							$color={'textOnSurfaceBackground'}
							$marginBottom={'small'}
							$marginTop={'extraLarge'}
						>
							{'Better at'}
						</Typography>
						{yourInsights.map(i => (
							<Insight key={i.icon} icon={i.icon as IconType} text={i.text} />
						))}
					</FriendInfo>
					<FriendInfo>
						<Typography
							$type={'Heading.Medium'}
							$color={'textOnSurfaceBackground'}
							$marginTop={'small'}
							$marginBottom={'extraLarge'}
						>
							{'Mum'}
						</Typography>
						<Gauge value={'75'} percentage={45} />
						<Typography
							$type={'Body.Medium'}
							$bold
							$align={'center'}
							$color={'textOnSurfaceBackground'}
							$marginBottom={'small'}
							$marginTop={'extraLarge'}
						>
							{'Better at'}
						</Typography>
						{otherInsights.map(i => (
							<Insight key={i.icon} icon={i.icon as IconType} text={i.text} />
						))}
					</FriendInfo>
				</Container>
			</ComplexDialog.Content>
			<ComplexDialog.Actions>
				<SignalInterestCTAs
					optinEvent={`${vehicleProfileEvents.ownersHub.compareWithFriendsCta}_yes`}
					optoutEvent={`${vehicleProfileEvents.ownersHub.compareWithFriendsCta}_no`}
					closeModal={closeModal}
				/>
			</ComplexDialog.Actions>
		</ComplexDialog.Dialog>
	);
};

const Container = styled.div`
	width: 100%;
	background: ${p => p.theme.ui.surfaceFillMuted};
	padding: ${p => p.theme.spacing.large};
	border-radius: ${p => p.theme.borderRadius.large};
	display: flex;
	justify-content: space-between;

	& > div:not(:last-child) {
		margin-right: ${p => p.theme.spacing.large};
	}
`;

const FriendInfo = styled.div`
	background: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};
	box-shadow: ${p => p.theme.boxShadow.extraSmall};
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	padding: ${p => p.theme.spacing.extraLarge} 20px;
`;

const InsightContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

export default CompareWithFriends;
