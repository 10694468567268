export const initialLtmCancellationState = {
    getConfig: {},
    quoteCancellation: {},
    quoteAutocycle: {},
    confirmCancellation: {},
};
export const LtmCancellationActionTypes = {
    GET_CONFIG: '@@platform/ltm-verification/GET_CONFIG',
    QUOTE_CANCELLATION: '@@platform/ltm-verification/QUOTE_CANCELLATION',
    QUOTE_AUTOCYCLE: '@@platform/ltm-verification/QUOTE_AUTOCYCLE',
    CONFIRM_CANCELLATION: '@@platform/ltm-verification/CONFIRM_CANCELLATION',
};
