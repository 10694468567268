import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';
import { ComplexDialogContent } from '~lib/frontend/design-system/components/dialog/atoms/ComplexDialogStyle';

export const StyledComplexDialogContent = styled(ComplexDialogContent)`
	padding: 0;
`;

export const EmailForm = styled.div`
	padding: ${p => p.theme.spacing.extraLarge} 64px;

	${media.lessThan('tablet')`
		padding: 32px 16px;
	`}
`;
