import React from 'react';
import styled from 'styled-components';

import Spinner from '~lib/frontend/design-system/components/spinner';

const SpinnerCover: React.FunctionComponent = () => (
	<PageWrap>
		<InnerWrap>
			<Spinner $size={'50px'} />
		</InnerWrap>
	</PageWrap>
);

const PageWrap = styled.div`
	display: flex;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const InnerWrap = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 50px;
	height: 50px;

	& > * {
		width: 50px;
		height: 50px;
	}
`;

export default SpinnerCover;
