import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Guides } from '../Containers';
import Content from '../content';
import DownloadInfo from '../molecules/DownloadInfo';
import { media } from '~lib/frontend/design-system/breakpoints';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import RegPlateInput from '~lib/frontend/design-system/components/input/molecules/RegPlateInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';
import { findVehicleId, getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import PageList from '~website/components/atoms/PageList';
import ToolLink from '~website/features/tools/molecules/ToolLink';
import VehicleProfileTable from '~website/features/vehicle-profile/components/organisms/VehicleProfileTable';
import { useGuideContent, useWebsiteCoreContent } from '~website/hooks/use-segment';
import { ApplicationState } from '~website/store';

interface GroupCheckerProps {
	get: GetterType;
}

const customErrorMessage = 'Not a valid reg plate, double check and try again';

const carCheckerGuides = ['how-long-does-no-claims-bonus-last', 'can-you-tax-a-car-without-insurance', 'age-and-car-insurance'];

const CarChecker: React.FCWithChildren<GroupCheckerProps> = ({ get }) => {
	const segment = useWebsiteCoreContent();
	const content = segment.content.pages?.['car-checker'];
	const guideSegment = useGuideContent();
	const { global } = guideSegment?.content || {};
	const topics = global ? Object.keys(global.guides) : [];
	const allGuides = topics.reduce((acc, curr) => [...acc, ...global.guides[curr]], []);
	const displayedGuides = allGuides.filter(({ url }) => carCheckerGuides.includes(url));
	const [input, setInput] = useState('');
	const [vehicleId, setVehicleId] = useState('');
	const vehicle = useSelector((s: ApplicationState) => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleSearch = useSelector((s: ApplicationState) => s.platform.vehicle.findVehicleId[`vrm:${input}`]);

	const dispatch = useDispatch();

	const onSearch = () => dispatch(findVehicleId.request({ field: 'vrm', value: input }));

	const onKeyUp = (e: React.KeyboardEvent) => {
		// eslint-disable-next-line deprecation/deprecation
		if (e.key === 'Enter' || e.keyCode === 13)
			onSearch();
	};

	useEffect(() => {
		if (vehicleSearch?.response) {
			setVehicleId(vehicleSearch?.response.id);
			dispatch(getPublicVehicle.request({ id: vehicleSearch?.response.id, bypassChecks: false }));
		}
	}, [dispatch, vehicleSearch]);

	return (
		<React.Fragment>
			<CarCheckerContainer>
				<InputDecorator
					error={(vehicle?.error || vehicleSearch?.error) && customErrorMessage}
				>
					<RegPlateInput
						value={input}
						onChange={input => setInput(input)}
						placeholder={'Search number plate'}
						ariaLabel={'Number plate'}
						onKeyUp={onKeyUp}
						displaySearchButton
						loading={vehicle?.fetching || vehicleSearch?.fetching}
						onSearch={onSearch}
					/>
				</InputDecorator>
				{vehicle?.response && (
					<React.Fragment>
						<VehicleProfileTableContainer>
							<VehicleProfileTable
								vehicle={vehicle?.response}
								vehicleId={vehicleId}
							/>
						</VehicleProfileTableContainer>
						<DownloadInfo get={get} />
						<Guides>
							<Typography
								$type={'Heading.Medium'}
								$align={'center'}
								$marginBottom={'large'}
								$color={'textOnSurfaceBackground'}
							>
								{'Guides'}
							</Typography>
							<PageList
								content={displayedGuides.map(a => ({
									text: a.title,
									url: `how-insurance-works/${a.url}`,
								}))}
							/>
						</Guides>
						<Typography
							$type={'Heading.Medium'}
							$align={'center'}
							$marginBottom={'large'}
							$color={'textOnSurfaceBackground'}
						>
							{get('other_tools_header')}
						</Typography>
						{content?.other_tools?.map((tool, index) => (
							<ToolLink
								key={`${index}-${tool.title}`}
								title={tool.title}
								url={tool.url}
								subtitle={tool.subtitle}
								illustrationName={`${tool.illustration_name}.svg`}
							/>
						))}
					</React.Fragment>
				)}
			</CarCheckerContainer>
			{!vehicle?.response && <Content get={get} />}
		</React.Fragment>
	);
};

const CarCheckerContainer = styled.div`
	width: 340px;
	${media.greaterThan('tablet')`
		width: 370px;
	`}
`;

const VehicleProfileTableContainer = styled.div`
	margin-top: 64px;
`;

export default CarChecker;
