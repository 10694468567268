import * as Sentry from '@sentry/react';
export default function configureSentry(_app, dsn, userId) {
    // eslint-disable-next-line no-process-env
    if (process.env.REACT_CUVVA_DEVELOPMENT)
        return; // No sentry when running locally, no no no
    const options = {
        dsn,
        environment: window.cuvva.client.env,
        release: window.cuvva.deployment.gitCommit,
        tracesSampleRate: 0.2,
        integrations: [
            Sentry.browserTracingIntegration({
                tracingOrigins: ['api.prod.cuv-prod.com', /api\.[a-z0-9]+\.cuv-nonprod\.com/],
            }),
            Sentry.replayIntegration(),
        ],
        replaysOnErrorSampleRate: 0.1,
    };
    Sentry.init(options);
    if (userId)
        Sentry.setUser({ id: userId });
}
