export const initialSurveyState = {
    listSurveysByUserId: {},
    submitSurvey: {},
};
export const SurveyActionTypes = {
    LIST_SURVEYS_BY_USER_ID: '@@platform/surveys/LIST_SURVEYS_BY_USER_ID',
    LIST_SURVEYS_BY_USER_ID_FAILURE: '@@platform/surveys/LIST_SURVEYS_BY_USER_ID_FAILURE',
    LIST_SURVEYS_BY_USER_ID_SUCCESS: '@@platform/surveys/LIST_SURVEYS_BY_USER_ID_SUCCESS',
    SUBMIT_SURVEY: '@@platform/surveys/SUBMIT_SURVEY',
    SUBMIT_SURVEY_FAILURE: '@@platform/surveys/SUBMIT_SURVEY_FAILURE',
    SUBMIT_SURVEY_SUCCESS: '@@platform/surveys/SUBMIT_SURVEY_SUCCESS',
};
