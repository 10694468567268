import { createAction } from '@reduxjs/toolkit';

import { CookieConsentType } from './types';

const action = (...args: TemplateStringsArray[]) => ['@@internal/cookie-policy-consent']
	.concat(...args)
	.join('/');

export const cookieConsentAction = {
	setCookieConsent: createAction<CookieConsentType>(action`setUserCookiePolicy`),
};
