import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import useVoluntaryExcess from '../hooks/use-voluntary-excess';
import { runLtmEstimate } from '../store';
import DownloadAppSubtext from './atoms/DownloadAppSubtext';
import HorizontalRule from './atoms/HorizontalRule';
import TotalExcess from './binded-fields/TotalExcess';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import SmartPricing from './molecules/SmartPricing';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';

const Customise: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { value: voluntaryExcess } = useVoluntaryExcess();
	const smartPricing = useSelector(s => s.internal.quote.state.smartPricing);
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);

	// If you're refreshing this, we need to request a new quote so that we can load the dropdown.
	useEffect(() => {
		dispatch(runLtmEstimate.actions.request());
	}, [dispatch, voluntaryExcess, smartPricing]);

	return (
		<div>
			<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginBottom={'extraLarge'}>
				{'Let’s perfect your cover'}
			</Typography>
			<PageError page={page} />

			<SmartPricing />

			<Margin $marginTop={'extraLarge'} $marginBottom={'extraLarge'}>
				<HorizontalRule />
			</Margin>

			<Margin $marginBottom={'extraLarge'}>
				<TotalExcess />
			</Margin>

			<div>
				<ProceedButton path={'/quote/your-quote'}>
					{'Continue'}
				</ProceedButton>

				{smartPricing && <DownloadAppSubtext />}
			</div>
		</div>
	);
};

export default Customise;
