import { getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(actions.sharedQuoteLiveLocation.request, function* sharedQuoteLiveLocation({ meta, payload }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield api.sharedQuoteLiveLocation(client, payload);
        yield put(actions.sharedQuoteLiveLocation.success(ident, response));
    }
    catch (error) {
        yield put(actions.sharedQuoteLiveLocation.failure(ident, error));
    }
});
