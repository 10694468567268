import { combineReducers } from 'redux';

import listCuvvaPeeps from './list-cuvva-peeps';

export {
	listCuvvaPeeps,
};

export default combineReducers({
	listCuvvaPeeps,
});
