import React from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import { AlignSingleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import { FaultStatus } from '~lib/platform/incident/types';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import notEmptyValidator from '~website/features/quote/validators/not-empty';

const optionsAtFault = [{
	value: 'yes',
	display: 'Yes',
}, {
	value: 'no',
	display: 'No',
}, {
	value: 'joint',
	display: 'Joint fault',
}];

const IncidentFault: React.FCWithChildren<{ index: number }> = ({ index }) => {
	const { onChange, validation, value } = useIncidentItemInternalChangeRequest(index, 'atFault', notEmptyValidator);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	return (
		<QuoteInputDecorator
			label={'Were you at fault?'}
			onFocus={onFocus}
			onBlur={onBlur}
			error={error || pageError}
		>
			<AlignSingleColChildren>
				{optionsAtFault.map(o => (
					<RadioInput
						key={o.display}
						name={`incident-at-fault-${index}`}
						checked={value === o.value}
						value={o.display}
						onChange={() => onChange(o.value as FaultStatus)}
					/>
				))}
			</AlignSingleColChildren>
		</QuoteInputDecorator>
	);
};

export default IncidentFault;
