const brandColors = {
    indigo050: '#EBEBFF',
    indigo100: '#D8D8FD',
    indigo200: '#BBB7FB',
    indigo300: '#978CF8',
    indigo400: '#7964F1',
    indigo500: '#6544E2',
    indigo600: '#4C35B6',
    indigo700: '#3E2D8F',
    indigo800: '#2D2263',
    indigo900: '#1C1640',
    indigo1000: '#050224',
    violet050: '#FAEBFF',
    violet100: '#F5DCFE',
    violet200: '#E8B9FD',
    violet300: '#D08EF6',
    violet400: '#B66BEB',
    violet500: '#9A50DB',
    violet600: '#7F3ABC',
    violet700: '#66289D',
    violet800: '#4F197F',
    violet900: '#400D6D',
    orange050: '#FFE8DB',
    orange100: '#FFDBC7',
    orange200: '#FFC3A3',
    orange300: '#FF9B70',
    orange400: '#F67846',
    orange500: '#EA5F2E',
    orange600: '#D2481E',
    orange700: '#B5361C',
    orange800: '#9D2110',
    orange900: '#82170D',
    red050: '#FFE6E9',
    red100: '#FFDBE2',
    red200: '#FFADBC',
    red300: '#FF8599',
    red400: '#FB5671',
    red500: '#E72E50',
    red600: '#D7193C',
    red700: '#BA1239',
    red800: '#9B0D33',
    red900: '#85092D',
    blue050: '#E6F3FF',
    blue100: '#D1E8FF',
    blue200: '#A8D1FF',
    blue300: '#75B3FF',
    blue400: '#3D8EFF',
    blue500: '#116FFC',
    blue600: '#0C56D9',
    blue700: '#0D41B0',
    blue800: '#102F7F',
    blue900: '#0F2362',
    yellow050: '#FDF8D8',
    yellow100: '#FEF4B4',
    yellow200: '#FDEA7C',
    yellow300: '#FDE25E',
    yellow400: '#FCD430',
    yellow500: '#F9C802',
    yellow600: '#ECAD0E',
    yellow700: '#C9870D',
    yellow800: '#995D0B',
    yellow900: '#773F0D',
    green050: '#EBFFF0',
    green100: '#CAFCDC',
    green200: '#AAF3C6',
    green300: '#7CDFA5',
    green400: '#4AC98A',
    green500: '#28AF74',
    green600: '#1D9162',
    green700: '#128159',
    green800: '#0C6447',
    green900: '#09533D',
    grey050: '#F2F2F4',
    grey100: '#E6E6EA',
    grey200: '#D8D8DF',
    grey300: '#C4C4CF',
    grey400: '#ABABBA',
    grey500: '#8C8C9B',
    grey600: '#646473',
    grey700: '#494955',
    grey800: '#383842',
    grey850: '#2B2B33',
    grey900: '#1B1B22',
    grey950: '#141419',
    white: '#FFFFFF',
    black: '#000000',
    violetGradientLeading: 'radial-gradient(100% 100% at 0% 100%, #A644E2 5.93%, rgba(101, 68, 226, 0.3) 59.9%, rgba(101, 68, 226, 0) 100%)',
    violetGradientCentre: 'radial-gradient(circle closest-corner at bottom center, #A644E2 5.93%, rgba(101, 68, 226, 0.3) 59.9%, rgba(101, 68, 226, 0) 100%)',
    violetGradientTrailing: 'radial-gradient(100% 100% at 100% 100%, #A644E2 5.93%, rgba(101, 68, 226, 0.3) 59.9%, rgba(101, 68, 226, 0) 100%)',
};
export default brandColors;
