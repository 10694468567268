import { call, getContext, put } from 'redux-saga/effects';
import { submitPhotos } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(submitPhotos.request, function* submitPhotosSaga(action) {
    const client = yield getContext('api');
    const { licensePhotoId, profilePhotoId, userId } = action.payload;
    const serviceRequest = new Map();
    serviceRequest.set('userId', userId);
    if (profilePhotoId)
        serviceRequest.set('profilePhotoId', profilePhotoId);
    if (licensePhotoId)
        serviceRequest.set('licensePhotoId', licensePhotoId);
    try {
        yield call([client, client.rpc], 'service-kyc', '1/2020-04-01/submit_photos', Object.fromEntries(serviceRequest));
        yield put(submitPhotos.success(void 0));
    }
    catch (error) {
        yield put(submitPhotos.failure(error));
    }
});
