import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import Select from '../atoms/Select';
import InputDecoratorContext from '../context/decorator';
const SelectInput = (props) => {
    const { error } = useContext(InputDecoratorContext);
    const { options, placeholder, disabled, value: objectValue, onChange, onChangeCapture, $size, ...restProps } = props;
    const value = options.findIndex(o => isEqual(objectValue, o.value));
    const onSelectChange = (e) => {
        // SC: Some usages of the select input expect a valid value to be returned,
        // therefore the only thing we can do is to not allow in any case a change of value
        // if selecting the placeholder (the placeholder is marked as disabled, but you never know)
        if (placeholder && placeholder === e.target.value)
            return;
        const selectedValue = options[parseInt(e.target.value, 10)];
        onChange(selectedValue, e);
    };
    return (React.createElement(Select, { "$error": Boolean(error), as: 'select', disabled: disabled, defaultValue: placeholder, onChange: onSelectChange, onChangeCapture: onChangeCapture, value: value === -1 ? void 0 : value, "$size": $size, ...restProps },
        placeholder && (React.createElement("option", { disabled: true, value: placeholder, selected: value === -1 }, placeholder)),
        options?.map((o, index) => (React.createElement("option", { key: `${o.label}:${index}`, value: index }, o.label)))));
};
export default SelectInput;
