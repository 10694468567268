import { combineReducers } from 'redux';
import createDiscount from './create-discount';
import createPromos from './create-promos';
import discount from './discount';
import doNotOffer from './do-not-offer';
import generateReferralCode from './generate-referral-code';
import listDiscounts from './list-discounts';
import listDiscountsNeu from './list-discounts-neu';
import listDiscountsPublic from './list-discounts-public';
import listPromos from './list-promos';
import redeemCode from './redeem-code';
import updatePromo from './update-promo';
import userReferees from './user-referees';
import userReferrer from './user-referrer';
export { createDiscount, createPromos, discount, doNotOffer, listDiscounts, listDiscountsNeu, listDiscountsPublic, listPromos, redeemCode, updatePromo, userReferees, userReferrer, generateReferralCode, };
export default combineReducers({
    createDiscount,
    createPromos,
    discount,
    doNotOffer,
    listDiscounts,
    listDiscountsNeu,
    listDiscountsPublic,
    listPromos,
    redeemCode,
    updatePromo,
    userReferees,
    userReferrer,
    generateReferralCode,
});
