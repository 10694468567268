import { useEffect, useState } from 'react';
const useAnimateModal = (open, backgroundTimeout) => {
    const [bgRendered, setBgRendered] = useState(false);
    const [dialogRendered, setDialogRendered] = useState(false);
    useEffect(() => {
        if (open)
            setBgRendered(true);
        else
            setDialogRendered(false);
    }, [open]);
    useEffect(() => {
        if (bgRendered)
            setDialogRendered(true);
    }, [bgRendered]);
    useEffect(() => {
        if (dialogRendered)
            return void 0;
        const timeout = setTimeout(() => {
            setBgRendered(false);
        }, backgroundTimeout);
        return () => void clearTimeout(timeout);
    }, [backgroundTimeout, dialogRendered]);
    return {
        bgRendered,
        dialogRendered,
    };
};
export default useAnimateModal;
