function dateValidator(value: string) {
	if (!value)
		return { valid: false };

	const splitDate = value.split('-').map(v => parseInt(v, 10));

	if (splitDate.length !== 3)
		return { valid: false };

	if (splitDate.some(Number.isNaN) || !splitDate.every(Boolean))
		return { valid: false };

	const [year, month, day] = splitDate;

	return {
		valid: validDate(day, month, year),
		day,
		month,
		year,
	};
}

function validDate(day: number, month: number, year: number) {
	if (!day || !month || !year)
		return false;

	if (day.toString().length > 2 || month.toString().length > 2 || year.toString().length !== 4)
		return false;

	if (day < 1 || month < 1)
		return false;

	const date = new Date(year, month - 1, day); // month is 0-based

	if (isNaN(date.getTime()))
		return false;

	const currentYear = (new Date()).getFullYear();

	if (year < currentYear - 1000)
		return false;

	return true;
}

export default dateValidator;
