import styled from 'styled-components';
export const DialogContainer = styled.div `
	outline: 0;
	position: relative;
	background: ${p => p.theme.ui.secondarySurface};
	box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.large};
	max-width: 480px;
	margin: ${p => p.theme.spacing.large};
	max-height: calc(100% - 24vmin);
	width: calc(100% - calc(${p => p.theme.spacing.large} * 2));

	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		max-width: 512px;
		padding: 24px;
	}

	${p => p.$centered && 'text-align: center'};
`;
export const DialogTitleWrap = styled.div `
	margin-top: ${p => p.theme.spacing.large};
	margin-bottom: ${p => p.theme.spacing.large};

	@media (min-width: 768px) {
		margin-top: 24px;
	}
`;
export const DialogContentContainer = styled.div `
	${p => p.theme.typography['Body.Medium']()};
	color: ${p => p.theme.ui.textOnSurfaceBackgroundMuted};
	overflow-y: auto;
`;
