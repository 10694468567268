import { call, getContext, put } from 'redux-saga/effects';
import { clearIncidents as clearIncidentsApi } from '../../api';
import { clearIncidents } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(clearIncidents.request, function* workerClearIncident({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId } = payload;
    try {
        yield call(clearIncidentsApi, client, userId);
        yield put(clearIncidents.success(ident));
    }
    catch (error) {
        yield put(clearIncidents.failure(ident, error));
    }
});
