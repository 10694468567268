import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import CuvvaLogo from '~lib/frontend/atoms/CuvvaLogo';
import { media } from '~lib/frontend/design-system/breakpoints';
import Icon from '~lib/frontend/design-system/components/Icon';

interface HeaderBarProps {
	LeftHeaderComponent?: React.ReactElement;
}

const HeaderBar: React.FCWithChildren<HeaderBarProps> = ({ LeftHeaderComponent }) => (
	<HeaderBarWrap>
		<Anchor href={'/'}>
			<CuvvaLogo />
		</Anchor>

		<LeftNav>
			{LeftHeaderComponent}
			<Anchor
				href={'mailto:support@cuvva.com'}
				className={'intercom-launcher'}
			>
				<Icon icon={'ic_support'} $size={'24px'} $color={'primaryFill'} />
			</Anchor>
		</LeftNav>
	</HeaderBarWrap>
);

const LeftNav = styled.div`
	display: flex;
	align-items: center;

	>* {
		margin-right: 6px;
	}
`;

const HeaderBarWrap = styled.div`
	padding: 22px 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};

	@media (max-width: 767px) {
		padding: 20px ${p => p.theme.spacing.regular};
	}

	&>:first-child {
		height: 26px;
		flex: 0 0 200px;
		display: flex;

		${media.lessThan('tablet')`
			height: 20px;
			flex: 0 0 150px;
		`}

		svg {
			max-width: 100%;
			max-height: 100%;
			height: 100%;

			path {
				fill: ${p => p.theme.ui.neutralFill};
			}
		}
	}
`;

export default HeaderBar;
