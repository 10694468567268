import Cher from '@cuvva/cher';

const notEmptyNumericOrBooleanValidator = (value: number | boolean) => {
	if (value === void 0)
		return new Cher('invalid');

	return void 0;
};

export default notEmptyNumericOrBooleanValidator;
