import React, { Fragment } from 'react';
import styled from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';
import {
	contentAdapter as titleSegmentContentAdapter,
	TitleSegmentProps,
} from '~website/components/atoms/TitleSegment';

export interface StepItem {
	index?: number;
	title: string;
	body: string;
}

export interface TitleSegmentStepsProps extends TitleSegmentProps {
	hideLine?: boolean;
	steps: StepItem[];
}

const BrandSubHeader: React.FCWithChildren<TitleSegmentStepsProps> = props => {
	const { hideLine, steps, title } = props;

	return (
		<Fragment>
			<TitleWrapper>
				<Typography $type={'Heading.Large'} as={'h2'} $color={'textOnSurfaceBackground'}>
					{title}
				</Typography>
			</TitleWrapper>
			<Wrapper>
				{!hideLine && <DottedLine />}
				{steps.map((s, i) => (
					<Step key={`${i}-${s.title}`}>
						<Orb>
							<Typography $bold $type={'Label.Large'} $color={'textOnFill'}>
								{i + 1}
							</Typography>
						</Orb>

						<Typography $bold $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
							{s.title}
						</Typography>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
							{s.body}
						</Typography>
					</Step>
				))}
			</Wrapper>
		</Fragment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentStepsProps => {
	const steps = get<StepItem[]>('steps') || [];
	const hideLine = get<boolean>('hide_line');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		// @ts-ignore
		steps,
		hideLine,
		...titleSegment,
	};
};

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: 800px;
	gap: 30px;
	text-align: center;

	margin: 50px auto;

	${media.lessThan('tablet')`
		flex-direction: column;
	`}
`;

const DottedLine = styled.div`
	position: absolute;
	z-index: 0;
	margin: 0 15px;
	top: calc(36px / 2);
	left: 15%;
	right: 15%;
	border-top: 1.125px dashed ${p => p.theme.ui.borderSeparator};

	${media.lessThan('tablet')`
		display: none;
	`}
`;

const Step = styled.div`
	flex: 0 1 33%;
	z-index: 1;

	${p => media.lessThan('tablet')`
		margin-bottom: ${p.theme.spacing.extraLarge};

		&:last-of-type {
			margin-bottom: 0;
		}
	`}
`;

const Orb = styled.div`
	background: ${p => p.theme.ui.surfaceDark};
	border-radius: 100%;
	margin: 0 auto;
	width: 35px;
	height: 36px;
	margin-bottom: ${p => p.theme.spacing.extraLarge};
	color: ${p => p.theme.ui.textOnFill};

	> ${Typography} {
		line-height: 36px;
	}
`;

const TitleWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	flex-direction: column;
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	max-width: 560px;
`;

export default BrandSubHeader;
