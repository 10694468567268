import CuvvaClient from '@cuvva/cuvva-client';
import { getContext } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';

import { clearAuthUser } from '../actions';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { trackEvent } from '~website/features/analytics/store/actions';

export default createTakeEverySagaSet(clearAuthUser, function* clearAuthUserSaga() {
	const client: CuvvaClient = yield getContext('api');

	client.auth = null;

	yield put(trackEvent({ eventName: 'sign_out' }));

	window.location.href = createAwareUrl('/');
});
