import React from 'react';

import type { FlattenPolicySubs } from '../hooks/use-subscriptions';

const SinglePolicyContext = React.createContext<FlattenPolicySubs>(void 0);

function useSinglePolicy() {
	const context = React.useContext(SinglePolicyContext);

	if (context === void 0)
		throw new Error('useSinglePolicy must be used within a SinglePolicyProvider');

	return context;
}

export { useSinglePolicy };
export default SinglePolicyContext;
