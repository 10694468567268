import React from 'react';
import styled from 'styled-components';

import type { FlattenPolicySubs } from '../hooks/use-subscriptions';
import PolicyCoreCard from './PolicyCoreCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';

interface PreviousPolicyProps {
	subs: FlattenPolicySubs[];
}

const PreviousPolicy: React.FCWithChildren<PreviousPolicyProps> = ({ subs }) => (
	<Margin $marginTop={'large'}>
		<Typography $type={'Heading.Small'} $bold $color={'textOnSurfaceBackground'}>
			{'Previous'}
		</Typography>

		<PolicyWrapper>
			{subs.map(sub => (
				<PolicyCardWrapper key={sub.policyId}>
					<PolicyCoreCard sub={sub} />
				</PolicyCardWrapper>
			))}
		</PolicyWrapper>
	</Margin>
);

export default PreviousPolicy;

export const PolicyWrapper = styled.div`
	width: 100%;
	border: none;
	padding: ${p => p.theme.spacing.large};
	background-color: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};

	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
	margin-top: ${p => p.theme.spacing.large};
`;

const PolicyCardWrapper = styled.div`
	&:not(:last-child) {
		padding-bottom: ${p => p.theme.spacing.large};
		border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	}
`;
