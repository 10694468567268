import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import IlloWrapper from '../../atoms/IlloWrapper';
import VerifyListItems from './VerifyListItems';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';
import useUser from '~website/hooks/use-user';

export interface UploadScreenProps {
	illuFilename: string;
	illuAlt: string;
	heading: string;
	body?: string;
	listItems?: string[];
	action: (file: File, userId: string, body: string) => void;
	analyticsPage: string;
}

export interface UploadCoreProps extends UploadScreenProps {
	isUploading: boolean;
	uploadError: Cher | undefined;
}

const UploadScreen = forwardRef<HTMLInputElement, UploadCoreProps>((props, ref) => {
	const {
		illuFilename,
		illuAlt,
		heading,
		body,
		listItems = [],
		action,
		isUploading,
		uploadError,
		analyticsPage,
	} = props;

	const { userId } = useUser();
	const dispatch = useDispatch();

	const [error, setError] = useState(void 0);

	useEffect(() => {
		setError(uploadError);
	}, [uploadError]);

	const openFilePicker = () => {
		const fileRef = ref as React.MutableRefObject<HTMLInputElement>;

		if (!fileRef?.current) return;

		fileRef.current.dispatchEvent(new MouseEvent('click'));
	};

	const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setError(void 0);

		const file = Array.from(e.target.files)?.[0];

		if (!file) return;

		const bufferReader = new FileReader();
		const previewReader = new FileReader();

		bufferReader.onload = async () => {
			previewReader.readAsDataURL(file);
		};

		bufferReader.onerror = () => {
			const error = new Cher('file_upload_failed', { message: 'File upload failed' });

			setError(error);
		};

		previewReader.onload = async () => {
			dispatch(action(file, userId, previewReader.result as string));
		};

		bufferReader.readAsArrayBuffer(file);
	};

	useEffect(() => {
		dispatch(trackPayOnWebScreenView({ screenName: analyticsPage }));
	}, [dispatch, analyticsPage]);

	return (
		<React.Fragment>
			<React.Fragment>
				{illuFilename && <IlloWrapper filename={illuFilename} altDescription={illuAlt} />}

				{heading && (
					<Typography
						$type={'Heading.Medium'}
						$color={'textOnSurfaceBackground'}
						$align={'center'}
						$marginTop={'large'}
						$marginBottom={'large'}
					>
						{heading}
					</Typography>
				)}

				{body && (
					<Margin $marginBottom={'extraLarge'}>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
							{body}
						</Typography>
					</Margin>
				)}

				{listItems.length > 0 && <VerifyListItems items={listItems} />}
			</React.Fragment>

			<CustomInput
				ref={ref}
				type={'file'}
				multiple={false}
				accept={'image/png, image/jpg, image/jpeg'}
				onChange={onUploadChange}
			/>

			<Margin $marginTop={'extraLarge'} $marginBottom={'large'}>
				<Button $stretch $size={'large'} type={'button'} onClick={openFilePicker} $loading={isUploading}>
					{'Upload photo'}
				</Button>
			</Margin>

			<Typography $align={'center'} $type={'Body.Small'}>
				{'Please upload a JPG, PNG or GIF'}
			</Typography>

			{error && (
				<ErrorWrapper>
					<Icon icon={'ic_exclamation_circle'} $size={'20px'} $color={'textDanger'} />

					<Typography $type={'Body.Small'} $color={'textDanger'} $marginLeft={'small'}>
						{error.message}
					</Typography>
				</ErrorWrapper>
			)}
		</React.Fragment>
	);
});

const CustomInput = styled.input`
	display: none;
`;

const ErrorWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	margin-top: ${p => p.theme.spacing.large};
`;

export default UploadScreen;
