import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ProceedButton from '../ProceedButton';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as SimpleDialog from '~lib/frontend/design-system/components/dialog/molecules/SimpleDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';

interface IncidentConfirmationModalProps {
	openModal: boolean;
	onClose: () => void;
}

const IncidentConfirmationModal: React.FCWithChildren<IncidentConfirmationModalProps> = props => {
	const { openModal, onClose } = props;

	const product = useSelector(s => s.internal.quote.state.product);
	const ltm = product === 'ltm';

	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) =>
		g(`app_settings.edit_account.incidents.declare_incidents_view_v2.${key}`, fallback);

	const incidentCheckList = get<string[]>('items', []);

	return (
		<SimpleDialog.Dialog centered open={openModal} title={''} onClose={onClose}>
			<SimpleDialog.Content>
				<Typography $align={'center'} $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{get('title', 'Confirm you’ve declared:')}
				</Typography>

				<IncidentItemWrapper>
					<ul>
						{incidentCheckList.map(i => (
							<li key={i}>
								<Icon
									icon={'ic_question_fill'}
									$size={'20px'}
									$color={'primaryFill'}
									$marginRight={'small'}
								/>
								<Typography $align={'left'} $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{i}
								</Typography>
							</li>
						))}
					</ul>
				</IncidentItemWrapper>

				<Typography $align={'left'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{get(
						'body',
						'If you’ve been involved in or reported an incident in the last 5 years, you need to declare it now as you won’t be able to do it later.',
					)}
				</Typography>
			</SimpleDialog.Content>
			<SimpleDialog.Actions>
				<Button $type={'neutralMuted'} $size={'large'} onClick={onClose}>
					{g('cta.cancel', 'Add an incident')}
				</Button>
				<ProceedButton
					type={'submit'}
					path={ltm ? '/quote/vehicle-info' : '/quote/your-estimate'}
					submitEstimate={!ltm}
				>
					{get('cta.continue', 'I’ve declared all incidents')}
				</ProceedButton>
			</SimpleDialog.Actions>
		</SimpleDialog.Dialog>
	);
};

const IncidentItemWrapper = styled.div`
	margin: ${p => p.theme.spacing.large} 0;

	ul {
		list-style: none;

		li {
			display: flex;
			align-items: center;
			margin-top: ${p => p.theme.spacing.large};
		}
	}
`;

export default IncidentConfirmationModal;
