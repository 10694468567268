import React from 'react';

import { AdapterProps } from '../types';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import EmbeddedVideo from '~website/components/atoms/EmbeddedVideo';
import TwoColumnsLayout from '~website/components/atoms/layout/TwoColumns';
import TitleSegment from '~website/components/atoms/TitleSegment';
import { contentAdapter } from '~website/components/atoms/TitleSegmentEmbeddedVideo';

interface ExtraProps {
	id?: string;
	flip?: boolean;
	asset?: DesignSystemAsset;
}

const adapter: React.FCWithChildren<AdapterProps> = ({ content, getter }) => {
	const { flip, id } = content as ExtraProps;
	const props = contentAdapter(getter);
	const { embeddedVideo, titleSegment } = props;
	const video = <EmbeddedVideo {...embeddedVideo} />;

	return (
		<TwoColumnsLayout flip={flip} id={id}>
			<TitleSegment {...titleSegment} />
			{video}
		</TwoColumnsLayout>
	);
};

export default {
	type: 'title_segment_embedded_video',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			flip: { type: 'boolean' },
			embedded_video: { type: 'embedded_video' },
			centered: { type: 'boolean' },
			title: { type: 'string' },
			subtitle: { type: 'string' },
			body: { type: 'text' },
			segment_checklist: { type: 'checklistItem[]' },
			show_button: { type: 'cta' },
			allow_more_buttons: { type: 'cta[]' },
			show_app_store_buttons: { type: 'boolean' },
			show_trustpilot: { type: 'boolean' },
		},
		default: {
			flip: false,
			embedded_video: {
				provider: 'youtube',
				source: '94lxIjf0Np8',
				autoplay: false,
				thumbnail: 'home/video_thumbnail.jpg',
			},
			centered: true,
			title: 'Anybody that needs a test',
			body: 'gets a test',
			subtitle: 'subtitle',
			segment_checklist: [
				{ label: 'One', checked: true },
				{ label: 'Two', checked: true },
				{ label: 'Three', checked: true },
			],
			show_button: {
				text: 'Get a quote',
				url: '/get-an-estimate',
			},
			show_app_store_buttons: true,
			show_trustpilot: true,
		},
	},
};
