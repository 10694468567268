import styled, { css } from 'styled-components';
const Textarea = styled.textarea `
	${p => p.$size === 'small' && css `
		${p => p.theme.typography['Body.Small']()};
		padding: ${p => p.theme.spacing.small};
		border-radius: ${p => p.theme.borderRadius.small};
	`}
	${p => p.$size === 'medium' && css `
		${p => p.theme.typography['Body.Medium']()};
		padding: ${p => p.theme.spacing.regular};
		border-radius: ${p => p.theme.borderRadius.regular};
	`}
	${p => p.$size === 'large' && css `
		${p => p.theme.typography['Body.Large']()};
		padding: ${p => p.theme.spacing.large};
		border-radius: ${p => p.theme.borderRadius.large};
	`}

	${p => p.$noBorder && css `
		border-radius: 0 !important;
	`}

	min-width: 0;
	transition: all 100ms;

	background: ${p => p.theme.ui.surface};
	color: ${p => p.theme.ui.textOnSurfaceBackground};

	border: 1px solid ${p => p.theme.ui.borderInteractiveDefault};

	&:hover {
		border: 1px solid ${p => p.theme.ui.borderInteractiveHover};
	}

	&:focus {
		outline: none; /* Outline isn't implemented or respected very well across browsers */
		border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:focus-within {
		border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
	}

	&:disabled {
		cursor: not-allowed;
		background: ${p => p.theme.ui.surfaceFillMuted};

		border: 1px solid ${p => p.theme.ui.borderInteractiveDisabled};

		color: ${p => p.theme.ui.textMinor};
		-webkit-text-fill-color: ${p => p.theme.ui.textMinor};
		opacity: 1;
	}

	${p => (p.$error ? `
		background: ${p.theme.ui.surface};
		border: 1px solid ${p.theme.ui.borderInteractiveDanger};
		box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveDanger};

		&:hover {
			border: 1px solid ${p.theme.ui.borderInteractiveDanger};
		}

		&:focus-within {
			border: 1px solid ${p.theme.ui.borderInteractiveFocus};
			box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveFocus};
		}
	` : '')};

	width: -webkit-fill-available;
`;
Textarea.defaultProps = {
    $size: 'medium',
};
export default Textarea;
