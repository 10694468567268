import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';

const SmartPricingPrivacy = () => {
	const { isMobile } = useMobileDetect();
	const marginSizing = isMobile ? 'extraLarge' : '48px';

	return (
		<Margin $marginTop={marginSizing}>
			<Icon $size={'40px'} $color={'primaryFill'} icon={'ic_lock'} />

			<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
				{'We care about privacy'}
			</Typography>

			<Typography $type={'Body.Medium'} $marginTop={'regular'} $color={'textOnSurfaceBackgroundMuted'}>
				{'It’s always important to ask questions about your data and how we protect it.'}
			</Typography>

			<PrivacyWrapper>
				<Anchor target={'_blank'} href={'https://www.cuvva.com/support/cuvva-privacy-policy'}>
					<Typography $bold $type={'Body.Medium'} $color={'textHighlight'}>
						{'Read our privacy notice'}
					</Typography>
				</Anchor>
			</PrivacyWrapper>
		</Margin>
	);
};

const PrivacyWrapper = styled.div`
	margin-top: ${p => p.theme.spacing.regular};
	margin-bottom: ${p => p.theme.spacing.large};

	a {
		display: inline-block;
		text-decoration: none;
	}
`;

export default SmartPricingPrivacy;
