import styled, { css, keyframes } from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';

const slideIn = keyframes`
	0% { opacity: 0; transform: translateY(100%); }
	100% { opacity: 1; transform: translateY(0); }
`;

export const CookiePolicyWrapper = styled.div<{ $display: boolean }>`
	position: fixed;

	left: 0;
	bottom: 0;
	z-index: 10;

	overflow-y: hidden;

	width: 100vw;
	padding: ${p => p.theme.spacing.large};

	${media.greaterThan('tablet')`
		padding: 32px;
	`}

	background: ${p => p.theme.ui.surfaceFill};
	box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
	transform: translateY(100%);

	${p => p.$display && css`
		transform: translateY(0);
		animation: ${slideIn} 500ms ease-in-out;
	`};
`;

export const CookiePolicyContainer = styled.div`
	max-width: 1084px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: center;
		gap: ${p => p.theme.spacing.extraLarge}
	}

	a, a:hover, a:visited {
		text-decoration: none;
		color: ${p => p.theme.ui.textHighlight};
	}
`;

export const CookiePolicyHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const CookiePolicyButtonArea = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.large};
	margin-top: ${p => p.theme.spacing.large};

	& > * {
		flex: 1;
	}

	@media (min-width: 768px) {
		margin-top: 0;
	}
`;
