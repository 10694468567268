import { useEffect, useState } from 'react';
import MobileDetect from 'mobile-detect';
export const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isApplePhone, setIsApplePhone] = useState(false);
    const [isApplePad, setIsApplePad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const userAgent = typeof window !== 'undefined' && window?.navigator?.userAgent;
    useEffect(() => {
        if (!userAgent)
            return;
        const md = new MobileDetect(window.navigator.userAgent);
        setIsMobile(Boolean(md.mobile()));
        setIsAndroid(md.os() === 'AndroidOS');
        setIsApplePhone(md.os() === 'iOS');
        setIsApplePad(md.os() === 'iPadOS');
        setIsLoading(false);
    }, [userAgent]);
    return {
        isMobile,
        isAndroid,
        isApplePhone,
        isApplePad,
        isLoading,
    };
};
export default useMobileDetect;
