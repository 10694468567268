import Cher from '@cuvva/cher';
import { call, delay, getContext, put } from 'redux-saga/effects';

import { lookupByPostcode } from '..';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
import { ExpandedAddressResponse, FullAddress, GetAddressIO } from '~website/helpers/getaddressio-sdk';

export default createTakeLatestSagaSet(lookupByPostcode.actions.request, function* lookupByPostcodeSaga(action) {
	const getAddress: GetAddressIO = yield getContext('getAddress');
	const { postcode } = action.payload;
	const { ident } = action.meta;

	try {
		// SC: wondering: is this needed?
		yield delay(500);

		const res: ExpandedAddressResponse = yield call(
			[getAddress, getAddress.listAddressesByPostcode],
			postcode.trim(),
		);

		const addresses = res.addresses.sort(bestGuessSort)
			.map(a => ({
				lines: [
					a.formattedAddress[0],
					a.formattedAddress
						.slice(1, 3)
						.filter(Boolean)
						.join(', '),
				].filter(Boolean),
				postalCode: res.postcode,
				locality: a.townOrCity,
				countryCode: 'GB',
			}));

		yield put(lookupByPostcode.actions.success(ident, {
			...res,
			addresses,
		}));
	} catch (error) {
		yield put(lookupByPostcode.actions.failure(ident, Cher.coerce(error)));
	}
});

const bestGuessSort = (a: FullAddress, b: FullAddress) => {
	const building = compareTwoString(a.buildingNumber, b.buildingNumber);
	const subBuilding = compareTwoString(a.subBuildingNumber, b.subBuildingNumber);

	if (building !== 0)
		return building;

	if (subBuilding !== 0)
		return subBuilding;

	return 0;
};

const compareTwoString = (a: string, b: string) => {
	if (!a && !b)
		return 0;

	const aNumber = parseInt(a, 10);
	const bNumber = parseInt(b, 10);

	if (Number.isNaN(aNumber))
		return 1;

	if (Number.isNaN(bNumber))
		return -1;

	if (aNumber !== bNumber)
		return Math.sign(aNumber - bNumber);

	return 0;
};
