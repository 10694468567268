import React from 'react';
import { Input } from 'reactstrap';

import { BuilderPageDetail } from '../types';
import { FieldWrap } from './styled';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import Typography from '~lib/frontend/design-system/components/Typography';

interface PageBuilderProps {
	state: BuilderPageDetail;
	setState: (state: BuilderPageDetail) => void;
}

const pageBuilderOptions = [
	{ label: 'LTM', value: 'ltm' },
	{ label: 'STM', value: 'stm' },
	{ label: 'STM full quote flow', value: 'stm_quote' },
];

const PageBuilder: React.FCWithChildren<PageBuilderProps> = ({ state, setState }) => (
	<div>
		<FieldWrap>
			<Typography $type={'Label.Medium'}>{'Is a landing page?'}</Typography>
			<Input
				type={'checkbox'}
				checked={state.landing}
				onChange={e => setState({ ...state, landing: e.target.checked })}
				data-testid={'landing-input'}
			/>
		</FieldWrap>
		<FieldWrap>
			<Typography $type={'Label.Medium'}>{'Is new brand design?'}</Typography>
			<Input
				type={'checkbox'}
				checked={state.new_design}
				onChange={e => setState({ ...state, new_design: e.target.checked })}
				data-testid={'new-brand-design-input'}
			/>
		</FieldWrap>
		<FieldWrap>
			<Typography $type={'Label.Medium'}>{'Show header hero?'}</Typography>
			<Input
				type={'checkbox'}
				checked={state.show_header_hero}
				onChange={e => setState({ ...state, show_header_hero: e.target.checked })}
				data-testid={'show-hero-header-input'}
			/>
		</FieldWrap>

		<InputDecorator label={'Quote pre-selected product:'}>
			<SelectInput
				$size={'small'}
				value={state.quote_product}
				options={pageBuilderOptions}
				data-testid={'quote-product-dropdown'}
				placeholder={'Display product selection'}
				onChange={({ value }) => setState({ ...state, quote_product: value })}
			/>
		</InputDecorator>
	</div>
);

export default PageBuilder;
