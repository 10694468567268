import { combineReducers } from 'redux';
import { ContentActionTypes, initialContentState } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
import createAsyncReducer from '~lib/shared/redux/reducers/async-reducer';
export const documentBase = createAsyncReducer(ContentActionTypes.GET_DOCUMENT_BASE, initialContentState.documentBase);
export const pages = createAsyncReducer(ContentActionTypes.GET_PAGES, initialContentState.pages);
export const segments = createAsyncMapReducer(ContentActionTypes.GET_SEGMENT, initialContentState.segments);
export default combineReducers({
    documentBase,
    pages,
    segments,
});
