import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';

import { trackQuoteScreenView } from '~website/features/analytics/store/actions';

interface QuoteRouteProps {
	name: string;
	path: string;
	analyticScreenName: string;
	component: React.FCWithChildren;
	exact?: boolean;
}

const QuoteRoute: React.FCWithChildren<QuoteRouteProps> = props => {
	const { component: Component, name, path, analyticScreenName, exact = true } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(trackQuoteScreenView({ screenName: analyticScreenName }));
	}, [analyticScreenName, dispatch, path]);

	return (
		<Route
			sensitive
			path={path}
			exact={exact}
		>
			<Helmet>
				<title>{name}</title>
			</Helmet>

			<Component />
		</Route>
	);
};

export default QuoteRoute;
