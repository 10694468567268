import React from 'react';

import IncidentMiniCard from '../../molecules/IncidentMiniCard';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';

interface IncidentCardProps {
	index: number;
	frosted: boolean;
	onEdit: () => void;
}

const IncidentMiniCardCR: React.FCWithChildren<IncidentCardProps> = ({ index, frosted, onEdit }) => {
	const { value: date } = useIncidentItemInternalChangeRequest(index, 'date');
	const { value: category } = useIncidentItemInternalChangeRequest(index, 'category');

	return (
		<IncidentMiniCard
			category={category}
			date={date}
			onEdit={frosted ? void 0 : () => onEdit()}
		/>
	);
};

export default IncidentMiniCardCR;
