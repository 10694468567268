import { all } from 'redux-saga/effects';

import acceptTerms from './accept-terms';
import clientCode from './client-code';
import fetchPreferences from './fetch-preferences';
import fetchUser from './fetch-user';
import lookupAddressByPostcode from './lookup-address-by-postcode';
import ltmEstimate from './ltm-estimate';
import ltmQuote from './ltm-quote';
import reverseGeocoding from './reverse-geocoding';
import setFlow from './set-flow';
import setNCB from './set-ncb';
import setQuoteLocation from './set-quote-location';
import setStartDate from './set-start-date';
import setUseClassSaga from './set-use-class';
import setVehicle from './set-vehicle';
import stmEstimate from './stm-estimate';
import submit from './submit';

export default function* quoteSaga() {
	yield all([
		acceptTerms,
		clientCode,
		fetchPreferences,
		fetchUser,
		lookupAddressByPostcode,
		ltmEstimate,
		ltmQuote,
		reverseGeocoding,
		setFlow,
		setNCB,
		setQuoteLocation,
		setStartDate,
		setUseClassSaga,
		setVehicle,
		stmEstimate,
		submit,
	]);
}
