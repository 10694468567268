import { all } from 'redux-saga/effects';
import confirmChangeRequest from './confirm-change-request';
import createChangeRequest from './create-change-request';
import discardChangeRequest from './discard-change-request';
import getChangeRequest from './get-change-request';
import getChangeRequestInProgress from './get-change-request-in-progress';
export default function* changeRequestSaga() {
    yield all([
        confirmChangeRequest,
        createChangeRequest,
        discardChangeRequest,
        getChangeRequest,
        getChangeRequestInProgress,
    ]);
}
