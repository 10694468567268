import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Typography from '~lib/frontend/design-system/components/Typography';

const PolicyDetailsHeading = () => (
	<Wrapper>
		<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
			{'Policy details'}
		</Typography>
		<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
			{'These details can affect your pricing. '}
			<Anchor href={'mailto:support@cuvva.com'} className={'intercom-launcher'}>
				{'Let us know if they’ve changed.'}
			</Anchor>
		</Typography>
	</Wrapper>
);

export default PolicyDetailsHeading;

const Wrapper = styled.div`
	a, a:hover, a:visited {
		text-decoration: none;
		color: ${p => p.theme.ui.textHighlight};
	}
`;
