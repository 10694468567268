import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { uploadFile } from '../actions';
import { UploadActionTypes } from '../types';
export default function* watcherUploadFile() {
    yield takeEvery(UploadActionTypes.UPLOAD_FILE, workerUploadFile);
}
function* workerUploadFile({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId, purpose, body, contentType, contentLength, relatedIdentifiers } = payload;
    try {
        const preparePath = '2/2018-11-14/prepare_upload';
        const confirmPath = '2/2018-11-14/confirm_upload';
        const payload = {
            userId,
            purpose,
            contentType,
            contentLength,
            relatedIdentifiers,
        };
        const { fileId, uploadUrl } = yield call([client, client.rpc], 'service-upload', preparePath, payload);
        // dispatch a PUT request to S3
        yield call(fetch, uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': contentType,
            },
            body,
        });
        yield call([client, client.rpc], 'service-upload', confirmPath, { fileId });
        yield put(uploadFile.success(ident, { fileId }));
    }
    catch (error) {
        yield put(uploadFile.failure(ident, error));
    }
}
