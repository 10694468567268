import Cher from '@cuvva/cher';
import { createAction } from '@reduxjs/toolkit';
import { createAsyncActionTypes } from './types';
// The reason we support Cher or Unknown here is because the default type of a catch error is now unknown. This sounds
// annoying, but it good to make sure you're doing type checking and not making assumptions. Thankfully we pass all
// of these through Cher.coerce, which will find a way to make it a Cher error. We do that in this stage now, so we
// can get global assertion from errors! Win win
export function createAsyncAction(actionType) {
    const { request, success, failure, reset } = createAsyncActionTypes(actionType);
    return {
        request: createAction(request),
        success: createAction(success),
        failure: createAction(failure, (payload) => ({ payload: Cher.coerce(payload) })),
        reset: createAction(reset),
    };
}
export function createAsyncMapAction(actionType, transform) {
    const { request, success, failure, reset } = createAsyncActionTypes(actionType);
    return {
        request: createAction(request, (payload) => ({ payload, meta: { ident: transform(payload) } })),
        success: createAction(success, (ident, payload) => ({ payload: payload ?? null, meta: { ident } })),
        failure: createAction(failure, (ident, payload) => ({
            payload: Cher.coerce(payload),
            meta: { ident },
        })),
        reset: createAction(reset, (ident) => ({ payload: void 0, meta: { ident } })),
    };
}
export function createAsyncListAction(actionType) {
    const { request, success, failure, reset } = createAsyncActionTypes(actionType);
    return {
        request: createAction(request, (payload) => ({ payload })),
        success: createAction(success, (payload) => ({ payload })),
        failure: createAction(failure, (payload) => ({ payload: Cher.coerce(payload) })),
        reset: createAction(reset),
    };
}
