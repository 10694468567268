import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { getCarClub, updateCarClubName } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(updateCarClubName.request, function* updateCarClubNameSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.updateCarClubName, client, requestPayload);
        yield put(updateCarClubName.success(ident, response));
        yield put(getCarClub.request({ carClubId: payload.carClubId }));
    }
    catch (error) {
        yield put(updateCarClubName.failure(ident, error));
    }
});
