import styled, { css } from 'styled-components';
export const CardBodyWrapper = styled.div `
	overflow-x: auto;
	padding: ${p => p.theme.spacing.large} 0;

	${p => p.$noPadding && css `
		padding: 0 0;
		border-top: 0 !important;
	`};
`;
export const CardBodyContainer = styled.div `
	${p => p.theme.typography['Body.Small']()};
	padding: 0 ${p => p.theme.spacing.large};
	${p => p.$collapsible && `max-height: ${p.$open ? `${p.$height}px` : '0'};`};

	overflow: hidden;
	transition: all 0.3s ease-in-out;

	${p => p.$noPadding && css `
		padding: 0 0;
	`};

	padding-bottom: ${p => (p.$border && p.$open ? p.theme.spacing.large : '')};

	${p => p.$border && css `
			:first-child {
				padding-top: ${p => p.theme.spacing.large};
			}

			${CardBodyWrapper} {
				border: 1px solid ${p => p.theme.ui.borderSeparator};
				border-radius: ${p => p.theme.borderRadius.extraSmall};
				padding: ${p => p.theme.spacing.large};
			}
		`}

	${p => p.$separator && css `
		:first-child {
			padding-top: ${p => p.theme.spacing.large};
		}

		${CardBodyWrapper} {
			border-top: 1px solid ${p => p.theme.ui.borderSeparator};
		}
	`}
`;
