import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import has from 'lodash/has';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import { HookReturn, Validator } from '../types';
import useFriendlyError from './use-friendly-error';
import { Profile } from '~lib/platform/profile/types';

function useProfileInternalChangeRequest<
	T extends Profile, K extends keyof T
>(key: K, validator?: Validator<T[K]>): HookReturn<T[K]> {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const userId = useSelector(s => s.internal.auth.user.response);

	const onChange = useCallback((value: T[K]) => {
		dispatch(stepActions.setProfileValue({
			key: key as keyof Profile,
			value,
			userId,
			internalChangeRequestId,
		}));
	}, [dispatch, key, userId, internalChangeRequestId]);

	const value = useProfileInternalChangeRequestGetter(key as keyof Profile) as T[K];
	const validation = page?.fields?.[key as string]?.error;

	useEffect(() => {
		dispatch(pageActions.registerField({
			field: key as keyof Profile,
			pageId,
		}));

		return () => {
			dispatch(pageActions.deregisterField({
				field: key as keyof Profile,
				pageId,
			}));
		};
	}, [dispatch, key, pageId]);

	useEffect(() => {
		if (!validator)
			return;

		const error = validator(value);

		dispatch(stepActions.setFieldError({
			field: key as keyof Profile,
			pageId,
			error,
		}));
	}, [validator, value, pageId, dispatch, key]);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'profile', key as keyof Profile),
	};
}

function useProfileInternalChangeRequestGetter<
	T extends Profile, K extends keyof Profile
>(key: K): T[K] | undefined {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);
	const userId = useSelector(s => s.internal.auth.user.response)!;
	const profile = useSelector(s => s.platform.profile.profileById[userId]);
	const ltmProfile = useSelector(s => s.platform.ltm.getProfile[userId]);

	const cr = internalChangeRequest[internalChangeRequestId];
	const crProfile = cr?.profile?.[userId];

	if (key === 'residentialAddress' && has(crProfile, key))
		return crProfile!.residentialAddress as T[K];

	if (key === 'residentialAddress' && has(ltmProfile?.response, key))
		return ltmProfile!.response!.residentialAddress as T[K];

	if (has(crProfile, key))
		return crProfile![key] as T[K];

	if (has(profile?.response, key))
		return profile.response![key] as T[K];

	return void 0;
}

export default useProfileInternalChangeRequest;
