import { useEffect, useState } from 'react';
const useOnBlurError = (error) => {
    const [isBlur, setIsBlur] = useState(false);
    const [canDisplay, setCanDisplay] = useState(false);
    const onFocus = () => setIsBlur(false);
    const onBlur = () => setIsBlur(true);
    const hasError = (!Array.isArray(error) && Boolean(error)) || (Array.isArray(error) && error.length > 0);
    useEffect(() => {
        let timer;
        if (!canDisplay && isBlur && hasError)
            timer = setTimeout(() => setCanDisplay(true), 100);
        if (canDisplay && !isBlur && !hasError)
            setCanDisplay(false);
        return () => {
            if (timer !== void 0)
                clearTimeout(timer);
        };
    }, [canDisplay, isBlur, hasError]);
    return {
        error: canDisplay ? error : void 0,
        onFocus,
        onBlur,
    };
};
export default useOnBlurError;
