import React from 'react';
import styled from 'styled-components';

import { LocationDuration } from './LiveLocation';
import Icon from '~lib/frontend/design-system/components/Icon';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';

export const LoadingLocationData = () => (
	<LocationDuration>
		<SpinnerContainer>
			<div>
				<Spinner $size={'30px'} />
			</div>
		</SpinnerContainer>

		<Typography $type={'Body.Small'} $color={'textOnSurfaceBackground'} $marginTop={'large'} $bold>
			{'Loading location data...'}
		</Typography>
	</LocationDuration>
);

export const NoLocationAvailable = () => (
	<LocationDuration>
		<ExclamationIl>
			<Icon icon={'ic_exclamation'} $size={'24px'} $color={'blankFill'} />
		</ExclamationIl>
		<Typography $type={'Body.Small'} $color={'textOnSurfaceBackground'} $marginTop={'large'} $bold>
			{'No location data'}
		</Typography>
		<Typography $type={'Body.Small'} $color={'textMinor'} $marginTop={'small'} $bold>
			{'We’ll keep looking and add any details we find'}
		</Typography>
	</LocationDuration>
);

const SpinnerContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const ExclamationIl = styled.div`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	padding: ${p => p.theme.spacing.extraSmall};
	background: linear-gradient(180deg, #d52a4e -160.56%, #f9ba40 100%);
`;
