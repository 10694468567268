import React from 'react';
import Cher from '@cuvva/cher';

import { OwnersData } from '../../types';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Typography from '~lib/frontend/design-system/components/Typography';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { AsyncState } from '~lib/shared/redux/types/state';
import VehicleProfileTable from '~website/features/vehicle-profile/components/organisms/VehicleProfileTable';

interface VehicleDetailscyInfoProps {
	ownersData: OwnersData;
	vehicle: AsyncState<PublicVehicle, Cher>;
	open: boolean;
	closeModal: (val: boolean) => void;
}

const VehicleDetails: React.FCWithChildren<VehicleDetailscyInfoProps> = props => {
	const { ownersData, vehicle, open, closeModal } = props;

	return (
		<ComplexDialog.Dialog
			open={open}
			title={''}
			onClose={() => closeModal(false)}
		>
			<ComplexDialog.Content>
				<Typography
					tabIndex={0}
					$type={'Heading.Small'}
					$color={'textBlack'}
				>
					{'Vehicle details'}
				</Typography>
				<VehicleProfileTable
					vehicle={vehicle?.response}
					vehicleId={ownersData?.vehicleId}
				/>
			</ComplexDialog.Content>
		</ComplexDialog.Dialog>
	);
};

export default VehicleDetails;
