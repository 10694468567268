import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { deauthenticate } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(deauthenticate.request, function* workerDeauthenticate({ payload, meta }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { userId } = payload;
    try {
        yield call(api.deauthenticate, client, userId);
        yield put(deauthenticate.success(ident));
        // temporary, this can be removed after we migrate this to the new components set (and the error down there too)
        yield put(flashMessages.addSuccess('The user has successfully been signed out of every device ⚠️'));
    }
    catch (error) {
        yield put(deauthenticate.failure(ident, error));
        yield put(flashMessages.addError('Unable to sign user out', 'There was a problem deauthenticating this user. The outputted error is below:', Cher.coerce(error)));
    }
});
