import styled from 'styled-components';
import { getBadgeColors, getPadding } from '../helpers';
/* eslint-disable @typescript-eslint/indent */
const BadgeSC = styled.div `
	${p => p.$size === 'small' ? p.theme.typography['Label.XSmall']() : ''};
	${p => p.$size === 'medium' ? p.theme.typography['Label.Medium']() : ''};

	display: inline-flex;
	flex-direction: row;
	align-items: center;

	box-sizing: border-box;
	padding: ${p => getPadding(p.$size, Boolean(p.$icon))};
	min-width: ${p => p.$size === 'small' ? '20px' : '32px'};
	min-height: ${p => p.$size === 'small' ? '20px' : '32px'}; ;
	white-space: nowrap;

	border-radius: ${p => p.theme.borderRadius.extraLarge};
	color: ${p => getBadgeColors(p.$type, p.$muted, p.theme.ui).text};
	background-color: ${p => getBadgeColors(p.$type, p.$muted, p.theme.ui).background};

	--fill: ${p => getBadgeColors(p.$type, p.$muted, p.theme.ui).text};
`;
/* eslint-enable */
export default BadgeSC;
