import { initialize } from '@iterable/web-sdk/dist/authorization';
import { track } from '@iterable/web-sdk/dist/events';
export default class IterableSDK {
    _logout;
    constructor() {
        this._logout = () => { };
    }
    async initializeIterable(userId, apiKey, token) {
        const { setUserID, logout } = initialize(apiKey, () => Promise.resolve(token));
        await setUserID(userId);
        this._logout = logout;
    }
    logout() {
        this._logout();
    }
    async trackEvent(event) {
        await track(event);
    }
}
