import React, { useContext, useEffect, useState } from 'react';

import HorizontalRule from '../atoms/HorizontalRule';
import { CardRoundedHeading, QuoteCard } from '../quote-page/atoms/QuoteCard';
import { LtmQuoteContext } from '../quote-page/context';
import { QuotePricingContainer } from '../quote-page/molecules/QuoteCardWithHeading';
import { SplashContent, TrustSegmentWrap } from './SplashLTM';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import TrustSegment from '~website/components/atoms/TrustSegment';
import CheckList from '~website/components/CheckList';
import { monzoMoney } from '~website/helpers/monies';

const ComprehensiveCoverCard = () => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.quote.${key}`, fallback);
	const { quote, asyncStatus, requestQuote } = useContext(LtmQuoteContext);

	const [requested, setRequested] = useState(false);

	useEffect(() => {
		if (requested)
			return;

		if (asyncStatus === 'pending') {
			setRequested(true);
			requestQuote();
		}
	}, [requested, requestQuote, asyncStatus]);

	const pricing = quote?.response?.pricing;
	const totalPayable = pricing?.totalPayable;
	const priceLoading = typeof totalPayable !== 'number';

	return (
		<SplashContent>
			<CardRoundedHeading>
				<Typography $type={'Label.Small'} $color={'textOnFill'} $align={'center'}>
					{'Fully comprehensive cover'}
				</Typography>
			</CardRoundedHeading>

			<QuoteCard $noTopRadius>
				<QuotePricingContainer>
					<div>
						<Typography $type={'Heading.XLarge'} $color={'textOnSurfaceBackground'} $align={'center'}>
							{priceLoading && <SkeletonLoader width={'144px'} height={'63px'} />}
							{!priceLoading && monzoMoney(totalPayable)}
						</Typography>
						<Typography
							$align={'center'}
							$type={'Body.Small'}
							$color={priceLoading ? 'textOnSurfaceBackgroundMuted' : 'textOnSurfaceBackground'}
						>
							{priceLoading && 'refreshing your price...'}
							{!priceLoading && 'per month'}
						</Typography>
					</div>

					<HorizontalRule />
					<CheckList items={get<string[]>('card_checklist', [])} />
				</QuotePricingContainer>
			</QuoteCard>

			<TrustSegmentWrap>
				<TrustSegment trustProvider={'trustpilot'} />
			</TrustSegmentWrap>
		</SplashContent>
	);
};

export default ComprehensiveCoverCard;
