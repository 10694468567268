import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentLinkStrips, { contentAdapter } from '~website/components/atoms/TitleSegmentLinkStrips';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentLinkStrips {...contentAdapter(getter)} centered={true} />
);

export default {
	type: 'title_segment_link_strips',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			links: {
				type: 'object[]',
				fields: {
					title: { type: 'string' },
					url: { type: 'string' },
				},
			},
		},
		default: {
			title: 'Title',
			body: 'Body',
			links: [
				{
					title: 'One',
					url: 'Body1',
				},
				{
					title: 'Two',
					url: 'Body2',
				},
				{
					title: 'Three',
					url: 'Body3',
				},
			],
		},
	},
};
