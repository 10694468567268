import styled from 'styled-components';

const TrustpilotWrap = styled.div`
	width: 100%;
	max-width: 308px;
	margin-left: auto;
	margin-right: auto;

	> svg {
		width: 100%;
	}
`;

export default TrustpilotWrap;
