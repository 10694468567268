import styled from 'styled-components';

const ContentContainer = styled.div`
	max-width: 548px;
	margin: ${p => p.theme.spacing.large} auto;
	padding: 0 ${p => p.theme.spacing.large};

	@media (min-width: 1024px) {
		margin: ${p => p.theme.spacing.extraLarge} auto;
		padding: 0 ${p => p.theme.spacing.extraLarge};
	}
`;

export default ContentContainer;
