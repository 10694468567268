import { put } from 'redux-saga/effects';

import { trackEvent } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.trackOwnersEvent,
	function* ownersEventSaga(action) {
		const { eventName, cuvvaSource } = action.payload;

		yield put(trackEvent({
			eventName,
			eventPayload: {
				product: 'stm',
				platform: 'website',
				cuvva_source: cuvvaSource,
			},
		}));
	});
