import React from 'react';

import { DesignSystemProvider } from '~lib/frontend/design-system';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import Meta from '~website/components/Meta';
import VehicleProfileExperiment from '~website/features/referral/experiments/vehicle-profile-experiment';
import { useHideIntercomLauncher } from '~website/hooks/intercom';

const Referral: React.FunctionComponent = () => {
	useHideIntercomLauncher();

	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.referral.${key}`, fallback);

	return (
		<DesignSystemProvider themeKey={'light'} typographyStyle={'expressive'}>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />

			<VehicleProfileExperiment />
		</DesignSystemProvider>
	);
};

export default Referral;
