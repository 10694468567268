import { getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import * as actions from '../actions';
import { VehicleActionTypes } from '../types';
export default function* watcherOverrideVrm() {
    yield takeEvery(VehicleActionTypes.OVERRIDE_VRM, workerOverrideVrm);
}
function* workerOverrideVrm({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield api.overrideVrm(client, {
            vehicleId: payload.vehicleId,
            newVrm: payload.newVrm,
            comment: payload.comment,
            conversationUrl: payload.conversationUrl,
            editedBy: payload.editedBy,
            overruleChecks: payload.overruleChecks,
        });
        yield put(actions.overrideVrm.success(ident));
    }
    catch (error) {
        yield put(actions.overrideVrm.failure(ident, error));
    }
}
