import { range } from '~lib/frontend/helpers/numbers';
export const getOtherVehicleOptions = (opt) => {
    if (!opt)
        return [];
    return range(opt.min, opt.max, opt.step)
        .map((l, index, { length }) => ({
        label: l === 0 ? 'None' : `${String(l)}${index + 1 === length ? ' or more' : ''}`,
        value: String(l),
    }));
};
