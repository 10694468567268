import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { setPrimaryIdentifier, userById } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(setPrimaryIdentifier.request, function* workerSetPrimaryIdentifier({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        const response = yield call(api.setPrimaryIdentifier, client, payload);
        yield put(setPrimaryIdentifier.success(ident, response));
        yield put(userById.request({ userId: payload.userId, includeRemovedIdentifiers: true }));
    }
    catch (error) {
        yield put(setPrimaryIdentifier.failure(ident, error));
        yield put(flashMessages.addError('Error', 'Unable to set this identifiers as primary on user. The outputted error is below:', Cher.coerce(error)));
    }
});
