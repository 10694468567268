import styled from 'styled-components';

export const Background = styled.div`
	overflow-x: hidden;
	background: ${p => p.theme.ui.secondaryBackground};
	position: relative;
`;

export const BackgroundInner = styled.div`
	margin-top: -57px;
	padding-top: 57px;
	background: ${p => p.theme.ui.secondaryBackground};
	padding-bottom: ${p => p.theme.spacing.extraLarge};

	@media (min-width: 1024px) {
		margin-top: -73px;
		padding-top: 73px;
	}
`;
