import Logger from '@cuvva/logger';
let logger = null;
// eslint-disable-next-line no-console
const browserOutputStream = { write: console.log };
export function setupLogger(app) {
    logger = new Logger('info', browserOutputStream).setService(`app-${app}`);
}
export function getLogger() {
    if (logger === null)
        // eslint-disable-next-line no-console
        console.error('You need to setup the logger at the start of your apps init...');
    // This should only happen locally, so let the app freak out further down the line
    return logger;
}
