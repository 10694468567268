import React from 'react';

import useMarketingConsent from '../../hooks/use-marketing-consent';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import CheckBox from '~lib/frontend/design-system/components/input/molecules/CheckBox';

const MarketingConsent: React.FunctionComponent = () => {
	const { value, onChange } = useMarketingConsent();

	const checked = value === 'anything';

	return (
		<Margin $marginTop={'large'}>
			<CheckBox
				name={'marketingOption'}
				label={'Send me your news, offers and promotions'}
				checked={checked}
				onChange={() => onChange(checked ? 'essential' : 'anything')}
				data-event-onchange-name={'welcome_terms_accepted'}
			/>
		</Margin>
	);
};

export default MarketingConsent;
