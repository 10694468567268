import React from 'react';
import styled from 'styled-components';

import { FormTrackingEventProps } from '../../hooks/use-form-events-tracking';

const FormItem = styled.form`
	display: grid;
	grid-template-columns: 1fr;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const Form: React.FCWithChildren<FormTrackingEventProps> = ({ children, ...otherProps }) => (
	<FormItem onSubmit={e => e.preventDefault()} {...otherProps}>
		{children}
	</FormItem>
);

export default Form;
