/* eslint-disable require-yield */
import { stepActions } from '../actions';
import { setItem } from '~lib/shared/helpers/local-storage';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { quoteLocation } from '~website/constants/storage';

export default createTakeEverySagaSet(stepActions.setQuoteLocation, function* setQuoteLocationSaga(action) {
	if ([void 0, null].includes(action.payload))
		setItem(quoteLocation, null, 'sessionStorage');
	else
		setItem(quoteLocation, action.payload, 'sessionStorage');
});
