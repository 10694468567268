import URI from 'urijs';
const disabledIntercomMessageCopy = '__disable_intercom_test_message';
export function createAwareUrl(input, overrides) {
    const uri = setAwareness(input, overrides);
    return uri.resource();
}
export function createAwareSearch(input, overrides) {
    const uri = setAwareness(input, overrides);
    return uri.search();
}
export function getSafeRedirectUri(uri) {
    if (!uri)
        return '/';
    return `/${uri.replace(/^(?:(?:https?)?[:/\\.]+)/, '')}`;
}
function setAwareness(input, overrides) {
    let env;
    let build;
    let disableIntercomTestMessage;
    let flags;
    const uri = new URI(input);
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        env = overrides?.env ?? window.cuvva.client.env;
        build = overrides?.build ?? params.get('build');
        disableIntercomTestMessage = overrides?.disableIntercomTestMessage ?? params.get(disabledIntercomMessageCopy);
        flags = overrides?.flags ?? params.get('flags');
    }
    // Reset these to be sure
    uri.removeQuery('env');
    uri.removeQuery('build');
    uri.removeQuery(disabledIntercomMessageCopy);
    if (env && env !== 'prod')
        uri.addQuery('env', env);
    if (build)
        uri.addQuery('build', build);
    // This isn't a falsy check, as `''` is a valid value!
    if (disableIntercomTestMessage !== void 0 && disableIntercomTestMessage !== null)
        uri.addQuery(disabledIntercomMessageCopy, '');
    if (flags)
        uri.addQuery('flags', flags);
    return uri;
}
