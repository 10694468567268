/* eslint-disable sort-imports, simple-import-sort/imports */

import { History } from 'history';
import { combineReducers } from 'redux';
import { AsyncMapState, AsyncState } from '~lib/shared/redux/types/state';
import { RouterState, connectRouter } from 'connected-react-router';
import { all, fork } from 'redux-saga/effects';

import aboutReducers from './about/reducers';
import aboutSagas from './about/sagas';
import { AboutState } from './about/types';

import careersReducers from './careers/reducers';
import careersSagas from './careers/sagas';
import { CareersState } from './careers/types';

import authReducers from '~lib/platform/auth/store/reducers';
import authSagas from '~lib/platform/auth/store/sagas';
import { AuthState } from '~lib/platform/auth/store/types';

import changeRequestReducers from '~lib/platform/change-request/store/reducers';
import changeRequestSagas from '~lib/platform/change-request/store/sagas';
import { ChangeRequestState } from '~lib/platform/change-request/store/types';

import codeMappingReducers from '~lib/platform/code-mapping/store/reducers';
import codeMappingSagas from '~lib/platform/code-mapping/store/sagas';
import { CodeMappingState } from '~lib/platform/code-mapping/store/types';

import contentReducers from '~lib/platform/content/store/reducers';
import contentSagas from '~lib/platform/content/store/sagas';
import { ContentState } from '~lib/platform/content/store/types';

import doggosReducers from '~lib/platform/doggos/store/reducers';
import doggosSagas from '~lib/platform/doggos/store/sagas';
import { DoggosState } from '~lib/platform/doggos/store/types';

import { DrivingLicenseRegistrationState } from '~lib/platform/driving-license-registration/store/types';
import drivingLicenseRegistrationReducer from '~lib/platform/driving-license-registration/store/reducers';
import drivingLicenseRegistrationSaga from '~lib/platform/driving-license-registration/store/sagas';

import { DVLAVESState } from '~lib/platform/dvla-ves/store/types';
import DVLAVESReducer from '~lib/platform/dvla-ves/store/reducers';
import DVLAVESSaga from '~lib/platform/dvla-ves/store/sagas';

import getConfigByIdReducer from '~lib/platform/user-config/store/reducers/get-config-by-id';
import getConfigByIdSaga from '~lib/platform/user-config/store/sagas/get-config-by-id';
import { UserConfigPayload } from '~lib/platform/user-config/types';

import incidentReducers from '~lib/platform/incident/store/reducers';
import incidentSagas from '~lib/platform/incident/store/sagas';
import { IncidentState } from '~lib/platform/incident/store/types';

import listStaffPublicReducer from '~lib/platform/staff/store/reducers/list-staff-public';
import listStaffPublicSaga from '~lib/platform/staff/store/sagas/list-staff-public';
import { StaffMemberPublic } from '~lib/platform/staff/types';

import ltmReducer from '~lib/platform/ltm/store/reducers';
import { LtmState } from '~lib/platform/ltm/store/types';
import ltmSaga from '~lib/platform/ltm/store/sagas';

import motReducer from '~lib/platform/mot/store/reducers';
import { MOTState } from '~lib/platform/mot/store/types';
import motSaga from '~lib/platform/mot/store/sagas';

import hiringOfferReducers from '~lib/platform/hiring-offer/store/reducers';
import hiringOfferSaga from '~lib/platform/hiring-offer/store/sagas';
import { HiringOfferState } from '~lib/platform/hiring-offer/store/types';

import profileReducer from '~lib/platform/profile/store/reducers';
import { ProfileState } from '~lib/platform/profile/store/types';
import profileSaga from '~lib/platform/profile/store/sagas';

import { PromoState } from '~lib/platform/promo/store/types';
import promoReducers from '~lib/platform/promo/store/reducers';
import promoSagas from '~lib/platform/promo/store/sagas';

import quoteReducers from '../features/quote/store';
import quoteSagas from '../features/quote/store/sagas';
import { InternalQuoteState as QuoteState } from '../features/quote/store/types';

import referralReducers from '../features/referral/store/reducers';
import referralSagas from '../features/referral/store/sagas';
import { ReferralState } from '../features/referral/store/types';

import internalAnalyticsReducers from '../features/analytics/store/reducers';
import internalAnalyticsSagas from '../features/analytics/store/sagas';
import { AnalyticsState } from '../features/analytics/store/types';

import { SurveyState } from '~lib/platform/survey/store/types';
import surveyReducers from '~lib/platform/survey/store/reducers';
import surveySagas from '~lib/platform/survey/store/sagas';

import userReducers from './auth/reducers';
import userSagas from './auth/sagas';
import { UserState } from './auth/types';

import { VehicleState } from '~lib/platform/vehicle/store/types';
import vehicleReducers from '~lib/platform/vehicle/store/reducers';
import vehicleSagas from '~lib/platform/vehicle/store/sagas';

import { VehicleProfileState } from '~lib/platform/vehicle-profile/store/types';
import vehicleProfileReducer from '~lib/platform/vehicle-profile/store/reducers';
import vehicleProfileSaga from '~lib/platform/vehicle-profile/store/sagas';

import intercomSaga from '~lib/platform/intercom/store/sagas';
import motorCoverageSaga from '~lib/platform/motor-coverage/store/sagas';
import termsSaga from '~lib/platform/terms/store/sagas';

import { platformHooksSaga } from './platform-hooks';
import { SocialDrivingState } from '~lib/platform/social-driving/store/types';
import socialDrivingReducers from '~lib/platform/social-driving/store/reducers';
import socialDrivingSagas from '~lib/platform/social-driving/store/sagas';

import { MarketingConsentState } from '~lib/platform/marketing-consent/store/types';
import marketingConsentReducers from '~lib/platform/marketing-consent/store';
import marketingConsentSaga from '~lib/platform/marketing-consent/store/sagas';

import paymentReducers from '~lib/platform/payment/store/reducers';
import paymentSaga from '~lib/platform/payment/store/sagas';
import { PaymentState } from '~lib/platform/payment/store/types';

import { CookieConsentState } from '~website/features/cookie-policy/store/types';
import cookieConsentReducers from '~website/features/cookie-policy/store';
import cookieConsentSaga from '~website/features/cookie-policy/store/sagas';

import { PolicyDocumentsState } from '~lib/platform/policy-documents/store/types';
import policyDocumentsReducers from '~lib/platform/policy-documents/store/reducers';
import policyDocumentsSaga from '~lib/platform/policy-documents/store/sagas';

import { LtmCancellationState } from '~lib/platform/ltm-cancellation/store/types';
import ltmCancellationReducer from '~lib/platform/ltm-cancellation/store/reducers';
import ltmCancellationSaga from '~lib/platform/ltm-cancellation/store/sagas';

import { UploadState } from '~lib/platform/upload/store/types';
import uploadReducers from '~lib/platform/upload/store/reducers';
import uploadSaga from '~lib/platform/upload/store/sagas';

import { KycState } from '~lib/platform/kyc/store/types';
import kycReducer from '~lib/platform/kyc/store/reducers';
import kycSaga from '~lib/platform/kyc/store/sagas';

export interface ApplicationState {
	router?: RouterState;

	internal: {
		about: AboutState;
		analytics: AnalyticsState;
		auth: UserState;
		careers: CareersState;
		cookieConsent: CookieConsentState;
		quote: QuoteState;
		referral: ReferralState;
	};

	platform: {
		auth: AuthState;
		changeRequest: ChangeRequestState;
		codeMapping: CodeMappingState;
		content: ContentState;
		doggos: DoggosState;
		drivingLicenseRegistration: DrivingLicenseRegistrationState;
		dvlaves: DVLAVESState;
		hiringOffer: HiringOfferState;
		incidents: IncidentState;
		kyc: KycState;
		ltm: LtmState;
		ltmCancellation: LtmCancellationState;
		marketingConsent: MarketingConsentState;
		mot: MOTState;
		payment: PaymentState;
		policyDocuments: PolicyDocumentsState;
		profile: ProfileState;
		promo: PromoState;
		vehicle: VehicleState;
		vehicleProfile: VehicleProfileState;
		staff: {
			listStaffPublic: AsyncState<StaffMemberPublic[]>;
		};
		survey: SurveyState;
		socialDriving: SocialDrivingState;
		upload: UploadState;
		userConfig: {
			getConfigById: AsyncMapState<UserConfigPayload>;
		};
	};
}

export const createRootReducer = (history: History) => combineReducers<ApplicationState>({
	router: connectRouter(history),

	// @ts-ignore
	internal: combineReducers({
		about: aboutReducers,
		analytics: internalAnalyticsReducers,
		auth: userReducers,
		careers: careersReducers,
		cookieConsent: cookieConsentReducers,
		quote: quoteReducers,
		referral: referralReducers,
	}),

	platform: combineReducers({
		auth: authReducers,
		changeRequest: changeRequestReducers,
		codeMapping: codeMappingReducers,
		content: contentReducers,
		doggos: doggosReducers,
		drivingLicenseRegistration: drivingLicenseRegistrationReducer,
		dvlaves: DVLAVESReducer,
		hiringOffer: hiringOfferReducers,
		incidents: incidentReducers,
		kyc: kycReducer,
		ltm: ltmReducer,
		ltmCancellation: ltmCancellationReducer,
		marketingConsent: marketingConsentReducers,
		mot: motReducer,
		payment: paymentReducers,
		policyDocuments: policyDocumentsReducers,
		profile: profileReducer,
		promo: promoReducers,
		vehicle: vehicleReducers,
		vehicleProfile: vehicleProfileReducer,
		staff: combineReducers({
			listStaffPublic: listStaffPublicReducer,
		}),
		survey: surveyReducers,
		socialDriving: socialDrivingReducers,
		upload: uploadReducers,
		userConfig: combineReducers({
			getConfigById: getConfigByIdReducer,
		}),
	}),
});

export function* rootSaga() {
	yield all([
		fork(aboutSagas),
		fork(authSagas),
		fork(careersSagas),
		fork(changeRequestSagas),
		fork(codeMappingSagas),
		fork(contentSagas),
		fork(cookieConsentSaga),
		fork(doggosSagas),
		fork(drivingLicenseRegistrationSaga),
		fork(DVLAVESSaga),
		fork(getConfigByIdSaga),
		fork(hiringOfferSaga),
		fork(incidentSagas),
		fork(kycSaga),
		fork(intercomSaga),
		fork(internalAnalyticsSagas),
		fork(listStaffPublicSaga),
		fork(ltmSaga),
		fork(ltmCancellationSaga),
		fork(marketingConsentSaga),
		fork(motorCoverageSaga),
		fork(motSaga),
		fork(paymentSaga),
		fork(policyDocumentsSaga),
		fork(profileSaga),
		fork(promoSagas),
		fork(quoteSagas),
		fork(referralSagas),
		fork(socialDrivingSagas),
		fork(surveySagas),
		fork(termsSaga),
		fork(uploadSaga),
		fork(userSagas),
		fork(vehicleProfileSaga),
		fork(vehicleSagas),

		fork(platformHooksSaga),
	]);
}
