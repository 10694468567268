import React from 'react';
import styled from 'styled-components';

import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import { media } from '~lib/frontend/design-system/breakpoints';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { UIColors } from '~lib/frontend/design-system/types';
import { GetterType } from '~lib/frontend/types/content';

interface FeatureItem {
	icon: IconType;
	title: string;
	body: string;
}

interface TitleSegmentTrioFeaturesProps extends TitleSegmentProps {
	features: [FeatureItem, FeatureItem, FeatureItem];
	featureColor?: keyof UIColors;
	featureBackground?: keyof UIColors;
}

const TitleSegmentTrioFeatures: React.FCWithChildren<TitleSegmentTrioFeaturesProps> = props => {
	const {
		centered,
		features,
		featureColor,
		featureBackground,
		...titleSegmentProps
	} = props;

	return (
		<TitleSegment {...titleSegmentProps} centered={centered}>
			<Wrapper $centered={centered} $largeWidth={features.length > 3}>
				{features.map((s, i) => (
					<Feature key={`${i}-${s?.icon}-${s.title}`}>
						{s.icon && (
							<FeatureIcon
								$featureColor={featureColor}
								$featureBackground={featureBackground}
							>
								<Icon icon={s.icon} $size={'20px'} $color={featureColor} />
							</FeatureIcon>
						)}

						<Typography
							$bold
							$type={'Body.Large'}
							$color={'textOnSurfaceBackground'}
						>
							{s.title}
						</Typography>
						<Typography
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackgroundMuted'}
						>
							{s.body}
						</Typography>
					</Feature>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

const Wrapper = styled.div<{ $centered: boolean; $largeWidth: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	max-width:  ${p => p.$largeWidth ? '1180px' : '800px'};
	width: 100%;

	margin: 50px ${p => p.$centered ? 'auto' : '0'};

	${media.lessThan('tablet')`
		flex-direction: column;
	`}
`;

const Feature = styled.div`
	padding: ${p => p.theme.spacing.regular};
	flex: 0;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		flex: 1;
	}

	${p => media.lessThan('tablet')`
		margin-bottom: ${p.theme.spacing.large};

		&:last-of-type {
			margin-bottom: 0;
		}
	`}
`;

const FeatureIcon = styled.div<{ $featureBackground: keyof UIColors; $featureColor: keyof UIColors }>`
	background: ${p => p.theme.ui[p.$featureBackground]};
	border-radius: 100%;
	margin: 0 auto;
	width: 36px; height: 36px;
	border-radius: ${p => p.theme.borderRadius.small};
	margin-bottom: ${p => p.theme.spacing.large};
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 768px) {
		margin-bottom: ${p => p.theme.spacing.extraLarge};
	}
`;

TitleSegmentTrioFeatures.defaultProps = {
	featureBackground: 'primaryFillMuted',
	featureColor: 'primaryFill',
};

export const contentAdapter = (get: GetterType): TitleSegmentTrioFeaturesProps => {
	const title = get('title');
	const body = get('body');
	const features = get<[FeatureItem, FeatureItem, FeatureItem]>('features');
	const featureColor = get<keyof UIColors>('feature_color') || TitleSegmentTrioFeatures.defaultProps.featureColor;
	const featureBackground = get<keyof UIColors>('feature_background') || TitleSegmentTrioFeatures.defaultProps.featureBackground;
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		title,
		body,
		features,
		featureColor,
		featureBackground,
		...titleSegment,
	};
};

export default TitleSegmentTrioFeatures;
