import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import ksuid from '@cuvva/ksuid';

import CheckYourInboxContent from '../molecules/CheckYourInboxContent';
import DoSignIn from '../molecules/DoSignIn';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { sendAuthenticationCode } from '~lib/platform/auth/store/actions';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackEvent } from '~website/features/analytics/store/actions';
import SpinnerCover from '~website/features/quote/components/quote-page/molecules/SpinnerCover';
import useQuery from '~website/hooks/use-query';
import useUser from '~website/hooks/use-user';

const generateRequestId = () => ksuid.generate('request').toString();

const SignIn: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { namedUser, userLoading } = useUser();
	const [emailAddress, setEmailAddress] = useState('');
	const [requestId, setRequestId] = useState(generateRequestId);
	const location = useLocation<{ redirectUri?: string }>();
	const redirectUri = location.state?.redirectUri ?? createAwareUrl('/me/policies');

	const query = useQuery();
	const build = query.get('build');

	const authCode = useSelector(s => s.platform.auth.sendAuthenticationCode[requestId]);
	const error = authCode?.error;
	const fetching = authCode?.fetching;
	const done = authCode?.response === null;

	const doSignIn = () => {
		const requestId = generateRequestId();

		setRequestId(requestId);

		dispatch(sendAuthenticationCode.request({
			requestId,
			emailAddress,
			env: window.cuvva.client.env,
			build,
			redirectUri,
		}));

		dispatch(
			trackEvent({
				eventName: 'sign_in_email_actioned',
				eventPayload: {
					action: 'sign_in',
				},
			}),
		);
	};

	if (userLoading)
		return <SpinnerCover />;

	if (namedUser) {
		const currentPath = window.location.pathname;
		const redirectPath = currentPath.includes('/signin') ? '/me/policies' : currentPath;

		return <Redirect to={createAwareUrl(redirectPath)} />;
	}

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Sign into your account | Cuvva'}</title>
				<meta name={'robots'} content={'noindex, follow'} />
			</Helmet>

			<VibrantScreen>
				<VibrantScreenCard maxWidth={436}>
					{!done && (
						<DoSignIn
							doSignIn={doSignIn}
							error={error}
							fetching={fetching}
							emailAddress={emailAddress}
							setEmailAddress={setEmailAddress}
						/>
					)}

					{done && (
						<CheckYourInboxContent emailAddress={emailAddress} />
					)}
				</VibrantScreenCard>
			</VibrantScreen>

			<Footer />
		</React.Fragment>
	);
};

export default SignIn;
