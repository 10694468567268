import styled from 'styled-components';

export const CategoriesGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 24px;
	grid-row-gap: ${p => p.theme.spacing.extraLarge};
	max-width: 1280px;
	width: 100%;
	padding: 20px 0 0 0;
	margin: 0 auto;
	margin-bottom: 64px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: ${p => p.theme.spacing.extraLarge};
		margin-bottom: 96px;
	}
`;

export const CategoryColumn = styled.div`
	& > * + * {
		margin-top: ${p => p.theme.spacing.large};
	}
`;
