import { call, getContext, put } from 'redux-saga/effects';
import { attachPaymentMethod } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(attachPaymentMethod.request, function* attachPaymentMethodSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { userId, stpPaymentMethodId } = payload;
    const { ident } = meta;
    try {
        const response = yield call(api.attachPaymentMethod, client, userId, stpPaymentMethodId);
        yield put(attachPaymentMethod.success(ident, response));
    }
    catch (error) {
        yield put(attachPaymentMethod.failure(ident, error));
    }
});
