import styled from 'styled-components';

import { UIColors } from '~lib/frontend/design-system/types';

const HorizontalRule = styled.hr<{ $color?: keyof UIColors }>`
	border: 0;
	padding: 0;
	height: 0;
	margin: 0;
	border-top: 1px solid ${p => p.theme.ui[p.$color ?? 'borderSeparator']};
`;

export default HorizontalRule;
