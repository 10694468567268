import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Markdown from '~lib/frontend/atoms/Markdown';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { UIColors } from '~lib/frontend/design-system/types';

interface ChecklistProps {
	checklist: string[];
	isHero?: boolean;
	isVisionary?: boolean;
	newDesign?: boolean;
}

const CheckList: FunctionComponent<ChecklistProps> = ({ checklist, isHero, isVisionary, newDesign }) => {
	let color: keyof UIColors = 'textOnSurfaceBackgroundMuted';

	if (isVisionary) color = 'textOnFillMuted';
	else if (isHero) color = 'textOnSurfaceBackground';

	if (!checklist?.length) return null;

	return (
		<div>
			{checklist.map((item, i) => {
				if (!item) return null;

				return (
					<CheckListWrapper key={i}>
						<Icon
							icon={newDesign ? 'ic_check_circle' : 'ic_check_circle_fill'}
							$color={newDesign ? 'blankFill' : 'primaryFill'}
							$size={'20px'}
						/>
						<Typography $type={'Body.Large'} $color={color}>
							<Markdown>{item}</Markdown>
						</Typography>
					</CheckListWrapper>
				);
			})}
		</div>
	);
};

const CheckListWrapper = styled.div`
	display: flex;
	margin-top: ${p => p.theme.spacing.large};
	align-items: center;
	justify-content: flex-start;
	gap: ${p => p.theme.spacing.regular};
	text-align: left;
`;

export default CheckList;
