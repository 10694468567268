import React, { useContext, useState } from 'react';
import Typography from '../Typography';
import { BannerButtonWrapper, BannerContent, CloseBanner } from './atoms/BannerContent';
import BannerWrapper from './atoms/BannerWrapper';
import BannerContext from './context';
import { getBannerColor, getBodyColor, getIconColor, getTitleColor } from './helpers';
import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
export const Wrapper = ({ type = 'primary', icon, dismissible, muted, shadow, children, }) => {
    const [show, setShow] = useState(true);
    const value = { type, muted };
    return (React.createElement(BannerContext.Provider, { value: value },
        React.createElement(BannerWrapper, { "$color": getBannerColor(muted)[type], "$show": show, "$muted": muted, "$shadow": shadow },
            icon && (React.createElement(Icon, { icon: icon, "$color": getIconColor(type, muted), "$size": '20px', "$marginRight": 'regular' })),
            React.createElement(BannerContent, { "$type": 'Body.Small', "$color": getBodyColor(type, muted) }, children),
            dismissible && (React.createElement(CloseBanner, { onClick: () => setShow(false) },
                React.createElement(Icon, { icon: 'ic_cross_outline', "$color": getIconColor(type, muted), "$size": '10px', "$marginLeft": 'regular' }))))));
};
export const Title = ({ children }) => {
    const { type, muted } = useContext(BannerContext);
    return (React.createElement(Typography, { "$bold": true, "$type": 'Body.Medium', "$color": getTitleColor(type, muted) }, children));
};
export const Link = ({ children, href }) => {
    const { type, muted } = useContext(BannerContext);
    return (React.createElement(Typography, { "$type": 'Body.Small', as: Anchor, href: href, "$display": 'inline', "$color": getBodyColor(type, muted) }, children));
};
export const Button = props => {
    const { display = 'block', children, ...extraProps } = props;
    const { type, muted } = useContext(BannerContext);
    return (React.createElement(BannerButtonWrapper, { "$type": 'Label.Small', ...extraProps, as: 'button', "$color": getIconColor(type, muted), "$display": display }, children));
};
