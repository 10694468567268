import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { doNotOffer } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherDoNotOffer() {
    yield takeEvery(PromoActionTypes.DO_NOT_OFFER, workerDoNotOffer);
}
function* workerDoNotOffer({ payload, meta }) {
    const { ident } = meta;
    const { discountId } = payload;
    const client = yield getContext('api');
    try {
        yield call(api.doNotOffer, client, discountId);
        yield put(doNotOffer.success(ident));
    }
    catch (error) {
        yield put(doNotOffer.failure(ident, error));
    }
}
