import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useLocation } from 'react-router';
import ksuid from '@cuvva/ksuid';
import styled from 'styled-components';

import CannotQuote from '../components/CannotQuote';
import CheckYourInbox from '../components/CheckYourInbox';
import Declarations from '../components/Declarations';
import LegacyQuotePage from '../components/LegacyQuotePage';
import DesktopScreenTab from '../components/molecules/DesktopScreenTab';
import PricingHeaderBar from '../components/molecules/headers/PricingHeaderBar';
import QuoteRoute from '../components/molecules/QuoteRoute';
import { SplashLTM } from '../components/molecules/SplashLTM';
import DownloadApp from '../components/organisms/DownloadApp';
import LtmQuoteProvider from '../components/quote-page/context';
import LtmQuote from '../components/quote-page/LtmQuote';
import QuoteExpired from '../components/QuoteExpired';
import Verifications from '../components/Verifications';
import { PageCtx } from '../contexts/PageCtx';
import { flows, QuotePage as QuotePageInterface } from '../flows';
import { pageActions, quoteActions } from '../store/actions';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import { abiCodes } from '~lib/platform/code-mapping/store/actions';
import SplitUI, { ContentContainer } from '~website/components/SplitUI';

const Quote: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const splashImage = useContentAsset('website_core', 'quote/quote_column.svg');

	const purchaseFlow = useConfigFlag('web_subscription_purchase_flow');

	const pageId = useRef<string>(void 0);
	const qState = useSelector(s => s.internal.quote);
	const flow = qState.state.flow || qState.state.recentFlow;
	const pages: QuotePageInterface[] = flows?.[flow]?.pages;
	const product = flows?.[flow]?.product;
	const couldRedirect = qState.state?.prefetch?.preferences && qState.state?.prefetch?.user;

	// pre-fetch all the info we know about the user
	// and stuff it's sure we will need
	useEffect(() => {
		dispatch(quoteActions.fetchUser.request());
		dispatch(quoteActions.fetchPreferences.request());
		dispatch(abiCodes.request());
	}, [dispatch]);

	const pageError = qState.pages?.[pageId.current]?.error;

	// redirect to "cannot quote" if there's a page error
	useLayoutEffect(() => {
		if (pageError?.code === 'cannot_quote')
			history.push(createAwareUrl('/quote/cannot-quote'));
	}, [history, pageError]);

	const generatePage = useCallback(() => {
		const internalChangeRequestId = ksuid.generate('intcr').toString();

		pageId.current = ksuid.generate('page').toString();

		dispatch(pageActions.newChangeRequest({
			pageId: pageId.current,
			internalChangeRequestId,
			pagePath: location.pathname,
		}));
	}, [dispatch, location.pathname]);

	if (!pageId.current)
		generatePage();

	// Handle new CR automatically based off page
	useEffect(() => {
		generatePage();
	}, [dispatch, generatePage, location.pathname]);

	useEffect(() => {
		if (!qState.state?.prefetch?.preferences)
			return;

		if (qState.state?.flow)
			return;

		if (!qState.state?.recentFlow)
			return;

		const recentFlowPages: QuotePageInterface[] = flows?.[qState.state?.recentFlow]?.pages;
		const wasInFlow = recentFlowPages.map(p => p.path).includes(location.pathname);

		if (wasInFlow)
			dispatch(quoteActions.setFlow(qState.state?.recentFlow));
	}, [
		qState.state?.flow,
		qState.state?.prefetch?.preferences,
		qState.state?.recentFlow,
		location.pathname,
		dispatch,
	]);

	// todo(sc): find a better way for this.
	useEffect(() => {
		if (!product)
			return;

		dispatch(quoteActions.setProduct(product));
	}, [dispatch, product]);

	const newLtmQuotePage = [void 0, 'ltm'].includes(product);
	const splashContent = product === 'ltm' ? <SplashLTM /> : void 0;

	return (
		<PageCtx.Provider value={pageId.current}>
			<LtmQuoteProvider>
				<Switch>
					<QuoteRoute
						name={'Download app'}
						path={'/quote/download'}
						component={DownloadApp}
						analyticScreenName={'download'}
					/>

					<QuoteRoute
						name={'Cannot quote'}
						path={'/quote/cannot-quote'}
						component={CannotQuote}
						analyticScreenName={'cannot-quote'}
					/>

					<QuoteRoute
						name={'Quote expired'}
						path={'/quote/expired'}
						component={QuoteExpired}
						analyticScreenName={'quote_expired_viewed'}
					/>

					{newLtmQuotePage && (
						<QuoteRoute
							name={'Your quote'}
							path={'/quote/your-quote'}
							component={LtmQuote}
							analyticScreenName={'ltm-quote'}
						/>
					)}

					<QuoteRoute
						name={'Your quote'}
						path={'/quote/your-quote'}
						component={LegacyQuotePage}
						analyticScreenName={`${product}-estimate`}
					/>

					<QuoteRoute
						name={'Your estimate'}
						path={'/quote/your-estimate'}
						component={LegacyQuotePage}
						analyticScreenName={`${product}-estimate`}
					/>

					{purchaseFlow && (
						<QuoteRoute
							name={'Declarations'}
							path={'/quote/declarations'}
							component={Declarations}
							analyticScreenName={`${product}-declarations`}
						/>
					)}
					{purchaseFlow && (
						<QuoteRoute
							exact={false}
							name={'Verifications'}
							path={'/quote/verifications'}
							component={Verifications}
							analyticScreenName={'verifications'}
						/>
					)}

					<SplitUI splashImage={splashImage} splashContent={splashContent}>
						<PricingHeaderBar />
						<DesktopScreenTab />

						<StyledContentContainer>
							<Switch>
								{pages?.map(p => (
									<QuoteRoute
										key={`${p.path}:${p.name}`}
										name={p.name}
										path={p.path}
										component={p.component}
										analyticScreenName={p.analyticScreenName}
									/>
								))}

								<QuoteRoute
									name={'Check your inbox'}
									path={'/quote/check-your-inbox'}
									component={CheckYourInbox}
									analyticScreenName={'magic-link-sent'}
								/>

								{couldRedirect && (
									<Redirect from={'/quote/*'} to={createAwareUrl('/vehicles')} />
								)}
							</Switch>
						</StyledContentContainer>

					</SplitUI>
				</Switch>
			</LtmQuoteProvider>
		</PageCtx.Provider>
	);
};

export const StyledContentContainer = styled(ContentContainer)`
	margin: ${p => p.theme.spacing.extraLarge} auto;
`;

export default Quote;
