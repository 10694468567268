import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { getVehicleProfile } from '../actions';
import { VehicleProfileActionTypes } from '../types';
export default function* watcherGetVehicleProfile() {
    yield takeEvery(VehicleProfileActionTypes.GET_VEHICLE_PROFILE, workerGetVehicleProfile);
}
function* workerGetVehicleProfile({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.getVehicleProfile, client, payload);
        yield put(getVehicleProfile.success(ident, response));
    }
    catch (error) {
        yield put(getVehicleProfile.failure(ident, error));
    }
}
