import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import Container from '../atoms/Container';
import EmbeddedVideo, { EmbeddedVideoProps, Wrapper as EmbeddedVideoWrapper } from '../atoms/EmbeddedVideo';
import { TwoColumnsImage, TwoColumnsImageTag } from '../atoms/layout/TwoColumns';
import TwoColumnsFixedLayout from '../atoms/layout/TwoColumnsFixed';
import TitleSegment, { TitleSegmentProps } from '../atoms/TitleSegment';
import HeaderHeroWrap from './HeaderHeroWrap';
import SubHeaderHero, { SubHeaderHeroProps } from './SubHeaderHero';
import { media } from '~lib/frontend/design-system/breakpoints';
import {
	EditableTypographyContext,
	useCreateEditableTypographyContextValue,
} from '~lib/frontend/design-system/context/editable-typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import { Theme } from '~lib/frontend/design-system/types';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset } from '~lib/frontend/types/content';

type GradientOption = 'leading' | 'center' | 'trailing' | 'none';

interface OldHeaderHeroProps {
	theme?: Theme;
	titleSegment: TitleSegmentProps;
	mainImage: DesignSystemAsset;
	embeddedVideo?: EmbeddedVideoProps;
	mobileImage?: DesignSystemAsset;
	subHero?: SubHeaderHeroProps;
	gradient?: GradientOption;
}

const OldHeaderHero: FC<OldHeaderHeroProps> = ({
	theme,
	titleSegment,
	mainImage,
	embeddedVideo,
	mobileImage,
	gradient,
	...props
}) => {
	const asBackground = titleSegment.centered;

	const main = useDesignSystemAsset(mainImage);
	const mobile = useDesignSystemAsset(mobileImage);

	const video = embeddedVideo?.thumbnail && <EmbeddedVideo {...embeddedVideo} />;

	const alternateBackground = theme === 'dark';
	const titleSegmentEditableTypographyContext = useCreateEditableTypographyContextValue('title_segment');

	return (
		<Fragment>
			<HeaderHeroWrap
				$themeKey={theme}
				$gradient={alternateBackground ? gradient : 'none'}
				$backgroundImage={asBackground ? main : void 0}
				$hasSubHero={Boolean(props.subHero)}
			>
				<InnerContent>
					{!video && asBackground && (
						<PaddedInner>
							<EditableTypographyContext.Provider value={titleSegmentEditableTypographyContext}>
								<TitleSegment {...titleSegment} isHero />
							</EditableTypographyContext.Provider>
						</PaddedInner>
					)}
					{!asBackground && (
						<PaddedInner>
							<TwoColumnsFixedLayout>
								<EditableTypographyContext.Provider value={titleSegmentEditableTypographyContext}>
									<TitleSegment {...titleSegment} isHero />
								</EditableTypographyContext.Provider>
								{video ? video : <TwoColumnsImage asset={mainImage} />}
							</TwoColumnsFixedLayout>
						</PaddedInner>
					)}
					{video ? video : mobile && <BackgroundMobileImage src={mobile} />}
				</InnerContent>
			</HeaderHeroWrap>

			{props.subHero && <SubHeaderHero {...props.subHero} theme={theme} />}
		</Fragment>
	);
};

const PaddedInner = styled.div`
	padding-left: 20px;
	padding-right: 20px;

	& > * {
		margin: 0 auto;
	}

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

const InnerContent = styled(Container)`
	padding-left: 0;
	padding-right: 0;
	width: 100%;
	overflow: hidden;

	/* as the content of the hero always goes under the header, we need to roughly account for the navigation height */
	@media screen and (min-width: 1024px) {
		padding-top: 60px;
	}

	${media.lessThan('tablet')`
		padding-top: 180px;
	`}

	${EmbeddedVideoWrapper} {
		${media.greaterThan('tablet')`
			display: none;
		`}
	}

	${PaddedInner} ${TwoColumnsImageTag}, ${PaddedInner} ${EmbeddedVideoWrapper} {
		display: none;

		${media.greaterThan('tablet')`
			display: block;
		`}
	}
`;

const BackgroundMobileImage = styled.img`
	width: 100%;

	/* I don't know why this is necessary - I couldn't find the root of the issue
	but seems to be consistent between browsers so it can't be rendering issue */
	margin-bottom: -4px;

	${media.greaterThan('tablet')`
		display: none;
	`}
`;

export default OldHeaderHero;
