import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~lib/frontend/design-system/components/Icon';
const AccordionContainer = styled.div `
	position: relative;
	max-width: 500px;
`;
const AccordionInner = styled.div `
	position: relative;
	width: 100%;
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	border: none;
	border-radius: 4px;
`;
const AccordionItem = styled.div `
	&:not(:first-child) {
		${p => p.active ? 'border-top: none;' : `border-top: 1px solid ${p.theme.ui.borderSeparator};`}
	};

	${p => p.active && `
		background: ${p.theme.ui.surfaceFillMuted};
		border-radius: ${p.theme.borderRadius.large};
		& + div {
			border-top: none !important;
		}
	`};
`;
const AccordionTitle = styled.h3 `
	margin: 0;
	padding: 24px 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	& > *:last-child {
		${p => p.active ? 'transform: rotate(90deg);' : 'none'}
	}
`;
const AccordionBody = styled.div `
	display: block;
	position: relative;
	padding: 0;
	margin: 0;
	height: 0;
	overflow: hidden;
	transition: height 0.3s;

	${({ active, bodyHeight }) => active && css `
			height: ${bodyHeight}px;
		`}
`;
const AccordionContent = styled.div `
	margin: 0;
	padding: 0 1rem 1rem;
	padding: 16px;
	height: auto;
`;
const ProxyLegacyItem = props => {
    const { index, content, setCurrentAccordion, currentAccordion, setBodyHeight, bodyHeight, title, } = props;
    const ref = useRef(null);
    return (React.createElement(AccordionItem, { active: currentAccordion === index, key: `${title}:${index}` },
        React.createElement(AccordionTitle, { active: currentAccordion === index, onClick: () => {
                setCurrentAccordion(index);
                setBodyHeight(ref.current.clientHeight);
            } },
            title,
            React.createElement(Icon, { icon: 'ic_chevron_right', "$size": '16px', "$color": 'primaryFill', "$marginLeft": 'small' })),
        React.createElement(AccordionBody, { active: currentAccordion === index, bodyHeight: bodyHeight },
            React.createElement(AccordionContent, { ref: ref }, content))));
};
const AccordionItems = ({ accordionContent, currentAccordion, setCurrentAccordion, setBodyHeight, bodyHeight, }) => (React.createElement(React.Fragment, null, accordionContent.map(({ title, content }, i) => (React.createElement(ProxyLegacyItem, { title: title, content: content, currentAccordion: currentAccordion, index: i, setBodyHeight: setBodyHeight, bodyHeight: bodyHeight, setCurrentAccordion: setCurrentAccordion })))));
const Accordion = ({ items }) => {
    const [currentAccordion, setCurrentAccordion] = useState(void 0);
    const [bodyHeight, setBodyHeight] = useState(0);
    const showAccordionItemContent = (val) => {
        setCurrentAccordion(currentAccordion === val ? void 0 : val);
    };
    return (React.createElement(AccordionContainer, null,
        React.createElement(AccordionInner, null,
            React.createElement(AccordionItems, { accordionContent: items, currentAccordion: currentAccordion, setCurrentAccordion: showAccordionItemContent, setBodyHeight: setBodyHeight, bodyHeight: bodyHeight }))));
};
export default Accordion;
