import { call, getContext, put } from 'redux-saga/effects';
import { updateHiringOfferStatus } from '../actions';
import * as api from '../api';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(updateHiringOfferStatus.request, function* updateHiringOfferStatusSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        yield call(api.updateHiringOfferStatus, client, payload);
        yield put(updateHiringOfferStatus.success(ident));
    }
    catch (error) {
        yield put(updateHiringOfferStatus.failure(ident, error));
    }
});
