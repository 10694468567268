import { combineReducers } from 'redux';

import user from './register-user';

export {
	user,
};

export default combineReducers({
	user,
});
