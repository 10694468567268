import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import CompareWithFriends from './CompareWithFriends';
import DrivingHighlights from './DrivingHighlights';
import LenderMilestones from './LenderMilestones';
import TripHistory from './TripHistory';
import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackOwnersEvent } from '~website/features/analytics/store/actions';

type OpenedModal = 'insights' | 'milestones' | 'history' | 'friends'

const futureInitiatives = [
	{ icon: 'ic_car' as IconType, key: 'insights' as OpenedModal, title: 'Driving insights', eventName: vehicleProfileEvents.ownersHub.drivingHighlightsCta },
	{ icon: 'ic_check' as IconType, key: 'milestones' as OpenedModal, title: 'Lender milestones', eventName: vehicleProfileEvents.ownersHub.lenderMilestonesCta },
	{ icon: 'ic_geo' as IconType, key: 'history' as OpenedModal, title: 'Individual trip history', eventName: vehicleProfileEvents.ownersHub.tripHistoryCta },
	{ icon: 'ic_gauge' as IconType, key: 'friends' as OpenedModal, title: 'Compare with friends', eventName: vehicleProfileEvents.ownersHub.compareWithFriendsCta },
];

const ComingSoon: React.FunctionComponent = () => {
	const [openedModal, setOpenedModal] = useState<OpenedModal>(void 0);
	const dispatch = useDispatch();

	const onClickInitiative = (eventName: string, key: OpenedModal) => {
		dispatch(trackOwnersEvent({
			eventName,
		}));

		setOpenedModal(key);
	};

	const closeOpenedModal = () => {
		setOpenedModal(void 0);
	};

	return (
		<React.Fragment>
			<ContentTitle>
				<Typography
					$type={'Label.Large'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginTop={'extraLarge'}
					$marginBottom={'regular'}
				>
					{'Coming soon!'}
				</Typography>
			</ContentTitle>
			<ComingSoonSection>
				{futureInitiatives.map(card => (
					<ComingSoonCard
						key={card.icon}
						onClick={() => onClickInitiative(card.eventName, card.key)}
					>
						<div>
							<Icon icon={card.icon} $size={'16px'} $color={'primaryFill'} />
							<Typography
								$type={'Body.Medium'}
								$bold
								$color={'textOnSurfaceBackground'}
							>
								{card.title}
							</Typography>
						</div>
					</ComingSoonCard>
				))}
			</ComingSoonSection>
			<DrivingHighlights
				open={openedModal === 'insights'}
				closeModal={closeOpenedModal}
			/>
			<LenderMilestones
				open={openedModal === 'milestones'}
				closeModal={closeOpenedModal}
			/>
			<TripHistory
				open={openedModal === 'history'}
				closeModal={closeOpenedModal}
			/>
			<CompareWithFriends
				open={openedModal === 'friends'}
				closeModal={closeOpenedModal}
			/>
		</React.Fragment>
	);
};

const ContentTitle = styled.div`
	margin-left: 16px;
	width: 343px;
`;

const ComingSoonSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 440px;
`;

const ComingSoonCard = styled.div`
	width: 160px;
	background: ${p => p.theme.ui.surface};
	height: 134px;
	padding: ${p => p.theme.spacing.large};
	border-radius: ${p => p.theme.borderRadius.regular};
	flex-grow: 0;
	margin: 0px ${p => p.theme.spacing.extraSmall} ${p => p.theme.spacing.small} ${p => p.theme.spacing.extraSmall};

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	> div {
		> * {
			margin: auto;
			text-align: center;
		}
	}
`;

export default ComingSoon;
