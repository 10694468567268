import React from 'react';

const Facebook: React.FunctionComponent = () => (
	<svg width={'20px'} height={'20px'} viewBox={'0 0 20 20'}>
		<circle
			cx={'10'}
			cy={'12'}
			r={'9'}
			fill={'#496195'}
			fillOpacity={'0'}
		></circle>
		<g id={'Assets'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g
				transform={'translate(-566.000000, -206.000000)'}
				fill={'#3B5998'}
			>
				<g id={'Footer-padding'}>
					<g id={'Group-8'} transform={'translate(52.000000, 177.000000)'}>
						<g id={'Follow-us'} transform={'translate(514.000000, 0.000000)'}>
							<g id={'Group-11'} transform={'translate(0.000000, 29.000000)'}>
								<g id={'Group-5'}>
									<g id={'Icons-/-Social-/-Facebook'}>
										<g id={'Facebook'}>
											<path d={'M18.5932916,-1.77635684e-13 L1.08561995,-1.77635684e-13 C0.486233107,-1.77635684e-13 -1.95399252e-14,0.484203629 -1.95399252e-14,1.08108871 L-1.95399252e-14,18.5156855 C-1.95399252e-14,19.1133871 0.486233107,19.5967742 1.08561995,19.5967742 L10.5101787,19.5967742 L10.5101787,12.0079234 L7.94454059,12.0079234 L7.94454059,9.05126008 L10.5101787,9.05126008 L10.5101787,6.86703629 C10.5101787,4.33660282 12.0631728,2.95992944 14.3303474,2.95992944 C15.4167873,2.95992944 16.3507156,3.03913306 16.6229406,3.07506048 L16.6229406,5.720625 L15.0478077,5.720625 C13.8178757,5.720625 13.578449,6.30934476 13.578449,7.16670363 L13.578449,9.05370968 L16.5171664,9.05370968 L16.1358875,12.0177218 L13.578449,12.0177218 L13.578449,19.5967742 L18.5924717,19.5967742 C19.1934984,19.5967742 19.6789116,19.1133871 19.6789116,18.5156855 L19.6789116,1.08108871 C19.6789116,0.484203629 19.1934984,-1.77635684e-13 18.5932916,-1.77635684e-13'}></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Facebook;
