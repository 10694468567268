import CuvvaClient from '@cuvva/cuvva-client';

import { Careers } from './types';

export const listCareers = (client: CuvvaClient) =>
	client.rpcNoAuth<Careers>(
		'service-career',
		'1/2019-05-17/list_careers',
		null,
	);
