export const coloursMap = {
    neutral: {
        backgroundMuted: 'surfaceFillMuted',
        textMuted: 'textOnSurfaceBackgroundMuted',
        backgroundSolid: 'neutralFillMuted',
        textSolid: 'textOnFill',
    },
    primary: {
        backgroundMuted: 'primaryFillMuted',
        textMuted: 'textHighlight',
        backgroundSolid: 'primaryFill',
        textSolid: 'textOnFill',
    },
    success: {
        backgroundMuted: 'successFillMuted',
        textMuted: 'textSuccess',
        backgroundSolid: 'successFill',
        textSolid: 'textOnFill',
    },
    danger: {
        backgroundMuted: 'dangerFillMuted',
        textMuted: 'textDanger',
        backgroundSolid: 'dangerFill',
        textSolid: 'textOnFill',
    },
    warning: {
        backgroundMuted: 'warningFillMuted',
        textMuted: 'textWarning',
        backgroundSolid: 'warningFill',
        textSolid: 'textOnFill',
    },
    info: {
        backgroundMuted: 'infoFillMuted',
        textMuted: 'textInfo',
        backgroundSolid: 'infoFill',
        textSolid: 'textOnFill',
    },
};
