import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listStaffPublic } from '../actions';
import { StaffActionTypes } from '../types';
export default function* watcherListStaffPublic() {
    yield takeEvery(StaffActionTypes.LIST_STAFF_PUBLIC, workerListStaffPublic);
}
function* workerListStaffPublic() {
    const client = yield getContext('api');
    try {
        const response = yield call([client, client.rpcNoAuth], 'service-staff', '1/2019-05-22/list_staff_public', null);
        yield put(listStaffPublic.success(response));
    }
    catch (error) {
        yield put(listStaffPublic.failure(error));
    }
}
