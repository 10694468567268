export const licenseByUserId = (api, userId, revision) => api.rpc('service-driving-license-registration', '1/latest/get_license', { userId, revision });
export const updateLicenseHint = (api, payload) => api.rpc('service-driving-license-registration', '1/2019-02-13/update_license_hint', payload);
export const declareLicense = (api, userId, dln, issuingCountryCode) => api.rpc('service-driving-license-registration', '1/2018-05-01/declare_license', { userId, dln, issuingCountryCode });
export const declareTestPass = (api, userId) => api.rpc('service-driving-license-registration', '1/2018-05-01/declare_test_pass', { userId });
export const removeLicense = (api, userId) => api.rpc('service-driving-license-registration', '1/2018-05-01/remove_license', { userId });
export const removeTestPass = (api, userId) => api.rpc('service-driving-license-registration', '1/2018-05-01/remove_test_pass', { userId });
export const updateLicense = (api, userId, fields) => api.rpc('service-driving-license-registration', '1/2018-05-01/update_license', { userId, ...fields });
export const listMockedLicenses = (api) => api.rpc('service-driving-license-registration', '1/2019-02-13/list_mocked_licenses', void 0);
export const search = (api, request) => api.rpc('service-driving-license-registration', '1/latest/search', request);
export const lookupLicense = (api, userId) => api.rpc('service-driving-license-registration', '1/2018-05-01/lookup_license', { userId });
