import { createAction } from '@reduxjs/toolkit';

import {
	AccountsEventPayload,
	AccountsScreenViewEventPayload,
	AnalyticsTrackingDebugEvent,
	CarCheckerEventPayload,
	OwnersEventPayload,
	OwnersScreenViewEventPayload,
	PaymentErrorEventPayload,
	PayOnWebEventPayload,
	QuoteEventPayload,
	QuoteScreenViewEventPayload,
	ScreenViewEventPayload,
	TrackEventPayload,
} from '../types';
import { AnalyticsEventTypes } from './types';
import { getCurrentTrackingParams } from '~website/helpers/source-tracking';

export const trackEvent = createAction<Omit<TrackEventPayload, 'eventType'>>(
	AnalyticsEventTypes.TRACK_EVENT,
);

export const trackScreenView = createAction<ScreenViewEventPayload>(
	AnalyticsEventTypes.SCREEN_VIEW,
);

export const sourceTracking = createAction(
	AnalyticsEventTypes.SOURCE_TRACKING,
	() => ({
		payload: getCurrentTrackingParams(),
	}),
);

export const trackQuoteScreenView = createAction<QuoteScreenViewEventPayload>(
	AnalyticsEventTypes.TRACK_QUOTE_SCREEN_VIEW,
);

export const trackQuickQuoteScreenView = createAction<QuoteScreenViewEventPayload>(
	AnalyticsEventTypes.TRACK_QUICK_QUOTE_SCREEN_VIEW,
);

export const trackQuickQuoteEvent = createAction<QuoteEventPayload>(
	AnalyticsEventTypes.TRACK_QUICK_QUOTE_EVENT,
);

export const trackCarCheckerEvent = createAction<CarCheckerEventPayload>(
	AnalyticsEventTypes.TRACK_QUICK_QUOTE_EVENT,
);

export const trackQuoteEvent = createAction<QuoteEventPayload>(
	AnalyticsEventTypes.TRACK_QUOTE_EVENT,
);

export const analyticsTrackingDebug = createAction<AnalyticsTrackingDebugEvent>(
	AnalyticsEventTypes.ANALYTICS_TRACKING_DEBUG,
);

export const trackOwnersScreenView = createAction<OwnersScreenViewEventPayload>(
	AnalyticsEventTypes.TRACK_OWNERS_SCREEN_VIEW,
);

export const trackOwnersEvent = createAction<OwnersEventPayload>(
	AnalyticsEventTypes.TRACK_OWNERS_EVENT,
);

export const trackAccountsScreenView = createAction<AccountsScreenViewEventPayload>(
	AnalyticsEventTypes.TRACK_ACCOUNTS_SCREEN_VIEW,
);

export const trackAccountsEvent = createAction<AccountsEventPayload>(
	AnalyticsEventTypes.TRACK_ACCOUNTS_EVENT,
);

export const referralEmailTriggerEvent = createAction(AnalyticsEventTypes.REFERRAL_EMAIL_TRIGGER_EVENT);

export const trackPayOnWebScreenView = createAction<PayOnWebEventPayload>(
	AnalyticsEventTypes.TRACK_PAY_ON_WEB_SCREEN_VIEW,
);

export const trackPaymentErrorEvent = createAction<PaymentErrorEventPayload>(
	AnalyticsEventTypes.TRACK_ACCOUNTS_EVENT,
);
