import { getContext, put } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import { AppsFlyerSDK, CustomEvent } from '~website/helpers/appsflyer-sdk';

function* handleAppsflyer(payload: Omit<CustomEvent, 'eventType'>) {
	const appsFlyer: AppsFlyerSDK = yield getContext('appsFlyer');

	appsFlyer.trackEvent(payload);

	yield put(analyticsTrackingDebug({
		destination: 'appsflyer',
		payload: {
			event: payload.eventName,
			payload,
		},
	}));
}

export default handleAppsflyer;
