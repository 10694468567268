import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { estimate } from '../actions';
import { MotorCoverageActionTypes } from '../types';
export default function* watcherEstimate() {
    yield takeEvery(MotorCoverageActionTypes.ESTIMATE, workerEstimate);
}
function* workerEstimate({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call([client, client.rest], 'service-motor-coverage', 'post', '1/quotes/estimate', null, payload);
        yield put(estimate.success(ident, response));
    }
    catch (error) {
        yield put(estimate.failure(ident, error));
    }
}
