import React from 'react';

import { AdapterProps } from '../types';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import ImageBelow from '~website/components/atoms/layout/ImageBelow';
import TitleSegment, { contentAdapter } from '~website/components/atoms/TitleSegment';

interface ExtraProps {
	asset?: DesignSystemAsset;
}

const adapter: React.FCWithChildren<AdapterProps> = ({ content, getter }) => {
	const { asset } = content as ExtraProps;

	return (
		<TitleSegment {...contentAdapter(getter)} isFullWidth>
			<ImageBelow asset={asset} />
		</TitleSegment>
	);
};

export default {
	type: 'title_segment',
	adapter,
	builder: {
		fields: {
			centered: { type: 'boolean' },
			title: { type: 'string' },
			subtitle: { type: 'string' },
			id: { type: 'string' },
			body: { type: 'text' },
			asset: { type: 'design_system_asset' },
			show_button: { type: 'cta' },
			allow_more_buttons: { type: 'cta[]' },
			show_app_store_buttons: { type: 'boolean' },
			show_trustpilot: { type: 'boolean' },
			show_app_store_buttons_with_review: { type: 'boolean' },
		},
		default: {
			centered: true,
			title: 'Anybody that needs a test',
			body: 'gets a test',
			asset: {
				type: 'image',
				value: 'help_dialog.png',
				description: '',
			},
			show_button: {
				text: 'Get a quote',
				url: '/get-an-estimate',
				track_click: false,
				event_name: '',
			},
			show_app_store_buttons: true,
			show_trustpilot: true,
			show_app_store_buttons_with_review: true,
		},
	},
};
