import { combineReducers } from 'redux';
import listProfiles from './list-profiles';
import profileById from './profile-by-id';
import search from './search';
import updateProfile from './update-profile';
export { listProfiles, profileById, search, updateProfile, };
export default combineReducers({
    listProfiles,
    profileById,
    search,
    updateProfile,
});
