import React, { useRef } from 'react';
import { CardHeaderGroupActionWrapper } from './atoms/CardHeaderGroupActionWrapper';
import CardActionsDropdown from './CardActionsDropdown';
import useResizeObserver from '~lib/frontend/hooks/use-resize-observer';
export const CardHeaderGroupAction = ({ children, loading, defaultCollapse, }) => {
    const headerAction = useRef(null);
    const [width] = useResizeObserver(headerAction);
    return (React.createElement(CardHeaderGroupActionWrapper, { onClick: e => e.stopPropagation(), ref: headerAction },
        React.createElement(CardActionsDropdown, { maxWidth: width ?? 0, loading: loading, defaultCollapse: defaultCollapse }, children)));
};
