import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listByIdentifiers, listFilesByUserId } from '../actions';
import { UploadActionTypes } from '../types';
export default function* watcherListByIdentifiers() {
    yield takeEvery(UploadActionTypes.LIST_FILES_BY_USER_ID, workerListFileByUserId);
}
function* workerListFileByUserId({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId, purpose, urlTtl, includeHidden } = payload;
    try {
        const response = yield call([client, client.rpc], 'service-upload', '2/2020-08-25/list_files_by_user', { userId, purpose, urlTtl, includeHidden });
        yield put(listFilesByUserId.success(ident, response));
    }
    catch (error) {
        yield put(listByIdentifiers.failure(ident, error));
    }
}
