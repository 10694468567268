import { call, getContext, put } from 'redux-saga/effects';
import { listOfficeDoggos } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(listOfficeDoggos.request, function* workerListOfficeDoggos() {
    const client = yield getContext('api');
    try {
        const response = yield call(api.listOfficeDoggos, client);
        yield put(listOfficeDoggos.success(response));
    }
    catch (error) {
        yield put(listOfficeDoggos.failure(error));
    }
});
