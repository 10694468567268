import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

const Counter3ConfettiGrid = styled.div`
	max-width: 690px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: 25px;
	justify-items: stretch;


	${Typography} {
		text-align: center !important;
	}

	@media (max-width: 767px) {
		grid-template-columns: 1fr 4fr;
		margin-top: 30px;

		${Typography} {
			text-align: left !important;
		}
	}

	> div {
		text-align: center;
		position: relative;

		@media (min-width: 768px) {
			> img {
				margin: 0 auto;
				width: 83%;
			}
		}
		@media (max-width: 767px) {
			> img {
				width: 100%;
			}
		}

		/* Sorry for this - but aligning the svg isn't easy peasy lemon squeezy */
		@media (max-width: 400px) {
			> img {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
`;

export default Counter3ConfettiGrid;
