import React from 'react';

import { AdapterProps } from '../types';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import TwoColumnsLayout, { TwoColumnsImage } from '~website/components/atoms/layout/TwoColumns';
import TitleSegment, { contentAdapter } from '~website/components/atoms/TitleSegment';

interface ExtraProps {
	id?: string;
	flip?: boolean;
	asset?: DesignSystemAsset;
}

const adapter: React.FCWithChildren<AdapterProps> = ({ content, getter }) => {
	const { id, flip, asset } = content as ExtraProps;

	return (
		<TwoColumnsLayout flip={flip} id={id}>
			<TitleSegment {...contentAdapter(getter)} />
			<TwoColumnsImage asset={asset} />
		</TwoColumnsLayout>
	);
};

export default {
	type: 'title_segment_two_columns',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			flip: { type: 'boolean' },
			asset: { type: 'design_system_asset' },
			centered: { type: 'boolean' },
			title: { type: 'string' },
			subtitle: { type: 'string' },
			body: { type: 'text' },
			segment_checklist: { type: 'checklistItem[]' },
			show_button: { type: 'cta' },
			allow_more_buttons: { type: 'cta[]' },
			show_app_store_buttons: { type: 'boolean' },
			show_trustpilot: { type: 'boolean' },
		},
		default: {
			flip: false,
			asset: {
				type: 'image',
				value: 'help_dialog.png',
				description: '',
			},
			centered: true,
			title: 'Anybody that needs a test',
			body: 'gets a test',
			subtitle: 'subtitle',
			segment_checklist: [
				{ label: 'One', checked: true },
				{ label: 'Two', checked: true },
				{ label: 'Three', checked: true },
			],
			show_button: {
				text: 'Get a quote',
				url: '/get-an-estimate',
			},
			show_app_store_buttons: true,
			show_trustpilot: true,
		},
	},
};
