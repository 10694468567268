import useSegment from '~lib/frontend/hooks/use-segment';
export default function useDesignSystemAsset(desiAsset) {
    const [, getAsset] = useDesignSystemAssets();
    if (!desiAsset || !desiAsset.value)
        return null;
    const { type, value } = desiAsset;
    if (type === 'icon')
        return getAsset(`icons/${value}`);
    if (type === 'illustration')
        return getAsset(`illustrations/${value}`);
    if (type === 'background')
        return getAsset(`backgrounds/${value}`);
    if (type === 'image')
        return getAsset(`images/${value}`);
    if (type === 'photo')
        return getAsset(`photos/${value}`);
    if (type === 'video')
        return getAsset(`videos/${value}`);
    if (type === 'mask')
        return getAsset(`masks/${value}`);
    if (type === 'share')
        return getAsset(`share/${value}`);
    if (type === 'manufacturer')
        return getAsset(`manufacturers/${value}`);
    if (type === 'payment')
        return getAsset(`payment_methods/${value}`);
    return '';
}
/**
 * Avoid using this helpers, as this allows unrestricted access to the design system assets,
 * making it really difficult to track what's been used and not.
 * Useful only for enumerating the object properties theoretically.
 */
export function useDesignSystemAssets() {
    const assets = useSegment('design_system').assets;
    const getAsset = (key) => assets[key]?.content;
    return [assets, getAsset];
}
