export const formatYearsMonths = (months, zero) => {
    if (!Number.isFinite(months))
        return '-';
    if (months === 0)
        return zero ?? 'Less than a month';
    const rest = months % 12;
    const years = Math.floor(months / 12);
    if (years === 0)
        return `${rest} month${rest === 1 ? '' : 's'}`;
    if (rest === 0)
        return `${years} year${years > 1 ? 's' : ''}`;
    return `${years} year${years > 1 ? 's' : ''} ${rest} month${rest > 1 ? 's' : ''}`;
};
export const formatYears = (years) => {
    if (!Number.isFinite(years))
        return '-';
    if (years === 0)
        return 'Less than a year';
    return `${years} year${years > 1 ? 's' : ''}`;
};
