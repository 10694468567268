import Cher from '@cuvva/cher';

const validBooleanValidator = (value: boolean | undefined) => {
	if (typeof value !== 'boolean')
		return new Cher('invalid');

	return void 0;
};

export default validBooleanValidator;
