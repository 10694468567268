import React from 'react';

import { AdapterProps } from '../types';
import OpenRoles, { contentAdapter } from '~website/features/careers/components/organisms/OpenRoles';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<OpenRoles {...contentAdapter(getter)} />
);

export default {
	type: 'careers_open_roles',
	adapter,
	builder: {
		fields: {
			title: { type: 'string' },
			body: { type: 'string' },
			filter: { type: 'string' },
		},
		default: {
			title: 'Our open roles',
			body: '',
			filter: '',
		},
	},
};
