import { combineReducers } from 'redux';
import attemptCycleGeneration from './attempt-cycle-generation';
import config from './config';
import confirmCancellation from './confirm-cancellation';
import createEstimate from './create-estimate';
import createQuote from './create-quote';
import createSubscription from './create-subscription';
import getAdditionalDriver from './get-additional-driver';
import getNextBillingDate from './get-next-biling-date';
import getProfile from './get-profile';
import getSubscriptionByPolicy from './get-subscription-by-policy';
import getSubscriptionDetail from './get-subscription-detail';
import subscriptionUsers from './get-subscription-user';
import listEvents from './list-events';
import listPoliciesBySubscription from './list-policies-by-subscription';
import listSubscriptions from './list-subscriptions';
import listTransactionsByPolicy from './list-transactions-by-policy';
import processCycle from './process-cycle';
import pushFailedPayment from './push-failed-payment';
import quoteAutocycle from './quote-autocycle';
import quoteCancellation from './quote-cancellation';
import regenerateQuote from './regenerate-quote';
import setDetails from './set-details';
import startPricing from './start-pricing';
import updatePricing from './update-pricing';
export { attemptCycleGeneration, config, confirmCancellation, createEstimate, createQuote, getNextBillingDate, getProfile, getSubscriptionDetail, getSubscriptionByPolicy, listEvents, listPoliciesBySubscription, listSubscriptions, listTransactionsByPolicy, processCycle, pushFailedPayment, quoteAutocycle, quoteCancellation, regenerateQuote, setDetails, startPricing, subscriptionUsers, updatePricing, createSubscription, getAdditionalDriver, };
export default combineReducers({
    attemptCycleGeneration,
    config,
    confirmCancellation,
    createEstimate,
    createQuote,
    getNextBillingDate,
    getProfile,
    getSubscriptionDetail,
    getSubscriptionByPolicy,
    listEvents,
    listPoliciesBySubscription,
    listSubscriptions,
    listTransactionsByPolicy,
    processCycle,
    pushFailedPayment,
    quoteAutocycle,
    quoteCancellation,
    regenerateQuote,
    setDetails,
    startPricing,
    subscriptionUsers,
    updatePricing,
    createSubscription,
    getAdditionalDriver,
});
