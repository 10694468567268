import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import ksuid from '@cuvva/ksuid';

import VerifyPrimaryIdentifier from './VerifyPrimaryIdentifier';
import VerifyPrimaryIdentifierToken from './VerifyPrimaryIdentifierToken';
import { isValidPhoneNumber, parsePhoneNumber } from '~lib/frontend/helpers/phone-number';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { replacePrimaryIdentifier, sendIdentifierToken, userById, verifyIdentifier } from '~lib/platform/auth/store/actions';
import { useVerificationContext } from '~website/features/quote/contexts/VerificationContext';
import useUser from '~website/hooks/use-user';

type VerificationType = 'email' | 'mobile_phone';

export interface VerifyPrimaryIdentifierProps {
	type: VerificationType;
}

type FormStep = 'identifier' | 'token';

const generateRequestId = () => ksuid.generate('request').toString();

const parseValue = (type: VerificationType, identifier: string): string => {
	if (type === 'mobile_phone') {
		if (!isValidPhoneNumber(identifier))
			throw new Cher('not_valid');
	}

	let value = identifier;

	if (type === 'mobile_phone')
		value = parsePhoneNumber(identifier);

	return value;
};

const VerificationIdentifier = ({ type }: VerifyPrimaryIdentifierProps) => {
	const [step, setStep] = useState<FormStep>('identifier');
	const { onUploadComplete } = useVerificationContext();

	const dispatch = useDispatch();
	const { userId } = useUser();
	const userByIdStore = useSelector(s => s.platform.auth.userById[`${userId}-false`]);

	const [sendTokenReqId, setSendTokenReqId] = useState(generateRequestId);
	const [primaryIdentifierReqId, setPrimaryIdentifierReqId] = useState(generateRequestId);

	const [sendIdentifierTokenError, setSendIdentifierTokenError] = useState<Cher>(void 0);
	const [setIdentifierError, setSetIdentifierError] = useState<Cher>(void 0);

	const sendIdentifierTokenStore = useSelector(s => s.platform.auth.sendIdentifierToken[sendTokenReqId]);
	const sendIdentifierTokenStoreState = useAsyncStateStatus(sendIdentifierTokenStore).status;

	// eslint-disable-next-line
	const verifyIdentifierStore = useSelector(s => s.platform.auth.verifyIdentifier[primaryIdentifierReqId]);
	const verifyIdentifierStatus = useAsyncStateStatus(verifyIdentifierStore).status;

	// eslint-disable-next-line
	const replacePrimaryIdentifierStore = useSelector(s => s.platform.auth.replacePrimaryIdentifier[primaryIdentifierReqId]);
	const replacePrimaryIdentifierStatus = useAsyncStateStatus(replacePrimaryIdentifierStore).status;

	const identifiers = userByIdStore?.response?.identifiers;
	const identifier = identifiers?.find(i => i.primary && i.type === type);

	const [newIdentifier, setNewIdentifier] = useState<string>(identifier?.value ?? '');

	useEffect(() => {
		if (sendIdentifierTokenStoreState === 'response')
			setStep('token');
	}, [sendIdentifierTokenStoreState]);

	useEffect(() => {
		if ([verifyIdentifierStatus, replacePrimaryIdentifierStatus].includes('response')) {
			dispatch(userById.request({ userId, includeRemovedIdentifiers: false }));

			onUploadComplete();
		}
	}, [verifyIdentifierStatus, replacePrimaryIdentifierStatus, onUploadComplete, dispatch, userId]);

	const onSendToken = (identifier: string) => {
		try {
			const requestId = generateRequestId();
			const parsed = parseValue(type, identifier);

			setSendIdentifierTokenError(void 0);
			setNewIdentifier(parsed);
			setSendTokenReqId(requestId);

			dispatch(sendIdentifierToken.request({
				requestId,
				value: parsed,
				userId,
				type,
				clientId: void 0,
			}));
		} catch (e) {
			setSendIdentifierTokenError(e as Cher);
		}
	};

	const onResendCode = () => onSendToken(newIdentifier);

	const onConfirmToken = (token: string) => {
		try {
			const requestId = generateRequestId();
			const parsed = parseValue(type, newIdentifier);

			setSetIdentifierError(void 0);
			setPrimaryIdentifierReqId(requestId);

			const action = type === 'mobile_phone' ? replacePrimaryIdentifier : verifyIdentifier;

			dispatch(action.request({
				requestId,
				clientId: void 0,
				value: parsed,
				userId,
				type,
				token: token.replace(/[^0-9]/gmi, ''),
			}));
		} catch (e) {
			setSetIdentifierError(e as Cher);
		}
	};

	const onChangeIdentifier = () => setStep('identifier');

	const loading = [verifyIdentifierStatus, replacePrimaryIdentifierStatus].includes('fetching');
	const error = setIdentifierError ?? (verifyIdentifierStore?.error || replacePrimaryIdentifierStore?.error);

	if (step === 'identifier') {
		return (
			<VerifyPrimaryIdentifier
				type={type}
				loading={sendIdentifierTokenStoreState === 'fetching'}
				error={sendIdentifierTokenError ?? sendIdentifierTokenStore?.error}
				initialValue={newIdentifier}
				onProceed={onSendToken}
			/>
		);
	}

	return (
		<VerifyPrimaryIdentifierToken
			type={type}
			identifier={newIdentifier}
			loading={loading}
			error={error}
			onResendCode={onResendCode}
			onChangeIdentifier={onChangeIdentifier}
			onConfirmToken={onConfirmToken}
		/>
	);
};

export default VerificationIdentifier;
