import styled from 'styled-components';
const TableHead = styled.th `
	${p => p.$width && `width: ${p.$width}`};
	text-transform: capitalize;
	white-space: nowrap;
	color: ${props => props.theme.ui.textOnSurfaceBackgroundMuted};

	font-weight: bold;
	text-align: left;
`;
export default TableHead;
