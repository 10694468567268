import { Doggo } from '~lib/platform/doggos/types';
import { StaffMemberPublic } from '~lib/platform/staff/types';
import { AsyncState, initialAsyncState } from '~lib/shared/redux/types/state';

export type CuvvaPeep = (StaffMemberPublic | Doggo);
export type CuvvaPeeps = CuvvaPeep[];

export interface AboutState {
	listCuvvaPeeps: AsyncState<CuvvaPeeps>;
}

export const initialAboutState: AboutState = {
	listCuvvaPeeps: initialAsyncState,
};

export const AboutActionTypes = {
	LIST_CUVVA_PEEPS: '@@internal/about/LIST_CUVVA_PEEPS',
	LIST_CUVVA_PEEPS_FAILURE: '@@internal/about/LIST_CUVVA_PEEPS_FAILURE',
	LIST_CUVVA_PEEPS_SUCCESS: '@@internal/about/LIST_CUVVA_PEEPS_SUCCESS',
};
