import { put } from 'redux-saga/effects';

import { trackEvent } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.trackAccountsEvent,
	function* accountsEventSaga(action) {
		const { eventName } = action.payload;

		const eventPayload = {
			product: 'ltm', // might support stm in future
			platform: 'website',
		};

		yield put(trackEvent({
			eventName,
			eventPayload,
		}));
	});
