import styled from 'styled-components';

import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

const Container = styled.div`
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing.large};

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export const SmallerContainer = styled.div`
	margin: 0 auto;
	padding: 0 ${p => p.theme.spacing.large};
	max-width: 950px;

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export default Container;
