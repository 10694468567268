import { put } from 'redux-saga/effects';

import { trackScreenView } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.trackOwnersScreenView,
	function* ownersScreenViewSaga(action) {
		const { screenName, cuvvaSource, intercomScreenViewName } = action.payload;

		yield put(trackScreenView({
			eventName: screenName,
			intercomScreenViewName: intercomScreenViewName || 'stm_referred_lender_owner',
			eventPayload: {
				cuvva_source: cuvvaSource,
			},
		}));
	});
