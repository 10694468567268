import { call, getContext, put } from 'redux-saga/effects';
import { createClientCode } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(createClientCode.request, function* workerCreateClientCode({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call([client, client.createClientCode], payload.device, payload.handoff, payload.scope);
        yield put(createClientCode.success(ident, response));
    }
    catch (error) {
        yield put(createClientCode.failure(ident, error));
    }
});
