import styled, { css } from 'styled-components';
const Margin = styled.div `
	margin-top: ${p => convertToMargin(p.$marginTop, p.theme.spacing)};
	margin-right: ${p => convertToMargin(p.$marginRight, p.theme.spacing)};
	margin-bottom: ${p => convertToMargin(p.$marginBottom, p.theme.spacing)};
	margin-left: ${p => convertToMargin(p.$marginLeft, p.theme.spacing)};
`;
function convertToMargin(margin, spacing) {
    if (margin === void 0)
        return '0';
    if (typeof margin === 'number')
        return '0';
    const marginKeys = Object.keys(spacing);
    if (!marginKeys.includes(margin))
        return margin;
    return spacing[margin];
}
export const parseMargin = (p) => css `
	${p.$marginTop === void 0 ? '' : `margin-top: ${convertToMargin(p.$marginTop, p.theme.spacing)}`};
	${p.$marginRight === void 0 ? '' : `margin-right: ${convertToMargin(p.$marginRight, p.theme.spacing)}`};
	${p.$marginBottom === void 0 ? '' : `margin-bottom: ${convertToMargin(p.$marginBottom, p.theme.spacing)}`};
	${p.$marginLeft === void 0 ? '' : `margin-left: ${convertToMargin(p.$marginLeft, p.theme.spacing)}`};
`;
export default Margin;
