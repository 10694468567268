import { all } from 'redux-saga/effects';
import abiCodes from './abi-codes';
import errorCodes from './error-codes';
import geoCodes from './geo-codes';
export default function* codeMappingSaga() {
    yield all([
        abiCodes,
        errorCodes,
        geoCodes,
    ]);
}
