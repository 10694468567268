import React from 'react';
import styled from 'styled-components';

import { PricingHeaderBarProps } from './headers/PricingHeaderBar';
import SteppedProgress from './SteppedProgress';
import { ContentContainer } from '~website/components/SplitUI';

const DesktopScreenTab = ({ hideProgress }: PricingHeaderBarProps) => {
	if (hideProgress)
		return null;

	return (
		<Wrap>
			<SteppedProgress />
		</Wrap>
	);
};

const Wrap = styled(ContentContainer)`
	display: none;

	margin-top: ${p => p.theme.spacing.extraLarge};
	margin-bottom: ${p => p.theme.spacing.extraLarge};

	@media (min-width: 1024px) {
		display: block;
	}
`;

export default DesktopScreenTab;
