import { call, getContext, put } from 'redux-saga/effects';
import * as api from '../../api';
import { listBorrowerPolicies } from '../actions';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';
export default createTakeLatestSagaSet(listBorrowerPolicies.request, function* listBorrowerPoliciesSaga({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...rest } = payload;
    try {
        const response = yield call(api.listBorrowerPolicies, client, rest);
        yield put(listBorrowerPolicies.success(ident, response));
    }
    catch (error) {
        yield put(listBorrowerPolicies.failure(ident, error));
    }
});
