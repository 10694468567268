import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stepActions } from '../store/actions';

const field = 'voluntaryExcess';

function useVoluntaryExcess() {
	const dispatch = useDispatch();
	const value = useSelector(s => s.internal.quote.state[field]);

	const onChange = useCallback((voluntaryExcess: number) => {
		dispatch(stepActions.setVoluntaryExcess(voluntaryExcess));
	}, [dispatch]);

	return {
		onChange,
		value,
	};
}

export default useVoluntaryExcess;
