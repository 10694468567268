import Cher from '@cuvva/cher';
import { takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import { GetUserActionTypes } from '../types';
import { getHash } from '~lib/platform/intercom/store/actions';
import { getLogger } from '~lib/shared/logger';
import { requestAndTake } from '~lib/shared/redux/sagas';
import { IntercomSettings } from '~website/augmentations';

export default function* watcherRegisterIntercom() {
	yield takeEvery(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS, workerRegisterIntercom);
}

function* workerRegisterIntercom({ payload: userId }: PayloadAction<string, string>) {
	if (!userId) return;

	try {
		const userHash: string = yield requestAndTake(getHash.request({ userId, client: 'web' }));

		window.intercomSettings = {
			app_id: 'pftsgka8',
			custom_launcher_selector: '.intercom-launcher',
			user_id: userId,
			user_hash: userHash,
			...(window.intercomSettings || {}),
		};

		initializeIntercom(window.intercomSettings);
	} catch (error) {
		const cher = Cher.coerce(error);

		getLogger().warning('intercom setup failed', { cher });
	}
}

function initializeIntercom(settings: IntercomSettings) {
	const originalIntercom = window.Intercom;

	// Create a proxy object for window.Intercom
	const intercomProxy = new Proxy(originalIntercom, {
		apply(target, thisArg, argumentsList) {
			const command = argumentsList[0];

			// Check if the command is 'boot'
			if (command === 'boot')
				return null;

			// Forward the function call to the originalIntercom
			return Reflect.apply(target, thisArg, argumentsList);
		},
	});

	// Replace the global window.Intercom with the proxy object
	window.Intercom = intercomProxy;

	// Initialize Intercom with settings
	intercomProxy('boot', settings);
}
