import { all } from 'redux-saga/effects';
import getDocumentBaseSaga from './get-document-base';
import getPagesSaga from './get-pages';
import getSegmentSaga from './get-segment';
export default function* contentSaga() {
    yield all([
        getDocumentBaseSaga,
        getPagesSaga,
        getSegmentSaga,
    ]);
}
