import { all } from 'redux-saga/effects';
import attachPaymentMethod from './attach-payment-method';
import completeWebIntent from './complete-web-intent';
import createPayment from './create-payment';
import createWebIntent from './create-web-intent';
import getMandates from './get-mandates';
import listIntentsByUser from './list-intents-by-user';
import listPaymentMethods from './list-payment-methods';
import updateMandate from './update-mandate';
import userById from './user-by-id';
export default function* paymentSaga() {
    yield all([
        attachPaymentMethod,
        completeWebIntent,
        createPayment,
        createWebIntent,
        listIntentsByUser,
        listPaymentMethods,
        userById,
        getMandates,
        updateMandate,
    ]);
}
