import { useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import Mustache from 'mustache';

import { ErrorCode } from '~lib/platform/code-mapping/types';

type ErrorMap = Record<string, string>;

const invalidFieldErrorMappings: Record<string, Record<string, ErrorMap>> = {
	profile: {
		personalName: invalid('Enter first name'),
		familyName: invalid('Enter last name'),
		birthDate: {
			empty: 'Please enter your date of birth.',
			invalid: 'The date format needs to be ‘DD/MM/YYYY’.',
			future_date: 'You can\'t be born in the future!',
			too_young: 'You\'re too young to Cuvva right now. We\'ll be waiting for you though!',
			too_old: 'Unfortunately we can\'t cover you on this Cuvva product right now.',
		},
		emailAddress: invalid('Not a valid email'),
		maritalStatus: invalid('Choose an option'),
		employmentCode: invalid('Choose an option'),
		vehiclesOwned: invalid('Choose an option'),
		homeowner: invalid('Choose an option'),
		residentialAddress: invalid('Enter a postcode'),
		occupationCode: invalid('Add a job title'),
		sex: invalid('We need this to match it to your DVLA record. We respect that you might identify differently.'),
	},

	dlr: {
		dln: invalid('Not a valid licence number'),
		postcode: invalid('Enter a valid postcode'),
	},
	vehicleProfile: {
		'owner.type': invalid('Select an owner'),
		'owner.durationMonths': invalid('Choose an option'),
		'storage.type': invalid('Choose an option'),
		'storage.location': invalid('Enter a postcode'),
		'registeredKeeperCode': invalid('Choose an option'),
		'annualMileage': invalid('Choose an option'),
		'estimatedValue': {
			invalid: 'Please enter a valid value',
			too_high: 'This looks too high',
			too_low: 'This looks too low, your value needs to be higher',
		},
	},
	incidents: {
		incident: {
			invalid: 'Choose an option',
		},
		category: invalid('Choose an option'),
		date: {
			empty: 'Please enter the incident date.',
			invalid: 'The date format needs to be ‘DD/MM/YYYY’.',
			future_date: 'Must be a past date',
			too_old: 'Only declare incidents that are up to 5 years old',
		},
		theftOf: invalid('Choose an option'),
		atFault: invalid('Choose an option'),
		bodilyInjury: invalid('Choose an option'),
		cost: invalid('Enter the claimed amount'),
	},
	stm: {
		quoteLocation: invalid('Enter a postcode'),
	},
	quote: {
		voluntaryExcess: invalid('Choose an option'),
		ncb: invalid('Please select an option.'),
		useClass: invalid('Choose an option'),
	},

	// SC: what is this? pls deprecate
	referral: {
		renewalDate: invalid('Enter a correct renewal date'),
	},
};

const somethingWentWrong = 'Whoops! Something went wrong 🙈';
const askSupport = 'Please get in touch with support';

const extraCodes: ErrorCode[] = [{
	code: 'unacceptable_birth_date',
	message: 'Your date of birth doesn\'t look quite right 🙈',
	category: void 0,
}, {
	code: 'missing_fields',
	message: 'You’ll need to correct a few details before you can continue',
	category: void 0,
}, {
	code: 'bad_request',
	message: somethingWentWrong,
	category: void 0,
}, {
	code: 'cannot_quote',
	message: 'Unfortunately we cannot provide you with a quote',
	category: void 0,
}, {
	code: 'unauthorized_mta',
	message: `${somethingWentWrong} ${askSupport}`,
	category: void 0,
}, {
	code: 'product_failure',
	message: `${somethingWentWrong} ${askSupport}`,
	category: void 0,
}];

const useFriendlyError = (error: Cher | void, namespace?: string, field?: string): string | undefined => {
	const errorCodes = useSelector(s => s.platform.codeMapping.errorCodes?.response);

	if (!error)
		return void 0;

	const { code, meta, reasons } = error;

	const internallyHandled = invalidFieldErrorMappings?.[namespace]?.[field]?.[code];

	if (internallyHandled)
		return internallyHandled;

	let errors: string[] = [];
	const allCodes = extraCodes.concat(errorCodes || []);
	const foundCode = allCodes.find(c => c.code === code);

	if (foundCode)
		errors.push(Mustache.render(foundCode.message, meta || {}));

	// create_change_request wraps the error, gotta safeguard gainst that as well
	if (error.code === 'invalid_body') {
		for (const [i, reason] of reasons.entries()) {
			const innerCode = reason.code;
			const innerMeta = reason.meta;

			const foundCode = extraCodes.concat(errorCodes || []).find(c => c.code === innerCode);

			if (!foundCode) {
				errors.push(innerCode);

				continue;
			}

			// If we found a more meaningful error than 'invalid_body'...
			if (i === 0)
				errors = [];

			const rendered = Mustache.render(foundCode.message, innerMeta || {});

			errors.push(rendered);
		}
	}

	return errors.length ? errors.join(' ') : void 0;
};

function invalid(message: string) {
	return {
		invalid: message,
	};
}

export default useFriendlyError;
