import { call, getContext, put } from 'redux-saga/effects';
import { listPoliciesBySubscription } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(listPoliciesBySubscription.request, function* listPoliciesBySubscriptionSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const { userId, subscriptionId } = payload;
        const response = yield call(api.listPoliciesBySubscription, client, userId, subscriptionId);
        yield put(listPoliciesBySubscription.success(ident, response));
    }
    catch (error) {
        yield put(listPoliciesBySubscription.failure(ident, error));
    }
});
