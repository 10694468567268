import React from 'react';
import styled, { css } from 'styled-components';

import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset, GetterType } from '~lib/frontend/types/content';

interface SplashAssetProps {
	asset: DesignSystemAsset;
	mobileAsset: DesignSystemAsset;
}

const SplashAsset: React.FCWithChildren<SplashAssetProps> = props => {
	const { asset, mobileAsset } = props;

	const dsAsset = useDesignSystemAsset(asset);
	const dsMobileAsset = useDesignSystemAsset(mobileAsset);

	return (
		<Wrapper $hasMobile={Boolean(mobileAsset?.value)}>
			<DesktopImage src={dsAsset} />
			<MobileImage src={dsMobileAsset} />
		</Wrapper>
	);
};

export const contentAdapter = (get: GetterType): SplashAssetProps => {
	const asset = get<DesignSystemAsset>('asset');
	const mobileAsset = get<DesignSystemAsset>('mobile_asset');

	return {
		asset,
		mobileAsset,
	};
};

const DesktopImage = styled.img`
	width: 100%;
	max-width: 1280px;
`;

const MobileImage = styled.img`
	width: 100%;
`;

const Wrapper = styled.div<{ $hasMobile: boolean }>`
	${MobileImage} {
		display: block;
	}

	${DesktopImage} {
		display: none;
	}

	${p => p.$hasMobile && css`
		@media (min-width: 768px) {
			${MobileImage} {
				display: none;
			}

			${DesktopImage} {
				display: block;
			}
		}
	`};

	${p => !p.$hasMobile && css`
		${MobileImage} {
			display: none;
		}

		${DesktopImage} {
			display: block;
		}
	`};
`;

export default SplashAsset;
