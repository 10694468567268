import styled from 'styled-components';

const LackingInspirationGrid = styled.div`
	max-width: 690px;
	margin: 50px auto 32px auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: 16px;
	grid-row-gap: 32px;
	justify-items: stretch;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;

export default LackingInspirationGrid;
