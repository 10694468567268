import React, { Fragment, useState } from 'react';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import CancellationFlowModal from '../organisms/cancellations';
import CancelImmediately from './CancelImmediately';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as SimpleDialog from '~lib/frontend/design-system/components/dialog/molecules/SimpleDialog';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CancelASubscriptionModalProps {
	open: boolean;
	onClose: () => void;
}

const CancelActiveSubscription: React.FCWithChildren<CancelASubscriptionModalProps> = props => {
	const { open, onClose } = props;
	const [cancelImmediatelyModal, setCancelImmediatelyModal] = useState(false);
	const [autocycleOffModal, setAutocycleOffModal] = useState(false);

	const openCancelImmediatelyModal = () => {
		onClose();
		setCancelImmediatelyModal(true);
	};

	const openAutocycleOffModal = () => {
		onClose();
		setAutocycleOffModal(true);
	};

	const policy = useSinglePolicy();

	const endDate = policy.endDate;
	const { date, time } = formattedEndDate(endDate);

	return (
		<Fragment>
			<SimpleDialog.Dialog open={open} onClose={onClose} title={'Cancel your subscription'}>
				<SimpleDialog.Content>
					<Banner.Wrapper type={'error'} muted icon={'ic_exclamation_circle'}>
						{'We no longer sell new subscription policies.'}
						<br />
						{'Once you cancel your policy, you won\'t be able to restart it later.'}
					</Banner.Wrapper>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $display={'inline'}>
						{'If you cancel today, you’ll still be covered right up to '}
					</Typography>
					<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $display={'inline'}>
						{time}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $display={'inline'}>
						{' on '}
					</Typography>
					<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $display={'inline'}>
						{date}
					</Typography>
				</SimpleDialog.Content>
				<SimpleDialog.Actions>
					<Button $type={'primaryMuted'} onClick={openCancelImmediatelyModal}>
						{'Stop my cover immediately'}
					</Button>
					<Button
						$type={'primary'}
						onClick={openAutocycleOffModal}
					>
						{'Continue cancelling'}
					</Button>
				</SimpleDialog.Actions>
			</SimpleDialog.Dialog>
			{cancelImmediatelyModal && (
				<CancelImmediately open={cancelImmediatelyModal} onClose={() => setCancelImmediatelyModal(false)} />
			)}
			{autocycleOffModal && (
				<CancellationFlowModal open={autocycleOffModal} onClose={() => setAutocycleOffModal(false)} />
			)}
		</Fragment>
	);
};

export default CancelActiveSubscription;

const formattedEndDate = (endDate: string) => {
	const optionsDate: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};

	const optionsTime: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	};

	const date = new Intl.DateTimeFormat('en-GB', optionsDate).format(new Date(endDate));
	const time = new Intl.DateTimeFormat('en-GB', optionsTime).format(new Date(endDate));

	return { date, time };
};
