import moment from 'moment';
export const getTitle = (isPolicyEnded, isStartDateUpcoming) => {
    if (isPolicyEnded)
        return 'Policy ended:';
    if (isStartDateUpcoming)
        return 'Policy starts:';
    return 'Policy ends:';
};
export function getTimerStates(voided, percentage, isStartDateUpcoming) {
    if (voided)
        return 'voided';
    if (isStartDateUpcoming)
        return 'upcoming';
    if (percentage >= 50)
        return 'just-started';
    if (percentage >= 25)
        return 'midway';
    return 'ending';
}
export const getPercentage = (params) => {
    const { isStartDateUpcoming, policyStartDate, policyEndDate, currentTime } = params;
    if (isStartDateUpcoming) {
        const totalFromNow = policyStartDate.diff(moment().startOf('day'), 'hours');
        const currentDay = currentTime.hours();
        const timeLeftToStart = totalFromNow - currentDay;
        return (timeLeftToStart / totalFromNow) * 100;
    }
    const total = policyEndDate.diff(policyStartDate, 'seconds');
    const startToNow = moment().diff(policyStartDate, 'seconds');
    const diff = total - startToNow;
    return (diff / total) * 100;
};
export const policyTimeString = (date) => {
    const now = moment();
    const relevantDate = moment(date);
    const diff = relevantDate.diff(now);
    const duration = moment.duration(Math.abs(diff));
    let timeString;
    if (duration.asHours() < 1)
        timeString = `${Math.floor(duration.asMinutes())} minute${Math.floor(duration.asMinutes()) > 1 ? 's' : ''}`;
    else if (duration.asHours() < 24)
        timeString = `${Math.floor(duration.asHours())} hour${Math.floor(duration.asHours()) > 1 ? 's' : ''}`;
    else
        timeString = `${Math.floor(duration.asDays())} day${Math.floor(duration.asDays()) > 1 ? 's' : ''}`;
    return {
        timeString,
        duration,
    };
};
export const getPolicyDuration = (secs, type) => {
    let seconds = secs;
    if (type !== 'driving' && seconds >= 3599)
        seconds += 1;
    const duration = moment.duration(seconds, 'seconds');
    const validUnits = ['hour', 'minute', 'second'];
    const parts = [];
    const days = duration.asDays();
    if (duration.asDays() >= 1) {
        const updatedDays = Math.floor(days);
        parts.push(`${updatedDays} day${updatedDays > 1 ? 's' : ''}`);
    }
    for (const unit of validUnits) {
        if (parts.length >= 2)
            break;
        const value = duration.get(unit);
        if (value)
            parts.push(`${value} ${unit}${value > 1 ? 's' : ''}`);
    }
    return parts.join(', ');
};
