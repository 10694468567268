import Cher from '@cuvva/cher';

import positiveNumericValidator from './positive-numeric';

// Estimate vehicle value is in pence
const defaultMinValue = 1_00;
const defaultMaxValue = 100_000_00;

const estimatedVehicleValueValidator = (
	value: number | undefined,
	minValue = defaultMinValue,
	maxValue = defaultMaxValue,
) => {
	const invalid = positiveNumericValidator(value);

	if (invalid)
		return invalid;

	if (value > maxValue)
		return new Cher('too_high');

	if (value < minValue)
		return new Cher('too_low');

	return void 0;
};

export default estimatedVehicleValueValidator;
