import React, { useContext } from 'react';
import { CardHeaderGroupContainer, CardHeaderGroupWrapper } from './atoms/CardHeaderGroupWrapper';
import CardContext from './context';
import CardHeaderChevron from './molecules/CardHeaderChevron';
export const CardHeaderGroup = ({ children }) => {
    const { open, toggleCollapse, collapsible } = useContext(CardContext);
    return (React.createElement(CardHeaderGroupContainer, { "$collapsible": collapsible, onClick: toggleCollapse },
        collapsible && React.createElement(CardHeaderChevron, { open: open }),
        React.createElement(CardHeaderGroupWrapper, { "$collapsible": collapsible }, children)));
};
