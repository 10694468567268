import styled from 'styled-components';

const HowItGoesSection = styled.div`
	margin: 0 auto;
	max-width: 550px;
	> * {
		text-align: center;
	}
`;

export default HowItGoesSection;
