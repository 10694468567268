import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { FormWrapper } from '../atoms/alignment';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { listDiscountsPublic, redeemCode } from '~lib/platform/promo/store/actions';
import { trackEvent } from '~website/features/analytics/store/actions';
import useErrorMapper from '~website/hooks/use-error-mapper';
import useUser from '~website/hooks/use-user';

const generateRequestId = () => ksuid.generate('request').toString();

const ApplyADiscount = () => {
	const dispatch = useDispatch();
	const { userId } = useUser();
	const [value, setValue] = useState(void 0);
	const referralCode = value?.trim();

	const redeemCodeIden = [userId, referralCode].join();
	const redeemCodeState = useSelector(s => s.platform.promo.redeemCode[redeemCodeIden]);
	const success = useAsyncStateStatus(redeemCodeState).is('response');

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const { getMappedError } = useErrorMapper();
	const error = getMappedError(redeemCodeState?.error);

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (referralCode) {
			dispatch(redeemCode.request({ userId, code: referralCode }));

			dispatch(trackEvent({
				eventName: 'discount_code_submitted',
				eventPayload: {
					product: 'none',
					platform: 'website',
				},
			}));
		}

		return;
	};

	useEffect(() => {
		if (success) {
			setValue(void 0);

			// toast a success message here
			dispatch(listDiscountsPublic.request({
				requestId: generateRequestId(),
				userId,
				includeExpired: true,
				includeUsed: true,
			}));
		}
	}, [dispatch, success, userId]);

	return (
		<FormWrapper onSubmit={handleFormSubmit}>
			<InputDecorator error={error}>
				<TextInput
					value={value ?? ''}
					onChange={onChange}
					placeholder={'Discount code...'}
					onFocus={() => {
						dispatch(trackEvent({
							eventName: 'discount_code_input_selected',
							eventPayload: {
								product: 'none',
								platform: 'website',
							},
						}));
					}}
				/>
			</InputDecorator>

			<Button
				$stretch
				$size={'large'}
				type={'submit'}
				disabled={!referralCode}
				$loading={redeemCodeState?.fetching}
			>
				{'Redeem'}
			</Button>
		</FormWrapper>
	);
};

export default ApplyADiscount;
