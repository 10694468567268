import { AsyncState, initialAsyncState } from '~lib/shared/redux/types/state';

export interface UserState {
	user: AsyncState<string>;
}

export const initialUserState: UserState = {
	user: { ...initialAsyncState },
};

export const GetUserActionTypes = {
	IMPORT_REGISTER_USER: '@@internal/auth/IMPORT_REGISTER_USER',
	IMPORT_AUTHED_USER: '@@internal/auth/IMPORT_AUTHED_USER',
	IMPORT_REGISTER_USER_SUCCESS: '@@internal/auth/IMPORT_REGISTER_USER_SUCCESS',
	IMPORT_REGISTER_USER_FAILURE: '@@internal/auth/IMPORT_REGISTER_USER_FAILURE',

	CLEAR_AUTH_USER: '@@internal/auth/CLEAR_AUTH_USER',
};
