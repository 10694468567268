import React from 'react';
class Selectable extends React.PureComponent {
    static defaultProps = {
        tag: 'span',
    };
    container = React.createRef();
    handleSelection = (e) => {
        if (!this.container.current)
            return;
        const selection = window.getSelection();
        if (!selection)
            return;
        selection.selectAllChildren(this.container.current);
        e.stopPropagation();
    };
    render() {
        const { tag, ...otherProps } = this.props;
        if (!tag)
            return null;
        // Couldn't use JSX as https://github.com/Microsoft/TypeScript/issues/28631
        return React.createElement(tag, {
            ...otherProps,
            onClick: this.handleSelection,
            ref: this.container,
        }, otherProps.children);
    }
}
export default Selectable;
