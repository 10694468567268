import styled from 'styled-components';

const IllustrationReferred = styled.img`
	margin: 0 auto;
	margin-bottom: ${p => p.theme.spacing.regular};
	width: 100%;
	max-width: 12rem;
`;

export default IllustrationReferred;
