import React from 'react';

import { AdapterProps } from '../types';
import SplashAsset, { contentAdapter } from '~website/components/atoms/SplashAsset';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<SplashAsset {...contentAdapter(getter)} />
);

export default {
	type: 'splash_asset',
	adapter,
	builder: {
		fields: {
			asset: { type: 'design_system_asset' },
			mobile_asset: { type: 'design_system_asset' },
		},
		default: {
			asset: {
				type: 'image',
				value: 'help_dialog.png',
				description: '',
			},
			mobile_asset: {
				type: 'image',
				value: 'help_dialog.png',
				description: '',
			},
		},
	},
};
