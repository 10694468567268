import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listEvents } from '../actions';
import { MotorCoverageActionTypes } from '../types';
export default function* watcherListEvents() {
    yield takeEvery(MotorCoverageActionTypes.LIST_EVENTS, workerListEvents);
}
function* workerListEvents({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { userId, afterDate } = payload;
    try {
        const events = [];
        let response;
        let lastTimestamp = null;
        do {
            const payload = { userId };
            if (lastTimestamp)
                payload.afterDate = lastTimestamp;
            else if (afterDate)
                payload.afterDate = afterDate;
            response = yield call([client, client.rpc], 'service-motor-coverage', '2/2017-11-23/list_events', payload);
            const lastEvent = response && response[response.length - 1];
            if (lastEvent)
                lastTimestamp = lastEvent.timestamp;
            events.push(...response);
        } while (response && response.length > 0);
        yield put(listEvents.success(ident, events));
    }
    catch (error) {
        yield put(listEvents.failure(ident, error));
    }
}
