import styled from 'styled-components';

import { shake } from '~lib/frontend/animations/keyframes/shake';

/* /2 Sorry for this - but aligning the svg isn't easy peasy lemon squeezy */
const Counter3ConfettiWrap = styled.div`
	position: absolute;
	top: -91px;
	width: 50%;
	left: 26%;

	@media (max-width: 767px) {
		left: -129px;
		top: -18px;
		width: 111px;
	}

	@media (max-width: 580px) {
		left: -104px;
		top: -23px;
		width: 100px;
	}

	@media (max-width: 379px) {
		left: -94px;
		top: -22px;
		width: 87px;
	}

	> svg:hover {
		animation: ${shake} 2s infinite;
	}
`;

export default Counter3ConfettiWrap;
