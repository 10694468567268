import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useIncidentsInternalChangeRequest from '../../hooks/use-incident-change-request';
import useOnPageError from '../../hooks/use-on-page-error';
import IncidentDialogHint from '../molecules/dialogs/IncidentDialogHint';
import IncidentsList from './incident/IncidentsList';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';

export interface IncidentsProps {
	setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}

const Incidents: React.FCWithChildren<IncidentsProps> = ({ setDisable }) => {
	const { beenDeclared, beenInvolved, hasUnexpiredIncidents, validation, onChange } = useIncidentsInternalChangeRequest();
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation, 'incidents_input');

	const incidentError = error || pageError;

	return (
		<div>
			<QuoteInputDecorator
				label={'Have you been involved in any incidents in the last 5 years?'}
				onFocus={onFocus}
				onBlur={onBlur}
				error={incidentError}
			>
				<AlignDoubleColChildren>
					{ typeof beenDeclared === 'undefined' ? <> <SkeletonLoader height={'48px'} /> <SkeletonLoader height={'48px'} /> </> : <>
						<RadioInput
							key={'Yes'}
							name={'incidents'}
							disabled={beenDeclared}
							checked={(!beenDeclared && beenInvolved) || (beenDeclared && hasUnexpiredIncidents) }
							value={'Yes'}
							data-event-onchange-name={'incidents_input_selected'}
							onChange={() => onChange(true)}
						/>
						<RadioInput
							key={'No'}
							name={'incidents'}
							disabled={beenDeclared}
							checked={beenInvolved === false || (beenDeclared && !hasUnexpiredIncidents)}
							value={'No'}
							data-event-onchange-name={'incidents_input_selected'}
							onChange={() => onChange(false)}
						/></>
					}
				</AlignDoubleColChildren>

			</QuoteInputDecorator>

			{!incidentError && (
				<Margin $marginTop={'small'}>
					<IncidentDialogHint />
				</Margin>
			)}

			<IncidentsList setDisable={setDisable} />
		</div>
	);
};

export default Incidents;
