import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { ConfirmScreenProps } from './ConfirmScreen';
import SingleFileUpload from './SingleFileUpload';
import { UploadScreenProps } from './UploadScreen';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { uploadFile } from '~lib/platform/upload/store/actions';
import { useVerificationContext } from '~website/features/quote/contexts/VerificationContext';

const LIST_ITEMS = [
	'be a utility or council tax bill, bank statement or tenancy agreement',
	'show your name and address',
	'be less than 3 months old',
];
const generateRequestId = () => ksuid.generate('request').toString();

const VerifyAddress = () => {
	const { onUploadComplete } = useVerificationContext();

	const [requestId, setRequestId] = useState<string>(void 0);
	const uploadedFile = useSelector(s => s.platform.upload.uploadFile[requestId]);
	const isUploading = useAsyncStateStatus(uploadedFile).is('fetching');
	const uploadError = useAsyncStateStatus(uploadedFile).is('error') && uploadedFile?.error;

	const fileId = uploadedFile?.response?.fileId;

	const [preview, setPreview] = useState<string>(void 0);

	const uploadScreen: UploadScreenProps = {
		illuFilename: 'il_docs_cuvva_logo.svg',
		illuAlt: 'docs_cuvva_logo',
		heading: 'Proof of address',
		listItems: LIST_ITEMS,
		action: (file, userId, buffer) => {
			const requestId = generateRequestId();

			setRequestId(requestId);
			setPreview(buffer);

			return uploadFile.request({
				userId,
				requestId,
				body: file,
				contentLength: file.size,
				contentType: file.type,
				purpose: 'residential_address_proof',
				relatedIdentifiers: [],
			});
		},
		analyticsPage: 'verification_takeResidentialAddressPhoto',
	};

	const confirmScreen: ConfirmScreenProps = {
		heading: 'Please check your proof of address',
		body: 'Make sure all the corners are visible and it’s clear - no blur, glare or reflections.',
		previewBuffer: preview,
		analyticsPage: 'verification_ResidentialAddressPhotoReview',
	};

	return (
		<SingleFileUpload
			uploadScreen={uploadScreen}
			confirmScreen={confirmScreen}
			fileId={fileId}
			isUploading={isUploading}
			onUploadComplete={() => onUploadComplete(fileId)}
			uploadError={uploadError}
			onRetry={() => setRequestId(void 0)}
		/>
	);
};

export default VerifyAddress;
