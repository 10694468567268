import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { regenerateQuote } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherRegenerateQuote() {
    yield takeEvery(LtmActionTypes.REGENERATE_QUOTE, workerRegenerateQuote);
}
function* workerRegenerateQuote({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.regenerateQuote, client, requestPayload);
        yield put(regenerateQuote.success(ident, response));
    }
    catch (error) {
        yield put(regenerateQuote.failure(ident, error));
    }
}
