import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentEligibility, { contentAdapter } from '~website/components/atoms/TitleSegmentEligibility';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentEligibility
		{...contentAdapter(getter)}
		centered={true}
	/>
);

export default {
	type: 'title_segment_eligibility',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			items: { type: 'string[]' },
		},
		default: {
			title: 'Anybody that needs a test',
			body: 'gets a test',
			items: [
				'one',
				'two',
				'three',
				'four',
			],
		},
	},
};
