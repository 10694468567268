import { put, select } from 'redux-saga/effects';

import { trackPayOnWebScreenView, trackScreenView } from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { flows } from '~website/features/quote/flows';
import { InternalQuoteState as QuoteState } from '~website/features/quote/store/types';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(trackPayOnWebScreenView,
	function* payOnWebScreenView({ payload }) {
		const qState: QuoteState = yield select((s: ApplicationState) => s.internal.quote);
		const flow = qState.state.flow || qState.state.recentFlow;
		const product = flows?.[flow]?.product;

		const eventPayload = {
			screen_name: payload.screenName,
			product: product ? product : 'none',
			product_category: 'motor',
			platform: 'website',
		};

		const eventName = `${payload.screenName}_viewed`;

		yield put(
			trackScreenView({
				eventName,
				eventPayload,
			}),
		);
	});
