import React, { Fragment } from 'react';

import { SmartPricingDialogContentWrapper } from '../../atoms/SmartPricingCard';
import SmartPricingInsOut from '../../atoms/SmartPricingInsOut';
import SmartPricingPrivacy from '../../atoms/SmartPricingPrivacy';
import SmartPricingSteps from '../../atoms/SmartPricingSteps';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';

interface SmartPricingDialogProps {
	open: boolean;
	onOpen: () => void;
	onClose: () => void;
}

const SmartPricingDialog: React.FCWithChildren<SmartPricingDialogProps> = ({ open, onOpen, onClose }) => {
	const { isMobile } = useMobileDetect();
	const marginSizing = isMobile ? 'large' : '24px';

	return (
		<Fragment>
			<Button $type={'text'} type={'button'} onClick={onOpen} $size={'medium'}>
				{'Learn more about how it works'}
			</Button>

			<ComplexDialog.Dialog open={open} size={'medium'} onClose={onClose} title={'How Smart Pricing works'}>
				<ComplexDialog.Content noPadding>
					<SmartPricingDialogContentWrapper>
						<SmartPricingSteps />

						<Margin $marginLeft={marginSizing} $marginRight={marginSizing}>
							<SmartPricingInsOut />
							<SmartPricingPrivacy />
						</Margin>
					</SmartPricingDialogContentWrapper>
				</ComplexDialog.Content>

				<ComplexDialog.Actions>
					<Button
						$display={'flex'}
						$stretch
						$size={'large'}
						$type={'primaryMuted'}
						onClick={() => {
							onClose();
						}}
					>
						{'Close'}
					</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</Fragment>
	);
};

export default SmartPricingDialog;
