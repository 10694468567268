import { combineReducers } from 'redux';
import attachIdentifier from './attach-identifier';
import authenticate from './authenticate';
import createClientCode from './create-client-code';
import deauthenticate from './deauthenticate';
import detachIdentifier from './detach-identifier';
import replacePrimaryIdentifier from './replace-primary-identifier';
import sendAuthenticationCode from './send-authentication-code';
import sendIdentifierToken from './send-identifier-token';
import setPrimaryIdentifier from './set-primary-identifier';
import userById from './user-by-id';
import verifyIdentifier from './verify-identifier';
export { attachIdentifier, authenticate, createClientCode, deauthenticate, detachIdentifier, replacePrimaryIdentifier, sendAuthenticationCode, sendIdentifierToken, setPrimaryIdentifier, userById, verifyIdentifier, };
export default combineReducers({
    attachIdentifier,
    authenticate,
    createClientCode,
    deauthenticate,
    detachIdentifier,
    replacePrimaryIdentifier,
    sendAuthenticationCode,
    sendIdentifierToken,
    setPrimaryIdentifier,
    userById,
    verifyIdentifier,
});
