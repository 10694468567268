import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';
import { getAuthenticationToken } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(getAuthenticationToken.actions.request, function* getAuthenticationTokenSaga({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        yield call(api.getAuthenticationToken, client, payload);
        yield put(getAuthenticationToken.actions.success(ident, null));
    }
    catch (error) {
        yield put(getAuthenticationToken.actions.failure(ident, Cher.coerce(error)));
    }
});
