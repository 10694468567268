import React from 'react';

import { ChevronContainer } from '../atoms/container';
import { MobileItem, MobileItemChild } from '../atoms/mobile';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface MobileAccordionItemProps {
	onClick: () => void;
	active: boolean;
	label: string;
	visionary: boolean;
	children: React.ReactNode[];
}

export const MobileAccordionItem: React.FCWithChildren<MobileAccordionItemProps> = ({
	onClick,
	active,
	label,
	visionary,
	children,
}) => (
	<React.Fragment>
		<MobileItem onClick={onClick}>
			<Typography $bold $type={'Label.Medium'} $color={visionary ? 'textOnFill' : 'textOnSurfaceBackground'}>
				{label}
			</Typography>
			<ChevronContainer $open={active} $visionary={visionary}>
				<Icon icon={'ic_chevron_down_sm'} $size={'20px'} $color={'textOnSurfaceBackground'} />
			</ChevronContainer>
		</MobileItem>
		{children.map((item, i) => (
			<MobileItemChild $visible={active} key={`${label}:${i}`}>
				{item}
			</MobileItemChild>
		))}
	</React.Fragment>
);
