import React, { Fragment } from 'react';
import Typography from '../Typography';
import { policyTimeString } from './utils';
const TimeLeft = ({ isPolicyEnded, isStartDateUpcoming, endDate, startDate }) => {
    if (isPolicyEnded) {
        return (React.createElement(Fragment, null,
            React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' },
                'Ended',
                " ",
                React.createElement("strong", null, `${policyTimeString(endDate).timeString} ago`))));
    }
    if (isStartDateUpcoming) {
        return (React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' },
            'Starts in ',
            " ",
            React.createElement("strong", null, policyTimeString(startDate).timeString)));
    }
    return (React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' },
        React.createElement("strong", null, policyTimeString(endDate).timeString),
        " ",
        ' left'));
};
export default TimeLeft;
