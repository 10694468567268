import { AuthActionTypes, } from './types';
import * as selectors from '~lib/platform/auth/store/selectors';
import { createAsyncMapAction } from '~lib/shared/redux/actions';
export const userById = createAsyncMapAction(AuthActionTypes.USER_BY_ID, p => `${p.userId}-${p.includeRemovedIdentifiers}`);
export const attachIdentifier = createAsyncMapAction(AuthActionTypes.ATTACH_IDENTIFIER, selectors.attachIdentifierSelector);
export const authenticate = createAsyncMapAction(AuthActionTypes.AUTHENTICATE, p => p.requestId);
export const detachIdentifier = createAsyncMapAction(AuthActionTypes.DETACH_IDENTIFIER, selectors.detachIdentifierSelector);
export const setPrimaryIdentifier = createAsyncMapAction(AuthActionTypes.SET_PRIMARY_IDENTIFIER, selectors.setPrimaryIdentifierSelector);
export const sendIdentifierToken = createAsyncMapAction(AuthActionTypes.SEND_IDENTIFIER_TOKEN, p => p.requestId);
export const sendAuthenticationCode = createAsyncMapAction(AuthActionTypes.SEND_AUTHENTICATION_CODE, p => p.requestId);
export const createClientCode = createAsyncMapAction(AuthActionTypes.CREATE_CLIENT_CODE, selectors.createClientCode);
export const deauthenticate = createAsyncMapAction(AuthActionTypes.DEAUTHENTICATE, p => p.requestId);
export const replacePrimaryIdentifier = createAsyncMapAction(AuthActionTypes.REPLACE_PRIMARY_IDENTIFIER, p => p.requestId);
export const verifyIdentifier = createAsyncMapAction(AuthActionTypes.VERIFY_IDENTIFIER, p => p.requestId);
