import React, { Fragment } from 'react';

import { useCancellationFlow } from '../../context/CancellationFlowContext';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as FlowDialog from '~lib/frontend/design-system/components/dialog/molecules/FlowDialog';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CancellationModalLayoutProps {
	canProceed: boolean;
	nextStep?: number;
	modalHeader?: string;
	loading?: boolean;
	confirm?: () => void;
	onSkip?: () => void;
}

const CancellationModalLayout: React.FCWithChildren<CancellationModalLayoutProps> = ({
	nextStep,
	canProceed,
	modalHeader,
	confirm,
	onSkip,
	loading,
	children,
}) => {
	const { flow, closeModal } = useCancellationFlow();
	const { goToUniqueStep, goToNextStep } = flow;

	const continueFlow = (next: number) => {
		if (next) goToUniqueStep(next);
		else goToNextStep();
	};

	const skipStep = () => {
		onSkip();
		continueFlow(nextStep);
	};

	return (
		<Fragment>
			<FlowDialog.Content>
				{modalHeader && (
					<Typography $bold $type={'Body.Large'} $color={'textOnSurfaceBackground'} $marginBottom={'large'}>
						{modalHeader}
					</Typography>
				)}
				{children}
			</FlowDialog.Content>

			<FlowDialog.Actions>
				{onSkip && (
					<Button $type={'primaryMuted'} onClick={skipStep}>
						{'Skip'}
					</Button>
				)}
				{!confirm && (
					<Button disabled={!canProceed} onClick={() => continueFlow(nextStep)}>
						{'Continue'}
					</Button>
				)}
				{confirm && (
					<Fragment>
						<Button disabled={!canProceed} onClick={confirm} $loading={loading}>
							{'Confirm cancellation'}
						</Button>
						<Button $type={'primaryMuted'} onClick={closeModal}>
							{'Keep subscription'}
						</Button>
					</Fragment>
				)}
			</FlowDialog.Actions>
		</Fragment>
	);
};

export default CancellationModalLayout;
