import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import { HookReturn } from '../types';
import validBooleanValidator from '../validators/valid-boolean';
import useFriendlyError from './use-friendly-error';
import { IncidentBase } from '~lib/platform/incident/types';

const field = 'incidents';

const useIncidentsInternalChangeRequest = () => {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);
	const userId = useSelector(s => s.internal.auth.user.response)!;
	const listLatestIncidents = useSelector(s => s.platform.incidents.listLatestIncidents[userId]?.response);

	const cr = internalChangeRequest[internalChangeRequestId];

	const onChange = useCallback((beenInvolved: boolean) => dispatch(stepActions.setBeenInvolved({
		beenInvolved,
		internalChangeRequestId,
	})), [dispatch, internalChangeRequestId]);

	const addIncident = useCallback(() =>
		dispatch(stepActions.addIncident({ internalChangeRequestId })), [
		dispatch, internalChangeRequestId,
	]);

	const deleteIncident = useCallback((index: number) =>
		dispatch(stepActions.deleteIncident({ index, internalChangeRequestId })), [
		dispatch, internalChangeRequestId,
	]);

	// `null` means never declared, `[]` means declared as empty
	const beenDeclared = (() => {
		// It's still fetching
		if (listLatestIncidents === void 0)
			return void 0;

		return listLatestIncidents.incidents !== null;
	})();

	const isDeclaredEmpty = beenDeclared !== null && listLatestIncidents?.incidents?.length === 0;

	const beenInvolved = (() => {
		if (typeof cr?.incidents?.beenInvolved === 'boolean')
			return cr?.incidents?.beenInvolved;

		if (beenDeclared)
			return !isDeclaredEmpty;

		return void 0;
	})();

	const totalCount = cr?.incidents?.incidents?.length ?? 0;

	const hasUnexpiredIncidents = listLatestIncidents?.incidents?.some(incident => {
		const incidentDate = new Date(incident.date);
		const now = new Date();
		const incidentExpiryDate = new Date(incidentDate.setFullYear(incidentDate.getFullYear() + 5));

		return incidentExpiryDate > now;
	}) || false;

	useEffect(() => {
		dispatch(pageActions.registerField({
			field,
			pageId,
		}));
	}, [dispatch, pageId]);

	useEffect(() => {
		const error = validBooleanValidator(beenInvolved);

		dispatch(stepActions.setFieldError({
			field,
			pageId,
			error,
		}));
	}, [beenInvolved, dispatch, pageId]);

	return {
		onChange,
		beenDeclared,
		beenInvolved,
		totalCount,
		addIncident,
		deleteIncident,
		validation: useFriendlyError(page?.fields?.incidents?.error, 'incidents', 'incident'),
		hasUnexpiredIncidents,
	};
};

type Validator<T> = (value: T) => Cher | void;

const fieldKeySelector = (index: number, key: keyof IncidentBase | string | number | symbol) => `incident:${index}:${String(key)}`;

export const useIncidentItemFieldError = (index: number, key: keyof IncidentBase) => {
	const field = fieldKeySelector(index, key);
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const validation = page?.fields?.[field]?.error;

	return validation;
};

export function useIncidentItemInternalChangeRequest<T extends IncidentBase, K extends keyof T>(
	index: number,
	key: K,
	validator?: Validator<T[K]>,
): HookReturn<T[K]> {
	const field = fieldKeySelector(index, key);

	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);

	const cr = internalChangeRequest[internalChangeRequestId];

	const onChange = useCallback((value: T[K] | undefined) => dispatch(stepActions.editIncident({
		index,
		// @ts-ignore
		key,
		// @ts-ignore
		value,
		internalChangeRequestId,
	})), [dispatch, index, key, internalChangeRequestId]);

	// @ts-ignore y u do this to me
	const value = cr?.incidents?.incidents[index as number][key];
	const validation = page?.fields?.[field]?.error;

	useEffect(() => {
		const payload = { field, pageId };

		dispatch(pageActions.registerField(payload));

		return () => void dispatch(pageActions.deregisterField(payload));
	}, [dispatch, field, pageId]);

	useEffect(() => {
		if (!validator)
			return;

		const error = validator(value);

		dispatch(stepActions.setFieldError({
			field,
			pageId,
			error,
		}));
	}, [dispatch, field, pageId, validator, value]);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'incidents', key as string),
	};
}

export default useIncidentsInternalChangeRequest;
