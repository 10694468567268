import React, { useCallback, useContext, useMemo, useState } from 'react';
import TabBackgroundContext from '../context/tab-background-context';
const TabsContext = React.createContext(void 0);
const Tabs = (props) => {
    const tabBackground = useContext(TabBackgroundContext);
    const { defaultIndex: index = 0, onChange, children } = props;
    const [selectedIndex, setSelectedIndex] = useState(() => index);
    const handleChangeIndex = useCallback((index) => {
        if (onChange)
            onChange(index);
        setSelectedIndex(index);
    }, [onChange]);
    const value = useMemo(() => ({
        selectedIndex,
        handleChangeIndex,
        background: tabBackground,
    }), [selectedIndex, handleChangeIndex, tabBackground]);
    return React.createElement(TabsContext.Provider, { value: value }, children);
};
export default Tabs;
export function useTabsContext() {
    const context = React.useContext(TabsContext);
    if (context === void 0)
        throw new Error('useTabsContext must be used within a TabsContextProvider');
    return context;
}
