import { formatNumber, parseNumber } from 'libphonenumber-js';
export const sanitisePhoneNumber = (value) => value
    .replace(/\s+/g, ' ')
    .replace(/[^\s\d+]/g, '');
export const isValidPhoneNumber = (value) => {
    if (typeof value !== 'string')
        return false;
    const safeInput = sanitisePhoneNumber(value).trim();
    const parsed = parseNumberCountryCodeAware(safeInput);
    if (!parsed || !parsed?.phone)
        return false;
    return true;
};
export const parsePhoneNumber = (value) => {
    if (!isValidPhoneNumber(value))
        return value;
    const safeInput = sanitisePhoneNumber(value).trim();
    return formatNumber(parseNumberCountryCodeAware(safeInput), 'E.164');
};
// this shall not be exposed as it doesn't do sanity checks.
const parseNumberCountryCodeAware = (value) => {
    const hasCountryCode = value.startsWith('+') || value.startsWith('00');
    const withParsableCountryCode = value.replace(/^00/, '+');
    const valueWithExtension = value.startsWith('44') ? value.replace(/^44/, '+44') : withParsableCountryCode;
    return parseNumber(valueWithExtension, hasCountryCode ? void 0 : 'GB');
};
