import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cher from '@cuvva/cher';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import ComprehensiveCoverCard from './molecules/ComprehensiveCoverCard';
import VerificationsSplitUI from './molecules/VerificationsSplitUI';
import { LtmQuoteContext } from './quote-page/context';
import SpinnerCover from './quote-page/molecules/SpinnerCover';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';

const modificationsList = [
	'additional lights, including roof-mounted beacons',
	'badges indicating increased performance',
	'body kits, bumpers, grills, and other aftermarket aesthetic changes',
	'bonnet vents',
	'bull bars',
	'campervan & motorhome conversions',
	'paint work, such as matte paint or a whole-vehicle wrap',
	'refrigerated vehicles',
	'tinted or darkened front or rear lights',
	'tinted windows',
	'stripes, stickers, or decals',
	'winches, and other off-roading equipment',
];

const Declarations: React.FunctionComponent = () => {
	const { quote, asyncStatus, requestQuote } = useContext(LtmQuoteContext);
	const [modificationModal, setModificationModal] = useState(false);
	const dispatch = useDispatch();

	const nextRoute = useNextRoute();

	useEffect(() => {
		dispatch(trackPayOnWebScreenView({ screenName: 'estimate_declarations' }));
	}, [dispatch]);

	useEffect(() => {
		if (asyncStatus === 'pending')
			requestQuote();
	}, [asyncStatus, requestQuote]);

	if (asyncStatus !== 'response')
		return <SpinnerCover />;

	const cannotQuote = () => dispatch(push(createAwareUrl('/quote/cannot-quote'), { error: new Cher('cannot_quote') }));

	const businessUse = quote?.response?.summary?.policy?.useClass === 'class1';

	const declarations = quote?.response?.underwriter.declarations.filter(d => {
		if (d.code === 'vehicle_use' && d.text.includes('business') && !businessUse)
			return null;

		return d;
	});

	return (
		<React.Fragment>
			<VerificationsSplitUI hideProgress splashContent={<ComprehensiveCoverCard />}>
				<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
					<Typography $type={'Heading.Medium'} $align={'center'} $color={'textOnSurfaceBackground'}>
						{'Are all of these details correct?'}
					</Typography>
				</Margin>
				<Container>
					{declarations.map(d => (
						<Item key={d.code}>
							<Icon icon={'ic_check'} $color={'primaryFill'} $size={'18px'} />
							<div>
								<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{d.text}
								</Typography>
								{d.code === 'vehicle_mods' && (
									<Button $type={'text'} onClick={() => setModificationModal(true)}>
										{'What’s a modification?'}
									</Button>
								)}
							</div>
						</Item>
					))}
				</Container>
				<Margin $marginTop={'extraLarge'} $marginBottom={'extraLarge'}>
					<Typography $type={'Body.Medium'}>
						{'By clicking yes, you acknowledge that all of these statements are true. If anything is found to be untrue, your policy could be voided or cancelled and claims could be rejected or not paid in full.'}
					</Typography>
				</Margin>
				<ButtonsWrap>
					<Button
						onClick={cannotQuote}
						$type={'neutralMuted'}
						$size={'large'}
						$stretch
					>
						{'No'}
					</Button>
					<Button
						as={Anchor}
						// @ts-ignore
						to={nextRoute}
						$type={'primary'}
						$size={'large'}
						$stretch
					>
						{'Yes'}
					</Button>
				</ButtonsWrap>
			</VerificationsSplitUI>

			<ComplexDialog.Dialog
				title={'What’s a modification?'}
				open={modificationModal}
				onClose={() => setModificationModal(false)}
			>
				<ComplexDialog.Content>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'Modifications include any changes to the vehicle after it’s left the factory, including:'}
					</Typography>

					<Margin $marginTop={'semiLarge'} $marginBottom={'semiLarge'}>
						<Container>
							{modificationsList.map(modification => (
								<Item key={modification} $center>
									<Icon icon={'ic_cross'} $color={'primaryFill'} $size={'18px'} />
									<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
										{modification}
									</Typography>
								</Item>
							))}
						</Container>
					</Margin>

					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'This isn’t a full list – please chat to us if you have any questions. We’re happy to help!'}
					</Typography>
				</ComplexDialog.Content>
				<ComplexDialog.Actions>
					<Button
						$type={'neutralMuted'}
						onClick={() => setModificationModal(false)}
					>
						{'Close'}
					</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</React.Fragment>
	);
};

const useNextRoute = () => {
	const { quote } = useContext(LtmQuoteContext);
	const verificationCount = quote.response?.requirements?.policy?.length ?? 0;

	if (verificationCount > 0)
		return '/quote/verifications';

	return '/quote/payment';
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;

const Item = styled.div<{ $center?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: ${p => p.$center ? 'center' : 'flex-start'};
	justify-content: flex-start;
	gap: ${p => p.theme.spacing.small};

	& > :first-child {
		flex: 0 0 18px;
	}

	& > :nth-child(2) {
		flex: 1;
	}
`;

const ButtonsWrap = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.large};
`;

export default Declarations;
