import sanitize from 'sanitize-html';
const sanitizerOptions = {
    allowedTags: ['b', 'strong', 'u', 'i', 'em'],
    allowedAttributes: {
        '*': [],
    },
    parser: {
        lowerCaseTags: true,
    },
};
export default function sanitizeHtml(input) {
    if (!input)
        return input;
    return sanitize(input, sanitizerOptions);
}
