import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentAccordion, { contentAdapter } from '~website/components/atoms/TitleSegmentAccordion';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentAccordion {...contentAdapter(getter)} centered />
);

export default {
	type: 'title_segment_accordion',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			items: {
				type: 'object[]',
				fields: {
					id: { type: 'string' },
					title: { type: 'string' },
					items: { type: 'text[]' },
				},
			},
		},
		default: {
			title: 'Anybody that needs a test',
			body: 'gets a test',
			items: [
				{
					title: 'One',
					items: ['one', 'two', 'three'],
				},
				{
					title: 'Two',
					items: ['four', 'five', 'six'],
				},
			],
		},
	},
};
