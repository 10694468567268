export const initialChangeRequestState = {
    confirmChangeRequest: {},
    createChangeRequest: {},
    discardChangeRequest: {},
    getChangeRequest: {},
    getChangeRequestInProgress: {},
};
export const ChangeRequestActionTypes = {
    CONFIRM_CHANGE_REQUEST: '@platform/change-request/CONFIRM_CHANGE_REQUEST',
    CREATE_CHANGE_REQUEST: '@platform/change-request/CREATE_CHANGE_REQUEST',
    DISCARD_CHANGE_REQUEST: '@platform/change-request/DISCARD_CHANGE_REQUEST',
    GET_CHANGE_REQUEST_IN_PROGRESS: '@platform/change-request/GET_CHANGE_REQUEST_IN_PROGRESS',
    GET_CHANGE_REQUEST: '@platform/change-request/GET_CHANGE_REQUEST',
};
